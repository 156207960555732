import fetchMessages from './fetchMessages'
import fetchMessagesAverageByDay from './fetchMessagesAverageByDay'
import fetchMessagesCountByHour from './fetchMessagesCountByHour'
import fetchMessagesPreviousPeriod from './fetchMessagesPreviousPeriod'
import fetchAnalyticsTrafficMessages from './fetchAnalyticsTrafficMessages'

export default {
  fetchMessages,
  fetchMessagesAverageByDay,
  fetchMessagesCountByHour,
  fetchMessagesPreviousPeriod,
  fetchAnalyticsTrafficMessages
}
