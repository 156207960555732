import React from 'react'
import { fromUtcToLocal } from 'helpers/moment'

import './styles.css'
import Message from '../Message'

const Conversation = ({ conversation }) => {
  let currentDate, messageDate = undefined;
  return (
    <ul className='Conversation'>
      {
      conversation.message.map((message, v) => {
        if (fromUtcToLocal(message.date, 'YYYY/MM/DD HH:mm') !== currentDate){
          currentDate = fromUtcToLocal(message.date, 'YYYY/MM/DD HH:mm')
          messageDate = fromUtcToLocal(message.date, 'YYYY/MM/DD HH:mm')
        } else {
          messageDate = undefined
        }
        return (<Message {...message} date={messageDate} key={v} />)}
      )}
    </ul>
  )
}

export default Conversation
