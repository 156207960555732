import { connect } from 'react-redux'

import * as botSelectors from 'modules/bot/selectors'
import * as answerAction from 'modules/answer/actions'
import * as actions from 'modules/bot/actions'
import WebchatConfig from '../components/WebchatConfig'

const mapStateToProps = state => ({
  isModified: botSelectors.getIsModified(state),
  webchatUrl: botSelectors.getWebchatUrl(state),
  webchat: botSelectors.getWebchat(state)
})

const mapDispatchToProps = {
  fetchWebchat: actions.fetchWebchat,
  saveWebchat: actions.saveWebchat,
  cleanWebchat: actions.cleanWebchat,
  uploadImage: answerAction.uploadImage,
  updateWebchat: actions.updateWebchat
}

export default connect(mapStateToProps, mapDispatchToProps)(WebchatConfig)
