import types from './types'

const fetchPerformanceRetentionRequest = () => ({
  type: types.FETCH_RETENTION_REQUEST
})

const fetchPerformanceRetentionSuccess = payload => ({
  type: types.FETCH_RETENTION_SUCCESS,
  payload
})

const fetchPerformanceRetentionFailure = errors => ({
  type: types.FETCH_RETENTION_NO_DATA,
  errors
})

export default {
  fetchPerformanceRetentionRequest,
  fetchPerformanceRetentionSuccess,
  fetchPerformanceRetentionFailure
}
