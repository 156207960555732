import React from 'react'
import ReactHighcharts from 'react-highcharts'
import isEmpty from 'lodash/isEmpty'
import NoDataSegment from '../NoDataSegment'
import defaultStyle from '../chartStyle'

const weekDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
// const loadingStyle = {
//   top: '110px',
//   height: '280px',
// }

const config = {
  ...defaultStyle,
  chart: {
    type: 'spline'
  },
  yAxis: [
    {
      title: {
        text: 'Messages'
      }
    },
    {
      title: {
        text: 'Users'
      },
      opposite: true
    }
  ],
  xAxis: {
    categories: weekDays,
    crosshair: true
  }
}

export default function UsersGroupedByDay({ users = null, messages = null, loading }) {
  const series = []

  if (isEmpty(users) || isEmpty(messages)) {
    return <NoDataSegment />
  }

  if (messages) {
    series.push({
      type: 'column',
      animation: false,
      name: 'Messages',
      data: messages.map(elem => [elem.day, elem.count])
    })
  }

  if (users) {
    series.push({
      type: 'spline',
      yAxis: 1,
      animation: false,
      name: 'Users',
      data: users.map(elem => [elem.day, elem.count])
    })
  }

  return <ReactHighcharts config={{ ...config, series }} domProps={{ id: 'UsersGroupedByDay' }} />
}
