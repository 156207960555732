import * as React from 'react'
import { connect } from 'react-redux'
import { List } from 'immutable'

import { fetchTrainingNotMatchedData } from '../../../../redux/training/operations'
import bot from '../../../../modules/bot'

import UtterancesTab from './UtterancesTab'

const TRAINING_LIST_NAME = 'trainingListNotMatched'

function NotMatched(props) {
  return <UtterancesTab {...props} trainingListName={TRAINING_LIST_NAME} showIntentFilter={false} />
}

const mapStateToProps = state => ({
  activeChannelId: state.main.filters.channel.id,
  activeIntent: state.training.getIn(['filters', 'intent', 'id']),
  activeLanguage: state.training.getIn(['filters', 'language', 'id']),
  botId: bot.selectors.getSelectedId(state),
  dateRange: state.training.getIn(['filters', 'dateRange'], List()).toJS(),
  isFetching: state.training.getIn([TRAINING_LIST_NAME, 'isFetching']),
  isFetchingAllData: state.training.get('fetchingAllData'),
  trainingList: state.training.getIn([TRAINING_LIST_NAME, 'data'], List()).toJS()
})

const mapDispatchToProps = {
  fetchTrainingUtterance: fetchTrainingNotMatchedData
}

export default connect(mapStateToProps, mapDispatchToProps)(NotMatched)
