import React, { Component } from 'react'
import { Progress, Popup } from 'semantic-ui-react'
import './filterbar.css'

class FilterBar extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    if (this.props.data > 19) {
      return (
        <Popup
          trigger={<Progress size='tiny' percent={100} success />}
          content={'100%'}
          position='top right'
          inverted
        />
      )
    } else if (this.props.data > 9) {
      return (
        <Popup
          trigger={<Progress size='tiny' value={this.props.data} total='20' warning />}
          content={(this.props.data / 0.2).toFixed(0) + '%'}
          position='top center'
          inverted
        />
      )
    } else {
      return (
        <Popup
          trigger={<Progress size='tiny' value={this.props.data} total='20' error />}
          content={(this.props.data / 0.2).toFixed(0) + '%'}
          position='top left'
          inverted
        />
      )
    }
  }
}

export default FilterBar
