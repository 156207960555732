import moment from 'moment'

const date = moment([2019, 9, 16, 15, 6])

const scheduleCampaignTest = {
  initState: {
    campaigns: [
      {
        id: 1,
        updated: true,
        title: 'Campagne 1',
        elements: [],
        cohorts: [],
        date,
        kpis: {
          sent: 0,
          read: 0,
          targeted: 0
        },
        status: 'draft'
      },
      {
        id: 2,
        updated: true,
        title: 'Campagne 2',
        elements: [],
        cohorts: [],
        date,
        kpis: {
          sent: 0,
          read: 0,
          targeted: 0
        },
        status: 'scheduled'
      }
    ],
    currentId: 1
  },
  reducedState: {
    campaigns: [
      {
        id: 1,
        updated: false, // updated
        title: 'Campagne 1',
        elements: [],
        cohorts: [],
        date,
        kpis: {
          sent: 0,
          read: 0,
          targeted: 0
        },
        status: 'scheduled' // updated
      },
      {
        id: 2,
        updated: true,
        title: 'Campagne 2',
        elements: [],
        cohorts: [],
        date,
        kpis: {
          sent: 0,
          read: 0,
          targeted: 0
        },
        status: 'scheduled'
      }
    ],
    currentId: 1
  }
}

export default scheduleCampaignTest
