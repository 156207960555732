import React, { FunctionComponent, useState } from 'react'
import { MdContentCopy } from 'react-icons/md'

import { webchatParamEnv } from 'helpers/effects'
import { stripMargin } from 'helpers/string'
import TextArea from 'components/TextArea'

import { getWebchatIdFromUrl, scriptUrlForWebChat } from '../../models'

import './styles.css'

type Props = Readonly<{
  url: string
}>

const Script: FunctionComponent<Props> = ({ url }) => {
  const [isCopied, setIsCopied] = useState(false)

  const copyCodeToClipboard = () => {
    const script = document.querySelector('.Script')
    if (script && script instanceof HTMLTextAreaElement) {
      script.select()
      document.execCommand('copy')
      setIsCopied(true)
      const selection = window.getSelection()
      selection !== null && selection.removeAllRanges()
    }
  }
  return (
    <div className="script--container">
      <div className="script-title--container">
        <h1 className="script-title--h1">Script</h1>
        <button className="script-title--button" onClick={copyCodeToClipboard}>
          <div className="script-title-button--container">
            <MdContentCopy />
            <p className="button-text">
              {!isCopied ? 'COPY TO CLIPBOARD' : 'COPIED !'}
            </p>
          </div>
        </button>
      </div>
      <p className="script--text">
        Here is the code to insert in the body of the different HTML pages where
        the webchat is to be inserted:
      </p>
      <div className="script-block--container">
        <TextArea
          className="Script"
          type="rounded"
          rows={7}
          value={stripMargin`<script>
            |   var tcfbot = "${getWebchatIdFromUrl(url)}";
            |   var TcfWbchtParams = { behaviour: 'default' };
            |   var display = 'iframe';
            |   var script = document.createElement('script');
            |   script.type = 'text/javascript';
            |   script.src = '${scriptUrlForWebChat(webchatParamEnv)}';
            |   document.body.appendChild(script);
            |</script>`}
        />
      </div>
    </div>
  )
}

export default Script
