import * as React from 'react'
import { SortableContainer } from 'react-sortable-hoc'
import { CarouselContainer, CarouselCardsContainer } from './styles'
import CarouselCard from './CarouselCard'
import AddCardButton from './AddCardButton'

const cardAdded = (prevProps, props) => prevProps.attachments.length < props.attachments.length
const scrollExtremeRight = dom => (dom.scrollLeft = dom.getBoundingClientRect().width)

const SortableCarousel = SortableContainer(({ children }) => (
  <CarouselCardsContainer>{children}</CarouselCardsContainer>
))

class ElementCarousel extends React.Component {
  state = {
    draggingElements: false
  }

  handleSortStart = () => {
    this.setState({ draggingElements: true })
  }

  handleSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ keyForCarousel }) => ({
      draggingElements: false,
      keyForCarousel: keyForCarousel + 1
    }))
    this.props.reorderCarouselCards(oldIndex, newIndex)
  }

  componentDidUpdate(prevProps) {
    if (cardAdded(prevProps, this.props)) {
      scrollExtremeRight(this.carouselContainer.firstElementChild)
    }
  }

  render() {
    const {
      attachments,
      changeImage,
      saveImage,
      deleteImage,
      changeCarouselCardTitle,
      changeCarouselCardDescription,
      addCarouselCard,
      deleteCarouselCard,
      changeCarouselButtonTitle,
      changeCarouselButtonValue,
      addButton,
      deleteButton,
      settings,
      canEdit
    } = this.props
    const { draggingElements } = this.state
    return (
      <CarouselContainer ref={c => (this.carouselContainer = c)}>
        <SortableCarousel
          useDragHandle
          lockAxis='x'
          axis='x'
          dragging={draggingElements}
          onSortEnd={this.handleSortEnd}
          onSortStart={this.handleSortStart}
        >
          {attachments.map((attachment, index) => (
            <CarouselCard
              key={attachment.id}
              index={index}
              settings={settings}
              attachment={attachment}
              noActions={attachments.length === 1}
              deleteCard={() => deleteCarouselCard(index)}
              saveImage={file => saveImage(index, file)}
              changeImage={temporaryUrl => changeImage(index, temporaryUrl)}
              deleteImage={() => deleteImage(index)}
              addButton={() => addButton(index)}
              deleteButton={buttonIndex => deleteButton(index, buttonIndex)}
              changeCarouselCardTitle={title => changeCarouselCardTitle(index, title)}
              changeCarouselCardDescription={description => changeCarouselCardDescription(index, description)}
              changeCarouselButtonTitle={(buttonIndex, title) => changeCarouselButtonTitle(index, buttonIndex, title)}
              changeCarouselButtonValue={(buttonIndex, value) => changeCarouselButtonValue(index, buttonIndex, value)}
              canEdit={canEdit}
            />
          ))}
        </SortableCarousel>
        {attachments.length < settings.carousel.cards.max && canEdit && <AddCardButton onClick={addCarouselCard} />}
      </CarouselContainer>
    )
  }
}

export default ElementCarousel
