import React from 'react'

import axios from 'axios'
import * as botManagementReactConversations from '@tolk-ai/bot-management-react-conversations'
import * as analyticsConversations from '@tolk-ai/analytics-conversations'
import {  botManagementBaseUrl } from 'helpers/effects'

const botManagementAxios = axios.create({
  baseURL: botManagementBaseUrl
})

const ChannelDetailConversation = 
botManagementReactConversations.channelDetailConversation.container(
    analyticsConversations.conversationsAxios.getOneChannelDetailConversationsCount(
      botManagementAxios
    )
  )

type Properties = {
  readonly channelId: analyticsConversations.clientModel.Entities.ChannelId
  readonly endDate: analyticsConversations.clientModel.Entities.EndDate
  readonly startDate: analyticsConversations.clientModel.Entities.StartDate
}

const Component: React.FC<Properties> = ({ channelId, endDate, startDate }: Properties) => { 
  return (<ChannelDetailConversation channelId={channelId} endDate={endDate} startDate={startDate} />)}



export { Component }
export type { Properties }
