import * as R from 'ramda'
import { v4 as uuid } from 'uuid'

export const insertId = (x: any) => ({ ...x, id: uuid() })

export const removeId = (obj: unknown) => {
  return R.omit(['id'], obj)
}
export const groupAndSumBy = (props: Array<string>, objs: ReadonlyArray<unknown>) => R.reduce(
  R.useWith(
    R.mergeWith(R.add), [R.identity, R.pick(props)]
    ),
  R.pick(props, R.map(
    R.flip(R.objOf)(0), props
    )),
  objs)
