import styled from 'styled-components'
import { initButton } from '../../style/globalStyles'

const ModaleContainer = styled.div`
  position: fixed;
  top: 0;
  left: ${({ theme }) => theme.width.leftPanel}px;
  right: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const ModaleStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: ${({ theme, short }) => (short ? theme.height.modaleShort : theme.height.modale)}px;
  width: ${({ theme }) => theme.width.modale}px;
  border-radius: ${({ theme }) => theme.borderRadius.modale}px;
  box-shadow: ${({ theme }) => theme.boxShadow.default};
  background: ${({ theme }) => theme.color.white};
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transition: opacity 250ms ease-in-out;
`

const ModaleMessageStyled = styled.div`
  width: ${({ theme }) => theme.width.modale}px;
  font-weight: 100;
  font-size: 1.25em;
  padding: 1em;
  text-align: center;
  transition: all 250ms ease-in-out;
  opacity: ${props => (props.visible ? 1 : 0)};

  span {
    display: block;
    margin-bottom: 1.5em;

    font-size: 1.25em;
    font-weight: 600;
    color: ${({ theme }) => theme.color.carouselDotSelected};
  }

  p {
    ${props =>
    props.important &&
      `
      font-weight: 600;
      color: ${props.theme.color.defaultColor};
    `}
  }
`

const ButtonsContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  > * {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: ${({ numberOfButtons }) => (100 - 10) / (numberOfButtons + 1)}%;
    margin-top: 0 !important;
    & ~ * {
      margin-left: 10px;
    }
  }
`

const Image = styled.div`
  width: 200px;
  height: 200px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${({ src }) => src});
`

const PostScriptum = styled.span`
  font-weight: 200;
  font-size: 0.75em;
  opacity: 0.5;
`

const ButtonCross = styled.button`
  ${initButton}
  position: absolute;
  display: inline-flex
  width: ${({ theme }) => theme.width.buttonCross}px;
  height: ${({ theme }) => theme.width.buttonCross}px;
  top: ${({ theme }) => theme.width.buttonCross}px;
  left: ${({ theme }) => theme.width.buttonCross}px;
  div {
    position: absolute;
    width: 100%;
    height: ${({ theme }) => theme.height.buttonCross}px;
    background-color: ${({ theme }) => theme.color.indicator};
    border-radius: ${({ theme }) => theme.height.buttonCross}px;
    transform-origin: center;
    &:first-child {
      transform: rotate(45deg);
    }
    &:last-child {
      transform: rotate(-45deg);
    }
  }
`

export { Image, ModaleContainer, ModaleStyled, ModaleMessageStyled, ButtonsContainer, PostScriptum, ButtonCross }
