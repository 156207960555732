import React, { Component } from 'react'
import { connect } from 'react-redux'
import { maybe } from '@sbizeul/fp-flow'

import operations from '../../../../../redux/analytics/operations'
import getSafe from '../../../../utils/getSafe'
import Delays from './Delays'
import NewFilters from '../../Filters/NewFilters'
import bot from '../../../../../modules/bot'

class DelaysContainer extends Component {
  componentDidMount() {
    const { filters } = this.props
    this.props.fetchAnalyticsPerformanceDelays(filters)
  }

  componentDidUpdate(prevProps) {
    const { filters } = this.props
    if (!maybe.equals(prevProps.botId, this.props.botId) || prevProps.filters.channel.id !== filters.channel.id) {
      this.props.fetchAnalyticsPerformanceDelays(filters)
    }
  }

  render() {
    return (
      <div>
        <NewFilters fetch={this.props.fetchAnalyticsPerformanceDelays} />
        <Delays {...this.props} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  filters: state.main.filters,
  intents: getSafe(() => state.analytics.intents.all),
  fetchingDelays: getSafe(() => state.analytics.delays.fetchingDelays),
  delays: getSafe(() => state.analytics.delays.data),
  messages: getSafe(() => state.analytics.messages.data),
  delaysAverage: getSafe(() => state.analytics.delays.count.average),
  delaysAveragePrevPeriod: getSafe(() => state.analytics.delays.countPreviousPeriod.average),
  comparisonDateRange: state.main.filters.comparisonDateRange,
  noMessagesData: false,
  noDelaysData: false,
  period: ['minutes', 'hours', 'days'][state.main.filters.periods.active],
  botId: bot.selectors.getSelectedId(state)
})

export default connect(mapStateToProps, operations)(DelaysContainer)
