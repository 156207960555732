import React, { Component } from 'react'
import { Grid, Loader } from 'semantic-ui-react'
import UserDefectsTable from './UserDefectsTable'
import UserDefectsList from './UserDefectsList'

class DefectCreate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedUser: 0
    }
  }

  handleClick = userId => {
    this.setState({ selectedUser: userId })
  }

  render() {
    const { data, type, isFetching } = this.props

    if (isFetching) {
      return (
        <div style={{ height: '200px' }}>
          <Loader active>Fetching Data</Loader>
        </div>
      )
    }

    return (
      <Grid
        style={{
          marginTop: '0px',
          height: '100%'
        }}
      >
        <Grid.Column
          mobile={8}
          tablet={4}
          computer={4}
          widescreen={4}
          style={{
            height: '100%',
            overflowX: 'hidden',
            overflowY: 'scroll'
          }}
        >
          <UserDefectsList
            data={data}
            type={type}
            handleClick={this.handleClick}
            selectedUser={this.state.selectedUser}
          />
        </Grid.Column>
        <Grid.Column
          mobile={8}
          tablet={12}
          computer={12}
          widescreen={12}
          style={{
            height: '100%',
            overflowY: 'scroll'
          }}
        >
          {data && data.length && <UserDefectsTable data={data[this.state.selectedUser].data[type]} />}
        </Grid.Column>
      </Grid>
    )
  }
}

export default DefectCreate
