import { maybe } from '@sbizeul/fp-flow'

import get from '../../_shared/get'
import formatFilters from '../../_shared/formatFilters'
import actions from './actions'
import bot from '../../../modules/bot'

const fetchAnswers = rawFilters => {
  const { channelId, start, end, period } = formatFilters(rawFilters)

  return async (dispatch, getState) => {
    const botId = bot.selectors.getSelectedId(getState())
    dispatch(actions.fetchAnswersRequest())
    try {
      const response = await get(`analytics/${maybe.get(botId)}/${channelId}/botanswers`, { start, end, period })
      const data = response.data.map(el => ({
        name: el.sentence.toString(),
        value: el.total
      }))
      const { count } = response
      return dispatch(actions.fetchAnswersSuccess({ data, count }))
    } catch (e) {
      return dispatch(actions.fetchAnswersFailure(e))
    }
  }
}

export default { fetchAnswers }
