import React from 'react'

import Label from '../LabeledName'
import EnabledState from '../EnabledState'
import OpenState from '../OpenState'
import ByLanguageCounter from '../ByLanguageCounter'
import { Empty } from '../../../../components/Loading'

import './styles.css'

function Entities({ entities }) {
  return <div className='Entities'>{entities.length ? renderAllEntities(entities) : renderNoEntities()}</div>
}

function renderNoEntities() {
  return <Empty description='No entities found' />
}

function renderAllEntities(entities) {
  return (
    <ul data-testid='all-entities' className='Entities-list'>
      {entities.map(entity => (
        <li key={entity.id}>
          <EntityRow entity={entity} />
        </li>
      ))}
    </ul>
  )
}

function EntityRow({ entity }) {
  const synonymCount = Object.keys(entity.synonyms).map((language, index) => (
    <li key={index}>
      <ByLanguageCounter language={language} count={entity.synonyms[language]} />
    </li>
  ))
  return (
    <ul className='Entities-row'>
      <li>
        <Label label={entity.name} to={`/entities/${entity.id}`} />
      </li>
      {synonymCount}
      <li>
        <OpenState open={entity.open} />
      </li>
      <li>
        <EnabledState enabled={entity.enabled} />
      </li>
    </ul>
  )
}

export default Entities
