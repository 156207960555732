import { connect } from 'react-redux'

import * as actions from '../actions'
import * as selectors from '../selectors'
import OutOfScope from '../components/OutOfScope'
import {
  RemoteDataOutOfScopeLogsFunctions,
  RemoteDataOutOfScopeLogsProperties
} from '../components/OutOfScope/types'
import { MonitorState } from '../types'

const mapStateToProps: (state: MonitorState) => RemoteDataOutOfScopeLogsProperties = state => ({
  logsOutOfScope: selectors.getLogsOutOfScope(state),
  createdQuestionId: selectors.getCreatedQuestionId(state)
})

const mapDispatchToProps: RemoteDataOutOfScopeLogsFunctions = {
  fetchOutOfScopeLogs: actions.fetchOutOfScopeRequest,
  onQuestionCreated: actions.createQuestion
}

const OutOfScopeContainer = connect(mapStateToProps, mapDispatchToProps)(OutOfScope)

export default OutOfScopeContainer
