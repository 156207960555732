import { List, Map } from 'immutable'
import { maybe } from '@sbizeul/fp-flow'
import * as R from 'ramda'

import getSafe from '../views/utils/getSafe'
import * as fromContent from './intents/reducers'
import bot from '../modules/bot'

export const getConfig = state => state.analytics.events.config

export const getMessagesPerUser = analytics =>
  Number(getSafe(() => analytics.messages.count.users, 0) / getSafe(() => analytics.users.count.total, 1))

export const getMessagesPerUserPrevPeriod = analytics =>
  Number(
    getSafe(() => analytics.messages.countPreviousPeriod.users, 0) /
      getSafe(() => analytics.users.countPreviousPeriod.total, 1)
  )

export const mergeMessagesAndDelays = analytics =>
  analytics.messages.data.map((elem, index) => ({
    time: elem.time,
    messages: elem.user,
    delays: analytics.delays.data[index].averageReplyTime
  }))

export const commentairesSelector = state => state.psa.getIn(['commentaires', 'data'], List()).toJS()

export const andonSelector = state => state.psa.getIn(['andon', 'data'], List()).toJS()

export const prisePosteSelector = state => state.psa.getIn(['prisePoste', 'data'], List()).toJS()

export const sortiePosteSelector = state => state.psa.getIn(['sortiePoste', 'data'], List()).toJS()

export const createDefectSelector = state => state.psa.getIn(['defectCreate', 'data'], List()).toJS()

export const settleDefectSelector = state => state.psa.getIn(['defectSettle', 'data'], List()).toJS()

export const isAdmin = state => getSafe(() => state.main.userData.is_admin) === true

export const getActiveConversation = state => state.conversations.get('activeConversation')

export const getRessources = state => {
  return R.pipe(
    maybe.map(bot => bot.ressources),
    maybe.getOrElse(() => [])
  )(bot.selectors.getSelected(state))
}

export const getRessourceData = state => state.ressources.get('ressource', Map({})).toJS()

export function getLastFetchFiltersData({ filters }) {
  return filters
}

export function messagesSelector(state) {
  return state.conversations.getIn(['messages', 'data'], List()).toJS()
}

export const eventConfigSelector = state => []

export const getActiveIso = state => {
  const languages = state.intents.getIn(['languages'], List()).toJS()
  const index = state.intents.getIn(['intent', 'activeLanguage'], 0)
  return languages[index]
}

export const getIntentData = state => {
  const data = state.intents.getIn(['intent', 'data'], Map()).toJS()
  if (!data.intent) {
    return {}
  }
  const intentData = {
    name: data.intent,
    slug: data.slug,
    description: data.description,
    examples: data.examples,
    answers: data.answers
  }
  return intentData
}

export const getIntentDiversity = state => state.intents.getIn(['intent', 'diversity', 'data'], List()).toJS()

export const getIntents = state => fromContent.getIntents(state.intents)

export const getUsers = state => fromContent.getUsers(state.intents)

export const isFetchingIntents = state => fromContent.isFetchingIntents(state.intents)
