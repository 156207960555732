import React, { Component } from 'react'
import { connect } from 'react-redux'
import { maybe } from '@sbizeul/fp-flow'
import operations from '../../../../../../redux/analytics/messages/operations'
import getSafe from '../../../../../utils/getSafe'
import Details from './Details'
import NewFilters from '../../../Filters/NewFilters'
import bot from '../../../../../../modules/bot'

class DetailsContainer extends Component {
  componentDidMount() {
    this.props.fetchAnalyticsTrafficMessages(this.props.filters)
  }

  componentDidUpdate(prevProps) {
    if (
      !maybe.equals(prevProps.botId, this.props.botId) ||
      prevProps.filters.channel.id !== this.props.filters.channel.id
    ) {
      this.props.fetchAnalyticsTrafficMessages(this.props.filters)
    }
  }

  render() {
    return (
      <div>
        <NewFilters fetch={this.props.fetchAnalyticsTrafficMessages} />
        <Details {...this.props} botId={maybe.get(this.props.botId)} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  filters: state.main.filters,
  botId: bot.selectors.getSelectedId(state),

  conversations: getSafe(() => state.analytics.conversations.count),
  fetchingConversations: getSafe(() => state.analytics.conversations.fetching),
  conversationsPrev: getSafe(() => state.analytics.conversations.countPreviousPeriod.total),
  fetchingConversationsPrev: getSafe(() => state.analytics.conversations.fetchingPreviousPeriod),
  messages: getSafe(() => state.analytics.messages.count.users),
  fetchingMessages: getSafe(() => state.analytics.messages.fetching),
  messagesPrev: getSafe(() => state.analytics.messages.countPreviousPeriod.users),
  fetchingMessagesPrev: getSafe(() => state.analytics.messages.fetchingCountPreviousPeriod),
  fetchingPayloads: getSafe(() => state.analytics.payloads.fetching),
  payloads: getSafe(() => state.analytics.payloads.count.total),
  comparisonDateRange: getSafe(() => state.main.filters.comparisonDateRange),
})

export default connect(mapStateToProps, operations)(DetailsContainer)
