export const format = intents =>
  intents.map(intent => ({
    id: intent.id,
    slug: intent.slug,
    name: intent.tcf_name,
    description: intent.description,
    isActive: intent.is_activated,
    editable: intent.tcf_editable || false,
    suggestionsCount: intent.suggestions_count,
    expressionsCount: intent.expressions_count
  }))
