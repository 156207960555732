import { remoteData } from '@sbizeul/fp-flow'
import * as R from 'ramda'

import * as logActions from './actions'
import * as array from '../../helpers/array'

export const initialState = {
  byId: remoteData.notAsked(),
  allIds: [],
  page: 0
}

const byId = R.lensProp('byId')
const page = R.lensProp('page')

const fetchNext = state => {
  return R.pipe(R.over(page, R.inc), R.over(byId, remoteData.loading))(state)
}

const fetchPrevious = state => {
  return R.pipe(R.over(page, R.dec), R.over(byId, remoteData.loading))(state)
}

const fetchSuccess = (state, action) => {
  return R.set(byId, remoteData.success(array.toMapById(action.payload.result)), state)
}

const fetchFailure = (state, action) => {
  return R.set(byId, remoteData.failure(action.payload), state)
}

export default function log(state = initialState, action) {
  switch (action.type) {
  case logActions.FETCH_NEXT_REQUEST:
    return fetchNext(state)
  case logActions.FETCH_PREV_REQUEST:
    return fetchPrevious(state)
  case logActions.FETCH_SUCCESS:
    return fetchSuccess(state, action)
  case logActions.FETCH_FAILURE:
    return fetchFailure(state, action)
  default:
    return state
  }
}
