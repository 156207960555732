import React, { useState } from 'react'
import * as R from 'ramda'

import { useEffectOnce } from 'helpers/react'
import TextInput from 'components/TextInput/TextInput'

type EditableTitleProps = Readonly<{
  originalText: string

  onChange: (text: string) => unknown
  onCancel: () => unknown
}>

const MIN_INPUT_LENGTH = 2

const isInputValid = (text: string) => text.length >= MIN_INPUT_LENGTH

const EditableTitle: React.FunctionComponent<EditableTitleProps> = ({ originalText, onChange, onCancel }) => {
  const [text, setText] = useState(originalText)

  const handleTextUpdate = (text: string) => setText(text)

  const handleEscapeEdition = onCancel

  const handleTextChange = () => R.cond<string, unknown>([
    [R.equals(originalText), onCancel],
    [isInputValid, onChange],
    [R.T, onCancel]
  ])(text.trim())

  const textInput = React.createRef<HTMLInputElement>()

  useEffectOnce(() => textInput.current && textInput.current.focus())

  return (
    <div
      className='QaQuestionPage-section_input-wrapper QaQuestionPage-section-title'
      onClick={event => event.stopPropagation()}
    >
      <TextInput
        className='QaQuestionPage-section-header-font'
        ref={textInput}
        value={text}
        onChange={event => handleTextUpdate(event.target.value)}
        onEnterKeyPressed={handleTextChange}
        onEscapeKeyPressed={handleEscapeEdition}
        onBlur={handleTextChange}
      />
    </div>
  )
}

export {
  EditableTitle
}
