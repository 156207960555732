import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { LOCATION_CHANGE } from 'connected-react-router'

import Api from 'services/authApi'

import * as userActions from './actions'

import * as userSelectors from './selectors'

export function* login() {
  try {
    const { data } = yield call(Api.get, '/v1/userinfo')
    yield put(userActions.loginSuccess(data))
  } catch (error) {
    yield put(userActions.loginFailure(error?.response?.data || error))
  }
}

export function* fetchOne(action) {
  const userId = action.payload
  try {
    const { data } = yield call(Api.get, `/user/${userId}`)
    yield put(userActions.fetchOneSuccess(data))
  } catch (error) {
    yield put(userActions.fetchOneFailure(error.response.data, userId))
  }
}

export function* fetchAll() {
  try {
    const { data } = yield call(Api.get, '/admin/listusers')
    yield put(userActions.fetchAllSuccess(data))
  } catch (error) {
    yield put(userActions.fetchAllFailure(error))
  }
}

export function* matchRoute() {
  const matchUsers = yield select(userSelectors.matchUsersRoute)
  if (matchUsers != null) {
    yield put(userActions.fetchAll())
  }
}

export function* root() {
  yield takeEvery(LOCATION_CHANGE, matchRoute)
  yield takeLatest(userActions.LOGIN_REQUEST, login)
  yield takeLatest(userActions.FETCH_ALL_REQUEST, fetchAll)
  yield takeLatest(userActions.FETCH_ONE_REQUEST, fetchOne)
}
