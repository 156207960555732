import { remoteData } from '@sbizeul/fp-flow'
import { connect } from 'react-redux'

import answer from 'modules/answer'

import * as actions from '../actions'
import EditorWithMenu from '../components/EditorWithMenu'
import * as selectors from '../selectors'

const mapStateToProps = state => ({
  baseSkill: remoteData.fromMaybe(answer.selectors.getAnswer(state)),
  isModified: answer.selectors.getIsModified(state),
  isPosting: selectors.getIsPosting(state),
  label: selectors.getLabel(state),
})

const mapDispatchToProps = {
  fetch: actions.fetchDefault,
  save: answer.actions.saveOneRequest,
  updateContent: answer.actions.updateContent
}

export default connect(mapStateToProps, mapDispatchToProps)(EditorWithMenu)
