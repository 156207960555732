import 'react-dates/initialize'
import React from 'react'
import { Container, Grid } from 'semantic-ui-react'
import ExamplesTable from './ExamplesTable'
import AddExampleForm from './AddExampleForm'

const TrainingPanel = ({ botId, intentData, language }) => (
  <Grid>
    <Grid.Column width={8}>
      <Container>
        <AddExampleForm
          initialValues={{
            botId,
            intentSlug: intentData.slug,
            language
          }}
          intentCount={intentData.examples.length}
        />
        {!!intentData.examples.length && (
          <ExamplesTable data={intentData.examples} botId={botId} intentSlug={intentData.slug} language={language} />
        )}
      </Container>
    </Grid.Column>
  </Grid>
)

export default TrainingPanel
