import * as R from 'ramda'
import { v4 as uuid } from 'uuid'

import { Id } from 'types'
import { CarouselAttachment, CarouselContent } from './types'
import { IsoLanguage } from 'helpers/language'

export const removeCarouselAttachment: (
  id: Id
) => (carouselContent: CarouselContent) => CarouselContent = (id) => (
  carouselContent
) => {
  const rejectedCarouselAttachment = R.reject(
    (carouselAttachment) => R.propEq('id', id, carouselAttachment),
    carouselContent.objectAttachment
  )
  const updatedCarouselContent = R.set(
    R.lensProp('objectAttachment'),
    rejectedCarouselAttachment,
    carouselContent
  )
  return updatedCarouselContent
}

export const updateCarouselAttachment: (carouselAttachment: CarouselAttachment) => (carouselContent: CarouselContent) => CarouselContent = carouselAttachment => carouselContent => {
  const index = R.findIndex(carouselCard => R.propEq('id', carouselAttachment.id, carouselCard), carouselContent.objectAttachment)
  const path = R.lensPath(['objectAttachment', index])
  const updatedContent = R.set(path, carouselAttachment, carouselContent)

  return updatedContent
}

export const addCarouselAttachment:
  (carouselAttachment: CarouselAttachment) => (carouselContent: CarouselContent) => CarouselContent =
  carouselAttachment => R.over(R.lensProp('objectAttachment'), R.append(carouselAttachment))

export const emptyCarouselAttachment: () => CarouselAttachment = () => ({
  id: uuid(),
  title: '',
  buttons: []
})

export const emptyCarouselContent: (
  language: IsoLanguage
) => CarouselContent = (language) => ({
  id: uuid(),
  type: 'carousel',
  language: language,
  objectAttachment: [emptyCarouselAttachment()]
})
