
import React from 'react'
import { Link } from 'react-router-dom'
import { RemoteData } from '@sbizeul/fp-flow'

import * as routes from '../../routes'
import { RestSkillReferences, Skill, SkillId } from '../../types'
import { FaTrash } from 'react-icons/fa'
import { Modal, Button } from 'semantic-ui-react'
import * as SkillModalContent from './SkillModalContent'

import { ServerFailure } from 'types'

type Props = Readonly<{
  advancedSkill: Skill,
  skillReferences: RemoteData<ServerFailure, RestSkillReferences>
  fetchSkillReferences: (id: SkillId) => unknown
  deleteSkill: (id: SkillId) => unknown
}>

const AdvancedSkill: React.FunctionComponent<Props> = ({ advancedSkill, skillReferences, fetchSkillReferences, deleteSkill }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const openModal = (event: React.MouseEvent) => {
    setIsModalOpen(true)
  }

  const closeModal = (event: React.MouseEvent) => {
    setIsModalOpen(false)
  }

  return (
  <div className="skill-advanced-container skill-advanced--button">
    <Link className='skill-default--button' to={routes.skillBuilderRoutes(advancedSkill.id)}>
      <p className='skill-advanced--label'>{advancedSkill.name}</p>
    </Link>
    <Modal
      onClose={closeModal}
      onOpen={openModal}
      open={isModalOpen}
      trigger={
        <button className='skill-trash-container skill-advanced-trash-container' onClick={openModal}>
          <FaTrash className='skill-trash-icon' size='15' />
        </button>
      }
    >

      <Modal.Header>Delete your scenario</Modal.Header>
      <Modal.Content>
        {
          isModalOpen && <SkillModalContent.Component
            contentType="skill"
            skillId={advancedSkill.id}
            skillReferences={skillReferences}
            fetchSkillReferences={fetchSkillReferences}
          />
        }
      </Modal.Content>
      <Modal.Actions>
        <Button negative content='Cancel'
          onClick={closeModal}
          />
        {skillReferences.tag === 'Success' && skillReferences.value.questions.length === 0 && skillReferences.value.skills.length === 0 &&
          <Button
            content="Yes"
            labelPosition='right'
            icon='checkmark'
            onClick={() => {deleteSkill(advancedSkill.id); setIsModalOpen(false)}}
            positive
          />
        }
      </Modal.Actions>
    </Modal>
  </div>
)}

export default AdvancedSkill
