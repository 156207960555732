import React from 'react'

import classnames from 'classnames'

import './style.css'

const DotLoader = ({ description, className = '' }) => (
  <div className={classnames('DotLoader', className)}>
    {description}
    <div className='lds-ellipsis'>
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
)

export default DotLoader
