import { createSelector } from 'reselect'
import * as R from 'ramda'
import { remoteData, maybe, Maybe } from '@sbizeul/fp-flow'

import * as map from 'helpers/map'
import { IsoLanguage } from 'helpers/language'

import { filterOutArchived } from './models'
import { AppState, Bot } from './types'

const getPropertyAsMaybe: <T>(propertyPath: string[]) => (value: Maybe<unknown>) => Maybe<T> = <T>(propertyPath: string[]) =>
  R.pipe(
    maybe.getOrElse<unknown>(() => ({})),
    R.view(R.lensPath(propertyPath)),
    (element: undefined | T) => maybe.fromNullable<T>(element)
  )

const getPropertyValue: <T>(propertyPath: string[], defaultValue: T) => (value: Maybe<unknown>) => T = <T>(propertyPath: string[], defaultValue: T) =>
  R.pipe(
    getPropertyAsMaybe<T>(propertyPath),
    maybe.getOrElse(() => defaultValue)
  )

const getProperty = (propertyPath: string[], defaultValue: unknown = undefined) =>
  defaultValue === undefined ? getPropertyAsMaybe(propertyPath) : getPropertyValue(propertyPath, defaultValue)

export const getEntity = (state: AppState) => state.entities.bot

export const getUi = (state: AppState) => state.ui.bot

export const getAll = createSelector(
  getEntity, state => remoteData.map(R.pipe(map.toArray, filterOutArchived), state.byId)
)

export const getPublishStatus = createSelector(getEntity, state => state.publishStatus)

export const getOne = R.curry((state, id) => {
  return remoteData.chain(byId => remoteData.fromMaybe(map.get(id, byId)), state.entities.bot.byId)
})

export const getSelected = createSelector([getUi, getEntity], (ui, entity): Maybe<Bot> => {
  return maybe.chain((data) => {
    const result1 = getOne({ entities: { bot: entity } })(data)
    return remoteData.toMaybe(result1) as Maybe<Bot>
  }, ui.selectedId)
})

export const getSelectedId = createSelector(getUi, state => state.selectedId)

export const getLanguages = createSelector(getSelected, getPropertyValue(['language'], ['fr_FR'] as IsoLanguage[]))

export const getName = createSelector(getSelected, getProperty(['name'], 'N/A'))

export const getNlpEngine = createSelector(getSelected, getProperty(['options', 'nlpEngine']))

export const getFeatures = createSelector(getSelected, getProperty(['features'], []))

export const getVersion = createSelector(getSelected, getProperty(['version'], 0))
export const getChannels = createSelector(getSelected, getProperty(['channels'], []))

export const getWebchatUrl = createSelector(getSelected, getProperty(['webchatUrl']))

export const getIsModified = createSelector(getEntity, entity => entity.isModified)
export const getWebchat = createSelector(getEntity, entity => entity.webchat)
export const getAvailableUseCases = createSelector(getEntity, entity => entity.availableUseCases)

export const getBotCreation = createSelector(getUi, state => state.botCreation)
export const getColors = createSelector(getBotCreation, state => state.theme)
export const getBotname = createSelector(getBotCreation, state => state.botName)
export const getLanguage = createSelector(getBotCreation, state => state.language)
export const getGreetings = createSelector(getBotCreation, state => state.greetings)
export const getUseCase = createSelector(getBotCreation, state => state.useCase)

export const getInfo = createSelector(getEntity, state => state.info)
export const getUrl = createSelector(getInfo, remoteData.map(R.prop('webchatUrl')))
export const getBotId = createSelector(
  getInfo,
  R.pipe(remoteData.map(R.prop('botId')), remoteData.getOrElse(R.always('noBotId')))
)
