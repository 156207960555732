const SET_FETCH_ERROR = 'tcfdash/global/SET_FETCH_ERROR'
const REMOVE_FETCH_ERROR = 'tcfdash/global/REMOVE_FETCH_ERROR'
const SET_LOADING = 'tcfdash/global/SET_LOADING'
const REMOVE_LOADING = 'tcfdash/global/REMOVE_LOADING'

export default {
  SET_FETCH_ERROR,
  REMOVE_FETCH_ERROR,
  SET_LOADING,
  REMOVE_LOADING
}
