import { createSelector } from 'reselect'
import { createMatchSelector } from 'connected-react-router'
import { remoteData, pipe } from '@sbizeul/fp-flow'

import * as map from '../../helpers/map'
import { format } from './models'

export const getState = state => state.intent

export const getAll = createSelector(getState, state =>
  pipe(remoteData.map(map.toArray), remoteData.map(format))(state.all)
)

export const getSelectedId = createSelector(getState, state => state.selectedId)

export const getIsPosting = createSelector(getState, state => state.isPosting)

export const matchSelector = createMatchSelector('/intents')
