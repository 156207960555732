import axios from 'axios'
import { maybe } from '@sbizeul/fp-flow'

import formatFilters from '../../../_shared/formatFilters'
import actions from '../actions'
import isResValid from '../../../_shared/isResValid'
import { addNullValuesByPeriodIntents } from '../../../../views/pages/Analytics/utils'
import bot from '../../../../modules/bot'
import { legacyApiUrl } from 'helpers/effects'

const fetchIntentsOverTime = rawFilters => (dispatch, getState) => {
  const { channelId, start, end, period } = formatFilters(rawFilters)
  const botId = bot.selectors.getSelectedId(getState())

  dispatch(actions.fetchIntentsOverTimeRequest())
  return axios({
    url: `${legacyApiUrl}/analytics/${maybe.get(botId)}/${channelId}/intents/all`,
    params: {
      start,
      end,
      period
    },
    method: 'get',
    responseType: 'json'
  })
    .then(response => {
      if (isResValid(response)) {
        const formatedData = addNullValuesByPeriodIntents(response.data.result.data, rawFilters.dateRange, period)
        dispatch(actions.fetchIntentsOverTimeSuccess(formatedData))
      } else {
        dispatch(actions.fetchIntentsOverTimeFailure())
      }
    })
    .catch(err => {
      dispatch(actions.fetchIntentsOverTimeFailure(err))
    })
}

export default fetchIntentsOverTime
