import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Matched from './Tables/Matched'
import NotMatched from './Tables/NotMatched'
import Archived from './Tables/Archived'

const Training = () => (
  <div className='Training pageContainer'>
    <Switch>
      <Route exact path='/training' component={NotMatched} />
      <Route exact path='/training/matched' component={Matched} />
      <Route path='/training/archived' component={Archived} />
    </Switch>
  </div>
)

export default Training
