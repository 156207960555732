import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import _ from 'lodash'
import { Form } from 'semantic-ui-react'
import { connect } from 'react-redux'
import contentOperations from '../../../../redux/intents/operations'
import InputField from '../../../components/InputFieldGroup'
import FilterBarForm from '../Main/FilterBarForm'

const required = value => (value ? undefined : 'Required')
const noEmpty = value => (_.trim(value) ? undefined : 'No Empty')

const AddExampleForm = ({ postIntentExample, handleSubmit, pristine, submitting, intentCount }) => (
  <div>
    <br />
    <FilterBarForm data={intentCount} />
    <Form onSubmit={handleSubmit(postIntentExample)}>
      <Form.Group>
        <Field
          validate={[required, noEmpty]}
          name='example'
          label='Add an expression'
          component={InputField}
          type='text'
        />
        <Form.Button
          style={{ color: '#F9F9F9', backgroundColor: '#3B86FF', fontFamily: 'Roboto', fontWeight: 'lighter' }}
          content='Train'
          type='submit'
          disabled={pristine || submitting}
        />
      </Form.Group>
    </Form>
  </div>
)

AddExampleForm.propTypes = {
  postIntentExample: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired
}
const mapDispatchToProps = {
  postIntentExample: contentOperations.postIntentExample
}

const ConnectedAddExampleForm = connect(null, mapDispatchToProps)(AddExampleForm)

export default reduxForm({
  touchOnBlur: false,
  enableReinitialize: true,
  form: 'addExampleToIntent'
})(ConnectedAddExampleForm)
