import { curry } from 'ramda'
import { remoteData, pipe } from '@sbizeul/fp-flow'

const getEntitiesState = state => state.entities.synonym

const getByKeywordId = curry((state, keywordId) =>
  pipe(
    remoteData.fromNullable,
    remoteData.getOrElse(remoteData.notAsked)
  )(state.entities.synonym.byKeywordId[keywordId])
)

export { getEntitiesState, getByKeywordId }
