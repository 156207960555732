import * as R from 'ramda'
import { takeLatest, put, select, call } from 'redux-saga/effects'
import { maybe, Maybe } from '@sbizeul/fp-flow'
import * as option from 'fp-ts/lib/Option'
import { v4 as uuid } from 'uuid'
import { push } from 'connected-react-router'
import { saveAs } from 'file-saver'

import Api from 'services/tolkApi'
import answer from 'modules/answer'
import { insertIdInsideCarouselContent, insertIdInsideButtonContent } from 'modules/answer/models'
import { SimpleAnswer, SimplifiedSkill } from 'modules/answer/types'
import bot from 'modules/bot'
import { insertId } from 'helpers/object'
import { IsoLanguage } from 'helpers/language'
import { Id } from 'types'

import * as skillActions from './actions'
import * as selectors from './selectors'
import { emptyFormContent } from './models'

import {
  Answer as SkillAnswer,
  Button as SkillButton,
  CreateAdvancedSkillRequest,
  CreateAdvancedSkillSuccess,
  CreateFormSkill,
  CreateFormSkillSuccess,
  DownloadFormDataAsExcelRequest,
  FetchFormRequest,
  FetchSkillRequest,
  FormSkillContent,
  FormSkill,
  Question as SkillQuestion,
  SaveSkillRequest,
  Skill,
  SkillFromApi,
  SaveFormRequest,
  UploadImageRequest,
  FetchFormResponseRequest,
  FormResponse,
  FormResponseContent,
  SaveFormResponseRequest,
  FormEmail,
  FormZapier,
  FetchSkillReferencesRequest,
  DeleteSkillRequest,
  FetchFormReferencesRequest,
  DeleteFormRequest
} from './types'

import { ALL_SKILLS, skillFormsRoutes, skillBuilderRoutes } from './routes'
import { emptyAdvancedSkill } from './models.typed'
import * as answerActions from '../answer/actions'
import botManagementApi from 'services/botManagementApi'

export const sagaToRightTypedData: (data: SkillFromApi) => Skill = data => ({
  ...data,
  questions: R.map(
    question => ({
      ...question,
      buttons: R.map(
        button => ({
          ...button,
          keywordID: option.fromNullable(button.keywordID)
        }),
        question.buttons
      )
    }),
    data.questions
  )
})

export const skillToSaga: (skill: Skill) => SkillFromApi = skill => ({
  ...skill,
  questions: R.map(
    question => ({
      ...question,
      buttons: R.map(
        button => ({
          ...button,
          keywordID: option.getOrElse(() => null as null | string)(button.keywordID)
        }),
        question.buttons
      )
    }),
    skill.questions
  )
})

const formSkillsEnsdpoint = (botId: Maybe<Id>, formId: Maybe<Id>) =>
  `bot/${maybe.get(botId)}/skill-forms/${maybe.getOrElse(() => '', formId)}`

const formResponseEndpoint = (botId: Maybe<Id>, formId: Maybe<Id>) => `bots/${maybe.get(botId)}/forms/${maybe.getOrElse(() => '', formId)}/response-reception`

const defaultSkillsEndpoint = (botId: Maybe<Id>, name: string) =>
  `bot/${maybe.get(botId)}/default-skills/${name}`

const skillEndpoint = (botId: Maybe<Id>, skillId: option.Option<Id>) =>
  `bot/${maybe.get(botId)}/skills/${option.fold(() => '', id => id)(skillId)}`

export const skillsEndpoint = (botId: Maybe<Id>) => `bot/${maybe.get(botId)}/skills`

export const formEndpoint = (botId: Maybe<Id>) => `bot/${maybe.getOrElse(() => 'error', botId)}/skill-forms`

export function* fetch () {
  const botId = yield select(bot.selectors.getSelectedId)
  const skillRoute = yield select(selectors.skillRouteSelector)

  try {
    const { data } = yield call(Api.get, defaultSkillsEndpoint(botId, skillRoute.params.name))
    yield put(answer.actions.fetchOneSuccess(maybe.fromNullable(answer.models.toAnswer(data))))
    yield put(skillActions.fetchDefaultSuccess(answer.models.toAnswer(data)))
  } catch (err) {
    const serverFailure = err.response.data
    yield put(answer.actions.fetchOneFailure('skill', serverFailure))
    yield put(skillActions.fetchDefaultFailure(serverFailure))
  }
}

export function* fetchSkill (action: FetchSkillRequest) {
  const botId = yield select(bot.selectors.getSelectedId)
  try {
    const { data } = yield call(Api.get, skillEndpoint(botId, action.payload))
    const rightTypedData: Skill = R.pipe(
      R.over(
        R.lensProp('questions'),
        R.map(
          R.over(
            R.lensProp('buttons'),
            R.map(
              R.over(
                R.lensProp('keywordID'),
                option.fromNullable
              )
            )
          )
        )
      ),
      R.over(
        R.lensProp('answers'),
        R.map(
          R.over(
            R.lensProp('answer'),
            R.pipe(
              insertIdInsideCarouselContent,
              insertIdInsideButtonContent
            )
          )
        )
      )
  )(data)
    yield put(skillActions.fetchSkillSuccess(rightTypedData))
  } catch (error) {
    const serverFailure = error.response.data
    yield put(skillActions.fetchSkillFailure(serverFailure))
  }
}

export function* fetchFormSkills () {
  const botId = yield select(bot.selectors.getSelectedId)

  try {
    const { data } = yield call(Api.get, formSkillsEnsdpoint(botId, maybe.nothing()))

    yield put(skillActions.fetchFormsSuccess(data))
  } catch (err) {
    const serverFailure = err.response.data
    yield put(skillActions.fetchFormFailure(serverFailure))
  }
}

export function* fetchAllSimplifiedSkills () {
  const botId = yield select(bot.selectors.getSelectedId)
  const skillId = yield select(selectors.getSkillId)
  try {
    const { data }: { data: ReadonlyArray<SimplifiedSkill> } = yield call(Api.get, answer.saga.simplifiedSkillsEndpoint(botId))
    const filterSkillsOut = option.fold(
      R.always(data),
      skillId => R.reject(skill => skill.id === skillId, data)
    )(skillId)
    yield put(answerActions.fetchAllSimplifiedSkillsSuccess(filterSkillsOut))
  } catch (error) {
    const serverFailure = error.response.data
    yield put(answerActions.fetchAllSimplifiedSkillsFailure(serverFailure))
  }
}

export function* fetchAllSimplifiedTags () {
  const botId = yield select(bot.selectors.getSelectedId)
  const skillId = yield select(selectors.getSkillId)
  try {
    const { data }: { data: ReadonlyArray<SimplifiedSkill> } = yield call(Api.get, answer.saga.simplifiedSkillsEndpoint(botId))
    const filterSkillsOut = option.fold(
      R.always(data),
      skillId => R.reject(skill => skill.id === skillId, data)
    )(skillId)
    yield put(answerActions.fetchAllSimplifiedSkillsSuccess(filterSkillsOut))
  } catch (error) {
    const serverFailure = error.response.data
    yield put(answerActions.fetchAllSimplifiedSkillsFailure(serverFailure))
  }
}

export function* fetchForm (action: FetchFormRequest) {
  const botId: Maybe<Id> = yield select(bot.selectors.getSelectedId)

  try {
    const { data }: { data: FormSkill } = yield call(Api.get, formSkillsEnsdpoint(botId, action.payload))
    const dataToForm: FormSkillContent = {
      botId: data.botId,
      id: data.id,
      name: data.name,
      content: R.map(insertId, data.inputs),
      conclusionAnswer: option.fromNullable(data.conclusionAnswer)
    }

    yield put(skillActions.fetchFormSuccess(dataToForm))
    yield put(answer.actions.fetchOneSuccess(option.fold<SimpleAnswer, Maybe<SimpleAnswer>>(
      () => maybe.of(answer.models.newSimpleAnswer(maybe.getOrElse(() => 'error', botId), uuid())),
      maybe.of
    )(dataToForm.conclusionAnswer)))
  } catch (err) {
    const serverFailure = err.response.data
    yield put(skillActions.fetchFormFailure(serverFailure))
  }
}

export function* fetchFormResponse (action: FetchFormResponseRequest) {
  const botId: Maybe<Id> = yield select(bot.selectors.getSelectedId)

  try {
    const { data }: { data: FormResponse } = yield call(Api.get, formResponseEndpoint(botId, action.payload))
    const dataToFormResponse: FormResponseContent = ({
      email: option.fromNullable(data.emailResponseReception),
      zapier: option.fromNullable(data.zapierResponseReception)
    })
    yield put(skillActions.fetchFormResponseSuccess(dataToFormResponse))
  } catch (err) {
    yield put(skillActions.fetchFormResponseFailure(err))
  }
}

export function* saveFormResponse (action: SaveFormResponseRequest) {
  const botId = yield select(bot.selectors.getSelectedId)
  const setEmailResponseReceptionNullIfAddressIsEmpty = (emailResponse: FormEmail) => R.isEmpty(R.prop('address')(emailResponse)) ? null : emailResponse
  const setZapierResponseReceptionNullIfUrlIsEmpty = (zapierResponse: FormZapier) => R.isEmpty(R.prop('url')(zapierResponse)) ? null : zapierResponse

  const formResponseToSavable = {
    emailResponseReception: action.payload.email && setEmailResponseReceptionNullIfAddressIsEmpty(action.payload.email),
    zapierResponseReception: action.payload.zapier && setZapierResponseReceptionNullIfUrlIsEmpty(action.payload.zapier)
  }
  try {
    yield call(Api.patch, formResponseEndpoint(botId, action.payload.id), formResponseToSavable)
    yield put(skillActions.saveFormResponseSuccess())
  } catch (error) {
    yield put(skillActions.saveFormResponseFailure({ message: error.response.data }))
  }
}

export function* createFormSkill (action: CreateFormSkill) {
  const botId = yield select(bot.selectors.getSelectedId)
  const formName = action.payload
  const formSkill = R.set(R.lensProp('botId'), maybe.get(botId), emptyFormContent(formName))

  try {
    const { data } = yield call(Api.post, formSkillsEnsdpoint(botId, maybe.nothing()), formSkill)
    yield put(skillActions.createFormSkillSuccess(data))
  } catch (error) {
    yield put(skillActions.createFormSkillFailure(error))
  }
}

export function* saveFormSkill (action: SaveFormRequest) {
  const botId = yield select(bot.selectors.getSelectedId)
  const formSkillToSavable: Omit<FormSkill, 'isDataAvailable'> = {
    id: action.payload.id,
    botId: action.payload.botId,
    name: action.payload.name,
    inputs: R.map(R.omit(['id']), action.payload.content),
    conclusionAnswer: option.getOrElse<SimpleAnswer | null>(() => null)(
      action.payload.conclusionAnswer
    )
  }
  try {
    yield call(Api.post, formSkillsEnsdpoint(botId, maybe.nothing()), formSkillToSavable)
    yield put(skillActions.saveFormSuccess())
  } catch (error) {
    yield put(skillActions.saveFormFailure(error))
  }
}

export function* fetchAllDefault () {
  const botId = yield select(bot.selectors.getSelectedId)

  try {
    const { data } = yield call(Api.get, defaultSkillsEndpoint(botId, ''))
    yield put(skillActions.fetchAllDefaultSuccess(data))
  } catch (err) {
    const serverFailure = err.response.data
    yield put(skillActions.fetchAllDefaultFailure(serverFailure))
  }
}

export function* navigateToAllSkills () {
  yield put(push(ALL_SKILLS))
}

export function* fetchAll () {
  const botId = yield select(bot.selectors.getSelectedId)
  try {
    const { data } = yield call(Api.get, formEndpoint(botId))
    yield put(skillActions.fetchAllSuccess(data))
  } catch (error) {
    const serverFailure = error.response.data
    yield put(skillActions.fetchAllFailure(serverFailure))
  }
}

export function* fetchAllAdvanced () {
  const botId = yield select(bot.selectors.getSelectedId)
  try {
    const { data } = yield call(Api.get, skillsEndpoint(botId))
    yield put(skillActions.fetchAllAdvancedSuccess(data))
  } catch (error) {
    const serverFailure = error.response.data
    yield put(skillActions.fetchAllAdvancedFailure(serverFailure))
  }
}

export function* createAdvancedSkill (action: CreateAdvancedSkillRequest) {
  const botId: Maybe<string> = yield select(bot.selectors.getSelectedId)
  const skillName = action.payload
  const advancedSkill = emptyAdvancedSkill(maybe.getOrElse(() => 'error', botId), 'fr_FR', skillName)

  try {
    const { data } = yield call(Api.post, skillsEndpoint(botId), R.omit(['id'], fromSkillToSkillApi(advancedSkill)))
    yield put(skillActions.createAdvancedSkillSuccess(data))
  } catch (error) {
    yield put(skillActions.createAdvancedSkillFailure(error))
  }
}

export function* navigateToFormSkill (action: CreateFormSkillSuccess) {
  yield put(push(skillFormsRoutes(action.payload)))
}

export function* downloadFormData (action: DownloadFormDataAsExcelRequest) {
  const { skillId, skillName } = action.payload
  try {
    const botId = yield select(bot.selectors.getSelectedId)
    const downloadEndpoint = `bot/${maybe.getOrElse(() => 'error', botId)}/form-data/${skillId}`
    const { data } = yield call(Api.get, downloadEndpoint, { responseType: 'arraybuffer' })
    const blob = new Blob(
      [data],
      { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' }
    )
    yield call(saveBlobAs, blob, `${skillName}.xlsx`)
    yield put(skillActions.downloadFormDataAsExcelSuccess({ skillId }))
  } catch (error) {
    yield put(skillActions.downloadFormDataAsExcelFailure({ skillId, error }))
  }
}

export function* saveSkill (action: SaveSkillRequest) {
  const botId = yield select(bot.selectors.getSelectedId)
  const route = skillsEndpoint(botId)

  try {
    yield call(Api.put, route, skillToSaga(action.payload))
    yield put(skillActions.saveSkillSuccess())
  } catch (error) {
    yield put(skillActions.saveSkillFailure(error.response.data))
  }
}

const saveBlobAs: (data: Blob, filename: string) => void = (data, filename) =>
  saveAs(data, filename, { autoBom: false })

export function* navigateToAdvancedSkill (action: CreateAdvancedSkillSuccess) {
  yield put(push(skillBuilderRoutes(action.payload)))
}

export function* uploadImage (action: UploadImageRequest) {
  const botId = yield select(bot.selectors.getSelectedId)
  try {
    const dataForm = new FormData()
    dataForm.append('image', action.payload.file)
    const { data } = yield call(Api.post, `/bot/${maybe.get(botId)}/image`, dataForm)
    yield put(skillActions.uploadImageSuccess({ id: action.payload.id, url: data.url, index: action.payload.index }))
  } catch (error) {
    yield put(skillActions.uploadImageFailure({ id: action.payload.id, error }))
  }
}

export type ButtonApi = Readonly<{
  id: Id
  keywordID: Id | null
  label: string
}>

export type QuestionApi = Readonly<{
  buttons: ReadonlyArray<ButtonApi>
  id: Id
  label: string
}>

export type AnswerApi = SkillAnswer

export type SkillApi = Readonly<{
  id: Id
  botId: Id
  name: string
  language: IsoLanguage
  questions: ReadonlyArray<QuestionApi>
  answers: ReadonlyArray<AnswerApi>
}>

const fromSkillToSkillApi: (skill: Skill) => SkillApi = skill => R.pipe(
  R.pick(['id', 'botId', 'name', 'language', 'answers']),
  R.assoc('questions', R.map(fromQuestionToQuestionApi, skill.questions))
)(skill)

const fromQuestionToQuestionApi: (question: SkillQuestion) => QuestionApi = question => R.pipe(
  R.pick(['id', 'label']),
  R.assoc('buttons', R.map(fromButtonToButtonApi, question.buttons))
)(question)

const fromButtonToButtonApi: (button: SkillButton) => ButtonApi = button => R.pipe(
  R.pick(['id', 'label']),
  R.assoc('keywordID', option.getOrElse<string | null>(() => null)(button.keywordID))
)(button)

export function* fetchSkillReferences (action: FetchSkillReferencesRequest) {
  const skillId = action.payload
  const botId = maybe.get(yield select(bot.selectors.getSelectedId))

  try {
    const { data } = yield call(botManagementApi.get, `v1/bots/${botId}/skills/${skillId}/references`)
    yield put(skillActions.fetchSkillReferencesSuccess(data))
  } catch (error) {
    yield put(skillActions.fetchSkillReferencesFailure(error))
  }
}

export function* deleteSkill (action: DeleteSkillRequest) {
  const skillId = action.payload
  const botId = maybe.get(yield select(bot.selectors.getSelectedId))
  try {
    const { data } = yield call(botManagementApi.delete, `v1/bots/${botId}/skills/${skillId}`)
    yield put(skillActions.deleteSkillSuccess(data))
  } catch (error) {
    yield put(skillActions.deleteSkillFailure(error))
  }
}

export function* fetchFormReferences (action: FetchFormReferencesRequest) {
  const formId = action.payload
  const botId = maybe.get(yield select(bot.selectors.getSelectedId))

  try {
    const { data } = yield call(botManagementApi.get, `v1/bots/${botId}/forms/${formId}/references`)
    yield put(skillActions.fetchFormReferencesSuccess(data))
  } catch (error) {
    yield put(skillActions.fetchFormReferencesFailure(error))
  }
}

export function* deleteForm (action: DeleteFormRequest) {
  const formId = action.payload
  const botId = maybe.get(yield select(bot.selectors.getSelectedId))
  try {
    const { data } = yield call(botManagementApi.delete, `v1/bots/${botId}/forms/${formId}`)
    yield put(skillActions.deleteFormSuccess(data))
  } catch (error) {
    yield put(skillActions.deleteFormFailure(error))
  }
}

export function* root () {
  yield takeLatest(skillActions.FETCH_DEFAULT_SKILL_REQUEST, fetch)
  yield takeLatest(skillActions.FETCH_ALL_SKILLS_REQUEST, fetchAll)
  yield takeLatest(answer.actions.FETCH_ONE_REQUEST, fetchAll)
  yield takeLatest(skillActions.FETCH_SKILL_REQUEST, fetchSkill)
  yield takeLatest(skillActions.FETCH_FORM_SKILLS_REQUEST, fetchFormSkills)
  yield takeLatest(skillActions.FETCH_FORM_SKILL_REQUEST, fetchForm)
  yield takeLatest(skillActions.FETCH_FORM_RESPONSE_REQUEST, fetchFormResponse)
  yield takeLatest(answer.actions.SAVE_ONE_SUCCESS, navigateToAllSkills)
  yield takeLatest(skillActions.CREATE_FORM_REQUEST, createFormSkill)
  yield takeLatest(skillActions.CREATE_FORM_SUCCESS, navigateToFormSkill)
  yield takeLatest(skillActions.SAVE_FORM_REQUEST, saveFormSkill)
  yield takeLatest(skillActions.SAVE_FORM_SUCCESS, bot.saga.fetchPublishStatus)
  yield takeLatest(skillActions.SAVE_FORM_SUCCESS, navigateToAllSkills)
  yield takeLatest(skillActions.FETCH_ALL_DEFAULT_SKILLS_REQUEST, fetchAllDefault)
  yield takeLatest(skillActions.FETCH_ALL_SIMPLIFIED_SKILLS_REQUEST, fetchAllSimplifiedSkills)
  yield takeLatest(skillActions.FETCH_ALL_SIMPLIFIED_TAGS_REQUEST, fetchAllSimplifiedTags)
  yield takeLatest(skillActions.DOWNLOAD_FORM_DATA_REQUEST, downloadFormData)
  yield takeLatest(skillActions.CREATE_ADVANCED_REQUEST, createAdvancedSkill)
  yield takeLatest(skillActions.CREATE_ADVANCED_SUCCESS, navigateToAdvancedSkill)
  yield takeLatest(skillActions.FETCH_ALL_ADVANCED_REQUEST, fetchAllAdvanced)
  yield takeLatest(skillActions.SAVE_SKILL_REQUEST, saveSkill)
  yield takeLatest(skillActions.SAVE_SKILL_SUCCESS, bot.saga.fetchPublishStatus)
  yield takeLatest(skillActions.SAVE_FORM_RESPONSE_REQUEST, saveFormResponse)
  yield takeLatest(skillActions.UPLOAD_IMAGE_REQUEST, uploadImage)
  yield takeLatest(skillActions.FETCH_SKILL_REFERENCES_REQUEST, fetchSkillReferences)
  yield takeLatest(skillActions.DELETE_SKILL_REQUEST, deleteSkill)
  yield takeLatest(skillActions.DELETE_SKILL_SUCCESS, fetchAllAdvanced)
  yield takeLatest(skillActions.DELETE_SKILL_SUCCESS, bot.saga.fetchPublishStatus)
  yield takeLatest(skillActions.FETCH_FORM_REFERENCES_REQUEST, fetchFormReferences)
  yield takeLatest(skillActions.DELETE_FORM_REQUEST, deleteForm)
  yield takeLatest(skillActions.DELETE_FORM_SUCCESS, fetchFormSkills)
  yield takeLatest(skillActions.DELETE_FORM_SUCCESS, bot.saga.fetchPublishStatus)
}
