import { fromJS, List } from 'immutable'
import types from './types'

const initialState = fromJS({
  defectCreate: {
    isFetching: false,
    data: []
  },
  defectSettle: {
    isFetching: false,
    data: []
  },
  prisePoste: {
    isFetching: false,
    data: []
  }
})

const psaData = (state = initialState, action) => {
  switch (action.type) {
  case types.GET_DEFECTCREATE_REQUEST:
    return state.setIn(['defectCreate', 'isFetching'], true)

  case types.GET_DEFECTCREATE_FAILURE:
    return state.setIn(['defectCreate', 'isFetching'], false).setIn(['defectCreate', 'data'], List())

  case types.GET_DEFECTCREATE_SUCCESS: {
    action.payload.sort((elem1, elem2) => {
      const date1 = elem1.data.defaut_data[elem1.data.defaut_data.length - 1].timeCreateDefaut
      const date2 = elem2.data.defaut_data[elem2.data.defaut_data.length - 1].timeCreateDefaut
      return date2 - date1
    })
    return state.setIn(['defectCreate', 'isFetching'], false).setIn(['defectCreate', 'data'], fromJS(action.payload))
  }

  case types.GET_DEFECTSETTLE_REQUEST:
    return state.setIn(['defectSettle', 'isFetching'], true)

  case types.GET_DEFECTSETTLE_FAILURE:
    return state.setIn(['defectSettle', 'isFetching'], false).setIn(['defectSettle', 'data'], List())

  case types.GET_DEFECTSETTLE_SUCCESS: {
    action.payload.sort((elem1, elem2) => {
      const date1 = elem1.data.solde_data[elem1.data.solde_data.length - 1].timeSolde
      const date2 = elem2.data.solde_data[elem2.data.solde_data.length - 1].timeSolde
      return date2 - date1
    })
    return state.setIn(['defectSettle', 'isFetching'], false).setIn(['defectSettle', 'data'], fromJS(action.payload))
  }

  case types.GET_PRISEPOSTE_REQUEST:
    return state.setIn(['prisePoste', 'isFetching'], true)

  case types.GET_PRISEPOSTE_FAILURE:
    return state.setIn(['prisePoste', 'isFetching'], false).setIn(['prisePoste', 'data'], List())

  case types.GET_PRISEPOSTE_SUCCESS: {
    /* action.payload.sort((elem1, elem2) => {
      const date1 = elem1.data.solde_data[elem1.data.sequenceDefaut.length - 1].timeCreateDefaut
      const date2 = elem2.data.solde_data[elem2.data.sequenceDefaut.length - 1].timeCreateDefaut
      return date2 - date1
    })*/
    return state.setIn(['prisePoste', 'isFetching'], false).setIn(['prisePoste', 'data'], fromJS(action.payload))
  }

  case types.GET_SORTIEPOSTE_REQUEST:
    return state.setIn(['sortiePoste', 'isFetching'], true)

  case types.GET_SORTIEPOSTE_FAILURE:
    return state.setIn(['sortiePoste', 'isFetching'], false).setIn(['sortiePoste', 'data'], List())

  case types.GET_SORTIEPOSTE_SUCCESS: {
    return state.setIn(['sortiePoste', 'isFetching'], false).setIn(['sortiePoste', 'data'], fromJS(action.payload))
  }

  case types.GET_ANDON_REQUEST:
    return state.setIn(['andon', 'isFetching'], true)

  case types.GET_ANDON_FAILURE:
    return state.setIn(['andon', 'isFetching'], false).setIn(['andon', 'data'], List())

  case types.GET_ANDON_SUCCESS: {
    return state.setIn(['andon', 'isFetching'], false).setIn(['andon', 'data'], fromJS(action.payload))
  }

  case types.GET_COMMENTAIRES_REQUEST:
    return state.setIn(['commentaires', 'isFetching'], true)

  case types.GET_COMMENTAIRES_FAILURE:
    return state.setIn(['commentaires', 'isFetching'], false).setIn(['commentaires', 'data'], List())

  case types.GET_COMMENTAIRES_SUCCESS: {
    return state.setIn(['commentaires', 'isFetching'], false).setIn(['commentaires', 'data'], fromJS(action.payload))
  }

  default:
    return state
  }
}

export default psaData
