const POST_RESSOURCE_REQUEST = 'tcfdash/ressources/POST_RESSOURCE_REQUEST'
const POST_RESSOURCE_FAILURE = 'tcfdash/ressources/POST_RESSOURCE_FAILURE'
const POST_RESSOURCE_SUCCESS = 'tcfdash/ressources/POST_RESSOURCE_SUCCESS'

const PUT_RESSOURCE_REQUEST = 'tcfdash/ressources/PUT_RESSOURCE_REQUEST'
const PUT_RESSOURCE_FAILURE = 'tcfdash/ressources/PUT_RESSOURCE_FAILURE'
const PUT_RESSOURCE_SUCCESS = 'tcfdash/ressources/PUT_RESSOURCE_SUCCESS'

const DELETE_RESSOURCE_REQUEST = 'tcfdash/ressources/DELETE_RESSOURCE_REQUEST'
const DELETE_RESSOURCE_FAILURE = 'tcfdash/ressources/DELETE_RESSOURCE_FAILURE'
const DELETE_RESSOURCE_SUCCESS = 'tcfdash/ressources/DELETE_RESSOURCE_SUCCESS'

const GET_RESSOURCE_REQUEST = 'tcfdash/ressources/GET_RESSOURCE_REQUEST'
const GET_RESSOURCE_FAILURE = 'tcfdash/ressources/GET_RESSOURCE_FAILURE'
const GET_RESSOURCE_SUCCESS = 'tcfdash/ressources/GET_RESSOURCE_SUCCESS'

const CLOSE_MODALS = 'tcfdash/ressources/CLOSE_MODALS'

export default {
  POST_RESSOURCE_REQUEST,
  POST_RESSOURCE_FAILURE,
  POST_RESSOURCE_SUCCESS,

  PUT_RESSOURCE_REQUEST,
  PUT_RESSOURCE_FAILURE,
  PUT_RESSOURCE_SUCCESS,

  DELETE_RESSOURCE_REQUEST,
  DELETE_RESSOURCE_FAILURE,
  DELETE_RESSOURCE_SUCCESS,

  GET_RESSOURCE_REQUEST,
  GET_RESSOURCE_FAILURE,
  GET_RESSOURCE_SUCCESS,

  CLOSE_MODALS
}
