import { connect } from 'react-redux'

import { getAll, isEntityCreating, isOpenCreateForm } from '../selectors'
import { fetchEntitiesRequest, create, openCreateForm, closeCreateForm } from '../actions'
import Entities from '../components/Entities'

const mapStateToProps = state => {
  return {
    entities: getAll(state),
    isCreating: isEntityCreating(state),
    isOpenCreateForm: isOpenCreateForm(state)
  }
}

const mapDispatchToProps = {
  fetchEntities: fetchEntitiesRequest,
  createEntity: create,
  openCreateForm: openCreateForm,
  closeCreateForm: closeCreateForm
}

const EntitiesContainer = connect(mapStateToProps, mapDispatchToProps)(Entities)

export default EntitiesContainer
