export const FETCH_ONE_REQUEST = 'intent/FETCH_ONE_REQUEST'
export const FETCH_ONE_SUCCESS = 'intent/FETCH_ONE_SUCCESS'
export const FETCH_ONE_FAILURE = 'intent/FETCH_ONE_FAILURE'
export const FETCH_ALL_REQUEST = 'intent/FETCH_ALL_REQUEST'
export const FETCH_ALL_SUCCESS = 'intent/FETCH_ALL_SUCCESS'
export const FETCH_ALL_FAILURE = 'intent/FETCH_ALL_FAILURE'
export const CREATE_REQUEST = 'intent/CREATE_REQUEST'
export const CREATE_SUCCESS = 'intent/CREATE_SUCCESS'
export const CREATE_FAILURE = 'intent/CREATE_FAILURE'
export const IMPORT_REQUEST = 'intent/IMPORT_REQUEST'
export const IMPORT_SUCCESS = 'intent/IMPORT_SUCCESS'
export const IMPORT_FAILURE = 'intent/IMPORT_FAILURE'
export const SELECT = 'intent/SELECT'

export const fetchOne = id => {
  return {
    type: FETCH_ONE_REQUEST,
    payload: id
  }
}

export const fetchOneSuccess = intent => {
  return {
    type: FETCH_ONE_SUCCESS,
    payload: intent
  }
}

export const fetchOneFailure = (error, id) => {
  return {
    type: FETCH_ONE_FAILURE,
    payload: { error, id }
  }
}

export const fetchAll = () => {
  return {
    type: FETCH_ALL_REQUEST
  }
}

export const fetchAllSuccess = intents => {
  return {
    type: FETCH_ALL_SUCCESS,
    payload: intents
  }
}

export const fetchAllFailure = error => {
  return {
    type: FETCH_ALL_FAILURE,
    payload: error
  }
}

export const create = intent => {
  return {
    type: CREATE_REQUEST,
    payload: intent
  }
}

export const createSuccess = intent => {
  return {
    type: CREATE_SUCCESS,
    payload: intent
  }
}

export const createFailure = error => {
  return {
    type: CREATE_FAILURE,
    payload: error
  }
}

export const importFile = data => {
  return {
    type: IMPORT_REQUEST,
    payload: data
  }
}

export const importFileSuccess = () => {
  return {
    type: IMPORT_SUCCESS
  }
}

export const importFileFailure = error => {
  return {
    type: IMPORT_FAILURE,
    payload: error
  }
}

export const select = id => {
  return {
    type: SELECT,
    payload: id
  }
}
