import 'react-dates/initialize'
import React, { Component } from 'react'
import { Tab, Icon, Modal } from 'semantic-ui-react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import _ from 'lodash'
import { maybe } from '@sbizeul/fp-flow'

import operations from '../../../../redux/intents/operations'
import { getActiveIso, getIntentData } from '../../../../redux/selectors'
import TrainingPanel from './TrainingPanel'
import matchingFlags from '../../../../utils/matchingFlags'
import UpdateIntentForm from './UpdateIntentForm'
import bot from '../../../../modules/bot'
import '../EditRecast/index.css'

const getAnswer = (intent, language) => {
  if (intent.answers && Array.isArray(intent.answers)) {
    return intent.answers
  } else if (intent.answers && intent.answers[language]) {
    return intent.answers[language]
  } else {
    return []
  }
}

const getIsoData = (intentData, iso) => {
  if (!intentData) {
    return {}
  }
  const isoData = {
    ...intentData,
    examples: _.get(intentData, 'examples', []).filter(elem => elem.language === iso),
    answers: getAnswer(intentData, iso)
  }
  return isoData
}

class EditPage extends Component {
  getBotId() {
    return maybe.getOrElse(() => 'unknownBotId', this.props.botId)
  }

  componentDidMount() {
    const { fetchIntentData, fetchIntentDiversity, match } = this.props
    fetchIntentData(this.getBotId(), match.params.intent)
    fetchIntentDiversity(this.getBotId(), match.params.intent, {
      start: moment().subtract(1, 'months'),
      end: moment()
    })
  }

  render() {
    const { selectIntentLanguage, languages = [], intentData } = this.props

    const panes = languages.map(language => ({
      menuItem: `${_.get(matchingFlags, language, language)}`,
      render: () => (
        <Tab.Pane>
          <TrainingPanel botId={this.getBotId()} intentData={getIsoData(intentData, language)} language={language} />
        </Tab.Pane>
      )
    }))
    return (
      <div>
        <div id='intent-title-builder'>
          <Link id='link-to-intents' to={'/intents'}>
            intents
          </Link>
          <span id='global-intent-name-builder'>
            /<span id='intent-name-builder'>{intentData.name} </span>
            <span id='icon-edit-name'>
              <Modal trigger={<Icon style={{ paddingLeft: '9px' }} name='edit outline' />}>
                <Modal.Header>Update the Intent</Modal.Header>
                <Modal.Content>
                  <UpdateIntentForm intent={intentData} />
                </Modal.Content>
              </Modal>
            </span>
          </span>
        </div>
        <br />
        <div id='global-intent-description-builder'>
          <div id='intent-description-builder'>{intentData.description}</div>
        </div>
        <br />
        <br />
        <br />
        {!this.props.isFetching && (
          <Tab onTabChange={(e, { activeIndex }) => selectIntentLanguage(activeIndex)} panes={panes} />
        )}
        <br />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  botId: bot.selectors.getSelectedId(state),
  isFetching: state.intents.getIn(['intent', 'isFetching'], false),
  intentData: getIntentData(state, getActiveIso(state)),
  activeIso: getActiveIso(state),
  languages: bot.selectors.getLanguages(state)
})

const connectedEditPage = connect(mapStateToProps, operations)(EditPage)

export default connectedEditPage
