import React from 'react'
import * as R from 'ramda'
import { either, remoteData } from '@sbizeul/fp-flow'
import { FaDownload } from 'react-icons/fa'
import { Loader } from 'semantic-ui-react'

import DateRangeComponent from '../../../../components/DateRangePicker'
import SearchBar from '../../../../components/SearchBar'
import Select from '../../../../components/Select'

import './styles.css'
import PageChanger from '../PageChanger'
import Button from '../../../../components/Button/Button'

import { Empty } from '../../../../components/Loading'

const renderDownloadButton = onClickDownload => (
  <Button display='custom' className='download-button' onClick={onClickDownload}>
    <FaDownload size='1.5em' />
  </Button>
)

const renderEmpty = error => <Empty description={error} />

const renderLoading = () => <Loader className='download-loading' active inline size='small' />

const getConversations = remoteData.getOrElse(() => [])

const getConversationCount = R.pipe(
  getConversations, R.length
)

export const ConversationsFilter = ({
  startDate,
  endDate,
  numberConversationsPerPage,
  page,
  searchBarValue,
  conversationsList,
  isDownloadingCSV,
  onDatesChange,
  onChangeSearchValue,
  onClickDownload,
  onSearch,
  previousPage,
  nextPage,
  onChangeNumberConversationsPerPage
}) => (
  <div className='ConversationsFilter'>
    <div className='ConversationsFilter-top'>
      <DateRangeComponent startDate={startDate} endDate={endDate} onDatesChange={onDatesChange} />
      <SearchBar value={searchBarValue} onChangeText={onChangeSearchValue} onEnterKeyPressed={onSearch} />
      <Select
        options={[
          { value: '5', text: '5 conversations/page' },
          { value: '10', text: '10 conversations/page' },
          { value: '15', text: '15 conversations/page' },
          { value: '30', text: '30 conversations/page' },
          { value: '50', text: '50 conversations/page' },
          { value: '100', text: '100 conversations/page' }
        ]}
        value={R.toString(numberConversationsPerPage)}
        onChange={option => onChangeNumberConversationsPerPage(parseInt(option.value))}
      />
    </div>
    {getConversationCount(conversationsList) > 0 && <div className='ConversationsFilter-bottom'>
      <PageChanger
        currentPage={page}
        maximumNumberOfElementsPerPage={numberConversationsPerPage}
        realNumberOfElementsPerPage={getConversationCount(conversationsList)}
        onPreviousPage={previousPage}
        onNextPage={nextPage}
      />
      {either.fold(
        renderEmpty,
        bool => (bool ? renderLoading() : renderDownloadButton(onClickDownload)),
        isDownloadingCSV
      )}
    </div>}
  </div>
)
export default ConversationsFilter
