import { maybe } from '@sbizeul/fp-flow'

import get from '../../_shared/get'
import formatFilters from '../../_shared/formatFilters'
import actions from './actions'
import bot from '../../../modules/bot'

const fetchMenuPersistent = rawFilters => {
  const { channelId, start, end, period } = formatFilters(rawFilters)

  return async (dispatch, getState) => {
    const botId = bot.selectors.getSelectedId(getState())
    dispatch(actions.fetchMenuPersistentRequest())
    try {
      const response = await get(`analytics/${maybe.get(botId)}/${channelId}/events/persistent_menu/item`, {
        start,
        end,
        period
      })
      return dispatch(actions.fetchMenuPersistentSuccess(response))
    } catch (e) {
      return dispatch(actions.fetchMenuPersistentFailure(e))
    }
  }
}

const fetchMenuPersistentUtil = rawFilters => {
  const { channelId, start, end, period } = formatFilters(rawFilters)

  return async (dispatch, getState) => {
    const botId = bot.selectors.getSelectedId(getState())
    dispatch(actions.fetchMenuPersistentUtilRequest())
    try {
      const response = await get(`analytics/${maybe.get(botId)}/${channelId}/events/persistent_menu`, {
        start,
        end,
        period
      })
      const formatedResponse = {
        data: response.data,
        count: response.count,
        name: 'Menu'
      }
      return dispatch(actions.fetchMenuPersistentUtilSuccess(formatedResponse))
    } catch (e) {
      return dispatch(actions.fetchMenuPersistentUtilFailure(e))
    }
  }
}

export default { fetchMenuPersistent, fetchMenuPersistentUtil }
