import types from './types'

const initialState = {
  fetching: false
}

const wordsReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.FETCH_WORDSCOUNT_REQUEST:
    return {
      ...state,
      fetching: true,
      data: null,
      moy_words: null,
      moy_car: null
    }

  case types.FETCH_WORDSCOUNT_NO_DATA:
    return {
      ...state,
      fetching: false
    }

  case types.FETCH_WORDSCOUNT_SUCCESS:
    return {
      ...state,
      fetching: false,
      data: action.payload.total_mess,
      moy_words: action.payload.moy_words,
      moy_car: action.payload.moy_car
    }

  default:
    return state
  }
}

export default wordsReducer
