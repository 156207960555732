import React from 'react'
import { Link } from 'react-router-dom'

import './ButtonLink.css'

export type Props = Readonly<{
  children: React.ReactNode
  className?: string
  route: string
}>

const ButtonLink: React.FunctionComponent<Props> = (props) => (
  <Link to={props.route} className={props.className}>
    <span className='ButtonLink ButtonLink-default primary'>
        <div className='ButtonLink-content'>{props.children}</div>
    </span>
  </Link>
)

export default ButtonLink
