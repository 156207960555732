import { List, fromJS } from 'immutable'
import types from './types'
import initialState from './initialState'

const conversationsReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.GET_CONVERSATIONS_LIST_REQUEST:
    return state.setIn(['conversationsList', 'isFetching'], true)
  case types.GET_CONVERSATIONS_LIST_SUCCESS:
    return state
      .setIn(['conversationsList', 'isFetching'], false)
      .setIn(['conversationsList', 'data'], fromJS(action.payload))
  case types.GET_CONVERSATIONS_LIST_FAILURE:
    return state.setIn(['conversationsList', 'isFetching'], false).setIn(['conversationsList', 'data'], List())

  case types.GET_CONVERSATIONS_LIST_BY_WORDS_REQUEST:
    return state.setIn(['conversationsList', 'isFetching'], true)
  case types.GET_CONVERSATIONS_LIST_BY_WORDS_SUCCESS:
    return state
      .setIn(['conversationsList', 'isFetching'], false)
      .setIn(['conversationsList', 'data'], fromJS(action.payload))
  case types.GET_CONVERSATIONS_LIST_BY_WORDS_FAILURE:
    return state.setIn(['conversationsList', 'isFetching'], false).setIn(['conversationsList', 'data'], List())

  case types.GET_TRAINING_SENTENCE_REQUEST:
    return state.setIn(['trainingList', 'isFetching'], true)
  case types.GET_TRAINING_SENTENCE_SUCCESS:
    return state.setIn(['trainingList', 'isFetching'], false).setIn(['trainingList', 'data'], fromJS(action.payload))
  case types.GET_TRAINING_SENTENCE_FAILURE:
    return state.setIn(['trainingList', 'isFetching'], false).setIn(['trainingList', 'data'], List())

  case types.GET_MESSAGES_REQUEST:
    return state.setIn(['messages', 'isFetching'], true)
  case types.GET_MESSAGES_SUCCESS:
    return state
      .setIn(['messages', 'isFetching'], false)
      .updateIn(['messages', 'data'], data => data.push(fromJS(action.payload)))
  case types.GET_MESSAGES_FAILURE:
    return state.setIn(['messages', 'isFetching'], false)

  case types.SET_ACTIVE_CONVERSATION:
    return state.set('activeConversation', action.payload)

  default:
    return state
  }
}

export default conversationsReducer
