import React from 'react'
import { VelocityTransitionGroup } from 'velocity-react'
import * as R from 'ramda'

import AnswerTypeContainer from '../containers/AnswerTypeContainer'
import SimpleText from '../components/SimpleText'
import AnswerButton from '../components/AnswerButton'
import QuickReply from '../components/QuickReply'
import Button from '../../../components/Button'
import { isValidAnswers } from '../models'
import { renderNothing } from '../../../helpers/react'

import './Answers.css'

class Answers extends React.Component {
  state = {
    mode: this.getMode() ? 'edit' : 'create'
  }

  getMode() {
    return R.pipe(R.split('/'), R.last, R.equals('edit'))(this.props.location.pathname)
  }

  componentDidMount() {
    this.props.fetchIntent(this.props.intentSlug)
  }

  componentWillUnmount() {
    this.props.resetState()
  }

  goBack = () => {
    this.props.history.push(`/intents/edit/${this.props.intentSlug}`)
  }

  save = () => {
    this.props.saveAnswers(this.props.answers)
  }

  isEmpty() {
    return this.props.answers.length <= 0
  }

  renderEmpty() {
    return (
      <div className='Answers-content'>
        <div className='Answers-empty'>
          <span className='Answers-empty--text'>Your answer is empty, add an element</span>
        </div>
      </div>
    )
  }

  renderAnswerButton = button => {
    return (
      <AnswerButton
        key={button.id}
        button={button}
        updateAnswer={this.props.updateAnswer}
        removeAnswer={this.props.removeAnswer}
        intentSlug={this.props.intentSlug}
      />
    )
  }

  renderSimpleText = simpleText => {
    return (
      <SimpleText
        key={simpleText.id}
        simpleText={simpleText}
        updateAnswer={this.props.updateAnswer}
        removeAnswer={this.props.removeAnswer}
        intentSlug={this.props.intentSlug}
      />
    )
  }

  renderQuickReply = quickRepkly => {
    return (
      <QuickReply
        key={quickRepkly.id}
        quickReply={quickRepkly}
        updateAnswer={this.props.updateAnswer}
        removeAnswer={this.props.removeAnswer}
        intentSlug={this.props.intentSlug}
      />
    )
  }

  renderAnswer = answer => {
    switch (answer.type) {
    case 'text':
      return this.renderSimpleText(answer)
    case 'button':
      return this.renderAnswerButton(answer)
    case 'quick_reply':
      return this.renderQuickReply(answer)
    default:
      return renderNothing()
    }
  }

  renderAnswers = answers => {
    return (
      <div className='Answers-content'>
        <VelocityTransitionGroup
          enter={{ animation: 'slideDown', duration: 200 }}
          leave={{ animation: 'slideUp', duration: 200 }}
          runOnMount={true}
        >
          {answers.map(this.renderAnswer)}
        </VelocityTransitionGroup>
        <div className='Answers-content--submit'>
          <Button onClick={this.goBack} className='Answers-content--submit---cancelButton'>
            CANCEL
          </Button>
          <Button
            onClick={this.save}
            className='primary Answers-content--submit---saveButton'
            disabled={!isValidAnswers(answers)}
          >
            SAVE
          </Button>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className='Answers pageContainer'>
        <AnswerTypeContainer intentSlug={this.props.intentSlug} />
        {this.isEmpty() ? this.renderEmpty() : this.renderAnswers(this.props.answers)}
      </div>
    )
  }
}

export default Answers
