import types from './types'

const fetchDelaysRequest = () => ({
  type: types.FETCH_DELAYS_REQUEST
})
const fetchDelaysSuccess = payload => ({
  type: types.FETCH_DELAYS_SUCCESS,
  payload
})
const fetchDelaysFailure = errors => ({
  type: types.FETCH_DELAYS_NO_DATA,
  errors
})

const fetchDelaysPreviousPeriodRequest = () => ({
  type: types.FETCH_DELAYS_PREVIOUS_PERIOD_REQUEST
})
const fetchDelaysPreviousPeriodSuccess = payload => ({
  type: types.FETCH_DELAYS_PREVIOUS_PERIOD_SUCCESS,
  payload
})
const fetchDelaysPreviousPeriodFailure = errors => ({
  type: types.FETCH_DELAYS_PREVIOUS_PERIOD_NO_DATA,
  errors
})

export default {
  fetchDelaysRequest,
  fetchDelaysSuccess,
  fetchDelaysFailure,
  fetchDelaysPreviousPeriodRequest,
  fetchDelaysPreviousPeriodSuccess,
  fetchDelaysPreviousPeriodFailure
}
