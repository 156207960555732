import React from 'react'
import { maybe, remoteData } from '@sbizeul/fp-flow'

import answer from 'modules/answer'

import SkillHeader from 'components/Header'

import { renderNothing, renderServerFailure, renderLoader, didMount } from 'helpers/react'

import * as routes from '../../routes'
import { isValidSkill } from '../../models'

const toSavable = skill => ({
  answerId: maybe.fromNullable(skill.id),
  name: skill.name,
  content: skill.content
})


const EditorWithMenu = ({ baseSkill, fetch, isModified, isPosting, label, save, ...props }) =>
  remoteData.fold({
    Success: skill => (
      <React.Fragment>
        <SkillHeader
          label={label}
          canItBeSaved={isModified && isValidSkill(skill)}
          route={routes.ALL_SKILLS}
          save={() => save(toSavable(skill))}
        />
        <answer.components.AnswerEditorSimpleContainer
          answer={maybe.of(skill)}
          onAnswerUpdated={updatedSkill => !maybe.equals(updatedSkill, maybe.of(skill)) && props.updateContent(updatedSkill)}
        />
      </React.Fragment>
    ),
    Failure: renderServerFailure,
    Loading: () => renderLoader('please wait...'),
    NotAsked: renderNothing
  })(baseSkill)

export default didMount(props => props.fetch())(EditorWithMenu)
