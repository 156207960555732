import React, { FunctionComponent } from 'react'

import './styles.css'

type Props = Readonly<{
  firstColor: string
  secondColor: string
  selected: boolean

  changeColours: (props: { firstColor: string, secondColor: string }) => unknown
}>

const ColourInput: FunctionComponent<Props> = ({ firstColor, secondColor, selected, changeColours }) => (
  <label
    style={{
      backgroundColor: firstColor,
      ...{ border: selected ? `2px ${firstColor} solid` : undefined }
    }}
    className='customize-color-box'
  >
    <input
      defaultChecked={selected}
      type='radio'
      name='color-group'
      className='ColourInput-color-box-input'
      onClick={() => {
        changeColours({ firstColor, secondColor })
      }}
    />
  </label>
)

export default ColourInput
