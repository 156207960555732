import React from 'react'

import Button from '../Button'
import './Paginator.css'

export default function Paginator(props) {
  return (
    <div className='Paginator'>
      <Button
        disabled={props.page <= 1 || props.disabled}
        onClick={props.onPrevious}
        display='rounded'
        className='Paginator-button primary'
      >
        Previous
      </Button>
      <span className='Paginator-counter'>{props.page > 0 ? props.page : null}</span>
      <Button onClick={props.onNext} display='rounded' className='Paginator-button primary' disabled={props.disabled}>
        Next
      </Button>
    </div>
  )
}
