import React from 'react'
import classNames from 'classnames'

import FormLabel from '../FormLabel'
import FormError from '../FormError'

import './styles.css'

export default function FormInput({ type = 'text', ...props }) {
  return (
    <div className={classNames('FormInput', props.className)} onBlur={props.onBlur}>
      <FormLabel required={props.required} label={props.label || ''} />
      <input
        className={classNames('FormInput-input', { 'FormInput-input--error': props.error })}
        type={type}
        value={props.value}
        onChange={props.onChange}
        name={props.name}
        placeholder={props.placeholder}
        autoComplete={props.autoComplete}
      />
      {props.icon}
      <FormError className='FormInput-formError' msg={props.error} />
    </div>
  )
}
