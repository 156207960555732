import * as R from 'ramda'

import { LabeledQuestions, Question, Section } from 'modules/question/types'

import { AllQuestionsBySection, QuestionsBySection } from './types'

const labeledQuestionsGroupedByQuestions: (labeledQuestions: LabeledQuestions) => AllQuestionsBySection =
  labeledQuestions => R.pipe(
    sortByLabel,
    R.map(questionsByFilteredSections(labeledQuestions.questions))
  )(labeledQuestions.sections)

const questionsByFilteredSections: (questions: ReadonlyArray<Question>) => (section: Section) => QuestionsBySection =
  questions => section => R.pipe(
    filterQuestionsBySection(section),
    questionsBySection(section)
  )(questions)

const filterQuestionsBySection: (section: Section) => (questions: ReadonlyArray<Question>) => ReadonlyArray<Question> =
  section => questions => R.filter(a => a.sectionId === section.id, questions)

const questionsBySection: (section: Section) => (questions: ReadonlyArray<Question>) => QuestionsBySection =
  section => questions => ({
    section,
    questions
  })

const sortByLabel: (sections: readonly Section[]) => readonly Section[] = R.sortBy(R.prop('label'))

export {
  labeledQuestionsGroupedByQuestions
}
