import React from 'react'
import * as R from 'ramda'
import { Option } from 'fp-ts/lib/Option'
import { Button, Icon } from 'semantic-ui-react'
import { v4 as uuid } from 'uuid'

import Collapsible from 'components/Collapsible'
import Header from 'components/Header'
import { Keywords } from 'modules/keyword/types'
import { Id } from 'types'

import * as routes from '../../routes'
import {
  CreateQuestion,
  CreateScenario,
  RemoveContent,
  RemoveScenario,
  Skill,
  UpdateAnswerContentParams,
  UpdateContent,
  UpdateQuestion
} from '../../types'
import QuestionEditor from './QuestionEditor'
import ScenarioComponent from './ScenarioComponent'

import './styles.css'

export type Props = Readonly<{
  keywords: Keywords
  skill: Skill
  isModified: boolean
  skillId: Option<Id>

  createQuestionContent: CreateQuestion
  createScenario: CreateScenario
  removeQuestionContent: RemoveContent
  removeScenario: RemoveScenario
  saveSkill: (skill: Skill) => unknown
  updateAnswerContent: UpdateAnswerContentParams
  updateQuestion: UpdateQuestion
  updateQuestionContent: UpdateContent
}>

const renderQuestions = (keywords: Keywords, updateContent: UpdateContent, removeContent: RemoveContent, updateQuestion: UpdateQuestion) => (
  skill: Skill
) =>
  R.map(
    question => (
      <QuestionEditor
        key={question.id}
        keywords={keywords}
        question={question}
        updateQuestionContent={updateContent}
        removeQuestionContent={removeContent}
        updateQuestion={updateQuestion}
      />
    ),
    skill.questions
  )

const renderCreateQuestion: React.FunctionComponent<CreateQuestion> = createQuestionContent => (
  <div key={uuid()} className='SkillBuilder-create-question'>
    <Button icon onClick={createQuestionContent}>
      <Icon name='add' className='SkillBuilder-create-question-icon' />
      ADD A QUESTION
    </Button>
  </div>
)

const hasNoAnswer = (skill: Skill): boolean => R.isEmpty(R.prop('answers', skill))

const hasNoQuestion = (skill: Skill): boolean => R.isEmpty(R.prop('questions', skill))

const hasEmptyAnswerContents = (skill: Skill): boolean => {
  const answersContentLength = skill.answers.map(answer => answer.answer.content.length === 0)
  return !!answersContentLength.find(contentLength => contentLength === true)
}

const hasEmptyQuestionButton = (skill: Skill): boolean => {
  const questionsButtonsLength = skill.questions.map(question => question.buttons.length === 0)
  return !!questionsButtonsLength.find(buttonLength => buttonLength === true)
}

const hasEmptyQuestionButtonLabel = (skill: Skill): boolean => {
  const questionsButtonsLabel = skill.questions.map(
    questions => questions.buttons.map(buttons => buttons.label.length === 0)
  ).reduce((acc, val) => acc.concat(val), [])
  return !!questionsButtonsLabel.find(buttonLabel => buttonLabel === true)
}

const isValidSkill = (skill: Skill): boolean => !R.anyPass([
  hasNoQuestion,
  hasNoAnswer,
  hasEmptyQuestionButtonLabel,
  hasEmptyQuestionButton,
  hasEmptyAnswerContents
])(skill)

const skillInfo = (skill: Skill): string => {
  if (R.isEmpty(R.prop('questions', skill))) {
    return 'No question'
  } else if (hasEmptyQuestionButton(skill)) {
    return 'Question as no button'
  } else if (hasEmptyQuestionButtonLabel(skill)) {
    return 'A question as an empty button text'
  } else if (R.isEmpty(R.prop('answers', skill))) {
    return 'No answer'
  } else if (hasEmptyAnswerContents(skill)) {
    return 'No answer content'
  }
  return ''
}

const SkillBuilder = ({
  createQuestionContent,
  createScenario,
  isModified,
  keywords,
  removeQuestionContent,
  removeScenario,
  saveSkill,
  skill,
  updateAnswerContent,
  updateQuestion,
  updateQuestionContent
}: Props) => {
  return (
    <React.Fragment>
      <Header
        label={R.prop('name', skill)}
        canItBeSaved={isModified && isValidSkill(skill)}
        route={routes.ALL_SKILLS}
        save={() => saveSkill(skill)}
        saveInfo={() => skillInfo(skill)}
        isValidSkill={isValidSkill(skill)}
      />
      <Collapsible title='Questions' open>
        {[renderQuestions(keywords, updateQuestionContent, removeQuestionContent, updateQuestion)(skill), renderCreateQuestion(createQuestionContent)]}
      </Collapsible>
      <Collapsible title='Answers' open={false}>
        <ScenarioComponent
          createScenario={createScenario}
          removeScenario={removeScenario}
          skill={skill}
          updateAnswerContent={updateAnswerContent}
        />
      </Collapsible>
    </React.Fragment>)
}

export default SkillBuilder
