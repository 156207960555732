import React from 'react'
import ReactHighcharts from 'react-highcharts'
import defaultStyle from '../chartStyle'

const config = {
  ...defaultStyle,
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: 'pie'
  },
  tooltip: {
    pointFormat: '{series.name}: <b>{point.percentage:.1f}% ({point.y} Users)</b>',
    shared: true
  },
  plotOptions: {
    pie: {
      cursor: 'pointer',
      dataLabels: {
        enabled: false
      },
      showInLegend: true
    }
  }
}

export default function Gender({ data }) {
  const gender = {
    type: 'pie',
    animation: { duration: 2000 },
    name: 'Repartition',
    data: [
      ['Male', data.male],
      ['Female', data.female],
      ['Unknown', data.unknown]
    ]
  }
  config.series = [gender]
  return <ReactHighcharts config={config} domProps={{ id: 'GenderPieChart' }} />
}
