import React from 'react'
import { MdCreateNewFolder } from 'react-icons/md'

import './styles.css'

type Props = {
  onClick?: () => unknown
}

const AddSectionButton: React.FunctionComponent<Props> = ({ onClick }) => (
  <div className='AddSectionButton' onClick={onClick}>
    <MdCreateNewFolder className='AddSectionButton-plusIcon'/><span>Add Section</span>
  </div>
)

export {
  AddSectionButton
}
