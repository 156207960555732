import { remoteData } from '@sbizeul/fp-flow'
import * as R from 'ramda'

import * as registerActions from './actions'
import { RegisterAction, RegisterFailure, RegisterUiState } from './types'

export const initialState: RegisterUiState = {
  form: remoteData.notAsked()
}

export const form = R.lensProp('form')

const registerRequest = R.set(form, remoteData.loading())

const registerSuccess = R.set(form, remoteData.success('ok'))

const registerFailure = (action: RegisterFailure, state: RegisterUiState) =>
  R.set(form, remoteData.failure(action.payload), state)

export default function reducer (state = initialState, action: RegisterAction) {
  switch (action.type) {
    case registerActions.REGISTER_REQUEST:
      return registerRequest(state)
    case registerActions.REGISTER_SUCCESS:
      return registerSuccess(state)
    case registerActions.REGISTER_FAILURE:
      return registerFailure(action, state)
    default:
      return state
  }
}
