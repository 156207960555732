import React from 'react'
import PropTypes from 'prop-types'

const PreviewIcon = ({ width, height, fill }) => (
  <svg version='1.1' xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 24.1 24.1'>
    <g fill={fill}>
      <path d='m23,14.05h-2c-0.6,0-1,0.4-1,1v4.5c0,0.3-0.2,0.5-0.5,0.5h-15c-0.3,0-0.5-0.2-0.5-0.5v-15c0-0.3 0.2-0.5 0.5-0.5h4.5c0.6,0 1-0.4 1-1v-2c0-0.6-0.4-1-1-1h-8c-0.6,0-1,0.4-1,1v22c0,0.6 0.4,1 1,1h22c0.6,0 1-0.4 1-1v-8c0-0.6-0.4-1-1-1z' />
      <path d='m23,.05h-8c-0.6,0-1,0.4-1,1v2c0,0.6 0.4,1 1,1h1c0.4,0 0.7,0.5 0.4,0.9l-8,8c-0.4,0.4-0.4,1 0,1.4l1.4,1.4c0.4,0.4 1,0.4 1.4,0l8-8c0.3-0.3 0.9-0.1 0.9,0.4v1c0,0.6 0.4,1 1,1h2c0.6,0 1-0.4 1-1v-8c-0.1-0.7-0.5-1.1-1.1-1.1z' />
    </g>
  </svg>
)

PreviewIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string
}

PreviewIcon.defaultProps = {
  width: '1.5em',
  height: '1.5em',
  fill: 'currentColor'
}

export default PreviewIcon
