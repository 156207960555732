
import * as React from 'react'
import { MdDelete } from 'react-icons/md'

import TextInput from 'components/TextInput'

const QuickReply = ({ title, onChangeText, answer, render, renderKeyword, removeButton }) => (
  <div className='QuickReplyContent-column--button'>
    <div className='QuickReplyContent--button'>
      <TextInput
        className='QuickReplyContent-text--button'
        data-testid='update-text-input'
        type='rounded'
        name='text'
        autocomplete='off'
        value={title[0]}
        onChangeText={onChangeText}
        placeholder='Type your message...'
      />
      <MdDelete
        className='QuickReplyContent-remove--button'
        size='20'
        data-testid='remove-quickReply'
        onClick={removeButton}
      />
    </div>
    <div className='QuickReply-answer'>
      {renderKeyword}
      <h2 className='QuickReply-answer--subtitle'>Answer</h2>
      {render(answer)}
    </div>
  </div>
)

export default QuickReply
