import styled from 'styled-components'
import { initButton } from '../../style/globalStyles'

const RecurringSelectionContainer = styled.div`
  display: inline-flex;
`

const RecurrenceSelection = styled.div`
  margin-left: 30px;
  transition: all 250ms ease-in-out;
  ${props =>
    props.disabled &&
    `
    pointer-events: none;
    opacity: 0.5;
  `}
`

const CheckBox = styled.span`
  border: 1px solid ${({ theme }) => theme.color.defaultColor};
  margin-right: ${({ theme }) => theme.margin.right.checkBox}px;
  width: ${({ theme }) => theme.width.checkBox}px;
  height: ${({ theme }) => theme.height.checkBox}px;
  padding: ${({ theme }) => theme.padding.checkBox}px;
  background-clip: content-box;
  pointer-events: none;
  ${props =>
    props.selected &&
    `
    background-color: ${props.theme.color.defaultColor};
  `}
  ${props =>
    props.disabled &&
    `
    opacity: 0.3;
  `}
`

const Recurring = styled.span`
  color: ${({ theme }) => theme.color.defaultColor};
  line-height: 1em;
  pointer-events: none;
  ${props =>
    props.selected &&
    `
  font-weight: 700;
`}
  ${props =>
    props.disabled &&
    `
  pointer-events: none;
`}
`

const RecurringContainer = styled.button`
  ${initButton}
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: ${({ theme }) => theme.margin.bottom.cohort}px;
  ${props =>
    props.selected &&
    `
    ${Recurring} {
      font-weight: 700;
    }
    ${CheckBox} {
      border: 3px solid blue;
      background-color: ${({ theme }) => theme.color.defaultColor};
    }
  `}
`

export { CheckBox, RecurringContainer, Recurring, RecurringSelectionContainer, RecurrenceSelection }
