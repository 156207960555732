import { either, maybe } from '@sbizeul/fp-flow'
import * as R from 'ramda'

import * as actions from './actions'
import synonym from 'modules/synonym_old'

export const initialState = {
  selectedEntityId: maybe.nothing(),
  isEntityCreating: either.of(false),
  isOpenCreateForm: false,
  isDeletingSynonym: either.right(false),
  isSynonymAdding: either.of(false)
}

const selectedEntityId = R.lensProp('selectedEntityId')
const isEntityCreating = R.lensProp('isEntityCreating')
const isOpenCreateForm = R.lensProp('isOpenCreateForm')
const isSynonymAdding = R.lensProp('isSynonymAdding')
const isDeletingSynonym = R.lensProp('isDeletingSynonym')

const selectEntity = (state, action) => R.set(selectedEntityId, maybe.just(action.payload), state)

const unselectEntity = state => R.set(selectedEntityId, maybe.nothing(), state)

const openCreateForm = state => R.set(isOpenCreateForm, true, state)

const closeCreateForm = state => R.set(isOpenCreateForm, false, state)

const createEntityRequest = state => R.set(isEntityCreating, either.of(true), state)

const createEntitySuccess = state => R.set(isEntityCreating, either.of(false), state)

const createEntityFailure = (state, action) => R.set(isEntityCreating, either.left(action.payload), state)

const addingRequest = state => R.set(isSynonymAdding, either.of(true), state)

const addingSuccess = state => R.set(isSynonymAdding, either.of(false), state)

const addingFailure = (state, action) => R.set(isSynonymAdding, either.left(action.payload), state)

const deleteSynonymFailure = (state, action) => {
  return R.set(isDeletingSynonym, either.left(action.payload), state)
}

const deleteSynonymRequest = state => {
  return R.set(isDeletingSynonym, either.right(true), state)
}

const deleteSynonymSuccess = state => {
  return R.set(isDeletingSynonym, either.right(false), state)
}

export default function(state = initialState, action) {
  switch (action.type) {
  case actions.SELECT_ENTITY:
    return selectEntity(state, action)
  case actions.UNSELECT_ENTITY:
    return unselectEntity(state)
  case actions.OPEN_CREATE_FORM:
    return openCreateForm(state)
  case actions.CLOSE_CREATE_FORM:
    return closeCreateForm(state)
  case actions.CREATE_REQUEST:
    return createEntityRequest(state)
  case actions.CREATE_SUCCESS:
    return createEntitySuccess(state)
  case actions.CREATE_FAILURE:
    return createEntityFailure(state, action)
  case synonym.actions.ADD_SYNONYM_REQUEST:
    return addingRequest(state)
  case synonym.actions.ADD_SYNONYM_SUCCESS:
    return addingSuccess(state)
  case synonym.actions.ADD_SYNONYM_FAILURE:
    return addingFailure(state, action)
  case synonym.actions.DELETE_SYNONYM_REQUEST:
    return deleteSynonymRequest(state)
  case synonym.actions.DELETE_SYNONYM_FAILURE:
    return deleteSynonymFailure(state, action)
  case synonym.actions.DELETE_SYNONYM_SUCCESS:
    return deleteSynonymSuccess(state)
  default:
    return state
  }
}
