import React, { useEffect } from 'react'
import { remoteData } from '@sbizeul/fp-flow'

import { renderNothing, renderServerFailure, renderLoader } from 'helpers/react'

import { Skill } from '../../types'
import SkillBuilder from './SkillBuilder'
import { DispatchFromProps, StateFromProps } from './types'

export type Props = StateFromProps & DispatchFromProps

const RemoteSkillbuilder = ({
  keywords,
  fetchKeywords,
  fetch,
  skill,
  skillId,
  isModified,
  createScenario,
  createQuestionContent,
  updateQuestionContent,
  removeQuestionContent,
  updateAnswerContent,
  removeScenario,
  updateQuestion,
  saveSkill
}: Props) => {
  useEffect(
    () => {
      fetch(skillId)
      fetchKeywords()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return remoteData.fold(
    {
      Success: (skill: Skill) => (
        <SkillBuilder
          keywords={keywords}
          skill={skill}
          createQuestionContent={createQuestionContent}
          updateQuestionContent={updateQuestionContent}
          removeQuestionContent={removeQuestionContent}
          createScenario={createScenario}
          updateAnswerContent={updateAnswerContent}
          removeScenario={removeScenario}
          updateQuestion={updateQuestion}
          saveSkill={saveSkill}
          skillId={skillId}
          isModified={isModified}
        />
      ),
      Failure: error => renderServerFailure({ message: error }),
      Loading: () => renderLoader('please wait...'),
      NotAsked: renderNothing
    },
    skill
  )
}

export default RemoteSkillbuilder
