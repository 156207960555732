import * as actions from './actions'
import * as saga from './saga'
import * as selectors from './selectors'
import reducer from './reducer'

export default {
  actions,
  reducer,
  saga,
  selectors
}
