import { connect } from 'react-redux'

import AuthForm from '../components/AuthForm'
import * as authActions from '../actions'

const mapDispatchToProps = {
  authenticate: authActions.authenticate
}

export default connect(null, mapDispatchToProps)(AuthForm)
