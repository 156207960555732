import React from 'react'
import PropTypes from 'prop-types'
import { Form, Label } from 'semantic-ui-react'

const FieldLabel = ({ error, warning }) => (
  <Label basic color={(error && 'red') || (warning && 'yellow')} pointing>
    {error}
  </Label>
)

FieldLabel.propTypes = {
  error: PropTypes.string,
  warning: PropTypes.string
}

FieldLabel.defaultProps = {
  error: undefined,
  warning: undefined
}

const InputField = ({ input, label, type, meta: { touched, error } }) => (
  <Form.Input
    width={10}
    // transparent
    {...input}
    placeholder={label}
    type={type}
    error={touched && error ? true : null}
    autoComplete='off'
  />
  // {/* {touched && (error || warning) && <FieldLabel error={error} warning={warning} />} */}
)

export default InputField
