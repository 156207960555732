import { put, takeEvery, takeLatest, select, call } from 'redux-saga/effects'
import { LOCATION_CHANGE, push } from 'connected-react-router'
import { maybe } from '@sbizeul/fp-flow'
import { toastr } from 'react-redux-toastr'

import api from 'services/tolkApi'
import apiLegacy from 'services/tolkApiLegacy'

import * as adminActions from './actions'
import * as adminSelectors from './selectors'
import user from '../user'

export function* updateUser(action) {
  try {
    const id = maybe.getOrElse(() => 'unknown', yield select(adminSelectors.getSelectedUserId))
    const data = { id, ...action.payload }

    yield call(apiLegacy.put, `/admin/user/${id}`, data)
    yield put(adminActions.submitUserSuccess())
    yield call(toastr.success, 'Congratulation', 'User modified')
    yield put(adminActions.navigateToUserList())
  } catch (err) {
    yield put(adminActions.submitUserFailure(err))
    yield call(toastr.error, 'Error', 'User Was Not Updated')
  }
}

export function* createUser(action) {
  try {
    yield call(api.post, '/admin/user', action.payload)
    yield put(adminActions.submitUserSuccess())
    yield call(toastr.success, 'Congratulation', 'New user Created Successfully')
    yield put(adminActions.navigateToUserList())
  } catch (err) {
    yield put(adminActions.submitUserFailure(err))
    yield call(toastr.error, 'Error', 'User Was Not Created')
  }
}

export function* submitUser(action) {
  const mode = yield select(adminSelectors.getMode)
  if (mode === 'create') {
    yield* createUser(action)
  } else {
    yield* updateUser(action)
  }
}

export function* deleteUser(action) {
  try {
    yield call(apiLegacy.delete, `admin/users/${action.payload.userId}`)
    yield put(adminActions.deleteUserSuccess())
    yield put(adminActions.navigateToUserList())
  } catch (error) {
    yield put(adminActions.deleteUserFailure(error))
  }
}

export function* navigateToUserList() {
  yield put(push('/admin/user'))
}

export function* matchRoute({ payload }) {
  const matchEdit = yield select(adminSelectors.matchEditRoute)
  const matchNew = yield select(adminSelectors.matchNewRoute)
  if (matchEdit != null) {
    yield put(adminActions.selectUser(matchEdit.params.id))
    yield put(adminActions.setMode('edit'))
    yield put(user.actions.fetchOne(matchEdit.params.id))
  } else if (matchNew != null) {
    yield put(adminActions.unselectUser())
    yield put(adminActions.setMode('create'))
  }
}

export function* root() {
  yield takeEvery(LOCATION_CHANGE, matchRoute)
  yield takeEvery(adminActions.NAVIGATE_TO_USER_LIST, navigateToUserList)
  yield takeLatest(adminActions.DELETE_USER_REQUEST, deleteUser)
  yield takeLatest(adminActions.SUBMIT_USER_REQUEST, submitUser)
}
