import React from 'react'
import ReactHighcharts from 'react-highcharts'
import moment from 'moment'
import defaultStyle from '../chartStyle'

const config = {
  ...defaultStyle,
  chart: {
    type: 'spline'
  },
  yAxis: {
    title: {
      text: null
    }
  },
  xAxis: {
    type: 'datetime'
  },
  plotOptions: {
    series: {
      connectNulls: false
    }
  }
}

export default function CustomAreaCharts({ chartId, data }) {
  const series = data.map(serie => ({
    animation: false,
    name: serie.name,
    data: serie.data.map(elem => [moment.utc(elem.date).valueOf(), elem.total])
  }))

  return <ReactHighcharts config={{ ...config, series }} domProps={{ id: chartId }} />
}
