import { connect } from 'react-redux'

import * as smartSkillsForm from '../components/form'
import axios from 'axios'

import bot from 'modules/bot'

import {
  botManagementBaseUrl,
  authManagementBaseUrl,
  cookieDomain
} from 'helpers/effects'

const botManagementExpressAxios = axios.create({
  baseURL: botManagementBaseUrl
})

const smartSkillsFormComponent = (): React.FC<smartSkillsForm.Properties> => smartSkillsForm.component(botManagementExpressAxios, document, authManagementBaseUrl, cookieDomain)
const SmartSkillsForm = smartSkillsFormComponent() 

const mapStateToProps = null

const mapDispatchToProps = {
  fetchPublishedStatus: bot.actions.fetchPublishStatus,
}

export default connect(mapStateToProps, mapDispatchToProps)(SmartSkillsForm)
