const FETCH_KEYWORDS_REQUEST = 'keyword/FETCH_KEYWORDS_REQUEST'
const FETCH_KEYWORDS_SUCCESS = 'keyword/FETCH_KEYWORDS_SUCCESS'
const FETCH_KEYWORDS_FAILURE = 'keyword/FETCH_KEYWORDS_FAILURE'

const FETCH_ONE_REQUEST = 'keyword/FETCH_ONE_REQUEST'
const FETCH_ONE_SUCCESS = 'keyword/FETCH_ONE_SUCCESS'
const FETCH_ONE_FAILURE = 'keyword/FETCH_ONE_FAILURE'

const CREATE_REQUEST = 'keyword/CREATE_REQUEST'
const CREATE_SUCCESS = 'keyword/CREATE_SUCCESS'
const CREATE_FAILURE = 'keyword/CREATE_FAILURE'

const UPDATE_REQUEST = 'keyword/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'keyword/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'keyword/UPDATE_FAILURE'

const SELECT_KEYWORD = 'keyword/SELECT_KEYWORD'
const UNSELECT_KEYWORD = 'keyword/UNSELECT_KEYWORD'

const OPEN_CREATE_FORM = 'keyword/OPEN_CREATE_FORM'
const CLOSE_CREATE_FORM = 'keyword/CLOSE_CREATE_FORM'

const fetchKeywordsRequest = () => {
  return {
    type: FETCH_KEYWORDS_REQUEST
  }
}

const fetchKeywordsSuccess = keywords => {
  return {
    type: FETCH_KEYWORDS_SUCCESS,
    payload: keywords
  }
}

const fetchKeywordsFailure = error => {
  return {
    type: FETCH_KEYWORDS_FAILURE,
    payload: error
  }
}

const fetchOneRequest = keywordId => {
  return {
    type: FETCH_ONE_REQUEST,
    payload: keywordId
  }
}

const fetchOneSuccess = keyword => {
  return {
    type: FETCH_ONE_SUCCESS,
    payload: keyword
  }
}

const fetchOneFailure = (keywordId, error) => {
  return {
    type: FETCH_ONE_FAILURE,
    payload: {
      keywordId,
      error
    }
  }
}

const create = shortKeyword => {
  return {
    type: CREATE_REQUEST,
    payload: shortKeyword
  }
}

const createSuccess = keyword => {
  return {
    type: CREATE_SUCCESS,
    payload: keyword
  }
}

const createFailure = error => {
  return {
    type: CREATE_FAILURE,
    payload: error
  }
}

const update = keyword => {
  return {
    type: UPDATE_REQUEST,
    payload: keyword
  }
}

const updateSuccess = keyword => {
  return {
    type: UPDATE_SUCCESS,
    payload: keyword
  }
}

const updateFailure = (keywordId, error) => {
  return {
    type: UPDATE_FAILURE,
    payload: {
      keywordId,
      error
    }
  }
}

const selectKeyword = id => {
  return {
    type: SELECT_KEYWORD,
    payload: id
  }
}

const unselectKeyword = () => {
  return {
    type: UNSELECT_KEYWORD
  }
}

const openCreateForm = () => {
  return {
    type: OPEN_CREATE_FORM
  }
}

const closeCreateForm = () => {
  return {
    type: CLOSE_CREATE_FORM
  }
}

export {
  FETCH_KEYWORDS_REQUEST,
  FETCH_KEYWORDS_SUCCESS,
  FETCH_KEYWORDS_FAILURE,
  fetchKeywordsRequest,
  fetchKeywordsSuccess,
  fetchKeywordsFailure,
  FETCH_ONE_REQUEST,
  FETCH_ONE_SUCCESS,
  FETCH_ONE_FAILURE,
  fetchOneRequest,
  fetchOneSuccess,
  fetchOneFailure,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  CREATE_FAILURE,
  create,
  createSuccess,
  createFailure,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,
  update,
  updateSuccess,
  updateFailure,
  SELECT_KEYWORD,
  UNSELECT_KEYWORD,
  selectKeyword,
  unselectKeyword,
  OPEN_CREATE_FORM,
  CLOSE_CREATE_FORM,
  openCreateForm,
  closeCreateForm
}
