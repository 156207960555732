import axios from 'axios'
import { maybe } from '@sbizeul/fp-flow'

import formatFilters from '../../../_shared/formatFilters'
import actions from '../actions'
import isResValid from '../../../_shared/isResValid'
import bot from '../../../../modules/bot'
import { legacyApiUrl } from 'helpers/effects'

const fetchIntents = rawFilters => (dispatch, getState) => {
  const { channelId, start, end, period } = formatFilters(rawFilters)
  const botId = bot.selectors.getSelectedId(getState())

  dispatch(actions.fetchIntentsRequest())
  return axios({
    url: `${legacyApiUrl}/analytics/${maybe.get(botId)}/${channelId}/intents`,
    params: {
      start,
      end,
      period
    },
    method: 'get',
    responseType: 'json'
  })
    .then(response => {
      if (isResValid(response)) {
        let formatedData = {}
        if (response.data.result.data) {
          formatedData = response.data.result.data.map(elem => ({
            name: elem.intent.toString(),
            nb_users: elem.nb_users,
            value: elem.total
          }))
        }

        const formatedResponse = {
          data: formatedData,
          count: response.data.result.count
        }
        dispatch(actions.fetchIntentsSuccess(formatedResponse))
      } else {
        dispatch(actions.fetchIntentsFailure())
      }
    })
    .catch(err => {
      dispatch(actions.fetchIntentsFailure(err))
    })
}

export default fetchIntents
