import React from 'react'

import { renderDotLoader } from 'helpers/react'

import './styles.css'

const DeletingSectionIndicator = () => (
  <div className='QaQuestionPage-section-questionList'>
    <div className='QaQuestionPage-section-deleting'>
      {renderDotLoader('Deleting section')}
    </div>
  </div>
)

export {
  DeletingSectionIndicator
}
