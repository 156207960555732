import { updateWebchat } from 'modules/bot/actions'
import * as React from 'react'
import { Radio } from 'semantic-ui-react'
import * as R from 'ramda'

import { UpdateWebchatParams, Webchat } from '../../types'
import './styles.css'

type Props = Readonly<{
  webchat: Webchat
  isModified: boolean

  updateWebchat: UpdateWebchatParams
}>

const Component: React.FC<Props> = ({webchat, updateWebchat}: Props) =>
    <Radio toggle label={(webchat.active || typeof webchat.active === 'undefined') ? 'Active' : 'Disabled'} defaultChecked={(webchat.active || typeof webchat.active === 'undefined')} onChange={(event, data) => updateWebchat(R.set(R.lensPath(['active']), data.checked, webchat))} />



export { Component }
