import React from 'react'
import UploadFile from '../UploadFile'

const ElementImage = ({
  settings,
  url,
  temporaryUrl,
  saveImage,
  deleteImage,
  changeImage,
  forCarousel,
  canEdit
}) => (
  <UploadFile
    {...settings.media}
    uploadType='image'
    forCarousel={forCarousel}
    url={url}
    temporaryUrl={temporaryUrl}
    changeFile={changeImage}
    saveFile={saveImage}
    deleteFile={deleteImage}
    canEdit={canEdit}
  />
)

export default ElementImage
