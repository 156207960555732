import { createSelector } from 'reselect'
import { maybe } from '@sbizeul/fp-flow'

const getUiState = state => state.ui.answer

const getAnswer = createSelector(getUiState, state => state.answer)

const getAnswerContent = createSelector(
  getAnswer,
  maybe.fold(
    () => [],
    answer => answer.content
  )
)

const isAnswerValid = createSelector(getUiState, state => state.isModified && state.isValid)

const getIsModified = createSelector(getUiState, state => state.isModified)

const getImagesUrl = createSelector(getUiState, state => state.imagesById)

const getSimplifiedSkills = createSelector(getUiState, state => state.simplifiedSkills)

const getSimplifiedTags = createSelector(getUiState, state => state.simplifiedTags)

export { getAnswer, getAnswerContent, getImagesUrl, getIsModified, getSimplifiedSkills, getSimplifiedTags, isAnswerValid }
