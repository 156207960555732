import React, { useState, useEffect } from 'react'
import { MdAdd } from 'react-icons/md'
import { Dropdown, DropdownProps, Icon } from 'semantic-ui-react'
import * as R from 'ramda'
import { v4 as uuid } from 'uuid'

import Button from 'components/Button'
import { Id } from 'types'

import { Button as ButtonSkill, Question, QuestionAndButtonIDs } from '../../../types'

import './style.css'

type Scenario = ReadonlyArray<QuestionAndButtonIDs>

type CreateScenarioProps = {
  questions: ReadonlyArray<Question>

  onScenarioAdded: (scenarios: Scenario) => unknown
}

type DropdownFormat = Readonly<{
  key: Id
  text: string
  value: string
}>

const EMPTY_VALUE = '-'
const EMPTY_TEXT = '-'

export const toDropdownFormat: (buttons: ReadonlyArray<ButtonSkill>) => DropdownFormat[] = R.pipe(
  R.map((button: ButtonSkill) => ({
    key: button.id,
    text: button.label,
    value: button.id
  })),
  R.prepend({ key: uuid(), text: EMPTY_TEXT, value: EMPTY_VALUE })
)

const questionAndButtonIDsFromQuestion: (question: Question) => QuestionAndButtonIDs =
  question => ({ questionID: question.id,buttonID: EMPTY_VALUE })

const defaultScenarioFromQuestions: (questions: ReadonlyArray<Question>) => Scenario =
  R.map(questionAndButtonIDsFromQuestion)

const validScenario: (scenario: Scenario) => Scenario =
  scenario => R.reject(R.propEq('buttonID', EMPTY_VALUE), scenario)

const plusIcon = <MdAdd key={uuid()} className='CreateScenario-icon' size={20} />

const CreateScenario = ({ questions, onScenarioAdded }: CreateScenarioProps) => {
  const [selectedScenario, setSelectedScenario] = useState(defaultScenarioFromQuestions(questions))

  useEffect(() => setSelectedScenario(defaultScenarioFromQuestions(questions)), [questions])

  const handleDropdownChange: (index: number) => (question: Question) => (event: React.SyntheticEvent, data: DropdownProps) => unknown =
    index => question => (_, data) => R.compose(
      setSelectedScenario, R.update(index, { questionID: question.id, buttonID: data.value as string })
    )(selectedScenario)

  const handleButtonClick = () => onScenarioAdded(validScenario(selectedScenario))

  const safeRender = () => (
    <>
      <p className='CreateScenario-title'>NEW SCENARIO</p>
      {R.pipe(
        R.addIndex<Question, React.ReactNode>(R.map)((question, index) =>
          <Dropdown
            key={question.id}
            className='CreateScenario-dropdown'
            scrolling
            selection
            placeholder='Select a quick reply...'
            options={toDropdownFormat(question.buttons)}
            onChange={handleDropdownChange(index)(question)}
          />),
          // @ts-ignore Fix issue with @sbizeul/fp-flow
        arr => arr.reduce((prev, curr) => [prev, plusIcon, curr])
        // @ts-ignore Fix issue with @sbizeul/fp-flow
      )(questions)}
      <Button
        className='CreateScenario-validation'
        onClick={handleButtonClick}
      >
        <Icon name='add' className='CreateScenario-add-icon'/>ADD
      </Button>
    </>
  )

  return (
    <div className='CreateScenario'>
      {R.not(R.isEmpty(questions)) && safeRender()}
    </div>
  )
}

export {
  CreateScenario
}
