import React from 'react'

import axios from 'axios'

import * as connectorsConfig from '@tolk-ai/bot-management-react-connectors-config'
import * as connectorsConfigShopify from '@tolk-ai/connectors-config-shopify'
import * as shopify from '@tolk-ai/account-management-shopify'
import * as authenticatedRequest from 'services/authenticated-request'
import { accountManagementBaseUrl, shopifyAppName } from 'helpers/effects'

import './index.css'

import '@tolk-ai/bot-management-react-connectors-config/dist/bot-management-react-connectors-config.esm.css'
import BackButton from 'components/BackButton'

const axiosaccountManagementExpressBaseUrl = axios.create({
  baseURL: accountManagementBaseUrl
})

const ShopifyLogin = connectorsConfig.shopifyLogin.container(
  shopify.axiosShopify.getOneShopInfosByBotId(
    axiosaccountManagementExpressBaseUrl
  ),
  connectorsConfigShopify.axiosConnectorConfigShopify.connectOneBotToShopInfo(
    authenticatedRequest.botManagement
  ),
  window,
  shopifyAppName
)

const Shopify = () => {
  const botId = localStorage.getItem('botId') || ''

  return (
    <>
      <div className="pageContainer pageContainer--shopify">
        <section className="channelsettings">
          <header className="channelsettings__header">
            <BackButton label={`Shopify Settings`} path="/channels" />
          </header>
          <ShopifyLogin
            botId={botId}
          />
        </section>
      </div>
    </>
  )
}

export default Shopify
