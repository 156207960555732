import types from './types'

const fetchEscaladesHeatRequest = () => ({
  type: types.FETCH_ESCALADESHEAT_REQUEST
})

const fetchEscaladesHeatSuccess = payload => ({
  type: types.FETCH_ESCALADESHEAT_SUCCESS,
  payload
})

const fetchEscaladesHeatFailure = errors => ({
  type: types.FETCH_ESCALADESHEAT_NO_DATA,
  errors
})

export default {
  fetchEscaladesHeatRequest,
  fetchEscaladesHeatSuccess,
  fetchEscaladesHeatFailure
}
