import React from 'react'
import ImageDrop from 'react-dropzone'
import * as R from 'ramda'
import { remoteData } from '@sbizeul/fp-flow'

import classNames from 'classnames'
import { MdClose, MdImage } from 'react-icons/md'

import { renderServerFailure } from 'helpers/react'
import { renderInlineLoader, renderFailure, renderNothing } from 'helpers/react'

import './style.css'

class ImageContentEditor extends React.Component {
  state = {
    isValid: false,
    isReject: false
  }

  handleUploadFile = files => {
    const acceptedFile = files[0]
    if (!acceptedFile) return
    this.setState({ isValid: true })
    this.setState({ isReject: false })
    this.props.uploadImage({ id: this.props.imageContent.id, file: acceptedFile })
  }

  handleUpdateImageContent = imageUrl => {
    const updatedContent = R.set(this.props.pathToImage, imageUrl, this.props.imageContent)
    this.props.updateContent({ content: updatedContent })
  }

  onDropRejected = files => {
    const rejectedFile = files[0]
    if (!rejectedFile) return
    this.setState({ isValid: false })
    this.setState({ isReject: true })
  }

  handleClickDelete = () => this.props.removeContent({ id: R.view(this.props.pathToId, this.props.imageContent) })

  componentDidUpdate(prevProps) {
    remoteData.fold({
      Success: imageUrl => {
        const previousImageUrl = R.view(this.props.pathToImage, this.props.imageContent)
        if (!R.isEmpty(imageUrl) && imageUrl !== previousImageUrl) {
          this.handleUpdateImageContent(imageUrl)
        }
      },
      NotAsked: () => null,
      Loading: () => null,
      Failure: null
    }, this.props.imageUrl)
  }

  render() {
    const isNotEmpty = str => str.length > 0

    return (
      <div className='imageContentEditor-container'>
        <MdImage size='25' className='imageContentEditor-image' />
        <MdClose
          data-testid='remove-content'
          size='20'
          className='imageContentEditor-icon'
          onClick={this.handleClickDelete}
        />
        <ImageDrop
          onDrop={this.handleUploadFile}
          onDropRejected={this.onDropRejected}
          maxSize={5000000}
          accept='image/*'
        >
          {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
            <div
              {...getRootProps()}
              className={classNames('imageContentEditor-dropzone', {
                'imageContentEditor-dropzone-active': isDragActive,
                'imageContentEditor-dropzone-forbidden': isDragReject
              })}
              style={{
                backgroundImage: `url(${remoteData.getOrElse(() => '', this.props.imageUrl)})`
              }}
            >
              <input {...getInputProps()} multiple={false} />
              {!isNotEmpty(remoteData.getOrElse(() => '', this.props.imageUrl)) && (
                <p className='imageContentEditor-dropzone-placeholder'>
                  Try dropping image here, or click to select image to upload. Image must be JPG, JPEG, PNG with a
                  maximum size of 5Mo
                </p>
              )}
              {isDragActive && !isDragReject && <p>Drop it here</p>}
              {isDragReject && <div>Only image file is accepted</div>}
              {remoteData.fold({
                Success: () => renderNothing,
                NotAsked: () => renderNothing,
                Loading: () => renderInlineLoader('Please wait...'),
                Failure: renderFailure
              })(this.props.imageUrl)}
            </div>
          )}
        </ImageDrop>
        {this.state.isReject && renderServerFailure({ message: 'The max image size is 5MB' })}
      </div>
    )
  }
}

export default ImageContentEditor
