import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'
import { reducer as toastr } from 'react-redux-toastr'
import { connectRouter } from 'connected-react-router'

import reducers from '../redux'

const rootReducer = history =>
  combineReducers({
    ...reducers,
    form,
    toastr,
    router: connectRouter(history)
  })

export default rootReducer
