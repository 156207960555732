import React from 'react'
import { Field } from 'redux-form'
import { Header } from 'semantic-ui-react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { maybe } from '@sbizeul/fp-flow'

import InputField from '../../../components/InputField'
import SelectField from '../../../components/SelectField'
import CustomForm from './CustomForm'
import { getRessources } from '../../../../redux/selectors'
import operations from '../../../../redux/ressources/operations'
import bot from '../../../../modules/bot'

const required = value => (!_.trim(value) ? 'Required' : undefined)

const getComputedFields = (formType = '', forms) => {
  const getFields = forms.filter(form => form.name === formType)
  if (getFields.length === 0) {
    return []
  }
  return getFields[0].fields.map((field, index) => {
    if (field.type === 'select') {
      return (
        <Field
          key={index}
          type='select'
          name={field.name}
          label={field.name}
          validate={field.required ? required : null}
          required={field.required || false}
          options={field.values.map((value, ind) => ({ key: ind, text: value, value }))}
          component={SelectField}
        />
      )
    } else if (field.type === 'input') {
      return (
        <Field
          key={index}
          type='text'
          name={field.name}
          validate={field.required ? required : null}
          required={field.required || false}
          label={field.name}
          component={InputField}
        />
      )
    }
    return null
  })
}

class EditRessource extends React.PureComponent {
  state = {}
  componentWillMount() {
    const { ressources, formType, ressourceId, values } = this.props

    const forms = ressources.forms.map(form => (
      <CustomForm
        key={form.name}
        onSubmit={this.onSubmit}
        fields={getComputedFields(form.name, ressources.forms)}
        form={`form-${ressourceId}`}
        initialValues={{ formType: form.name, ...values }}
        loading={this.props.isPosting}
      />
    ))

    this.form = forms.filter(form => form.key === formType)
  }

  onSubmit = data => {
    const formatedData = {
      id: this.props.ressourceId,
      bot_id: maybe.getOrElse(() => 'unknownBot', this.props.botId),
      ressources: {
        ...data
      }
    }
    this.props.editRessource(formatedData)
  }

  render() {
    return (
      <div style={{ margin: '20px' }}>
        <Header as='h3'>Modifier le formulaire</Header>
        {this.form}
        <br />
      </div>
    )
  }
}

EditRessource.defaultProps = {
  ressources: {
    forms: []
  }
}

const mapStateToProps = state => ({
  isPosting: state.ressources.get('isPosting'),
  ressources: getRessources(state),
  botId: bot.selectors.getSelectedId(state)
})

const connectedEditRessource = connect(mapStateToProps, operations)(EditRessource)

export default connectedEditRessource
