
import React, * as react from 'react';
import * as uiLibrary from '@tolk-ai/ui-library-react'
import '@tolk-ai/ui-library-react/dist/ui-library-react.esm.css'

import { maybe } from '@sbizeul/fp-flow'

import './index.css'

type Properties = {
  state: {botId: string, botName: string}
  isLoggedIn: boolean
  botName: string
  webchatUrl: string
  bots: {id: string; name: string}[]
  handleOnBotNameClick: any
}

const Component: react.FC<Properties> = ({isLoggedIn, bots, botName, webchatUrl, handleOnBotNameClick, state}: Properties) => {
 if(isLoggedIn) {
    return (            
      <>
        <uiLibrary.Dropdown defaultText={state.botName} value={botName} className='topbar-left-content__dropdown-bot' listElementClassName="topbar-left-content__dropdown-bot-list">
          {bots.map((bot)  => {
            return (
              <uiLibrary.DropdownElement
                key={bot.id}
                onClick={() => handleOnBotNameClick(bot.id)}
                className="dropdown-bot__element"
              >
                {bot.name}
              </uiLibrary.DropdownElement>
            )
          })}
        </uiLibrary.Dropdown>
        {botName === 'N/A' && (
          <a href='/' rel="noopener noreferrer" target="_self" className='topbar-left-content__bot-link'>
            <span className='bot-link__label'>Test my bot</span>
            <uiLibrary.Icon icon="ExternalLinkIcon" className="bot-link__icon" />
          </a>
        )}
 
        {botName !== 'N/A' && maybe.fold(
          () => null,
          /* @ts-ignore */
          url => {
            return(
            /* @ts-ignore */
            <a href={url} rel="noopener noreferrer" target="_blank" className='topbar-left-content__bot-link'>
              <span className='bot-link__label'>Test my bot</span>
              <uiLibrary.Icon icon="ExternalLinkIcon" className="bot-link__icon" />
            </a>
          )},
          webchatUrl
        )}
      </>
    )
  }
  
  return(<></>)

};

export {Component}