import { call, put, takeLatest, select } from 'redux-saga/effects'
import { maybe } from '@sbizeul/fp-flow'
import { push } from 'connected-react-router'

import Api from 'services/tolkApiLegacy'
import * as answerActions from './actions'
import * as answerSelectors from './selectors'
import intentsActions from '../../redux/intents/operations'
import intent from '../intent'
import bot from '../bot'
import * as object from '../../helpers/object'

export function* saveCustom(action) {
  try {
    const botId = yield select(bot.selectors.getSelectedId)
    const {
      params: { slug }
    } = yield select(answerSelectors.matchAnswersRoute)
    const answers = action.payload.map(object.removeId)
    yield call(Api.put, `/bot/${maybe.get(botId)}/intent/${slug}/answer`, { data: answers })
    yield put(answerActions.saveAnswersSuccess())
    yield put(push(`/intents/edit/${slug}`))
  } catch (error) {
    yield put(answerActions.saveAnswersFailure(error))
  }
}

export function* deleteCustom(action) {
  try {
    const botId = yield select(bot.selectors.getSelectedId)
    yield call(Api.delete, `/bot/${maybe.get(botId)}/intent/${action.payload}/answer`)
    yield put(answerActions.deleteAnswersSuccess())
    yield put(intentsActions.fetchIntentData(maybe.get(botId), action.payload))
  } catch (error) {
    yield put(answerActions.deleteAnswersFailure(error))
  }
}

export function* setCustom(action) {
  const { answers, slug } = action.payload
  const params = window.location.href.split('/')
  if (params.includes('answers') && params.includes('edit')) {
    yield put(answerActions.setCustom(answers, slug))
  }
}

export function* root() {
  yield takeLatest(answerActions.SAVE_ANSWERS_REQUEST, saveCustom)
  yield takeLatest(answerActions.DELETE_ANSWERS_REQUEST, deleteCustom)
  yield takeLatest(intent.actions.FETCH_ONE_SUCCESS, setCustom)
}
