import styled, { css } from 'styled-components'
import { initButton } from '../../style/globalStyles'
import { cssForError } from '../ElementText/styles'

const flexStartStartColumn = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

const flexStartCenterColumn = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

const CarouselCardActions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  height: 20px;
  * + * {
    margin-left: ${({ theme }) => theme.margin.left.carouselCard / 2}px;
  }
  > * {
    margin-top: 0 !important;
  }
`

const ButtonContainer = styled.div`
  ${initButton}
  ${flexStartCenterColumn}
  position: relative;
  width: 100%;
  cursor: pointer;
  span {
    pointer-events: none;
  }
  :not(:last-of-type) {
    margin-bottom: 15px;
  }
  ${({ error }) => error && cssForError}
`

const ButtonTitle = styled.span`
  position: relative;
  font-weight: 600;
  font-size: 1.2em;
  ${({ asPlaceholder }) => asPlaceholder && 'opacity: 0.5;'}
`

const ButtonValue = styled.span`
  position: relative;
  font-size: 0.8em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: calc(${({ theme }) => theme.width.carouselCard}px - 2rem);
  svg {
    width: 0.9em;
    height: 0.9em;
    margin-right: 0.5em;
  }
  ${({ asPlaceholder }) => asPlaceholder && 'opacity: 0.5;'}
`

const ButtonEditContainer = styled.div`
  position: fixed;
  width: ${({ theme }) => theme.width.buttonEditContainer}px;
  min-height: ${({ theme }) => theme.height.buttonEditContainer}px;
  padding: 1em;

  box-shadow: ${({ theme }) => theme.boxShadow.default};
  background: ${({ theme }) => theme.color.white};

  border-radius: ${({ theme }) => theme.borderRadius.buttonEditContainer}px;
  border: 1px solid ${({ theme }) => theme.color.grey};

  color: ${({ theme }) => theme.color.defaultColor};
  font-weight: 300;
  font-family: Open Sans, sans-serif;
  &::after {
    content: '';
    height: 20px;
    border: 20px solid transparent;
    border-left: 20px solid ${({ theme }) => theme.color.white};
    border-right: 0px solid transparent;
    /* width: 10px; */
    position: absolute;
    right: -20px;
    margin-top: auto;
    margin-bottom: auto;
    top: 0;
    bottom: 0;
  }
`

const ButtonEditSection = styled.span`
  display: inline-block;
  font-weight: 600;
  font-size: 1.2em;
  &:not(:first-of-type) {
    margin-top: 15px;
  }
  svg {
    width: 0.9em;
    height: 0.9em;
    margin-right: 0.5em;
  }
`

const ButtonsContainer = styled.div`
  ${flexStartCenterColumn}
  width: 100%;
  margin: 15px 0;
`

const AddButtonContainer = styled.button`
  ${initButton}
  width: 100%;

  font-weight: 600;
  text-transform: uppercase;

  opacity: 0.5;
  transition: opacity 250ms ease-in-out;
  &:hover {
    opacity: 1;
  }
`

const CarouselCardContainer = styled.div`
  ${flexStartCenterColumn}
`

const CarouselCardContent = styled.div`
  ${flexStartStartColumn}
  padding: 1em;
  width: 100%;
`

const CarouselCardStyled = styled.div`
  ${flexStartStartColumn}
  flex-shrink: 0;
  height: 100%;
  width: ${({ theme }) => theme.width.carouselCard}px;

  background: ${({ theme }) => theme.color.white};

  border: 1px solid ${({ theme }) => theme.color.grey};

  overflow: hidden;
`

const CarouselCardsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  /* max-height: ${({ theme }) => theme.height.carousel}px; */
  flex-grow: 0;
  flex-shrink: 1;
  overflow-x: auto;
  /* Carousel cards styling depending on their position */
  ${CarouselCardContainer} + ${CarouselCardContainer} {
    margin-left: ${({ theme }) => theme.margin.left.carouselCard}px;
  }

  ${CarouselCardContainer}:first-of-type {
    ${CarouselCardStyled} {
      border-top-left-radius: ${({ theme }) => theme.borderRadius.carouselCard}px;
      border-bottom-left-radius: ${({ theme }) => theme.borderRadius.carouselCard}px;
    }
  }

  ${CarouselCardContainer}:last-of-type {
    ${CarouselCardStyled} {
      border-top-right-radius: ${({ theme }) => theme.borderRadius.carouselCard}px;
      border-bottom-right-radius: ${({ theme }) => theme.borderRadius.carouselCard}px;
    }
  }
`
const CarouselContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-shrink: 0;
  max-width: 110%;
  overflow-x: hidden;
`

const AddCardButtonStyled = styled.button`
  ${initButton}
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  margin-top: auto;
  margin-bottom: auto;

  height: ${({ theme }) => theme.height.carouselAddCardButton}px;
  width: ${({ theme }) => theme.width.carouselAddCardButton}px;
  margin-left: ${({ theme }) => theme.margin.left.carouselCard}px;

  font-size: 50px;
  line-height: 0.5em;

  background: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.grey};
  border-radius: 100%;
`

export {
  CarouselCardActions,
  CarouselCardsContainer,
  ButtonsContainer,
  ButtonContainer,
  ButtonTitle,
  ButtonValue,
  ButtonEditContainer,
  ButtonEditSection,
  AddButtonContainer,
  CarouselCardContent,
  CarouselCardStyled,
  CarouselContainer,
  AddCardButtonStyled,
  CarouselCardContainer
}
