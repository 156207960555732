import React from 'react'
import StepZilla from 'react-stepzilla'
import { Progress } from 'semantic-ui-react'
import classNames from 'classnames'
import * as R from 'ramda'
import { remoteData } from '@sbizeul/fp-flow'

import { IsoLanguage } from 'helpers/language'
import { useEffectOnce } from 'helpers/react'
import WebchatPreview from 'components/WebchatPreview'

import { UseCase } from '../../types'
import { atLastStep, toPercent } from '../../models'
import { CreationFormProps } from './types'
import Customize from './formComponents/Customize'
import Naming from './formComponents/Naming'
import Interact from './formComponents/Interact'

import './styles.css'

export const LanguagesForDropdown = [
  { text: 'French', value: 'fr_FR' },
  { text: 'English', value: 'en_GB' },
  { text: 'German', value: 'de_DE' },
  { text: 'Italian', value: 'it_IT' },
  { text: 'Lithuanian', value: 'lt_LT' },
  { text: 'Spanish', value: 'es_ES' },
  { text: 'Dutch', value: 'nl_NL' },
  { text: 'Portuguese', value: 'pt_PT' }
]

const availableUseCasesForDropdown = (availableUseCases: ReadonlyArray<UseCase>) =>
  R.map(useCase => ({ text: useCase, value: useCase }), availableUseCases)

const CreationForm = ({
  createBot,
  updateBotName,
  updateGreetings,
  updateLanguage,
  updateTheme,
  updateAvatar,
  updateUseCase,
  fetchUseCases,
  webchat,
  botName,
  language,
  greetings,
  useCase,
  availableUseCases
}: CreationFormProps) => {
  useEffectOnce(() => { fetchUseCases() })

  const [currentStepInfo, setCurrentStepInfo] = React.useState({ currentStep: 0, percent: 25 })

  const { theme, avatar } = webchat

  const steps = [
    {
      name: 'Customize',
      component: (
        <Customize
          updateTheme={updateTheme}
          updateAvatar={updateAvatar}
          selectedAvatar={avatar}
          selectedTheme={theme}
        />
      )
    },
    {
      name: 'Naming',
      component: (
        <Naming
          botName={botName}
          availableLanguages={LanguagesForDropdown}
          language={language}
          availableUseCases={remoteData.map(availableUseCasesForDropdown, availableUseCases)}
          useCase={useCase}
          updateBotName={updateBotName}
          updateLanguage={lang => updateLanguage(lang as IsoLanguage)}
          updateUseCase={updateUseCase}
        />
      )
    },
    {
      name: 'Interact',
      component: (
        <Interact updateGreetings={updateGreetings} greetings={greetings} botName={botName} language={language}/>
      )
    }
  ]

  return (
    <div className='step-progress'>
      <Progress percent={currentStepInfo.percent} active/>
      <div className='bot-creation-form-content'>
        <StepZilla
          onStepChange={R.compose(setCurrentStepInfo, setCurrentStep(steps))}
          showSteps={false}
          steps={steps}
          nextButtonText='Continue'
          nextButtonCls={classNames('bot-creation-form-button-continue', {
            'form-button-disabled': currentStepInfo.currentStep === 1 && botName === ''
          })} // rajouter une classe pour disable le bouton si le state de botname est vide
          backButtonText='Back'
          backButtonCls={classNames('bot-creation-form-button-back', 'bot-creation-form-button-cancel', {
            'bot-creation-form-button-back-last': atLastStep(currentStepInfo.currentStep, steps.length)
          })}
        />
        <div className='bot-creation-form-content--preview'>
          <WebchatPreview {...webchat} />
        </div>
      </div>
      {atLastStep(currentStepInfo.currentStep, steps.length) && (
        <button
          className='bot-creation-form-button-create bot-creation-form-button-continue'
          disabled={greetings === ''}
          onClick={createBot}
        >
          Finish
        </button>
      )}
    </div>
  )
}

type Step = Readonly<{
  name: string
  component: JSX.Element
}>

type StepInfo = Readonly<{
  currentStep: number
  percent: number
}>

const setCurrentStep: (steps: ReadonlyArray<Step>) => (step: number) => StepInfo = steps => step => ({
  currentStep: step,
  percent: toPercent(step + 1, steps.length + 1)
})

export default CreationForm
