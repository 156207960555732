import { Maybe } from '@sbizeul/fp-flow'

import { IsoLanguage } from 'helpers/language'
import { Id, ServerFailure } from 'types'

import {
  emptyButtonContent,
  emptyFormContent,
  emptyImageContent,
  emptyQRAnswer,
  emptyTagAgentContent,
  emptyTextContent
} from './models.typed'
import {
  Answer,
  FetchOne, FetchOneSuccess, FetchOneFailure,
  SaveOneRequest, SaveOneParam, SaveOneSuccess, SaveOneFailure,
  CreateQRAnswer, CreateTagAgentContent, CreateTextContent, CreateImageContent,
  CreateSkillContent,
  CreateButtonContent,
  UpdateContent,
  ChangeFeedbackStatus,
  UpdateQRAnswer,
  UpdateQRAnswerParam,
  RemoveContentParam,
  RemoveContent,
  UploadImageRequest,
  UploadImageRequestPayload,
  UploadImageSuccess,
  UploadImageSuccessPayload,
  UploadImageFailure,
  UploadImageFailurePayload,
  FetchAllSimplifiedSkillsFailure,
  FetchAllSimplifiedSkillsSuccess,
  SimplifiedSkill,
  FetchAllSimplifiedSkills,
  FetchAllSimplifiedTagsFailure,
  FetchAllSimplifiedTagsSuccess,
  SimplifiedTag,
  FetchAllSimplifiedTags
} from './types'

export const FETCH_ONE_REQUEST = 'answer/FETCH_ONE_REQUEST'
export const FETCH_ONE_SUCCESS = 'answer/FETCH_ONE_SUCCESS'
export const FETCH_ONE_FAILURE = 'answer/FETCH_ONE_FAILURE'

export const SAVE_ONE_REQUEST = 'answer/SAVE_ONE_REQUEST'
export const SAVE_ONE_SUCCESS = 'answer/SAVE_ONE_SUCCESS'
export const SAVE_ONE_FAILURE = 'answer/SAVE_ONE_FAILURE'

export const UPLOAD_IMAGE_REQUEST = 'answer/UPLOAD_IMAGE_REQUEST'
export const UPLOAD_IMAGE_SUCCESS = 'answer/UPLOAD_IMAGE_SUCCESS'
export const UPLOAD_IMAGE_FAILURE = 'answer/UPLOAD_IMAGE_FAILURE'

export const CREATE_QR_ANSWER = 'answer/CREATE_QR_ANSWER'
export const CREATE_TAG_CONTENT = 'answer/CREATE_TAG_CONTENT'
export const CREATE_TEXT_CONTENT = 'answer/CREATE_TEXT_CONTENT'
export const CREATE_IMAGE_CONTENT = 'answer/CREATE_IMAGE_CONTENT'
export const CREATE_SKILL_CONTENT = 'answer/CREATE_SKILL_CONTENT'
export const CREATE_BUTTON_CONTENT = 'answer/CREATE_BUTTON_CONTENT'

export const UPDATE_CONTENT = 'answer/UPDATE_CONTENT'
export const REMOVE_CONTENT = 'answer/REMOVE_CONTENT'
export const CHANGE_FEEDBACK_STATUS = 'answer/CHANGE_FEEDBACK_STATUS'

export const UPDATE_QR_ANSWER = 'answer/UPDATE_QR_ANSWER'

export const GO_TO_SKILLS = 'answer/GO_TO_SKILLS'

export const FETCH_ALL_SIMPLIFIED_SKILLS_REQUEST = 'answer/FETCH_ALL_SIMPLIFIED_SKILLS_REQUEST'
export const FETCH_ALL_SIMPLIFIED_SKILLS_SUCCESS = 'answer/FETCH_ALL_SIMPLIFIED_SKILLS_SUCCESS'
export const FETCH_ALL_SIMPLIFIED_SKILLS_FAILURE = 'answer/FETCH_ALL_SIMPLIFIED_SKILLS_FAILURE'

export const FETCH_ALL_SIMPLIFIED_TAGS_REQUEST = 'answer/FETCH_ALL_SIMPLIFIED_TAGS_REQUEST'
export const FETCH_ALL_SIMPLIFIED_TAGS_SUCCESS = 'answer/FETCH_ALL_SIMPLIFIED_TAGS_SUCCESS'
export const FETCH_ALL_SIMPLIFIED_TAGS_FAILURE = 'answer/FETCH_ALL_SIMPLIFIED_TAGS_FAILURE'

export const fetchOne: (id: Id) => FetchOne = id => ({
  type: FETCH_ONE_REQUEST,
  payload: id
})

export const fetchOneSuccess: (answer: Maybe<Answer>) => FetchOneSuccess = answer => ({
  type: FETCH_ONE_SUCCESS,
  payload: answer
})

export const fetchOneFailure: (id: Id, error: ServerFailure) => FetchOneFailure = (id, error) => ({
  type: FETCH_ONE_FAILURE,
  payload: {
    error,
    id
  }
})

export const saveOneRequest: (params: SaveOneParam) => SaveOneRequest = contentAndId => ({
  type: SAVE_ONE_REQUEST,
  payload: contentAndId
})

export const saveOneSuccess: (id: Id) => SaveOneSuccess = id => ({
  type: SAVE_ONE_SUCCESS,
  payload: id
})

export const saveOneFailure: (error: ServerFailure) => SaveOneFailure = error => ({
  type: SAVE_ONE_FAILURE,
  payload: error
})

export const createQRAnswer: (lang: IsoLanguage) => CreateQRAnswer = language => ({
  type: CREATE_QR_ANSWER,
  payload: emptyQRAnswer(language)
})

export const createTagAgentContent: () => CreateTagAgentContent = () => ({
  type: CREATE_TAG_CONTENT,
  payload: emptyTagAgentContent()
})

export const createTextContent: (lang: IsoLanguage) => CreateTextContent = language => ({
  type: CREATE_TEXT_CONTENT,
  payload: emptyTextContent(language)
})

export const createImageContent: (lang: IsoLanguage) => CreateImageContent = language => ({
  type: CREATE_IMAGE_CONTENT,
  payload: emptyImageContent(language)
})

export const createSkillContent: () => CreateSkillContent = () => ({
  type: CREATE_SKILL_CONTENT,
  payload: emptyFormContent()
})

export const createButtonContent: (lang: IsoLanguage) => CreateButtonContent = language => ({
  type: CREATE_BUTTON_CONTENT,
  payload: emptyButtonContent(language)
})

export const updateContent: (content: Maybe<Answer>) => UpdateContent = content => ({
  type: UPDATE_CONTENT,
  payload: content
})

export const changeFeedbackStatus: (askFeedback: boolean) => ChangeFeedbackStatus = askFeedback => ({
  type: CHANGE_FEEDBACK_STATUS,
  payload: askFeedback
})

export const updateQRAnswer: (answer: UpdateQRAnswerParam) => UpdateQRAnswer = answer => ({
  type: UPDATE_QR_ANSWER,
  payload: answer
})

export const removeContent: (content: RemoveContentParam) => RemoveContent = content => ({
  type: REMOVE_CONTENT,
  payload: content
})

export const uploadImage: (image: UploadImageRequestPayload) => UploadImageRequest = image => ({
  type: UPLOAD_IMAGE_REQUEST,
  payload: image
})

export const uploadImageSuccess: (url: UploadImageSuccessPayload) => UploadImageSuccess = url => ({
  type: UPLOAD_IMAGE_SUCCESS,
  payload: url
})

export const uploadImageFailure: (error: UploadImageFailurePayload) => UploadImageFailure = error => ({
  type: UPLOAD_IMAGE_FAILURE,
  payload: error
})

export const goToSkills = () => ({
  type: GO_TO_SKILLS
})

export const fetchAllSimplifiedSkills: () => FetchAllSimplifiedSkills = () => ({
  type: FETCH_ALL_SIMPLIFIED_SKILLS_REQUEST
})

export const fetchAllSimplifiedSkillsSuccess: (
  skills: ReadonlyArray<SimplifiedSkill>
) => FetchAllSimplifiedSkillsSuccess = skills => ({
  type: FETCH_ALL_SIMPLIFIED_SKILLS_SUCCESS,
  payload: skills
})

export const fetchAllSimplifiedSkillsFailure: (
  error: ServerFailure
) => FetchAllSimplifiedSkillsFailure = error => ({
  type: FETCH_ALL_SIMPLIFIED_SKILLS_FAILURE,
  payload: error
})


export const fetchAllSimplifiedTags: () => FetchAllSimplifiedTags = () => {
  return {
  type: FETCH_ALL_SIMPLIFIED_TAGS_REQUEST
}}

export const fetchAllSimplifiedTagsSuccess: (
  tags: ReadonlyArray<SimplifiedTag>
) => FetchAllSimplifiedTagsSuccess = tags => ({
  type: FETCH_ALL_SIMPLIFIED_TAGS_SUCCESS,
  payload: tags
})

export const fetchAllSimplifiedTagsFailure: (
  error: ServerFailure
) => FetchAllSimplifiedTagsFailure = error => ({
  type: FETCH_ALL_SIMPLIFIED_TAGS_FAILURE,
  payload: error
})