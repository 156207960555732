import types from './types'

const fetchSatisfactionRequest = () => ({
  type: types.FETCH_SATISFACTION_REQUEST
})

const fetchSatisfactionSuccess = payload => ({
  type: types.FETCH_SATISFACTION_SUCCESS,
  payload
})

const fetchSatisfactionFailure = errors => ({
  type: types.FETCH_SATISFACTION_NO_DATA,
  errors
})
const fetchGlobalSatisfactionRequest = () => ({
  type: types.FETCH_GLOBAL_SATISFACTION_REQUEST
})

const fetchGlobalSatisfactionSuccess = payload => ({
  type: types.FETCH_GLOBAL_SATISFACTION_SUCCESS,
  payload
})

const fetchGlobalSatisfactionFailure = errors => ({
  type: types.FETCH_GLOBAL_SATISFACTION_NO_DATA,
  errors
})

const fetchUnsatisfactionRequest = () => ({
  type: types.FETCH_UNSATISFACTION_REQUEST
})

const fetchUnsatisfactionSuccess = payload => ({
  type: types.FETCH_UNSATISFACTION_SUCCESS,
  payload
})

const fetchUnsatisfactionFailure = errors => ({
  type: types.FETCH_UNSATISFACTION_NO_DATA,
  errors
})

export default {
  fetchSatisfactionRequest,
  fetchSatisfactionSuccess,
  fetchSatisfactionFailure,

  fetchGlobalSatisfactionRequest,
  fetchGlobalSatisfactionSuccess,
  fetchGlobalSatisfactionFailure,

  fetchUnsatisfactionRequest,
  fetchUnsatisfactionSuccess,
  fetchUnsatisfactionFailure
}
