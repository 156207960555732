import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { Menu } from 'semantic-ui-react'

import * as routes from '../../../layouts/Routes'

const TrafficTabs = ({ location: { pathname } }) => (
  <div>
    <Menu pointing secondary position='center' style={{ fontFamily: 'Raleway', marginBottom: '21px' }}>
      <Menu.Item
        name='Trends'
        active={pathname === routes.ANALYTICS_TRENDS}
        as={Link}
        to={{ pathname: routes.ANALYTICS_TRENDS }}
      />
      <Menu.Item
        name='Behaviours'
        active={pathname === routes.ANALYTICS_TRENDS_BEHAVIOURS}
        as={Link}
        to={{ pathname: routes.ANALYTICS_TRENDS_BEHAVIOURS }}
      />
    </Menu>
  </div>
)

export default withRouter(TrafficTabs)
