import { createSelector } from 'reselect'
import { remoteData } from '@sbizeul/fp-flow'
import * as genericUtils from '@tolk-genii/generic-utils'
import * as browserUtils from '@tolk-genii/browser-utils'
import * as jwt from 'jsonwebtoken'
import * as oauthApi from 'services/oauth-api-service'

export const getState = state => state.auth

export const getToken = createSelector(getState, state => {
  const accessToken = browserUtils.getCookie(document)('tolk_access_token')
  if (accessToken === null) {
    return remoteData.notAsked('No access token')
  }

  const decodedToken = jwt.decode(accessToken)
  if (decodedToken === null || !genericUtils.isObject(decodedToken)) {
    oauthApi.removeAuthenticationInfos(window.localStorage)
    return remoteData.failure('Invalid token')
  }

  if (typeof decodedToken?.exp !== 'number') {
    oauthApi.removeAuthenticationInfos(window.localStorage)
    return remoteData.failure('Invalid token')
  }

  if (decodedToken.exp * 1000 < Date.now()) {
    return remoteData.failure('Expired token')
  }

  return remoteData.success(accessToken)
})

export const getIsAuthenticated = createSelector(getToken, remoteData.isSuccess)
