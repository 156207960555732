import actions from '../actions'
import users from '../users/operations'
import messages from '../messages/operations'
import demographics from '../demographics/operations'
import conversations from '../conversations/operations'
import events from '../events/operations'

const fetchAnalyticsTrafficUsers = filters => async dispatch => {
  dispatch(actions.fetchAnalyticsTrafficUsersRequest())
  try {
    const conversationsPromise = dispatch(conversations.fetchConversations(filters))
    const messagesPromise = dispatch(messages.fetchMessages(filters))
    const messagesPreviousPeriod = dispatch(messages.fetchMessagesPreviousPeriod(filters))
    const usersPromise = dispatch(users.fetchUsers(filters))
    const usersPreviousPeriodPromise = dispatch(users.fetchUsersPreviousPeriod(filters))
    const newUsersPromise = dispatch(users.fetchNewUsers(filters))
    const demographicsPromise = dispatch(demographics.fetchDemographics(filters))
    const eventsConfig = dispatch(events.fetchBotConfig(filters))
    const eventsData = dispatch(events.fetchCustomEventsData(filters))
    await Promise.all([
      eventsData,
      eventsConfig,
      conversationsPromise,
      messagesPromise,
      messagesPreviousPeriod,
      usersPromise,
      usersPreviousPeriodPromise,
      newUsersPromise,
      demographicsPromise
    ])

    await dispatch(events.fetchCustomEventsData(filters))
    await dispatch(actions.fetchAnalyticsTrafficUsersSuccess())
  } catch (e) {
    await dispatch(actions.fetchAnalyticsTrafficUsersFailure())
  }
}

export default fetchAnalyticsTrafficUsers
