import { connect } from 'react-redux'
import { Maybe } from '@sbizeul/fp-flow'

import bot from 'modules/bot'
import keyword from 'modules/keyword'
import question from 'modules/question'
import { AppState as BotAppState } from 'modules/bot/types'
import { AppState as QuestionAppState } from 'modules/question/types'
import { AppState as KeywordAppState } from 'modules/keyword/types'

import * as actions from '../actions'
import AnswerEditor from '../components/AnswerEditor/AnswerEditor'
import * as selectors from '../selectors'

import {
  AppState,
  SimpleDispatchFromProps,
  StateFromProps,
  Answer
} from '../types'

type State = AppState & BotAppState & QuestionAppState & KeywordAppState

const mapStateToProps: (
  state: State,
  props: { answer: Maybe<Answer>; canCreateQR?: boolean; children?: React.ReactNode }
) => StateFromProps = (state, props) => ({
  answer: props.answer,
  canCreateQR: props.canCreateQR,
  children: props.children,
  imagesUrl: selectors.getImagesUrl(state),
  skills: selectors.getSimplifiedSkills(state),
  tags: selectors.getSimplifiedTags(state),
  languages: bot.selectors.getLanguages(state),
  keywords: keyword.selectorsTyped.getAll(state),
  questions: question.selectors.getAll(state)
})

const mapDispatchToProps: SimpleDispatchFromProps = {
  fetchQuestions: question.actions.fetchAll,
  fetchAllSkills: actions.fetchAllSimplifiedSkills,
  fetchAllTags: actions.fetchAllSimplifiedTags,
  uploadImage: actions.uploadImage,
  goToSkills: actions.goToSkills,
  fetchAllKeywords: keyword.actions.fetchKeywordsRequest
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnswerEditor)
