import React from 'react'
import { connect } from 'react-redux'
import { Grid, Segment } from 'semantic-ui-react'
import isEmpty from 'lodash/isEmpty'
import { maybe } from '@sbizeul/fp-flow'
import MoreInfoPopup from '../../../../components/MoreInfoPopup'
import RetentionGraphEntities from '../../components/BarCharts/RetentionHighcharts'
import text from '../../../../../config/translations/en.json'
import BarUserSatisfaction from '../../components/BarCharts/BarUserSatisfaction'
import GlobalSatisfaction from '../../components/PieCharts/GlobalSatisfaction'
import Tiles from '../../components/Tiles'
import bot from '../../../../../modules/bot'

const style = {
  borderRadius: '3px',
  border: 'none',
  boxShadow: '0 2px 4px rgba(50, 50, 93,.1)',
  padding: '5px'
}

const averageSat = globalSatisfaction => {
  const criteriasArray = Object.keys(globalSatisfaction)
  if (criteriasArray.length > 0) {
    const nbVotesArray = Object.values(globalSatisfaction)
    const numeratorArray = nbVotesArray.map((value, index) => value * criteriasArray[index])
    const numerator = numeratorArray.reduce((pv, cv) => pv + cv, 0)
    const denominator = nbVotesArray.reduce((pv, cv) => pv + cv, 0)
    return (numerator / denominator).toFixed(2)
  }
  return 0
}

const isGlobalSatBots = botId => {
  const botIds = [
    '59f09b39bed87007d10138b8',
    '5a0ae11ef408420880a69554',
    '5b430a8111a5136f6789196a',
    '584edeb7b7dd9b11aa51d3c4',
    '5b438c33dbf69f2b19ba0fe2'
  ]
  return botIds.includes(botId)
}

const Retention = ({ retentionData, satisfactionGraph, period, botId, globalSatisfaction }) => {
  const activeBotId = maybe.getOrElse(() => 'unknwonBot', botId)
  return (
    <div style={{ fontFamily: 'Roboto' }}>
      <br />
      <Grid>
        {isGlobalSatBots(activeBotId) && (
          <React.Fragment>
            <Grid.Row>
              <Grid.Column mobile={8} tablet={4} computer={4} widescreen={4}>
                <Tiles name='Average Global Sat.' value={averageSat(globalSatisfaction)} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={8} computer={8} widescreen={8}>
                <h1>
                  Global User Satisfaction <MoreInfoPopup content={text.performance.globalsat} />
                </h1>
                <Segment style={style}>
                  <GlobalSatisfaction satisfaction={globalSatisfaction} />
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </React.Fragment>
        )}
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={16} widescreen={16}>
            <h1>
              User Satisfaction Analysis
              <MoreInfoPopup content={text.performance.satisfactionanalysis} />
            </h1>
            <BarUserSatisfaction satisfaction={satisfactionGraph} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={16} widescreen={16}>
            <h1>
              User Retention <MoreInfoPopup content={text.performance.usersretention} />
            </h1>
            <Segment style={style}>
              <RetentionGraphEntities entities={retentionData} noDataDisplay={isEmpty(retentionData)} />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  )
}
const mapStateToProps = state => ({
  botId: bot.selectors.getSelectedId(state)
})

export default connect(mapStateToProps)(Retention)
