import api from 'services/tolkApiLegacy'
import { maybe } from '@sbizeul/fp-flow'

import formatFilters from '../../../_shared/formatFilters'
import actions from '../actions'
import { groupConversationsByHourByDay, groupDataByDay, groupDataByHour } from '../../../../views/pages/Analytics/utils'
import bot from '../../../../modules/bot'

const fetchConversationsCountByHour = rawFilters => {
  const { channelId, start, end } = formatFilters(rawFilters)

  return async (dispatch, getState) => {
    const botId = bot.selectors.getSelectedId(getState())
    dispatch(actions.fetchConversationsCountByHourRequest())
    try {
      const response = await api.get(`analytics/${maybe.get(botId)}/${channelId}/conversations`, {
        start,
        end,
        period: 'hours'
      })
      const conversationsCountByDay = groupDataByDay(response.data)
      const conversationsCountByHour = groupDataByHour(response.data)
      const conversationsCountByHourByDay = groupConversationsByHourByDay(response.data)
      dispatch(
        actions.fetchConversationsCountByHourSuccess({
          conversationsCountByHour,
          conversationsCountByDay,
          conversationsCountByHourByDay
        })
      )
    } catch (e) {
      dispatch(actions.fetchConversationsCountByHourFailure(e))
    }
  }
}

export default fetchConversationsCountByHour
