import delays from '../delays/operations'
import actions from '../actions'
import entities from '../entities/operations'
import messages from '../messages/operations'

const fetchAnalyticsPerformanceDelays = filters => async dispatch => {
  dispatch(actions.fetchPerformanceDelaysRequest())
  try {
    const fetchDelaysPromise = dispatch(delays.fetchDelays(filters))
    const fetchDelaysPreviousPeriodPromise = dispatch(delays.fetchDelaysPreviousPeriod(filters))
    const fetchEntitiesPromise = dispatch(entities.fetchEntities(filters))
    const fetchMessagesPromise = dispatch(messages.fetchMessages(filters))

    await Promise.all([
      fetchDelaysPromise,
      fetchDelaysPreviousPeriodPromise,
      fetchEntitiesPromise,
      fetchMessagesPromise
    ])
    await dispatch(actions.fetchPerformanceDelaysSuccess())
  } catch (e) {
    console.error(e)
    await dispatch(actions.fetchPerformanceDelaysFailure())
  }
}

export default fetchAnalyticsPerformanceDelays
