import { put, takeLatest } from 'redux-saga/effects'
import { push } from 'connected-react-router'

import { NAVIGATE_TO, NavigateTo } from './types'

export function* navigateTo (action: NavigateTo) {
  yield put(push(action.payload.path))
}

export function* root () {
  yield takeLatest(NAVIGATE_TO, navigateTo)
}
