import misunderstood from '../misunderstood/operations'
import satisfaction from '../satisfaction/operations'
import escaladesBegin from '../escaladesBegin/operations'
import escaladesEnd from '../escaladesEnd/operations'
import nothandled from '../nothandled/operations'
import actions from '../actions'

const fetchAnalyticsPerformanceUnderstanding = filters => async dispatch => {
  dispatch(actions.fetchPerformanceUnderstandingRequest())
  try {
    const fetchMisunderstood = dispatch(misunderstood.fetchMisunderstood(filters))
    const fetchMisunderstoodAverage = dispatch(misunderstood.fetchMisunderstoodAverage(filters))
    const fetchUnSatisfaction = dispatch(satisfaction.fetchUnsatisfaction(filters))
    const fetchEscaladesBegin = dispatch(escaladesBegin.fetchEscaladesBegin(filters))
    const fetchEscaladesEnd = dispatch(escaladesEnd.fetchEscaladesEnd(filters))
    const fetchNothandled = dispatch(nothandled.fetchNothandled(filters))
    await Promise.all([
      fetchMisunderstood,
      fetchMisunderstoodAverage,
      fetchUnSatisfaction,
      fetchEscaladesBegin,
      fetchNothandled,
      fetchEscaladesEnd
    ])
    await dispatch(actions.fetchPerformanceUnderstandingSuccess())
  } catch (e) {
    console.error(e)
    await dispatch(actions.fetchPerformanceUnderstandingFailure())
  }
}

export default fetchAnalyticsPerformanceUnderstanding
