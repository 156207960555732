import React from 'react'
import * as R from 'ramda'

import SelectOutlinedUI, { SelectStyled } from '../SelectOutlinedUI'
import { CheckBox, RecurringContainer, Recurring, RecurringSelectionContainer, RecurrenceSelection } from './styles'

const recurrences = [
  { label: 'Every minute', value: 'minutes' },
  { label: 'Every hour', value: 'hour' },
  { label: 'Every day', value: 'day' },
  { label: 'Every week', value: 'week' },
  { label: 'Every month', value: 'months' }
]

const findRecurrenceWithLabel = (label) => R.find(R.propEq('label', label))(recurrences)
const findRecurrenceWithValue = (value) => R.find(R.propEq('value', value))(recurrences)
const valueFromChoice = R.pipe(R.path(['target', 'value']), findRecurrenceWithLabel, R.prop('value'))
const labelFromValue = R.pipe(findRecurrenceWithValue, R.propOr('Every day', 'label'))

const RecurringSelection = ({ canEdit, selectRecurring, selectRecurrence, recurrence, recurring }) => (
  <RecurringSelectionContainer>
    <RecurringContainer onClick={() => selectRecurring(!recurring)}>
      <CheckBox disabled={!canEdit} selected={recurring} />
      <Recurring>Recurring campaign</Recurring>
    </RecurringContainer>
    <RecurrenceSelection disabled={!canEdit || !recurring}>
      <SelectOutlinedUI>
        <SelectStyled
          disabled={!canEdit || !recurring}
          value={labelFromValue(recurrence)}
          onChange={R.pipe(valueFromChoice, selectRecurrence)}
        >
          {recurrences.map(R.prop('label')).map(recurrence => (
            <option key={recurrence} value={recurrence}>
              {recurrence}
            </option>
          ))}
        </SelectStyled>
      </SelectOutlinedUI>
    </RecurrenceSelection>
  </RecurringSelectionContainer>
)

export default RecurringSelection
