import React, { useEffect } from 'react'
import { RemoteData, remoteData } from '@sbizeul/fp-flow'
import * as R from 'ramda'

import { renderServerFailure, renderLoader } from 'helpers/react'
import { restoreScroll, withScrollManager } from 'helpers/scroll-manager'
import { WithScrollManagerAndLocationProps } from 'helpers/scroll-manager/types'
import { ServerFailure } from 'types'

import { MonitorLogs, Progress } from '../../types'
import UnmatchedQuestions, { UnmatchedLogsProps } from './UnmatchedQuestions'

import './styles.css'

type UnmatchedRemoteLogsProps = Omit<UnmatchedLogsProps, 'logs'> & Readonly<{
  logs: RemoteData<ServerFailure, MonitorLogs>
  progress: RemoteData<ServerFailure, Progress>,

  fetchLogs: () => unknown
  fetchQuestions: () => unknown
}> & WithScrollManagerAndLocationProps

const renderUnmatchedQuestion = (props: UnmatchedRemoteLogsProps) => (logs: MonitorLogs) =>
  <UnmatchedQuestions {...props} logs={logs}/>

const UnmatchedRemoteLogs: React.FunctionComponent<UnmatchedRemoteLogsProps> = (props) => {

  useEffect(() => {
    props.fetchLogs()
    props.fetchQuestions()
  },[])

  return (
    <div className='Monitor'>
      {remoteData.fold({
        Success: R.compose(restoreScroll(props), renderUnmatchedQuestion(props)),
        Loading: () => renderLoader('Loading...'),
        NotAsked: () => renderLoader('Loading...'),
        Failure: renderServerFailure
      }, props.logs)}
    </div>
  )
}

export default withScrollManager(UnmatchedRemoteLogs)
