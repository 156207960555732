const GET_CONVERSATIONS_LIST_REQUEST = 'tcfdash/conversations/GET_CONVERSATIONS_LIST_REQUEST'
const GET_CONVERSATIONS_LIST_SUCCESS = 'tcfdash/conversations/GET_CONVERSATIONS_LIST_SUCCESS'
const GET_CONVERSATIONS_LIST_FAILURE = 'tcfdash/conversations/GET_CONVERSATIONS_LIST_FAILURE'

const GET_CONVERSATIONS_LIST_BY_WORDS_REQUEST = 'tcfdash/conversations/GET_CONVERSATIONS_LIST_BY_WORDS_REQUEST'
const GET_CONVERSATIONS_LIST_BY_WORDS_SUCCESS = 'tcfdash/conversations/GET_CONVERSATIONS_LIST_BY_WORDS_SUCCESS'
const GET_CONVERSATIONS_LIST_BY_WORDS_FAILURE = 'tcfdash/conversations/GET_CONVERSATIONS_LIST_BY_WORDS_FAILURE'

const GET_MESSAGES_REQUEST = 'tcfdash/conversations/GET_MESSAGES_REQUEST'
const GET_MESSAGES_SUCCESS = 'tcfdash/conversations/GET_MESSAGES_SUCCESS'
const GET_MESSAGES_FAILURE = 'tcfdash/conversations/GET_MESSAGES_FAILURE'

const GET_TRAINING_SENTENCE_REQUEST = 'tcfdash/training/GET_TRAINING_SENTENCE_REQUEST'
const GET_TRAINING_SENTENCE_SUCCESS = 'tcfdash/training/GET_TRAINING_SENTENCE_SUCCESS'
const GET_TRAINING_SENTENCE_FAILURE = 'tcfdash/training/GET_TRAINING_SENTENCE_FAILURE'

const SET_ACTIVE_CONVERSATION = 'tcfdash/conversations/SET_ACTIVE_CONVERSATION'

export default {
  GET_CONVERSATIONS_LIST_REQUEST,
  GET_CONVERSATIONS_LIST_SUCCESS,
  GET_CONVERSATIONS_LIST_FAILURE,

  GET_CONVERSATIONS_LIST_BY_WORDS_REQUEST,
  GET_CONVERSATIONS_LIST_BY_WORDS_SUCCESS,
  GET_CONVERSATIONS_LIST_BY_WORDS_FAILURE,

  GET_TRAINING_SENTENCE_REQUEST,
  GET_TRAINING_SENTENCE_SUCCESS,
  GET_TRAINING_SENTENCE_FAILURE,

  GET_MESSAGES_REQUEST,
  GET_MESSAGES_SUCCESS,
  GET_MESSAGES_FAILURE,

  SET_ACTIVE_CONVERSATION
}
