import React from 'react'
import { FaSave } from 'react-icons/fa'
import * as O from 'fp-ts/lib/Option'

import Button from 'components/Button'
import BackButton from 'components/BackButton'

import { ChannelField, ChannelInformation } from '../../types'

type SettingsFormProps = Readonly<{
  name: string
  fields: ReadonlyArray<ChannelField>
  channelInformation: O.Option<ChannelInformation>

  onSubmit: (event: React.FormEvent<HTMLFormElement>) => unknown
}>

const SettingsForm: React.FunctionComponent<SettingsFormProps> = ({
  name,
  fields,
  channelInformation,
  onSubmit
}) => (
  <form onSubmit={onSubmit}>
    <header className="channelsettings__header">
      <BackButton label={`${name} Settings`} />
      <Button type="submit" className="rounded primary">
        <FaSave />
        &nbsp;Save
      </Button>
    </header>
    <div className="channelsettings__content">
      <h1 className="channelsettings__title">Set your app</h1>
      <h2 className="channelsettings__subtitle">
        Complete this form to connect your assistant to {name}. If you need
        guidance, consider{' '}
        <a
          className="channelsettings__subtitlelink"
          href="https://academy.tolk.ai/kb/fr"
          target="_blank"
          rel="noopener noreferrer"
        >
          visit our help center
        </a>
        .
      </h2>
      {fields.map((fieldObject, index) => (
        <p key={index} className="channelsettings__inputrow">
          <label
            htmlFor={fieldObject.name}
            className="channelsettings__inputlabel"
          >
            {fieldObject.title}
          </label>
          <input
            className="channelsettings__input"
            type="text"
            id={fieldObject.name}
            name={fieldObject.name}
            placeholder={fieldObject.placeholder}
            defaultValue={O.fold(
              () => '',
              (channelInfo: ChannelInformation) =>
                channelInfo.options[fieldObject.name]
            )(channelInformation)}
          />
        </p>
      ))}
    </div>
  </form>
)

export { SettingsForm }
