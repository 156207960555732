import * as actions from './actions'
import * as saga from './saga'
import * as selectors from './selectors'
import reducer, { initialState } from './reducer'

export default {
  actions,
  saga,
  selectors,
  reducer,
  initialState
}
