import moment from 'moment-timezone'
import types from './types'
import stripDateDetails from '../_shared/stripDateDetails'

/* Loading */
const setLoading = message => ({ type: types.SET_LOADING, message })
const removeLoading = () => ({ type: types.REMOVE_LOADING })

/* Auth */
const loginRequest = () => ({ type: types.LOGIN_REQUEST })
const loginSuccess = payload => ({ type: types.LOGIN_SUCCESS, payload })
const loginFailure = error => ({ type: types.LOGIN_FAILURE, error })

const verifyAuthRequest = () => ({ type: types.VERIFY_AUTH_REQUEST })
const verifyAuthFailure = error => ({ type: types.VERIFY_AUTH_FAILURE, error })
const verifyAuthSuccess = payload => ({ type: types.VERIFY_AUTH_SUCCESS, payload })

const logout = () => ({ type: types.LOGOUT })

const setActiveChannel = channel => {
  return {
    type: types.SET_ACTIVE_CHANNEL,
    channel
  }
}

const setActivePeriod = index => ({
  type: types.SET_ACTIVE_PERIOD,
  index
})

const setDateRangeDropDown = ({ duration }) => {
  const end = stripDateDetails(moment.tz('Europe/Paris'))
  const today = stripDateDetails(moment.tz('Europe/Paris'))
  switch (duration) {
  case 'd':
    return {
      type: types.SET_DATE_RANGE,
      dateRange: {
        start: moment(today),
        end: moment(today)
      }
    }
  case 'w':
    return {
      type: types.SET_DATE_RANGE,
      dateRange: {
        start: moment(today).subtract(1, 'weeks'),
        end: moment(end).subtract(1, 'days')
      }
    }
  case 'm':
    return {
      type: types.SET_DATE_RANGE,
      dateRange: {
        start: moment(today).subtract(1, 'months'),
        end: moment(end).subtract(1, 'days')
      }
    }
  case 'y':
    return {
      type: types.SET_DATE_RANGE,
      dateRange: {
        start: moment(today).subtract(1, 'days'),
        end: moment(today).subtract(1, 'days')
      }
    }
  case 'year':
    return {
      type: types.SET_DATE_RANGE,
      dateRange: {
        start: moment(today).subtract(12, 'months'),
        end: moment(end).subtract(1, 'days')
      }
    }
  default:
    return {
      type: types.SET_DATE_RANGE
    }
  }
}

const setDateRange = dateRange => {
  const stripedDateRange = {
    start: stripDateDetails(dateRange.start),
    end: stripDateDetails(dateRange.end || moment())
  }
  return {
    type: types.SET_DATE_RANGE,
    dateRange: stripedDateRange
  }
}

export default {
  setLoading,
  removeLoading,

  setActiveChannel,
  setActivePeriod,
  setDateRangeDropDown,
  setDateRange,

  loginRequest,
  loginSuccess,
  loginFailure,

  verifyAuthRequest,
  verifyAuthSuccess,
  verifyAuthFailure,

  logout
}
