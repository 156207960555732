import * as R from 'ramda'
import { remoteData } from '@sbizeul/fp-flow'

import * as qaActions from './actions'

export const initialState = {
  byId: {}
}

const byId = R.lensPath(['byId'])

const fetchOneRequest = (state, { payload: id }) => {
  const path = R.compose(byId, R.lensPath([id]))
  return R.over(path, remoteData.loading, state)
}

const fetchOneSuccess = (state, { payload }) => {
  const path = R.compose(byId, R.lensPath([payload.id]))
  return R.set(path, remoteData.success(payload), state)
}

const fetchOneFailure = (state, { payload }) => {
  const path = R.compose(byId, R.lensPath([payload.id]))
  return R.set(path, remoteData.failure(payload.error), state)
}

export default function(state = initialState, action) {
  switch (action.type) {
  case qaActions.FETCH_ONE_REQUEST:
    return fetchOneRequest(state, action)
  case qaActions.FETCH_ONE_SUCCESS:
    return fetchOneSuccess(state, action)
  case qaActions.FETCH_ONE_FAILURE:
    return fetchOneFailure(state, action)
  default:
    return state
  }
}
