import React, { Component } from 'react'
import SelectOutlinedUI, { SelectStyled } from '../SelectOutlinedUI'
import { timeOptionValue, filterMinutes, createMinutesIntervals, createInitialDate } from '../../helpers'
import { convertTimestring12To24, concatenateDateAndTimestring } from '../../../../helpers/moment'

class TimePicker extends Component {
  componentWillReceiveProps(nextProps) {
    if (nextProps.disabled !== this.props.disabled) {
      if (!nextProps.disabled) {
        this.props.changeDate(createInitialDate().date)
      }
    }
  }
  render() {
    const { changeDate, date, disabled, isSent } = this.props
    return (
      <SelectOutlinedUI as='div'>
        <SelectStyled
          disabled={disabled}
          value={date.clone().format('LT')}
          onChange={e => changeDate(concatenateDateAndTimestring(date, convertTimestring12To24(e.target.value)))}
          // onLoad={e => changeDate(conc  atenateDateAndTimestring(date, convertTimestring12To24(e.target.value)))}
        >
          {createMinutesIntervals()
            .filter(minutes => filterMinutes(date, minutes, isSent))
            .map(minutes => (
              <option key={minutes} value={timeOptionValue(date, minutes)}>
                {timeOptionValue(date, minutes)}
              </option>
            ))}
        </SelectStyled>
      </SelectOutlinedUI>
    )
  }
}

export default TimePicker
