import React, { Component } from 'react'
import { Modal, Button } from 'semantic-ui-react'
import { connect } from 'react-redux'
import EditRessource from '../Edit'
import operations from '../../../../redux/ressources/operations'

class EditingModal extends Component {
  state = { modalOpen: false }

  componentWillReceiveProps(nextProps) {
    if (nextProps.closeModal === true && this.props.closeModal === false) {
      this.setState({ modalOpen: false })
    }
  }

  handleOpen = () => {
    this.props.closeModals(false)
    this.setState({ modalOpen: true })
  }

  handleClose = () => this.setState({ modalOpen: false })

  render() {
    const { botId, ressourceId, editRessource, ressourceName, values } = this.props
    return (
      <Modal
        trigger={<Button onClick={this.handleOpen}>Edit</Button>}
        closeIcon
        open={this.state.modalOpen}
        onClose={this.handleClose}
      >
        <EditRessource
          formType={ressourceName}
          ressourceId={ressourceId}
          editRessource={editRessource}
          botId={botId}
          values={values}
        />
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  closeModal: state.ressources.get('closeModals')
})

const connectedEditingModal = connect(mapStateToProps, operations)(EditingModal)

export default connectedEditingModal
