import { LOCATION_CHANGE, push } from 'connected-react-router'
import { call, put, takeEvery, takeLatest, select } from 'redux-saga/effects'
import { maybe } from '@sbizeul/fp-flow'

import bot from 'modules/bot'
import Api from 'services/tolkApi'
import { Id } from 'types'

import * as monitorAction from './actions'
import * as createQuestionACL from './create-question-ACL'
import {
  ConfirmOneRequest,
  CreateQuestionRequest,
  MonitorLogs,
  Navigate,
  ValidateTaggingAction,
  Progress
} from './types'
import * as selectors from './selectors'

export function * fetchLogs () {
  const botId = maybe.get<string>(yield select(bot.selectors.getSelectedId))
  try {
    const { data }: { data: MonitorLogs } = yield call(Api.get, `/bots/${botId}/user-logs-to-monitor`)
    yield put(monitorAction.fetchSuccess(data))
  } catch (error) {
    yield put(monitorAction.fetchFailure(error))
  }
}

export function * fetchProgress () {
  const botId = maybe.get<string>(yield select(bot.selectors.getSelectedId))
  try {
    const { data }: { data: Progress } = yield call(Api.get, `/bots/${botId}/monitoring-status`)
    yield put(monitorAction.fetchProgressSuccess(data))
  } catch (error) {
    yield put(monitorAction.fetchProgressFailure(error))
  }
}

export function * fetchOutOfScopeLogs () {
  const botId = maybe.get(yield select(bot.selectors.getSelectedId))
  try {
    const { data }: { data: MonitorLogs } = yield call(Api.get, `/bots/${botId}/out-of-scope`)
    yield put(monitorAction.fetchOutOfScopeSuccess(data))
  } catch (error) {
    yield put(monitorAction.fetchOutOfScopeFailure(error))
  }
}

export function * confirmOneQuestion (action: ConfirmOneRequest) {
  const botId = maybe.get(yield select(bot.selectors.getSelectedId))
  const monitorMatchedEndpoint = `/bot/${botId}/logs/matched`
  try {
    yield call(Api.post, monitorMatchedEndpoint, action.payload.confirmedLogs)
    yield put(monitorAction.confirmOneSuccess())
  } catch (error) {
    yield put(monitorAction.confirmOneFailure(error))
  }
}

export function * validateTagging (action: ValidateTaggingAction) {
  const botId = maybe.get(yield select(bot.selectors.getSelectedId))
  const monitorUnmatchedEndpoint = `/bot/${botId}/logs/unmatched`
  const taggedLogs = action.payload
  yield call(Api.post, monitorUnmatchedEndpoint, taggedLogs)
  yield put(monitorAction.fetchRequest())
}

export function * navigate (action: Navigate) {
  yield put(push(action.payload))
}

export function * createQuestion (action: CreateQuestionRequest) {
  const botId = maybe.getOrElse(() => 'unknown', yield select(bot.selectors.getSelectedId))
  try {
    const { data }: { data: Id } =
      yield call(Api.post, `/bot/${botId}/qa/logs`, createQuestionACL.toApi(botId)(action.payload))
  yield put(monitorAction.createQuestionSuccess(data))
  } catch (error) {
    yield put(monitorAction.createQuestionFailure(error))
  }
}

export function * matchRoute () {
  const matchOutOfScopeRoute = yield select(selectors.matchOutOfScopeRoute)
  if (matchOutOfScopeRoute != null) {
    yield put(monitorAction.cleanCreatedQuestion())
  }
}

export function * root () {
  yield takeEvery(LOCATION_CHANGE, matchRoute)
  yield takeLatest(monitorAction.MONITOR_FETCH_REQUEST, fetchLogs)
  yield takeLatest(monitorAction.MONITOR_FETCH_REQUEST, fetchProgress)
  yield takeLatest(monitorAction.MONITOR_FETCH_PROGRESS_REQUEST, fetchProgress)
  yield takeLatest(monitorAction.MONITOR_FETCH_OUT_OF_SCOPE_REQUEST, fetchOutOfScopeLogs)
  yield takeLatest(monitorAction.CREATE_QUESTION_SUCCESS, fetchOutOfScopeLogs)
  yield takeLatest(monitorAction.CONFIRM_ONE_QUESTION_REQUEST, confirmOneQuestion)
  yield takeLatest(monitorAction.CONFIRM_ONE_QUESTION_SUCCESS, fetchLogs)
  yield takeLatest(monitorAction.CONFIRM_ONE_QUESTION_SUCCESS, fetchProgress)
  yield takeLatest(monitorAction.CREATE_QUESTION_REQUEST, createQuestion)
  yield takeLatest(monitorAction.MONITOR_VALIDATE_TAGGING, validateTagging)
  yield takeLatest(monitorAction.NAVIGATE_TO_DASHBOARD, navigate)
  yield takeLatest(monitorAction.NAVIGATE_TO_OUT_OF_SCOPE, navigate)
}
