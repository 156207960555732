import styled from 'styled-components'
import { initButton } from '../../style/globalStyles'

const CampaignsCollapseListStyled = styled.button`
  ${initButton}
  margin-top: ${({ theme }) => theme.margin.top.outlinedUI}px;
  text-decoration: underline;
`

export { CampaignsCollapseListStyled }
