import React from 'react'
import PropTypes from 'prop-types'

const AddFriendIcon = ({ width, height, fill }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 38 38'>
      <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='1' transform='translate(-1495.000000, -1264.000000)' fill={fill} fillRule='nonzero'>
          <g id='add-friend' transform='translate(1495.000000, 1264.000000)'>
            <path
              d='M32.223671,5.52834513 C28.6598397,1.96336172 23.9215049,0 18.8816242,0 C13.8414555,0 9.10340881,1.96336172 5.53957745,5.52834513 C1.97574615,9.09361652 0.0132484805,13.8333912 0.0132484805,18.875 C0.0132484805,21.5972672 0.607124329,24.3077259 1.73698618,26.7658883 C0.659254056,27.9331913 -2.84217094e-14,29.4927635 -2.84217094e-14,31.2032528 C-2.84217094e-14,34.8131657 2.93597027,37.75 6.54473114,37.75 C8.2523403,37.75 9.8090324,37.092474 10.9757595,36.017334 C13.4376659,37.152956 16.1538849,37.75 18.8816242,37.75 C23.9215049,37.75 28.6601277,35.7866383 32.223671,32.2216549 C35.7875023,28.6563835 37.75,23.9166088 37.75,18.875 C37.75,13.8333912 35.7875023,9.09361652 32.223671,5.52834513 Z M6.54473114,35.5380859 C4.15569113,35.5380859 2.21191406,33.5934448 2.21191406,31.2032528 C2.21191406,28.8127727 4.15569113,26.8681316 6.54473114,26.8681316 C8.93405914,26.8681316 10.8778362,28.8127727 10.8778362,31.2032528 C10.8778362,33.5934448 8.93405914,35.5380859 6.54473114,35.5380859 Z M30.6594906,30.6577625 C27.5132732,33.8048439 23.3305092,35.5380859 18.8816242,35.5380859 C16.604044,35.5380859 14.4145946,35.092535 12.3564778,34.2120895 C12.8247814,33.3106193 13.0897503,32.2873211 13.0897503,31.2032528 C13.0897503,27.5933399 10.15378,24.6562176 6.54473114,24.6562176 C5.46383097,24.6562176 4.44283672,24.9200344 3.54338266,25.386322 C2.66812134,23.3322372 2.22516254,21.1473961 2.22516254,18.875 C2.22516254,14.4240989 3.95782856,10.2396068 7.10375784,7.09223745 C10.2496872,3.94515606 14.4324513,2.21191406 18.8816242,2.21191406 C23.3305092,2.21191406 27.5132732,3.94515606 30.6592026,7.09223745 C33.8054199,10.2396068 35.5380859,14.4240989 35.5380859,18.875 C35.5380859,23.3259011 33.8054199,27.5103932 30.6594906,30.6577625 Z'
              id='Shape'
            />
            <path
              d='M8.54034994,30.0972957 L7.65068817,30.0972957 L7.65068817,29.2070579 C7.65068817,28.5961895 7.15559958,28.1011009 6.54473114,28.1011009 C5.9341507,28.1011009 5.43877411,28.5961895 5.43877411,29.2070579 L5.43877411,30.0972957 L4.54940033,30.0972957 C3.93853189,30.0972957 3.4434433,30.5923843 3.4434433,31.2032528 C3.4434433,31.8138332 3.93853189,32.3092098 4.54940033,32.3092098 L5.43877411,32.3092098 L5.43877411,33.1994476 C5.43877411,33.8100281 5.9338627,34.3054047 6.54473114,34.3054047 C7.15559958,34.3054047 7.65068817,33.8100281 7.65068817,33.1994476 L7.65068817,32.3092098 L8.54006195,32.3092098 C9.15093039,32.3092098 9.64601898,31.8138332 9.64601898,31.2032528 C9.64601898,30.5923843 9.15093039,30.0972957 8.54034994,30.0972957 Z'
              id='Shape'
            />
            <path
              d='M22.0935078,18.9127293 C22.8616294,18.1120625 23.3342533,17.0259781 23.3342533,15.8313141 C23.3342533,13.3754558 21.3366184,11.377533 18.8816242,11.377533 C16.426342,11.377533 14.4289952,13.3754558 14.4289952,15.8313141 C14.4289952,17.0259781 14.901619,18.1120625 15.6694527,18.9127293 C13.6510811,20.0428791 12.2836113,22.2029514 12.2836113,24.6766663 C12.2836113,25.2875347 12.7786999,25.7826233 13.3895683,25.7826233 L24.3736801,25.7826233 C24.9842606,25.7826233 25.4796371,25.2875347 25.4796371,24.6766663 C25.4796371,22.2029514 24.1121674,20.0428791 22.0935078,18.9127293 Z M18.8816242,13.589447 C20.1171856,13.589447 21.1223393,14.5951767 21.1223393,15.8313141 C21.1223393,17.0674515 20.1171856,18.0731812 18.8816242,18.0731812 C17.6460628,18.0731812 16.6409092,17.0674515 16.6409092,15.8313141 C16.6409092,14.5951767 17.6460628,13.589447 18.8816242,13.589447 Z M14.6366501,23.5707092 C15.1277065,21.6848221 16.84482,20.2885513 18.8816242,20.2885513 C20.9184284,20.2885513 22.6352539,21.6848221 23.1265983,23.5707092 L14.6366501,23.5707092 Z'
              id='Shape'
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

AddFriendIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string
}

AddFriendIcon.defaultProps = {
  width: '1.5em',
  height: '1.5em',
  fill: 'currentColor'
}

export default AddFriendIcon
