import { ServerFailure } from 'types'

import {
  BOT_CREATION_ENDED,
  BOT_CREATION_FAILURE,
  BOT_CREATION_GET_LAST_BOT,
  BOT_CREATION_REQUEST,
  BOT_CREATION_SUCCESS,
  BOT_CREATION_UPDATE_AVATAR,
  BOT_CREATION_UPDATE_BOTNAME,
  BOT_CREATION_UPDATE_GREETINGS,
  BOT_CREATION_UPDATE_LANGUAGE,
  BOT_CREATION_UPDATE_THEME,
  BOT_CREATION_UPDATE_USE_CASE,
  CLEAN_WEBCHAT,
  CleanWebchat,
  FETCH_ALL_FAILURE,
  FETCH_ALL_REQUEST,
  FETCH_ALL_SUCCESS,
  FETCH_ONE_FAILURE,
  FETCH_ONE_REQUEST,
  FETCH_ONE_SUCCESS,
  FETCH_PUBLISH_STATUS_FAILURE,
  FETCH_PUBLISH_STATUS_REQUEST,
  FETCH_PUBLISH_STATUS_SUCCESS,
  FETCH_USE_CASES_FAILURE,
  FETCH_USE_CASES_REQUEST,
  FETCH_USE_CASES_SUCCESS,
  FETCH_WEBCHAT_FAILURE,
  FETCH_WEBCHAT_REQUEST,
  FETCH_WEBCHAT_SUCCESS,
  FetchAllFailure,
  FetchAllRequest,
  FetchAllRequestPayload,
  FetchOneRequestPayload,
  FetchAllSuccess,
  FetchAllSuccessPayload,
  FetchOneRequest,
  FetchOneSuccessPayload,
  FetchOneSuccess,
  FetchOneFailure,
  FetchPublishStatus,
  FetchPublishStatusFailure,
  FetchPublishStatusSuccess,
  FetchPublishStatusSuccessPayload,
  FetchUseCasesFailure,
  FetchUseCasesRequest,
  FetchUseCasesSuccess,
  FetchUseCasesSuccessPayload,
  FetchWebchat,
  FetchWebchatFailure,
  FetchWebchatSuccess,
  FetchWebchatSuccessPayload,
  GetLastBot,
  Publish,
  PUBLISH_FAILURE,
  PUBLISH_REQUEST,
  PUBLISH_SUCCESS,
  PublishFailure,
  PublishPayload,
  PublishSuccess,
  PublishSuccessPayload,
  REGISTER_PUBLISH_STATUS,
  RegisterCreatedBotEnded,
  RegisterCreatedBotFailure,
  RegisterCreatedBotRequest,
  RegisterCreatedBotSuccess,
  RegisterCreatedBotSuccessPayload,
  RegisterPublishStatus,
  SAVE_WEBCHAT,
  SAVE_WEBCHAT_FAILURE,
  SAVE_WEBCHAT_SUCCESS,
  SaveWebchat,
  SaveWebchatFailure,
  SaveWebchatPayload,
  SaveWebchatSuccess,
  SaveWebchatSuccessPayload,
  Select,
  SELECT,
  SelectPayload,
  UNREGISTER_PUBLISH_STATUS,
  UnregisterPublishStatus,
  UPDATE_WEBCHAT,
  UpdateAvatar,
  UpdateAvatarPayload,
  UpdateBotName,
  UpdateBotNamePayload,
  UpdateGreetings,
  UpdateGreetingsPayload,
  UpdateLanguage,
  UpdateLanguagePayload,
  UpdateTheme,
  UpdateThemePayload,
  UpdateUseCase,
  UpdateUseCasePayload,
  UpdateWebchat,
  UpdateWebchatPayload
} from './types'

export const saveWebchat: (payload: SaveWebchatPayload) => SaveWebchat = payload => ({
  type: SAVE_WEBCHAT,
  payload
})

export const saveWebchatSuccess: (payload: SaveWebchatSuccessPayload) => SaveWebchatSuccess = payload => ({
  type: SAVE_WEBCHAT_SUCCESS,
  payload
})

export const saveWebchatFailure: (payload: ServerFailure) => SaveWebchatFailure = payload => ({
  type: SAVE_WEBCHAT_FAILURE,
  payload
})

export const updateWebchat: (payload: UpdateWebchatPayload) => UpdateWebchat = payload => ({
  type: UPDATE_WEBCHAT,
  payload
})

export const fetchWebchat: () => FetchWebchat = () => ({
  type: FETCH_WEBCHAT_REQUEST
})

export const fetchWebchatFailure: (error: ServerFailure) => FetchWebchatFailure = payload => ({
  type: FETCH_WEBCHAT_FAILURE,
  payload
})

export const fetchWebchatSuccess: (payload: FetchWebchatSuccessPayload) => FetchWebchatSuccess = payload => ({
  type: FETCH_WEBCHAT_SUCCESS,
  payload
})

export const cleanWebchat: () => CleanWebchat = () => ({
  type: CLEAN_WEBCHAT
})

export const fetchAll: (payload: FetchAllRequestPayload) => FetchAllRequest = payload => ({
  type: FETCH_ALL_REQUEST,
  payload
})

export const fetchAllSuccess: (payload: FetchAllSuccessPayload) => FetchAllSuccess = payload => ({
  type: FETCH_ALL_SUCCESS,
  payload
})

export const fetchAllFailure: (payload: ServerFailure) => FetchAllFailure = payload => ({
  type: FETCH_ALL_FAILURE,
  payload
})

export const fetchOne: (payload: FetchOneRequestPayload) => FetchOneRequest = payload => ({
  type: FETCH_ONE_REQUEST,
  payload
})

export const fetchOneSuccess: (payload: FetchOneSuccessPayload) => FetchOneSuccess = payload => ({
  type: FETCH_ONE_SUCCESS,
  payload
})

export const fetchOneFailure: (payload: ServerFailure) => FetchOneFailure = payload => ({
  type: FETCH_ONE_FAILURE,
  payload
})

export const updateBotName: (payload: UpdateBotNamePayload) => UpdateBotName = payload => ({
  type: BOT_CREATION_UPDATE_BOTNAME,
  payload
})

export const updateGreetings: (payload: UpdateGreetingsPayload) => UpdateGreetings = payload => ({
  type: BOT_CREATION_UPDATE_GREETINGS,
  payload
})

export const updateLanguage: (payload: UpdateLanguagePayload) => UpdateLanguage = payload => ({
  type: BOT_CREATION_UPDATE_LANGUAGE,
  payload
})

export const updateTheme: (payload: UpdateThemePayload) => UpdateTheme = payload => ({
  type: BOT_CREATION_UPDATE_THEME,
  payload
})

export const updateAvatar: (payload: UpdateAvatarPayload) => UpdateAvatar = payload => ({
  type: BOT_CREATION_UPDATE_AVATAR,
  payload
})

export const updateUseCase: (payload: UpdateUseCasePayload) => UpdateUseCase = payload => ({
  type: BOT_CREATION_UPDATE_USE_CASE,
  payload
})

export const getLastBot: () => GetLastBot = () => ({
  type: BOT_CREATION_GET_LAST_BOT
})

export const fetchPublishStatus: () => FetchPublishStatus = () => ({
  type: FETCH_PUBLISH_STATUS_REQUEST
})

export const fetchPublishStatusSuccess: (payload: FetchPublishStatusSuccessPayload) => FetchPublishStatusSuccess = payload => ({
  type: FETCH_PUBLISH_STATUS_SUCCESS,
  payload
})

export const fetchPublishStatusFailure: (payload: ServerFailure) => FetchPublishStatusFailure = payload => ({
  type: FETCH_PUBLISH_STATUS_FAILURE,
  payload
})

export const publishRequest: (payload: PublishPayload) => Publish = payload => ({
  type: PUBLISH_REQUEST,
  payload
})

export const publishSuccess: (payload: PublishSuccessPayload) => PublishSuccess = payload => ({
  type: PUBLISH_SUCCESS,
  payload
})

export const publishFailure: (payload: ServerFailure) => PublishFailure = payload => ({
  type: PUBLISH_FAILURE,
  payload
})

export const select: (payload: SelectPayload) => Select = payload => ({
  type: SELECT,
  payload
})

export const registerPublishStatus: () => RegisterPublishStatus = () => ({
  type: REGISTER_PUBLISH_STATUS
})

export const unregisterPublishStatus: () => UnregisterPublishStatus = () => ({
  type: UNREGISTER_PUBLISH_STATUS
})

export const registerCreatedBotRequest: () => RegisterCreatedBotRequest = () => ({
  type: BOT_CREATION_REQUEST
})

export const registerCreatedBotSuccess: (payload: RegisterCreatedBotSuccessPayload) => RegisterCreatedBotSuccess = payload => ({
  type: BOT_CREATION_SUCCESS,
  payload
})

export const registerCreatedBotFailure: (payload: ServerFailure) => RegisterCreatedBotFailure = payload => ({
  type: BOT_CREATION_FAILURE,
  payload
})

export const registerCreatedBotEnded: () => RegisterCreatedBotEnded = () => ({
  type: BOT_CREATION_ENDED
})

export const fetchUseCases: () => FetchUseCasesRequest = () => ({
  type: FETCH_USE_CASES_REQUEST
})

export const fetchUseCasesSuccess: (payload: FetchUseCasesSuccessPayload) => FetchUseCasesSuccess = payload => ({
  type: FETCH_USE_CASES_SUCCESS,
  payload
})

export const fetchUseCasesFailure: (payload: ServerFailure) => FetchUseCasesFailure = payload => ({
  type: FETCH_USE_CASES_FAILURE,
  payload
})

export {
  BOT_CREATION_ENDED,
  BOT_CREATION_FAILURE,
  BOT_CREATION_GET_LAST_BOT,
  BOT_CREATION_REQUEST,
  BOT_CREATION_SUCCESS,
  BOT_CREATION_UPDATE_AVATAR,
  BOT_CREATION_UPDATE_BOTNAME,
  BOT_CREATION_UPDATE_GREETINGS,
  BOT_CREATION_UPDATE_LANGUAGE,
  BOT_CREATION_UPDATE_THEME,
  BOT_CREATION_UPDATE_USE_CASE,
  CLEAN_WEBCHAT,
  FETCH_ALL_FAILURE,
  FETCH_ALL_REQUEST,
  FETCH_ALL_SUCCESS,
  FETCH_ONE_FAILURE,
  FETCH_ONE_REQUEST,
  FETCH_ONE_SUCCESS,
  FETCH_PUBLISH_STATUS_FAILURE,
  FETCH_PUBLISH_STATUS_REQUEST,
  FETCH_PUBLISH_STATUS_SUCCESS,
  FETCH_USE_CASES_FAILURE,
  FETCH_USE_CASES_REQUEST,
  FETCH_USE_CASES_SUCCESS,
  FETCH_WEBCHAT_REQUEST,
  FETCH_WEBCHAT_SUCCESS,
  FETCH_WEBCHAT_FAILURE,
  PUBLISH_FAILURE,
  PUBLISH_REQUEST,
  PUBLISH_SUCCESS,
  REGISTER_PUBLISH_STATUS,
  SAVE_WEBCHAT,
  SAVE_WEBCHAT_SUCCESS,
  SAVE_WEBCHAT_FAILURE,
  SELECT,
  UNREGISTER_PUBLISH_STATUS,
  UPDATE_WEBCHAT
}
