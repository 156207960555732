export const FETCH_NEXT_REQUEST = 'log/FETCH_NEXT_REQUEST'
export const FETCH_PREV_REQUEST = 'log/FETCH_PREV_REQUEST'
export const FETCH_SUCCESS = 'log/FETCH_SUCCESS'
export const FETCH_FAILURE = 'log/FETCH_FAILURE'

export const fetchNext = () => {
  return { type: FETCH_NEXT_REQUEST }
}

export const fetchPrevious = () => {
  return { type: FETCH_PREV_REQUEST }
}

export const fetchSuccess = pagination => {
  return { type: FETCH_SUCCESS, payload: pagination }
}

export const fetchFailure = error => {
  return { type: FETCH_FAILURE, payload: error }
}
