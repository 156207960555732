import * as React from 'react'
import * as R from 'ramda'
import { Dropdown, Popup } from 'semantic-ui-react'
import { remoteData } from '@sbizeul/fp-flow'

import keyword from 'modules/keyword'
import { toMapByWantedKey } from 'helpers/array'

import { get } from 'helpers/map'
import { renderFailure, renderInlineLoader } from 'helpers/react'

import { truncate } from '../../models'
import './styles.css'

const KeywordDropdown = ({ onSelectKeyword, selectedKeyword, keywords }) => {
  const arrayOfKeywords = R.pipe(
    remoteData.getOrElse(() => []),
    keyword.models.sortByAlphabetical('name')
  )(keywords)
  const mapKeyword = toMapByWantedKey(arrayOfKeywords, 'name')
  const keywordsOptions = arrayOfKeywords.map((keyword, index) => ({
    key: index,
    text: (
      <Popup
        disabled={keyword.name.length <= 30}
        trigger={<p className='quickReply-answer-dropdown-text'>{truncate(30)(keyword.name)}</p>}
        content={keyword.name}
        position='top right'
      />
    ),
    value: keyword.name
  }))

  const renderDropDown = () => (
    <Dropdown
      className='QuickReply-answer-dropdown'
      scrolling
      clearable
      selection
      onChange={(evt, { value }) => onSelectKeyword(get(value, mapKeyword))}
      value={selectedKeyword ? selectedKeyword.name : ''}
      placeholder='Select a keyword'
      options={keywordsOptions}
    />
  )

  return remoteData.fold({
    Success: renderDropDown,
    NotAsked: () => renderInlineLoader('Please wait...'),
    Loading: () => renderInlineLoader('Please wait...'),
    Failure: renderFailure
  })(keywords)
}

export default KeywordDropdown
