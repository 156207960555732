import reducer from './reducer'
import * as selectors from './selectors'
import * as saga from './saga'
import * as actions from './actions'
import ChannelSelect from './components/ChannelSelect'

export default {
  actions,
  saga,
  reducer,
  selectors,
  components: {
    ChannelSelect
  }
}
