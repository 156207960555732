import React from 'react'

import './styles.css'

const MessageText = ({ text }) => (
  <p className='Message-text'>{text}</p>
)

const MessageQr = ({ type, quick_replies }) => (
  <ul className='Message-Qr'>
    {quick_replies.map(conv => (
      <li>{conv.title}</li>
    ))}
  </ul>
)

const MessageImage = ({ image }) => (
  <p className='Message-text'><img src={image} alt='pic'/></p>
)

const Buttons = ({ buttons, text }) => (
  <div className='Message-buttons-container'>
  {text&& <p>{text}</p>}
  {buttons.map(button => (
    <span>{button.title} {(button.type === 'web_url') ? '🔗' : ''}{(button.type === 'postback') ? '❓' : ''}</span>
  ))}
  </div>
)

const MessageButtons = ({ buttons, text }) => (
  <div className='Message-buttons'>
    <Buttons buttons={buttons} text={text} />
  </div>
)

const MessageCarrousel = ({ carrousel }) => (
  <div className='Message-carrousel'>
    <ul> 
    {carrousel.map(card => (
      <li>
        {card.image_url && <img src={card.image_url} alt={card.image_url} />}
        {card.title && <h3>{card.title}</h3>}
        {card.subtitle && <p>{card.subtitle}</p>}
        {card.description && <p>{card.description}</p>}
        {card.buttons && card.buttons.length > 0 && <Buttons buttons={card.buttons} />}
      </li>
    ))}
    </ul>
  </div>
)

const MessagePayloadClicked = ({ payload_title }) => (
  <p className='Message-payload-clicked'>{payload_title}</p>
)

const Message = ({ date, is_bot, text, type, quick_replies, payload, image, carrousel, buttons }) => {
  const payloadTitle = (payload && payload[0] && payload[0].title) ? payload[0].title : undefined;
  return (<li className={`Message ${is_bot ? '' : 'Message-user'}`}>
    {text && date && <p className='Message-date'><span>{date}</span></p>}
    {text && !payloadTitle && !buttons && <MessageText text={text} />}
    {image && <MessageImage image={image} />}
    {buttons && buttons.length > 0 && <MessageButtons buttons={buttons} text={text} />}
    {carrousel && <MessageCarrousel carrousel={carrousel} />}
    {type && type==='quick_reply' && <MessageQr type={type} quick_replies={quick_replies} />}
    {payloadTitle && <MessagePayloadClicked payload_title={payloadTitle} />}
  </li>)
  }

export default Message
