import * as actions from './actions'
import * as saga from './saga'
import * as selectors from './selectors'
import * as ui from './reducer.ui'

import RegisterContainer from './containers/RegisterContainer'

export default {
  actions,
  saga,
  selectors,
  reducers: {
    ui
  },
  components: {
    Register: RegisterContainer
  }
}
