import types from './types'

const initialState = {
  fetching: false,
  fetchingNew: false,
  fetchingPrevious: false,
  fetchingByHour: false,
  fetchingByDay: false,
  data: [],
  new: []
}

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.FETCH_USERS_REQUEST:
    return {
      ...state,
      fetching: true,
      count: null,
      groupedByDay: null
    }
  case types.FETCH_USERS_NO_DATA:
    return {
      ...state,
      fetching: false
    }
  case types.FETCH_USERS_SUCCESS:
    return {
      ...state,
      fetching: false,
      data: action.payload.data,
      count: action.payload.count,
      groupedByDay: action.payload.usersByDay
    }

  case types.FETCH_NEW_USERS_REQUEST:
    return {
      ...state,
      fetchingNew: true
    }
  case types.FETCH_NEW_USERS_NO_DATA:
    return {
      ...state,
      fetchingNew: false
    }
  case types.FETCH_NEW_USERS_SUCCESS:
    return {
      ...state,
      fetchingNew: false,
      new: action.payload
    }

  case types.FETCH_USERS_PREVIOUS_PERIOD_REQUEST:
    return {
      ...state,
      fetchingPreviousUsers: true,
      countPreviousPeriod: null
    }
  case types.FETCH_USERS_PREVIOUS_PERIOD_NO_DATA:
    return {
      ...state,
      fetchingPreviousUsers: false
    }
  case types.FETCH_USERS_PREVIOUS_PERIOD_SUCCESS:
    return {
      ...state,
      fetchingPreviousUsers: false,
      countPreviousPeriod: action.payload.count
    }

  case types.FETCH_USERS_BY_HOUR_REQUEST:
    return {
      ...state,
      fetchingByHour: true,
      groupedByHour: null,
      byHour: null
    }
  case types.FETCH_USERS_BY_HOUR_NO_DATA:
    return {
      ...state,
      fetchingByHour: false
    }
  case types.FETCH_USERS_BY_HOUR_SUCCESS:
    return {
      ...state,
      fetchingByHour: false,
      groupedByHour: action.payload.usersByHour,
      byHour: action.payload.usersByHour
    }

  case types.FETCH_USERS_AVERAGE_BY_DAY_REQUEST:
    return {
      ...state,
      fetchingByDay: true,
      byDay: null
    }
  case types.FETCH_USERS_AVERAGE_BY_DAY_NO_DATA:
    return {
      ...state,
      fetchingByDay: false
    }
  case types.FETCH_USERS_AVERAGE_BY_DAY_SUCCESS:
    return {
      ...state,
      fetchingByDay: false,
      byDay: action.payload.usersAverageByDay
    }

  default:
    return state
  }
}

export default usersReducer
