
import React from 'react'
import SlidingPane from 'react-sliding-pane'
import { remoteData } from '@sbizeul/fp-flow'
import { Loader, Empty } from '../../../../components/Loading'

export default class TestPanel extends React.Component {
  state = {
    isPaneOpen: false
  }

  renderConversation(userInput) {
    return answer => (
      <div data-testid='conversation'>
        <span className='TestPanel-text'>{userInput}</span>
        <span className='TestPanel-answer'>
          {answer} / {this.props.recastAnswerConfidence}
        </span>
      </div>
    )
  }

  renderLoading() {
    return <Loader description={'Loading the data'} />
  }

  renderFailure(errorMessage) {
    return <Empty description={errorMessage} />
  }

  renderNothing() {
    return <div data-testid='nothing' />
  }

  render() {
    return (
      <React.Fragment>
        <button className='TestPanel-button' onClick={() => this.setState({ isPaneOpen: true })}>
          TEST
        </button>
        <SlidingPane
          isOpen={this.state.isPaneOpen}
          width='400px'
          onRequestClose={() => {
            this.setState({ isPaneOpen: false })
          }}
        >
          {remoteData.fold(
            {
              Success: this.renderConversation(this.props.userInput),
              Loading: this.renderLoading,
              Failure: this.renderFailure,
              NotAsked: this.renderNothing
            },
            this.props.recastAnswer
          )}
          <input
            onKeyDown={e => (e.key === 'Enter' ? this.props.onEnterKeyPressed() : null)}
            autoFocus
            data-testid='input'
            className='TestPanel-input'
            placeholder='Type a sentence...'
            onChange={this.props.onTextChange}
            type='text'
          />
        </SlidingPane>
      </React.Fragment>
    )
  }
}
