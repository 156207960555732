const FETCH_SATISFACTION_REQUEST = 'tcfdash/analytics/FETCH_SATISFACTION_REQUEST'
const FETCH_SATISFACTION_SUCCESS = 'tcfdash/analytics/FETCH_SATISFACTION_SUCCESS'
const FETCH_SATISFACTION_NO_DATA = 'tcfdash/analytics/FETCH_SATISFACTION_NO_DATA'

const FETCH_UNSATISFACTION_REQUEST = 'tcfdash/analytics/FETCH_UNSATISFACTION_REQUEST'
const FETCH_UNSATISFACTION_SUCCESS = 'tcfdash/analytics/FETCH_UNSATISFACTION_SUCCESS'
const FETCH_UNSATISFACTION_NO_DATA = 'tcfdash/analytics/FETCH_UNSATISFACTION_NO_DATA'

const FETCH_GLOBAL_SATISFACTION_REQUEST = 'tcfdash/analytics/FETCH_GLOBAL_SATISFACTION_REQUEST'
const FETCH_GLOBAL_SATISFACTION_SUCCESS = 'tcfdash/analytics/FETCH_GLOBAL_SATISFACTION_SUCCESS'
const FETCH_GLOBAL_SATISFACTION_FAILURE = 'tcfdash/analytics/FETCH_GLOBAL_SATISFACTION_FAILURE'
export default {
  FETCH_SATISFACTION_REQUEST,
  FETCH_SATISFACTION_SUCCESS,
  FETCH_SATISFACTION_NO_DATA,

  FETCH_GLOBAL_SATISFACTION_REQUEST,
  FETCH_GLOBAL_SATISFACTION_SUCCESS,
  FETCH_GLOBAL_SATISFACTION_FAILURE,

  FETCH_UNSATISFACTION_REQUEST,
  FETCH_UNSATISFACTION_SUCCESS,
  FETCH_UNSATISFACTION_NO_DATA
}
