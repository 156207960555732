import React from 'react'
import { Grid, Segment } from 'semantic-ui-react'

import MessagesSentSpline from '../../../components/Spline/MessagesSentByUsersSpline'

import '../../index.css'


const style = {
  borderRadius: '3px',
  border: 'none',
  boxShadow: '0 2px 4px rgba(50, 50, 93,.1)',
  padding: '5px'
}

const Messages = ({
  messagesData
}) => {
  return(
    <Grid.Row>
      <Grid.Column>
        <h1>Messages Sent By Users</h1>
        <Segment style={style}>
          <MessagesSentSpline messagesTotal={messagesData} />
        </Segment>
      </Grid.Column>
    </Grid.Row>
)}

export default Messages
