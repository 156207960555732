import { OpeningStatusInfo, SAVE_COLLAPSIBLE_SECTION_OPENING_STATUS, SAVE_COLLAPSIBLE_SECTION_OPENING_STATUS_SUCCESS } from './types'

export const saveOpeningStatus = (payload: OpeningStatusInfo) => ({
  type: SAVE_COLLAPSIBLE_SECTION_OPENING_STATUS,
  payload
})

export const saveOpeningStatusSuccess = (payload: OpeningStatusInfo) => ({
  type: SAVE_COLLAPSIBLE_SECTION_OPENING_STATUS_SUCCESS,
  payload
})
