
import Conversations from '../components/Conversations'
import { connect } from 'react-redux'
import { getConversations, getSelectedConversation, getSelectedID } from '../selectors'

import { fetchConversationsRequest, select } from '../actions'
import bot from '../../bot'

const mapStateToProps = state => ({
  botId: bot.selectors.getSelectedId(state),
  conversations: getConversations(state),
  selectedID: getSelectedID(state),
  selectedConversation: getSelectedConversation(state)
})

const mapDispatchToProps = {
  fetchConversations: fetchConversationsRequest,
  setActiveConversation: select
}

const ConnectedConversations = connect(mapStateToProps, mapDispatchToProps)(Conversations)
export default ConnectedConversations
