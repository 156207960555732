import { ServerFailure } from 'types'

import {
  AnalyticRates,
  FetchHealthRateSuccess,
  FetchHealthRateRequest,
  FetchHealthRateFailure,
  ChangeDatesParams,
  WebchatStats,
  FetchWebchatstatsRequest,
  FetchWebchatstatsSuccess,
  FetchWebchatstatsFailure
} from './types'

const FETCH_HEALTH_RATE_REQUEST = 'analytics/FETCH_HEALTH_RATE_REQUEST'
const FETCH_HEALTH_RATE_SUCCESS = 'analytics/FETCH_HEALTH_RATE_SUCCESS'
const FETCH_HEALTH_RATE_FAILURE = 'analytics/FETCH_HEALTH_RATE_FAILURE'

const FETCH_WEBCHAT_STATS_REQUEST = 'analytics/FETCH_WEBCHAT_STATS_REQUEST'
const FETCH_WEBCHAT_STATS_SUCCESS = 'analytics/FETCH_WEBCHAT_STATS_SUCCESS'
const FETCH_WEBCHAT_STATS_FAILURE = 'analytics/FETCH_WEBCHAT_STATS_FAILURE'

const FILTER_CHANGE_DATE = 'analytics/FILTER_CHANGE_DATE'

export const changeDates = (newDates: ChangeDatesParams) => {
  return {
    type: FILTER_CHANGE_DATE,
    payload: newDates
  }
}

const fetchHealthRateRequest: () => FetchHealthRateRequest =
  () => {
    return {
      type: FETCH_HEALTH_RATE_REQUEST
    }
  }

const fetchHealthRateSuccess: (analyticsRates: AnalyticRates) => FetchHealthRateSuccess =
  analyticsRates => {
    return {
      type: FETCH_HEALTH_RATE_SUCCESS,
      payload: analyticsRates
    }
  }

const fetchHealthRateFailure: (error: ServerFailure) => FetchHealthRateFailure = error => {
  return {
    type: FETCH_HEALTH_RATE_FAILURE,
    payload: error
  }
}

const fetchWebchatstatsRequest: () => FetchWebchatstatsRequest =
  () => {
    return {
      type: FETCH_WEBCHAT_STATS_REQUEST
    }
  }

const fetchWebchatstatsSuccess: (analyticsRates: WebchatStats) => FetchWebchatstatsSuccess =
  analyticsRates => {
    return {
      type: FETCH_WEBCHAT_STATS_SUCCESS,
      payload: analyticsRates
    }
  }

const fetchWebchatstatsFailure: (error: ServerFailure) => FetchWebchatstatsFailure = error => {
  return {
    type: FETCH_WEBCHAT_STATS_FAILURE,
    payload: error
  }
}

export {
  FETCH_HEALTH_RATE_REQUEST,
  FETCH_HEALTH_RATE_SUCCESS,
  FETCH_HEALTH_RATE_FAILURE,
  FETCH_WEBCHAT_STATS_REQUEST,
  FETCH_WEBCHAT_STATS_SUCCESS,
  FETCH_WEBCHAT_STATS_FAILURE,
  FILTER_CHANGE_DATE,
  fetchHealthRateRequest,
  fetchHealthRateSuccess,
  fetchHealthRateFailure,
  fetchWebchatstatsRequest,
  fetchWebchatstatsSuccess,
  fetchWebchatstatsFailure
}
