import React from 'react'
import * as R from 'ramda'
import { FaTimes } from 'react-icons/fa'

import TextInput from '../../../components/TextInput'
import Checkbox from '../../../components/Checkbox'

import './AnswerButton.css'

export default function AnswerButton(props) {
  const removeAnswer = () => {
    props.removeAnswer(props.button.id, props.intentSlug)
  }

  const updateText = text => {
    const lens = R.lensPath(['objectAttachment', 'text', 0])
    const button = R.set(lens, text, props.button)
    props.updateAnswer(button, props.intentSlug)
  }

  const updateTitle = title => {
    const lens = R.lensPath(['objectAttachment', 'buttons', 0, 'title', 0])
    const button = R.set(lens, title, props.button)
    props.updateAnswer(button, props.intentSlug)
  }

  const updateUrl = url => {
    const lens = R.lensPath(['objectAttachment', 'buttons', 0, 'url', 0])
    const button = R.set(lens, url, props.button)
    props.updateAnswer(button, props.intentSlug)
  }

  const updateTarget = ev => {
    const target = ev.target.checked ? 'blank' : 'self'
    const lens = R.lensPath(['objectAttachment', 'buttons', 0, 'target'])
    const button = R.set(lens, target, props.button)
    props.updateAnswer(button, props.intentSlug)
  }

  const { objectAttachment } = props.button

  return (
    <div className='AnswerButton'>
      <div className='AnswerButton-actions'>
        <FaTimes className='AnswerButton-actions--delete' onClick={removeAnswer} />
      </div>
      <div className='AnswerButton-row'>
        <TextInput
          className='AnswerButton-row--text'
          type='rounded'
          name='text'
          value={objectAttachment.text[0]}
          onChangeText={updateText}
          placeholder='Type your message...'
        />
      </div>
      <div className='AnswerButton-row'>
        <TextInput
          className='AnswerButton-row--title'
          type='rounded'
          name='title'
          value={objectAttachment.buttons[0].title[0]}
          onChangeText={updateTitle}
          pattern='^.{1,30}'
          placeholder='Button text displayed to users...'
        />
        <TextInput
          className='AnswerButton-row--url'
          type='rounded'
          name='url'
          value={objectAttachment.buttons[0].url[0]}
          onChangeText={updateUrl}
          placeholder='Your URL'
        />
      </div>
      <div className='AnswerButton-row'>
        <Checkbox
          name='target'
          checked={objectAttachment.buttons[0].target === 'blank'}
          onChange={updateTarget}
          className='AnswerButton-row--target'
          label='URL is opened in a new tab'
        />
      </div>
    </div>
  )
}
