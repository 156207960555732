import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import _ from 'lodash'
import { Form, Button } from 'semantic-ui-react'
import { connect } from 'react-redux'
import contentOperations from '../../../../redux/intents/operations'
import InputField from '../../../components/InputField'

const required = value => (value ? undefined : 'Required')
const noEmpty = value => (_.trim(value) ? undefined : 'No Empty')
const length = value => (value.length > 9 ? undefined : 'Too short, 8 characters')
const maj = value => (value.replace(/[^A-Z]/g, '').length > 0 ? undefined : 'One Uppercase')
const char = value => (value.replace(/[^$,&,@,=,?,!]/g, '').length > 0 ? undefined : 'One Special Char')

const PassForm = ({ createPassword, handleSubmit, pristine, submitting, id }) => (
  <Form autoComplete='off' onSubmit={handleSubmit(createPassword)}>
    <Field
      name='password'
      label='Set up your password'
      component={InputField}
      validate={[required, noEmpty, length, maj, char]}
      type='password'
    />
    <Field
      name='password2'
      component={InputField}
      label='Confirm Password'
      validate={[required, noEmpty, length, maj, char]}
      type='password'
    />

    <Button type='submit' disabled={pristine || submitting}>
      Submit
    </Button>
  </Form>
)

PassForm.propTypes = {
  postPassword: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired
}
const mapDispatchToProps = {
  createPassword: contentOperations.createPassword
}

let InitializeFromStateForm = reduxForm({
  enableReinitialize: true,
  form: 'passForm'
})(PassForm)

InitializeFromStateForm = connect(
  (state, props) => ({
    initialValues: {
      user: props.id
    }
  }),
  {}
)(InitializeFromStateForm)

export default connect(null, mapDispatchToProps)(InitializeFromStateForm)
