import * as R from 'ramda'
import { maybe, remoteData } from '@sbizeul/fp-flow'

import * as answerActions from './actions'
import { contentLens, imagesById } from './models'
import { isValidAnswer } from './models.typed'

export const initialState = {
  answer: maybe.nothing(),
  isValid: true,
  isModified: false,
  imagesById: {},
  simplifiedSkills: remoteData.notAsked(),
  simplifiedTags: remoteData.notAsked()
}

const answer = R.lensProp('answer')
const isValid = R.lensProp('isValid')
const isModified = R.lensProp('isModified')
export const simplifiedSkills = R.lensProp('simplifiedSkills')
export const simplifiedTags = R.lensProp('simplifiedTags')

const updateContent = (state, { payload }) => R.pipe(
  R.set(answer, payload),
  R.set(isValid, maybe.fold(() => false, isValidAnswer, payload)),
  R.set(isModified, true)
  )(state)


const fetchOneRequest = (state, { payload: id }) => R.pipe(
  R.set(answer, maybe.nothing()),
  R.set(isModified, false)
)(state)

const fetchOneSuccess = (state, action) => R.pipe(
  R.set(answer, action.payload),
  R.set(isModified, false)
)(state)

const uploadImage = (state, action) => {
  const image = R.compose(imagesById, R.lensPath([action.payload.id]))
  return R.set(image, remoteData.loading(), state)
}

const updateImageAnswer = (lens, element, action) =>
  R.over(
    lens,
    R.reduce(
      (acc, el) => [
        ...acc,
        el.id === action.payload.id ? R.set(R.lensPath(['objectAttachment', 'imageUrl']), action.payload.url, el) : el
      ],
      []
    ),
    element
  )

const updateQRImageAnswer = (answer, action) => {
  return R.over(
    R.lensProp('buttons'),
    R.map(button => {
      const lens = R.compose(R.lensPath(['answer']), contentLens)
      return R.isNil(R.view(lens, button)) ? button : updateImageAnswer(lens, button, action)
    }),
    answer
  )
}

const uploadImageSuccess = (state, action) => {
  const image = R.compose(imagesById, R.lensPath([action.payload.id]))
  return R.pipe(
    R.set(image, remoteData.success(action.payload.url)),
    R.over(answer,
      maybe.map(ans =>
        ans.type === 'QR' ? updateQRImageAnswer(ans, action) : updateImageAnswer(contentLens, ans, action)
      )
    ),
    R.set(isValid, true)
  )(state)
}

const uploadImageFailure = (state, action) => {
  const image = R.compose(imagesById, R.lensPath([action.payload.id]))
  return R.set(image, remoteData.failure(action.payload.error), state)
}

const fetchAllSimplified = R.set(simplifiedSkills, remoteData.loading())

const fetchAllSimplifiedFailure =
  (action, state) => R.set(simplifiedSkills, remoteData.failure(action.payload), state)

const fetchAllSimplifiedSuccess =
  (action, state) => R.set(simplifiedSkills, remoteData.success(action.payload), state)

const fetchAllSimplifiedTags = R.set(simplifiedTags, remoteData.loading())

const fetchAllSimplifiedTagsFailure =
  (action, state) => R.set(simplifiedTags, remoteData.failure(action.payload), state)

const fetchAllSimplifiedTagsSuccess =
  (action, state) => R.set(simplifiedTags, remoteData.success(action.payload), state)
  
export default function(state = initialState, action) {
  switch (action.type) {
  case answerActions.FETCH_ONE_REQUEST:
    return fetchOneRequest(state, action)
  case answerActions.FETCH_ONE_SUCCESS:
    return fetchOneSuccess(state, action)
  case answerActions.UPDATE_CONTENT:
    return updateContent(state, action)
  case answerActions.UPLOAD_IMAGE_REQUEST:
    return uploadImage(state, action)
  case answerActions.UPLOAD_IMAGE_FAILURE:
    return uploadImageFailure(state, action)
  case answerActions.UPLOAD_IMAGE_SUCCESS:
    return uploadImageSuccess(state, action)
  case answerActions.FETCH_ALL_SIMPLIFIED_SKILLS_REQUEST:
    return fetchAllSimplified(state)
  case answerActions.FETCH_ALL_SIMPLIFIED_SKILLS_FAILURE:
    return fetchAllSimplifiedFailure(action, state)
  case answerActions.FETCH_ALL_SIMPLIFIED_SKILLS_SUCCESS:
    return fetchAllSimplifiedSuccess(action, state)
  case answerActions.FETCH_ALL_SIMPLIFIED_TAGS_REQUEST:
    return fetchAllSimplifiedTags(state)
  case answerActions.FETCH_ALL_SIMPLIFIED_TAGS_FAILURE:
    return fetchAllSimplifiedTagsFailure(action, state)
  case answerActions.FETCH_ALL_SIMPLIFIED_TAGS_SUCCESS:
    return fetchAllSimplifiedTagsSuccess(action, state)
  default:
    return state
  }
}
