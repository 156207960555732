import types from './types'

const fetchEntitiesRequest = () => ({
  type: types.FETCH_ENTITIES_REQUEST
})

const fetchEntitiesSuccess = payload => ({
  type: types.FETCH_ENTITIES_SUCCESS,
  payload
})

const fetchEntitiesFailure = errors => ({
  type: types.FETCH_ENTITIES_NO_DATA,
  errors
})

export default {
  fetchEntitiesRequest,
  fetchEntitiesSuccess,
  fetchEntitiesFailure
}
