import React from 'react'
import { Route } from 'react-router'
import { FiArrowLeft } from 'react-icons/fi'

import './styles.css'

const onBackButtonClick = (history, path) => () => {
  if (typeof path === 'undefined') {
    history.goBack()
    return
  }
  history.push(path)
}

const PureBackButton = ({ history, label, path }) => (
  <div className='QnABackButton'>
    <div className='QnABackButton-content' onClick={onBackButtonClick(history, path)}>
      <FiArrowLeft size='30'/>
    </div>
    <div className='QnABackButton-label'>{label}</div>
  </div>
)

const WrappedBackButton = (props) => (
  <Route render={({ history }) => <PureBackButton history={history} label={props.label} path={props.path} />}/>
)

export {
  PureBackButton,
  WrappedBackButton
}
