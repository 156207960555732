import types from './types'

const initialState = {
  fetching: false
}

const wordsusersReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.FETCH_WORDSUSERS_REQUEST:
    return { ...state, fetching: true, data: null }

  case types.FETCH_WORDSUSERS_NO_DATA:
    return { ...state, fetching: false }

  case types.FETCH_WORDSUSERS_SUCCESS:
    return {
      ...state,
      fetching: false,
      data: action.payload.data
    }

  default:
    return state
  }
}

export default wordsusersReducer
