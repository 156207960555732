import { combineReducers } from 'redux'
import answers from './answers'
import conversations from './conversations'
import demographics from './demographics'
import entities from './entities'
import escaladesHeat from './escaladesHeat'
import escaladesBegin from './escaladesBegin'
import escaladesEnd from './escaladesEnd'
import events from './events'
import intents from './intents'
import messages from './messages'
import delays from './delays'
import messagesHeat from './messagesHeat'
import misunderstood from './misunderstood'
import nothandled from './nothandled'
import payloads from './payloads'
import retention from './retention'
import users from './users'
import wordsusers from './wordsusers'
import referral from './referral'
import satisfaction from './satisfaction'
import words from './words'
import menuPersistent from './menuPersistent'
import funnel from './funnel/reducer'

const analyticsReducer = combineReducers({
  answers,
  conversations,
  demographics,
  entities,
  escaladesHeat,
  escaladesBegin,
  escaladesEnd,
  events,
  intents,
  messages,
  delays,
  messagesHeat,
  misunderstood,
  nothandled,
  payloads,
  retention,
  users,
  wordsusers,
  referral,
  satisfaction,
  words,
  menuPersistent,
  funnel
})

export default analyticsReducer
