const FETCH_REFERRAL_REQUEST = 'tcfdash/analytics/FETCH_REFERRAL_REQUEST'
const FETCH_REFERRAL_SUCCESS = 'tcfdash/analytics/FETCH_REFERRAL_SUCCESS'
const FETCH_REFERRAL_NO_DATA = 'tcfdash/analytics/FETCH_REFERRAL_NO_DATA'
const FETCH_REFERRALUTIL_REQUEST = 'tcfdash/analytics/FETCH_REFERRALUTIL_REQUEST'
const FETCH_REFERRALUTIL_SUCCESS = 'tcfdash/analytics/FETCH_REFERRALUTIL_SUCCESS'
const FETCH_REFERRALUTIL_NO_DATA = 'tcfdash/analytics/FETCH_REFERRALUTIL_NO_DATA'

export default {
  FETCH_REFERRAL_REQUEST,
  FETCH_REFERRAL_SUCCESS,
  FETCH_REFERRAL_NO_DATA,
  FETCH_REFERRALUTIL_REQUEST,
  FETCH_REFERRALUTIL_SUCCESS,
  FETCH_REFERRALUTIL_NO_DATA
}
