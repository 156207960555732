import React, { FC } from 'react'

import './index.css'

type DropdownProps = {
  label: 'Type' | 'Priority'
  selectedValue: string
  options: ReadonlyArray<{ name: string, key: string }>
  onChangeSelectedValue: any
}

export const Dropdown: FC<DropdownProps> = ({
  label,
  selectedValue,
  options,
  onChangeSelectedValue
}) => {
  return (
    <label htmlFor='dropdown-select' className='dropdown__label-container'>
      <span className='dropdown__text'>{label}</span>
      <div className='dropdown__select'>
        <select
          name='dropdown-select'
          id='dropdown-select'
          className='dropdown__standard-select'
          onChange={onChangeSelectedValue}
          value={selectedValue}>
          {options.map(option => {
            return (
              <option value={option.key} className='dropdow__option' key={option.key}>
                {option.name}
              </option>
            )
          })}
        </select>
      </div>
    </label>
  )
}

export default Dropdown
