import { v4 as uuid } from 'uuid'

export const SAVE_ANSWERS_REQUEST = 'answer/SAVE_ANSWERS_REQUEST'
export const SAVE_ANSWERS_SUCCESS = 'answer/SAVE_ANSWERS_SUCCESS'
export const SAVE_ANSWERS_FAILURE = 'answer/SAVE_ANSWERS_FAILURE'
export const DELETE_ANSWERS_REQUEST = 'answer/DELETE_ANSWERS_REQUEST'
export const DELETE_ANSWERS_SUCCESS = 'answer/DELETE_ANSWERS_SUCCESS'
export const DELETE_ANSWERS_FAILURE = 'answer/DELETE_ANSWERS_FAILURE'
export const CREATE_BUTTON = 'answer/CREATE_BUTTON'
export const CREATE_SIMPLE_TEXT = 'answer/CREATE_SIMPLE_TEXT'
export const CREATE_QUICK_REPLY = 'answer/CREATE_QUICK_REPLY'
export const UPDATE_ANSWER = 'answer/UPDATE_ANSWER'
export const REMOVE_ANSWER = 'answer/REMOVE_ANSWER'
export const SET_CUSTOM = 'answer/SET_CUSTOM'
export const RESET_STATE = 'answer/RESET_STATE'

export const saveAnswers = answers => {
  return {
    type: SAVE_ANSWERS_REQUEST,
    payload: answers
  }
}

export const saveAnswersSuccess = () => {
  return { type: SAVE_ANSWERS_SUCCESS }
}

export const saveAnswersFailure = error => {
  return {
    type: SAVE_ANSWERS_FAILURE,
    payload: error
  }
}

export const deleteAnswers = intentSlug => {
  return {
    type: DELETE_ANSWERS_REQUEST,
    payload: intentSlug
  }
}

export const deleteAnswersSuccess = () => {
  return { type: DELETE_ANSWERS_SUCCESS }
}

export const deleteAnswersFailure = error => {
  return {
    type: DELETE_ANSWERS_FAILURE,
    payload: error
  }
}

export const createButton = intentSlug => {
  const answer = {
    id: uuid(),
    type: 'button',
    objectAttachment: {
      text: [''],
      buttons: [
        {
          type: 'web_url',
          url: [''],
          title: [''],
          target: 'self'
        }
      ]
    },
    language: 'fr_FR'
  }
  return {
    type: CREATE_BUTTON,
    payload: {
      answer,
      intentSlug
    }
  }
}

export const createSimpleText = intentSlug => {
  const answer = {
    id: uuid(),
    type: 'text',
    objectAttachment: {
      text: ['']
    },
    language: 'fr_FR'
  }
  return {
    type: CREATE_SIMPLE_TEXT,
    payload: {
      intentSlug,
      answer
    }
  }
}

export const createQuickReply = intentSlug => {
  const answer = {
    id: uuid(),
    type: 'quick_reply',
    objectAttachment: {
      text: [''],
      buttons: [
        {
          type: 'postback',
          title: [''],
          payload: ['']
        }
      ]
    },
    language: 'fr_FR'
  }
  return {
    type: CREATE_QUICK_REPLY,
    payload: {
      answer,
      intentSlug
    }
  }
}

export const updateAnswer = (answer, intentSlug) => {
  return {
    type: UPDATE_ANSWER,
    payload: {
      answer,
      intentSlug
    }
  }
}

export const removeAnswer = (id, intentSlug) => {
  return {
    type: REMOVE_ANSWER,
    payload: {
      id,
      intentSlug
    }
  }
}

export const setCustom = (answers, intentSlug) => {
  return {
    type: SET_CUSTOM,
    payload: {
      answers,
      intentSlug
    }
  }
}

export const resetState = () => {
  return { type: RESET_STATE }
}
