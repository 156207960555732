import * as actions from './actions'
import AddSynonym from './components/AddSynonym'
import * as entity from './reducer.entity'
import * as saga from './saga'
import * as selectors from './selectors'
import Synonyms from './components/Synonyms'

export default {
  actions,
  reducers: {
    entity
  },
  saga,
  selectors,
  components: {
    AddSynonym,
    Synonyms
  }
}
