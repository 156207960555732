import * as React from 'react'
import IndicatorStyled from './styles'

const Indicator = ({ value, Icon }) => {
  return (
    <IndicatorStyled>
      <div className='Navbar-menuItem'>
        <Icon />
      </div>
      <span>{value}</span>
    </IndicatorStyled>
  )
}

export default Indicator
