import moment, { Moment } from 'moment'
import { call, cancel, put, takeLatest, select } from 'redux-saga/effects'

import { maybe } from '@sbizeul/fp-flow'
import * as R from 'ramda'

import Api from 'services/tolkApi'
import bot from 'modules/bot'
import { toShortFormat } from 'helpers/moment'

import * as analyticsAction from './actions'
import { getStartDate, getEndDate } from './selectors'

const isNotValidDate = R.isNil

const oneOfDatesIsNotCorrect = (startDate: Moment, endDate: Moment) => R.or(isNotValidDate(startDate), isNotValidDate(endDate))

export function* fetchHealthRate () {
  const botId = maybe.get(yield select(bot.selectors.getSelectedId))
  const startDate = yield select(getStartDate)
  const endDate = yield select(getEndDate)

  if (oneOfDatesIsNotCorrect(startDate, endDate)) {
    yield cancel()
  }

  const params = {
    start_date: moment(startDate)
      .startOf('day')
      .toISOString(),
    end_date: moment(endDate)
      .endOf('day')
      .toISOString()
  }

  try {
    const { data } = yield call(Api.get, `/bot/${botId}/analytics/health`, { params })
    yield put(analyticsAction.fetchHealthRateSuccess(data))
  } catch (error) {
    yield put(analyticsAction.fetchHealthRateFailure(error))
  }
}

export function* fetchWebchatStats () {
  const botId = maybe.get(yield select(bot.selectors.getSelectedId))
  const startDate = yield select(getStartDate)
  const endDate = yield select(getEndDate)

  if (oneOfDatesIsNotCorrect(startDate, endDate)) {
    yield cancel()
  }

  const params = {
    start: moment(startDate).startOf('day').toISOString(),
    end: moment(endDate).endOf('day').toISOString()
  }

  try {
    const { data } = yield call(Api.get, `/bot/${botId}/analytics/webchat`, { params })
    yield put(analyticsAction.fetchWebchatstatsSuccess(data))
  } catch (error) {
    yield put(analyticsAction.fetchWebchatstatsFailure(error))
  }
}

export function* root () {
  yield takeLatest(analyticsAction.FETCH_HEALTH_RATE_REQUEST, fetchHealthRate)
  yield takeLatest(analyticsAction.FILTER_CHANGE_DATE, fetchHealthRate)
  yield takeLatest(analyticsAction.FETCH_WEBCHAT_STATS_REQUEST, fetchWebchatStats)
  yield takeLatest(analyticsAction.FILTER_CHANGE_DATE, fetchWebchatStats)
}
