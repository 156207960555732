import { Either, RemoteData } from '@sbizeul/fp-flow'
import { Option } from 'fp-ts/lib/Option'

import { Map } from 'helpers/map'
import { Id, ServerFailure } from 'types'
import { ListTrainingQuestionsProps } from '../trainingQuestion/types'

export type AppState = Readonly<{
  entities: Readonly<{
    question: QuestionEntityState
  }>
  ui: Readonly<{
    question: QuestionUiState
  }>
}>

export const QuestionState = {
  ACTIVE: 'active',
  ARCHIVED: 'archived',
  PIN: true,
  UNPIN: false
} as const
export type QuestionState = typeof QuestionState[keyof typeof QuestionState]

export type Question = Readonly<{
  id: Id
  bot_id: Id
  external_id: Id
  answer_id?: Id
  sectionId: Id
  label: string
  state: QuestionState
  ask_feedback: boolean
  description: string
  created_at: number
  updated_at: number
  is_in_tapflow: boolean
  is_in_hot_topics: boolean
  template_id?: string
}>

export type Section = Readonly<{
  id: Id
  bot_id: Id
  label: string
}>

export type LabeledQuestions = Readonly<{
  questions: ReadonlyArray<Question>
  sections: ReadonlyArray<Section>
}>

export type QuestionNames = ReadonlyArray<string>

export type AdvancedSkillNames = ReadonlyArray<string>

export type QuestionReferencesElementsError = Readonly<{
  error_description: string
}>

export type QuestionReferencesElements = Readonly<{
  questionId: string
  questionNames: QuestionNames
  advancedSkillNames: AdvancedSkillNames
}>

export type RemoteDataQuestionReferencesElements = RemoteData<
  QuestionReferencesElementsError,
  QuestionReferencesElements
>

export const FETCH_ONE_REQUEST = 'question/FETCH_ONE_REQUEST'
export const FETCH_ONE_SUCCESS = 'question/FETCH_ONE_SUCCESS'
export const FETCH_ONE_FAILURE = 'question/FETCH_ONE_FAILURE'

export const FETCH_ALL_REQUEST = 'question/FETCH_ALL_REQUEST'
export const FETCH_ALL_SUCCESS = 'question/FETCH_ALL_SUCCESS'
export const FETCH_ALL_FAILURE = 'question/FETCH_ALL_FAILURE'

export const FETCH_LABELED_REQUEST = 'question/FETCH_LABELED_REQUEST'
export const FETCH_LABELED_SUCCESS = 'question/FETCH_LABELED_SUCCESS'
export const FETCH_LABELED_FAILURE = 'question/FETCH_LABELED_FAILURE'

export const FETCH_QUESTION_LINK_REQUEST = 'question/FETCH_QUESTION_REQUEST'
export const FETCH_QUESTION_LINK_SUCCESS = 'question/FETCH_QUESTION_SUCCESS'
export const FETCH_QUESTION_LINK_FAILURE = 'question/FETCH_QUESTION_FAILURE'

export const CREATE_REQUEST = 'question/CREATE_REQUEST'
export const CREATE_SUCCESS = 'question/CREATE_SUCCESS'
export const CREATE_FAILURE = 'question/CREATE_FAILURE'

export const SAVE_ONE_REQUEST = 'question/SAVE_ONE_REQUEST'
export const MOVE_SECTION_REQUEST = 'question/MOVE_SECTION_REQUEST'

export const ARCHIVE_QUESTION_REQUEST = 'question/ARCHIVE_REQUEST'
export const UNARCHIVE_QUESTION_REQUEST = 'question/UNARCHIVE_REQUEST'

export const MERGE_QUESTION_REQUEST = 'question/MERGE_REQUEST'
export const MERGE_QUESTION_SUCCESS = 'question/MERGE_SUCCESS'
export const MERGE_QUESTION_FAILURE = 'question/MERGE_FAILURE'

export const SAVE_ONE_SUCCESS = 'question/SAVE_ONE_SUCCESS'
export const SAVE_ONE_FAILURE = 'question/SAVE_ONE_FAILURE'

export const CREATE_SECTION_REQUEST = 'qa/CREATE_SECTION_REQUEST'
export const CREATE_SECTION_SUCCESS = 'qa/CREATE_SECTION_SUCCESS'
export const CREATE_SECTION_FAILURE = 'qa/CREATE_SECTION_FAILURE'

export const SAVE_SECTION_REQUEST = 'question/SAVE_SECTION_REQUEST'
export const SAVE_SECTION_SUCCESS = 'question/SAVE_SECTION_SUCCESS'
export const SAVE_SECTION_FAILURE = 'question/SAVE_SECTION_FAILURE'

export const DELETE_SECTION_REQUEST = 'question/DELETE_SECTION_REQUEST'
export const DELETE_SECTION_SUCCESS = 'question/DELETE_SECTION_SUCCESS'
export const DELETE_SECTION_FAILURE = 'question/DELETE_SECTION_FAILURE'

export const SELECT = 'question/SELECT'

export const SORT_BY_CREATION_DESC = 'question/SORT_BY_CREATION_DESC'

export type FetchOneRequestParam = Id

export type FetchOneRequest = Readonly<{
  type: typeof FETCH_ONE_REQUEST
  payload: FetchOneRequestParam
}>

export type FetchOneSuccessParam = Question

export type FetchOneSuccess = Readonly<{
  type: typeof FETCH_ONE_SUCCESS
  payload: FetchOneSuccessParam
}>

export type FetchOneFailureParam = Readonly<{ error: ServerFailure; id: Id }>

export type FetchOneFailure = Readonly<{
  type: typeof FETCH_ONE_FAILURE
  payload: FetchOneFailureParam
}>

export type FetchAllRequest = Readonly<{
  type: typeof FETCH_ALL_REQUEST
}>

export type FetchAllSuccessParam = ReadonlyArray<Question>

export type FetchAllSuccess = Readonly<{
  type: typeof FETCH_ALL_SUCCESS
  payload: FetchAllSuccessParam
}>

export type FetchAllFailureParam = ServerFailure

export type FetchAllFailure = Readonly<{
  type: typeof FETCH_ALL_FAILURE
  payload: FetchAllFailureParam
}>

export type FetchLabeledRequest = Readonly<{
  type: typeof FETCH_LABELED_REQUEST
}>

export type FetchLabeledSuccessParam = LabeledQuestions

export type FetchLabeledSuccess = Readonly<{
  type: typeof FETCH_LABELED_SUCCESS
  payload: FetchLabeledSuccessParam
}>

export type FetchLabeledFailureParam = ServerFailure

export type FetchLabeledFailure = Readonly<{
  type: typeof FETCH_LABELED_FAILURE
  payload: FetchLabeledFailureParam
}>
export type FetchQuestionReferencesRequest = Readonly<{
  type: typeof FETCH_QUESTION_LINK_REQUEST
  payload: QuestionId
}>

export type FetchQuestionReferencesSuccessParam = QuestionReferencesElements

export type FetchQuestionReferencesSuccess = Readonly<{
  type: typeof FETCH_QUESTION_LINK_SUCCESS
  payload: FetchQuestionReferencesSuccessParam
}>

export type FetchQuestionReferencesFailureParam = QuestionReferencesElementsError

export type FetchQuestionReferencesFailure = Readonly<{
  type: typeof FETCH_QUESTION_LINK_FAILURE
  payload: FetchQuestionReferencesFailureParam
}>

export type CreateRequestParam = Readonly<{
  question: string
  shouldStayOnPage: boolean
}>

export type CreateRequest = Readonly<{
  type: typeof CREATE_REQUEST
  payload: CreateRequestParam
}>

export type CreateSuccessParam = Id

export type CreateSuccess = Readonly<{
  type: typeof CREATE_SUCCESS
  payload: CreateSuccessParam
}>

export type CreateFailureParam = ServerFailure

export type CreateFailure = Readonly<{
  type: typeof CREATE_FAILURE
  payload: CreateFailureParam
}>

export type SaveOneParam = Readonly<{
  question: Question
  text: string
}>

export type SaveOneRequest = Readonly<{
  type: typeof SAVE_ONE_REQUEST
  payload: SaveOneParam
}>

export type SaveOneSuccessParam = Id

export type SaveOneSuccess = Readonly<{
  type: typeof SAVE_ONE_SUCCESS
  payload: SaveOneSuccessParam
}>

export type SaveOneFailureParam = ServerFailure

export type SaveOneFailure = Readonly<{
  type: typeof SAVE_ONE_FAILURE
  payload: SaveOneFailureParam
}>

export type MoveSectionParam = Readonly<{
  question: Question
  sectionId: Id
}>

export type MoveSectionRequest = Readonly<{
  type: typeof MOVE_SECTION_REQUEST
  payload: MoveSectionParam
}>

export type ArchiveQuestionParam = Question

export type ArchiveQuestionRequest = Readonly<{
  type: typeof ARCHIVE_QUESTION_REQUEST
  payload: ArchiveQuestionParam
}>

export type UnarchiveQuestionParam = Question

export type UnarchiveQuestionRequest = Readonly<{
  type: typeof UNARCHIVE_QUESTION_REQUEST
  payload: UnarchiveQuestionParam
}>

export type MergeQuestionParam = {
  sourceQuestionId: Id
  targetQuestionId: Id
}

export type MergeQuestionRequest = Readonly<{
  type: typeof MERGE_QUESTION_REQUEST
  payload: MergeQuestionParam
}>

export type MergeQuestionSuccessParam = Id

export type MergeQuestionSuccess = Readonly<{
  type: typeof MERGE_QUESTION_SUCCESS
  payload: MergeQuestionSuccessParam
}>

export type MergeQuestionFailureParam = ServerFailure

export type MergeQuestionFailure = Readonly<{
  type: typeof MERGE_QUESTION_FAILURE
  payload: MergeQuestionFailureParam
}>

export type CreateSectionParam = Readonly<{
  label: string
}>

export type CreateSection = Readonly<{
  type: typeof CREATE_SECTION_REQUEST
  payload: CreateSectionParam
}>

export type CreateSectionSuccessParam = Id

export type CreateSectionSuccess = Readonly<{
  type: typeof CREATE_SECTION_SUCCESS
  payload: CreateSectionSuccessParam
}>

export type CreateSectionFailureParam = ServerFailure

export type CreateSectionFailure = Readonly<{
  type: typeof CREATE_SECTION_FAILURE
  payload: CreateSectionFailureParam
}>

export type SaveSectionParam = Section

export type SaveSectionRequest = Readonly<{
  type: typeof SAVE_SECTION_REQUEST
  payload: SaveSectionParam
}>

export type SaveSectionSuccessParam = Id

export type SaveSectionSuccess = Readonly<{
  type: typeof SAVE_SECTION_SUCCESS
  payload: SaveSectionSuccessParam
}>

export type SaveSectionFailureParam = ServerFailure

export type SaveSectionFailure = Readonly<{
  type: typeof SAVE_SECTION_FAILURE
  payload: SaveSectionFailureParam
}>

export type DeleteSectionParam = Id

export type DeleteSectionRequest = Readonly<{
  type: typeof DELETE_SECTION_REQUEST
  payload: DeleteSectionParam
}>

export type DeleteSectionSuccessParam = boolean

export type DeleteSectionSuccess = Readonly<{
  type: typeof DELETE_SECTION_SUCCESS
  payload: DeleteSectionSuccessParam
}>

export type DeleteSectionFailureParam = ServerFailure

export type DeleteSectionFailure = Readonly<{
  type: typeof DELETE_SECTION_FAILURE
  payload: DeleteSectionFailureParam
}>

export type SortByCreationDesc = Readonly<{
  type: typeof SORT_BY_CREATION_DESC
}>

export type SelectQuestionParam = Id

export type SelectQuestion = Readonly<{
  type: typeof SELECT
  payload: SelectQuestionParam
}>

export type QuestionId = Id

export type QuestionEntityAction =
  | FetchOneRequest
  | FetchOneSuccess
  | FetchOneFailure
  | FetchAllRequest
  | FetchAllSuccess
  | FetchAllFailure
  | FetchLabeledRequest
  | FetchLabeledSuccess
  | FetchLabeledFailure
  | FetchQuestionReferencesRequest
  | FetchQuestionReferencesSuccess
  | FetchQuestionReferencesFailure
  | SaveOneRequest
  | SaveOneSuccess
  | SaveOneFailure
  | SortByCreationDesc

export type QuestionUiAction =
  | CreateRequest
  | CreateSuccess
  | CreateFailure
  | SelectQuestion

export type QuestionEntityState = Readonly<{
  all: RemoteData<any, Map<Question>>
  allLabeledQuestions: RemoteData<ServerFailure, LabeledQuestions>
  questionReferences: RemoteDataQuestionReferencesElements
  byId: Map<RemoteData<any, Question>>
}>

export type QuestionStateActivity = Readonly<{
  id: Id
  state: QuestionState
}>
export type UpdatingQuestion = Option<QuestionStateActivity>

export type QuestionUiState = Readonly<{
  isPosting: Either<ServerFailure, boolean>
}>

type TrainingQuestionsProps = Omit<ListTrainingQuestionsProps, 'questionId'>

export type QuestionProps = TrainingQuestionsProps &
  Readonly<{
    questions: ReadonlyArray<Question>
    question: Question
    sections: ReadonlyArray<Section>
    isDeleting: Either<ServerFailure, boolean>
    isUpdatingQuestion: Either<ServerFailure, UpdatingQuestion>
    questionReferences: RemoteDataQuestionReferencesElements

    navigateToQuestion: (questionId: Id) => unknown
    deleteQa: (param: Id) => unknown
    saveQuestion: (param: SaveOneParam) => unknown
    moveSection: (param: MoveSectionParam) => unknown
    archiveQuestion: (param: ArchiveQuestionParam) => unknown
    mergeQuestion: (param: MergeQuestionParam) => unknown
    unarchiveQuestion: (param: UnarchiveQuestionParam) => unknown
    fetchQuestionReferences: (questionId: QuestionId) => unknown
  }>
