import {
  pinQuestionToHottopic,
  unpinQuestionToHottopic
} from 'modules/question/actions'
import { Question, QuestionState } from 'modules/question/types'
import React, { useState } from 'react'
import { MdDone } from 'react-icons/md'
import { useDispatch } from 'react-redux'
import { CLOSE_DROPDOWN } from '.'
import { PinProps } from './types'

const MAX_SLOTS = 3
export const RemainingSlots = ({ count }: { count: number }) => (
  <span>
    ({count}/{MAX_SLOTS})
  </span>
)

export const PIN_TO_HOTTOPIC_REQUEST = 'question/PIN_TO_HOTTOPIC_REQUEST'
export const UNPIN_TO_HOTTOPIC_REQUEST = 'question/UNPIN_TO_HOTTOPIC_REQUEST'

export const PinToHottopic = ({
  assignedSlots = 0,
  question,
  setPinDropdownState,
  toggleParentState,
  setActionDescriptionText
}: PinProps) => {
  const dispatch = useDispatch()
  const [isInHotTopics, setIsInHotTopics] = useState(
    !!question.is_in_hot_topics
  )
  const unpin = (question: Question) => {
    dispatch(
      unpinQuestionToHottopic({
        ...question,
        is_in_hot_topics: QuestionState.UNPIN
      })
    )
    toggleParentState(QuestionState.UNPIN)
    setIsInHotTopics(QuestionState.UNPIN)
  }
  const pin = (question: Question) => {
    dispatch(
      pinQuestionToHottopic({
        ...question,
        is_in_hot_topics: QuestionState.PIN
      })
    )
    toggleParentState(QuestionState.PIN)
    setIsInHotTopics(QuestionState.PIN)
  }

  const onClick = () => {
    setPinDropdownState(CLOSE_DROPDOWN)
    setActionDescriptionText('')
    if (isInHotTopics) {
      unpin(question)
      return
    }
    if (!isInHotTopics && assignedSlots < 3) {
      pin(question)
      return
    }
  }

  const reachMaxSlots = assignedSlots >= MAX_SLOTS
  const NOTHING = ''
  return (
    <div
      role="option"
      aria-selected={isInHotTopics}
      className={`${reachMaxSlots ? 'text-muted' : NOTHING} item`}
      onClick={onClick}
    >
      {isInHotTopics && <MdDone />}
      <span className="text">
        Widget Hot Topic <RemainingSlots count={assignedSlots} />
      </span>
    </div>
  )
}
