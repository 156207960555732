import React from 'react'
import { Switch, Route } from 'react-router-dom'

import './index.css'
import Tabs from './Tabs'
import Delays from './Delays'
import Retention from './Retention'
import Understanding from './Understanding'

const Performance = () => (
  <div>
    <Tabs />
    <Switch>
      <Route exact path='/analytics/performance' component={Delays} />
      <Route exact path='/analytics/performance/retention' component={Retention} />
      <Route exact path='/analytics/performance/understanding' component={Understanding} />
    </Switch>
  </div>
)

export default Performance
