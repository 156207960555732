import styled, { css } from 'styled-components'
import { initButton } from '../../style/globalStyles'

const cssActive = css`
  &:active,
  &.active {
    background-color: ${({ theme }) => theme.color.blue};
    color: ${({ theme }) => theme.color.white};
  }
`

const cssDisabled = css`
  &:disabled {
    cursor: default;
    opacity: 0.5;
    filter: grayscale(100%);
  }
`

const cssTextOverflow = css`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

const computeColor = ({ disabled, theme, selected }) => {
  if (disabled) return theme.color.defaultColorFaded
  if (selected) return theme.color.white
  return theme.color.defaultColor
}

const cssGroupBorders = css`
  &:not(:first-of-type) {
    border-left-width: ${({ grouped }) => (grouped ? 0 : 1)}px;
  }

  &:first-of-type {
    border-top-left-radius: ${({ theme, grouped }) => (grouped ? theme.borderRadius.outlinedUI : 0)}px;
    border-bottom-left-radius: ${({ theme, grouped }) => (grouped ? theme.borderRadius.outlinedUI : 0)}px;
  }

  &:last-of-type {
    border-top-right-radius: ${({ theme, grouped }) => (grouped ? theme.borderRadius.outlinedUI : 0)}px;
    border-bottom-right-radius: ${({ theme, grouped }) => (grouped ? theme.borderRadius.outlinedUI : 0)}px;
  }
`

const cssMargins = css`
  > * ~ * {
    margin-left: ${({ theme, childrenDirection: dir }) => (dir === 'row' ? theme.margin.left.outlinedUIChildren : 0)}px;
    margin-top: ${({ theme, childrenDirection: dir }) =>
    dir === 'column' ? theme.margin.top.outlinedUIChildren : 0}px;
  }

  &:not(:first-of-type) {
    margin-top: ${({ theme, childrenDirection: dir }) => (dir === 'row' ? theme.margin.top.outlinedUI : 0)}px;
  }
`

const OutlinedUIStyled = styled.button`
  ${initButton}
  display: inline-flex;
  flex-direction: ${({ childrenDirection }) => childrenDirection};
  align-items: center;
  cursor: pointer;
  color: ${computeColor};

  border-radius: ${({ theme, grouped }) => (grouped ? '0px' : theme.borderRadius.outlinedUI + 'px !important')};
  border: 1px solid ${({ theme }) => theme.color.grey};
  background-color: ${({ theme: { color }, selected }) => (selected ? color.blue : color.white)};

  ${cssActive}
  ${cssDisabled}


  > span, > h3 {
    flex-grow: 1;
    font-weight: 300;
    margin-bottom: 0px;
    ${cssTextOverflow}
    text-align: ${({ left }) => (left ? 'left' : 'center')};
  }

  padding: ${({
    theme: {
      padding: { YAxis, XAxis }
    }
  }) => `${YAxis.outlinedUI}px ${XAxis.outlinedUI}px`};

  > * {
    pointer-events: none;
  }


  ${cssMargins}

  ${cssGroupBorders}
`

export const ColoredOutlinedUI = styled(OutlinedUIStyled)`
  background: linear-gradient(to right, rgba(106, 55, 197, 1), rgba(109, 223, 155, 1));
  color: ${({ theme }) => theme.color.white};
`

export const OutlinedUIWithTrash = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  &:hover {
    button {
      pointer-events: auto;
      cursor: pointer;
      opacity: 1 !important;
      visibility: visible !important;
    }
  }
`

export default OutlinedUIStyled
