import * as React from 'react'
import { FaTrashAlt, FaPencilAlt } from 'react-icons/fa'
import { either } from '@sbizeul/fp-flow'
import { Loader } from 'semantic-ui-react'

import TextInput from 'components/TextInput'

import './styles.css'

function Synonyms({ synonyms, entityID, onDelete, isDeletingSynonym, updateSynonym }) {
  return (
    <ul data-testid='all-synonyms' className='Synonym-list'>
      {synonyms.map(synonym => (
        <li key={synonym.id}>
          <SynonymRow
            synonym={synonym}
            entityID={entityID}
            onDelete={onDelete}
            isDeletingSynonym={isDeletingSynonym}
            updateSynonym={updateSynonym}
          />
        </li>
      ))}
    </ul>
  )
}

class SynonymRow extends React.PureComponent {
  state = {
    isEditable: false,
    value: this.props.synonym.value
  }

  textInput = React.createRef()

  makeInputEditable = () =>
    this.setState({ isEditable: true }, () => {
      this.textInput.current && this.textInput.current.focus()
    })

  makeInputUneditable = () => this.setState({ isEditable: false })

  onChangeText = value => this.setState({ value })

  updateSynonym = () => {
    if (this.state.value.trim().length > 0) {
      this.props.updateSynonym({ ...this.props.synonym, value: this.state.value })
    } else {
      this.setState({ value: this.props.synonym.value })
    }
    this.makeInputUneditable()
  }

  render() {
    return (
      <ul className='Synonym-row'>
        <li onDoubleClick={this.makeInputEditable}>
          <TextInput
            ref={this.textInput}
            disabled={!this.state.isEditable}
            value={this.state.value}
            onChangeText={this.onChangeText}
            onEnterKeyPressed={this.updateSynonym}
            onBlur={this.updateSynonym}
            autoFocus={true}
            data-testid='synonym-value-editor'
          />
        </li>
        <li>
          <FaPencilAlt data-testid='edit' className='Synonym-row--icon-old' onClick={this.makeInputEditable} />
        </li>
        <li>
          {either.fold(
            this.renderDefaultIcon,
            isDeleting =>
              isDeleting ? <Loader className='Synonym-row--loading' active inline /> : this.renderDefaultIcon(),
            this.props.isDeletingSynonym
          )}
        </li>
      </ul>
    )
  }

  renderDefaultIcon() {
    return (
      <FaTrashAlt
        data-testid='delete'
        className='Synonym-row--icon-old'
        onClick={() => this.props.onDelete(this.props.synonym.id)}
      />
    )
  }
}

export default Synonyms
