import React, { useState, useEffect } from 'react'
import * as R from 'ramda'
import { maybe, Maybe } from '@sbizeul/fp-flow'

import CreateContentMenu from 'components/CreateContentMenu'
import { emptyCarouselContent } from 'components/CarouselContentEditor/models'
import { IsoLanguage } from 'helpers/language'
import { renderNothing } from 'helpers/react'

import { hasNoSkillReference, isSimpleAnswerMaybe } from '../../models'
import {
  addContent,
  emptyButtonContent,
  emptyGotoSkillContent,
  emptyTextContent,
  emptyTagAgentContent,
  emptyImageContent,
  removeContent,
  updateContent
} from '../../models.typed'
import SimpleAnswerEditor from '../SimpleAnswerEditor'
import {
  Answer,
  AnswerContent,
  DispatchFromProps,
  SimpleAnswer,
  StateFromProps
} from '../../types'

import './styles.css'

export type Props = Omit<StateFromProps, 'keywords'> & Omit<DispatchFromProps, 'fetchAllKeywords'>

const AnswerEditor: React.FunctionComponent<Props> = (props) => {
  const [answer, setAnswer] = useState(props.answer)
  useEffect(() => {
    props.onAnswerUpdated(answer)
  }, [answer])
  useEffect(() => {
    if (!maybe.equals(props.answer, answer)) {
      setAnswer(props.answer)
    }
  }, [props.answer])
  const handleSetState = (modifier: R.Arity1Fn) => R.pipe(
    modifier,
    setAnswer
  )(answer)
  const handleCreateContent = (model: AnswerContent) => handleSetState(addContent(model))

  const renderSimpleContent = (answer: SimpleAnswer) => (
    <SimpleAnswerEditor
      answer={answer}
      questions={props.questions}
      removeContent={R.compose(handleSetState, removeContent, R.prop('id'))}
      updateContent={R.compose(handleSetState, updateContent, R.prop('content'))}
      uploadImage={props.uploadImage}
      imagesUrl={props.imagesUrl}
      skills={props.skills}
      tags={props.tags}
      fetchQuestions={props.fetchQuestions}
      fetchAllSkills={props.fetchAllSkills}
      fetchAllTags={props.fetchAllTags}
      goToSkills={props.goToSkills}
    />
  )

  const renderContent = (answer: Maybe<Answer>) => maybe.fold(renderNothing, ans => renderSimpleContent(ans as SimpleAnswer), answer)

  return (
    <div className='AnswerEditor'>
      <div className='AnswerEditor-content'>{renderContent(answer)}</div>
      <CreateContentMenu
        createButtonContent={(params: IsoLanguage) => handleCreateContent(emptyButtonContent(params))}
        createCarouselContent={(params: IsoLanguage) => handleCreateContent(emptyCarouselContent(params))}
        createTextContent={(params: IsoLanguage) => handleCreateContent(emptyTextContent(params))}
        createImageContent={(params: IsoLanguage) => handleCreateContent(emptyImageContent(params))}
        createSkillContent={() => handleCreateContent(emptyGotoSkillContent())}
        createTagAgentContent={() => handleCreateContent(emptyTagAgentContent())}
        canCreateSimple={R.allPass([isSimpleAnswerMaybe, hasNoSkillReference])(answer)}
        languages={props.languages}
      />
    </div>
  )
}

export default AnswerEditor
