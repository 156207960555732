import React from 'react'
import { FaCalendarAlt } from 'react-icons/fa'
import {
  NewCampaign,
  CampaignScheduled,
  CampaignSent,
  CampaignDrafts,
  CampaignsExpandList,
  CampaignsCollapseList
} from '..'
import { LeftPanelStyled, Title, SubSection } from './styles'

const smallList = 10
const showExpandButton = campaigns => campaigns.length > smallList

class LeftPanel extends React.Component {
  state = {
    draftsExpanded: false,
    scheduledExpanded: false,
    toReviewExpanded: false,
    sentExpanded: false
  }

  handleExpandDrafts = () => this.setState({ draftsExpanded: true })
  handleExpandScheduled = () => this.setState({ scheduledExpanded: true })
  handleExpandToReview = () => this.setState({ toReviewExpanded: true })
  handleExpandSent = () => this.setState({ sentExpanded: true })

  handleCollapseDrafts = () => this.setState({ draftsExpanded: false })
  handleCollapseScheduled = () => this.setState({ scheduledExpanded: false })
  handleCollapseToReview = () => this.setState({ toReviewExpanded: false })
  handleCollapseSent = () => this.setState({ sentExpanded: false })

  renderSubSection = ({ campaigns, emptyMessage, expanded, handleExpand, handleCollapse, renderCampaignUi }) => (
    <React.Fragment>
      {!campaigns.length
        ? emptyMessage
        : campaigns.filter((_, ind) => expanded || ind < smallList).map(renderCampaignUi)}
      {!expanded && showExpandButton(campaigns) && <CampaignsExpandList onClick={handleExpand} />}
      {expanded && showExpandButton(campaigns) && <CampaignsCollapseList onClick={handleCollapse} />}
    </React.Fragment>
  )

  render() {
    const {
      drafts,
      scheduled,
      // toReview,
      sent,
      createNewCampaignRequest,
      selectCampaign,
      currentCampaignId,
      deleteCampaignRequest
    } = this.props

    const {
      draftsExpanded,
      scheduledExpanded,
      // toReviewExpanded,
      sentExpanded
    } = this.state
    return (
      <LeftPanelStyled>
        <Title>Create a campaign</Title>
        <SubSection>
          <NewCampaign onClick={() => createNewCampaignRequest()} />
        </SubSection>
        <Title>Drafts</Title>
        <SubSection>
          {this.renderSubSection({
            campaigns: drafts,
            emptyMessage: 'No drafts',
            expanded: draftsExpanded,
            handleExpand: this.handleExpandDrafts,
            handleCollapse: this.handleCollapseDrafts,
            renderCampaignUi: campaign => (
              <CampaignDrafts
                key={campaign.id}
                campaign={campaign}
                selected={currentCampaignId === campaign.id}
                onClick={selectCampaign}
                deleteCampaignRequest={deleteCampaignRequest}
              />
            )
          })}
        </SubSection>
        <Title>
          Scheduled
          <div className='Navbar-menuItem'>
            <FaCalendarAlt />
          </div>
        </Title>
        <SubSection>
          {this.renderSubSection({
            campaigns: scheduled,
            emptyMessage: 'No campaign scheduled',
            expanded: scheduledExpanded,
            handleExpand: this.handleExpandScheduled,
            handleCollapse: this.handleCollapseScheduled,
            renderCampaignUi: campaign => (
              <CampaignScheduled
                key={campaign.id}
                campaign={campaign}
                selected={currentCampaignId === campaign.id}
                onClick={selectCampaign}
                deleteCampaignRequest={deleteCampaignRequest}
              />
            )
          })}
        </SubSection>
        {/* <Title>To review</Title>
        <SubSection>
          {this.renderSubSection({
            campaigns: toReview,
            emptyMessage: 'No campaign to review',
            expanded: toReviewExpanded,
            handleExpand: this.handleExpandToReview,
            renderCampaignUi: campaign =>
              <CampaignDrafts
                key={campaign.id}
                campaign={campaign}
                selected={currentCampaignId === campaign.id}
                onClick={selectCampaign}
              />})}
        </SubSection> */}
        <Title>Sent</Title>
        <SubSection>
          {this.renderSubSection({
            campaigns: sent,
            emptyMessage: 'No campaign sent',
            expanded: sentExpanded,
            handleExpand: this.handleExpandSent,
            handleCollapse: this.handleCollapseSent,
            renderCampaignUi: campaign => (
              <CampaignSent
                key={campaign.id}
                campaign={campaign}
                selected={currentCampaignId === campaign.id}
                onClick={selectCampaign}
              />
            )
          })}
        </SubSection>
      </LeftPanelStyled>
    )
  }
}

export default LeftPanel
