import types from './types'

const fetchMenuPersistentRequest = () => ({ type: types.FETCH_MENUPERSISTENT_REQUEST })
const fetchMenuPersistentSuccess = payload => ({
  type: types.FETCH_MENUPERSISTENT_SUCCESS,
  payload
})
const fetchMenuPersistentFailure = errors => ({ type: types.FETCH_MENUPERSISTENT_NO_DATA, errors })

const fetchMenuPersistentUtilRequest = () => ({ type: types.FETCH_MENUPERSISTENTUTIL_REQUEST })
const fetchMenuPersistentUtilSuccess = payload => ({
  type: types.FETCH_MENUPERSISTENTUTIL_SUCCESS,
  payload
})
const fetchMenuPersistentUtilFailure = errors => ({
  type: types.FETCH_MENUPERSISTENTUTIL_NO_DATA,
  errors
})

export default {
  fetchMenuPersistentRequest,
  fetchMenuPersistentSuccess,
  fetchMenuPersistentFailure,
  fetchMenuPersistentUtilRequest,
  fetchMenuPersistentUtilSuccess,
  fetchMenuPersistentUtilFailure
}
