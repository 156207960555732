import * as React from 'react'
import classNames from 'classnames'
import { Message } from 'semantic-ui-react'

import './styles.css'

const Error = ({ header, content, onClose, className }) => (
  <Message
    className={classNames('SaveError', 'floating-style', className)}
    header={header}
    content={content}
    onDismiss={onClose}
  />
)

export default Error
