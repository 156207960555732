import { eventChannel } from 'redux-saga'
import * as socketIo from 'socket.io-client'

import { pushServerUrl } from 'helpers/effects'

const createSocketChannel = messageName => socket =>
  eventChannel(emit => {
    const handler = event => emit(JSON.parse(event))

    socket.on(messageName, handler)

    return () => {
      socket.off(messageName, handler)
    }
  })

const socketIoClient = socketIo.connect(pushServerUrl)

export { createSocketChannel, socketIoClient }
