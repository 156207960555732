import * as R from 'ramda'

const toMap = keywords => {
  return keywords.reduce((acc, currentKeyword) => {
    acc[currentKeyword.name] = currentKeyword
    return acc
  }, {})
}

function groupSynonymsByLanguages(languages, synonyms) {
  return languages.reduce(
    (accumulator, currentLanguage) => ({
      ...accumulator,
      [currentLanguage]: synonyms.filter(synonym => synonym.language === currentLanguage)
    }),
    {}
  )
}

function getSynonymCountByLanguage(synonymsByLanguages, language) {
  const synonyms = synonymsByLanguages[language]
  return synonyms ? synonyms.length : 0
}

const sortByAlphabetical = value => R.sortBy(R.pipe(R.prop(value), R.toLower))

export {
  toMap,
  getSynonymCountByLanguage,
  groupSynonymsByLanguages,
  sortByAlphabetical
}
