import * as React from 'react'
import { Provider } from 'react-redux'

export const withReduxProvider = store => WrappedComponent => {
  return class ProviderEnhancer extends React.Component {
    render() {
      return (
        <Provider store={store}>
          <WrappedComponent />
        </Provider>
      )
    }
  }
}
