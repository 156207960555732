import styled from 'styled-components'
import OutlinedUI from '../OutlinedUI'

const OutlinedUIStyled = styled(OutlinedUI)`
  > span {
    font-size: 0.65em;
  }
`

const ElementSelectorStyled = styled.div`
  display: flex;
  width: ${({ children, theme }) => children.length * theme.width.outlinedUIGrouped}px;
  > * {
    flex-grow: ${({ grouped }) => (grouped ? 1 : 0)};
  }
`
export { ElementSelectorStyled, OutlinedUIStyled }
