import types from './types'

const fetchAnalyticsRequest = () => ({ type: types.FETCH_ANALYTICS_REQUEST })
const fetchAnalyticsSuccess = () => ({ type: types.FETCH_ANALYTICS_SUCCESS })
const fetchAnalyticsFailure = () => ({ type: types.FETCH_ANALYTICS_FAILURE })

const fetchAnalyticsTrafficMessagesRequest = () => ({ type: types.FETCH_ANALYTICS_TRAFFIC_MESSAGES_REQUEST })
const fetchAnalyticsTrafficMessagesSuccess = () => ({ type: types.FETCH_ANALYTICS_TRAFFIC_MESSAGES_SUCCESS })
const fetchAnalyticsTrafficMessagesFailure = () => ({ type: types.FETCH_ANALYTICS_TRAFFIC_MESSAGES_FAILURE })

const fetchAnalyticsTrafficUsersRequest = () => ({ type: types.FETCH_ANALYTICS_TRAFFIC_USERS_REQUEST })
const fetchAnalyticsTrafficUsersSuccess = () => ({ type: types.FETCH_ANALYTICS_TRAFFIC_USERS_SUCCESS })
const fetchAnalyticsTrafficUsersFailure = () => ({ type: types.FETCH_ANALYTICS_TRAFFIC_USERS_FAILURE })

const fetchTrendsRequest = () => ({ type: types.FETCH_TRENDS_REQUEST })
const fetchTrendsSuccess = () => ({ type: types.FETCH_TRENDS_SUCCESS })
const fetchTrendsFailure = () => ({ type: types.FETCH_TRENDS_FAILURE })

const fetchPerformanceDelaysRequest = () => ({ type: types.FETCH_PERFORMANCE_DELAYS_REQUEST })
const fetchPerformanceDelaysSuccess = () => ({ type: types.FETCH_PERFORMANCE_DELAYS_SUCCESS })
const fetchPerformanceDelaysFailure = () => ({ type: types.FETCH_PERFORMANCE_DELAYS_FAILURE })

const fetchPerformanceRetentionRequest = () => ({ type: types.FETCH_PERFORMANCE_RETENTION_REQUEST })
const fetchPerformanceRetentionSuccess = () => ({ type: types.FETCH_PERFORMANCE_RETENTION_SUCCESS })
const fetchPerformanceRetentionFailure = () => ({ type: types.FETCH_PERFORMANCE_RETENTION_FAILURE })

const fetchPerformanceUnderstandingRequest = () => ({ type: types.FETCH_PERFORMANCE_UNDERSTANDING_REQUEST })
const fetchPerformanceUnderstandingSuccess = () => ({ type: types.FETCH_PERFORMANCE_UNDERSTANDING_SUCCESS })
const fetchPerformanceUnderstandingFailure = () => ({ type: types.FETCH_PERFORMANCE_UNDERSTANDING_FAILURE })

const fetchBehavioursRequest = () => ({ type: types.FETCH_BEHAVIOURS_REQUEST })
const fetchBehavioursSuccess = () => ({ type: types.FETCH_BEHAVIOURS_SUCCESS })
const fetchBehavioursFailure = () => ({ type: types.FETCH_BEHAVIOURS_FAILURE })

export default {
  fetchPerformanceRetentionRequest,
  fetchPerformanceRetentionSuccess,
  fetchPerformanceRetentionFailure,

  fetchPerformanceUnderstandingRequest,
  fetchPerformanceUnderstandingSuccess,
  fetchPerformanceUnderstandingFailure,

  fetchPerformanceDelaysRequest,
  fetchPerformanceDelaysSuccess,
  fetchPerformanceDelaysFailure,

  fetchTrendsRequest,
  fetchTrendsSuccess,
  fetchTrendsFailure,

  fetchBehavioursRequest,
  fetchBehavioursSuccess,
  fetchBehavioursFailure,

  fetchAnalyticsRequest,
  fetchAnalyticsSuccess,
  fetchAnalyticsFailure,

  fetchAnalyticsTrafficMessagesRequest,
  fetchAnalyticsTrafficMessagesSuccess,
  fetchAnalyticsTrafficMessagesFailure,

  fetchAnalyticsTrafficUsersRequest,
  fetchAnalyticsTrafficUsersSuccess,
  fetchAnalyticsTrafficUsersFailure
}
