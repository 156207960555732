import React from 'react'
import { reduxForm } from 'redux-form'
import { Form } from 'semantic-ui-react'

const CustomForm = ({ handleSubmit, onSubmit, pristine, submitting, fields, loading }) => (
  <Form onSubmit={handleSubmit(onSubmit)} loading={loading}>
    {fields}
    <Form.Button content='Submit' type='submit' disabled={pristine || submitting} />
  </Form>
)

export default reduxForm({
  destroyOnUnmount: false,
  touchOnBlur: false,
  enableReinitialize: true
})(CustomForm)
