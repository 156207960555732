import axios from "axios";
import React, * as react from 'react'
import * as uiLibrary from '@tolk-ai/ui-library-react'
import '@tolk-ai/ui-library-react/dist/ui-library-react.esm.css'
import * as browserUtils from '@tolk-genii/browser-utils'

import { accountManagementReactBaseUrl } from 'helpers/effects'

import bot from 'modules/bot'
import * as routes from '../../../../../Routes'
import { cookieDomain, authManagementBaseUrl } from 'helpers/effects'

import './index.css'

type Properties = {
  isLoggedIn: boolean
  userName: any
  historyPush: () => () => void
  logout: any
  isAdmin: boolean
}



const authManagementExpressAxios = axios.create({
  baseURL: authManagementBaseUrl
})

const handleOnLogout = () => {
  browserUtils.logout(
      browserUtils.authenticatedRequest(
          authManagementExpressAxios,
          authManagementBaseUrl,
          browserUtils.removeCookie(document, cookieDomain),
          browserUtils.getCookie(document),
          browserUtils.setCookie(document, cookieDomain)
      ),
      cookieDomain
  )()
}

const Component: react.FC<Properties> = ({
  isLoggedIn,
  userName,
  historyPush,
  logout,
  isAdmin
}: Properties) => {
  if (isLoggedIn) {
    return (
      <uiLibrary.Dropdown
        defaultText={browserUtils.getGivenNameAndPartialFamilyName()}
        icon={{ name: 'UserIcon', type: 'icon' }}
        value=""
        className="topbar-right-content__dropdown-menu"
        listElementClassName="topbar-right-content__dropdown-menu-list"
      >
        <uiLibrary.DropdownSection label="admin">
          <uiLibrary.DropdownElement
            icon={{ name: 'PlusIcon' }}
            onClick={historyPush}
            path={bot.routes.BOT_CREATION}
          >
            Add a new bot
          </uiLibrary.DropdownElement>
          <uiLibrary.DropdownElement
            icon={{ name: 'UserCircleIcon' }}
            url={{ linkTo: accountManagementReactBaseUrl, target: '_self' }}
          >
            Account
          </uiLibrary.DropdownElement>
          <>
            {isAdmin && (
              <>
                <uiLibrary.DropdownElement
                  icon={{ name: 'UserGroupIcon' }}
                  path="/admin/user"
                  onClick={historyPush}
                >
                  Users
                </uiLibrary.DropdownElement>
                <uiLibrary.DropdownElement
                  icon={{ name: 'DatabaseIcon' }}
                  path="/admin/logs"
                  onClick={historyPush}
                >
                  Logs
                </uiLibrary.DropdownElement>
              </>
            )}
          </>
        </uiLibrary.DropdownSection>
        <uiLibrary.DropdownSection label="help">
          <uiLibrary.DropdownElement
            icon={{ name: 'AcademicCapIcon' }}
            url={{ linkTo: 'https://academy.tolk.ai', target: '_blank' }}
          >
            Academy
          </uiLibrary.DropdownElement>
          <uiLibrary.DropdownElement
            icon={{ name: 'SupportIcon' }}
            url={{ linkTo: 'https://status.tolk.ai/', target: '_blank' }}
          >
            Status page
          </uiLibrary.DropdownElement>
          <uiLibrary.DropdownElement
            icon={{ name: 'MailIcon' }}
            url={{ linkTo: 'mailto:support@tolk.ai', target: "_self"}}
          >
            Contact us
          </uiLibrary.DropdownElement>
        </uiLibrary.DropdownSection>
        <uiLibrary.dropdown.footerButton.Component
          icon={{ name: 'LogoutIcon' }}
          onClick={handleOnLogout}
        >
          Log out
        </uiLibrary.dropdown.footerButton.Component>
      </uiLibrary.Dropdown>
    )
  } else {
    return (
      <uiLibrary.Button
        icon={{ name: 'LogoutIcon', position: 'left-icon' }}
        url={{ linkTo: routes.LOGIN, target: '_self' }}
      >
        Sign in
      </uiLibrary.Button>
    )
  }
}

export { Component }
