import React, { FunctionComponent } from 'react'
import * as R from 'ramda'
import { RemoteData } from '@sbizeul/fp-flow'
import { MdLink, MdClose } from 'react-icons/md'

import TextArea from 'components/TextArea'
import {
  ButtonContent as ButtonContentType,
  ButtonsAttachmentContent,
  RemoveContentParam,
  UpdateContentParam,
  idPath,
  textPath
} from 'modules/answer/types'
import { Question } from 'modules/question/types'
import { ServerFailure } from 'types'

import ButtonContent from './ButtonContent'
import './style.css'

export type Props = Readonly<{
  buttonContent: ButtonContentType
  questions: RemoteData<ServerFailure, ReadonlyArray<Question>>

  fetchQuestions: () => unknown
  removeContent: (params: RemoveContentParam) => unknown
  updateContent: (params: UpdateContentParam) => unknown
}>

const ButtonContentEditor: FunctionComponent<Props> = (props) => {
  const removeAnswer = () => {
    props.removeContent({ id: R.view(idPath, props.buttonContent) })
  }

  const updateButtonContentProperty = (lens: R.Lens) => (propertyValue: string) => {
    const buttonContent = R.set(lens, propertyValue, props.buttonContent)
    props.updateContent({ content: buttonContent })
  }

  const updateButtons: (buttonsContent: ButtonsAttachmentContent, buttonContent: ButtonContentType) => unknown =
    (buttonsContent, buttonContent) => {
      const buttons = R.set(R.lensPath(['objectAttachment', 'buttons']), buttonsContent, buttonContent)
      return props.updateContent({ content: buttons })
    }

  const updateText = updateButtonContentProperty(textPath)

  return (
    <div className='ButtonContent'>
      <div className='ButtonContent-actions'>
        <MdLink size='30' />
        <MdClose
          data-testid='remove-content'
          size='20'
          className='ButtonContent-actions--delete'
          onClick={removeAnswer}
        />
      </div>
      <div className='ButtonContent-row'>
        <TextArea
          className='ButtonContent-row--text'
          data-testid='update-text-input'
          type='rounded'
          name='text'
          autocomplete='off'
          value={R.view(textPath, props.buttonContent)}
          onChangeText={updateText}
          placeholder='Type your message...'
        />
      </div>
      <ButtonContent {...props}
      buttonsContent={props.buttonContent.objectAttachment.buttons}
      updateContent={buttonsAttachmentContent => updateButtons(buttonsAttachmentContent, props.buttonContent)}/>
    </div>
  )
}

export default ButtonContentEditor
