import * as actions from './actions'
import * as saga from './saga'
import * as selectors from './selectors'
import * as reducer from './reducer'
import AuthPage from './components/AuthPage'
import AuthForm from './components/AuthForm'
import AuthFormContainer from './containers/AuthFormContainer'
import { withAuth } from './enhancers/withAuth'

export default {
  actions,
  saga,
  selectors,
  reducer,
  components: {
    AuthForm,
    AuthPage,
    AuthFormContainer
  },
  enhancers: {
    withAuth
  }
}
