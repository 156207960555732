import { maybe } from '@sbizeul/fp-flow'

import actions from '../actions'
import formatFilters from '../../../_shared/formatFilters'
import { addNullValuesByPeriod } from '../../../../views/pages/Analytics/utils'
import bot from '../../../../modules/bot'
import api from 'services/tolkApiLegacy'

const fetchNewUsers = rawFilters => (dispatch, getState) => {
  const { channelId, start, end, period } = formatFilters(rawFilters)
  const botId = bot.selectors.getSelectedId(getState())

  dispatch(actions.fetchNewUsersRequest())

  return api.get(
    `/analytics/${maybe.get(botId)}/${channelId}/users/new`,
    {
      params: {
        start,
        end,
        period
      }
    }
  ).then(response => {
      if (response.data.result.data) {
        const formatedData = addNullValuesByPeriod(response.data.result.data, rawFilters.dateRange, period)
        dispatch(actions.fetchNewUsersSuccess(formatedData))
      } else {
        const formatedData = []
        dispatch(actions.fetchNewUsersSuccess(formatedData))
      }
    })
    .catch(err => {
      dispatch(actions.fetchNewUsersFailure(err))
    })
}

export default fetchNewUsers
