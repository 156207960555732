import { connect } from 'react-redux'

import UserForm from '../components/UserForm'
import * as adminSelectors from '../selectors'
import * as adminActions from '../actions'
import bot from '../../bot'

const mapDispatchToProps = {
  onCancel: adminActions.navigateToUserList,
  onSubmit: adminActions.submitUser
}

const mapStateToProps = state => ({
  user: adminSelectors.getSelectedUser(state),
  bot: bot.selectors.getAll(state)
})

export default connect(mapStateToProps, mapDispatchToProps)(UserForm)
