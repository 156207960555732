import React from 'react'
import * as R from 'ramda'
import { FaTimes } from 'react-icons/fa'

import TextInput from '../../../components/TextInput'

import './SimpleText.css'

export default function SimpleText(props) {
  const handleTextChange = text => {
    const lens = R.lensPath(['objectAttachment', 'text', 0])
    const simpleText = R.set(lens, text, props.simpleText)
    props.updateAnswer(simpleText, props.intentSlug)
  }

  const handleClickDelete = () => {
    props.removeAnswer(props.simpleText.id, props.intentSlug)
  }

  return (
    <div className='SimpleText'>
      <div className='SimpleText-delete'>
        <FaTimes className='SimpleText-delete--icon' onClick={handleClickDelete} />
      </div>
      <div className='SimpleText-text'>
        <TextInput
          className='SimpleText-text--input'
          type='rounded'
          required
          pattern='^.{1,600}'
          placeholder='Type your message...'
          onChangeText={handleTextChange}
          value={props.simpleText.objectAttachment.text[0]}
        />
      </div>
    </div>
  )
}
