import React from 'react'
import { isEmpty } from 'lodash'
import * as R from 'ramda'

import ReactHighcharts from 'react-highcharts'
import defaultStyle from '../chartStyle'
import NoDataSegment from '../NoDataSegment'

const getConfig = categories => ({
  ...defaultStyle,
  chart: {
    type: 'bar'
  },
  xAxis: {
    crosshair: true,
    max: 19,
    labels: {
      style: {
        fontSize: '8px'
      }
    },
    categories
  },
  yAxis: {
    max: 100,
    title: {
      text: 'Percentage'
    }
  },
  tooltip: {
    formatter() {
      return `Total number of votes (positive & negative): <b>${this.point.val}</b>`
    }
  },
  plotOptions: {
    series: {
      shadow: false,
      borderWidth: 0,
      dataLabels: {
        enabled: true,
        formatter() {
          return `${this.y}% of satisfaction`
        }
      }
    }
  }
})

const createGraphData = satisfaction => {
  return Object.keys(satisfaction).map(intent => {
    const { yes = 0, total } = satisfaction[intent]
    return { y: Math.ceil((yes / total) * 100), val: total, intent }
  })
}

export default function BarUserSatisfaction({ satisfaction }) {
  const serie = R.pipe(createGraphData, R.sortWith([R.descend(R.prop('y'))]), data => ({ name: 'Satisfaction', data }))(
    satisfaction
  )

  const renderChart = () => {
    return (
      <ReactHighcharts
        config={{
          ...getConfig(serie.data.map(x => x.intent)),
          series: [serie]
        }}
        domProps={{ id: 'BarGraphEntities' }}
      />
    )
  }

  return isEmpty(satisfaction) ? <NoDataSegment /> : renderChart()
}
