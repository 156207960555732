import 'react-dates/initialize'
import React, { Component } from 'react'
import { Tab } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { connect } from 'react-redux'
import _ from 'lodash'
import { maybe } from '@sbizeul/fp-flow'

import operations from '../../../../redux/intents/operations'
import { getActiveIso, getIntentData } from '../../../../redux/selectors'
import TrainingPanel from './TrainingPanel'
import matchingFlags from '../../../../utils/matchingFlags'
import { renderNothing } from '../../../../helpers/react'
import bot from '../../../../modules/bot'
import './index.css'

const getAnswer = (intent, language) => {
  if (intent.answers && intent.answers.type === 'custom') {
    return intent.answers.content
  } else if (intent.answers && intent.answers[language]) {
    return intent.answers[language]
  } else {
    return []
  }
}

const getIsoData = (intentData, iso) => {
  if (!intentData) {
    return {}
  }
  const isoData = {
    ...intentData,
    examples: _.get(intentData, 'examples', []).filter(elem => elem.language === iso),
    answers: getAnswer(intentData, iso)
  }
  return isoData
}

class EditPage extends Component {
  componentDidMount() {
    const { fetchIntentData, fetchIntentDiversity, match } = this.props
    maybe.fold(
      _.noop,
      botId => {
        fetchIntentData(botId, match.params.intent)
        fetchIntentDiversity(botId, match.params.intent, {
          start: moment().subtract(1, 'months'),
          end: moment()
        })
      },
      this.props.botId
    )
  }

  renderTrainingPanel = (intentData, language) => botId => {
    return (
      <Tab.Pane>
        <TrainingPanel botId={botId} intentData={getIsoData(intentData, language)} language={language} />
      </Tab.Pane>
    )
  }

  render() {
    const { selectIntentLanguage, languages = [], intentData } = this.props

    const panes = languages.map(language => ({
      menuItem: `${_.get(matchingFlags, language, language)}`,
      render: () => maybe.fold(renderNothing, this.renderTrainingPanel(intentData, language), this.props.botId)
    }))

    return (
      <div>
        <div id='intent-title-builder'>
          <Link id='link-to-intents' to={'/intents'}>
            intents
          </Link>
          /{intentData.name}{' '}
        </div>
        <br />
        <div id='intent-description-builder'>{intentData.description}</div>
        <br />
        <Tab onTabChange={(e, { activeIndex }) => selectIntentLanguage(activeIndex)} panes={panes} />
        <br />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  botId: bot.selectors.getSelectedId(state),
  isFetching: state.intents.getIn(['intent', 'isFetching'], false),
  intentData: getIntentData(state, getActiveIso(state)),
  activeIso: getActiveIso(state),
  languages: bot.selectors.getLanguages(state)
})

const connectedEditPage = connect(mapStateToProps, operations)(EditPage)

export default connectedEditPage
