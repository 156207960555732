import React from 'react'
import Dropzone from 'react-dropzone'
import classNames from 'classnames'
import { DropzoneContainer, ReplaceButton, SaveButton, ButtonsContainer, DeleteButton } from './styles'

const rejectMessage = ({ allowedFormats, maxSize, uploadType }) =>
  'This is not a valid ' +
  uploadType.toLowerCase() +
  '. ' +
  uploadType +
  ' must be ' +
  allowedFormats +
  ' with a maximum size of ' +
  maxSize / 1000000 +
  'Mo'

const inactiveMessage = ({ allowedFormats, maxSize, uploadType }) =>
  'Try dropping ' +
  uploadType.toLowerCase() +
  ' here, or click to select ' +
  uploadType.toLowerCase() +
  ' to upload. ' +
  uploadType +
  ' must be ' +
  allowedFormats +
  ' with a maximum size of ' +
  maxSize / 1000000 +
  'Mo'

const activeMessage = ({ uploadType }) => `Drop ${uploadType.toLowerCase()} here... `

class UploadFile extends React.Component {
  state = {
    file: null,
    changedFile: false,
    showReplaceButton: false,
    showSaveButton: false
  }

  // Process upload then save on button save click
  handleUploadFile = files => {
    if (!files || !files.length) return
    const file = files[0]
    const temporaryUrl = URL.createObjectURL(file)
    this.props.changeFile(temporaryUrl)
    this.setState({
      file,
      changedFile: true,
      showSaveButton: true
    })
  }

  handleSaveClick = e => {
    e.stopPropagation()
    this.props.saveFile(this.state.file)
    this.setState({
      showSaveButton: false,
      changedFile: false
    })
  }

  handleDeleteClick = e => {
    e.stopPropagation()
    this.props.deleteFile()
  }

  // Process upload and save directly
  handleUploadAndSaveFile = files => {
    if (!files || !files.length) return
    const file = files[0]
    const temporaryUrl = URL.createObjectURL(file)
    this.props.changeFile(temporaryUrl)
    this.props.saveFile(file)
    this.setState({
      showSaveButton: false,
      changedFile: false
    })
  }

  handleMouseHover = e => {
    e.persist()
    const { temporaryUrl, url } = this.props
    if (!(temporaryUrl || url)) return
    this.setState(({ changedFile }) => ({
      showReplaceButton: e.type === 'mouseenter',
      showSaveButton: changedFile
    }))
  }

  render() {
    const { temporaryUrl, url, types, allowedFormats, children, uploadType, forCarousel, maxSize, canEdit } = this.props
    const { showSaveButton, showReplaceButton } = this.state

    return (
      <Dropzone onDrop={this.handleUploadAndSaveFile} accept={types} maxSize={maxSize} disabled={!canEdit}>
        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
          <DropzoneContainer
            {...getRootProps()}
            className={classNames('MainPage-dropzone', {
              'dropzone-active': isDragActive,
              'dropzone-forbidden': isDragReject
            })}
            forCarousel={forCarousel}
            isDragActive={isDragActive}
            url={temporaryUrl || url}
            onMouseEnter={this.handleMouseHover}
            onMouseLeave={this.handleMouseHover}
          >
            <input {...getInputProps()} multiple={false} />
            {isDragActive ? (
              <p>
                {isDragReject ? rejectMessage({ allowedFormats, maxSize, uploadType }) : activeMessage({ uploadType })}
              </p>
            ) : temporaryUrl || url ? (
              <React.Fragment>
                {children}
                <ButtonsContainer
                  className={classNames({
                    isVisible: showReplaceButton || showSaveButton
                  })}
                >
                  {canEdit && showSaveButton && <SaveButton value='Save' onClick={this.handleSaveClick} />}
                  {canEdit && <DeleteButton value='Delete' onClick={this.handleDeleteClick} />}
                  {canEdit && <ReplaceButton value='Replace' />}
                </ButtonsContainer>
              </React.Fragment>
            ) : (
              <p>{inactiveMessage({ allowedFormats, maxSize, uploadType })}</p>
            )}
          </DropzoneContainer>
        )}
      </Dropzone>
    )
  }
}

export default UploadFile
