import { connect } from 'react-redux'
import * as actions from '../../actions'
import * as selectors from '../../selectors'
import bot from '../../../bot'
import { BroadcastManagerScene } from '../../components'
import { CampaignStatus } from '../../types'

const mapStateToProps = () => state => ({
  key: bot.selectors.getSelectedId(state).value,
  settings: selectors.getSettings(state),
  // main content props
  noCurrentCampaign: selectors.isNoCurrentCampaign(state),
  title: selectors.getCurrentCampaignTitle(state),
  date: selectors.getCurrentCampaignDate(state),
  status: selectors.getCurrentCampaignStatus(state),
  sendNow: selectors.getCurrentCampaignSendNow(state),
  sentDate: selectors.getCurrentCampaignSentDate(state),
  cohorts: selectors.getCohorts(state),
  campaignCohorts: selectors.getCurrentCampaignCohorts(state),
  recurring: selectors.getCurrentCampaignRecurring(state),
  recurrence: selectors.getCurrentCampaignRecurrence(state),
  currentAnalytics: selectors.getCurrentAnalytics(state),
  // left panel props
  drafts: selectors.getCampaignsWithStatus(CampaignStatus.draft)(state),
  sent: selectors.getCampaignsWithStatus(CampaignStatus.sent)(state),
  toReview: selectors.getCampaignsWithStatus(CampaignStatus.toReview)(state),
  scheduled: selectors.getCampaignsWithStatus(CampaignStatus.scheduled)(state),
  currentCampaignId: selectors.getCurrentCampaignId(state),
  previewIsSending: selectors.getCurrentCampaignSendingPreview(state),
  buttonSendDisable: !selectors.canSendCurrentCampaign(state),
  buttonPreviewDisable: !selectors.canPreviewCurrentCampaign(state),
  buttonSaveDisable: !selectors.canSaveCurrentCampaign(state),
  buttonPreviewAvailable: selectors.canSendPreview(state),
  // elements creation panel props
  elements: selectors.getCurrentCampaignElements(state)
})

const dispatchToProps = {
  // main content actions
  loadCampaignsRequest: actions.loadCampaignsRequest,
  loadCohortsRequest: actions.loadCohortsRequest,
  changeCampaignTitle: actions.changeCampaignTitle,
  changeCampaignDate: actions.changeCampaignDate,
  toggleCampaignSendNow: actions.toggleCampaignSendNow,
  scheduleCampaignRequest: actions.scheduleCampaignRequest,
  sendPreviewRequest: actions.sendPreviewRequest,
  selectCohort: actions.selectCohort,
  selectRecurrence: actions.selectRecurrence,
  selectRecurring: actions.selectRecurring,
  sendCampaignRequest: actions.sendCampaignRequest,
  saveCampaignRequest: actions.saveCampaignRequest,
  // elements creation panel actions
  addElement: actions.addElement,
  reorderElements: actions.reorderElements,
  deleteElement: actions.deleteElement,
  editCarouselElementAddCard: actions.editCarouselElementAddCard,
  editCarouselElementDeleteCard: actions.editCarouselElementDeleteCard,
  editCarouselElementReorderCards: actions.editCarouselElementReorderCards,
  editCarouselElementImage: actions.editCarouselElementImage,
  editCarouselElementTitle: actions.editCarouselElementTitle,
  editCarouselElementDescription: actions.editCarouselElementDescription,
  editCarouselElementAddButton: actions.editCarouselElementAddButton,
  editCarouselElementDeleteButton: actions.editCarouselElementDeleteButton,
  editCarouselElementButtonTitle: actions.editCarouselElementButtonTitle,
  editCarouselElementButtonValue: actions.editCarouselElementButtonValue,
  editImageElement: actions.editImageElement,
  editTextElement: actions.editTextElement,
  editVideoElement: actions.editVideoElement,
  saveCarouselElementImageRequest: actions.saveCarouselElementImageRequest,
  saveImageElementRequest: actions.saveImageElementRequest,
  saveVideoElementRequest: actions.saveVideoElementRequest,
  deleteCarouselElementImageRequest: actions.deleteCarouselElementImageRequest,
  deleteImageElementRequest: actions.deleteImageElementRequest,
  deleteVideoElementRequest: actions.deleteVideoElementRequest,
  // left panel actions
  selectCampaign: actions.selectCampaign,
  deleteCampaignRequest: actions.deleteCampaignRequest,
  createNewCampaignRequest: actions.createNewCampaignRequest,
  duplicateCampaignRequest: actions.duplicateCampaignRequest
}

const BroadcastManager = connect(mapStateToProps, dispatchToProps)(BroadcastManagerScene)

export default BroadcastManager
