import React from 'react'
import { Tab } from 'semantic-ui-react'

import { withRouter, RouteComponentProps } from 'react-router'

import AuthForm from '../../containers/AuthFormContainer'
import Register from 'modules/register'

import './styles.css'

const panes = [
  {
    menuItem: 'Register',
    render: () => <Tab.Pane attached={false}><Register.components.Register /></Tab.Pane>
  }
]

const AuthTab = ({ location }: RouteComponentProps) =>
  <div className= 'AuthTab-container'>
    <h1 className='AuthTab-title'>Welcome onboard!</h1>
    <Tab classNames='AuthTab-tab-container' menu={{ secondary: true, pointing: true }} panes={panes} defaultActiveIndex={0} />
  </div>

export default withRouter(AuthTab)
