import moment from 'moment'

const date = moment([2019, 9, 16, 15, 6])

const cancelDeleteCampaignTest = {
  initialState: {
    campaigns: [
      {
        id: 1,
        updated: true,
        title: 'Campagne 1',
        elements: [],
        cohorts: [],
        date,
        kpis: {
          sent: 0,
          read: 0,
          targeted: 0
        },
        status: 'draft'
      }
    ],
    currentId: 1
  },
  action: {
    campaign: {
      id: 2,
      updated: true,
      title: 'Campagne 2',
      elements: [],
      cohorts: [],
      date,
      kpis: {
        sent: 0,
        read: 0,
        targeted: 0
      },
      status: 'scheduled'
    }
  },
  reducedState: {
    campaigns: [
      {
        id: 1,
        updated: true,
        title: 'Campagne 1',
        elements: [],
        cohorts: [],
        date,
        kpis: {
          sent: 0,
          read: 0,
          targeted: 0
        },
        status: 'draft'
      },
      {
        id: 2,
        updated: true,
        title: 'Campagne 2',
        elements: [],
        cohorts: [],
        date,
        kpis: {
          sent: 0,
          read: 0,
          targeted: 0
        },
        status: 'scheduled'
      }
    ],
    currentId: 1
  }
}

export default cancelDeleteCampaignTest
