import React from 'react'
import { Grid, Segment } from 'semantic-ui-react'

import HeatHighcharts from '../../../components/AreaCharts/HeatHighcharts'
import HeatHighcharts2 from '../../../components/AreaCharts/HeatHighcharts0'
import MoreInfoPopup from '../../../../../components/MoreInfoPopup'
import text from '../../../../../../config/translations/en.json'

import '../../index.css'


const style = {
  borderRadius: '3px',
  border: 'none',
  boxShadow: '0 2px 4px rgba(50, 50, 93,.1)',
  padding: '5px'
}

const Messages = ({
  botId,
  escaladesHeat,
  messagesHeat
}) => {
  return(
    <Grid.Row>
      <Grid.Column>
        <h1>Messages traffic analysis</h1>
        <HeatHighcharts data={messagesHeat} />
      </Grid.Column>

      {botId === '59f09b39bed87007d10138b8' ||
      botId === '58e787b77b60a536a51a4606' ||
      botId === '5af0101102ed620dc1ba0031' ||
      botId === '5a1be30759281f10d33130a0' ||
      botId === '5a9d2c13940d2a603181dc9a' ||
      botId === '5b6331bdf6c8d77c7ed20496' ||
      botId === '5b682311b75ca705e78b0854' ||
      botId === '59807497203b3933d159beb8' ? (
          <Grid.Column>
            <h1>
            Human handover <MoreInfoPopup content={text.traffic.handover} />
            </h1>
            <Segment style={style}>
              <HeatHighcharts2 data={escaladesHeat} />
            </Segment>
          </Grid.Column>
        ) : null}
    </Grid.Row>
)}

export default Messages
