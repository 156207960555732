import React from 'react'
import * as array from 'fp-ts/lib/Array'
import * as option from 'fp-ts/lib/Option'
import * as R from 'ramda'
import { RemoteData, remoteData } from '@sbizeul/fp-flow'
import { MdClose, MdLabel } from 'react-icons/md'

import Select from 'components/Select'

import './styles.css'
import { SimplifiedTag, TagAgentContent, SimpleAnswer } from 'modules/answer/types'
import { Id, ServerFailure } from 'types'
import { renderFailure, renderInlineLoader } from 'helpers/react'

type UpdateContentParam = Readonly<{
  content: TagAgentContent
}>
type RemoveContentParam = Readonly<{
  id: Id
}>

type Props = Readonly<{
  content: TagAgentContent
  tags: RemoteData<ServerFailure, SimplifiedTag[]>
  pathToId: R.Lens
  fetchAll: () => unknown
  removeContent: (params: RemoveContentParam) => unknown
  goToSkills: () => unknown
  updateContent: (params: UpdateContentParam) => unknown
  answer: SimpleAnswer
}>

const getId: (content: TagAgentContent) => Id = content => {
  return content.objectAttachment.tag_id
}

const findAndTransformSimplifiedTag = (id: Id, data: { text: string, value: string }) => (tags: SimplifiedTag[]): TagAgentContent => {
  const tag: SimplifiedTag | undefined = tags.filter(tag => tag.id === data.value)[0]
  return ({
    id: id,
    type: 'tagagent',
    objectAttachment: {
      tag_id: tag.id,
      action: 'set'
    }
  })
}

class TagAgentContentEditor extends React.Component<Props> {
  componentDidMount () {
    this.props.fetchAll()
  }

  handleClickDelete = () =>
    this.props.removeContent({
      id: R.view(this.props.pathToId, this.props.content)
    })

  render () {
    const { content, tags, answer } = this.props
    return remoteData.fold({
      Failure: renderFailure,
      Loading: renderInlineLoader,
      NotAsked: renderInlineLoader,
      Success: (tags: SimplifiedTag[]) => {
        const tagsWithoutCurrentTag: SimplifiedTag[] | undefined = tags.filter(tag => tag.id !== answer.id)
        const id = content.id
        const allTags = R.pipe(
          R.map((tag: SimplifiedTag) => ({
            text: tag.name,
            value: tag.id
          })),
          R.sortBy(R.pipe(R.prop('text'), R.toLower))
        )(tagsWithoutCurrentTag)
        return (
          <div className='SkillContentEditor'>
            <div className='SkillContentEditor-top_bar'>
              <MdLabel className='SkillContentEditor-top_bar--content_icon' />
              <MdClose
                data-testid='remove-content'
                className='SkillContentEditor-top_bar--remove_icon'
                onClick={this.handleClickDelete}
              />
            </div>
            <div className='SkillContentEditor-content'>
              <p className='SkillContentEditor-content--text'>
                The conversation will be labeled with this Tag. <br />
                You can create Tags on Agent.
          </p>
              <div className='SkillContentEditor-content--bottom'>
                <Select
                  className='SkillContentEditor-content--bottom---select'
                  name='Forms'
                  options={allTags}
                  value={
                    option.getOrElse(
                      () => ({ id: 'lol' })
                    )(array.findFirst<SimplifiedTag>(tag => tag.id === getId(content))(tagsWithoutCurrentTag)).id}
                  onChange={
                    (data: { text: string, value: string, answer: any }) => {
                    this.props.updateContent({
                      content: findAndTransformSimplifiedTag(id, data)(tagsWithoutCurrentTag)
                    })
                  }}
                />
              </div>
            </div>
          </div>
        )
      }
    })(tags)
  }
}

export default TagAgentContentEditor

