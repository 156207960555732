import { connect } from 'react-redux'

import {
  changeDates,
  changeNumberConversationsPerPage,
  changeSearchValue,
  nextPage,
  previousPage,
  downloadCSVRequest,
  search
} from '../actions'

import {
  getConversations,
  getCurrentPage,
  getEndDate,
  getIsDownloadingCSV,
  getNbResultsPerPage,
  getSearchValue,
  getStartDate
} from '../selectors'

import ConversationsFilter from '../components/ConversationsFilter'

const mapStateToProps = state => ({
  startDate: getStartDate(state),
  endDate: getEndDate(state),
  conversationsList: getConversations(state),
  numberConversationsPerPage: getNbResultsPerPage(state),
  searchBarValue: getSearchValue(state),
  page: getCurrentPage(state),
  isDownloadingCSV: getIsDownloadingCSV(state)
})

const mapDispatchToProps = {
  onDatesChange: changeDates,
  onClickDownload: downloadCSVRequest,
  onChangeSearchValue: changeSearchValue,
  onSearch: search,
  onChangeNumberConversationsPerPage: changeNumberConversationsPerPage,
  nextPage,
  previousPage
}

const ConversationFiltersContainer = connect(mapStateToProps, mapDispatchToProps)(ConversationsFilter)

export default ConversationFiltersContainer
