/* eslint-disable no-lone-blocks */
import React from 'react'
import { maybe } from '@sbizeul/fp-flow'
import * as R from 'ramda'
import { useHistory, useLocation } from 'react-router-dom'

import monitor from 'modules/monitor'
import qa from 'modules/qa'
import skill from 'modules/skill'
import channels from 'modules/channels'
import bot from 'modules/bot'

import * as routes from 'views/layouts/Routes'

import { analyticsReactBaseUrl } from 'helpers/effects'

import { Menu, MenuAccordion, MenuItemButton, Nav } from '@tolk-ai/ui-library-react'
import '@tolk-ai/ui-library-react/dist/ui-library-react.esm.css'

import { isFeatureEnabled } from '../../../models'

import './style.css'

const isBroadcastEnabled = isFeatureEnabled('broadcast')

const isHeimdall = (nlpEngine) =>
  R.pipe(maybe.getOrElse(R.F), (engine) => engine === 'heimdall')(nlpEngine)

export const Navbar = ({ nlpEngine, features }) => {
  const history = useHistory()
  const location = useLocation()

  const historyPush = (path) => () => {
    history.push(path)
  }

  const isActualRoute = (location) => (path) => {
    return location.pathname === path
  }

  const isActualRouteOnSection = isActualRoute(location)

  return (
    <div className="navbar">
      <Nav>
        {renderQAMenu(features, historyPush, isActualRouteOnSection)}
        <MenuAccordion
          icon="AnnotationIcon"
          label="Monitor"
          isSectionOpen={
            isActualRouteOnSection(monitor.routes.MONITOR) ||
            isActualRouteOnSection(routes.TRAINING) ||
            isActualRouteOnSection(routes.CONVERSATIONS) ||
            isActualRouteOnSection(monitor.routes.OUT_OF_SCOPE) ||
            isActualRouteOnSection(routes.SMART_SKILLS_FORM) ||
            isActualRouteOnSection(routes.SMART_SKILLS_LIST) 
          }
        >
          
          <MenuItemButton
            onClick={historyPush}
            path={{ pathname: monitor.routes.MONITOR_UNMATCHED }}
            isActive={Array.isArray(
              window.location.pathname.match(
                new RegExp(
                  `^${monitor.routes.MONITOR}|${monitor.routes.MONITOR_UNMATCHED}`
                )
              )
            )}
          >
            Monitoring
          </MenuItemButton>
          <MenuItemButton
            onClick={historyPush}
            path={{ pathname: routes.CONVERSATIONS }}
            isActive={Array.isArray(
              window.location.pathname.match(
                new RegExp(`^${routes.CONVERSATIONS}`)
              )
            )}
          >
            Conversations
          </MenuItemButton>
          
          <MenuItemButton
            onClick={historyPush}
            path={{ pathname: monitor.routes.OUT_OF_SCOPE }}
            isActive={Array.isArray(
              window.location.pathname.match(
                new RegExp(`^${monitor.routes.OUT_OF_SCOPE}`)
              )
            )}
          >
            Out of scope
          </MenuItemButton>
        </MenuAccordion>

        <MenuAccordion
          icon="LightningBoltIcon"
          label="Deploy"
          isSectionOpen={
            isActualRouteOnSection(bot.routes.WEBCHAT) ||
            isActualRouteOnSection(channels.routes.CHANNELS_LIST) ||
            isActualRouteOnSection(routes.CUSTOMER_APIS_LIST) ||
            isActualRouteOnSection(routes.CUSTOMER_API_FORM) 
          }
        >
          <MenuItemButton
            onClick={historyPush}
            path={{ pathname: bot.routes.WEBCHAT }}
            isActive={Array.isArray(
              window.location.pathname.match(
                new RegExp(`^${bot.routes.WEBCHAT}`)
              )
            )}
          >
            Design
          </MenuItemButton>
          <MenuItemButton
            onClick={historyPush}
            path={{ pathname: channels.routes.CHANNELS_LIST }}
            isActive={Array.isArray(
              window.location.pathname.match(
                new RegExp(`^${channels.routes.CHANNELS_LIST}|/apps`)
              )
            )}
          >
            Apps
          </MenuItemButton>
          {/* <MenuItemButton
            onClick={historyPush}
            path={{ pathname: routes.CUSTOMER_APIS_LIST}}
            isActive={Array.isArray(
              window.location.pathname.match(
                new RegExp(`^${routes.CUSTOMER_APIS_LIST}`)
              ) || window.location.pathname.match(
                new RegExp(`^${routes.CUSTOMER_API_FORM}`)
              )
            )}
          >
            APIs
          </MenuItemButton> */}
        </MenuAccordion>
        <Menu url={{linkTo: analyticsReactBaseUrl, target: '_blank'}} label='Analytics' icon={'ChartBarIcon'} />
      </Nav>
    </div>
  )
}

function renderQAMenu(features, historyPush, isActualRouteOnSection) {
  return (
    <MenuAccordion
      icon="BeakerIcon"
      label="Build"
      isSectionOpen={
        isActualRouteOnSection(qa.routes.QA_QUESTIONS) ||
        isActualRouteOnSection('/') ||
        isActualRouteOnSection(routes.KEYWORDS) ||
        isActualRouteOnSection(skill.routes.ALL_SKILLS) ||
        isActualRouteOnSection('/skill-form') ||
        isActualRouteOnSection('/skill')
      }
    >
      <MenuItemButton
        onClick={historyPush}
        path={{ pathname: qa.routes.QA_QUESTIONS }}
        isActive={Array.isArray(
          window.location.pathname.match(
            new RegExp(`^${qa.routes.QA_QUESTIONS}`)
          )
        )}
      >
        Q&A
      </MenuItemButton>
      <MenuItemButton
        onClick={historyPush}
        path={{ pathname: routes.KEYWORDS }}
        isActive={Array.isArray(
          window.location.pathname.match(new RegExp(`^${routes.KEYWORDS}`))
        )}
      >
        Keywords
      </MenuItemButton>
      <MenuItemButton
        onClick={historyPush}
        path={{ pathname: skill.routes.ALL_SKILLS }}
        isActive={Array.isArray(
          window.location.pathname.match(
            new RegExp(`^${skill.routes.ALL_SKILLS}|/skill-form|/skill`)
          )
        )}
      >
        Skills
      </MenuItemButton>
      {/* <MenuItemButton
        onClick={historyPush}
        path={{ pathname: routes.SMART_SKILLS_LIST}}
        isActive={Array.isArray(
          window.location.pathname.match(
            new RegExp(`^${routes.SMART_SKILLS_LIST}`)
          ) || window.location.pathname.match(
            new RegExp(`^${routes.SMART_SKILLS_FORM}`)
          )
        )}
      >
        Smart skills (NEW)
      </MenuItemButton> */}
      {isBroadcastEnabled(features) && (
        <MenuItemButton
          onClick={historyPush}
          path={{ pathname: routes.BROADCAST }}
          isActive={Array.isArray(
            window.location.pathname.match(new RegExp(`^${routes.BROADCAST}`))
          )}
        >
          Broadcast
        </MenuItemButton>
      )}
    </MenuAccordion>
  )
}

// eslint-disable-next-line no-unused-vars
function renderBuilderMenu(historyPush) {
  return (
    <MenuAccordion icon="CogIcon" label="Build">
      <MenuItemButton
        onClick={historyPush}
        path={{ pathname: routes.INTENTS }}
        isActive={Array.isArray(
          window.location.pathname.match(new RegExp(`^${routes.INTENTS}`))
        )}
      >
        Intents
      </MenuItemButton>
      <MenuItemButton
        onClick={historyPush}
        path={{ pathname: routes.ENTITIES }}
        isActive={Array.isArray(
          window.location.pathname.match(new RegExp(`^${routes.ENTITIES}`))
        )}
      >
        Entities
      </MenuItemButton>
    </MenuAccordion>
  )
}

export default Navbar
