import * as R from 'ramda'

import { WithScrollManagerAndLocationProps } from './types'

const restoreScroll: (props: WithScrollManagerAndLocationProps) => (component: JSX.Element) => JSX.Element =
  props => R.tap(() => props.scrollManager.scrollFor(props.location))

export {
  restoreScroll
}
