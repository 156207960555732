import React from 'react'
import { Modale, OutlinedUI, NewCampaign } from '..'
import * as icons from '../../icons'

const ConfirmCampaignBefore = ({ sendNow, confirm, cancel }) => {
  return (
    <Modale
      image={icons.Onboarding4}
      title={sendNow ? 'Message is sending 🤘' : 'Message is scheduled 🤘'}
      message={
        sendNow
          ? ['We have lift-off!', 'Keep in mind that delivery takes from 30 seconds to minutes at peak hour.']
          : ["We're all set up!", 'You can still edit your campaign as long as the delivery is not initiated.']
      }
      resetModale={cancel}
      buttons={[
        {
          Component: OutlinedUI,
          props: {
            onClick: cancel,
            value: sendNow ? 'SEE CAMPAIGN' : 'EDIT CAMPAIGN'
          }
        },
        {
          Component: NewCampaign,
          props: {
            onClick: confirm,
            colored: true
          }
        }
      ]}
    />
  )
}

export default ConfirmCampaignBefore
