import React, { Component } from 'react'
import { connect } from 'react-redux'
import operations from '../../../../../../redux/analytics/messages/operations'
import Traffic from './Traffic'
import bot from '../../../../../../modules/bot'
import getSafe from '../../../../../utils/getSafe'

class TrafficContainer extends Component {
  render() {
    return (
        <Traffic {...this.props} />
    )
  }
}

const mapStateToProps = state => ({
  botId: bot.selectors.getSelectedId(state),
  messagesHeat: getSafe(() => state.analytics.messagesHeat.data),
  escaladesHeat: getSafe(() => state.analytics.escaladesHeat.data)
})

export default connect(mapStateToProps, operations)(TrafficContainer)
