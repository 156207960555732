import React from 'react'
import { Dropdown, DropdownItemProps, DropdownProps, Form } from 'semantic-ui-react'
import * as R from 'ramda'
import { RemoteData, remoteData } from '@sbizeul/fp-flow'

import { ServerFailure } from 'types'
import { IsoLanguage } from 'helpers/language'
import { renderDotLoader } from 'helpers/react'

import { NO_USE_CASE } from '../../../models'

export type AvailableList = ReadonlyArray<{
  text: string
  value: string
}>

type Props = Readonly<{
  botName: string
  language: IsoLanguage
  availableLanguages: AvailableList
  useCase: string
  availableUseCases: RemoteData<ServerFailure, AvailableList>

  updateBotName: (name: string) => unknown
  updateLanguage: (language: string) => unknown
  updateUseCase: (useCase: string) => unknown
}>

const NO_USE_CASE_ITEM = { text: NO_USE_CASE, value: NO_USE_CASE }

const toDropdownItems: (list: AvailableList) => DropdownItemProps[] = R.pipe(
  R.prepend(NO_USE_CASE_ITEM),
  R.map(availableItem => ({
    key: availableItem.text,
    text: availableItem.text,
    value: availableItem.value
  }))
)

const toDropdownItemsLg: (list: AvailableList) => DropdownItemProps[] = R.map(
  availableItem => ({
    key: availableItem.text,
    text: availableItem.text,
    value: availableItem.value
  })
)

const updateFromDropdown = (updateProperty: (property: string) => unknown) => (_: React.SyntheticEvent<HTMLElement>, data: DropdownProps) =>
  updateProperty(data.value as string)

const Naming = ({ availableLanguages, availableUseCases, botName, language, updateBotName, updateLanguage, updateUseCase, useCase }: Props) => {
  const renderBotNameField = () => (
    <Form.Field>
      <label className='bot-creation-form-subtitle'>Bot Name</label>
      <input
        defaultValue={botName}
        className='bot-creation-form-input'
        onChange={event => updateBotName(event.target.value)}
        placeholder='Mr bot, your assistant'
        maxLength={40}
      />
    </Form.Field>
  )

  const renderUseCasesField = () => (
    <Form.Field>
      <label className='bot-creation-form-subtitle'>Use case</label>
      {remoteData.fold({
        NotAsked: () => renderDotLoader('fetching use cases'),
        Loading: () => renderDotLoader('fetching use cases'),
        Failure: error => {
          console.error(error)
          return (
          <>
            <Dropdown
              className='naming-dropdown'
              options={toDropdownItems([])}
              onChange={updateFromDropdown(updateUseCase)}
              selection
              defaultValue={useCase}
            />
            <p className='bot-creation-useCases-error'>
              An error occurs while fetching the use cases.<br />
              You can refresh the page to retry.
            </p>
            <p>{/* needs this last empty element because of a ui rule of semantic ui on form field */}</p>
          </>
          )
        },
        Success: useCases => (
          <Dropdown
            className='naming-dropdown'
            options={toDropdownItems(useCases)}
            onChange={updateFromDropdown(updateUseCase)}
            selection
            defaultValue={useCase}
          />
        )
      }, availableUseCases)}
    </Form.Field>
  )

  const renderLanguagesField = () => (
    <Form.Field>
      <label className='bot-creation-form-subtitle'>Language</label>
      <Dropdown
        className='naming-dropdown'
        options={toDropdownItemsLg(availableLanguages)}
        onChange={updateFromDropdown(updateLanguage)}
        placeholder='Language'
        selection
        defaultValue={language}
      />
    </Form.Field>
  )

  return (
    <div>
      <h1 className='bot-creation-form-title'>Who is your assistant?</h1>
      <p className='bot-creation-form-text'>
        Tell us what will be the name of the assistant and what is his main language.
      </p>
      <Form>
        {renderBotNameField()}
        {renderUseCasesField()}
        {renderLanguagesField()}
      </Form>
    </div>
  )
}

export default Naming
