import React, { FunctionComponent, useState, useRef } from 'react'
import { v4 as uuid } from 'uuid'
import { ChromePicker } from 'react-color'
import * as R from 'ramda'

import { useOutsideClick } from 'helpers/react'

import ColourInput from './ColourInput'
import { coloursToTheme } from '../../models.typed'
import { UpdateWebchatParams, Webchat } from '../../types'

type Colour = {
  firstColor: string
  secondColor: string
}
type DefaultColours = ReadonlyArray<Colour>

type Props = Readonly<{
  webchat: Webchat

  updateWebchat: UpdateWebchatParams
}>

const defaultColours: DefaultColours = [
  { firstColor: '#3a0071', secondColor: '#3a0071' },
  { firstColor: '#008060', secondColor: '#008060' }
]
const isDefaultColours = (webchat: Webchat) =>
  R.any(
    (colour: Colour) =>
      colour.firstColor === R.view(R.lensPath(['settings', 'color1']), webchat)
  )(defaultColours)

const ColorSelection: FunctionComponent<Props> = ({
  webchat,
  updateWebchat
}) => {
  const [color, setColor] = useState(webchat.settings.color1)
  const [isColorOpen, setIsColorOpen] = useState(false)
  const changeColours: (coulour: { firstColor: string }) => void = (colour) => {
    updateWebchat(
      R.over(
        R.lensPath(['settings']),
        (settings) => ({
          ...settings,
          color1: colour.firstColor,
          color2: coloursToTheme(colour).secondColour
        }),
        webchat
      )
    )
  }
  const ref = useRef<HTMLHeadingElement>(null)

  useOutsideClick(ref, () => {
    setIsColorOpen(!isColorOpen)
  })

  return (
    <div>
      <h2>Main color</h2>
      <div className="customize-color">
        {R.map(
          (defaultColor) => (
            <ColourInput
              key={uuid()}
              firstColor={defaultColor.firstColor}
              secondColor={defaultColor.secondColor}
              selected={defaultColor.firstColor === webchat.settings.color1}
              changeColours={changeColours}
            />
          ),
          defaultColours
        )}
        <div className="BotConfig-custom-color-container">
          {isDefaultColours(webchat) ? (
            <label className="BotConfig-avatar-custom customize-icon">
              <input
                onClick={() => setIsColorOpen(!isColorOpen)}
                className="customize-icon-input"
              />
            </label>
          ) : (
            <ColourInput
              firstColor={color}
              secondColor={color}
              selected
              changeColours={() => setIsColorOpen(!isColorOpen)}
            />
          )}
          {isColorOpen && (
            <div className="BotConfig-color-custom" ref={ref}>
              <ChromePicker
                color={color}
                disableAlpha
                onChange={(evt) => setColor(evt.hex)}
                onChangeComplete={(evt) => {
                  changeColours({ firstColor: evt.hex })
                }}
              />
              <div className="BotConfig-color-custom-arrow-down" />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ColorSelection
