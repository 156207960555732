import * as R from 'ramda'
import { v4 as uuid } from 'uuid'

import {
  AssignedQuestion,
  DeletedLog,
  MonitorLog,
  MonitorLogs,
  ReQualifiedLog,
  SidelinedLog,
  TaggedLog,
  UnmatchedQuestion,
  MatchedLog,
  DropdownOption
} from './types'

import { Question } from 'modules/question/types'

const OUT_OF_SCOPE = 'Out of Scope'

const sidelinedTaggedLog: (question: AssignedQuestion) => SidelinedLog = question => ({
  log_id: question.logId,
  monitoring: 'Sidelined'
})

const deletedLog: (question: AssignedQuestion) => DeletedLog = question => ({
  log_id: question.logId,
  monitoring: 'Deleted'
})

const reQualifiedTaggedLog: (question: AssignedQuestion) => ReQualifiedLog = question => ({
  log_id: question.logId,
  monitoring: 'ReQualified',
  expected_id: question.value
})

const fromAssignedQuestionToTaggedLog: (question: AssignedQuestion) => TaggedLog =
  R.cond([
    [R.propEq('value', OUT_OF_SCOPE), sidelinedTaggedLog],
    [R.propEq('deleted', true), deletedLog],
    [R.T, reQualifiedTaggedLog]
  ])

const setValue: (value: string) => (question: AssignedQuestion) => AssignedQuestion =
  value => R.set(R.lensPath(['value']), value)

const setConfirmed: (confirmed: boolean) => (question: AssignedQuestion) => AssignedQuestion =
  confirmed => R.set(R.lensPath(['confirmed']), confirmed)

const setDeleted: (deleted: boolean) => (question: AssignedQuestion) => AssignedQuestion =
  deleted => R.set(R.lensPath(['deleted']), deleted)

const fromUserLogToQuestion: (monitorLog: MonitorLog) => UnmatchedQuestion = monitorLog => ({
  id: monitorLog.id,
  textIn: monitorLog.text,
  intentList: monitorLog.intentList || []
})

const toFormattedUnmatchedLogs: (monitorLogs: MonitorLogs) => ReadonlyArray<UnmatchedQuestion> = monitorLogs => {
  return monitorLogs.map(fromUserLogToQuestion)
}
const monitorLogslogsAreEmpty: (monitorLogs: MonitorLogs) => boolean = (monitorLogs) => monitorLogs.length === 0

const logIntentLabel = (log: MonitorLog) => log.question?.questionText

const toFormattedLog = (log: MonitorLog) => ({
  id: log.question?.questionId,
  associatedQuestion: log.question?.questionText,
  matchedLogs: [{ id: log.id, textIn: log.text }]
})

const insertMatchedQuestion = (intialLog: MatchedLog, matchedQuestionId: string, matchedQuestionText: string) => ({
  ...intialLog,
  matchedLogs: [...intialLog.matchedLogs, { id: matchedQuestionId, textIn: matchedQuestionText }]
})

export const toFormattedLogsByQuestion: (usersLogs: MonitorLogs) => MatchedLog[] = (userLogs: MonitorLogs) => {
  const matchedUserLogs = userLogs.filter(userLog => userLog.question && userLog.question?.questionText.length > 0)

  const formattedLogsByQuestion = R.reduce(
    (acc: MatchedLog[], log: MonitorLog) => {
      const toTest = acc.map((formattedLog: MatchedLog) => ({ associatedQuestion: formattedLog.associatedQuestion }))
      const indexFound = R.findIndex(R.propEq('associatedQuestion', logIntentLabel(log)), acc)
      return R.includes({ associatedQuestion: logIntentLabel(log) }, toTest)
        ? R.update(indexFound, insertMatchedQuestion(acc[indexFound], log.id, log.text), acc)
        : R.insert(0, toFormattedLog(log), acc) as MatchedLog[]
    },
    [],
    matchedUserLogs
  )

  return R.reverse(formattedLogsByQuestion)
}

export const toDropdownFormat: (questions: ReadonlyArray<Question>) => ReadonlyArray<DropdownOption> = questions => {
  return questions.map(formatOptionDropdown)
}

const formatOptionDropdown: (question: Question) => DropdownOption = question => ({
  key: uuid(),
  text: question.label,
  value: question.external_id
})

export const isTerminated = R.equals('terminated')

export {
  OUT_OF_SCOPE,
  fromAssignedQuestionToTaggedLog,
  monitorLogslogsAreEmpty,
  setConfirmed,
  setDeleted,
  setValue,
  toFormattedUnmatchedLogs
}
