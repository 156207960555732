import React from 'react'
import { Table } from 'semantic-ui-react'

function UserDefectsTable({ data = [] }) {
  return (
    <Table basic='very' celled collapsing>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Date</Table.HeaderCell>
          <Table.HeaderCell>Heure</Table.HeaderCell>
          <Table.HeaderCell>Nature</Table.HeaderCell>
          <Table.HeaderCell>Localisation</Table.HeaderCell>
          <Table.HeaderCell>Sequence</Table.HeaderCell>
          <Table.HeaderCell>ID</Table.HeaderCell>
          <Table.HeaderCell>Equipe</Table.HeaderCell>
          <Table.HeaderCell>Commentaire</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {data
          .map(defect => (
            <Table.Row key={defect.idUniq}>
              <Table.Cell>{defect.dateDefaut}</Table.Cell>
              <Table.Cell>{defect.timeDefaut}</Table.Cell>
              <Table.Cell>{defect.natureDefaut}</Table.Cell>
              <Table.Cell>{defect.localisationDefaut}</Table.Cell>
              <Table.Cell>{defect.sequenceDefaut}</Table.Cell>
              <Table.Cell>{defect.userId}</Table.Cell>
              <Table.Cell>{defect.userTeam}</Table.Cell>
              <Table.Cell>{defect.declarerDefautComment}</Table.Cell>
            </Table.Row>
          ))
          .reverse()}
      </Table.Body>
    </Table>
  )
}

export default UserDefectsTable
