export const AUTHENTICATE_REQUEST = 'auth/AUTHENTICATE_REQUEST'
export const AUTHENTICATE_SUCCESS = 'auth/AUTHENTICATE_SUCCESS'
export const AUTHENTICATE_FAILURE = 'auth/AUTHENTICATE_FAILURE'

export const authenticate = ({ username, password }) => ({
  type: AUTHENTICATE_REQUEST,
  payload: {
    username,
    password
  }
})

export const authenticateSuccess = token => ({
  type: AUTHENTICATE_SUCCESS,
  payload: token
})

export const authenticateFailure = error => ({
  type: AUTHENTICATE_FAILURE,
  payload: error
})
