import styled, { css } from 'styled-components'

const whenDragging = css`
  * {
    pointer-events: none;
  }
`

const ElementsContainerStyled = styled.div`
  > * {
    margin-bottom: ${({ theme }) => theme.margin.bottom.elements}px;
  }
  border-bottom: 1px dotted ${({ theme }) => theme.color.defaultColor};
  margin-bottom: ${({ theme }) => theme.margin.bottom.elements}px;
  padding-bottom: ${({ theme }) => theme.margin.bottom.elements}px;
  ${({ dragging }) => dragging && whenDragging}
`

export default ElementsContainerStyled
