import React, { Component } from 'react'
import { connect } from 'react-redux'
import operations from '../../../../../../redux/analytics/messages/operations'
import getSafe from '../../../../../utils/getSafe'
import Week from './Week'

class WeekContainer extends Component {
  render() {
    return (
        <Week {...this.props} />
    )
  }
}

const mapStateToProps = state => ({
  messagesByDay: getSafe(() => state.analytics.messages.averageByDay),
  period: ['minutes', 'hours', 'days'][state.main.filters.periods.active],
  usersByDay: getSafe(() => state.analytics.users.byDay),
})

export default connect(mapStateToProps, operations)(WeekContainer)
