import * as React from 'react'
import * as R from 'ramda'
import { remoteData } from '@sbizeul/fp-flow'
import { MdAddCircle } from 'react-icons/md'
import { Modal } from 'semantic-ui-react'
import { v4 as uuid } from 'uuid'

import { renderFailure, renderLoader, renderNothing } from 'helpers/react'

import { isFallback, isOnboarding, isHumanHandover } from '../../models'

import AdvancedSkill from './AdvancedSkill'
import PrebuiltSkills from './PrebuiltSkills'
import OnboardingButton from './Onboarding'
import FallbackButton from './Fallback'
import HumanHandoverButton from './HumanHandover'
import TapFlow from './TapFlow'
import Rgpd from './Rgpd'
import CreateSkillForm from './CreateSkill'
import SkillList from './SkillList'

import './styles.css'

const chooseIcon = R.cond([
  [isOnboarding, () => <OnboardingButton key={uuid()} />],
  [isFallback, () => <FallbackButton key={uuid()} />],
  [isHumanHandover, () => <HumanHandoverButton key={uuid()} />],
  [R.T, renderNothing]
])

const renderDefaultSkills = skills => (
  <div className='base-skills'>
    <SkillList title='Base'>
      {R.map(chooseIcon)(skills)}
      <TapFlow key={uuid()} />
      <Rgpd key={uuid()} />
    </SkillList>
  </div>
)

const renderFormSkills = (
  isDownloadingFormData,
  navigateTo,
  onClickDownload,
  fetchFormSkills,
  fetchFormReferences,
  formReferences,
  deleteForm
) => R.map(
  formSkill => (
    <PrebuiltSkills
      formSkill={formSkill}
      fetchFormSkills={fetchFormSkills}
      isDownloadingFormData={isDownloadingFormData}
      onClickDownload={onClickDownload}
      navigateTo={navigateTo}
      fetchFormReferences={fetchFormReferences}
      formReferences={formReferences}
      deleteForm={deleteForm}
      key={formSkill.id} 
    />
  )
)

const renderAdvancedSkills = (fetchSkillReferences, skillReferences, deleteSkill) => R.map(advancedSkill => <AdvancedSkill advancedSkill={advancedSkill} fetchSkillReferences={fetchSkillReferences} skillReferences={skillReferences} deleteSkill={deleteSkill} key={advancedSkill.id} />)

const renderRemoteSkills = (skills, renderSkill) =>
  remoteData.fold({
    Success: (skills) => renderSkill(skills.filter( skill => skill.name !== 'TapFlow' )),
    NotAsked: () => renderLoader('Please wait...'),
    Loading: () => renderLoader('Please wait...'),
    Failure: renderFailure
  })(skills)

const renderCreateForm = (handleModal, modalHandler, createFormSkill) => (
  <Modal
    open={handleModal}
    closeOnEscape={true}
    closeOnDimmerClick={true}
    trigger={
      <div onClick={modalHandler} className='skill-default--button create-skill-form--button'>
        <MdAddCircle className='create-skill-form--icon' />
        <p className='skill-add-form--label'>Add a form</p>
      </div>
    }
  >
    <Modal.Header>
      <p className='create-skill-form--modal-title'>Create a Form Skill</p>
    </Modal.Header>
    <Modal.Content>
      <CreateSkillForm createSkill={createFormSkill} onClose={modalHandler} label='Form name'/>
    </Modal.Content>
  </Modal>
)

const renderCreateAdvancedSkill = (handleModal, modalHandler, createAdvancedSkill) => (
  <Modal
    open={handleModal}
    closeOnEscape={true}
    closeOnDimmerClick={true}
    trigger={
      <div onClick={modalHandler} className='skill-default--button create-skill-form--button'>
        <MdAddCircle className='create-skill-form--icon' />
        <p className='skill-add-form--label'>Add a scenario</p>
      </div>
    }
  >
    <Modal.Header>
      <p className='create-skill-form--modal-title'>Create a scenario</p>
    </Modal.Header>
    <Modal.Content>
      <CreateSkillForm createSkill={createAdvancedSkill} onClose={modalHandler} label='Scenario name'/>
    </Modal.Content>
  </Modal>
)

class SkillPage extends React.PureComponent {
  componentDidMount() {
    this.props.fetch()
    this.props.fetchFormSkills()
    this.props.fetchAllAdvanced()
  }

  state = {
    handleModalForm: false,
    handleModalAdvanced: false
  }

  modalHandlerForm = () => this.setState({ handleModalForm: !this.state.handleModalForm })

  modalHandlerAdvanced = () => this.setState({ handleModalAdvanced: !this.state.handleModalAdvanced })

  render() {
    return (
      <div className='SkillPage-container'>
        <div className='SkillPage'>{renderRemoteSkills(this.props.skills, renderDefaultSkills)}</div>
        <div className='SkillPage'>
          <SkillList title='Form'>
            {renderRemoteSkills(this.props.formSkills, renderFormSkills(
              this.props.isDownloadingFormData,
              this.props.navigateTo,
              this.props.onClickDownload,
              this.props.fetchFormSkills,
              this.props.fetchFormReferences, 
              this.props.formReferences, 
              this.props.deleteForm
            ))}
            {renderCreateForm(this.state.handleModalForm, this.modalHandlerForm, this.props.createFormSkill)}
          </SkillList>
          <SkillList title='Scenario'>
            {renderRemoteSkills(this.props.advancedSkills, renderAdvancedSkills(this.props.fetchSkillReferences, this.props.skillReferences, this.props.deleteSkill))}
            {renderCreateAdvancedSkill(
              this.state.handleModalAdvanced,
              this.modalHandlerAdvanced,
              this.props.createAdvancedSkill
            )}
          </SkillList>
        </div>
      </div>
    )
  }
}

export default SkillPage
