import React from 'react'
import { SingleDatePicker } from 'react-dates'
import SelectOutlinedUI from '../SelectOutlinedUI'
import DatePickerContainerStyled from './styles'
import { fusionDateTime } from '../../../../helpers/moment'

class DatePicker extends React.Component {
  state = { focused: false }

  changeFocus = focused => this.setState({ focused })

  onDateChange = newDate => {
    const { date, changeDate } = this.props
    changeDate(fusionDateTime(newDate, date))
  }

  render() {
    const { date } = this.props
    const { focused } = this.state
    return (
      <DatePickerContainerStyled>
        <SingleDatePicker
          date={date} // momentPropTypes.momentObj or null
          onDateChange={this.onDateChange} // PropTypes.func.isRequired
          focused={focused} // PropTypes.bool
          onFocusChange={({ focused }) => this.changeFocus(focused)} // PropTypes.func.isRequired
          id='date' // PropTypes.string.isRequired,
          numberOfMonths={1}
          hideKeyboardShortcutsPanel
          noBorder
          displayFormat='DD/MM/YYYY'
          openDirection='up'
        />
        <SelectOutlinedUI onClick={() => this.changeFocus(!focused)}>
          <span>{date.format('MMM, Do')}</span>
        </SelectOutlinedUI>
      </DatePickerContainerStyled>
    )
  }
}

export default DatePicker
