import {
  FetchAppInformationRequest,
  FetchAppInformationSuccess,
  FetchAppInformationFailure,
  SaveAppInformationRequest,
  SaveAppInformationSuccess,
  SaveAppInformationFailure,
  FETCH_APP_INFORMATION_FAILURE,
  FETCH_APP_INFORMATION_REQUEST,
  FETCH_APP_INFORMATION_SUCCESS,
  SAVE_APP_INFORMATION_REQUEST,
  SAVE_APP_INFORMATION_SUCCESS,
  SAVE_APP_INFORMATION_FAILURE,
  FetchAppInformationRequestParam,
  FetchAppInformationSuccessParam,
  FetchAppInformationFailureParam,
  SaveAppInformationRequestParam,
  SaveAppInformationSuccessParam,
  SaveAppInformationFailureParam
} from './types'

export const fetchAppInformationRequest = (data: FetchAppInformationRequestParam): FetchAppInformationRequest => ({
  type: FETCH_APP_INFORMATION_REQUEST,
  payload: data
})

export const fetchAppInformationSuccess = (data: FetchAppInformationSuccessParam): FetchAppInformationSuccess => ({
  type: FETCH_APP_INFORMATION_SUCCESS,
  payload: data
})

export const fetchAppInformationFailure = (error: FetchAppInformationFailureParam): FetchAppInformationFailure => ({
  type: FETCH_APP_INFORMATION_FAILURE,
  payload: error
})

export const saveAppInformationRequest = (data: SaveAppInformationRequestParam): SaveAppInformationRequest => ({
  type: SAVE_APP_INFORMATION_REQUEST,
  payload: data
})

export const saveAppInformationSuccess = (data: SaveAppInformationSuccessParam): SaveAppInformationSuccess => ({
  type: SAVE_APP_INFORMATION_SUCCESS,
  payload: data
})

export const saveAppInformationFailure = (error: SaveAppInformationFailureParam): SaveAppInformationFailure => ({
  type: SAVE_APP_INFORMATION_FAILURE,
  payload: error
})
