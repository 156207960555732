import _ from 'lodash'
import React from 'react'
import { Table } from 'semantic-ui-react'
import EditExampleInPlace from '../Edit/EditExampleInPlace'

const ExamplesTable = ({ data, botId, intentSlug }) => (
  <Table verticalAlign='middle' celled fixed>
    <Table.Body>
      {_.map(data, ({ source, id }) => (
        <Table.Row key={id} verticalAlign='middle'>
          <EditExampleInPlace
            form={`form-${id.toString()}`}
            id={id}
            initialValues={{
              botId,
              intentSlug,
              example: source,
              id
            }}
          />
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
)

export default ExamplesTable
