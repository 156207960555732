import axios from 'axios'
import { toastr } from 'react-redux-toastr'
import { reset } from 'redux-form'
import { maybe } from '@sbizeul/fp-flow'

import history from '../../config/history'
import actions from './actions'
import bot from '../../modules/bot'
import { legacyApiUrl } from 'helpers/effects'

/* fetchIntents */
const fetchIntents = botId => dispatch => {
  dispatch(actions.fetchIntentsRequest())
  return axios({
    url: `${legacyApiUrl}/contents/intents/${botId}`,
    method: 'get',
    responseType: 'json'
  })
    .then(response => {
      if (response.data) {
        dispatch(actions.fetchIntentsSuccess(response.data))
      } else {
        dispatch(actions.fetchIntentsFailure())
        toastr.error('Error', 'Error While Getting Intents, please refresh browser')
      }
    })
    .catch(err => {
      dispatch(actions.fetchIntentsFailure())
      toastr.error('Error', `Error: ${err}`)
    })
}

/* fetchIntentData */
const fetchIntentData = (botId, intentSlug) => (dispatch, getState) => {
  const botId = bot.selectors.getSelectedId(getState())
  dispatch(actions.fetchIntentDataRequest())
  return axios({
    url: `${legacyApiUrl}/contents/intents/${maybe.get(botId)}/${intentSlug}`,
    method: 'get',
    responseType: 'json'
  })
    .then(response => {
      if (response.status === 200) {
        dispatch(actions.fetchIntentDataSuccess(response.data))
      } else if (response.status === 204) {
        dispatch(actions.fetchIntentDataFailure())
        toastr.error('Error', 'Intent Not Found')
        history.push('/intents/')
      } else {
        dispatch(actions.fetchIntentDataFailure())
        toastr.error('Error', 'Intent Fetch Error')
        history.push('/intents/')
      }
    })
    .catch(err => {
      dispatch(actions.fetchIntentDataFailure())
      toastr.error('Error', `Error: ${err}`)
    })
}

/* getDiversity */
const fetchIntentDiversity = (botId, intentSlug, dateRange) => dispatch => {
  const start = dateRange.start.format('YYYY-MM-DD')
  const end = dateRange.end.format('YYYY-MM-DD')
  dispatch(actions.getDiversityRequest())
  return axios({
    url: `${legacyApiUrl}/analytics/${botId}/diversity/${intentSlug}`,
    method: 'get',
    params: {
      start,
      end,
      period: 'days'
    },
    responseType: 'json'
  })
    .then(response => {
      if (response.data && response.data.data) {
        dispatch(actions.getDiversitySuccess(response.data.data))
      } else {
        dispatch(actions.getDiversityFailure())
        toastr.error('Error', 'Error While Getting Diversity Data, please refresh browser')
      }
    })
    .catch(err => {
      dispatch(actions.getDiversityFailure())
      toastr.error('Error', `Error: ${err}`)
    })
}

/* postIntent */
const postIntent = data => dispatch => {
  dispatch(actions.postIntentRequest())
  return axios({
    url: `${legacyApiUrl}/contents/intents/${data.botId}`,
    method: 'post',
    responseType: 'json',
    data: {
      name: data.name,
      description: data.description
    }
  })
    .then(response => {
      if (response.data && response.data.results && response.data.results.id) {
        dispatch(actions.postIntentSuccess())

        history.push(`/intents/edit/${response.data.results.slug}`)
      } else {
        dispatch(actions.postIntentFailure())
        toastr.error('Error', 'Intent Was Not Updated')
      }
    })
    .catch(err => {
      dispatch(actions.postIntentFailure())
      toastr.error('Error', `Error: ${err}`)
    })
}
/* postIntentTrainer */
const postIntentTrainer = data => dispatch => {
  dispatch(actions.postIntentRequest())
  return axios({
    url: `${legacyApiUrl}/contents/intents/${data.botId}`,
    method: 'post',
    responseType: 'json',
    data: {
      name: data.name,
      description: data.description,
      id: data.id
    }
  })
    .then(response => {
      if (response.data && response.data.results && response.data.results.id) {
        dispatch(actions.postIntentSuccess())
      } else {
        dispatch(actions.postIntentFailure())
        toastr.error('Error', 'Intent Was Not Updated')
      }
    })
    .catch(() => {
      dispatch(actions.postIntentFailure())
      toastr.error('Error', 'Error: Intent already exists')
    })
}

/* putIntent */
const putIntent = data => (dispatch, getState) => {
  const answers = {
    language: data.language,
    id: data.id,
    answers: {
      answer: data.answer
    }
  }
  dispatch(actions.putIntentRequest())
  const botId = bot.selectors.getSelectedId(getState())
  return axios({
    url: `${legacyApiUrl}/contents/intents/${maybe.get(botId)}/${data.intentSlug}`,
    method: 'put',
    responseType: 'json',
    data: {
      intent: data.intentName,
      description: data.description,
      ...answers
    }
  })
    .then(response => {
      if (response.status === 200) {
        dispatch(actions.putIntentSuccess())
        dispatch(fetchIntentData(data.botId, data.intentSlug))
        dispatch(reset('AddAnswerToIntent'))
      } else {
        dispatch(actions.putIntentFailure())
        toastr.error('Error', 'Intent Was Not Created')
      }
    })
    .catch(err => {
      dispatch(actions.putIntentFailure())
      toastr.error('Error', `Error: ${err}`)
    })
}

/* putIntentInfos */
const putIntentInfos = data => (dispatch, getState) => {
  const botId = maybe.get(bot.selectors.getSelectedId(getState()))
  dispatch(actions.putIntentInfosRequest())
  return axios({
    url: `${legacyApiUrl}/contents/intents/${botId}/${data.intentSlug}/infos`,
    method: 'put',
    responseType: 'json',
    data: {
      name: data.name,
      description: data.description
    }
  })
    .then(response => {
      if (response.status === 200) {
        dispatch(actions.putIntentInfosSuccess())
        dispatch(fetchIntentData(data.botId, data.name))
        dispatch(reset('UpdateIntentForm'))
      } else {
        dispatch(actions.putIntentInfosFailure())
        toastr.error('Error', 'Intent Was Not Modified')
      }
    })
    .catch(err => {
      dispatch(actions.putIntentInfosFailure())
      toastr.error('Error', `Error: ${err}`)
    })
}

/* postExample */
const postIntentExample = data => dispatch => {
  dispatch(actions.postIntentExampleRequest())
  return axios({
    url: `${legacyApiUrl}/contents/intents/${data.botId}/examples/${data.intentSlug}`,
    method: 'post',
    responseType: 'json',
    data: {
      language: data.language,
      example: data.example
    }
  })
    .then(response => {
      if (response.data) {
        dispatch(actions.postIntentExampleSuccess(response.data))
        dispatch(reset('addExampleToIntent'))
        dispatch(fetchIntentData(data.botId, data.intentSlug))
      } else {
        dispatch(actions.postIntentExampleFailure())
        toastr.error('Error', 'XXX')
      }
    })
    .catch(err => {
      dispatch(actions.postIntentExampleFailure())
      toastr.error('Error', `Error: ${err}`)
    })
}

/* putIntentExample */
const putIntentExample = data => dispatch => {
  dispatch(actions.putIntentExampleRequest())
  return axios({
    url: `${legacyApiUrl}/contents/intents/${data.botId}/examples/${data.intentSlug}/${data.id}`,
    method: 'put',
    responseType: 'json',
    data: {
      example: data.example
    }
  })
    .then(response => {
      if (response.data) {
        dispatch(actions.putIntentExampleSuccess(response.data))
        setTimeout(() => {
          dispatch(fetchIntentData(data.botId, data.intentSlug))
        }, 500)
      } else {
        dispatch(actions.putIntentExampleFailure())
        toastr.error('Error', 'XXX')
      }
    })
    .catch(err => {
      dispatch(actions.putIntentExampleFailure())
      toastr.error('Error', err)
    })
}

/* deleteIntentExample */
const deleteIntentExample = data => dispatch => {
  dispatch(actions.deleteIntentExampleRequest())
  return axios({
    url: `${legacyApiUrl}/contents/intents/${data.botId}/examples_delete/${data.intentSlug}/${data.id}`,
    method: 'delete',
    responseType: 'json'
  })
    .then(response => {
      if (response.status === 200) {
        dispatch(actions.deleteIntentExampleSuccess(response.data))
        setTimeout(() => {
          dispatch(fetchIntentData(data.botId, data.intentSlug))
        }, 0)
      } else {
        dispatch(actions.deleteIntentExampleFailure())
        toastr.error('Error', 'XXX')
      }
    })
    .catch(err => {
      dispatch(actions.deleteIntentExampleFailure())
      toastr.error('Error', err)
    })
}

/* deleteIntentAnswer */
const deleteIntentAnswer = data => dispatch => {
  const body = {
    language: data.language,
    id: data.id
  }
  dispatch(actions.deleteIntentAnswerRequest())
  return axios({
    url: `${legacyApiUrl}/contents/intents/${data.botId}/${data.intentSlug}/answer`,
    method: 'put',
    responseType: 'json',
    data: {
      ...body
    }
  })
    .then(response => {
      if (response.status === 200) {
        dispatch(actions.deleteIntentAnswerSuccess())
        dispatch(fetchIntentData(data.botId, data.intentSlug))
      } else {
        dispatch(actions.deleteIntentAnswerFailure())
        toastr.error('Error', 'Intent Was Not Created')
      }
    })
    .catch(err => {
      dispatch(actions.deleteIntentAnswerFailure())
      toastr.error('Error', `Error: ${err}`)
    })
}

/* deleteIntent */
const deleteIntent = (botId, intentSlug) => dispatch => {
  dispatch(actions.deleteIntentRequest())
  return axios({
    url: `${legacyApiUrl}/contents/intents/${botId}/${intentSlug}`,
    method: 'delete',
    responseType: 'json'
  })
    .then(response => {
      if (response.status === 200) {
        dispatch(actions.deleteIntentSuccess())
        dispatch(fetchIntents(botId))
      } else {
        dispatch(actions.deleteIntentFailure())
        toastr.error('Error', 'Intent Was Not Deleted')
      }
    })
    .catch(err => {
      dispatch(actions.deleteIntentFailure())
      toastr.error('Error', `Error: ${err}`)
    })
}

export const fetchUsers = () => dispatch => {
  dispatch(actions.fetchUserDataRequest())
  return axios({
    url: `${legacyApiUrl}/admin/listusers`,
    method: 'get',
    responseType: 'json'
  })
    .then(response => {
      if (response.status === 200) {
        dispatch(actions.fetchUserDataSuccess(response.data))
      } else if (response.status === 204) {
        dispatch(actions.fetchUserDataFailure())
        toastr.error('Error', 'User Not Found')
        history.push('/admin/user')
      } else {
        dispatch(actions.fetchUserDataFailure())
        toastr.error('Error', 'User Fetch Error')
        history.push('/admin/user')
      }
    })
    .catch(err => {
      dispatch(actions.fetchUserDataFailure())
      toastr.error('Error', `Error: ${err}`)
    })
}

/* postPassword */
const postPassword = data => dispatch => {
  dispatch(actions.postPasswordRequest())
  return axios({
    url: `${legacyApiUrl}/admin/user/${data.user._id.$oid}`,
    method: 'put',
    responseType: 'json',
    data: {
      old_password: data.passwordold,
      password: data.password
    }
  })
    .then(response => {
      if (response.data) {
        dispatch(actions.postPasswordSuccess({ username: data.user.username, password: data.password }))
        toastr.success('Congratulation', 'New password Created Successfully')
        history.push('/password')
      } else {
        dispatch(actions.postPasswordFailure())
        toastr.error('Error', 'Password Was Not Updated')
      }
    })
    .catch(err => {
      dispatch(actions.postPasswordFailure())
      toastr.error('Error', `Error: ${err}`)
    })
}

/* createPassword */
const createPassword = data => dispatch => {
  dispatch(actions.createPasswordRequest())
  return axios({
    url: `${legacyApiUrl}/${data.user}/password`,
    method: 'post',
    responseType: 'json',
    data: {
      password: data.password
    }
  })
    .then(response => {
      if (response.data) {
        dispatch(actions.createPasswordSuccess())
        toastr.success('Congratulation', 'New password Created Successfully')
        history.push('/auth/login')
      } else {
        dispatch(actions.createPasswordFailure())
        toastr.error('Error', 'Password Was Not Created')
      }
    })
    .catch(err => {
      dispatch(actions.createPasswordFailure())
      toastr.error('Error', `Error: ${err}`)
    })
}

/* disableIntent */
const toggleIntent = (botId, intent, activated) => dispatch => {
  dispatch(actions.toggleIntentRequest())
  return axios({
    url: `${legacyApiUrl}/contents/intents/${botId}/${intent}/toggle`,
    method: 'put',
    responseType: 'json',
    data: {
      activated: activated
    }
  })
    .then(response => {
      if (response.status === 200) {
        dispatch(actions.toggleIntentSuccess())
        // dispatch(fetchIntents(botId))
      } else {
        dispatch(actions.toggleIntentFailure())
        toastr.error('Error', 'Intent Was Not toggle')
      }
    })
    .catch(err => {
      dispatch(actions.toggleIntentFailure())
      toastr.error('Error', `Error: ${err}`)
    })
}

const { selectIntentLanguage } = actions

export default {
  fetchIntents,
  fetchIntentData,
  fetchIntentDiversity,

  postIntent,
  postIntentTrainer,
  putIntent,
  postPassword,
  createPassword,

  fetchUsers,

  postIntentExample,
  putIntentExample,
  deleteIntentExample,
  deleteIntentAnswer,
  toggleIntent,
  deleteIntent,
  putIntentInfos,

  selectIntentLanguage
}
