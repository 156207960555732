const FETCH_ENTITIES_REQUEST = 'entity/FETCH_ENTITIES_REQUEST'
const FETCH_ENTITIES_SUCCESS = 'entity/FETCH_ENTITIES_SUCCESS'
const FETCH_ENTITIES_FAILURE = 'entity/FETCH_ENTITIES_FAILURE'

const FETCH_ONE_REQUEST = 'entity/FETCH_ONE_REQUEST'
const FETCH_ONE_SUCCESS = 'entity/FETCH_ONE_SUCCESS'
const FETCH_ONE_FAILURE = 'entity/FETCH_ONE_FAILURE'

const CREATE_REQUEST = 'entity/CREATE_REQUEST'
const CREATE_SUCCESS = 'entity/CREATE_SUCCESS'
const CREATE_FAILURE = 'entity/CREATE_FAILURE'

const UPDATE_REQUEST = 'entity/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'entity/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'entity/UPDATE_FAILURE'

const SELECT_ENTITY = 'entity/SELECT_ENTITY'
const UNSELECT_ENTITY = 'entity/UNSELECT_ENTITY'

const OPEN_CREATE_FORM = 'entity/OPEN_CREATE_FORM'
const CLOSE_CREATE_FORM = 'entity/CLOSE_CREATE_FORM'

const fetchEntitiesRequest = () => {
  return {
    type: FETCH_ENTITIES_REQUEST
  }
}

const fetchEntitiesSuccess = entities => {
  return {
    type: FETCH_ENTITIES_SUCCESS,
    payload: entities
  }
}

const fetchEntitiesFailure = error => {
  return {
    type: FETCH_ENTITIES_FAILURE,
    payload: error
  }
}

const fetchOneRequest = entityId => {
  return {
    type: FETCH_ONE_REQUEST,
    payload: entityId
  }
}

const fetchOneSuccess = entity => {
  return {
    type: FETCH_ONE_SUCCESS,
    payload: entity
  }
}

const fetchOneFailure = (entityId, error) => {
  return {
    type: FETCH_ONE_FAILURE,
    payload: {
      entityId,
      error
    }
  }
}

const create = shortEntity => {
  return {
    type: CREATE_REQUEST,
    payload: shortEntity
  }
}

const createSuccess = entity => {
  return {
    type: CREATE_SUCCESS,
    payload: entity
  }
}

const createFailure = error => {
  return {
    type: CREATE_FAILURE,
    payload: error
  }
}

const update = entity => {
  return {
    type: UPDATE_REQUEST,
    payload: entity
  }
}

const updateSuccess = entity => {
  return {
    type: UPDATE_SUCCESS,
    payload: entity
  }
}

const updateFailure = (entityId, error) => {
  return {
    type: UPDATE_FAILURE,
    payload: {
      entityId,
      error
    }
  }
}

const selectEntity = id => {
  return {
    type: SELECT_ENTITY,
    payload: id
  }
}

const unselectEntity = () => {
  return {
    type: UNSELECT_ENTITY
  }
}

const openCreateForm = () => {
  return {
    type: OPEN_CREATE_FORM
  }
}

const closeCreateForm = () => {
  return {
    type: CLOSE_CREATE_FORM
  }
}

export {
  FETCH_ENTITIES_REQUEST,
  FETCH_ENTITIES_SUCCESS,
  FETCH_ENTITIES_FAILURE,
  fetchEntitiesRequest,
  fetchEntitiesSuccess,
  fetchEntitiesFailure,
  FETCH_ONE_REQUEST,
  FETCH_ONE_SUCCESS,
  FETCH_ONE_FAILURE,
  fetchOneRequest,
  fetchOneSuccess,
  fetchOneFailure,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  CREATE_FAILURE,
  create,
  createSuccess,
  createFailure,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,
  update,
  updateSuccess,
  updateFailure,
  SELECT_ENTITY,
  UNSELECT_ENTITY,
  selectEntity,
  unselectEntity,
  OPEN_CREATE_FORM,
  CLOSE_CREATE_FORM,
  openCreateForm,
  closeCreateForm
}
