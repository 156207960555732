import * as React from 'react'
import ReactDOM from 'react-dom'
import { SortableElement } from 'react-sortable-hoc'
import { withTheme } from 'styled-components'
import {
  CarouselCardStyled,
  CarouselCardContent,
  ButtonsContainer,
  ButtonContainer,
  ButtonTitle,
  ButtonValue,
  AddButtonContainer,
  CarouselCardActions,
  CarouselCardContainer
} from './styles'
import { ElementText, ElementImage, MoveHandle, Trash } from '..'
import { LinkIcon } from '../../icons'
import { urlIsValid } from '../../helpers'
import ButtonEdit from './ButtonEdit'

const initState = {
  buttonEditPosition: null,
  buttonEditContent: null
}

const buttonTitlePlaceholder = 'Button title'
const buttonUrlPlaceholder = 'https://tolk.ai'

const showButtonError = ({ title, value: url }) => {
  if (!title && !url) return "Your button's title and url are empty"
  if (!urlIsValid(url)) return 'Your button url needs start with https://'
  if (!url) return 'Your button url is empty'
  if (!title) return 'Your button title is empty'
}

class CarouselCard extends React.Component {
  state = initState

  handleButtonClick = e => {
    e.stopPropagation()
    const buttonPosition = e.target.getBoundingClientRect()
    const {
      theme: { width, height }
    } = this.props
    this.setState({
      buttonEditPosition: {
        left: buttonPosition.left - width.buttonEditContainer,
        top: buttonPosition.top + buttonPosition.height / 2 - height.buttonEditContainer / 2
      },
      buttonEditIndex: e.target.dataset.index
    })
  }

  handleResetButtonEditPortal = () => {
    this.setState(initState)
  }

  handleButtonTitleChange = text => {
    this.props.changeCarouselButtonTitle(this.state.buttonEditIndex, text)
  }
  handleButtonValueChange = text => {
    this.props.changeCarouselButtonValue(this.state.buttonEditIndex, text)
  }

  render() {
    const {
      attachment,
      changeCarouselCardTitle,
      changeCarouselCardDescription,
      saveImage,
      changeImage,
      deleteImage,
      deleteCard,
      addButton,
      deleteButton,
      noActions,
      settings,
      canEdit
    } = this.props
    const { title, buttons, text: description, image } = attachment
    const { buttonEditPosition, buttonEditIndex } = this.state
    return (
      <CarouselCardContainer>
        {buttonEditPosition &&
          ReactDOM.createPortal(
            <ButtonEdit
              {...buttonEditPosition}
              settings={settings.carousel.buttons}
              buttonTitlePlaceholder={buttonTitlePlaceholder}
              buttonUrlPlaceholder={buttonUrlPlaceholder}
              button={buttons[buttonEditIndex]}
              onButtonTitleChange={this.handleButtonTitleChange}
              onButtonValueChange={this.handleButtonValueChange}
              resetButtonEditPortal={this.handleResetButtonEditPortal}
            />,
            document.body
          )}
        <CarouselCardActions>
          {!noActions && canEdit && (
            <React.Fragment>
              <Trash relative showOnHover onTrashClick={deleteCard} />
              <MoveHandle relative />
            </React.Fragment>
          )}
        </CarouselCardActions>
        <CarouselCardStyled>
          <ElementImage
            {...image}
            settings={settings.image}
            forCarousel
            changeImage={changeImage}
            saveImage={saveImage}
            deleteImage={deleteImage}
            canEdit={canEdit}
          />
          <CarouselCardContent>
            <ElementText
              forCarousel
              type='title'
              text={title}
              placeholder='Your title here'
              changeText={changeCarouselCardTitle}
              maxLength={settings.carousel.title.maxLength}
            />
            <ElementText
              forCarousel
              type='description'
              text={description}
              placeholder='Your description here'
              changeText={changeCarouselCardDescription}
              maxLength={settings.carousel.description.maxLength}
            />
            <ButtonsContainer>
              {buttons.map((button, index) => (
                <ButtonContainer
                  key={index}
                  onClick={canEdit ? this.handleButtonClick : () => 'Button is disabled'}
                  data-index={index}
                  error={showButtonError(button)}
                  buttonContainer
                >
                  <ButtonTitle asPlaceholder={!button.title}>{button.title || buttonTitlePlaceholder}</ButtonTitle>
                  <ButtonValue asPlaceholder={!button.value}>
                    <LinkIcon />
                    {button.value || buttonUrlPlaceholder}
                  </ButtonValue>
                  {buttons.length > 1 && canEdit && (
                    <Trash
                      inside
                      showOnHover
                      onTrashClick={e => {
                        e.stopPropagation()
                        deleteButton(index)
                      }}
                    />
                  )}
                </ButtonContainer>
              ))}
            </ButtonsContainer>
            {buttons.length < settings.carousel.buttons.max && canEdit && (
              <AddButtonContainer onClick={addButton}>+ Add Button</AddButtonContainer>
            )}
          </CarouselCardContent>
        </CarouselCardStyled>
      </CarouselCardContainer>
    )
  }
}

export default SortableElement(withTheme(CarouselCard))
