import types from './types'

const fetchMessagesHeatRequest = () => ({
  type: types.FETCH_MESSAGESHEAT_REQUEST
})

const fetchMessagesHeatSuccess = payload => ({
  type: types.FETCH_MESSAGESHEAT_SUCCESS,
  payload
})

const fetchMessagesHeatFailure = errors => ({
  type: types.FETCH_MESSAGESHEAT_NO_DATA,
  errors
})

export default {
  fetchMessagesHeatRequest,
  fetchMessagesHeatSuccess,
  fetchMessagesHeatFailure
}
