import { connect } from 'react-redux'

import {
  fetchHealthRateRequest,
  changeDates
} from '../actions'
import Dashboard from '../components/Dashboard/Dashboard'
import {
  getStartDate,
  getEndDate,
  getHealthRate
} from '../selectors'

import { AppState } from '../types'

const mapStateToProps = (state: AppState) => ({
  startDate: getStartDate(state),
  endDate: getEndDate(state),
  healthRate: getHealthRate(state)
})

const mapDispatchToProps = {
  onDatesChange: changeDates,
  fetchHealthRate: fetchHealthRateRequest
}

const DashboardContainer = connect(mapStateToProps, mapDispatchToProps)(Dashboard)

export default DashboardContainer
