import * as React from 'react'
import * as R from 'ramda'
import { MdDelete, MdLabel } from 'react-icons/md'
import { Maybe, remoteData } from '@sbizeul/fp-flow'

import { renderNothing } from 'helpers/react'
import TextInput from 'components/TextInput'
import answer from 'modules/answer'
import { Keywords, TrackableKeyword } from 'modules/keyword/types'
import { Id } from 'types'

import './styles.css'

type MinimalObject = Readonly<{
  label: string
  id: Id
}>

type FunctionProps = Readonly<{
  add: () => unknown
  onChangeText: ({ index, text }: {
    index: number
    text: string
  }) => unknown
  remove: (id: Id) => unknown
  updateKeyword: ({ index, keyword }: {
    index: number
    keyword: Maybe<TrackableKeyword>
  }) => unknown
  keywords: Keywords
  selectedKeyword: (index: number) => unknown
}>

type Props<T extends MinimalObject> = FunctionProps & Readonly<{
  elements: ReadonlyArray<T>
}>

const renderKeywordDropdown = (props: Omit<FunctionProps, 'add'>, index: number) =>
    <div className='Quick-reply-keyword-container'>
      <MdLabel size='20' className='Quick-reply-keyword-label' /><answer.components.KeywordDropdown
        onSelectKeyword={(keyword: Maybe<TrackableKeyword>) => props.updateKeyword({ index, keyword })}
        keywords={props.keywords}
        selectedKeyword={props.selectedKeyword(index)}
      />
    </div>

const renderButton: (props: Omit<FunctionProps, 'add'>) => (index: number) => React.FunctionComponent<MinimalObject> =
  (props) => (index) => (element) => <div key={element.id} className='Quick-reply--button-container'>
      <div className='Quick-reply--button'>
        <TextInput
          type='rounded'
          name='text'
          className='Quick-reply--button---text'
          autocomplete='off'
          value={element.label}
          onChangeText={(text: string) => props.onChangeText({ index, text })}
          placeholder='Type your message...'
        />
        <MdDelete
          className='Quick-reply--button--remove'
          size='20'
          onClick={() => props.remove(element.id)}
        />
      </div>
      {R.pipe(
        remoteData.getOrElse(() => []),
        R.ifElse(R.isEmpty, renderNothing, () => renderKeywordDropdown(props, index))
      )(props.keywords)
      }
    </div>

const renderButtons = (props: Omit<FunctionProps, 'add'>) =>
  (listOfQuickReplies: ReadonlyArray<MinimalObject>) => R.addIndex<MinimalObject, unknown>(R.map)((element, index) =>
    renderButton(props)(index)(element), listOfQuickReplies
  )

const QuickReplies = <T extends MinimalObject>(props: Props<T>) => <div className='Quick-reply'>
  Quick reply
  {renderButtons({ ...props })(props.elements)}
  <div onClick={props.add} className='Quick-reply--add'>
    ＋
  </div>
</div>

export default QuickReplies
