import React from 'react'
import { Route, Switch } from 'react-router-dom'

import EntityContainer from './EntityContainer'
import EntitiesContainer from './EntitiesContainer'

function EntityIndex() {
  return (
    <Switch>
      <Route exact path='/entities/:id' component={EntityContainer} />
      <Route exact path='/entities' component={EntitiesContainer} />
    </Switch>
  )
}

export default EntityIndex
