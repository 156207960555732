import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import _ from 'lodash'
import { Grid, Segment, Button, Icon } from 'semantic-ui-react'
import { maybe } from '@sbizeul/fp-flow'

import PieChart from '../../components/PieCharts/CustomPieChart'
import Table from '../../components/Table/CustomTable'
import AreaChart from '../../components/AreaCharts/CustomAreaCharts'
import getSafe from '../../../../utils/getSafe'
import operations from '../../../../../redux/analytics/operations'
import DeleteModal from '../_components/DeleteModal'
import Filters from '../../Filters/NewFilters'
import bot from '../../../../../modules/bot'

const style = {
  border: '1px solid #e6eaee',
  WebkitBoxShadow: '0 0 0 0',
  padding: '5px'
}

const getSeriesData = state => getSafe(() => state.analytics.events.data)

// links series fetched to the API to the chart we want to generate
const getChartSeries = (chart, seriesData) =>
  chart.data.map(serie => seriesData.find(elem => elem.id && serie.id && elem.id === serie.id))

class Events extends Component {
  componentDidMount() {
    const { filters } = this.props
    this.props.fetchEvents(filters)
  }

  componentDidUpdate(prevProps) {
    const { filters } = this.props
    if (!maybe.equals(this.props.botId, prevProps.botId) || prevProps.filters.channel.id !== filters.channel.id) {
      this.props.fetchEvents(filters)
    }
  }

  getBotId() {
    return maybe.getOrElse(() => 'unknownBotId', this.props.botId)
  }

  render() {
    const { charts, seriesData } = this.props
    let customCharts = ''
    if (charts.config && seriesData) {
      customCharts = charts.config.map(chart => {
        switch (chart.chart) {
        case 'pie': {
          const data = _.get(getChartSeries(chart, seriesData), '0.data.data', null)
          if (data) {
            return (
              <Grid key={chart.id}>
                <Grid.Column>
                  <h1>{chart.name}</h1>
                  <Segment key={chart.id} style={style}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <DeleteModal
                        id={chart.id}
                        description={chart.name}
                        botId={this.getBotId()}
                        reloadEvents={this.props.fetchEvents}
                      />
                    </div>
                    <PieChart name={chart.name} chartId={chart.id} data={data} />
                  </Segment>
                </Grid.Column>
              </Grid>
            )
          }
          return null
        }
        case 'line': {
          const data = getChartSeries(chart, seriesData).map(elem => {
            if (elem === undefined) {
              return null
            }
            return {
              name: elem.name,
              data: elem.data.data
            }
          })
          if (data && data.every(elem => elem)) {
            return (
              <Grid key={chart.id}>
                <Grid.Column>
                  <h1>{chart.name}</h1>
                  <Segment key={chart.id} style={style}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <DeleteModal
                        id={chart.id}
                        description={chart.name}
                        botId={this.getBotId()}
                        reloadEvents={this.props.fetchEvents}
                      />
                    </div>
                    <AreaChart chartId={chart.id} data={data} />
                  </Segment>
                </Grid.Column>
              </Grid>
            )
          }
          return null
        }
        case 'table': {
          const data = _.get(getChartSeries(chart, seriesData), '0.data.data', null)
          const header = _.get(getChartSeries(chart, seriesData), '0.data.header', null)
          if (header && data && data.every(elem => elem)) {
            return (
              <Grid key={chart.id}>
                <Grid.Column>
                  <h1>{chart.name}</h1>
                  <Segment key={chart.id} style={style}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <DeleteModal
                        id={chart.id}
                        description={chart.name}
                        botId={this.getBotId()}
                        reloadEvents={this.props.fetchEvents}
                      />
                    </div>
                    <Table data={data} header={header} />
                  </Segment>
                </Grid.Column>
              </Grid>
            )
          }
          return null
        }
        default:
          return null
        }
      })
    }
    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button inverted size='small' color='green' as={Link} to={'/analytics/events/add'}>
            <Icon name='add' />
            Add Custom
          </Button>
        </div>
        <Filters {...this.props} fetch={this.props.fetchEvents} />
        {customCharts}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  filters: state.main.filters,
  botId: bot.selectors.getSelectedId(state),
  charts: getSafe(() => state.analytics.events),
  seriesData: getSeriesData(state)
})

export default connect(mapStateToProps, operations)(Events)
