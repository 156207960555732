import React from 'react'
import PropTypes from 'prop-types'
import { Form, Label } from 'semantic-ui-react'

const FieldLabel = ({ error, warning }) => (
  <Label basic color={(error && 'red') || (warning && 'yellow')} pointing>
    {error}
  </Label>
)

FieldLabel.propTypes = {
  error: PropTypes.string,
  warning: PropTypes.string
}

FieldLabel.defaultProps = {
  error: undefined,
  warning: undefined
}

const SelectField = ({ input, label, options, required, type, meta: { touched, error, warning } }) => (
  <Form.Field>
    {required ? <label htmlFor={input.name}>{`${label}*`}</label> : <label htmlFor={input.name}>{label}</label>}
    <div>
      <Form.Select
        {...input}
        options={options}
        placeholder={label}
        type={type}
        onChange={(param, data) => input.onChange(data.value)}
        error={touched && error ? true : null}
      />
      {touched && (error || warning) && <FieldLabel error={error} warning={warning} />}
    </div>
  </Form.Field>
)

export default SelectField
