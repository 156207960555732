export const FETCH_ONE_REQUEST = 'qa/FETCH_ONE_REQUEST'
export const FETCH_ONE_SUCCESS = 'qa/FETCH_ONE_SUCCESS'
export const FETCH_ONE_FAILURE = 'qa/FETCH_ONE_FAILURE'

export const IMPORT_REQUEST = 'qa/IMPORT_REQUEST'
export const IMPORT_SUCCESS = 'qa/IMPORT_SUCCESS'
export const IMPORT_FAILURE = 'qa/IMPORT_FAILURE'

export const SAVE_ONE_REQUEST = 'qa/SAVE_ONE_REQUEST'
export const SAVE_ONE_SUCCESS = 'qa/SAVE_ONE_SUCCESS'
export const SAVE_ONE_FAILURE = 'qa/SAVE_ONE_FAILURE'

export const NAVIGATE_TO_QUESTIONS = 'qa/NAVIGATE_TO_QUESTIONS'
export const NAVIGATE_TO_QNA = 'qa/NAVIGATE_TO_QNA'

export const DELETE_ONE_QNA_REQUEST = 'question/DELETE_ONE_QNA_REQUEST'
export const DELETE_ONE_QNA_SUCCESS = 'question/DELETE_ONE_QNA_SUCCESS'
export const DELETE_ONE_QNA_FAILURE = 'question/DELETE_ONE_QNA_FAILURE'

export const DELETE_ALL_QNA_REQUEST = 'qa/DELETE_ALL_REQUEST'
export const DELETE_ALL_QNA_SUCCESS = 'qa/DELETE_ALL_SUCCESS'
export const DELETE_ALL_QNA_FAILURE = 'qa/DELETE_ALL_FAILURE'

export const fetchOne = id => {
  return {
    type: FETCH_ONE_REQUEST,
    payload: id
  }
}

export const fetchOneSuccess = question => {
  return {
    type: FETCH_ONE_SUCCESS,
    payload: question
  }
}

export const fetchOneFailure = (error, id) => {
  return {
    type: FETCH_ONE_FAILURE,
    payload: { error, id }
  }
}

export const importFile = data => ({
  type: IMPORT_REQUEST,
  payload: data
})

export const importFileSuccess = () => ({
  type: IMPORT_SUCCESS
})

export const importFileFailure = error => ({
  type: IMPORT_FAILURE,
  payload: error
})

export const saveOne = saveOneParam => ({
  type: SAVE_ONE_REQUEST,
  payload: saveOneParam
})

export const saveOneSuccess = id => ({
  type: SAVE_ONE_SUCCESS,
  payload: id
})

export const saveOneFailure = error => ({
  type: SAVE_ONE_FAILURE,
  payload: error
})

export const navigateToQnA = questionId => ({
  type: NAVIGATE_TO_QNA,
  payload: questionId
})

export const navigateToQuestions = () => ({
  type: NAVIGATE_TO_QUESTIONS
})

export const deleteOneQnA = id => {
  return {
    type: DELETE_ONE_QNA_REQUEST,
    payload: id
  }
}

export const deleteOneQnASuccess = () => {
  return {
    type: DELETE_ONE_QNA_SUCCESS
  }
}

export const deleteOneQnAFailure = error => ({ type: DELETE_ONE_QNA_FAILURE, payload: error })

export const deleteAllQnA = () => ({
  type: DELETE_ALL_QNA_REQUEST
})

export const deleteAllQnASuccess = () => ({
  type: DELETE_ALL_QNA_SUCCESS
})

export const deleteAllQnAFailure = error => ({
  type: DELETE_ALL_QNA_FAILURE,
  payload: error
})
