import { maybe } from '@sbizeul/fp-flow'

import actions from '../actions'
import events from '../events/operations'
import bot from '../../../modules/bot'

const fetchEvents = filters => async (dispatch, getState) => {
  const botId = maybe.get(bot.selectors.getSelectedId(getState()))
  dispatch(actions.fetchTrendsRequest())
  try {
    const config = dispatch(events.fetchBotConfig(botId))
    await Promise.all([config])
    await dispatch(events.fetchCustomEventsData(filters))
    await dispatch(actions.fetchTrendsSuccess())
  } catch (e) {
    await dispatch(actions.fetchTrendsFailure())
  }
}

export default fetchEvents
