import { connect } from 'react-redux'

import AnswerType from '../components/AnswerType'
import * as answerActions from '../actions'

const mapDispatchToProps = (dispatch, props) => ({
  createSimpleText: () => dispatch(answerActions.createSimpleText(props.intentSlug)),
  createButton: () => dispatch(answerActions.createButton(props.intentSlug)),
  createQuickReply: () => dispatch(answerActions.createQuickReply(props.intentSlug))
})


export default connect(null, mapDispatchToProps)(AnswerType)
