import React, { Component } from 'react'
import { Grid } from 'semantic-ui-react'
import { Dimmer, Loader } from 'semantic-ui-react'
import { maybe } from '@sbizeul/fp-flow'

import Filters from '../Filters'
import TrainTabs from '../Filters/TrainTabs'
import ConversationsList from '../ConversationsList'
import TestPanelWrapper from './TestPanelWrapper'

import './index.css'

class UtterancesTab extends Component {
  fetchTrainingData(props) {
    this.props.fetchTrainingUtterance(props.activeChannelId, props.dateRange, props.activeLanguage, props.activeIntent)
  }

  isFetching(props) {
    const { isFetchingAllData, isFetching } = props || this.props
    return isFetchingAllData || isFetching
  }

  getBotId() {
    return maybe.getOrElse(() => 'unknownBotId', this.props.botId)
  }

  componentDidMount() {
    this.fetchTrainingData(this.props)
  }

  componentWillReceiveProps(nextProps) {
    if (!this.isFetching(nextProps) && !maybe.equals(nextProps.botId, this.props.botId)) {
      this.fetchTrainingData(nextProps)
    }
  }

  renderConversations() {
    return (
      <ConversationsList
        activeBotId={this.getBotId()}
        listp={this.props.trainingList}
        listName={this.props.trainingListName}
      />
    )
  }

  renderLoader() {
    return (
      <Dimmer active inverted>
        <Loader size='big'>Loading...</Loader>
      </Dimmer>
    )
  }

  render() {
    return (
      <div>
        <Filters
          fetch={() => this.fetchTrainingData(this.props)}
          showIntent={this.props.showIntentFilter}
          showChannels={false}
        />
        <TrainTabs />
        <TestPanelWrapper botId={this.getBotId()} />
        <Grid>
          <Grid.Column>{this.isFetching() ? this.renderLoader() : this.renderConversations()}</Grid.Column>
        </Grid>
      </div>
    )
  }
}

export default UtterancesTab
