import * as R from 'ramda'
import { maybe } from '@sbizeul/fp-flow'
import { v4 as uuid } from 'uuid'

import { insertId, removeId } from 'helpers/object'
import { emptyButtonContent, emptyImageContent, emptyTextContent } from './models.typed'

const imagesById = R.lensPath(['imagesById'])

const takeContentProp = R.prop('content')

const toContent = R.pipe(takeContentProp, R.map(insertId))

const isNotEmpty = str => str.length > 0

const isValidTextContent = answerContent => {
  return answerContent.objectAttachment.text.every(isNotEmpty)
}

const safeHead = arr => (arr ? maybe.of(R.head(arr)) : maybe.nothing())

const truncate = (number, string) =>
  R.when(
    R.propSatisfies(length => R.gt(length, number), 'length'),
    R.pipe(R.take(number), R.append('…'), R.join(''))
  )

const getTitle = R.pipe(
  R.prop('content'),
  safeHead,
  maybe.map(R.path(['objectAttachment', 'text'])),
  maybe.flatMap(safeHead)
)

const emptyButtonAnswer = () => ({
  id: uuid(),
  type: 'postback',
  title: [''],
  payload: ''
})

const newSimpleAnswer = botId => ({
  type: 'Simple',
  bot_id: botId,
  content: []
})

const emptySimpleAnswer = (language, botId = '') =>
  R.set(R.lensProp('content'), [emptyTextContent(language)], newSimpleAnswer(botId))

const missingMessage = R.lensPath(['missing_message'])

const contentLens = R.lensProp('content')

const titleLens = R.pipe(R.lensPath(['content', 0, 'objectAttachment', 'text', 0]), missingMessage)

const buttons = R.lensPath(['buttons'])

const oneButton = index => R.pipe(R.lensPath([index]), buttons)

const title = R.pipe(
  R.view(missingMessage),
  getTitle,
  maybe.getOrElse(() => '')
)

const answerLens = index => R.pipe(R.lensPath([index, 'answer']), buttons)

const answerContentLens = index => R.pipe(R.lensProp('content'), answerLens(index))

const updateAnswer = index => answer => qrAnswer =>
  R.set(answerLens(index), answer, qrAnswer)

const removeAnswer = index => ({ id }) =>
  R.pipe(
    R.view(answerContentLens(index)),
    R.filter(answer => answer.id !== id)
  )

const addNewButton = lens => R.pipe(R.view(lens), R.append(emptyButtonAnswer()))

const addNewAnswerButton = (language, lens) => R.over(lens, R.append(emptyButtonContent(language)))

const addNewTextAnswer = (language, lens) => R.over(lens, R.append(emptyTextContent(language)))

const addNewImageAnswer = (language, lens) => R.over(lens, R.append(emptyImageContent(language)))

const addButtonAnswer = (language, index) => R.pipe(addInfos(index), addNewAnswerButton(language, contentLens))

const addInfos = index => qrAnswer =>
  R.pipe(R.view(R.compose(buttons, R.lensPath([index, 'answer']))), R.merge(newSimpleAnswer(qrAnswer.bot_id)))(qrAnswer)

const addTextAnswer = (language, index) => R.pipe(addInfos(index), addNewTextAnswer(language, contentLens))

const addImageAnswer = (language, index) => R.pipe(addInfos(index), addNewImageAnswer(language, contentLens))

const removeButton = id =>
  R.pipe(
    R.view(buttons),
    R.filter(qr => qr.id !== id)
  )

const isSimpleAnswer = R.pipe(R.view(contentLens), R.isNil, R.not)

const isSimpleAnswerMaybe = maybe.fold(() => true, isSimpleAnswer)

const isFormContent = content => content.type === 'form'

const isGotoSkillContent = content => content.type === 'skill'

const isSkillReferenceContent = R.anyPass([isFormContent, isGotoSkillContent])

const hasNoSkillReference = maybe.fold(
  () => true,
  R.pipe(
    R.view(contentLens),
    R.filter(isSkillReferenceContent),
    R.isEmpty
  )
)

const isEmptySimpleAnswer = maybe.fold(
  () => true,
  answer => R.view(contentLens, answer).length === 0
)

const isEmptyQRAnswer = maybe.isNothing

const isEmptyAnswer = answer => (isSimpleAnswerMaybe(answer) ? isEmptySimpleAnswer(answer) : isEmptyQRAnswer(answer))

const toSimpleReceivedAnswer = R.over(contentLens, R.map(removeId))

const insertIdInsideCarouselContent = R.over(
  contentLens,
  R.map(
    content => R.propEq('type', 'carousel')(content) ? R.over(R.lensProp('objectAttachment'), R.map(insertId))(content) : content
  )
)

const insertIdInsideButtonContent = R.over(
  contentLens,
  R.map(
    content => R.propEq('type', 'button')(content) ? insertId(content) : content
  )
  )

const toSimpleAnswer = R.pipe(
  R.over(contentLens, R.map(insertId)),
  insertIdInsideCarouselContent
)

const toQRReceivedAnswer = R.pipe(
  R.over(R.lensProp('missing_message'), toSimpleReceivedAnswer),
  R.over(R.lensProp('buttons'), R.map(removeId)),
  R.over(R.lensProp('buttons'), R.map(R.over(R.lensProp('answer'), toSimpleReceivedAnswer)))
)

const toQRAnswer = R.pipe(
  R.over(R.lensProp('missing_message'), toSimpleAnswer),
  R.over(R.lensProp('buttons'), R.map(insertId)),
  R.over(R.lensProp('buttons'), R.map(R.over(R.lensProp('answer'), toSimpleAnswer)))
)

const toReceivedAnswer = ans =>
  isSimpleAnswerMaybe(maybe.of(ans)) ? toSimpleReceivedAnswer(ans) : toQRReceivedAnswer(ans)

const toAnswer = ans => (isSimpleAnswerMaybe(maybe.of(ans)) ? toSimpleAnswer(ans) : toQRAnswer(ans))

export {
  addButtonAnswer,
  addNewButton,
  addTextAnswer,
  addImageAnswer,
  removeButton,
  answerLens,
  answerContentLens,
  buttons,
  contentLens,
  emptyButtonAnswer,
  emptySimpleAnswer,
  getTitle,
  hasNoSkillReference,
  imagesById,
  insertIdInsideCarouselContent,
  insertIdInsideButtonContent,
  isEmptyAnswer,
  isSimpleAnswer,
  isSimpleAnswerMaybe,
  isValidTextContent,
  missingMessage,
  newSimpleAnswer,
  oneButton,
  removeAnswer,
  takeContentProp,
  title,
  titleLens,
  toContent,
  toAnswer,
  toReceivedAnswer,
  truncate,
  updateAnswer
}
