import React from 'react'
import PropTypes from 'prop-types'

const DuplicateIcon = ({ width, height, fill }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 48 48'>
      <g className='nc-icon-wrapper' fill={fill}>
        <path d='M32 16h-4v6h-6v4h6v6h4v-6h6v-4h-6zM4 24c0-5.58 3.29-10.39 8.02-12.64V7.05C5.03 9.51 0 16.17 0 24s5.03 14.49 12.02 16.95v-4.31C7.29 34.39 4 29.58 4 24zM30 6c-9.93 0-18 8.07-18 18s8.07 18 18 18 18-8.07 18-18S39.93 6 30 6zm0 32c-7.72 0-14-6.28-14-14s6.28-14 14-14 14 6.28 14 14-6.28 14-14 14z' />
      </g>
    </svg>
  )
}

DuplicateIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string
}

DuplicateIcon.defaultProps = {
  width: '1.5em',
  height: '1.5em',
  fill: 'currentColor'
}

export default DuplicateIcon
