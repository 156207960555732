import React, { useState, useEffect } from 'react'
import { MdKeyboardArrowLeft } from 'react-icons/md'
import { Button, Dropdown, Modal } from 'semantic-ui-react'
import { either, Either } from '@sbizeul/fp-flow'

import { renderDotLoader, renderServerFailure } from 'helpers/react'
import { ServerFailure } from 'types'
import question from 'modules/question'
import { Section } from 'modules/question/types'

import { UpdatingSection, OpeningStatusInfo, OpeningStatusInfoList } from '../../types'
import { EditableTitle } from './EditableTitle'

import './styles.css'

type Props = {
  section: Section
  open: boolean
  isUpdatingSection: Either<ServerFailure, UpdatingSection>
  children: React.ReactNode
  collapsibleSectionsOpeningStatus: OpeningStatusInfoList

  onLabelUpdate: (title: string) => unknown
  onSectionDeleted: () => unknown
  saveOpeningStatus: (info: OpeningStatusInfo) => unknown
}

const renderMenu = (editSection: () => unknown, deleteSection: () => unknown) => (
  <Dropdown icon='ellipsis horizontal' direction='left' className='QaQuestionPage-section-menu'>
    <Dropdown.Menu>
      <Dropdown.Item icon='edit' text='Edit' onClick={editSection}/>
      <Dropdown.Item icon='delete' text='Delete' onClick={deleteSection}/>
    </Dropdown.Menu>
  </Dropdown>
)

const CollapsibleSection = (props: Props) => {
  const getOpeningStatus = () => {
    const result = props.collapsibleSectionsOpeningStatus[props.section.id]
    return typeof result === 'boolean' ? result : true
  }

  const [isOpen, setIsOpen] = useState(getOpeningStatus())
  const [editMode, setEditMode] = useState(false)
  const [openModal, setOpenModal] = useState(false)

  useEffect(() => {
    props.saveOpeningStatus({
      id: props.section.id,
      isOpened: isOpen
    })
  }, [isOpen])

  const isSectionEditable = () => !question.models.isUnlabeledSection(props.section)

  const toggleEditModeOn = () => setEditMode(true)

  const toggleEditModeOff = () => setEditMode(false)

  const openModalAlert = () => setOpenModal(true)

  const closeModalAlert = () => setOpenModal(false)

  const handleTitleUpdate = (title: string) => {
    props.onLabelUpdate(title)
    toggleEditModeOff()
  }

  const handleDeleteSection = () => {
    props.onSectionDeleted()
    closeModalAlert()
  }

  const renderEditableTitle = () => (
    <EditableTitle
      originalText={props.section.label}
      onCancel={toggleEditModeOff}
      onChange={handleTitleUpdate}
    />
  )

  const renderSectionUpdating = () => (
    <div className='QaQuestionPage-section-title'>{renderDotLoader('updating section')}</div>
  )

  const renderDefaultTitle = () =>
    <p className='QaQuestionPage-section-title QaQuestionPage-section-header-font'>{props.section.label}</p>

  const renderTitle = () => editMode ? renderEditableTitle() : renderDefaultTitle()

  const renderTitleOrLoader = () => either.fold(
    renderServerFailure,
    isUpdatingSection => either.fold(
      renderTitle,
      sectionId => props.section.id === sectionId ? renderSectionUpdating() : renderEditableTitle(),
      isUpdatingSection
    ),
    props.isUpdatingSection
  )

  return <div className='QaQuestionPage-section-questionList'>
    <div className='QaQuestionPage-section-header' onClick={() => setIsOpen(!isOpen)}>
      {renderTitleOrLoader()}
      <MdKeyboardArrowLeft size='25' className={`QaQuestionPage-section-arrow-icon ${isOpen && 'open'}`}/>
      {isSectionEditable() && renderMenu(toggleEditModeOn, openModalAlert)}
    </div>
    {isOpen && props.children}
    <Modal open={openModal} onClose={closeModalAlert}>
      <Modal.Header>Delete the section {props.section.label}</Modal.Header>
      <Modal.Content>
        <p>Are you sure you want to delete this section?</p>
        <p>Watch out, this action is irreversible!</p>
      </Modal.Content>
      <Modal.Actions>
        <Button negative content='No' onClick={closeModalAlert}/>
        <Button positive icon='checkmark' labelPosition='right' content='Yes' onClick={handleDeleteSection}/>
      </Modal.Actions>
    </Modal>
  </div>
}

export {
  CollapsibleSection
}
