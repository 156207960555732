import * as R from 'ramda'
import { createSelector } from 'reselect'
import { settings } from './reference'
import { ElementTypes, SCHEDULED, CampaignStatus } from './types'
import { urlIsValid } from './helpers'

export const getCampaigns = ({ broadcast }) => broadcast.campaigns

export const getCohorts = ({ broadcast }) => broadcast.cohorts

export const getChannel = ({ broadcast }) => broadcast.channel

export const getPreviewUsers = ({ broadcast }) => broadcast.previewUsers

export const getCurrentAnalytics = ({ broadcast }) => broadcast.currentAnalytics

export const getSettings = createSelector(getChannel, channel => settings[channel] || settings.default)

export const isNoCampaign = createSelector(getCampaigns, campaigns => !campaigns.length)

const ordCampaignDatesSort = (camp1, camp2) => (camp1.date.isBefore(camp2.date) ? 1 : -1)
const decCampaignDatesSort = (camp1, camp2) => (camp1.date.isBefore(camp2.date) ? -1 : 1)

export const getCampaignsWithStatus = status =>
  createSelector(getCampaigns, campaigns =>
    [...campaigns.filter(campaign => campaign.status === status)].sort(
      status === SCHEDULED ? decCampaignDatesSort : ordCampaignDatesSort
    )
  )

export const getCurrentCampaignId = ({ broadcast }) => broadcast.currentId

export const getCurrentCampaign = createSelector(
  [getCurrentCampaignId, getCampaigns],
  (campaignId, campaigns) => campaigns.find(({ id }) => id === campaignId) || {}
)

export const isNoCurrentCampaign = createSelector(getCurrentCampaignId, currentId => !currentId)

export const canSaveCurrentCampaign = createSelector(
  [isNoCurrentCampaign, getCurrentCampaign],
  (noCampaign, campaign) => noCampaign || campaign.updated
)

export const getCurrentCampaignSendingPreview = createSelector(
  [isNoCurrentCampaign, getCurrentCampaign],
  (noCampaign, campaign) => noCampaign || campaign.sendingPreview
)

export const getCurrentCampaignSentDate = createSelector(
  [isNoCurrentCampaign, getCurrentCampaign],
  (noCampaign, { status, sentDate }) => {
    if (noCampaign || status !== CampaignStatus.sent) return false
    return sentDate
  }
)

export const canSendPreview = createSelector(
  [isNoCurrentCampaign, getCurrentCampaign, getPreviewUsers],
  (noCampaign, { status }, previewUsers) => {
    if (noCampaign) return false
    const hasPreviewUsers = !!previewUsers && !R.isEmpty(previewUsers)
    const checkStatus = status === CampaignStatus.draft || status === CampaignStatus.scheduled
    return checkStatus && hasPreviewUsers
  }
)

export const canSendCurrentCampaign = createSelector(
  [isNoCurrentCampaign, getCurrentCampaign],
  (noCampaign, campaign) => {
    if (noCampaign) return false
    if (!campaign.title.length) return false
    if (!campaign.cohorts.length) return false
    if (!campaign.elements.length) return false
    for (let element of campaign.elements) {
      switch (element.type) {
      default:
        break
      case ElementTypes.text:
        if (!element.content.length) return false
        break
      case ElementTypes.url:
        if (!element.content.length) return false
        if (!urlIsValid(element.content)) return false
        break
      case ElementTypes.carousel:
        const carousel = element.content.attachments
        for (let card of carousel) {
          if (!card.title) return false
          if (!card.text) return false
          for (let button of card.buttons) {
            if (!button.title) return false
            if (!button.value) return false
            if (!urlIsValid(button.value)) return false
          }
        }
        break
      }
    }
    return true
  }
)

export const canPreviewCurrentCampaign = createSelector(
  [isNoCurrentCampaign, getCurrentCampaign],
  (noCampaign, campaign) => {
    if (campaign.sendingPreview) return false
    if (noCampaign) return false
    if (!campaign.title.length) return false
    if (!campaign.elements.length) return false
    for (let element of campaign.elements) {
      switch (element.type) {
      default:
        break
      case ElementTypes.text:
        if (!element.content.length) return false
        break
      case ElementTypes.url:
        if (!element.content.length) return false
        if (!urlIsValid(element.content)) return false
        break
      case ElementTypes.carousel:
        const carousel = element.content.attachments
        for (let card of carousel) {
          if (!card.title) return false
          if (!card.text) return false
          for (let button of card.buttons) {
            if (!button.title) return false
            if (!button.value) return false
            if (!urlIsValid(button.value)) return false
          }
        }
        break
      }
    }
    return true
  }
)

export const getCurrentCampaignTitle = createSelector(getCurrentCampaign, campaign => campaign.title)

export const getCurrentCampaignCohorts = createSelector(getCurrentCampaign, campaign => campaign.cohorts)

export const getCurrentCampaignRecurring = createSelector(getCurrentCampaign, campaign => campaign.recurring)

export const getCurrentCampaignRecurrence = createSelector(getCurrentCampaign, campaign => campaign.recurrence)

export const getCurrentCampaignElements = createSelector(getCurrentCampaign, campaign => campaign.elements)

export const getCurrentCampaignDate = createSelector(getCurrentCampaign, campaign => campaign.date)

export const getCurrentCampaignSendNow = createSelector(getCurrentCampaign, campaign => campaign.sendNow)

export const getCurrentCampaignStatus = createSelector(getCurrentCampaign, campaign => campaign.status)

export const getCarouselCard = (carousel, cardIndex) => carousel.content.attachments[cardIndex]

export const getCarouselLength = carousel => carousel.content.attachments.length
