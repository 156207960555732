import types from './types'

const fetchDemographicsRequest = () => ({
  type: types.FETCH_DEMOGRAPHICS_REQUEST
})

const fetchDemographicsSuccess = payload => ({
  type: types.FETCH_DEMOGRAPHICS_SUCCESS,
  payload
})

const fetchDemographicsFailure = errors => ({
  type: types.FETCH_DEMOGRAPHICS_NO_DATA,
  errors
})

export default {
  fetchDemographicsRequest,
  fetchDemographicsSuccess,
  fetchDemographicsFailure
}
