import axios from 'axios'
import { maybe } from '@sbizeul/fp-flow'

import formatFilters from '../../_shared/formatFilters'
import actions from './actions'
import bot from '../../../modules/bot'
import api from 'services/tolkApiLegacy'

const fetchDemographics = rawFilters => {
  const { channelId, start, end, period } = formatFilters(rawFilters)

  return async (dispatch, getState) => {
    const botId = bot.selectors.getSelectedId(getState())
    dispatch(actions.fetchDemographicsRequest())
    try {
      const response = await api.get(
        `/analytics/${maybe.get(botId)}/${channelId}/users/demographics`,
        {
          params: {
            start,
            end,
            period
          }
        }
      )
      if (response.data && response.data.result) {
        const formatedResponse = {
          count: response.data.result.count
        }
        dispatch(actions.fetchDemographicsSuccess(formatedResponse))
      } else {
        dispatch(actions.fetchDemographicsFailure())
      }
    } catch (e) {
      dispatch(actions.fetchDemographicsFailure())
    }
  }
}

export default { fetchDemographics }
