import * as React from 'react'
import { withFormik } from 'formik'
import * as Yup from 'yup'
import classNames from 'classnames'
import { MdRemoveRedEye } from 'react-icons/md'

import FormInput from '../../../../components/FormInput'
import Button from '../../../../components/Button'

import './styles.css'

export class AuthForm extends React.Component {
  state = {
    showPassword: false
  }

  getError(field, { touched, errors }) {
    return touched[field] && errors[field] ? errors[field] : null
  }

  handleSubmit = ({ values, authenticate }) => {
    return e => {
      e.preventDefault()
      authenticate({
        username: values.username,
        password: values.password
      })
    }
  }

  render() {
    return (
      <form className={classNames('AuthForm', this.props.className)} onSubmit={this.handleSubmit(this.props)}>
        <FormInput
          type='text'
          name='username'
          label='Username'
          placeholder='Username'
          onChange={this.props.handleChange}
          value={this.props.values.username}
          error={this.getError('username', this.props)}
          autoComplete='username'
        />
        <FormInput
          name='password'
          type={this.state.showPassword ? 'text' : 'password'}
          icon={
            <MdRemoveRedEye
              className={` AuthForm-icon ${classNames({
                'AuthForm-icon-active': this.state.showPassword
              })}`}
              onClick={() => this.setState({ showPassword: !this.state.showPassword })}
            />
          }
          label='Password'
          placeholder='Password'
          onChange={this.props.handleChange}
          value={this.props.values.password}
          error={this.getError('password', this.props)}
          autoComplete='current-password'
        />
        <p className='AuthForm-help'>
          Need help? <a href='mailto:hello@tolk.ai'>Drop us a line</a>
        </p>
        <Button
          className='AuthForm-submitButton primary'
          type='submit'
          display='rounded'
          disabled={!this.props.isValid}
        >
          <p className='AuthForm-button-text'>LOG IN</p>
        </Button>
      </form>
    )
  }
}

const validationSchema = Yup.object().shape({
  username: Yup.string().required(),
  password: Yup.string()
    .min(8)
    .required()
})

export default withFormik({
  validationSchema,
  mapPropsToValues: () => ({
    username: '',
    password: ''
  }),
  enableReinitialize: true,
  isInitialValid: false,
  handleSubmit: () => {},
  displayName: 'AuthForm'
})(AuthForm)
