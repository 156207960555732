import { maybe } from '@sbizeul/fp-flow'
import get from '../../../_shared/get'
import formatFilters from '../../../_shared/formatFilters'
import actions from '../actions'
import bot from '../../../../modules/bot'

const fetchFunnel = rawFilters => {
  const { channelId, start, end } = formatFilters(rawFilters)

  return async (dispatch, getState) => {
    const botId = bot.selectors.getSelectedId(getState())
    dispatch(actions.fetchFunnelRequest())
    try {
      const response = await get(`analytics/${maybe.get(botId)}/${channelId}/events/funnel`, { start, end })
      const formatedResponse = {
        data: response.data
      }
      dispatch(actions.fetchFunnelSuccess(formatedResponse))
    } catch (e) {
      dispatch(actions.fetchFunnelFailure(e))
    }
  }
}

export default fetchFunnel
