import { assoc, curry, defaultTo, descend, dropLastWhile, last, map, pipe, prop, sortWith } from 'ramda'
import moment from 'moment'
import { Conversation, Message } from './types'

export const findLastAcceptableDate = curry((lastAcceptableDate: Date, conv: Conversation): string =>
  pipe(
    dropLastWhile<Message>(mess => lastAcceptableDate < new Date(mess.date)),
    lastMessage,
    defaultTo(conv.message[0]),
    prop('date')
  )(conv.message)
)

const lastMessage: (messages: Message[]) => Message = last

export const fromConversationToSortable = curry((lastAcceptableDate, conversation) =>
  assoc('lastDateInRange', findLastAcceptableDate(lastAcceptableDate)(conversation))(conversation)
)

export const sortByLastInRange = (lastAcceptableDate: Date) =>
  // @ts-ignore Fix issue with @sbizeul/fp-flow
  pipe(map(fromConversationToSortable(lastAcceptableDate)), sortWith([descend(prop('lastDateInRange'))]))

export const defaultStartDate: (date?: moment.Moment) => moment.Moment = date => date || moment().subtract(90, 'day')

export const defaultEndDate: (date?: moment.Moment) => moment.Moment = date => date || moment()
