/* eslint-disable no-unused-vars */
import { webchatParamEnv } from 'helpers/effects'
import { scriptUrlForWebChat } from '../../models'

const addBot = (webchatId) => {
  var display = 'iframe'
  var script = document.createElement('script')
  script.type = 'text/javascript'
  script.src = scriptUrlForWebChat(webchatParamEnv)

  document.body.appendChild(script)

  var tcfbot = webchatId
  var TcfWbchtParams = JSON.stringify(webchatParamFromEnv(webchatParamEnv))
}

const webchatParamFromEnv = (environment) => {
  switch (environment) {
    case 'production':
      return {
        isDisplayForced: true
      }

    default:
      return {
        env: environment,
        isDisplayForced: true,
        debug: true
      }
  }
}

const removeBot = () => {
  var iframe = document.querySelector('#chatbot-insert')
  if (iframe && iframe.parentNode) {
    iframe.parentNode.removeChild(iframe)
  }
  var script = document.querySelector(
    `script[src="${scriptUrlForWebChat(webchatParamEnv)}"]`
  )
  if (script && script.parentNode) {
    script.parentNode.removeChild(script)
  }

  var style = document.querySelector('#tolk-widget')
  if (style && style.parentNode) {
    style.parentNode.removeChild(style)
  }
}

export { addBot, removeBot }
