import formatFilters from '../../../_shared/formatFilters'
import actions from '../actions'
import axios from 'axios'
import { addNullValuesByPeriod } from '../../../../views/pages/Analytics/utils'
import { botManagementBaseUrl } from 'helpers/effects'

const fetchConversationsCountByDay = rawFilters => {
  const { channelId, start, end } = formatFilters(rawFilters)

  const startDate = encodeURIComponent(new Date(start).toISOString())
  const endDate = encodeURIComponent(new Date(end).toISOString())

  return async (dispatch, getState) => {
    dispatch(actions.fetchConversationsCountByDayRequest())
    try {
      const period = 'days'
      const response = await axios.get(
        `${botManagementBaseUrl}/v1/channels/${channelId}/detail-conversation-count?startDate=${startDate}&endDate=${endDate}`
      )

      const success = response.data.detailCount ? addNullValuesByPeriod(response.data.detailCount.map((detail) => { return {date: detail.date, total: detail.count}}), rawFilters.dateRange, period) : []
      dispatch(actions.fetchConversationsCountByDaySuccess(success))
    } catch (e) {
      dispatch(actions.fetchConversationsCountByDayFailure(e))
    }
  }
}

export default fetchConversationsCountByDay
