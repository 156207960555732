import { remoteData } from '@sbizeul/fp-flow'

import * as authActions from './actions'
import user from '../user'

export const initialState = {
  token: remoteData.notAsked()
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
  case authActions.AUTHENTICATE_REQUEST:
    return { token: remoteData.loading() }
  case authActions.AUTHENTICATE_SUCCESS:
    return { token: remoteData.success(action.payload) }
  case authActions.AUTHENTICATE_FAILURE:
    return { token: remoteData.failure(action.payload) }
  case user.actions.LOGOUT:
    return initialState
  default:
    return state
  }
}
