import actions from '../actions'

import login from './login'
import logout from './logout'

const {
  setActiveChannel,
  setActivePeriod,
  setDateRangeDropDown,
  setDateRange
} = actions

export default {
  login,
  logout,
  setActiveChannel,
  setActivePeriod,
  setDateRangeDropDown,
  setDateRange
}
