const FETCH_DELAYS_REQUEST = 'tcfdash/analytics/FETCH_DELAYS_REQUEST'
const FETCH_DELAYS_SUCCESS = 'tcfdash/analytics/FETCH_DELAYS_SUCCESS'
const FETCH_DELAYS_NO_DATA = 'tcfdash/analytics/FETCH_DELAYS_NO_DATA'

const FETCH_DELAYS_PREVIOUS_PERIOD_REQUEST = 'tcfdash/analytics/FETCH_DELAYS_PREVIOUS_PERIOD_REQUEST'
const FETCH_DELAYS_PREVIOUS_PERIOD_SUCCESS = 'tcfdash/analytics/FETCH_DELAYS_PREVIOUS_PERIOD_SUCCESS'
const FETCH_DELAYS_PREVIOUS_PERIOD_NO_DATA = 'tcfdash/analytics/FETCH_DELAYS_PREVIOUS_PERIOD_NO_DATA'

export default {
  FETCH_DELAYS_REQUEST,
  FETCH_DELAYS_SUCCESS,
  FETCH_DELAYS_NO_DATA,
  FETCH_DELAYS_PREVIOUS_PERIOD_REQUEST,
  FETCH_DELAYS_PREVIOUS_PERIOD_SUCCESS,
  FETCH_DELAYS_PREVIOUS_PERIOD_NO_DATA
}
