export const channelsConfig = {
  'iadvize': {
    name: 'iAdvize',
    fields: [
      { title: 'Site ID (format : ha-XXXX)', name: 'idWebsite', placeholder: 'Ex. ha-1234' },
      { title: 'Access Token', name: 'accessToken', placeholder: 'xxxx' }
    ]
  },
  'messenger': {
    name: 'Messenger',
    fields: [
      { title: 'Page id', name: 'pageId', placeholder: 'Ex. xxxx' },
      { title: 'Page token', name: 'pageToken', placeholder: 'Ex. xxxx' }
    ]
  },
  'twitter': {
    name: 'Twitter',
    fields: [
      { title: 'Access token', name: 'accessToken', placeholder: 'Ex. xxxx' },
      { title: 'Access token secret', name: 'accessTokenSecret', placeholder: 'Ex. xxxx' },
      { title: 'Twitter profile id', name: 'twitterProfileId', placeholder: 'Ex. xxxx' }
    ]
  },
  'dimelo': {
    name: 'Dimelo',
    fields: [
      { title: 'Token API', name: 'tokenApi', placeholder: 'Ex. xxxx' },
      { title: 'API domain', name: 'apiDomain', placeholder: 'Ex. xxxx' },
      { title: 'Verify token', name: 'verifyToken', placeholder: 'Ex. xxxx' },
      { title: 'Secret key', name: 'secretKey', placeholder: 'Ex. xxxx' },
      { title: 'Agent category', name: 'agentCategory', placeholder: 'Ex. xxxx' },
      { title: 'Bot category', name: 'botCategory', placeholder: 'Ex. xxxx' }
    ]
  },
  'zendesk': {
    name: 'Zendesk',
    fields: [
      { title: 'Access Token', name: 'accessToken', placeholder: 'Ex. xxxx' }
    ]
  }
}
