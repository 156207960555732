import * as R from 'ramda'

const countProp = <T extends string | number, K extends string>(property: K) => (data: Record<K, T>[]) => R.pipe(
  R.map<Record<K, T>, T>(R.prop(property)),
  R.countBy(R.identity)
)(data)

const toPieChartFormat: <T extends string | number, K extends string>(countByProp: K) => (data: Record<K, T>[]) => { value: string, count: number }[] =
  countByProp => R.pipe(
    countProp(countByProp),
    R.mapObjIndexed((val, key) => {
      return {
        value: key,
        count: val
      }
    }),
    R.values
  )

export const rateToPieFormat = (rate: number, label?: string) => {
  return [{
    name: label || 'UsedValue',
    value: rate
  }, {
    name: 'Other',
    value: 100 - rate
  }]
}

export {
  toPieChartFormat
}
