import axios from 'axios'
import { maybe } from '@sbizeul/fp-flow'

import isResValid from '../../../_shared/isResValid'
import formatFilters from '../../../_shared/formatFilters'
import actions from '../actions'
import bot from '../../../../modules/bot'
import { legacyApiUrl } from 'helpers/effects'

const fetchSatisfaction = rawFilters => (dispatch, getState) => {
  const { channelId, start, end, period } = formatFilters(rawFilters)
  const botId = bot.selectors.getSelectedId(getState())

  dispatch(actions.fetchSatisfactionRequest())
  return axios({
    url: `${legacyApiUrl}/analytics/${maybe.get(botId)}/${channelId}/events/satisfaction_reponse/satisfaction/intent`,
    params: {
      start,
      end,
      period
    },
    method: 'get',
    responseType: 'json'
  })
    .then(response => {
      if (isResValid(response)) {
        const formatedDataYes = Object.keys(response.data.result.data.yes || {}).map(intent => ({
          intent,
          total: response.data.result.data.yes[intent]
        }))

        const formatedDataNo = Object.keys(response.data.result.data.no || {}).map(intent => ({
          intent,
          total: response.data.result.data.no[intent]
        }))

        const formatedResponse = {
          data: { yes: formatedDataYes, no: formatedDataNo },
          count: response.data.result.count,
          graph: response.data.result.graph
        }

        dispatch(actions.fetchSatisfactionSuccess(formatedResponse))
      } else {
        dispatch(actions.fetchSatisfactionFailure())
      }
    })
    .catch(err => {
      dispatch(actions.fetchSatisfactionFailure(err))
    })
}

export default fetchSatisfaction
