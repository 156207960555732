import { pipe } from 'fp-ts/lib/pipeable'
import * as R from 'ramda'

import { IsoLanguage } from 'helpers/language'

import { BotCreation, Theme } from './types'
import { NO_USE_CASE } from './models'

export type BotCreationApi = Readonly<{
  avatar: string
  botName: string
  greetings: string
  language: IsoLanguage
  theme: Theme
  use_case?: string
}>

const botCreationToApi: (botCreation: BotCreation) => BotCreationApi = botCreation => pipe(
  ({
    ...botCreation,
    ...(botCreation.useCase !== NO_USE_CASE && { use_case: botCreation.useCase })
  }),
  apiObjectWithUseCase => R.dissoc('useCase', apiObjectWithUseCase)
)

export {
  botCreationToApi
}
