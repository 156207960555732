import moment from 'moment'
import { CampaignStatus } from '../types'

const campaignsExamples = [
  {
    id: 1,
    updated: true,
    title: 'Politics',
    cohorts: [],
    elements: [],
    kpis: {
      sent: 0,
      read: 0,
      targeted: 0
    },
    date: moment([2019, 9, 15, 15, 5]),
    timeZone: 'Europe/Paris',
    sendNow: false,
    status: CampaignStatus.scheduled
  },
  {
    id: 2,
    updated: true,
    title: 'Sports',
    cohorts: [],
    elements: [],
    kpis: {
      sent: 0,
      read: 0,
      targeted: 0
    },
    date: moment([2019, 9, 16, 15, 6]),
    timeZone: 'Europe/Paris',
    sendNow: true,
    status: CampaignStatus.draft
  },
  {
    id: 3,
    updated: true,
    title: 'Music',
    cohorts: ['sports'],
    elements: [
      {
        type: 'text',
        content: 'plouf'
      }
    ],
    kpis: {
      sent: 0,
      read: 0,
      targeted: 0
    },
    date: moment([2019, 9, 17, 15, 7]),
    timeZone: 'Europe/Paris',
    sendNow: false,
    status: CampaignStatus.toReview
  },
  {
    id: 4,
    updated: true,
    title: 'Sports',
    cohorts: [],
    elements: [
      {
        type: 'text',
        content: 'plouf'
      }
    ],
    kpis: {
      sent: 53482,
      read: 1203,
      targeted: 0
    },
    date: moment([2019, 9, 18, 15, 8]),
    timeZone: 'Europe/Paris',
    sendNow: false,
    status: CampaignStatus.sent
  },
  {
    id: 5,
    updated: true,
    title: 'Videos of sports',
    cohorts: [],
    elements: [],
    kpis: {
      sent: 23482,
      read: 1203,
      targeted: 0
    },
    date: moment([2019, 9, 19, 15, 9]),
    timeZone: 'Europe/Paris',
    sendNow: false,
    status: CampaignStatus.sent
  },
  {
    id: 6,
    updated: true,
    title: 'Politics',
    cohorts: [],
    elements: [],
    kpis: {
      sent: 3482,
      read: 203,
      targeted: 0
    },
    date: moment([2019, 9, 20, 15, 10]),
    timeZone: 'Europe/Paris',
    sendNow: false,
    status: CampaignStatus.sent
  },
  {
    id: 7,
    updated: true,
    title: 'All',
    cohorts: [],
    elements: [],
    kpis: {
      sent: 103482,
      read: 21203,
      targeted: 0
    },
    date: moment([2019, 9, 21, 15, 11]),
    timeZone: 'Europe/Paris',
    sendNow: false,
    status: CampaignStatus.sent
  },
  {
    id: 8,
    updated: true,
    title: '',
    cohorts: [],
    elements: [],
    kpis: {
      sent: 103482,
      read: 21203,
      targeted: 0
    },
    date: moment([2019, 9, 21, 15, 11]),
    timeZone: 'Europe/Paris',
    sendNow: false,
    status: CampaignStatus.sent
  }
]

export default campaignsExamples
