import reducer from './reducer'
import * as selectors from './selectors'
import * as saga from './saga'
import * as actions from './actions'
import Message from './components/Message'
import Conversation from './components/Conversation'
import ConversationList from './components/ConversationList'
import Conversations from './components/Conversations'

import ConversationContainer from './containers/ConversationContainer'

export default {
  actions,
  saga,
  reducer,
  selectors,
  components: {
    Message,
    Conversation,
    ConversationList,
    Conversations,
    ConversationContainer
  }
}
