import { LocationWithKey, ScrollManager } from './types'

const scrollManager: (elementID: string) => ScrollManager = elementID => {
  const url: Map<string, number> = new Map<string, number>()

  const registerScroll: (location: LocationWithKey) => (nextLocation: LocationWithKey) => void =
    location => nextLocation => {
      const key = location.key || 'enter'
      const locationChanged = (nextLocation.pathname !== location.pathname ||
        nextLocation.search !== location.search) &&
        nextLocation.hash === ''
      if (locationChanged) {
        const scroll = getScrollElement(elementID)
        scrollToElement(0, elementID)
        url.set(key, scroll)
      }
    }

  const scrollFor = (location: LocationWithKey) => {
    const key = location && location.key ? location.key : 'enter'
    const nextFind = url.get(key)
    if (nextFind) {
      scrollToElement(nextFind, elementID)
    }
  }

  return {
    registerScroll,
    scrollFor
  }
}

const getScrollElement = (elementId: string) => {
  const element = document.getElementById(elementId)
  return element?.scrollTop || 0
}

const scrollToElement = (scroll: number, elementId: string) => {
  const element = document.getElementById(elementId)
  if (element) {
    element.scrollTop = scroll
  }
}

export {
  scrollManager
}
