import axios from 'axios'
import moment from 'moment-timezone'
import { maybe } from '@sbizeul/fp-flow'

import API from 'services/tolkApiLegacy'

import actions from './actions'
import globalActions from '../global/actions'
import { getComparisonDateRange } from './utils'
import bot from '../../modules/bot'
import { legacyApiUrl } from 'helpers/effects'

const getTrainingSentenceFilter = (dateStart, dateEnd, language, intent) => (dispatch, getState) => {
  const botId = maybe.getOrElse('unknownBot', bot.selectors.getSelectedId(getState()))
  dispatch(actions.getTrainingSentenceFilterRequest())
  const url = `/bot/${botId}/train/messages?understood=true&start=${dateStart}&end=${dateEnd}&lang=${language}&intent=${intent}`

  return API.get(url)
    .then(response => {
      dispatch(actions.getTrainingSentenceFilterSuccess(response.data))
    })
    .catch(err => {
      dispatch(actions.getTrainingSentenceFilterFailure(err))
    })
}

const getTrainingSentenceNotMatchedFilter = (dateStart, dateEnd, language) => (dispatch, getState) => {
  dispatch(actions.getTrainingSentenceFilterNotMatchedRequest())
  const botId = maybe.getOrElse('unknownBot', bot.selectors.getSelectedId(getState()))
  const url = `/bot/${botId}/train/messages?understood=false&start=${dateStart}&end=${dateEnd}&lang=${language}`

  return API.get(url)
    .then(response => {
      dispatch(actions.getTrainingSentenceFilterNotMatchedSuccess(response.data))
    })
    .catch(err => {
      dispatch(actions.getTrainingSentenceFilterNotMatchedFailure(err))
    })
}

const getTrainingSentenceStandByFilter = (dateStart, dateEnd, language) => (dispatch, getState) => {
  const botId = maybe.getOrElse('unknownBot', bot.selectors.getSelectedId(getState()))
  dispatch(actions.getTrainingSentenceFilterStandByRequest())
  const url = `/bot/${botId}/train/messages?archived=true&start=${dateStart}&end=${dateEnd}&lang=${language}`

  return API.get(url)
    .then(response => {
      dispatch(actions.getTrainingSentenceFilterStandBySuccess(response.data))
    })
    .catch(err => {
      dispatch(actions.getTrainingSentenceFilterStandByFailure(err))
    })
}

const getTrainingUtterances = listName => {
  switch (listName) {
  case 'trainingList':
    return getTrainingSentenceFilter
  case 'trainingListNotMatched':
    return getTrainingSentenceNotMatchedFilter
  case 'trainingListStandBy':
    return getTrainingSentenceStandByFilter
  default:
    return
  }
}

const fetchBotConfig = () => (dispatch, getState) => {
  const botId = maybe.getOrElse('unknownBot', bot.selectors.getSelectedId(getState()))
  dispatch(actions.fetchBotConfigRequest())
  return axios({
    url: `${legacyApiUrl}/admin/${botId}/analytics/events`,
    method: 'get',
    responseType: 'json'
  })
    .then(response => {
      if (response.status === 200) {
        dispatch(actions.fetchBotConfigSuccess(response.data))
      } else {
        dispatch(actions.fetchBotConfigFailure())
      }
    })
    .catch(err => {
      dispatch(actions.fetchBotConfigFailure())
      dispatch(globalActions.setFetchError(`Bot Config Fetching: ${err.message}`))
    })
}

const fetchBotIntent = () => (dispatch, getState) => {
  const botId = maybe.getOrElse('unknownBot', bot.selectors.getSelectedId(getState()))
  dispatch(actions.fetchBotIntentRequest())
  return axios({
    url: `${legacyApiUrl}/contents/intents/${botId}`,
    method: 'get',
    responseType: 'json'
  })
    .then(response => {
      if (response.status === 200) {
        dispatch(actions.fetchBotIntentSuccess(response.data))
      } else {
        dispatch(actions.fetchBotIntentFailure())
      }
    })
    .catch(err => {
      dispatch(actions.fetchBotIntentFailure())
      dispatch(globalActions.setFetchError(`Bot Intent Fetching: ${err.message}`))
    })
}

const fetchTrainingData = (channelId, dateRange, language, intent) => (dispatch, getState) => {
  if (!dateRange.start || !dateRange.end) {
    dateRange.start = moment.tz('Europe/Paris')
    dateRange.end = moment.tz('Europe/Paris')
  }
  const start = dateRange.start.format('YYYY-MM-DD')
  const end = dateRange.end.format('YYYY-MM-DD')
  const comparisonDateRange = getComparisonDateRange(dateRange)

  dispatch(actions.isFetchingAllData(true))
  dispatch(
    actions.setDataFilters({
      dateRange,
      comparisonDateRange,
      channelId,
      intent
    })
  )
  Promise.all([
    dispatch(getTrainingSentenceFilter(start, end, language, intent)),
    dispatch(fetchBotConfig(channelId)),
    dispatch(fetchBotIntent())
  ]).then(() => dispatch(actions.isFetchingAllData(false)))
}

const fetchTrainingNotMatchedData = (channelId, dateRange, language) => (dispatch, getState) => {
  if (!dateRange.start || !dateRange.end) {
    dateRange.start = moment.tz('Europe/Paris')
    dateRange.end = moment.tz('Europe/Paris')
  }
  const start = dateRange.start.format('YYYY-MM-DD')
  const end = dateRange.end.format('YYYY-MM-DD')
  getComparisonDateRange(dateRange)

  dispatch(actions.isFetchingAllData(true))
  dispatch(
    actions.setDataFilters({
      dateRange,
      channelId
    })
  )
  Promise.all([
    dispatch(getTrainingSentenceNotMatchedFilter(start, end, language)),
    dispatch(fetchBotConfig(channelId)),
    dispatch(fetchBotIntent())
  ]).then(() => dispatch(actions.isFetchingAllData(false)))
}

const fetchTrainingArchivedData = (channelId, dateRange, language, intent) => (dispatch, getState) => {
  if (!dateRange.start || !dateRange.end) {
    dateRange.start = moment.tz('Europe/Paris')
    dateRange.end = moment.tz('Europe/Paris')
  }
  const start = dateRange.start.format('YYYY-MM-DD')
  const end = dateRange.end.format('YYYY-MM-DD')
  const comparisonDateRange = getComparisonDateRange(dateRange)
  dispatch(actions.isFetchingAllData(true))
  dispatch(
    actions.setDataFilters({
      dateRange,
      comparisonDateRange,
      channelId,
      intent
    })
  )
  Promise.all([
    dispatch(getTrainingSentenceStandByFilter(start, end, language, intent)),
    dispatch(fetchBotConfig(channelId)),
    dispatch(fetchBotIntent())
  ]).then(() => dispatch(actions.isFetchingAllData(false)))
}

const trainLine = (index, listName, id, channel, intent, language, dateRange, activeLang, activeIntent) => (
  dispatch,
  getState
) => {
  const botId = maybe.getOrElse('unknownBot', bot.selectors.getSelectedId(getState()))
  dispatch(actions.trainLineRequest(listName, index))
  const start = dateRange.start.format('YYYY-MM-DD')
  const end = dateRange.end.format('YYYY-MM-DD')
  const url = legacyApiUrl + '/admin/' + botId + '/train/' + intent
  return axios({
    url,
    method: 'post',
    responseType: 'json',
    data: {
      sentence: JSON.stringify([id]),
      language
    }
  })
    .then(response => {
      if (response.status === 200) {
        dispatch(actions.trainLineSuccess(listName, index))
        dispatch(getTrainingUtterances(listName)(start, end, activeLang, activeIntent))
      } else {
        dispatch(actions.trainLineFailure())
      }
    })
    .catch(err => {
      dispatch(actions.trainLineFailure(err))
    })
}

const trainSelected = (listName, ids, channel, intent, language, dateRange, activeLang, activeIntent) => (
  dispatch,
  getState
) => {
  const botId = maybe.getOrElse('unknownBot', bot.selectors.getSelectedId(getState()))
  dispatch(actions.trainSelectedRequest(listName))
  const start = dateRange.start.format('YYYY-MM-DD')
  const end = dateRange.end.format('YYYY-MM-DD')

  const newIds = ids.map(id => id.id)
  const url = legacyApiUrl + '/admin/' + botId + '/train/' + intent
  return axios({
    url,
    method: 'post',
    responseType: 'json',
    data: {
      sentence: JSON.stringify(newIds),
      language
    }
  })
    .then(response => {
      if (response.status === 200) {
        dispatch(actions.trainSelectedSuccess(listName))
        dispatch(getTrainingUtterances(listName)(start, end, activeLang, activeIntent))
      } else {
        dispatch(actions.trainSelectedFailure())
      }
    })
    .catch(err => {
      dispatch(actions.trainSelectedFailure(err))
    })
}

const archiveLine = (id, index, listName, channel, language, dateRange) => (dispatch, getState) => {
  const botId = maybe.getOrElse('unknownBot', bot.selectors.getSelectedId(getState()))
  dispatch(actions.archiveLineRequest(index, listName))
  const start = dateRange.start.format('YYYY-MM-DD')
  const end = dateRange.end.format('YYYY-MM-DD')

  const url = legacyApiUrl + '/admin/' + botId + '/train'
  return axios({
    url,
    data: {
      sentences: id,
      archived: true
    },
    method: 'put',
    responseType: 'json'
  })
    .then(response => {
      if (response.status === 200) {
        dispatch(actions.archiveLineSuccess(index, listName))
        dispatch(getTrainingUtterances(listName)(start, end, language))
      } else {
        dispatch(actions.archivedFailure())
      }
    })
    .catch(err => {
      dispatch(actions.archivedFailure(err))
    })
}

const deleteLine = (id, index, listName, channel, language, dateRange) => (dispatch, getState) => {
  const botId = maybe.getOrElse('unknownBot', bot.selectors.getSelectedId(getState()))
  dispatch(actions.deleteLineRequest(index, listName))
  const start = dateRange.start.format('YYYY-MM-DD')
  const end = dateRange.end.format('YYYY-MM-DD')

  const url = legacyApiUrl + '/admin/' + botId + '/train'
  return axios({
    url,
    data: {
      sentences: id,
      deleted: true
    },
    method: 'put',
    responseType: 'json'
  })
    .then(response => {
      if (response.status === 200) {
        dispatch(actions.deleteLineSuccess(index, listName))
        dispatch(getTrainingUtterances(listName)(start, end, language))
      } else {
        dispatch(actions.deletedFailure())
      }
    })
    .catch(err => {
      dispatch(actions.deletedFailure(err))
    })
}

const setDateRange = dateRange => dispatch => {
  localStorage.setItem('dateRange', JSON.stringify(dateRange))
  dispatch(actions.setDateRange(dateRange))
  dispatch(actions.setActivePeriod(2))
}

const setDateRange2 = dateRange => dispatch => {
  localStorage.setItem('dateRange', JSON.stringify(dateRange))
  dispatch(actions.setDateRange2(dateRange))
  dispatch(actions.setActivePeriod(2))
}

const {
  setActiveChannel,
  setActiveIntent,
  setActiveLanguage,
  setDateRangeDropDown,
  setActivePeriod,

  clearIntent,
  clearIntentLine,
  clearIntentSelected,

  toggleLine,
  toggleAll,

  assignSelected,
  assignLine,
  assignListName
} = actions

export {
  getTrainingSentenceFilter,
  getTrainingSentenceNotMatchedFilter,
  getTrainingSentenceStandByFilter,
  fetchTrainingNotMatchedData,
  fetchTrainingArchivedData,
  fetchTrainingData,
  fetchBotIntent,
  setDateRange,
  setDateRange2,
  setDateRangeDropDown,
  setActivePeriod,
  setActiveChannel,
  setActiveIntent,
  clearIntent,
  clearIntentLine,
  clearIntentSelected,
  trainLine,
  archiveLine,
  deleteLine,
  trainSelected,
  toggleLine,
  toggleAll,
  assignSelected,
  assignLine,
  assignListName,
  setActiveLanguage
}
