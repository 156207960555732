const FETCH_INTENTS_REQUEST = 'tcfdash/analytics/FETCH_INTENTS_REQUEST'
const FETCH_INTENTS_SUCCESS = 'tcfdash/analytics/FETCH_INTENTS_SUCCESS'
const FETCH_INTENTS_NO_DATA = 'tcfdash/analytics/FETCH_INTENTS_NO_DATA'

const FETCH_INTENTS_PREVIOUS_PERIOD_REQUEST = 'tcfdash/analytics/FETCH_INTENTS_PREVIOUS_PERIOD_REQUEST'
const FETCH_INTENTS_PREVIOUS_PERIOD_SUCCESS = 'tcfdash/analytics/FETCH_INTENTS_PREVIOUS_PERIOD_SUCCESS'
const FETCH_INTENTS_PREVIOUS_PERIOD_NO_DATA = 'tcfdash/analytics/FETCH_INTENTS_PREVIOUS_PERIOD_NO_DATA'

const FETCH_INTENTS_OVER_TIME_REQUEST = 'tcfdash/analytics/FETCH_INTENTS_OVER_TIME_REQUEST'
const FETCH_INTENTS_OVER_TIME_SUCCESS = 'tcfdash/analytics/FETCH_INTENTS_OVER_TIME_SUCCESS'
const FETCH_INTENTS_OVER_TIME_NO_DATA = 'tcfdash/analytics/FETCH_INTENTS_OVER_TIME_NO_DATA'

export default {
  FETCH_INTENTS_REQUEST,
  FETCH_INTENTS_SUCCESS,
  FETCH_INTENTS_NO_DATA,
  FETCH_INTENTS_PREVIOUS_PERIOD_REQUEST,
  FETCH_INTENTS_PREVIOUS_PERIOD_SUCCESS,
  FETCH_INTENTS_PREVIOUS_PERIOD_NO_DATA,
  FETCH_INTENTS_OVER_TIME_REQUEST,
  FETCH_INTENTS_OVER_TIME_SUCCESS,
  FETCH_INTENTS_OVER_TIME_NO_DATA
}
