import * as R from 'ramda'
import { maybe } from '@sbizeul/fp-flow'
import bot from 'modules/bot'
import {
  withoutUserIdDoublons,
  countByName,
  formatData,
  isEmpty,
  getLucieData,
  getDsAutoData,
  getAmpData,
  getBrealData,
  getDsAutoLeadGenData,
  getMuglerData,
  getFreeprintsData,
  getOpelData,
  getBrealTolkData,
  getDsTolkData,
} from './helpers'

const LUCIE_DEV_ID = '5bffee8507c16e75e4a51c01'
const LUCIE_PROD_ID = '5b430a8111a5136f6789196a'
const DS_AUTO_DEV_ID = '5bed3f7707c16e75e4a51bfa'
const DS_AUTO_PROD_ID = '5bed437ce6f4b847256c6d57'
const AMP_DEV_ID = '5bffcb1607c16e75e4a51bfe'
const AMP_PROD_ID = '5bffd1fbe6f4b847256c6d5d'
const BREAL_DEV_ID = '5bfd426507c16e75e4a51bfc'
const BREAL_PROD_ID = '5bfd59a3e6f4b847256c6d5b'
const DS_AUTO_LEAD_GEN_PROD_ID = '5d39ab344f65193bcb2279ee'
const MUGLER_PROD = '5c9b3fe7d0760c4dded64932'
const FREEPRINTS_PROD_ID = 'C1fe113cd1cb3Eb5A3d4fdf6'
const FREEPRINTS_DEV_ID = '28e6C1F3EaA4dbf19cF1cBDb'
const OPEL_PROD_ID = '5d19ca864f65193bcb2279ed'
const OPEL_PREPROD_ID = '5d0b69d8cbd87d203b9cdccb'
const OPEL_DEV_ID = '5d0b5ef7b00739025445f432'
const BREAL_TOLK_ID = 'DFDfEdeD3ed563ADAB14EfeA'
const DS_TOLK_ID = '5179FE835AF2A8F9CfFD94be'

const isIncludedIn = ids => botId => ids.includes(botId)

const isLucie = isIncludedIn([LUCIE_DEV_ID, LUCIE_PROD_ID])

const isDsAuto = isIncludedIn([DS_AUTO_DEV_ID, DS_AUTO_PROD_ID])

const isAmp = isIncludedIn([AMP_DEV_ID, AMP_PROD_ID])

const isBreal = isIncludedIn([BREAL_DEV_ID, BREAL_PROD_ID])

const isDsAutoLeadGen = isIncludedIn([DS_AUTO_LEAD_GEN_PROD_ID])

const isMugler = isIncludedIn([MUGLER_PROD])

const isFreeprints = isIncludedIn([FREEPRINTS_PROD_ID, FREEPRINTS_DEV_ID])

const isOpel = isIncludedIn([OPEL_PROD_ID, OPEL_PREPROD_ID, OPEL_DEV_ID])

const isBrealTolk = isIncludedIn([BREAL_TOLK_ID])

const isDsTolk = isIncludedIn([DS_TOLK_ID])

export const getFunnel = state => {
  const data = state.analytics.funnel.funnel.data
  const currentBotId = maybe.get(bot.selectors.getSelectedId(state))
  const originalData = R.cond([
    [isLucie, getLucieData],
    [isDsAuto, getDsAutoData],
    [isAmp, getAmpData],
    [isBreal, getBrealData],
    [isDsAutoLeadGen, getDsAutoLeadGenData],
    [isMugler, getMuglerData],
    [isFreeprints, getFreeprintsData],
    [isOpel, getOpelData],
    [isBrealTolk, getBrealTolkData],
    [isDsTolk, getDsTolkData],
    [R.T, () => []]
  ])(currentBotId)

  if (isEmpty(data)) {
    return []
  } else {
    const countedElements = R.pipe(withoutUserIdDoublons, countByName)(data)
    return formatData(originalData, countedElements)
  }
}
