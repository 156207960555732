export const BROADCAST_SELECT_CHANNEL = 'broadcast-manager/BROADCAST_SELECT_CHANNEL'
export const BROADCAST_LOAD_CAMPAIGNS_REQUEST = 'broadcast-manager/BROADCAST_LOAD_CAMPAIGNS_REQUEST'
export const BROADCAST_LOAD_CAMPAIGNS_SUCCESS = 'broadcast-manager/BROADCAST_LOAD_CAMPAIGNS_SUCCESS'
export const BROADCAST_LOAD_CAMPAIGNS_FAILURE = 'broadcast-manager/BROADCAST_LOAD_CAMPAIGNS_FAILURE'
export const BROADCAST_LOAD_COHORTS_REQUEST = 'broadcast-manager/BROADCAST_LOAD_COHORTS_REQUEST'
export const BROADCAST_LOAD_COHORTS_SUCCESS = 'broadcast-manager/BROADCAST_LOAD_COHORTS_SUCCESS'
export const BROADCAST_LOAD_COHORTS_FAILURE = 'broadcast-manager/BROADCAST_LOAD_COHORTS_FAILURE'
export const BROADCAST_CREATE_NEW_CAMPAIGN_REQUEST = 'broadcast-manager/BROADCAST_CREATE_NEW_CAMPAIGN_REQUEST'
export const BROADCAST_CREATE_NEW_CAMPAIGN_SUCCESS = 'broadcast-manager/BROADCAST_CREATE_NEW_CAMPAIGN_SUCCESS'
export const BROADCAST_CREATE_NEW_CAMPAIGN_FAILURE = 'broadcast-manager/BROADCAST_CREATE_NEW_CAMPAIGN_FAILURE'
export const BROADCAST_SELECT_CAMPAIGN = 'broadcast-manager/BROADCAST_SELECT_CAMPAIGN'
export const BROADCAST_SELECT_COHORT = 'broadcast-manager/BROADCAST_SELECT_COHORT'
export const BROADCAST_DELETE_CAMPAIGN_REQUEST = 'broadcast-manager/BROADCAST_DELETE_CAMPAIGN_REQUEST'
export const BROADCAST_DELETE_CAMPAIGN_SUCCESS = 'broadcast-manager/BROADCAST_DELETE_CAMPAIGN_SUCCESS'
export const BROADCAST_DELETE_CAMPAIGN_FAILURE = 'broadcast-manager/BROADCAST_DELETE_CAMPAIGN_FAILURE'
export const BROADCAST_SAVE_CAMPAIGN_REQUEST = 'broadcast-manager/BROADCAST_SAVE_CAMPAIGN_REQUEST'
export const BROADCAST_SAVE_CAMPAIGN_SUCCESS = 'broadcast-manager/BROADCAST_SAVE_CAMPAIGN_SUCCESS'
export const BROADCAST_SAVE_CAMPAIGN_FAILURE = 'broadcast-manager/BROADCAST_SAVE_CAMPAIGN_FAILURE'
export const BROADCAST_DUPLICATE_CAMPAIGN_REQUEST = 'broadcast-manager/BROADCAST_DUPLICATE_CAMPAIGN_REQUEST'
export const BROADCAST_DUPLICATE_CAMPAIGN_SUCCESS = 'broadcast-manager/BROADCAST_DUPLICATE_CAMPAIGN_SUCCESS'
export const BROADCAST_DUPLICATE_CAMPAIGN_FAILURE = 'broadcast-manager/BROADCAST_DUPLICATE_CAMPAIGN_FAILURE'
export const BROADCAST_SCHEDULE_CAMPAIGN_REQUEST = 'broadcast-manager/BROADCAST_SCHEDULE_CAMPAIGN_REQUEST'
export const BROADCAST_SCHEDULE_CAMPAIGN_SUCCESS = 'broadcast-manager/BROADCAST_SCHEDULE_CAMPAIGN_SUCCESS'
export const BROADCAST_SCHEDULE_CAMPAIGN_FAILURE = 'broadcast-manager/BROADCAST_SCHEDULE_CAMPAIGN_FAILURE'
export const BROADCAST_SEND_CAMPAIGN_REQUEST = 'broadcast-manager/BROADCAST_SEND_CAMPAIGN_REQUEST'
export const BROADCAST_SEND_CAMPAIGN_SUCCESS = 'broadcast-manager/BROADCAST_SEND_CAMPAIGN_SUCCESS'
export const BROADCAST_SEND_CAMPAIGN_FAILURE = 'broadcast-manager/BROADCAST_SEND_CAMPAIGN_FAILURE'
export const BROADCAST_UPDATE_CAMPAIGN_TITLE = 'broadcast-manager/BROADCAST_UPDATE_CAMPAIGN_TITLE'
export const BROADCAST_UPDATE_CAMPAIGN_DATE = 'broadcast-manager/BROADCAST_UPDATE_CAMPAIGN_DATE'
export const BROADCAST_TOGGLE_CAMPAIGN_SEND_NOW = 'broadcast-manager/BROADCAST_TOGGLE_CAMPAIGN_SEND_NOW'
export const BROADCAST_ADD_ELEMENT = 'broadcast-manager/BROADCAST_ADD_ELEMENT'
export const BROADCAST_DELETE_ELEMENT = 'broadcast-manager/BROADCAST_DELETE_ELEMENT'
export const BROADCAST_REORDER_ELEMENTS = 'broadcast-manager/BROADCAST_REORDER_ELEMENTS'
export const BROADCAST_EDIT_CAROUSEL_ELEMENT_ADD_CARD = 'broadcast-manager/BROADCAST_EDIT_CAROUSEL_ELEMENT_ADD_CARD'
export const BROADCAST_EDIT_CAROUSEL_ELEMENT_DELETE_CARD =
  'broadcast-manager/BROADCAST_EDIT_CAROUSEL_ELEMENT_DELETE_CARD'
export const BROADCAST_EDIT_CAROUSEL_ELEMENT_REORDER_CARDS =
  'broadcast-manager/BROADCAST_EDIT_CAROUSEL_ELEMENT_REORDER_CARDS'
export const BROADCAST_EDIT_CAROUSEL_ELEMENT_IMAGE = 'broadcast-manager/BROADCAST_EDIT_CAROUSEL_ELEMENT_IMAGE'
export const BROADCAST_EDIT_CAROUSEL_ELEMENT_TITLE = 'broadcast-manager/BROADCAST_EDIT_CAROUSEL_ELEMENT_TITLE'
export const BROADCAST_EDIT_CAROUSEL_ELEMENT_DESCRIPTION =
  'broadcast-manager/BROADCAST_EDIT_CAROUSEL_ELEMENT_DESCRIPTION'
export const BROADCAST_EDIT_CAROUSEL_ELEMENT_ADD_BUTTON = 'broadcast-manager/BROADCAST_EDIT_CAROUSEL_ELEMENT_ADD_BUTTON'
export const BROADCAST_EDIT_CAROUSEL_ELEMENT_BUTTON_TITLE =
  'broadcast-manager/BROADCAST_EDIT_CAROUSEL_ELEMENT_BUTTON_TITLE'
export const BROADCAST_EDIT_CAROUSEL_ELEMENT_BUTTON_VALUE =
  'broadcast-manager/BROADCAST_EDIT_CAROUSEL_ELEMENT_BUTTON_VALUE'
export const BROADCAST_EDIT_CAROUSEL_ELEMENT_DELETE_BUTTON =
  'broadcast-manager/BROADCAST_EDIT_CAROUSEL_ELEMENT_DELETE_BUTTON'
export const BROADCAST_EDIT_TEXT_ELEMENT = 'broadcast-manager/BROADCAST_EDIT_TEXT_ELEMENT'
export const BROADCAST_EDIT_IMAGE_ELEMENT = 'broadcast-manager/BROADCAST_EDIT_IMAGE_ELEMENT'
export const BROADCAST_EDIT_VIDEO_ELEMENT = 'broadcast-manager/BROADCAST_EDIT_VIDEO_ELEMENT'
export const BROADCAST_SAVE_CAROUSEL_ELEMENT_IMAGE_REQUEST =
  'broadcast-manager/BROADCAST_SAVE_CAROUSEL_ELEMENT_IMAGE_REQUEST'
export const BROADCAST_SAVE_CAROUSEL_ELEMENT_IMAGE_SUCCESS =
  'broadcast-manager/BROADCAST_SAVE_CAROUSEL_ELEMENT_IMAGE_SUCCESS'
export const BROADCAST_SAVE_CAROUSEL_ELEMENT_IMAGE_FAILURE =
  'broadcast-manager/BROADCAST_SAVE_CAROUSEL_ELEMENT_IMAGE_FAILURE'
export const BROADCAST_DELETE_CAROUSEL_ELEMENT_IMAGE_REQUEST =
  'broadcast-manager/BROADCAST_DELETE_CAROUSEL_ELEMENT_IMAGE_REQUEST'
export const BROADCAST_SAVE_IMAGE_ELEMENT_REQUEST = 'broadcast-manager/BROADCAST_SAVE_IMAGE_ELEMENT_REQUEST'
export const BROADCAST_SAVE_IMAGE_ELEMENT_SUCCESS = 'broadcast-manager/BROADCAST_SAVE_IMAGE_ELEMENT_SUCCESS'
export const BROADCAST_SAVE_IMAGE_ELEMENT_FAILURE = 'broadcast-manager/BROADCAST_SAVE_IMAGE_ELEMENT_FAILURE'
export const BROADCAST_DELETE_IMAGE_ELEMENT_REQUEST = 'broadcast-manager/BROADCAST_DELETE_IMAGE_ELEMENT_REQUEST'
export const BROADCAST_SAVE_VIDEO_ELEMENT_REQUEST = 'broadcast-manager/BROADCAST_SAVE_VIDEO_ELEMENT_REQUEST'
export const BROADCAST_SAVE_VIDEO_ELEMENT_SUCCESS = 'broadcast-manager/BROADCAST_SAVE_VIDEO_ELEMENT_SUCCESS'
export const BROADCAST_SAVE_VIDEO_ELEMENT_FAILURE = 'broadcast-manager/BROADCAST_SAVE_VIDEO_ELEMENT_FAILURE'
export const BROADCAST_DELETE_VIDEO_ELEMENT_REQUEST = 'broadcast-manager/BROADCAST_DELETE_VIDEO_ELEMENT_REQUEST'
export const BROADCAST_SEND_PREVIEW_REQUEST = 'broadcast-manager/BROADCAST_SEND_PREVIEW_REQUEST'
export const BROADCAST_SEND_PREVIEW_SUCCESS = 'broadcast-manager/BROADCAST_SEND_PREVIEW_SUCCESS'
export const BROADCAST_SEND_PREVIEW_FAILURE = 'broadcast-manager/BROADCAST_SEND_PREVIEW_FAILURE'
export const BROADCAST_SELECT_PREVIEW_USERS = 'broadcast-manager/BROADCAST_SELECT_PREVIEW_USERS'
export const BROADCAST_CANCEL_CAMPAIGN_DELETE_REQUEST = 'broadcast-manager/BROADCAST_CANCEL_CAMPAIGN_DELETE_REQUEST'
export const BROADCAST_CANCEL_CAMPAIGN_DELETE_SUCCESS = 'broadcast-manager/BROADCAST_CANCEL_CAMPAIGN_DELETE_SUCCESS'
export const BROADCAST_CANCEL_CAMPAIGN_DELETE_FAILURE = 'broadcast-manager/BROADCAST_CANCEL_CAMPAIGN_DELETE_FAILURE'
export const BROADCAST_SELECT_IS_RECURRING = 'broadcast-manager/BROADCAST_SELECT_IS_RECURRING'
export const BROADCAST_SELECT_RECURRENCE = 'broadcast-manager/BROADCAST_SELECT_RECURRENCE'
export const BROADCAST_FETCH_ANALYTICS_REQUEST = 'broadcast-manager/BROADCAST_FETCH_ANALYTICS_REQUEST'
export const BROADCAST_FETCH_ANALYTICS_SUCCESS = 'broadcast-manager/BROADCAST_FETCH_ANALYTICS_SUCCESS'
export const BROADCAST_FETCH_ANALYTICS_FAILURE = 'broadcast-manager/BROADCAST_FETCH_ANALYTICS_FAILURE'

export const selectPreviewUsers = previewUsers => ({
  type: BROADCAST_SELECT_PREVIEW_USERS,
  payload: previewUsers
})

export const selectChannel = channel => ({
  type: BROADCAST_SELECT_CHANNEL,
  payload: channel
})

export const addElement = element => ({
  type: BROADCAST_ADD_ELEMENT,
  payload: element
})

export const changeCampaignDate = date => ({
  type: BROADCAST_UPDATE_CAMPAIGN_DATE,
  payload: date
})

export const changeCampaignTitle = title => ({
  type: BROADCAST_UPDATE_CAMPAIGN_TITLE,
  payload: title
})

export const createNewCampaignRequest = () => ({
  type: BROADCAST_CREATE_NEW_CAMPAIGN_REQUEST
})

export const createNewCampaignSuccess = (id, date, timeZone, channel) => ({
  type: BROADCAST_CREATE_NEW_CAMPAIGN_SUCCESS,
  payload: {
    id,
    date,
    timeZone,
    channel
  }
})

export const createNewCampaignFailure = () => ({
  type: BROADCAST_CREATE_NEW_CAMPAIGN_FAILURE
})

export const deleteCampaignRequest = campaignId => ({
  type: BROADCAST_DELETE_CAMPAIGN_REQUEST,
  payload: campaignId
})

export const deleteCampaignSuccess = campaignId => ({
  type: BROADCAST_DELETE_CAMPAIGN_SUCCESS,
  payload: campaignId
})

export const cancelCampaignDeleteRequest = campaignId => ({
  type: BROADCAST_CANCEL_CAMPAIGN_DELETE_REQUEST,
  payload: campaignId
})

export const cancelCampaignDeleteSuccess = campaign => ({
  type: BROADCAST_CANCEL_CAMPAIGN_DELETE_SUCCESS,
  payload: campaign
})

export const cancelCampaignDeleteFailure = error => ({
  type: BROADCAST_CANCEL_CAMPAIGN_DELETE_FAILURE,
  payload: error
})

export const deleteCampaignFailure = () => ({
  type: BROADCAST_DELETE_CAMPAIGN_FAILURE
})

export const deleteCarouselElementImageRequest = (index, cardIndex) => ({
  type: BROADCAST_DELETE_CAROUSEL_ELEMENT_IMAGE_REQUEST,
  payload: {
    index,
    cardIndex
  }
})

export const deleteImageElementRequest = index => ({
  type: BROADCAST_DELETE_IMAGE_ELEMENT_REQUEST,
  payload: {
    index
  }
})

export const deleteVideoElementRequest = index => ({
  type: BROADCAST_DELETE_VIDEO_ELEMENT_REQUEST,
  payload: { index }
})

export const deleteElement = index => ({
  type: BROADCAST_DELETE_ELEMENT,
  payload: index
})

export const editCarouselElementAddCard = index => ({
  type: BROADCAST_EDIT_CAROUSEL_ELEMENT_ADD_CARD,
  payload: {
    index
  }
})

export const editCarouselElementDeleteCard = (index, cardIndex) => ({
  type: BROADCAST_EDIT_CAROUSEL_ELEMENT_DELETE_CARD,
  payload: {
    index,
    cardIndex
  }
})

export const editCarouselElementReorderCards = (index, oldIndex, newIndex) => ({
  type: BROADCAST_EDIT_CAROUSEL_ELEMENT_REORDER_CARDS,
  payload: {
    index,
    oldIndex,
    newIndex
  }
})

export const editCarouselElementImage = (index, cardIndex, temporaryUrl) => ({
  type: BROADCAST_EDIT_CAROUSEL_ELEMENT_IMAGE,
  payload: {
    index,
    cardIndex,
    temporaryUrl
  }
})

export const editCarouselElementTitle = (index, cardIndex, title) => ({
  type: BROADCAST_EDIT_CAROUSEL_ELEMENT_TITLE,
  payload: {
    index,
    cardIndex,
    title
  }
})

export const editCarouselElementDescription = (index, cardIndex, description) => ({
  type: BROADCAST_EDIT_CAROUSEL_ELEMENT_DESCRIPTION,
  payload: {
    index,
    cardIndex,
    description
  }
})

export const editCarouselElementAddButton = (index, cardIndex) => ({
  type: BROADCAST_EDIT_CAROUSEL_ELEMENT_ADD_BUTTON,
  payload: {
    index,
    cardIndex
  }
})

export const editCarouselElementDeleteButton = (index, cardIndex, buttonIndex) => ({
  type: BROADCAST_EDIT_CAROUSEL_ELEMENT_DELETE_BUTTON,
  payload: {
    index,
    cardIndex,
    buttonIndex
  }
})

export const editCarouselElementButtonTitle = (index, cardIndex, buttonIndex, title) => ({
  type: BROADCAST_EDIT_CAROUSEL_ELEMENT_BUTTON_TITLE,
  payload: {
    index,
    cardIndex,
    buttonIndex,
    title
  }
})

export const editCarouselElementButtonValue = (index, cardIndex, buttonIndex, value) => ({
  type: BROADCAST_EDIT_CAROUSEL_ELEMENT_BUTTON_VALUE,
  payload: {
    index,
    cardIndex,
    buttonIndex,
    value
  }
})

export const editTextElement = (index, text) => ({
  type: BROADCAST_EDIT_TEXT_ELEMENT,
  payload: {
    index,
    text
  }
})

export const editImageElement = (index, temporaryUrl) => ({
  type: BROADCAST_EDIT_IMAGE_ELEMENT,
  payload: {
    index,
    temporaryUrl
  }
})

export const editVideoElement = (index, temporaryUrl) => ({
  type: BROADCAST_EDIT_VIDEO_ELEMENT,
  payload: {
    index,
    temporaryUrl
  }
})

export const fetchAnalyticsSuccess = analytics => ({
  type: BROADCAST_FETCH_ANALYTICS_SUCCESS,
  payload: analytics
})

export const fetchAnalyticsFailure = error => ({
  type: BROADCAST_FETCH_ANALYTICS_FAILURE,
  payload: error
})

export const loadCampaignsRequest = () => ({
  type: BROADCAST_LOAD_CAMPAIGNS_REQUEST
})

export const loadCampaignsSuccess = campaigns => ({
  type: BROADCAST_LOAD_CAMPAIGNS_SUCCESS,
  payload: campaigns
})

export const loadCampaignsFailure = () => ({
  type: BROADCAST_LOAD_CAMPAIGNS_FAILURE
})

export const loadCohortsRequest = () => ({
  type: BROADCAST_LOAD_COHORTS_REQUEST
})

export const loadCohortsSuccess = cohorts => ({
  type: BROADCAST_LOAD_COHORTS_SUCCESS,
  payload: cohorts
})

export const loadCohortsFailure = () => ({
  type: BROADCAST_LOAD_COHORTS_FAILURE
})

export const reorderElements = (oldIndex, newIndex) => ({
  type: BROADCAST_REORDER_ELEMENTS,
  payload: { oldIndex, newIndex }
})

export const saveCampaignRequest = () => ({
  type: BROADCAST_SAVE_CAMPAIGN_REQUEST
})

export const saveCampaignSuccess = () => ({
  type: BROADCAST_SAVE_CAMPAIGN_SUCCESS
})

export const saveCampaignFailure = () => ({
  type: BROADCAST_SAVE_CAMPAIGN_FAILURE
})

export const duplicateCampaignRequest = () => ({
  type: BROADCAST_DUPLICATE_CAMPAIGN_REQUEST
})

export const duplicateCampaignSuccess = newCampaign => ({
  type: BROADCAST_DUPLICATE_CAMPAIGN_SUCCESS,
  payload: newCampaign
})

export const duplicateCampaignFailure = () => ({
  type: BROADCAST_DUPLICATE_CAMPAIGN_FAILURE
})

export const saveCarouselElementImageRequest = (index, cardIndex, file) => ({
  type: BROADCAST_SAVE_CAROUSEL_ELEMENT_IMAGE_REQUEST,
  payload: {
    index,
    cardIndex,
    file
  }
})

export const saveCarouselElementImageSuccess = (index, cardIndex, url) => ({
  type: BROADCAST_SAVE_CAROUSEL_ELEMENT_IMAGE_SUCCESS,
  payload: {
    index,
    cardIndex,
    url
  }
})

export const saveCarouselElementImageFailure = () => ({
  type: BROADCAST_SAVE_CAROUSEL_ELEMENT_IMAGE_FAILURE
})

export const saveImageElementRequest = (index, file) => ({
  type: BROADCAST_SAVE_IMAGE_ELEMENT_REQUEST,
  payload: {
    index,
    file
  }
})

export const saveImageElementSuccess = (index, url) => ({
  type: BROADCAST_SAVE_IMAGE_ELEMENT_SUCCESS,
  payload: {
    index,
    url
  }
})

export const saveImageElementFailure = () => ({
  type: BROADCAST_SAVE_IMAGE_ELEMENT_FAILURE
})

export const saveVideoElementRequest = (index, file) => ({
  type: BROADCAST_SAVE_VIDEO_ELEMENT_REQUEST,
  payload: {
    index,
    file
  }
})

export const saveVideoElementSuccess = (index, url, size) => ({
  type: BROADCAST_SAVE_VIDEO_ELEMENT_SUCCESS,
  payload: {
    index,
    url,
    size
  }
})

export const saveVideoElementFailure = () => ({
  type: BROADCAST_SAVE_VIDEO_ELEMENT_FAILURE
})

export const scheduleCampaignRequest = () => ({
  type: BROADCAST_SCHEDULE_CAMPAIGN_REQUEST
})

export const scheduleCampaignSuccess = () => ({
  type: BROADCAST_SCHEDULE_CAMPAIGN_SUCCESS
})

export const scheduleCampaignFailure = () => ({
  type: BROADCAST_SCHEDULE_CAMPAIGN_FAILURE
})

export const sendCampaignRequest = () => ({
  type: BROADCAST_SEND_CAMPAIGN_REQUEST
})

export const sendCampaignSuccess = campaign => ({
  type: BROADCAST_SEND_CAMPAIGN_SUCCESS,
  payload: campaign
})

export const sendCampaignFailure = () => ({
  type: BROADCAST_SEND_CAMPAIGN_FAILURE
})

export const selectCampaign = id => ({
  type: BROADCAST_SELECT_CAMPAIGN,
  payload: id
})

export const selectCohort = (cohort, cohorts) => ({
  type: BROADCAST_SELECT_COHORT,
  payload: {
    cohort,
    cohorts
  }
})

export const toggleCampaignSendNow = sendNow => ({
  type: BROADCAST_TOGGLE_CAMPAIGN_SEND_NOW,
  payload: sendNow
})

export const sendPreviewRequest = () => ({
  type: BROADCAST_SEND_PREVIEW_REQUEST
})

export const sendPreviewSuccess = () => ({
  type: BROADCAST_SEND_PREVIEW_SUCCESS
})

export const sendPreviewFailure = () => ({
  type: BROADCAST_SEND_PREVIEW_FAILURE
})

export const selectRecurring = recurring => ({
  type: BROADCAST_SELECT_IS_RECURRING,
  payload: recurring
})

export const selectRecurrence = recurrence => ({
  type: BROADCAST_SELECT_RECURRENCE,
  payload: recurrence
})
