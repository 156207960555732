const black = 'rgba(19,21,22,1)'
export const white = 'rgba(252,252,252,1)'
export const grey = 'rgba(235,234,239,1)'
export const darkGrey = 'rgba(200,200,200,1)'
export const blue = 'rgba(62,136,248,1.00)'
const boxShadow = '0px 0px 26px 0px rgba(220,220,220,0.5)'

const theme = {
  color: {
    black,
    blue,
    white,
    primary: black,
    grey,
    darkGrey,
    leftPanelBg: 'rgba(243,242,242,1.00)',
    mainContentBg: 'rgba(249,248,248,1.00)',
    defaultColor: 'rgba(116,122,141,1.00)',
    defaultColorFaded: 'rgba(116,122,141,0.17)',
    indicator: 'rgba(178,177,177,1.00)',
    carouselDot: 'rgba(203, 207, 220, 1.00)',
    carouselDotSelected: 'rgba(24, 42, 102, 1.00)',
    veryLightPink: '#ececec'
  },
  boxShadow: {
    default: boxShadow
  },
  zIndex: {},
  borderRadius: {
    outlinedUI: 10,
    titleInput: 10,
    elementImage: 10,
    carouselCard: 10,
    buttonEditContainer: 10,
    modale: 20
  },
  height: {
    buttonEditContainer: 150,
    elementImage: 130,
    carousel: 350,
    checkBox: 15,
    carouselAddCardButton: 50,
    cohortSummary: 30,
    modale: 450,
    modaleShort: 250,
    carouselDot: 10,
    toggleSwitch: 16,
    switch: 10,
    buttonCross: 3
  },
  width: {
    buttonEditContainer: 300,
    leftPanel: 300,
    outlinedUIGrouped: 100,
    selectArrow: 20,
    checkBox: 15,
    elementText: 60, // in ch
    elementImage: 225,
    carouselCard: 275,
    carouselAddCardButton: 50,
    modale: 500,
    carouselDot: 10,
    toggleSwitch: 30,
    switch: 10,
    buttonCross: 30
  },
  margin: {
    top: {
      leftPanelTitle: 20,
      outlinedUIChildren: 10,
      outlinedUI: 10,
      uIWithindicator: 10,
      section: 30,
      cohortSummary: 15,
      trashCentered: 0,
      trashNotCentered: 10
    },
    bottom: {
      leftPanelTitle: 10,
      leftPanelSubSection: 30,
      section: 15,
      sectionTitle: 20,
      sectionActionTitle: 20,
      elements: 10,
      cohort: 5
    },
    right: {
      checkBox: 10,
      slider: 10,
      toggleSwitch: 10
    },
    left: {
      leftPanelSubSection: 5,
      leftPanelTitleChildren: 15,
      outlinedUIChildren: 10,
      outlinedUI: 10,
      selectIcon: 30,
      indicator: 10,
      carouselCard: 10,
      cohortSummary: 15,
      trash: -20,
      toggleSwitch: 10
    }
  },
  padding: {
    leftPanel: 30,
    mainContent: 30,
    checkBox: 2,
    toggleSwitch: 2,
    XAxis: {
      outlinedUI: 10
    },
    YAxis: {
      outlinedUI: 8
    }
  },
  duration: {}
}

export default theme
