import React from 'react'

import matchingFlags from '../../../../utils/matchingFlags'

import './styles.css'

function ByLanguageCounter({ language, count }) {
  return (
    <div className='Counter'>
      <span className='Counter-flag'>{matchingFlags[language]}</span>
      <span className='Counter-label'>{count.toString()}</span>
    </div>
  )
}

export default ByLanguageCounter
