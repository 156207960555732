import axios from 'axios'
import actions from '../actions'
import { legacyApiUrl } from 'helpers/effects'

const fetchBotConfig = bot => async dispatch => {
  dispatch(actions.fetchBotConfigRequest())
  return axios({
    url: `${legacyApiUrl}/admin/${bot}/analytics/events`,
    method: 'get',
    responseType: 'json'
  })
    .then(response => {
      dispatch(actions.fetchBotConfigSuccess(response.data))
    })
    .catch(() => {
      dispatch(actions.fetchBotConfigFailure())
    })
}

export default fetchBotConfig
