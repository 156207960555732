import * as R from 'ramda'
import { Maybe, RemoteData } from '@sbizeul/fp-flow'

import { IsoLanguage } from 'helpers/language'
import { Map } from 'helpers/map'
import { Keyword } from 'modules/keyword/types'
import { Question } from 'modules/question/types'
import { Id, ServerFailure } from 'types'

export const idPath = R.lensProp('id')

export const targetPath = (index: number) => R.lensPath([index, 'target'])

export const textPath = R.lensPath(['objectAttachment', 'text', 0])

export const typePath = (index: number) => R.lensPath([index, 'type'])

export const imagePath = R.lensPath(['objectAttachment', 'imageUrl'])

export const titlePath = (index: number) => R.lensPath([index, 'title', 0])

export const urlPath = (index: number) => R.lensPath([index, 'url'])
export const externalQuestionIdPath = (index: number) => R.lensPath([index, 'external_question_id'])

export type SimpleAnswer = Readonly<{
  id: Id
  bot_id: Id
  type: 'Simple'
  content: ReadonlyArray<AnswerContent>
}>

export type SimpleAnswerButton = Readonly<{
  id: Id
  bot_id: Id
  type: 'Simple'
  content: ReadonlyArray<AnswerContentQuestion>
}>

export type ButtonWithAnswer = Readonly<{
  id: Id
  type: 'postback'
  title: string[]
  keyword?: Keyword
  payload: string
  answer?: SimpleAnswerButton
}>

export type QRAnswer = Readonly<{
  id: Id
  type: 'QR'
  bot_id: Id
  language: string
  missing_message: SimpleAnswer
  buttons: ButtonWithAnswer[]
}>

export type SaveOneParam = Readonly<{
  answerId: Maybe<Id>
  name?: string
  content: ReadonlyArray<AnswerContent>
}>

export type AnswerContent =
  | ButtonContent
  | CarouselContent
  | FormContent
  | GotoSkillContent
  | GotoSmartSkillContent
  | BaseSkillContent
  | RgpdContent
  | ActionContent
  | ImageContent
  | TagAgentContent
  | TextContent

  export type AnswerContentQuestion =
  | ButtonContent
  | CarouselContent
  | FormContent
  | GotoSkillContent
  | GotoSmartSkillContent
  | BaseSkillContent
  | ImageContent
  | TextContent
  | ActionContent

export type CarouselAttachment = Readonly<{
  id: Id
  image?: string
  title: string
  description?: string
  buttons: ReadonlyArray<ButtonAttachmentContent>
}>

export type CarouselContent = Readonly<{
  id: Id
  type: 'carousel'
  delay?: number
  language: IsoLanguage
  objectAttachment: ReadonlyArray<CarouselAttachment>
}>

type TextAttachment = Readonly<{
  text: ReadonlyArray<string>
}>

export type TextContent = Readonly<{
  id: Id
  type: 'text'
  delay?: number
  objectAttachment: TextAttachment
  language: string
}>

export type ButtonAttachmentQnAContent = Readonly<{
  type: 'question_link'
  external_question_id: Id
  title: ReadonlyArray<string>
}>

export type ButtonAttachmentUrlContent = Readonly<{
  type: 'web_url'
  url: string
  title: ReadonlyArray<string>
  target: 'blank' | 'self'
}>

export type ButtonAttachmentContent = ButtonAttachmentQnAContent | ButtonAttachmentUrlContent
export type ButtonsAttachmentContent = ReadonlyArray<ButtonAttachmentContent>

export type ButtonAttachment = Readonly<{
  text: ReadonlyArray<string>
  buttons: ReadonlyArray<ButtonAttachmentContent>
}>

export type ButtonContent = Readonly<{
  id: Id
  type: 'button'
  delay?: number
  objectAttachment: ButtonAttachment
  language: IsoLanguage
}>

type ImageAttachment = Readonly<{
  imageUrl: string
}>

export type ImageContent = Readonly<{
  id: Id
  type: 'image'
  delay?: number
  objectAttachment: ImageAttachment
  language: string
}>

export type SaveOneRequest = Readonly<{
  type: 'answer/SAVE_ONE_REQUEST',
  payload: SaveOneParam
}>

export type SaveOneSuccess = Readonly<{
  type: 'answer/SAVE_ONE_SUCCESS',
  payload: Id
}>

export type SaveOneFailure = Readonly<{
  type: 'answer/SAVE_ONE_FAILURE',
  payload: ServerFailure
}>

type GotoSkillAttachment = Readonly<{
  skill_id: Id
}>

type GotoSmartSkillAttachment = Readonly<{
  smartskill_id: Id
}>

type ActionAttachment = Readonly<{
  name: string
  scenario: string
  type?: 'packaged'
  app_id: string
}>

export type SkillContent = Readonly<{
  id: Id
}>

type FormAttachment = Readonly<{
  form_id: Id
}>

type BaseSkillAttachment = Readonly<{
  answer_id: Id
}>

type TagAgentAttachment = Readonly<{
  tag_id: Id,
  action: 'set'
}>

export type TagAgentContent = Readonly<{
  id: Id
  type: 'tagagent'
  objectAttachment: TagAgentAttachment
}>

export type FormContent = SkillContent & Readonly<{
  type: 'form'
  objectAttachment: FormAttachment
}>

export type GotoSkillContent = SkillContent & Readonly<{
  type: 'skill'
  objectAttachment: GotoSkillAttachment
}>

export type GotoSmartSkillContent = SkillContent & Readonly<{
  type: 'smartskill'
  objectAttachment: GotoSmartSkillAttachment
}>

export type BaseSkillContent = SkillContent & Readonly<{
  type: 'answer'
  objectAttachment: BaseSkillAttachment
}>

export type RgpdContent = SkillContent & Readonly<{
  type: 'rgpd'
}>

export type ActionContent = SkillContent & Readonly<{
  type: 'app',
  objectAttachment: ActionAttachment
}>

export type SimplifiedSkill = Readonly<{
  id: Id
  name: string
  type: 'form' | 'advanced' | 'base' | 'rgpd' | 'app' | 'smartskill'
}>

export type SimplifiedTag = Readonly<{
  id: Id
  name: string
}>

export type RemoveContentParam = Readonly<{ id: Id }>

export type FetchOne = {
  type: 'answer/FETCH_ONE_REQUEST'
  payload: Id
}

export type Answer = SimpleAnswer | QRAnswer

export type FetchOneSuccess = {
  type: 'answer/FETCH_ONE_SUCCESS'
  payload: Maybe<Answer>
}

export type FetchOneFailure = {
  type: 'answer/FETCH_ONE_FAILURE'
  payload: {
    error: ServerFailure
    id: Id
  }
}

export type AnswerContents = ReadonlyArray<Omit<AnswerContent, 'id'>>

export type NewContent = Readonly<{
  type: 'answer/NEW_CONTENT'
  payload: AnswerContents
}>

export type CreateTextContent = Readonly<{
  type: 'answer/CREATE_TEXT_CONTENT'
  payload: TextContent
}>

export type CreateTagAgentContent = Readonly<{
  type: 'answer/CREATE_TAG_CONTENT'
  payload: TagAgentContent
}>

export type CreateImageContent = Readonly<{
  type: 'answer/CREATE_IMAGE_CONTENT'
  payload: ImageContent
}>

export type CreateSkillContent = Readonly<{
  type: 'answer/CREATE_SKILL_CONTENT'
  payload: FormContent
}>

export type GoToSkills = Readonly<{
  type: 'answer/GO_TO_SKILLS'
}>

export type CreateButtonContent = {
  type: 'answer/CREATE_BUTTON_CONTENT'
  payload: ButtonContent
}

export type CreateQRAnswerParam = Omit<QRAnswer, 'id' | 'bot_id'>

export type CreateQRAnswer = Readonly<{
  type: 'answer/CREATE_QR_ANSWER'
  payload: CreateQRAnswerParam
}>

export type UpdateContentParam = Readonly<{ content: AnswerContent }>

export type UpdateQRAnswerParam = Readonly<{ qrAnswer: QRAnswer }>

export type UpdateContent = {
  type: 'answer/UPDATE_CONTENT'
  payload: Maybe<Answer>
}

export type UpdateQRAnswer = Readonly<{
  type: 'answer/UPDATE_QR_ANSWER'
  payload: UpdateQRAnswerParam
}>

export type ChangeFeedbackStatusParam = Readonly<{ id: Id }>

export type RemoveContent = {
  type: 'answer/REMOVE_CONTENT'
  payload: RemoveContentParam
}

export type UploadImageRequestPayload = Readonly<{
  id: Id
  file: File
}>

export type UploadImageRequest = Readonly<{
  type: 'answer/UPLOAD_IMAGE_REQUEST'
  payload: UploadImageRequestPayload
}>

export type UploadImageSuccessPayload = Readonly<{
  id: Id
  url: string
}>

export type UploadImageSuccess = Readonly<{
  type: 'answer/UPLOAD_IMAGE_SUCCESS'
  payload: UploadImageSuccessPayload
}>

export type UploadImageFailurePayload = Readonly<{
  id: Id
  error: ServerFailure
}>

export type UploadImageFailure = Readonly<{
  type: 'answer/UPLOAD_IMAGE_FAILURE'
  payload: UploadImageFailurePayload
}>

export type ChangeFeedbackStatus = {
  type: 'answer/CHANGE_FEEDBACK_STATUS'
  payload: boolean
}

export type FetchAllSimplifiedTags = Readonly<{
  type: 'answer/FETCH_ALL_SIMPLIFIED_TAGS_REQUEST'
}>

export type FetchAllSimplifiedTagsFailure = Readonly<{
  type: 'answer/FETCH_ALL_SIMPLIFIED_TAGS_FAILURE'
  payload: ServerFailure
}>

export type FetchAllSimplifiedTagsSuccess = Readonly<{
  type: 'answer/FETCH_ALL_SIMPLIFIED_TAGS_SUCCESS'
  payload: ReadonlyArray<SimplifiedTag>
}>

export type FetchAllSimplifiedSkills = Readonly<{
  type: 'answer/FETCH_ALL_SIMPLIFIED_SKILLS_REQUEST'
}>

export type FetchAllSimplifiedSkillsFailure = Readonly<{
  type: 'answer/FETCH_ALL_SIMPLIFIED_SKILLS_FAILURE'
  payload: ServerFailure
}>

export type FetchAllSimplifiedSkillsSuccess = Readonly<{
  type: 'answer/FETCH_ALL_SIMPLIFIED_SKILLS_SUCCESS'
  payload: ReadonlyArray<SimplifiedSkill>
}>

export type AnswerUiAction =
  | CreateButtonContent
  | CreateTextContent
  | CreateTagAgentContent
  | CreateImageContent
  | CreateSkillContent
  | CreateQRAnswer
  | RemoveContent
  | UpdateContent
  | UploadImageRequest
  | UploadImageSuccess
  | UploadImageFailure
  | ChangeFeedbackStatus
  | SaveOneRequest
  | SaveOneSuccess
  | SaveOneFailure
  | UpdateQRAnswer
  | NewContent
  | FetchAllSimplifiedSkills
  | FetchAllSimplifiedSkillsFailure
  | FetchAllSimplifiedSkillsSuccess
  | FetchAllSimplifiedTags
  | FetchAllSimplifiedTagsFailure
  | FetchAllSimplifiedTagsSuccess

export type AnswerEntityAction = FetchOne | FetchOneSuccess | FetchOneFailure

export type AnswerAction = AnswerUiAction | AnswerEntityAction

export type AnswerEntityState = Readonly<{
  byId: Record<string, RemoteData<ServerFailure, Answer>>
}>

export type AnswerUiState = Readonly<{
  answer: Maybe<Answer>
  isValid: boolean
  isModified: boolean
  imagesById: Record<string, RemoteData<ServerFailure, string>>
  simplifiedSkills: RemoteData<ServerFailure, ReadonlyArray<SimplifiedSkill>>
  simplifiedTags: RemoteData<ServerFailure, ReadonlyArray<SimplifiedTag>>
}>

export type AppState = Readonly<{
  ui: {
    answer: AnswerUiState
  },
  entity: {
    answer: AnswerEntityState
  }
}>

export type SimpleDispatchFromProps = Readonly<{
  fetchAllSkills: () => unknown
  fetchAllTags: () => unknown
  fetchQuestions: () => unknown
  goToSkills: () => unknown
  uploadImage: (params: UploadImageRequestPayload) => unknown
  fetchAllKeywords: () => unknown
}>

export type DispatchFromProps = SimpleDispatchFromProps &
  Readonly<{
    onAnswerUpdated: (answer: Maybe<Answer>) => unknown
  }>

export type StateFromProps = Readonly<{
  answer: Maybe<Answer>
  canCreateQR?: boolean
  children?: React.ReactNode
  imagesUrl: Map<RemoteData<ServerFailure, string>>
  keywords: RemoteData<ServerFailure, ReadonlyArray<Keyword>>
  skills: RemoteData<ServerFailure, SimplifiedSkill[]>
  tags: RemoteData<ServerFailure, SimplifiedTag[]>
  languages: IsoLanguage[]
  questions: RemoteData<ServerFailure, ReadonlyArray<Question>>
}>
