import axios from 'axios'
import isResValid from './isResValid'
import { legacyApiUrl } from 'helpers/effects'

const get = async (path, params = null) => {
  const response = await axios({
    url: `${legacyApiUrl}/${path}`,
    params,
    method: 'get',
    responseType: 'json'
  })
  if (isResValid(response)) {
    return response.data.result
  }
  throw new Error(`There was a problem fetching ${path}`)
}

export default get
