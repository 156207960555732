import types from './types'

const initialState = {
  fetching: false,
  fetchingCountPreviousPeriod: false,
  fetchingCountByHour: false,
  fetchingAverageByDay: false
}

const messagesReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.FETCH_MESSAGES_REQUEST:
    return {
      ...state,
      fetching: true,
      data: null,
      count: null
    }
  case types.FETCH_MESSAGES_NO_DATA:
    return {
      ...state,
      fetching: false
    }
  case types.FETCH_MESSAGES_SUCCESS:
    return {
      ...state,
      fetching: false,
      data: action.payload.data,
      count: action.payload.count
    }

  case types.FETCH_MESSAGES_PREVIOUS_PERIOD_REQUEST:
    return {
      ...state,
      fetchingCountPreviousPeriod: true,
      countPreviousPeriod: null
    }
  case types.FETCH_MESSAGES_PREVIOUS_PERIOD_NO_DATA:
    return {
      ...state,
      fetchingCountPreviousPeriod: false
    }
  case types.FETCH_MESSAGES_PREVIOUS_PERIOD_SUCCESS:
    return {
      ...state,
      fetchingCountPreviousPeriod: false,
      countPreviousPeriod: action.payload.count
    }

  case types.FETCH_MESSAGES_COUNT_BY_HOUR_REQUEST:
    return {
      ...state,
      fetchingCountCountByHour: true,
      countByHour: null,
      countByDay: null,
      countByHourByDay: null
    }
  case types.FETCH_MESSAGES_COUNT_BY_HOUR_NO_DATA:
    return {
      ...state,
      fetchingCountByHour: false
    }
  case types.FETCH_MESSAGES_COUNT_BY_HOUR_SUCCESS:
    return {
      ...state,
      fetchingCountByHour: false,
      countByHour: action.payload.messagesCountByHour,
      countByDay: action.payload.messagesCountByDay,
      countByHourByDay: action.payload.messagesCountByHourByDay
    }

  case types.FETCH_MESSAGES_AVERAGE_BY_DAY_REQUEST:
    return {
      ...state,
      fetchingAverageByDay: true,
      averageByDay: null
    }
  case types.FETCH_MESSAGES_AVERAGE_BY_DAY_NO_DATA:
    return {
      ...state,
      fetchingAverageByDay: false
    }
  case types.FETCH_MESSAGES_AVERAGE_BY_DAY_SUCCESS:
    return {
      ...state,
      fetchingAverageByDay: false,
      averageByDay: action.payload.messagesAverageByDay
    }

  default:
    return state
  }
}

export default messagesReducer
