import React from 'react'
import classNames from 'classnames'

import './Checkbox.css'

export default function Checkbox(props) {
  const handleChange = evt => {
    if (props.onChange) {
      props.onChange(evt)
    }
    if (props.onChangeBoolean) {
      props.onChangeBoolean(evt.target.checked)
    }
  }

  return (
    <div className={classNames('Checkbox', props.className)}>
      <input
        type='checkbox'
        checked={props.checked}
        onChange={handleChange}
        name={props.name}
        className='Checkbox-input'
      />
      <label className='Checkbox-label'>{props.label}</label>
    </div>
  )
}
