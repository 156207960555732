import { fork } from 'redux-saga/effects'

import admin from 'modules/admin'
import analytics from 'modules/analytics'
import answer from 'modules/answer'
import answer_old from 'modules/answer_old'
import auth from 'modules/auth'
import bot from 'modules/bot'
import broadcast from 'modules/broadcast'
import channel from 'modules/channel'
import channels from 'modules/channels'
import apps from 'modules/apps'
import conversation from 'modules/conversation'
import entity from 'modules/entity'
import intent from 'modules/intent'
import keyword from 'modules/keyword'
import log from 'modules/log'
import monitor from 'modules/monitor'
import qa from 'modules/qa'
import question from 'modules/question'
import register from 'modules/register'
import router from 'modules/router'
import skill from 'modules/skill'
import synonym from 'modules/synonym'
import synonym_old from 'modules/synonym_old'
import train from 'modules/train'
import tq from 'modules/trainingQuestion'
import user from 'modules/user'

export default function* root() {
  yield fork(admin.saga.root)
  yield fork(analytics.saga.root)
  yield fork(answer.saga.root)
  yield fork(answer_old.saga.root)
  yield fork(auth.saga.root)
  yield fork(bot.saga.root)
  yield fork(broadcast.saga.root)
  yield fork(channel.saga.root)
  yield fork(channels.saga.root)
  yield fork(apps.saga.root)
  yield fork(conversation.saga.root)
  yield fork(entity.saga.root)
  yield fork(intent.saga.root)
  yield fork(keyword.saga.root)
  yield fork(log.saga.root)
  yield fork(monitor.saga.root)
  yield fork(qa.saga.root)
  yield fork(question.saga.root)
  yield fork(register.saga.root)
  yield fork(router.saga.root)
  yield fork(skill.saga.root)
  yield fork(synonym.saga.root)
  yield fork(synonym_old.saga.root)
  yield fork(train.saga.root)
  yield fork(tq.saga.root)
  yield fork(user.saga.root)
}
