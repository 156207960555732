const FETCH_PAYLOADS_REQUEST = 'tcfdash/analytics/FETCH_PAYLOADS_REQUEST'
const FETCH_PAYLOADS_SUCCESS = 'tcfdash/analytics/FETCH_PAYLOADS_SUCCESS'
const FETCH_PAYLOADS_NO_DATA = 'tcfdash/analytics/FETCH_PAYLOADS_NO_DATA'

const FETCH_PAYLOADS_OVER_TIME_REQUEST = 'tcfdash/analytics/FETCH_PAYLOADS_OVER_TIME_REQUEST'
const FETCH_PAYLOADS_OVER_TIME_SUCCESS = 'tcfdash/analytics/FETCH_PAYLOADS_OVER_TIME_SUCCESS'
const FETCH_PAYLOADS_OVER_TIME_NO_DATA = 'tcfdash/analytics/FETCH_PAYLOADS_OVER_TIME_NO_DATA'

export default {
  FETCH_PAYLOADS_REQUEST,
  FETCH_PAYLOADS_SUCCESS,
  FETCH_PAYLOADS_NO_DATA,
  FETCH_PAYLOADS_OVER_TIME_REQUEST,
  FETCH_PAYLOADS_OVER_TIME_SUCCESS,
  FETCH_PAYLOADS_OVER_TIME_NO_DATA
}
