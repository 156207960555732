import React from 'react'
import * as R from 'ramda'
import { v4 as uuid } from 'uuid'

import './styles.css'

export type Props = Readonly<{
  img: string
  text: string
  title: string
  backLineCount?: number
  children?: React.ReactNode
}>

const backLines: (count?: number) => number[] = (count = 9) => Array(count).fill(0)

const emptyElement = () => (
  <div key={uuid()} className='emptyElement-container'>
    <div className='emptyElement-first emptyContent' />
    <div className='emptyElement-second emptyContent' />
  </div>
)

const EmptyCard = ({ ...props }: Props) =>
  <div className='emptyCard-container'>
    <img className='emptyCard-icon' src={props.img} alt='Empty Card icon'/>
    <h1>{props.title}</h1>
    <p className='emptyCard-text'>{props.text}</p>
    <div className='emptyCard-children-container'>{props.children}</div>
  </div>

const EmptyStateContent = ({ ...props }: Props) =>
  <div className='EmptyStateContent-container'>
    <div className='EmptyStateContent-backpage'>
      {R.map(_ => emptyElement(), backLines(props.backLineCount))}
    </div>
    {<EmptyCard {...props} />}
  </div>

export default EmptyStateContent
