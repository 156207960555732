import React from 'react'
import classNames from 'classnames'
import { FaTimes } from 'react-icons/fa'

import './styles.css'

export default function Tag(props) {
  return (
    <div className={classNames('Tag', props.className)}>
      <FaTimes onClick={props.onRemove} className='Tag-iconDelete' />
      {props.text}
    </div>
  )
}
