import React, { Component } from 'react'
import { connect } from 'react-redux'
import operations from '../../../../../../redux/analytics/messages/operations'
import getSafe from '../../../../../utils/getSafe'
import Day from './Day'

class DayContainer extends Component {
  render() {
    return (
        <Day {...this.props} />
    )
  }
}

const mapStateToProps = state => ({
  messagesByHour: getSafe(() => state.analytics.messages.countByHour),
  usersByHour: getSafe(() => state.analytics.users.byHour),
})

export default connect(mapStateToProps, operations)(DayContainer)
