import React, { SyntheticEvent, useState } from 'react'
import { maybe, RemoteData, remoteData } from '@sbizeul/fp-flow'
import { FaBookmark, FaTrashAlt, FaCaretDown } from 'react-icons/fa'
import { MdDone } from 'react-icons/md'
import * as R from 'ramda'
import { Dropdown } from 'semantic-ui-react'

import { Question } from 'modules/question/types'
import { ServerFailure } from 'types'

import {
  OUT_OF_SCOPE,
  setValue,
  setConfirmed,
  setDeleted,
  toDropdownFormat
} from '../../models'
import { AssignedQuestion, UnmatchedQuestion, DropdownOptions, DropdownOption } from '../../types'

import './styles.css'

const confirmedAssignQuestions: (
  confirmedValueFromQuestion: (question: AssignedQuestion) => boolean
) => (assignedQuestions: AssignedQuestion[]) => (index: number) => AssignedQuestion[] =
  confirmedValueFromQuestion => assignedQuestions => index => R.map(
    question => R.propEq('dropdownIndex', index)(question)
      ? R.assoc('confirmed', confirmedValueFromQuestion(question), question)
      : question,
    assignedQuestions
  )

const removeQuestionWithIndex: (dropdownIndex: number) => (assignedQuestions: AssignedQuestion[]) => AssignedQuestion[] =
  dropdownIndex => assignedQuestions => {
    const index = R.findIndex(R.propEq('dropdownIndex', dropdownIndex), assignedQuestions)
    return R.remove(index, 1, assignedQuestions)
  }

const toggleQuestions = confirmedAssignQuestions(R.pipe(R.prop('confirmed'), R.not))

type Props = {
  assignedQuestions: AssignedQuestion[]
  unmatchedQuestion: UnmatchedQuestion
  index: number
  questions: RemoteData<ServerFailure, Question[]>

  onQuestionSelected: (assignedQuestions: AssignedQuestion[]) => unknown
}

const DELETE_TEXT = '-'
const DELETE_VALUE = ''

const isDeleteValue: (value: string) => boolean = value => value === DELETE_VALUE

const UnmatchedLog: React.FunctionComponent<Props> = ({ assignedQuestions, questions, unmatchedQuestion, index, onQuestionSelected }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [dropdownPreview, setDropdownPreview] = useState('*')

  const getQuestionByDropdownIndex = (dropdownIndex: number) =>
    maybe.fromNullable(assignedQuestions.find(R.propEq('dropdownIndex', dropdownIndex)))

  const assignValue = (question: AssignedQuestion) => {
    const foundQuestion = getQuestionByDropdownIndex(question.dropdownIndex)
    const selectedQuestions: AssignedQuestion[] = maybe.fold(
      () => question.confirmed ? R.append(question, assignedQuestions) : assignedQuestions,
      _ => question.confirmed
        ? updateAssignedQuestion(question)
        : removeQuestionWithIndex(question.dropdownIndex)(assignedQuestions)
    )(foundQuestion)
    onQuestionSelected(selectedQuestions)
  }

  const updateAssignedQuestion: (assignedQuestion: AssignedQuestion) => AssignedQuestion[] =
    ({ dropdownIndex, value, confirmed, deleted }: AssignedQuestion) => R.map(
      R.unless(
        R.pipe(R.propEq('dropdownIndex', dropdownIndex), R.not),
        R.pipe(setValue(value), setConfirmed(confirmed), setDeleted(deleted))
      ), assignedQuestions)

  const onChangeProcess = (
    _: SyntheticEvent<HTMLElement>,
    questionId: string,
    logId: string,
    dropdownIndex: number
  ) => {
    assignValue({ logId, dropdownIndex, value: questionId, confirmed: !isDeleteValue(questionId), deleted: false })
  }

  const highLight = assignedQuestions.find(
    R.allPass([R.propEq('dropdownIndex', index), R.prop('confirmed')])
  )
    ? 'highLight'
    : ''

  const sortByText = R.sortBy(R.pipe(R.prop('text'), R.toLower))
  const getDropdownItems = R.pipe(
    remoteData.map(toDropdownFormat),
    remoteData.getOrElse(() => [])
  )
  const selectQuestionByIntent = R.curry((dropdownoptions: DropdownOptions, intent: string) => R.filter(
    (dropdownoption: DropdownOption) => R.equals(intent, R.prop('value',dropdownoption)),
    dropdownoptions))

  const sortByIntentList = R.curry((unmatchedQuestion, dropdownOptions: DropdownOptions) => R.concat(
    // @ts-ignore Fix issue with @sbizeul/fp-flow
    R.flatten(R.map(selectQuestionByIntent(dropdownOptions)
      , unmatchedQuestion.intentList
    )),
    R.filter((dropdownoption: DropdownOption) =>
        R.not(R.includes(R.prop('value',dropdownoption),R.prop('intentList',unmatchedQuestion)))
      , dropdownOptions)
  ))

  const dropdownList = R.pipe(getDropdownItems, sortByText, sortByIntentList(unmatchedQuestion))(questions)
  const outOfScopeOption = () => ({
    text: OUT_OF_SCOPE,
    value: OUT_OF_SCOPE,
    content: (
      <div className='unmatched-dropdown-header'>
        <FaBookmark/>
        <p className='unmatched-dropdown-header-p'>Out of Scope</p>
      </div>
    )
  })
  const deleteValueOption = () => ({
    text: DELETE_TEXT,
    value: DELETE_VALUE
  })
  // @ts-ignore Fix issue with @sbizeul/fp-flow
  const dropdownOptionsList = () => R.concat([deleteValueOption(), outOfScopeOption()], dropdownList)
  // @ts-ignore Fix issue with @sbizeul/fp-flow
  const selectedValue = R.filter(option => option.value === dropdownPreview, dropdownOptionsList())

  const handleDelete = () => {
    assignValue({ logId: unmatchedQuestion.id, dropdownIndex: index, value: unmatchedQuestion.id, confirmed: true, deleted: true })
  }

  const renderLogIndex = () => <p className='unmatched-question-index'>{index + 1}</p>

  const renderDeleteButton = () => <FaTrashAlt
    className='unmatched-question-index unmatched-question--delete'
    data-testid='delete'
    onClick={handleDelete}
  />

  return (
    <div key={unmatchedQuestion.id} className='unmatched-question'>
      {renderLogIndex()}
      {renderDeleteButton()}
      <p className='unmatched-question-text'>{unmatchedQuestion.textIn}</p>
      <div className='unmatched-dropdown'>
        {!isOpen &&
        <div className='unmatched-dropdown-preview' onClick={() => setIsOpen(true)}>
          {// @ts-ignore Fix issue with @sbizeul/fp-flow
          R.isEmpty(selectedValue) ? '' : selectedValue[0].text}
          <FaCaretDown className='unmatched-dropdown-preview-icon' size='15' />
        </div>}
        {isOpen && <Dropdown
          selection
          search
          clearable
          className='icon'
          defaultOpen
          defaultValue={[]}
          // @ts-ignore Fix issue with @sbizeul/fp-flow
          options={dropdownOptionsList()}
          onClose={() => setIsOpen(false)}
          onChange={(event, { value }) => [setDropdownPreview(value as string), onChangeProcess(event, value as string, unmatchedQuestion.id, index)]}
        />}
      </div>
      <div
        className={`unmatched-question-done ${highLight}`}
        onClick={() => {
          onQuestionSelected(toggleQuestions(assignedQuestions)(index))
        }}
      >
        <MdDone className='unmatched-question-congrats-li-done'/>
      </div>
    </div>
  )
}

export default UnmatchedLog
