import React from 'react'
import * as R from 'ramda'
import { remoteData, either } from '@sbizeul/fp-flow'

import { LabeledQuestions } from 'modules/question/types'
import { renderNothing, renderLoader, renderServerFailure, useEffectOnce } from 'helpers/react'
import { restoreScroll, withScrollManager } from 'helpers/scroll-manager'

import { QaQuestionPageProps } from '../../types'
import { labeledQuestionsGroupedByQuestions } from './models'
import TopBar from '../TopBar'
import QaQuestions from './QaQuestions'

import './styles.css'

export const QaQuestionPage = (props: QaQuestionPageProps) => {
  const {
    labeledQuestions,
    importFile,
    isImporting,
    isCreatingSection,
    fetchLabeledQuestions,
    createQuestion,
    createSection,
    isPostingQuestion
  } = props

  const onInputKeyEnter = (text: string, event: React.KeyboardEvent) =>
    createQuestion({ question: text, shouldStayOnPage: event.ctrlKey })

  const renderContent = (questions: LabeledQuestions) => {
   return  (
    <QaQuestions {...props} questionsBySection={labeledQuestionsGroupedByQuestions(questions)} />
  )
  }

  useEffectOnce(() => {
    fetchLabeledQuestions()
  })

  return (
    <div className='QaQuestionPage pageContainer'>
      <TopBar
        isImporting={isImporting}
        isPostingQuestion={isPostingQuestion}
        isCreatingSection={isCreatingSection}
        onInputKeyEnter={onInputKeyEnter}
        importFile={importFile}
        createSection={createSection}
      />
      {either.fold(renderServerFailure, renderNothing, isPostingQuestion)}
      {remoteData.fold(
        {
          Success: R.compose(restoreScroll(props), renderContent),
          Failure: renderServerFailure,
          Loading: () => renderLoader('Fetching questions...'),
          NotAsked: renderNothing
        },
        labeledQuestions
      )}
    </div>
  )
}

export default withScrollManager(QaQuestionPage)
