import types from './types'

/* Get Bot Config */
const fetchBotConfigRequest = () => ({
  type: types.FETCH_BOTCONFIG_REQUEST
})
const fetchBotConfigSuccess = payload => ({
  type: types.FETCH_BOTCONFIG_SUCCESS,
  payload
})
const fetchBotConfigFailure = errors => ({
  type: types.FETCH_BOTCONFIG_NO_DATA,
  errors
})

/* Get Custom Events Data */
const fetchCustomEventsRequest = () => ({
  type: types.FETCH_CUSTOM_EVENTS_REQUEST
})
const fetchCustomEventsSuccess = payload => ({
  type: types.FETCH_CUSTOM_EVENTS_SUCCESS,
  payload
})
const fetchCustomEventsFailure = errors => ({
  type: types.FETCH_CUSTOM_EVENTS_NO_DATA,
  errors
})

/* postEventChart */
const postEventChartRequest = () => ({
  type: types.POST_EVENT_CHART_REQUEST
})
const postEventChartSuccess = () => ({
  type: types.POST_EVENT_CHART_SUCCESS
})
const postEventChartFailure = () => ({
  type: types.POST_EVENT_CHART_FAILURE
})

export default {
  fetchBotConfigRequest,
  fetchBotConfigSuccess,
  fetchBotConfigFailure,
  fetchCustomEventsRequest,
  fetchCustomEventsSuccess,
  fetchCustomEventsFailure,
  postEventChartRequest,
  postEventChartSuccess,
  postEventChartFailure
}
