import { remoteData } from '@sbizeul/fp-flow'
import { lensProp, over, pipe, set, lensPath, compose } from 'ramda'

import { toMapById } from 'helpers/array'

import * as actions from './actions'

export const initialState = {
  all: remoteData.notAsked(),
  byId: {}
}

const all = lensProp('all')
const byId = lensPath(['byId'])

const keywordsRequest = state => over(all, remoteData.loading, state)

const keywordsSuccess = (state, action) => set(all, pipe(toMapById, remoteData.success)(action.payload), state)

const keywordsFailure = (state, action) => set(all, remoteData.failure(action.payload), state)

const oneRequest = (state, action) => {
  const path = compose(byId, lensPath([action.payload]))
  return over(path, remoteData.loading, state)
}

const oneSuccess = (state, action) => {
  const path = compose(byId, lensPath([action.payload.id]))
  return set(path, remoteData.success(action.payload), state)
}

const oneFailure = (state, action) => {
  const path = compose(byId, lensPath([action.payload.keywordId]))
  return set(path, remoteData.failure(action.payload.error), state)
}

const updateKeywordRequest = (state, action) => {
  const path = compose(byId, lensPath([action.payload.id]))
  return over(path, remoteData.loading, state)
}

const updateKeywordSuccess = (state, action) => {
  const path = compose(byId, lensPath([action.payload.id]))
  return set(path, remoteData.success(action.payload), state)
}

const updateKeywordFailure = (state, action) => {
  const { keywordId, error } = action.payload
  const path = compose(byId, lensPath([keywordId]))
  return set(path, remoteData.failure(error), state)
}

export default function(state = initialState, action) {
  switch (action.type) {
  case actions.FETCH_KEYWORDS_REQUEST:
    return keywordsRequest(state)
  case actions.FETCH_KEYWORDS_SUCCESS:
    return keywordsSuccess(state, action)
  case actions.FETCH_KEYWORDS_FAILURE:
    return keywordsFailure(state, action)
  case actions.FETCH_ONE_REQUEST:
    return oneRequest(state, action)
  case actions.FETCH_ONE_SUCCESS:
    return oneSuccess(state, action)
  case actions.FETCH_ONE_FAILURE:
    return oneFailure(state, action)
  case actions.UPDATE_REQUEST:
    return updateKeywordRequest(state, action)
  case actions.UPDATE_SUCCESS:
    return updateKeywordSuccess(state, action)
  case actions.UPDATE_FAILURE:
    return updateKeywordFailure(state, action)
  default:
    return state
  }
}
