import React from 'react'
import { Table } from 'semantic-ui-react'
import NoDataSegment from '../NoDataSegment'

export default function CustomTable({ data, header, noDataDisplay }) {
  if (noDataDisplay) {
    return <NoDataSegment />
  }

  const style = {
    borderRadius: '3px',
    border: 'none',
    boxShadow: '0 2px 4px rgba(50, 50, 93,.1)'
  }

  const forbiddenSentences = [
    '{"resultHost":false,"type":"location"}',
    '{"resultHost":false,"type":"image"}',
    '{"resultHost":false,"type":"audio"}'
  ]

  const isContentMustBeFiltered = data => data && data.length && 'sentence' in data[0]

  function renderContent() {
    return data.map((elem, index) => (
      <Table.Row key={index}>
        {header.map((key, index) => (
          <Table.Cell key={index}>{elem[key]}</Table.Cell>
        ))}
      </Table.Row>
    ))
  }

  function renderContentFiltered() {
    return data
      .filter(content => !forbiddenSentences.includes(content['sentence']))
      .map((elem, index) => (
        <Table.Row key={index}>
          {header.map((key, index) => (
            <Table.Cell key={index}>{elem[key]}</Table.Cell>
          ))}
        </Table.Row>
      ))
  }

  function tableTitles(key) {
    if (key === 'name' || key === 'sentence') {
      return 'Item'
    } else if (key === 'nb_users' || key === 'total_users') {
      return 'Number of users'
    } else if (key === 'value' || key === 'total') {
      return 'Occurrences'
    } else {
      return key
    }
  }

  return (
    <Table celled style={style}>
      <Table.Header>
        <Table.Row>
          {header.map(key => (
            <Table.HeaderCell key={key}>{tableTitles(key)}</Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>
      <Table.Body>{isContentMustBeFiltered(data) ? renderContentFiltered() : renderContent()}</Table.Body>
    </Table>
  )
}
