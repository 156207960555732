import * as actions from './actions'
import reducer from './reducer'
import * as saga from './saga'
import * as selectors from './selectors'
import AnswerButton from './components/AnswerButton'
import SimpleText from './components/SimpleText'
import QuickReply from './components/QuickReply'
import Answers from './components/Answers'
import CustomAnswerContainer from './containers/AnswersContainer'
import AnswerType from './components/AnswerType'
import AnswerTypeContainer from './containers/AnswerTypeContainer'

export default {
  actions,
  reducer,
  saga,
  selectors,
  components: {
    AnswerButton,
    SimpleText,
    QuickReply,
    AnswerType,
    Answers,
    AnswerTypeContainer,
    CustomAnswerContainer
  }
}
