/*
MIME types
*/
// source: https://htmlstrip.com/mime-file-type-checker
/*
image MIME types
image/x-jg, image/bmp, image/x-windows-bmp, image/fif, image/vnd.fpx, image/vnd.net-fpx, image/g3fax, image/gif, image/x-icon, image/ief, image/jpeg, image/pjpeg, image/x-jps, image/jutvision, image/vasa, image/naplps, image/x-niff, image/x-pordiv-bitmap, image/x-pict, image/x-pcx, image/x-pordiv-graymap, image/x-pordiv-greymap, image/pict, image/x-pordiv-anymap, image/x-pordiv-pixmap, image/x-quicktime, image/x-cmu-raster, image/cmu-raster, image/vnd.rn-realflash, image/x-rgb, image/vnd.rn-realpix, image/vnd.dwg, image/x-dwg, image/tiff, image/x-tiff, image/florian, image/vnd.wap.wbmp, image/x-xbitmap, image/x-xbm, image/xbm, image/vnd.xiff, image/x-xpixmap, image/xpm, image/png, image/x-xwd, image/x-xwindowdump
*/

/*
Video MIME types
video/animaflex, video/x-ms-asf, video/x-ms-asf-plugin, video/avi, video/msvideo, video/x-msvideo, video/avs-video, video/dl, video/x-dl, video/x-dv, video/fli, video/x-fli, video/x-atomic3d-feature, video/gl, video/x-gl, video/x-isvideo, video/x-motion-jpeg, video/x-mpeq2a, video/x-mpeg, video/mpeg, video/x-sgi-movie, video/quicktime, video/x-qtc, video/vnd.rn-realvideo, video/x-scm, video/vdo, video/vivo, video/vnd.vivo, video/vosaic, video/x-amt-demorun, video/x-amt-showrun, video/mp4, video/h264
*/

/*
Settings
*/
const baseSettings = {
  image: {
    description: {
      maxLength: 120
    },
    media: {
      maxSize: 1000 * 1000, // bytes
      allowedMIMETypes: 'image/jpeg, image/pjpeg',
      allowedFormats: 'JPG, JPEG'
    },
    thumbnail: {
      maxSize: 100 * 1000,
      allowedMIMETypes: 'image/jpeg, image/pjpeg',
      allowedFormats: 'JPG, JPEG'
    }
  },
  video: {
    media: {
      maxSize: 50 * 1000 * 1000, // bytes
      allowedMIMETypes: 'video/mp4, video/h264',
      allowedFormats: 'MP4, H264',
      maxDuration: 180 // seconds
    }
  },
  carousel: {
    buttons: {
      max: 4,
      maxLength: 50
    },
    cards: {
      max: 6
    },
    title: {
      maxLength: 50
    },
    description: {
      maxLength: 150
    }
  }
}

const viberSettings = baseSettings

const messengerSettings = {
  ...baseSettings,
  carousel: {
    ...baseSettings.carousel,
    buttons: {
      max: 3,
      maxLength: 50
    }
  }
}

const settings = {
  Viber: viberSettings,
  Messenger: messengerSettings,
  default: baseSettings
}

const allCohorts = 'All'

export { settings, allCohorts }
