import { Map, fromJS } from 'immutable'
import types from './types'

const initialState = Map(
  fromJS({
    closeModals: false,
    isPosting: false,
    isDeleting: false,
    isEditing: false,
    isPuting: false,
    fetchingData: false,
    ressource: {}
  })
)

const ressourcesReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.POST_RESSOURCE_REQUEST:
    return state.set('isPosting', true)
  case types.POST_RESSOURCE_FAILURE:
    return state.set('isPosting', false)
  case types.POST_RESSOURCE_SUCCESS:
    return state.set('isPosting', false)

  case types.GET_RESSOURCE_REQUEST:
    return state.set('fetchingData', true)
  case types.GET_RESSOURCE_FAILURE:
    return state.set('fetchingData', false).set('ressource', Map({}))
  case types.GET_RESSOURCE_SUCCESS:
    return state.set('fetchingData', false).set('ressource', fromJS(action.payload))

  case types.DELETE_RESSOURCE_REQUEST:
    return state.set('isDeleting', true)
  case types.DELETE_RESSOURCE_FAILURE:
    return state.set('isDeleting', false)
  case types.DELETE_RESSOURCE_SUCCESS:
    return state.set('isDeleting', false)

  case types.PUT_RESSOURCE_REQUEST:
    return state.set('isEditing', true)
  case types.PUT_RESSOURCE_FAILURE:
    return state.set('isEditing', false)
  case types.PUT_RESSOURCE_SUCCESS:
    return state.set('isEditing', false)

  case types.CLOSE_MODALS:
    return state.set('closeModals', action.payload)

  default:
    return state
  }
}

export default ressourcesReducer
