import React from 'react'

import Select from '../../../../components/Select'
import { isoToCountry } from '../../../../helpers/language'

const LanguageDropDown = ({
  setActiveLanguage,
  languages,
  activeLanguage,
  fetch,
  activeBot,
  activeChannelId,
  dateRangeStart,
  dateRangeEnd,
  activeIntent
}) => {
  const handleChange = ({ text: name, value: id }) => {
    setActiveLanguage(id, name)
    fetch(activeChannelId, { start: dateRangeStart, end: dateRangeEnd }, id)
  }

  return (
    <Select
      placeholder='Select Language'
      options={languages.map(lang => ({ value: lang, text: isoToCountry(lang) }))}
      value={activeLanguage}
      onChange={handleChange}
    />
  )
}

export default LanguageDropDown
