import { Map, fromJS } from 'immutable'

const initialState = Map(
  fromJS({
    intents: {
      isFetching: false,
      data: []
    },
    intent: {
      isFetching: false,
      data: {},
      diversity: {
        data: [],
        isFetching: false
      }
    },
    users: {
      isFetching: false,
      data: []
    },
    diversity: {
      data: [],
      isFetching: false
    },
    languages: []
  })
)

export default initialState
