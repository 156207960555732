import { pipe } from '@sbizeul/fp-flow'

import { withReduxProvider, withConnectedRouter } from './enhancers'
import { render } from '../../helpers/react'
import auth from '../auth'

import configureStore from '../../config/configureStore'
import history from '../../config/history'

const store = configureStore()

export const bootstrap = elementId =>
  pipe(auth.enhancers.withAuth, withConnectedRouter(history), withReduxProvider(store), render(elementId))
