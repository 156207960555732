import 'react-dates/initialize'
import React from 'react'
import { connect } from 'react-redux'
import { List } from 'immutable'
import { maybe } from '@sbizeul/fp-flow'

import * as operations from '../../../../redux/training/operations'

import DateRangePicker from './DateRangePicker'
import LanguageDropDown from './LangSelector'
import IntentDropDown from './IntentSelector'
import { renderNothing } from '../../../../helpers/react'
import channel from '../../../../modules/channel'
import bot from '../../../../modules/bot'
import './index.css'

const TrainingFilters = ({
  fetch,
  setDateRange,
  setDateRange2,
  setDateRangeDropDown,
  setActiveChannel,
  setActiveIntent,
  clearIntent,
  setActiveLanguage,
  bot,
  channels,
  activeChannelId,
  dateRange,
  dateRangeStart,
  dateRangeEnd,
  languages,
  activeLanguage,
  filters,
  activePeriod,
  intentList,
  activeIntent,
  showIntent,
  showChannels = true
}) => {
  const handleSelectChannel = ({ value, text }) => {
    setActiveChannel(value, text)
    fetch(value, { start: dateRangeStart, end: dateRangeEnd }, activeLanguage, activeIntent)
  }

  const renderIntentDropDown = (bot, key) => (
    <div key={key} className='TrainingFilters-filter'>
      <IntentDropDown
        intents={intentList ? intentList.toJS() : []}
        activeIntent={activeIntent}
        setActiveIntent={setActiveIntent}
        clearIntent={clearIntent}
        fetch={fetch}
        activeBot={bot}
        dateRangeStart={dateRangeStart}
        dateRangeEnd={dateRangeEnd}
        activeChannelId={activeChannelId}
        language={activeLanguage}
      />
    </div>
  )

  const renderLanguageDropDown = (bot, key) => (
    <div key={key} className='TrainingFilters-filter'>
      <LanguageDropDown
        languages={languages}
        activeLanguage={activeLanguage}
        setActiveLanguage={setActiveLanguage}
        fetch={fetch}
        activeBot={bot}
        dateRangeStart={dateRangeStart}
        dateRangeEnd={dateRangeEnd}
        activeChannelId={activeChannelId}
        activeIntent={activeIntent}
      />
    </div>
  )

  const renderChannelDropDown = (bot, key) => (
    <div key={key} className='TrainingFilters-filter'>
      <channel.components.ChannelSelect
        channels={channels}
        selectedId={activeChannelId}
        onSelect={handleSelectChannel}
      />
    </div>
  )

  const renderDropDowns = bot => {
    return [
      showIntent && renderIntentDropDown(bot, 0),
      languages && languages.length > 1 && renderLanguageDropDown(bot, 1),
      showChannels && channels && channels.length > 1 && renderChannelDropDown(bot, 2)
    ]
  }

  return (
    <div className='TrainingFilters'>
      <div className='TrainingFilters-filter'>
        <DateRangePicker
          dateRange={filters.dateRange}
          setDateRange={setDateRange2}
          fetch={fetch}
          style={{ height: 38 }}
        />
      </div>
      {maybe.fold(renderNothing, renderDropDowns, bot)}
    </div>
  )
}

const mapStateToProps = state => ({
  bot: bot.selectors.getSelected(state),
  activeChannelId: state.main.filters.channel.id,
  filters: state.training.getIn(['filters']),
  activePeriod: ['minutes', 'hours', 'days'][state.main.filters.periods.active],
  channels: channel.selectors.getBySelectedBotToOption(state),
  dateRangeStart: state.training.getIn(['filters', 'dateRange', 'start']),
  dateRangeEnd: state.training.getIn(['filters', 'dateRange', 'end']),
  languages: bot.selectors.getLanguages(state),
  activeLanguage: state.training.getIn(['filters', 'language', 'id']),
  activeIntent: state.training.getIn(['filters', 'intent', 'id']),
  intentList: state.training.getIn(['intentList', 'data'], List()),
  dateRange: state.training.getIn(['filters', 'dateRange'])
})

const connectedFiltersComp = connect(mapStateToProps, operations)(TrainingFilters)

export default connectedFiltersComp
