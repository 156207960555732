import * as R from 'ramda'
import { remoteData, maybe, either } from '@sbizeul/fp-flow'

import * as questionActions from './actions'
import * as array from '../../helpers/array'

export const initialState = {
  all: remoteData.notAsked(),
  byId: {},
  selectedId: maybe.nothing(),
  isPosting: either.of(false)
}

const all = R.lensProp('all')
const byId = R.lensPath(['byId'])
const selectedId = R.lensProp('selectedId')
const isPosting = R.lensProp('isPosting')

const fetchAllRequest = state => R.over(all, remoteData.loading, state)

const fetchAllSuccess = (state, { payload }) => R.set(all, remoteData.success(array.toMapById(payload)), state)

const fetchAllFailure = (state, { payload }) => R.set(all, remoteData.failure(payload), state)

const fetchOneRequest = (state, { payload: id }) => {
  const path = R.compose(byId, R.lensPath([id]))
  return R.over(path, remoteData.loading, state)
}

const fetchOneSuccess = (state, { payload }) => {
  const path = R.compose(byId, R.lensPath([payload.id]))
  return R.set(path, remoteData.success(payload), state)
}

const fetchOneFailure = (state, { payload }) => {
  const path = R.compose(byId, R.lensPath([payload.id]))
  return R.set(path, remoteData.failure(payload.error), state)
}

const createRequest = state => R.set(isPosting, either.of(R.T()), state)

const createSuccess = state => R.set(isPosting, either.of(R.F()), state)

const createFailure = (state, { payload }) => R.set(isPosting, either.left(payload), state)

const selectOne = (state, { payload }) => {
  return R.set(selectedId, maybe.just(payload), state)
}

export default function(state = initialState, action) {
  switch (action.type) {
  case questionActions.FETCH_ALL_REQUEST:
    return fetchAllRequest(state)
  case questionActions.FETCH_ALL_SUCCESS:
    return fetchAllSuccess(state, action)
  case questionActions.FETCH_ALL_FAILURE:
    return fetchAllFailure(state, action)
  case questionActions.FETCH_ONE_REQUEST:
    return fetchOneRequest(state, action)
  case questionActions.FETCH_ONE_SUCCESS:
    return fetchOneSuccess(state, action)
  case questionActions.FETCH_ONE_FAILURE:
    return fetchOneFailure(state, action)
  case questionActions.CREATE_REQUEST:
  case questionActions.IMPORT_REQUEST:
    return createRequest(state)
  case questionActions.CREATE_SUCCESS:
  case questionActions.IMPORT_SUCCESS:
    return createSuccess(state)
  case questionActions.CREATE_FAILURE:
  case questionActions.IMPORT_FAILURE:
    return createFailure(state, action)
  case questionActions.SELECT:
    return selectOne(state, action)
  default:
    return state
  }
}
