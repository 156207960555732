import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { Menu } from 'semantic-ui-react'

import * as routes from '../../../layouts/Routes'

const TrainTabs = ({ location: { pathname } }) => (
  <div>
    <Menu pointing secondary position='center' style={{ fontFamily: 'Raleway', marginBottom: '21px' }}>
      <Menu.Item
        name='Not Matched'
        active={pathname === routes.TRAINING_NOT_MATCHED}
        as={Link}
        to={{ pathname: routes.TRAINING_NOT_MATCHED }}
      />
      <Menu.Item
        name='Matched'
        active={pathname === routes.TRAINING_MATCHED}
        as={Link}
        to={{ pathname: routes.TRAINING_MATCHED }}
      />
      <Menu.Item
        name='Archived'
        active={pathname === routes.TRAINING_ARCHIVED}
        as={Link}
        to={{ pathname: routes.TRAINING_ARCHIVED }}
      />
    </Menu>
  </div>
)

export default withRouter(TrainTabs)
