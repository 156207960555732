import React from 'react'
import { Route, Switch } from 'react-router-dom'

import MainIntent from './Main'
import EditIntentIntent from './Edit'
import EditRecastIntentIntent from './EditRecast'
import NewIntent from './New'

export default function IntentPage() {
  return (
    <div className='IntentPage pageContainer'>
      <Switch>
        <Route exact path='/intents' component={MainIntent} />
        <Route exact path='/intents/new' component={NewIntent} />
        <Route exact path='/intents/edit/:intent' component={EditIntentIntent} />
        <Route exact path='/intents/editr/:intent' component={EditRecastIntentIntent} />
      </Switch>
    </div>
  )
}
