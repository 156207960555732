import React from 'react'
import PropTypes from 'prop-types'

const CarouselIcon = ({ width, height, fill }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 53 53'>
      <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Push-Copy-2' transform='translate(-1533.000000, -623.000000)' fill={fill} fillRule='nonzero'>
          <g id='tablet' transform='translate(1533.000000, 623.000000)'>
            <path
              d='M45.446773,0 L6.68603952,0 C2.99937085,0 0,2.99937085 0,6.68603952 L0,45.446773 C0,49.1334417 2.99937085,52.1328125 6.68603952,52.1328125 L45.446773,52.1328125 C49.1334417,52.1328125 52.1328125,49.1334417 52.1328125,45.446773 L52.1328125,6.68603952 C52.1328125,2.99937085 49.1334417,0 45.446773,0 Z M6.68603952,3.05465698 L45.446773,3.05465698 C47.4490051,3.05465698 49.0781555,4.68380737 49.0781555,6.68603952 L49.0781555,42.3945024 L3.05465698,42.3945024 L3.05465698,6.68603952 C3.05465698,4.68380737 4.68380737,3.05465698 6.68603952,3.05465698 Z M45.446773,49.0781555 L6.68603952,49.0781555 C4.68460281,49.0781555 3.05585023,47.4505961 3.05465698,45.4491594 L49.0781555,45.4491594 C49.0769623,47.4505961 47.4482097,49.0781555 45.446773,49.0781555 Z'
              id='Shape'
            ></path>
            <path
              d='M28.7467882,8.15012714 L23.3860243,8.15012714 C22.5424139,8.15012714 21.8586958,7.46640897 21.8586958,6.62279865 C21.8586958,5.77918822 22.5424139,5.09547016 23.3860243,5.09547016 L28.7467882,5.09547016 C29.5903986,5.09547016 30.2741167,5.77918822 30.2741167,6.62279865 C30.2741167,7.46640897 29.5903986,8.15012714 28.7467882,8.15012714 Z'
              id='Shape'
            ></path>
            <path
              d='M15.1865773,24.4849849 L9.93320363,24.4849849 C8.23086875,24.4849849 6.84593177,23.100048 6.84593177,21.3977131 L6.84593177,16.1443395 C6.84593177,14.4420046 8.23086875,13.0570676 9.93320363,13.0570676 L15.1865773,13.0570676 C16.8889121,13.0570676 18.273849,14.4420046 18.273849,16.1443395 L18.273849,21.3977131 C18.273849,23.100048 16.8889121,24.4849849 15.1865773,24.4849849 Z M9.93320363,16.1117246 C9.91530517,16.1117246 9.90058875,16.126441 9.90058875,16.1443395 L9.90058875,21.3977131 C9.90058875,21.4156115 9.91530517,21.4299302 9.93320363,21.4299302 L15.1865773,21.4299302 C15.2044756,21.4299302 15.219192,21.4156115 15.219192,21.3977131 L15.219192,16.1443395 C15.219192,16.126441 15.2044756,16.1117246 15.1865773,16.1117246 L9.93320363,16.1117246 Z'
              id='Shape'
            ></path>
            <path
              d='M28.6493415,24.4849849 L23.3959679,24.4849849 C21.693633,24.4849849 20.308696,23.100048 20.308696,21.3977131 L20.308696,16.1443395 C20.308696,14.4420046 21.693633,13.0570676 23.3959679,13.0570676 L28.6493415,13.0570676 C30.3516764,13.0570676 31.7366133,14.4420046 31.7366133,16.1443395 L31.7366133,21.3977131 C31.7366133,23.100048 30.3516764,24.4849849 28.6493415,24.4849849 Z M23.3959679,16.1117246 C23.3780694,16.1117246 23.363353,16.126441 23.363353,16.1443395 L23.363353,21.3977131 C23.363353,21.4156115 23.3780694,21.4299302 23.3959679,21.4299302 L28.6493415,21.4299302 C28.6672399,21.4299302 28.6819563,21.4156115 28.6819563,21.3977131 L28.6819563,16.1443395 C28.6819563,16.126441 28.6672399,16.1117246 28.6493415,16.1117246 L23.3959679,16.1117246 Z'
              id='Shape'
            ></path>
            <path
              d='M42.0317619,24.4849849 L36.7783883,24.4849849 C35.0760534,24.4849849 33.6911164,23.100048 33.6911164,21.3977131 L33.6911164,16.1443395 C33.6911164,14.4420046 35.0760534,13.0570676 36.7783883,13.0570676 L42.0317619,13.0570676 C43.7340968,13.0570676 45.1186359,14.4420046 45.1186359,16.1443395 L45.1186359,21.3977131 C45.1190337,23.100048 43.7340968,24.4849849 42.0317619,24.4849849 Z M36.7783883,16.1117246 C36.7604899,16.1117246 36.7457734,16.126441 36.7457734,16.1443395 L36.7457734,21.3977131 C36.7457734,21.4156115 36.7604899,21.4299302 36.7783883,21.4299302 L42.0317619,21.4299302 C42.0496602,21.4299302 42.0643768,21.4156115 42.0643768,21.3977131 L42.0643768,16.1443395 C42.0643768,16.126441 42.0496602,16.1117246 42.0317619,16.1117246 L36.7783883,16.1117246 Z'
              id='Shape'
            ></path>
            <path
              d='M15.2267491,37.9051907 L9.97337552,37.9051907 C8.27104064,37.9051907 6.88610366,36.5202539 6.88610366,34.817919 L6.88610366,29.5645454 C6.88610366,27.8622105 8.27104064,26.4772735 9.97337552,26.4772735 L15.2267491,26.4772735 C16.929084,26.4772735 18.3140209,27.8622105 18.3140209,29.5645454 L18.3140209,34.817919 C18.3140209,36.5202539 16.929084,37.9051907 15.2267491,37.9051907 Z M9.97337552,29.5319305 C9.95547717,29.5319305 9.94076065,29.546647 9.94076065,29.5645454 L9.94076065,34.817919 C9.94076065,34.8358173 9.95547717,34.8505338 9.97337552,34.8505338 L15.2267491,34.8505338 C15.2446475,34.8505338 15.2593639,34.8358173 15.2593639,34.817919 L15.2593639,29.5645454 C15.2593639,29.546647 15.2446475,29.5319305 15.2267491,29.5319305 L9.97337552,29.5319305 Z'
              id='Shape'
            ></path>
            <path
              d='M28.6895134,37.9051907 L23.4361398,37.9051907 C21.7338049,37.9051907 20.3488679,36.5202539 20.3488679,34.817919 L20.3488679,29.5645454 C20.3488679,27.8622105 21.7338049,26.4772735 23.4361398,26.4772735 L28.6895134,26.4772735 C30.3918483,26.4772735 31.7767852,27.8622105 31.7767852,29.5645454 L31.7767852,34.817919 C31.7767852,36.5202539 30.3918483,37.9051907 28.6895134,37.9051907 Z M23.4361398,29.5319305 C23.4182414,29.5319305 23.4035249,29.546647 23.4035249,29.5645454 L23.4035249,34.817919 C23.4035249,34.8358173 23.4182414,34.8505338 23.4361398,34.8505338 L28.6895134,34.8505338 C28.7074118,34.8505338 28.7221282,34.8358173 28.7221282,34.817919 L28.7221282,29.5645454 C28.7221282,29.546647 28.7074118,29.5319305 28.6895134,29.5319305 L23.4361398,29.5319305 Z'
              id='Shape'
            ></path>
            <path
              d='M42.0719338,37.9051907 L36.8185602,37.9051907 C35.1162253,37.9051907 33.7312884,36.5202539 33.7312884,34.817919 L33.7312884,29.5645454 C33.7312884,27.8622105 35.1162253,26.4772735 36.8185602,26.4772735 L42.0719338,26.4772735 C43.7742687,26.4772735 45.1592056,27.8622105 45.1592056,29.5645454 L45.1592056,34.817919 C45.1592056,36.5202539 43.7742687,37.9051907 42.0719338,37.9051907 Z M36.8185602,29.5319305 C36.8006618,29.5319305 36.7859454,29.546647 36.7859454,29.5645454 L36.7859454,34.817919 C36.7859454,34.8358173 36.8006618,34.8505338 36.8185602,34.8505338 L42.0719338,34.8505338 C42.0898322,34.8505338 42.1045487,34.8358173 42.1045487,34.817919 L42.1045487,29.5645454 C42.1045487,29.546647 42.0898322,29.5319305 42.0719338,29.5319305 L36.8185602,29.5319305 Z'
              id='Shape'
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

CarouselIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string
}

CarouselIcon.defaultProps = {
  width: '1.5em',
  height: '1.5em',
  fill: 'currentColor'
}

export default CarouselIcon
