import moment from 'moment'
import momentz from 'moment-timezone'
import types from './types'
import { stripDateDetails } from './utils'

const isFetchingAllData = payload => ({
  type: types.SET_FETCHING_ALL_DATA,
  payload
})

/* fetchUsers */
const fetchUsersRequest = () => ({
  type: types.FETCH_USERS_REQUEST
})

const setActiveChannel = (id, name) => ({
  type: types.SET_ACTIVE_CHANNEL,
  payload: {
    id,
    name
  }
})

const setActiveIntent = (id, name) => ({
  type: types.SET_ACTIVE_INTENT,
  payload: {
    id,
    name
  }
})

const setActiveLanguage = (id, name) => ({
  type: types.SET_ACTIVE_LANGUAGE,
  payload: {
    id,
    name
  }
})

const clearIntent = () => {
  return {
    type: types.CLEAR_INTENT
  }
}

/* setDataFilters */
const setDataFilters = payload => ({
  type: types.SET_DATA_FILTERS,
  payload
})

/* Get Bot Config */
const fetchBotConfigRequest = () => ({
  type: types.FETCH_BOT_CONFIG_REQUEST
})

const fetchBotConfigSuccess = payload => ({
  type: types.FETCH_BOT_CONFIG_SUCCESS,
  payload
})

const fetchBotConfigFailure = errors => ({
  type: types.FETCH_BOT_CONFIG_NO_DATA,
  errors
})

/* setDateRange */
const setDateRange = dateRange => {
  const stripedDateRange = {
    start: stripDateDetails(dateRange.start),
    end: stripDateDetails(dateRange.end || stripDateDetails(momentz.tz('Europe/Paris')))
  }
  return {
    type: types.SET_DATE_RANGE,
    dateRange: stripedDateRange
  }
}
const setDateRange2 = dateRange => {
  const stripedDateRange = {
    start: stripDateDetails(dateRange.startDate),
    end: stripDateDetails(dateRange.endDate || stripDateDetails(momentz.tz('Europe/Paris')))
  }
  return {
    type: types.SET_DATE_RANGE,
    dateRange: stripedDateRange
  }
}

const setActivePeriod = index => ({
  type: types.SET_ACTIVE_PERIOD,
  index
})

/* fetchTrainings */
const getTrainingSentenceFilterRequest = () => ({
  type: types.GET_TRAINING_SENTENCE_REQUEST
})

const getTrainingSentenceFilterSuccess = payload => ({
  type: types.GET_TRAINING_SENTENCE_SUCCESS,
  payload
})

const getTrainingSentenceFilterFailure = errors => ({
  type: types.GET_TRAINING_SENTENCE_FAILURE,
  errors
})

/* fetchTrainingsNotMatched */
const getTrainingSentenceFilterNotMatchedRequest = () => ({
  type: types.GET_TRAINING_SENTENCENOT_REQUEST
})

const getTrainingSentenceFilterNotMatchedSuccess = payload => ({
  type: types.GET_TRAINING_SENTENCENOT_SUCCESS,
  payload
})

const getTrainingSentenceFilterNotMatchedFailure = errors => ({
  type: types.GET_TRAINING_SENTENCENOT_FAILURE,
  errors
})

/* fetchTrainingsStandBy */
const getTrainingSentenceFilterStandByRequest = () => ({
  type: types.GET_TRAINING_SENTENCESTANDBY_REQUEST
})

const getTrainingSentenceFilterStandBySuccess = payload => ({
  type: types.GET_TRAINING_SENTENCESTANDBY_SUCCESS,
  payload
})

const getTrainingSentenceFilterStandByFailure = errors => ({
  type: types.GET_TRAINING_SENTENCESTANDBY_FAILURE,
  errors
})

/* fetchBotIntent */
const fetchBotIntentRequest = () => ({
  type: types.FETCH_BOT_INTENT_REQUEST
})

const fetchBotIntentSuccess = payload => ({
  type: types.FETCH_BOT_INTENT_SUCCESS,
  payload
})

const fetchBotIntentFailure = errors => ({
  type: types.FETCH_BOT_INTENT_FAILURE,
  errors
})

/* trainLine */
const trainLineRequest = (listName, index) => ({
  type: types.TRAIN_LINE_REQUEST,
  listName,
  index
})
const trainLineSuccess = (listName, index) => ({
  type: types.TRAIN_LINE_SUCCESS,
  listName,
  index
})
const trainLineFailure = errors => ({
  type: types.TRAIN_LINE_FAILURE,
  errors
})

/* trainSelected */
const trainSelectedRequest = listName => ({
  type: types.TRAIN_SELECTED_REQUEST,
  listName
})
const trainSelectedSuccess = listName => ({
  type: types.TRAIN_SELECTED_SUCCESS,
  listName
})
const trainSelectedFailure = listName => ({
  type: types.TRAIN_SELECTED_SUCCESS,
  listName
})

/* archiveSelected */
const archiveSelectedRequest = listName => ({
  type: types.ARCHIVE_SELECTED_REQUEST,
  listName
})
const archiveSelectedSuccess = listName => ({
  type: types.ARCHIVE_SELECTED_REQUEST,
  listName
})
const archiveSelectedFailure = listName => ({
  type: types.ARCHIVE_SELECTED_REQUEST,
  listName
})

/* deleteSelected */
const deleteSelectedRequest = listName => ({
  type: types.DELETE_SELECTED_REQUEST,
  listName
})
const deleteSelectedSuccess = listName => ({
  type: types.DELETE_SELECTED_REQUEST,
  listName
})
const deleteSelectedFailure = listName => ({
  type: types.DELETE_SELECTED_REQUEST,
  listName
})

/* deletedIntent */
const deletedRequest = () => ({
  type: types.DELETED_REQUEST
})

const deletedSuccess = payload => ({
  type: types.DELETED_SUCCESS,
  payload
})

const deletedFailure = errors => ({
  type: types.DELETED_FAILURE,
  errors
})

const archiveLineRequest = (index, listName) => ({
  type: types.ARCHIVE_LINE_REQUEST,
  index,
  listName
})

const archiveLineSuccess = (index, listName) => ({
  type: types.ARCHIVE_LINE_SUCCESS,
  index,
  listName
})

const deleteLineRequest = (index, listName) => ({
  type: types.DELETE_LINE_REQUEST,
  index,
  listName
})
const deleteLineSuccess = (index, listName) => ({
  type: types.DELETE_LINE_SUCCESS,
  index,
  listName
})

const toggleLine = (index, listName) => ({
  type: types.TOGGLE_LINE,
  index,
  listName
})

const toggleAll = listName => ({
  type: types.TOGGLE_ALL,
  listName
})

const assignListName = listName => ({
  type: types.ASSIGN_LIST_NAME,
  listName
})

const assignSelected = (intentId, listName) => ({
  type: types.ASSIGN_SELECTED,
  intentId,
  listName
})

const clearIntentSelected = listName => ({
  type: types.CLEAR_INTENT_SELECTED,
  payload: listName
})

const assignLine = (index, listName, intentId) => ({
  type: types.ASSIGN_LINE,
  index,
  listName,
  intentId
})

const clearIntentLine = (index, listName) => ({
  type: types.CLEAR_INTENT_LINE,
  payload: {
    index,
    listName
  }
})

const setDateRangeDropDown = ({ duration }) => {
  const end = stripDateDetails(momentz.tz('Europe/Paris'))
  const today = stripDateDetails(momentz.tz('Europe/Paris'))

  switch (duration) {
  case 'd':
    return {
      type: types.SET_DATE_RANGE,
      dateRange: {
        start: moment(today),
        end: moment(today)
      }
    }
  case 'w':
    return {
      type: types.SET_DATE_RANGE,
      dateRange: {
        start: moment(today).subtract(1, 'weeks'),
        end: moment(end).subtract(1, 'days')
      }
    }
  case 'm':
    return {
      type: types.SET_DATE_RANGE,
      dateRange: {
        start: moment(today).subtract(1, 'months'),
        end: moment(end).subtract(1, 'days')
      }
    }
  case 'y':
    return {
      type: types.SET_DATE_RANGE,
      dateRange: {
        start: moment(today).subtract(1, 'day'),
        end: moment(today).subtract(1, 'days')
      }
    }
  case 'year':
    return {
      type: types.SET_DATE_RANGE,
      dateRange: {
        start: moment(today).subtract(12, 'months'),
        end: moment(end).subtract(1, 'days')
      }
    }
  default:
    return {
      type: types.SET_DATE_RANGE
    }
  }
}

export default {
  isFetchingAllData,
  setDateRangeDropDown,

  toggleLine,
  toggleAll,
  assignSelected,
  assignLine,
  assignListName,

  getTrainingSentenceFilterRequest,
  getTrainingSentenceFilterSuccess,
  getTrainingSentenceFilterFailure,

  getTrainingSentenceFilterNotMatchedRequest,
  getTrainingSentenceFilterNotMatchedSuccess,
  getTrainingSentenceFilterNotMatchedFailure,

  getTrainingSentenceFilterStandByRequest,
  getTrainingSentenceFilterStandBySuccess,
  getTrainingSentenceFilterStandByFailure,

  clearIntent,
  clearIntentLine,
  clearIntentSelected,

  setDataFilters,
  setDateRange,
  setActivePeriod,
  fetchUsersRequest,

  setActiveChannel,
  setActiveIntent,

  fetchBotConfigRequest,
  fetchBotConfigSuccess,
  fetchBotConfigFailure,

  fetchBotIntentRequest,
  fetchBotIntentSuccess,
  fetchBotIntentFailure,

  trainLineRequest,
  trainLineSuccess,
  trainLineFailure,

  trainSelectedRequest,
  trainSelectedSuccess,
  trainSelectedFailure,

  archiveSelectedRequest,
  archiveSelectedSuccess,
  archiveSelectedFailure,

  deleteSelectedRequest,
  deleteSelectedSuccess,
  deleteSelectedFailure,

  archiveLineRequest,
  archiveLineSuccess,

  deletedRequest,
  deletedSuccess,
  deletedFailure,

  deleteLineRequest,
  deleteLineSuccess,

  setActiveLanguage,
  setDateRange2
}
