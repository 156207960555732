import * as actions from './actions'
import * as saga from './saga'
import * as selectors from './selectors'
import * as entityReducer from './reducer.entity'
import * as uiReducer from './reducer.ui'
import QuestionComponent from './components/Question'
import * as models from './models'

export default {
  actions,
  models,
  reducers: {
    entity: entityReducer,
    ui: uiReducer
  },
  saga,
  selectors,
  components: {
    Question: QuestionComponent
  }
}
