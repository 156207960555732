import React from 'react'
import { RouteComponentProps } from 'react-router'
import { withRouter } from 'react-router-dom'
import { Maybe, maybe } from '@sbizeul/fp-flow'
import * as R from 'ramda'

import { Id } from 'types'
import { webchatParamEnv } from 'helpers/effects'

import { isWebchatShowable } from '../../models.typed'
import { getWebchatIdFromUrl } from '../../models'

import WebchatComponent from './WebchatComponent'

import './style.css'

const WEBCHAT_IFRAME_ID = 'chatbot-insert'

const findById = (id: Id) => maybe.fromNullable(document.getElementById(id))
const removeElement = maybe.map(element => element.remove())
const removeWebChat = () => R.pipe(findById, removeElement, R.always(null))(WEBCHAT_IFRAME_ID)

type WebChatFrameProps = Readonly<{
  selectedBotId: Id
  isWebChatDisplayable: boolean
}>

const WebChatFrame: React.FunctionComponent<WebChatFrameProps> = React.memo(({ selectedBotId, isWebChatDisplayable }) => {
  React.useEffect(() => { removeWebChat() })

  return isWebChatDisplayable ? (
    <WebchatComponent webchatId={selectedBotId} webchatParamEnv={webchatParamEnv}/>
  ) : null
})

type WebchatProps = RouteComponentProps & Readonly<{
  webchatUrl: Maybe<string>
  isLoggedIn: boolean
}>

const Webchat = ({ webchatUrl, isLoggedIn, location }: WebchatProps) =>
  isLoggedIn
    ? maybe.fold(R.always(null), R.compose(renderWebChat(location.pathname), getWebchatIdFromUrl))(webchatUrl)
    : null

const renderWebChat = (pathname: string) => (selectedBotId: Id) => (
  <WebChatFrame selectedBotId={selectedBotId} isWebChatDisplayable={isWebchatShowable(pathname)} />
)

export default withRouter(Webchat)
