import { call, put, takeLatest, select } from 'redux-saga/effects'
import { maybe } from '@sbizeul/fp-flow'

import Api from 'services/tolkApiLegacy'
import * as trainActions from './actions'
import bot from '../bot'

export function* publish() {
  try {
    const botId = yield select(bot.selectors.getSelectedId)
    yield call(Api.post, `/admin/bot/${maybe.get(botId)}/train`)
    yield put(trainActions.publishSuccess())
  } catch (err) {
    yield put(trainActions.publishFailure(err))
  }
}

export function* root() {
  yield takeLatest(trainActions.PUBLISH_REQUEST, publish)
}
