import { createSelector } from 'reselect'
import { createMatchSelector } from 'connected-react-router'

import { MonitorState } from './types'
import * as routes from './routes'

export const getState = (state: MonitorState) => state

export const getEntitiesState = createSelector(getState, state => state.entities)

export const getMonitor = createSelector(getEntitiesState, entities => entities.monitor)
export const getLogs = createSelector(getMonitor, monitor => monitor.logs)
export const getProgress = createSelector(getMonitor, monitor => monitor.progress)
export const getLogsOutOfScope = createSelector(getMonitor, monitor => monitor.logsOutOfScope)
export const getCreatedQuestionId = createSelector(getMonitor, monitor => monitor.createdQuestionId)

export const matchOutOfScopeRoute = createMatchSelector(routes.OUT_OF_SCOPE)
