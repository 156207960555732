
import React from 'react'
import moment from 'moment'
import { Button } from 'semantic-ui-react'

import './PreselectButtons.css'

export const PreselectButton = ({ text, value, subStart, subEnd, onClick }) => (
  <Button
    color='teal'
    size='tiny'
    onClick={() =>
      onClick({
        startDate: moment().subtract(subStart, value),
        endDate: moment().subtract(subEnd, value)
      })
    }
  >
    {text}
  </Button>
)

export default function PreselectButtons({ onDatesChange }) {
  return (
    <div className='PreselectButtons'>
      <PreselectButton text='Last month' value='month' subStart={1} subEnd={0} onClick={onDatesChange} />
      <PreselectButton text='Yesterday' value='day' subStart={1} subEnd={1} onClick={onDatesChange} />
      <PreselectButton text='Today' value='day' subStart={0} subEnd={0} onClick={onDatesChange} />
    </div>
  )
}
