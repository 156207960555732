import React from 'react'
import { either } from '@sbizeul/fp-flow'
import classNames from 'classnames'
import { MdFileUpload } from 'react-icons/md'

import { renderDotLoader, renderFailure } from 'helpers/react'

import './styles.css'

const renderQAButton = ({ isImporting, importFile }) => (
  <React.Fragment>
    <label
      className='ImportButton-import--label'
      htmlFor='ImportButton-import--input'
    >
      <MdFileUpload className='ImportButton-icon' size='25' />
      IMPORT Q&A
    </label>
    <input
      id='ImportButton-import--input'
      type='file'
      data-testid='importButton-input'
      disabled={isImporting}
      onChange={({ currentTarget }) => currentTarget.files && importFile(currentTarget.files)}
    />
  </React.Fragment>
)

const ImportButton = ({ isImporting, importFile }) => (
  <div className={classNames(
    'ImportButton', {
      'ImportButton--disabled': isImporting
    })}>
    {isImporting ? renderDotLoader('Importing Q&A') : renderQAButton({ isImporting, importFile })}
  </div>
)

const LoadingImportButton = ({ importFile, isImporting }) =>
  either.fold(
    error => (
      <React.Fragment>
        {renderFailure(error.message || error)}
        <ImportButton isImporting={false} importFile={importFile} />
      </React.Fragment>
    ),
    importing => <ImportButton isImporting={importing} importFile={importFile} />
  )(isImporting)

export default LoadingImportButton
export { ImportButton }
