import React from 'react'
import axios from 'axios'

import * as customerApisList from './components/list'
import * as customerApiForm from './components/form'

import '@tolk-ai/bot-management-react-customer-apis/dist/bot-management-react-customer-apis.esm.css'

import {
  botManagementBaseUrl,
  authManagementBaseUrl,
  cookieDomain
} from 'helpers/effects'

const botManagementExpressAxios = axios.create({
  baseURL: botManagementBaseUrl
})

const customerApisListComponent = (): React.FC => customerApisList.component(document, cookieDomain, botManagementExpressAxios, authManagementBaseUrl)
const CustomerApisList = customerApisListComponent() 

const customerApiFormComponent = (): React.FC => customerApiForm.component(cookieDomain, botManagementExpressAxios, authManagementBaseUrl)
const CustomerApiForm = customerApiFormComponent() 

export { CustomerApisList, CustomerApiForm}