import * as actions from './actions'
import * as entity from './reducer.entity'
import * as ui from './reducer.ui'
import * as routes from './routes'
import * as saga from './saga'
import AppSettingsContainer from './containers/AppSettingsContainer/AppSettingsContainer'
import { appsConfig as config } from './config'

export default {
  actions,
  components: {
    AppSettingsContainer,
  },
  reducers: {
    entity,
    ui
  },
  routes,
  saga,
  config
}
