import * as R from 'ramda'

export const getLucieData = () => [
  { from: '', to: 'onboarding', weight: 0 },
  { from: 'onboarding', to: 'proposition de destinations', weight: 0 },
  { from: 'proposition de destinations', to: 'decouvrir', weight: 0 },
  { from: 'decouvrir', to: "pret à s'envoler vers la destination", weight: 0 },
  { from: 'decouvrir', to: 'destination non satisfaisante', weight: 0 },
  { from: "pret à s'envoler vers la destination", to: 'guide', weight: 0 },
  { from: "pret à s'envoler vers la destination", to: 'info pratique', weight: 0 },
  { from: "pret à s'envoler vers la destination", to: 'reserver un vol', weight: 0 },
  { from: "pret à s'envoler vers la destination", to: 'recommencer', weight: 0 }
]

export const getDsAutoData = () => [
  { from: '', to: 'premiere_page', weight: 0 },
  { from: 'premiere_page', to: 'seconde_page', weight: 0 },
  { from: 'seconde_page', to: 'troisieme_page', weight: 0 },
  { from: 'seconde_page', to: 'retour_premiere_page', weight: 0 },
  { from: 'troisieme_page', to: 'fin', weight: 0 },
  { from: 'troisieme_page', to: 'retour_seconde_page', weight: 0 }
]

export const getAmpData = () => [
  { from: '', to: 'start_parking_place', weight: 0 },
  { from: 'start_parking_place', to: 'date_parking_place', weight: 0 },
  { from: 'date_parking_place', to: 'type_parking_place', weight: 0 },
  { from: 'date_parking_place', to: 'erreur_parking_place', weight: 0 },
  { from: 'type_parking_place', to: 'end_parking_place', weight: 0 }
]

export const getBrealData = () => [
  { from: '', to: 'start_suivi_commande_funnel', weight: 0 },
  { from: 'start_suivi_commande_funnel', to: 'number_suivi_commande_funnel', weight: 0 },
  { from: 'number_suivi_commande_funnel', to: 'end_suivi_commande_funnel', weight: 0 }
]

export const getDsAutoLeadGenData = () => [
  { from: '', to: 'onboarding', weight: 0 },
  { from: 'onboarding', to: 'contact_choice', weight: 0 },
  { from: 'contact_choice', to: 'messenger', weight: 0 },
  { from: 'contact_choice', to: 'whatsapp', weight: 0 },
  { from: 'contact_choice', to: 'email', weight: 0 },
  { from: 'contact_choice', to: 'phone', weight: 0 },
  { from: 'messenger', to: 'messenger_click', weight: 0 },
  { from: 'whatsapp', to: 'whatsapp_validated', weight: 0 },
  { from: 'email', to: 'email_validated', weight: 0 },
  { from: 'phone', to: 'phone_validated', weight: 0 }
]

export const getMuglerData = () => [
  { from: '', to: 'start_parcours', weight: 0 },
  { from: 'start_parcours', to: 'start_ticket', weight: 0 },
  { from: 'start_ticket', to: 'picture_ticket', weight: 0 },
  { from: 'picture_ticket', to: 'shop_ticket', weight: 0 },
  { from: 'shop_ticket', to: 'infos_ticket', weight: 0 },
  { from: 'infos_ticket', to: 'infos_ticket_ok', weight: 0 },
  { from: 'shop_ticket', to: 'name_ticket', weight: 0 },
  { from: 'name_ticket', to: 'email_ticket', weight: 0 },
  { from: 'email_ticket', to: 'address_ticket', weight: 0 },
  { from: 'start_parcours', to: 'start_capuchon', weight: 0 },
  { from: 'start_capuchon', to: 'qualif_capuchon_ok', weight: 0 },
  { from: 'qualif_capuchon_ok', to: 'parfum_capuchon', weight: 0 },
  { from: 'parfum_capuchon', to: 'name_capuchon', weight: 0 },
  { from: 'name_capuchon', to: 'email_capuchon', weight: 0 },
  { from: 'email_capuchon', to: 'number_capuchon', weight: 0 },
  { from: 'number_capuchon', to: 'address_capuchon', weight: 0 },
  { from: 'start_capuchon', to: 'qualif_capuchon_ko', weight: 0 }
]

export const getFreeprintsData = () => [
  { from: '', to: 'start_experience', weight: 0 },
  { from: 'start_experience', to: 'proposal_upload_picture', weight: 0 },
  { from: 'proposal_upload_picture', to: 'upload_picture', weight: 0 },
  { from: 'upload_picture', to: 'completion_upload_picture', weight: 0 },
  { from: 'completion_upload_picture', to: 'get_user_information', weight: 0 },
  { from: 'get_user_information', to: 'validation_recap', weight: 0 },
  { from: 'validation_recap', to: 'credit_card_payment', weight: 0 },
  { from: 'validation_recap', to: 'paypal_payment', weight: 0 },
  { from: 'credit_card_payment', to: 'application_fallback_clicked', weight: 0 },
  { from: 'paypal_payment', to: 'application_fallback_clicked', weight: 0 }
]

export const getOpelData = () => [
  { from: '', to: 'onboarding', weight: 0 },
  { from: 'onboarding', to: 'start_flow_reservation', weight: 0 },
  { from: 'start_flow_reservation', to: 'validation_recap', weight: 0 },
  { from: 'onboarding', to: 'start_flow_curiosite', weight: 0 },
  { from: 'start_flow_curiosite', to: 'fin_flow_curiosite', weight: 0 }
]

export const getBrealTolkData = () => [
  { from: '', to: 'Debut du suivi', weight: 0 },
  { from: 'Debut du suivi', to: 'Numero OK', weight: 0 },
  { from: 'Numero OK', to: 'Nom OK', weight: 0 },
  { from: 'Nom OK', to: 'Envoi du carrousel', weight: 0 },
  { from: 'Envoi du carrousel', to: 'Abonnement', weight: 0 },
  { from: 'Nom OK', to: 'Commande introuvable', weight: 0 }
]

export const getDsTolkData = () => [
  { from: '', to: 'entry_escalade_funnel', weight: 0 },
  { from: 'entry_escalade_funnel', to: 'premiere_page', weight: 0 },
  { from: 'premiere_page', to: 'seconde_page', weight: 0 },
  { from: 'seconde_page', to: 'troisieme_page', weight: 0 },
  { from: 'troisieme_page', to: 'fin', weight: 0 }
]

export const isEmpty = R.either(R.isEmpty, R.isNil)

export const withoutUserIdDoublons = R.uniqBy(element => ({ userId: element.userId, name: element.name }))

export const countByName = R.countBy(R.prop('name'))

export const formatData = (originalData, countedElements) => {
  const reference =
    countedElements['onboarding'] ||
    countedElements['premiere_page'] ||
    countedElements['start_parking_place'] ||
    countedElements['start_suivi_commande_funnel'] ||
    countedElements['start_parcours'] ||
    countedElements['start_experience'] ||
    countedElements['Debut du suivi'] ||
    countedElements['entry_escalade_funnel']
  return originalData.map(element => ({
    ...element,
    weight: isNaN((countedElements[element.to] / reference) * 100)
      ? 0
      : Math.round((countedElements[element.to] / reference) * 100)
  }))
}
