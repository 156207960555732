import React from 'react'
import * as R from 'ramda'
import { MdMail, MdClose } from 'react-icons/md'

import TextArea from 'components/TextArea'

import './style.css'

export default function EmailContentEditor(props) {
  const handleTextChange = text => {
    const simpleText = R.set(props.pathToText, text, props.textContent)
    props.updateContent({ content: simpleText })
  }

  const handleClickDelete = () => {
    props.removeContent({ id: R.view(props.pathToId, props.textContent) })
  }

  return (
    <div className='EmailContent'>
      <div className='EmailContent-icons-container'>
        <MdMail size='25' />
        <MdClose
          data-testid='remove-content'
          size='20'
          className='EmailContent-delete--icon'
          onClick={handleClickDelete}
        />
      </div>
      <div className='EmailContent-text'>
        <TextArea
          data-testid='update-text'
          className='EmailContent-text--input'
          type='rounded'
          required
          pattern='^.{1,600}'
          placeholder='Type your question to receive email address...'
          autocomplete='off'
          onChangeText={handleTextChange}
          value={R.view(props.pathToText, props.textContent)}
        />
      </div>
    </div>
  )
}
