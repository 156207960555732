import { always, curry, pipe } from 'ramda'

import { maybe } from '@sbizeul/fp-flow'

const _getItem = curry((ctx, key) =>
  pipe((key) => ctx.localStorage.getItem(key), maybe.fromNullable)(key)
)

const _setItem = curry((ctx, key, value) =>
  ctx.localStorage.setItem(key, value)
)

const _removeItem = curry((ctx, key) => ctx.localStorage.removeItem(key))

export const getEnv = (prop) =>
  window._env_
    ? maybe.of(window._env_[prop])
    : maybe.fromNullable(process.env[prop])

export const legacyApiUrl = maybe.getOrElse(
  always(''),
  getEnv('LEGACY_API_URL')
)

export const campaignsApiUrl = maybe.getOrElse(
  always(''),
  getEnv('CAMPAIGNS_API_URL')
)

export const authAnalyticsPwd = maybe.getOrElse(
  always(''),
  getEnv('BASIC_AUTH_ANALYTICS_PWD')
)

export const apiUrl = maybe.getOrElse(always(''), getEnv('TOLK_API_URL'))

export const bucketUrl = maybe.getOrElse(always(''), getEnv('BUCKET_URL'))

export const webchatParamEnv = maybe.getOrElse(
  always(''),
  getEnv('WEBCHAT_PARAM_ENV')
)

export const pushServerUrl = maybe.getOrElse(
  always(''),
  getEnv('PUSH_SERVER_URL')
)

export const getItem = _getItem(window)

export const setItem = _setItem(window)

export const removeItem = _removeItem(window)

export const agentBaseUrl = maybe.getOrElse(
  always(''),
  getEnv('AGENT_BASE_URL')
)

export const botManagementBaseUrl = maybe.getOrElse(
  always(''),
  getEnv('BOT_MANAGEMENT_EXPRESS_BASE_URL')
)

export const analyticsReactBaseUrl = maybe.getOrElse(
  always(''),
  getEnv('ANALYTICS_REACT_BASE_URL')
)

export const botManagementReactBaseUrl = maybe.getOrElse(
  always(''),
  getEnv('BOT_MANAGEMENT_REACT_BASE_URL')
)

export const authManagementBaseUrl = maybe.getOrElse(
  always(''),
  getEnv('AUTH_MANAGEMENT_EXPRESS_BASE_URL')
)

export const accountManagementReactBaseUrl = maybe.getOrElse(
  always(''),
  getEnv('TOLK_MANAGEMENT_REACT_BASE_URL')
)

export const accountManagementBaseUrl = maybe.getOrElse(always(''), getEnv('ACCOUNT_MANAGEMENT_EXPRESS_BASE_URL'))

export const oauthClientId = maybe.getOrElse(
  always(''),
  getEnv('OAUTH_CLIENT_ID')
)

export const oauthRedirectUri = maybe.getOrElse(
  always(''),
  getEnv('OAUTH_REDIRECT_URI')
)

export const cookieDomain = maybe.getOrElse(always(''), getEnv('COOKIE_DOMAIN'))

export const shopifyAppName = maybe.getOrElse(
  always(''),
  getEnv('SHOPIFY_APP_NAME')
)
