import { connect } from 'react-redux'

import * as qaActions from 'modules/qa/actions'

import CreationForm from '../components/CreationForm'
import * as actions from '../actions'
import * as selectors from '../selectors'

const mapStateToProps = state => ({
  botUrl: selectors.getUrl(state),
  theme: selectors.getColors(state),
  webchat: selectors.getBotCreation(state),
  botName: selectors.getBotname(state),
  language: selectors.getLanguage(state),
  greetings: selectors.getGreetings(state),
  availableUseCases: selectors.getAvailableUseCases(state),
  useCase: selectors.getUseCase(state)
})

const mapDispatchToProps = {
  createBot: actions.registerCreatedBotRequest,
  updateBotName: actions.updateBotName,
  updateGreetings: actions.updateGreetings,
  updateLanguage: actions.updateLanguage,
  updateUseCase: actions.updateUseCase,
  updateTheme: actions.updateTheme,
  updateAvatar: actions.updateAvatar,
  getLastBot: actions.getLastBot,
  fetchUseCases: actions.fetchUseCases,
  creationBotEnded: actions.registerCreatedBotEnded,
  cancelForm: qaActions.navigateToQuestions
}

const CreationFormContainer = connect(mapStateToProps, mapDispatchToProps)(CreationForm)

export default CreationFormContainer
