import { Map } from 'immutable'

const initialState = Map({
  loading: false,
  fetchError: false,
  messageError: '',
  loadingMessage: 'Chargement...'
})

export default initialState
