import * as actions from './actions'
import reducer from './reducer'
import * as saga from './saga'
import * as selectors from './selectors'

export default {
  actions,
  reducer,
  saga,
  selectors
}
