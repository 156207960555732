import React from 'react'
import { remoteData, RemoteData } from '@sbizeul/fp-flow'
import * as R from 'ramda'
import { VelocityTransitionGroup } from 'velocity-react'

import { Map } from 'helpers/map'
import { renderNothing } from 'helpers/react'
import ButtonContentEditor from 'components/ButtonContentEditor'
import ImageContentEditor from 'components/ImageContentEditor'
import TextContentEditor from 'components/TextContentEditor'
import TagAgentContentEditor from 'components/TagAgentContentEditor'
import { isValidButtonContent } from 'modules/answer/models.typed'
import { Question } from 'modules/question/types'
import { ServerFailure } from 'types'

import { completeButton, filterEmptyButton, hasThreeButton } from '../../models.typed'
import SkillContentEditor from '../SkillContentEditor'
import {
  idPath,
  imagePath,
  textPath,
  AnswerContent,
  ButtonContent,
  FormContent,
  ImageContent,
  RemoveContentParam,
  SimpleAnswer,
  TagAgentContent,
  TextContent,
  UpdateContentParam,
  GotoSkillContent,
  GotoSmartSkillContent,
  SimplifiedSkill,
  SimplifiedTag,
  UploadImageRequestPayload,
  CarouselContent,
  BaseSkillContent,
  RgpdContent,
  ActionContent
} from '../../types'

import './style.css'
import CarouselContentEditor from 'components/CarouselContentEditor'

export type Props = Readonly<{
  answer: SimpleAnswer
  imagesUrl: Map<RemoteData<ServerFailure, string>>
  questions: RemoteData<ServerFailure, ReadonlyArray<Question>>
  skills: RemoteData<ServerFailure, SimplifiedSkill[]>
  tags: RemoteData<ServerFailure, SimplifiedTag[]>
  fetchAllSkills: () => unknown
  fetchAllTags: () => unknown
  fetchQuestions: () => unknown
  goToSkills: () => unknown
  removeContent: (params: RemoveContentParam) => unknown
  updateContent: (params: UpdateContentParam) => unknown
  uploadImage: (params: UploadImageRequestPayload) => unknown
}>

const SimpleAnswerEditor: React.FunctionComponent<Props> = ({ ...props }) => {
  const isEmpty = () => R.isNil(R.prop('content', props.answer))

  const renderButtonContent = (button: ButtonContent) => {
    return (
      <ButtonContentEditor
        key={button.id}
        buttonContent={!hasThreeButton(button) && isValidButtonContent(button) ? completeButton(button) : button}
        updateContent={R.pipe(filterEmptyButton, props.updateContent)}
        removeContent={props.removeContent}
        fetchQuestions={props.fetchQuestions}
        questions={props.questions}
      />
    )
  }

  const renderImageContent = (imageContent: ImageContent) => (
    <ImageContentEditor
      key={imageContent.id}
      pathToId={idPath}
      pathToImage={imagePath}
      imageContent={imageContent}
      uploadImage={props.uploadImage}
      updateContent={props.updateContent}
      removeContent={props.removeContent}
      imageUrl={R.defaultTo(
        remoteData.of(imageContent.objectAttachment.imageUrl),
        props.imagesUrl[imageContent.id]
      )}
    />
  )

  const renderTagAgentContent = (tagAgentContent: TagAgentContent) => (
    <TagAgentContentEditor
      key={tagAgentContent.id}
      pathToId={R.lensProp('id')}
      content={tagAgentContent}
      fetchAll={props.fetchAllTags}
      updateContent={props.updateContent}
      removeContent={props.removeContent}
      tags={props.tags}
      goToSkills={props.goToSkills}
      answer={props.answer}
    />
  )

  const renderTextContent = (textContent: TextContent) => (
    <TextContentEditor
      key={textContent.id}
      pathToId={R.lensProp('id')}
      pathToText={textPath}
      textContent={textContent}
      updateContent={props.updateContent}
      removeContent={props.removeContent}
    />
  )

  const renderCarouselContent = (carouselContent: CarouselContent) =>
    <CarouselContentEditor
      key={carouselContent.id}
      carouselContent={carouselContent}
      updateContent={content => props.updateContent({ content })}
      removeContent={props.removeContent}
      fetchQuestions={props.fetchQuestions}
      questions={props.questions}
      uploadImage={props.uploadImage}
      imagesUrl={props.imagesUrl}
    />

  const renderSkillContent = (content: FormContent | GotoSkillContent | GotoSmartSkillContent | BaseSkillContent | RgpdContent | ActionContent) => (props.goToSkills && props.fetchAllSkills && props.skills) &&
    <SkillContentEditor
      key={content.id}
      pathToId={R.lensProp('id')}
      content={content}
      fetchAll={props.fetchAllSkills}
      updateContent={props.updateContent}
      removeContent={props.removeContent}
      skills={props.skills}
      goToSkills={props.goToSkills}
      answer={props.answer}
    />

  const renderSimpleAnswerContent = (content: AnswerContent) => {
    switch (content.type) {
      case 'text':
        return renderTextContent(content)
      case 'button':
        return renderButtonContent(content)
      case 'carousel':
        return renderCarouselContent(content)
      case 'image':
        return renderImageContent(content)
      case 'tagagent':
        return renderTagAgentContent(content)
      case 'form':
      case 'skill':
      case 'smartskill':
      case 'answer':
      case 'rgpd':
      case 'app':
        return renderSkillContent(content)
    }
  }

  const renderSimpleAnswer = (answer: SimpleAnswer) => (
    <VelocityTransitionGroup
      enter={{ animation: 'slideDown', duration: 200 }}
      leave={{ animation: 'slideUp', duration: 200 }}
      runOnMount={true}
    >
      {answer.content.map(renderSimpleAnswerContent)}
    </VelocityTransitionGroup>
  )

  return (
    <div className='SimpleAnswerEditor-content'>
      {isEmpty() ? renderNothing() : renderSimpleAnswer(props.answer)}
    </div>
  )
}

export default SimpleAnswerEditor
