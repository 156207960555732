import formatFilters from '../../../_shared/formatFilters'
import actions from '../actions'
import axios from 'axios'
import { botManagementBaseUrl } from 'helpers/effects'

const fetchConversations = rawFilters => {
  const { channelId, start, end } = formatFilters(rawFilters)

  const startDate = encodeURIComponent(new Date(start).toISOString())
  const endDate = encodeURIComponent(new Date(end).toISOString())

  return async (dispatch, getState) => {
    dispatch(actions.fetchConversationsRequest())
    try {

      const response = await axios.get(
        `${botManagementBaseUrl}/v1/channels/${channelId}/detail-conversation-count?startDate=${startDate}&endDate=${endDate}`
      )
      const { totalCount, data } = response.data
      dispatch(actions.fetchConversationsSuccess({ data, totalCount }))
    } catch (e) {
      dispatch(actions.fetchConversationsFailure(e))
    }
  }
}

export default fetchConversations
