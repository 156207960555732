import React from 'react'
import * as R from 'ramda'
import { remoteData } from '@sbizeul/fp-flow'
import { Loader } from 'semantic-ui-react'

import Paginator from '../../../components/Paginator'
import { renderNothing } from '../../../helpers/react'
import Log from './Log'

export default class LogList extends React.Component {
  componentDidMount() {
    this.props.fetchNext()
  }

  renderLog = log => <Log log={log} />

  render() {
    return (
      <div className='LogList'>
        {remoteData.fold(
          {
            Success: R.map(this.renderLog),
            Loading: () => <Loader />,
            Failure: renderNothing,
            NotAsked: renderNothing
          },
          this.props.logs
        )}
        <Paginator
          onNext={this.props.fetchNext}
          onPrevious={this.props.fetchPrevious}
          page={this.props.page}
          disabled={!remoteData.isSuccess(this.props.logs)}
        />
      </div>
    )
  }
}
