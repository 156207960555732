import * as React from 'react'
import { SortableElement } from 'react-sortable-hoc'
import ElementStyled from './styles'
import { MoveHandle, Trash } from '..'

const Element = ({ render, deleteElement, notDragable, canEdit }) => (
  <ElementStyled>
    {canEdit && <Trash notCentered={!notDragable} showOnHover onTrashClick={deleteElement} />}
    {!notDragable && <MoveHandle />}
    {render()}
  </ElementStyled>
)

export default SortableElement(Element)
