import axios from 'axios'
import { toastr } from 'react-redux-toastr'

import actions from './actions'
import { legacyApiUrl } from 'helpers/effects'

/* getDefectCreate */
const getDefectCreate = () => dispatch => {
  dispatch(actions.getDefectCreateRequest())
  const url = `${legacyApiUrl}/data_glados/5a0c2bc1946f111a4f15ee29/psa_create_defaut`
  return axios({
    url,
    method: 'get',
    contentType: 'application/json',
    responseType: 'json'
  })
    .then(res => {
      if (res.status === 200) {
        dispatch(actions.getDefectCreateSuccess(res.data))
        toastr.info('Congrats 🎉', 'Fetching Defect Create Success')
      } else {
        dispatch(actions.getDefectCreateFailure())
        toastr.info('Error 🚫', 'Fetching Defect Create Error')
      }
    })
    .catch(err => {
      dispatch(actions.getDefectCreateFailure())
      toastr.info('Error 🚫', `Fetching Defect Create Error ${err}`)
    })
}

/* getDefectSettle */
const getDefectSettle = () => dispatch => {
  dispatch(actions.getDefectSettleRequest())
  const url = `${legacyApiUrl}/data_glados/5a0c2bc1946f111a4f15ee29/psa_solde_defaut`
  return axios({
    url,
    method: 'get',
    contentType: 'application/json',
    responseType: 'json'
  })
    .then(res => {
      if (res.status === 200) {
        dispatch(actions.getDefectSettleSuccess(res.data))
        toastr.info('Congrats 🎉', 'Fetching Defect Settle Success')
      } else {
        dispatch(actions.getDefectSettleFailure())
        toastr.info('Error 🚫', 'Fetching Defect Settle Error')
      }
    })
    .catch(err => {
      dispatch(actions.getDefectSettleFailure())
      toastr.info('Error 🚫', `Fetching Defect Settle Error ${err}`)
    })
}

/* getPrisePoste */
const getPrisePoste = () => dispatch => {
  dispatch(actions.getPrisePosteRequest())
  const url = `${legacyApiUrl}/data_glados/5a0c2bc1946f111a4f15ee29/psa_prise_poste`
  return axios({
    url,
    method: 'get',
    contentType: 'application/json',
    responseType: 'json'
  })
    .then(res => {
      if (res.status === 200) {
        dispatch(actions.getPrisePosteSuccess(res.data))
        toastr.info('Congrats 🎉', 'Fetching Prise Poste Success')
      } else {
        dispatch(actions.getPrisePosteFailure())
        toastr.info('Error 🚫', 'Fetching Prise Poste Error')
      }
    })
    .catch(err => {
      dispatch(actions.getPrisePosteFailure())
      toastr.info('Error 🚫', `Fetching Prise Poste Error ${err}`)
    })
}

/* getSortiePoste */
const getSortiePoste = () => dispatch => {
  dispatch(actions.getSortiePosteRequest())
  const url = `${legacyApiUrl}/data_glados/5a0c2bc1946f111a4f15ee29/psa_sortie_poste`
  return axios({
    url,
    method: 'get',
    contentType: 'application/json',
    responseType: 'json'
  })
    .then(res => {
      if (res.status === 200) {
        dispatch(actions.getSortiePosteSuccess(res.data))
        toastr.info('Congrats 🎉', 'Fetching Sortie Poste Success')
      } else {
        dispatch(actions.getSortiePosteFailure())
        toastr.info('Error 🚫', 'Fetching Sortie Poste Error')
      }
    })
    .catch(err => {
      dispatch(actions.getSortiePosteFailure())
      toastr.info('Error 🚫', `Fetching Sortie Poste Error ${err}`)
    })
}

/* getAndon */
const getAndon = () => dispatch => {
  dispatch(actions.getAndonRequest())
  const url = `${legacyApiUrl}/data_glados/5a0c2bc1946f111a4f15ee29/probleme_andon_declaration`
  return axios({
    url,
    method: 'get',
    contentType: 'application/json',
    responseType: 'json'
  })
    .then(res => {
      if (res.status === 200) {
        dispatch(actions.getAndonSuccess(res.data))
        toastr.info('Congrats 🎉', 'Fetching Andon Success')
      } else {
        dispatch(actions.getAndonFailure())
        toastr.info('Error 🚫', 'Fetching Andon Error')
      }
    })
    .catch(err => {
      dispatch(actions.getAndonFailure())
      toastr.info('Error 🚫', `Fetching Andon Error ${err}`)
    })
}

/* getCommentaire */
const getCommentaires = () => dispatch => {
  dispatch(actions.getCommentairesRequest())
  const url = `${legacyApiUrl}/data_glados/5a0c2bc1946f111a4f15ee29/commentaire_milieu_flow`
  return axios({
    url,
    method: 'get',
    contentType: 'application/json',
    responseType: 'json'
  })
    .then(res => {
      if (res.status === 200) {
        dispatch(actions.getCommentairesSuccess(res.data))
        toastr.info('Congrats 🎉', 'Fetching Comments Success')
      } else {
        dispatch(actions.getCommentairesFailure())
        toastr.info('Error 🚫', 'Fetching Comments Error')
      }
    })
    .catch(err => {
      dispatch(actions.getCommentairesFailure())
      toastr.info('Error 🚫', `Fetching Comments Error ${err}`)
    })
}

export default {
  getDefectCreate,
  getDefectSettle,
  getPrisePoste,
  getSortiePoste,
  getAndon,
  getCommentaires
}
