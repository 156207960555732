import React from 'react'

import Select from '../../../components/Select'

export default function ChannelSelect({ onSelect, channels, selectedId }) {
  return (
    <Select
      className='ChannelSelect'
      placeholder='Select channel'
      value={selectedId}
      options={channels}
      onChange={onSelect}
    />
  )
}
