
import React from 'react'
import { either } from '@sbizeul/fp-flow'
import * as R from 'ramda'

import TextInputMaterial from 'components/TextInputMaterial'

import { renderNothing, renderServerFailure } from 'helpers/react'

const MIN_INPUT_LENGTH = 2
const validateInputLength = text => R.not(R.isEmpty(text)) && text.length >= MIN_INPUT_LENGTH

const InputQuestion = ({ onKeyEnter, isPostingQuestion }) => (
  <React.Fragment>
    <TextInputMaterial
      onEnter={onKeyEnter}
      placeholder='e.g. « How I can talk to a customer rep? » '
      isLoading={either.getOrElse(() => false, isPostingQuestion)}
      validators={[validateInputLength]}
    />
    {either.fold(renderServerFailure, renderNothing, isPostingQuestion)}
  </React.Fragment>
)

export default InputQuestion
