/* OPERATIONS = REDUX THUNKS
This file defines the public interface of the duck
-- what can be dispatched from components
Simple operations are just about forwarding
an action creator, ex: simpleQuack
Complex operations involve returning
a thunk that dispatches multiple actions in a certain order
*/

import axios from 'axios'
import { toastr } from 'react-redux-toastr'

import { reset } from 'redux-form'
import actions from './actions'
import toastrOptions from '../../utils/toastrOptions'
import { legacyApiUrl } from 'helpers/effects'

/* postRessource */
const postRessource = data => dispatch => {
  dispatch(actions.postRessourceRequest())
  const url = `${legacyApiUrl}/bot/ressources`
  return axios({
    url,
    method: 'post',
    contentType: 'application/json',
    responseType: 'json',
    data
  })
    .then(res => {
      if (res.status === 200) {
        dispatch(actions.postRessourceSuccess(res.data))
        dispatch(reset(`form-${data.type}`))
        toastr.info(
          'Ressource uploadée avec succès',
          toastrOptions({
            icon: 'check'
          })
        )
      } else {
        dispatch(actions.postRessourceFailure())
        toastr.error(
          'Problème pour uploader la ressource',
          `Statut Erreur ${res.status}`,
          toastrOptions({
            icon: 'warning sign'
          })
        )
      }
    })
    .catch(err => {
      dispatch(actions.postRessourceFailure())
      toastr.info('Error 🚫', 'Error to upload ressource')
    })
}

/* getRessource */
const getRessource = (botId, ressourceType) => dispatch => {
  dispatch(actions.getRessourceRequest())
  return axios({
    url: `${legacyApiUrl}/bot/ressources/${botId}/${ressourceType}`,
    method: 'get',
    contentType: 'application/json',
    responseType: 'json'
  })
    .then(res => {
      if (res.status === 200) {
        dispatch(actions.getRessourceSuccess(res.data))
      } else {
        dispatch(actions.getRessourceFailure())
        toastr.error(
          'Problème pour récupérer la ressource',
          `Statut Erreur ${res.status}`,
          toastrOptions({
            icon: 'warning sign'
          })
        )
      }
    })
    .catch(err => {
      dispatch(actions.getRessourceFailure())
      toastr.error(
        'Problème pour récupérer la ressource',
        `Erreur ${err}`,
        toastrOptions({
          icon: 'warning sign'
        })
      )
    })
}

/* deleteRessource */
const deleteRessource = (botId, ressourceId) => dispatch => {
  dispatch(actions.deleteRessourceRequest())
  return axios({
    url: `${legacyApiUrl}/bot/ressources/delete/${botId}/${ressourceId}`,
    method: 'delete',
    contentType: 'application/json',
    responseType: 'json'
  })
    .then(res => {
      if (res.status === 200) {
        dispatch(actions.deleteRessourceSuccess(res.data))
        toastr.info('Congrats 🎉', 'Ressource deleted successfully')
      } else {
        dispatch(actions.deleteRessourceFailure())
        toastr.error('Error 🚫', 'Ressource was not deleted')
      }
    })
    .catch(err => {
      dispatch(actions.deleteRessourceFailure())
      toastr.error('Error 🚫', `Ressource was not deleted: ${err}`)
    })
}

/* editRessource */
const editRessource = data => dispatch => {
  dispatch(actions.editRessourceRequest())
  const url = `${legacyApiUrl}/bot/ressources`
  return axios({
    url,
    method: 'put',
    contentType: 'application/json',
    responseType: 'json',
    data
  })
    .then(res => {
      if (res.status === 200) {
        dispatch(actions.editRessourceSuccess(res.data))
        toastr.info('Congrats 🎉', 'Ressource edited successfully')
        dispatch(actions.closeModals(true))
      } else {
        dispatch(actions.editRessourceFailure())
        toastr.error('Error 🚫', 'Ressource was not edited')
      }
    })
    .catch(err => {
      dispatch(actions.editRessourceFailure())
      toastr.error('Error 🚫', `Ressource was not edited: ${err}`)
    })
}

const closeModals = actions.closeModals

export default {
  postRessource,
  getRessource,
  deleteRessource,
  editRessource,
  closeModals
}
