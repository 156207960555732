import React, { useEffect } from 'react'
import { remoteData } from '@sbizeul/fp-flow'
import * as R from 'ramda'

import { LinkedElements, LinkedElement, TrackableKeyword } from 'modules/keyword/types'
import { renderFailure, renderNothing, renderInlineLoader } from 'helpers/react'
import { Id } from 'types'

export type Props = Readonly<{
  keyword: TrackableKeyword
  linkedKeywordElements: LinkedElements

  fetchLinkedKeywordElement: (id: Id) => unknown
}>

const renderKeywordModalContent: (linkedKeywordElements: ReadonlyArray<LinkedElement>) => JSX.Element = linkedKeywordElements =>
    !R.isEmpty(linkedKeywordElements) ?
      <div>
        <p className='DeleteKeyword-linkedElement--title'>You should remove the keyword in the content linked below
          :
        </p>
        <ul>
          {R.map(
            (linkedKeywordElement: LinkedElement) =>
              <li className='Keyword-linkedElement--list'>
                <p className='Keyword-linkedElement--title'>Name: {linkedKeywordElement.name}</p>
                <p className='Keyword-linkedElement--type'>Type of content: {linkedKeywordElement.type === 'QA' ? 'Q&A' : linkedKeywordElement.type}</p>
              </li>
          )(linkedKeywordElements)
          }
        </ul>
      </div>
      :
      <p>Are you sure to delete this keyword and its synonyms ?</p>

const RemoteKeywordModalContent = (props: Props) => {
  useEffect(() => { props.fetchLinkedKeywordElement(props.keyword.id) }, [props.keyword.id])
  return remoteData.fold({
    Success: (linkedKeywordElements: ReadonlyArray<LinkedElement>) => renderKeywordModalContent(linkedKeywordElements),
    NotAsked: renderNothing,
    Loading: () => renderInlineLoader('Please wait...'),
    Failure: renderFailure
  }, props.linkedKeywordElements)
}

export default RemoteKeywordModalContent
