export const appsConfig = {
  'genii_fallback': {
    name: '🧞‍♂️ Genii',
    id: 'genii_fallback',
    fields: [
      { 
        title: 'Project Id', name: 'GENII_FALLBACK_PROJECTID', placeholder: 'd1acd845-281c-452d-adb9-1a687653dc39'
      }
    ]
  },
  'shipup': {
    name: '📦 Shipup',
    id: 'shipup',
    fields: [
      { datatype:'config-connector', title: 'Shipup Authentication Private Key', name: 'ShipupPrivateKey', placeholder: '' },
      { datatype:'content-management', title: 'Tracking status : pending', name: 'ShipupTrackingStatusPending', placeholder: 'L\'information de tracking n\'a pas encore été transmise au transporteur' },
      { datatype:'content-management', title: 'Tracking status : info received', name: 'ShipupTrackingStatusInfoReceived', placeholder: 'Le transporteur a bien reçu le colis' },
      { datatype:'content-management', title: 'Tracking status : in transit', name: 'ShipupTrackingStatusInTransit', placeholder: 'Votre colis est en cours de livraison' },
      { datatype:'content-management', title: 'Tracking status : failed_attempt', name: 'ShipupTrackingStatusFailedAttempt', placeholder: 'Une tentative de livraison a eu lieu mais le colis n\'a pas pu être livré' },
      { datatype:'content-management', title: 'Tracking status : exception', name: 'ShipupTrackingStatusException', placeholder: 'Il semblerait qu\'un incident soit survenu durant la livraison' },
      { datatype:'content-management', title: 'Tracking status : delayed', name: 'ShipupTrackingStatusDelayed', placeholder: 'Le colis est toujours en cours de livraison' },
      { datatype:'content-management', title: 'Tracking status : pickup', name: 'ShipupTrackingStatusPickup', placeholder: 'Votre colis est disponible pour récupération dans un point de retrait' },
      { datatype:'content-management', title: 'Tracking status : delivered', name: 'ShipupTrackingStatusDelivered', placeholder: 'Le colis a été livré dans un point de retrait' },
      { datatype:'content-management', title: 'Tracking status : return', name: 'ShipupTrackingStatusReturn', placeholder: 'Le colis a été renvoyé à l\'expéditeur' },
      { datatype:'content-management', title: 'Tracking status : expired', name: 'ShipupTrackingStatusExpired', placeholder: 'Le colis a été pris en charge il y a plus de 45 jours mais n\'est toujours pas livré' }
    ]
  },
  'openweather': {
    name: '⛅ Open Weather',
    id: 'openweather',
    fields: [
      { 
        title: 'API Key', name: 'apiKey', placeholder: ''
      }
    ]
  },
  'magento_1_rest': {
    name: '🛍 Magento 1 (REST)',
    id: 'magento_1_rest',
    fields: [
      { title: 'API Base URL', name: 'baseUrl', placeholder: 'https://www.mon-eshop.com/api/rest/' },
      { title: 'API Consumer Key', name: 'ConsumerKey', placeholder: '' },
      { title: 'API Consumer Secret', name: 'ConsumerSecret', placeholder: '' },
      { title: 'API Token Key', name: 'TokenKey', placeholder: '' },
      { title: 'API Token Secret', name: 'TokenSecret', placeholder: '' }
    ]
  },
  'prestashop_1_7': {
    name: '🐧 Prestashop 1.7',
    id: 'prestashop_1_7',
    fields: [
      { title: 'API Base URL', name: 'PRESTASHOP_1_7_baseUrl', placeholder: 'https://www.mon-eshop-prestashop.com/api/' },
      { title: 'API Access Token', name: 'PRESTASHOP_1_7_AccessToken', placeholder: '' },
    ]
  }
}



