import React, { Component } from 'react'
import { connect } from 'react-redux'
import { maybe } from '@sbizeul/fp-flow'

import operations from '../../../../../redux/analytics/operations'
import getSafe from '../../../../utils/getSafe'
import Understanding from './Understanding'
import NewFilters from '../../Filters/NewFilters'
import bot from '../../../../../modules/bot'

class UnderstandingContainer extends Component {
  componentDidMount() {
    const { filters } = this.props
    this.props.fetchAnalyticsPerformanceUnderstanding(filters)
  }

  componentDidUpdate(prevProps) {
    const { filters } = this.props
    if (!maybe.equals(prevProps.botId, this.props.botId) || prevProps.filters.channel.id !== filters.channel.id) {
      this.props.fetchAnalyticsPerformanceUnderstanding(filters)
    }
  }

  render() {
    return (
      <div>
        <NewFilters fetch={this.props.fetchAnalyticsPerformanceUnderstanding} />
        <Understanding {...this.props} botId={maybe.get(this.props.botId)} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  filters: state.main.filters,
  noMessagesData: false,
  noDelaysData: false,
  botId: bot.selectors.getSelectedId(state),
  unsatisfactionData: getSafe(() => state.analytics.satisfaction.dataUnsat),
  escaladesEnd: getSafe(() => state.analytics.escaladesEnd.data),
  escaladesBegin: getSafe(() => state.analytics.escaladesBegin.data),
  messagesMisunderstood: getSafe(() => state.analytics.misunderstood),
  fetchingMisunderstood: getSafe(() => state.analytics.misunderstood.fetching),
  nothandledData: getSafe(() => state.analytics.nothandled.data),
  period: ['minutes', 'hours', 'days'][state.main.filters.periods.active],
  noIntentsData: false
})

export default connect(mapStateToProps, operations)(UnderstandingContainer)
