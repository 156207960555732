import { remoteData } from '@sbizeul/fp-flow'
import { lensPath, over, set, compose } from 'ramda'

import * as actions from './actions'

export const initialState = {
  byKeywordId: {}
}

const byKeywordId = lensPath(['byKeywordId'])

const fetchSynonymsRequest = (state, action) => {
  const path = compose(byKeywordId, lensPath([action.payload]))
  return over(path, remoteData.loading, state)
}

const fetchSynonymsSuccess = (state, action) => {
  const path = compose(byKeywordId, lensPath([action.payload.keywordId]))
  return set(path, remoteData.success(action.payload.synonyms), state)
}

const fetchSynonymsFailure = (state, action) => {
  const path = compose(byKeywordId, lensPath([action.payload.keywordId]))
  return set(path, remoteData.failure(action.payload.error), state)
}

export default function(state = initialState, action) {
  switch (action.type) {
  case actions.FETCH_SYNONYMS_REQUEST:
    return fetchSynonymsRequest(state, action)
  case actions.FETCH_SYNONYMS_SUCCESS:
    return fetchSynonymsSuccess(state, action)
  case actions.FETCH_SYNONYMS_FAILURE:
    return fetchSynonymsFailure(state, action)
  default:
    return state
  }
}
