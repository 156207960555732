import { createSelector } from 'reselect'
import { remoteData, RemoteData } from '@sbizeul/fp-flow'

import * as map from 'helpers/map'
import { ServerFailure } from 'types'

import { AppState, Question } from './types'

const getEntitiesState = (state: AppState) => state.entities.question

export const getAll: (
  state: AppState
) => RemoteData<
  ServerFailure,
  ReadonlyArray<Question>
> = createSelector(getEntitiesState, (state) =>
  remoteData.map(map.toArray, state.all)
)

export const getLabeledQuestions = createSelector(
  getEntitiesState,
  (state) => state.allLabeledQuestions
)

export const getQuestionReferences = createSelector(
  getEntitiesState,
  (state) => state.questionReferences
)

const getUiState = (state: AppState) => state.ui.question

export const getIsPosting = createSelector(
  getUiState,
  (state) => state.isPosting
)
