import React, { FunctionComponent } from 'react'
import Button from 'components/Button'
import { MdSave } from 'react-icons/md'

import './styles.css'

type Props = Readonly<{
  canBeSaved: boolean
  save: () => unknown
}>

const SaveButton: FunctionComponent<Props> = ({ canBeSaved, save }) => (
  <Button onClick={save} className='primary SaveButton' disabled={!canBeSaved}>
    <MdSave className='SaveButton-icon' size='20' />
        SAVE
  </Button>
)

export default SaveButton
