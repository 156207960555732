import styled from 'styled-components'
import OutlinedUIStyled from '../OutlinedUI/styles'

const OutlinedUIWithIndicatorStyled = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  > ${OutlinedUIStyled}, > div {
    max-width: 55%;
    flex: 1;
  }
  > div ${OutlinedUIStyled} {
    max-width: 100%;
  }
  span,
  > div span {
    flex-grow: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &:not(:first-of-type) {
    margin-top: ${({ theme }) => theme.margin.top.uIWithindicator}px;
  }

  &:hover {
    button {
      pointer-events: auto;
      cursor: pointer;
      opacity: 1 !important;
      visibility: visible !important;
    }
  }
`

export default OutlinedUIWithIndicatorStyled
