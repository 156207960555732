import { connect } from 'react-redux'

import * as actions from '../actions'
import * as selectors from '../selectors'
import WebchatOverview from '../components/WebchatOverview'
import { AppState } from '../types'

const mapStateToProps = (state: AppState) => ({
  webchatStats: selectors.getWebchatStats(state),
  startDate: selectors.getStartDate(state),
  endDate: selectors.getEndDate(state)
})

const mapDispatchToProps = {
  fetchWebchatStats: actions.fetchWebchatstatsRequest,
  onDatesChange: actions.changeDates
}

const WebchatOverviewContainer = connect(mapStateToProps, mapDispatchToProps)(WebchatOverview)

export default WebchatOverviewContainer
