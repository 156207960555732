import styled from 'styled-components'

export const VideoStyled = styled.video`
  position: absolute;
  width: 100%;
  height: 100%;
  margin-top: 10px;
  margin-bottom: -10px;
  margin-left: -10px;
  overflow: hidden;
`
