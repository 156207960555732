import types from './types'

/* fetch */
const getDefectCreateRequest = () => ({
  type: types.GET_DEFECTCREATE_REQUEST
})
const getDefectCreateSuccess = data => ({
  type: types.GET_DEFECTCREATE_SUCCESS,
  payload: data
})
const getDefectCreateFailure = errors => ({
  type: types.GET_DEFECTCREATE_FAILURE,
  errors
})

/* fetch */
const getDefectSettleRequest = () => ({
  type: types.GET_DEFECTSETTLE_REQUEST
})
const getDefectSettleSuccess = data => ({
  type: types.GET_DEFECTSETTLE_SUCCESS,
  payload: data
})
const getDefectSettleFailure = errors => ({
  type: types.GET_DEFECTSETTLE_FAILURE,
  errors
})

/* fetch */
const getPrisePosteRequest = () => ({
  type: types.GET_PRISEPOSTE_REQUEST
})
const getPrisePosteSuccess = data => ({
  type: types.GET_PRISEPOSTE_SUCCESS,
  payload: data
})
const getPrisePosteFailure = errors => ({
  type: types.GET_PRISEPOSTE_FAILURE,
  errors
})

/* fetch */
const getSortiePosteRequest = () => ({
  type: types.GET_SORTIEPOSTE_REQUEST
})
const getSortiePosteSuccess = data => ({
  type: types.GET_SORTIEPOSTE_SUCCESS,
  payload: data
})
const getSortiePosteFailure = errors => ({
  type: types.GET_SORTIEPOSTE_FAILURE,
  errors
})

/* fetch */
const getAndonRequest = () => ({
  type: types.GET_ANDON_REQUEST
})
const getAndonSuccess = data => ({
  type: types.GET_ANDON_SUCCESS,
  payload: data
})
const getAndonFailure = errors => ({
  type: types.GET_ANDON_FAILURE,
  errors
})

/* fetch */
const getCommentairesRequest = () => ({
  type: types.GET_COMMENTAIRES_REQUEST
})
const getCommentairesSuccess = data => ({
  type: types.GET_COMMENTAIRES_SUCCESS,
  payload: data
})
const getCommentairesFailure = errors => ({
  type: types.GET_COMMENTAIRES_FAILURE,
  errors
})

export default {
  getDefectCreateRequest,
  getDefectCreateSuccess,
  getDefectCreateFailure,

  getDefectSettleRequest,
  getDefectSettleSuccess,
  getDefectSettleFailure,

  getPrisePosteRequest,
  getPrisePosteSuccess,
  getPrisePosteFailure,

  getSortiePosteRequest,
  getSortiePosteSuccess,
  getSortiePosteFailure,

  getAndonRequest,
  getAndonSuccess,
  getAndonFailure,

  getCommentairesRequest,
  getCommentairesSuccess,
  getCommentairesFailure
}
