import { call, put, takeLatest, select, takeEvery } from 'redux-saga/effects'
import { maybe } from '@sbizeul/fp-flow'
import { push } from 'connected-react-router'

import Api from 'services/tolkApiLegacy'

import * as intentActions from './actions'
import bot from '../bot'

import { matchSelector } from './selectors'

export function* fetchAll() {
  const botId = yield select(bot.selectors.getSelectedId)
  try {
    const { data } = yield call(Api.get, `/contents/intents/${maybe.get(botId)}`)
    yield put(intentActions.fetchAllSuccess(data))
  } catch (error) {
    yield put(intentActions.fetchAllFailure(error.response.data))
  }
}

export function* fetchOne(action) {
  const botId = yield select(bot.selectors.getSelectedId)
  try {
    const { data } = yield call(Api.get, `/contents/intents/${maybe.get(botId)}/${action.payload}`)
    yield put(intentActions.fetchOneSuccess(data))
  } catch (error) {
    yield put(intentActions.fetchOneFailure(error.response.data, action.payload))
  }
}

export function* create(action) {
  const botId = yield select(bot.selectors.getSelectedId)
  try {
    const { data } = yield call(Api.post, `/contents/intents/${maybe.get(botId)}`, action.payload)
    yield put(intentActions.createSuccess(data.results))
    yield put(push(`/intents/edit/${data.results.slug}`))
  } catch (error) {
    yield put(intentActions.createFailure(error.response.data))
  }
}

export function* importFile(action) {
  const botId = yield select(bot.selectors.getSelectedId)
  try {
    const data = new FormData()
    data.append('file', action.payload[0])
    const headers = { 'content-type': 'application/x-www-form-urlencoded' }
    yield call(Api.post, `/post/${maybe.get(botId)}`, { data, headers })
    yield put(intentActions.importFileSuccess())
  } catch (error) {
    yield put(intentActions.importFileFailure(error.response.data))
  }
}

export function* selectBot() {
  const route = yield select(matchSelector)
  if (route !== null) {
    yield put(intentActions.fetchAll())
  }
}

export function* root() {
  yield takeLatest(intentActions.FETCH_ONE_REQUEST, fetchOne)
  yield takeLatest(intentActions.FETCH_ALL_REQUEST, fetchAll)
  yield takeEvery(intentActions.CREATE_REQUEST, create)
  yield takeEvery(intentActions.IMPORT_REQUEST, importFile)
  yield takeEvery(intentActions.CREATE_SUCCESS, fetchAll)
  yield takeEvery(intentActions.IMPORT_SUCCESS, fetchAll)
  yield takeLatest(bot.actions.SELECT, selectBot)
}
