import * as actions from './actions'
import * as selectors from './selectors'
import reducer, { initialState } from './reducer'
import * as saga from './saga'
import UserForm from './components/UserForm'
import UserFormContainer from './containers/UserFormContainer'

export default {
  actions,
  selectors,
  reducer,
  initialState,
  saga,
  components: {
    UserForm,
    UserFormContainer
  }
}
