import moment from 'moment'

type FocusedInput = 'startDate' | 'endDate'

export const isOutOfRange: (limit: number) => ({ startDate, endDate, focusedInput, day }: { startDate: moment.Moment, endDate: moment.Moment, focusedInput?: FocusedInput, day: moment.Moment }) => boolean =
  limit => ({ startDate, endDate, focusedInput, day }) => {
    switch (focusedInput) {
      case 'startDate':
        return day.isSameOrBefore(moment(endDate).subtract(limit, 'day'), 'day') || day.isAfter(moment(), 'day')
      case 'endDate':
        return day.isSameOrAfter(moment(startDate).add(limit, 'day'), 'day') || day.isAfter(moment(), 'day')
      default:
        return false
    }
  }

export const toTimeStamp = (date: moment.Moment) => moment.utc(moment(date)).unix()

export const sortMomentAsc = (left: moment.Moment, right: moment.Moment) => toTimeStamp(left) - toTimeStamp(right)

export const sortMomentDesc = (left: moment.Moment, right: moment.Moment) => toTimeStamp(right) - toTimeStamp(left)

export const toShortFormat = (date: moment.Moment) => date.format('YYYY-MM-DD')

// using REFERENCE for the ability to test with a fixed date. Should be `moment()` in production
export const TODAY = moment()
  .clone()
  .startOf('day')
export const TOMORROW = moment()
  .clone()
  .add(1, 'days')
  .startOf('day')

export const isToday = (date: moment.Moment) =>
  date
    .clone()
    .startOf('day')
    .isSame(TODAY, 'd')
export const isTomorrow = (date: moment.Moment) =>
  date
    .clone()
    .startOf('day')
    .isSame(TOMORROW, 'd')

export const fusionDateTime = (date: moment.Moment, time: moment.Moment) =>
  moment(date.clone().format('YYYY-MM-DD') + 'T' + time.clone().format('HH:mm'))

export const convertTimestring12To24 = (time: string) => moment(time, 'LT').format('HH:mm')

export const concatenateDateAndTimestring = (date: moment.Moment, time: string) =>
  moment(date.clone().format('YYYY-MM-DD') + 'T' + time)

const formatFromString = (date: string | number | Date) =>
  Intl.DateTimeFormat('fr-FR', { month: 'numeric', day: 'numeric' }).format(new Date(date))

export const toDateFormat = (date?: string | number | Date) => date
  ? moment(date).isValid() ? formatFromString(date) : date.toString()
  : ''

export const fromUtcToLocal: (utcDate: string, format?: string) => string =
  (utcDate, format = 'L LT') => moment.utc(utcDate).local().format(format)
