import * as React from 'react'
import { FaTrashAlt } from 'react-icons/fa'
import TrashStyled from './styles'

const Trash = ({ notCentered, selected, relative, inside, onTrashClick, showOnHover, showWhenSelected, size }) => (
  <TrashStyled
    notCentered={notCentered}
    relative={relative}
    showOnHover={showOnHover}
    showWhenSelected={showWhenSelected}
    selected={selected}
    inside={inside}
    size={size}
    onClick={onTrashClick}
  >
    <FaTrashAlt />
  </TrashStyled>
)

Trash.defaultProps = {
  size: 16,
  showOnHover: false,
  showWhenSelected: false,
  notCentered: false,
  relative: false
}

export default Trash
