import React, { Component, Context, useContext } from 'react'
import { RouteComponentProps, StaticContext } from 'react-router'
import { withRouter } from 'react-router-dom'

import {
  Key,
  ScrollManager as ScrollManagerT,
  WithScrollManagerAndLocationProps,
  WithScrollManagerProps
} from './types'
import { scrollManager } from './models'

type ScrollProps = RouteComponentProps<{}, StaticContext, Key> & {
  elementID: string
}

const ScrollManagerContext: Context<ScrollManagerT> = React.createContext(scrollManager('root'))

class ScrollManager extends Component<ScrollProps> {
  url: Map<string, number>
  scroll: ScrollManagerT

  constructor (props: ScrollProps) {
    super(props)
    this.detectPop = this.detectPop.bind(this)
    this.url = new Map()
    this.scroll = scrollManager(props.elementID)
  }

  componentDidMount (): void {
    window.addEventListener('popstate', this.detectPop)
  }

  componentWillUnmount (): void {
    window.removeEventListener('popstate', this.detectPop)
  }

  shouldComponentUpdate (nextProps: ScrollProps): boolean {
    this.scroll.registerScroll(this.props.location)(nextProps.location)
    return false
  }

  detectPop (): void {
    this.scroll.scrollFor(this.props.location)
  }

  render () {
    return (
      <ScrollManagerContext.Provider value={this.scroll}>
        {this.props.children}
      </ScrollManagerContext.Provider>
    )
  }
}

function addScrollManager<P extends WithScrollManagerProps = WithScrollManagerProps> (
  WrappedComponent: React.ComponentType<P>
) {
  return function ManagedComponent (props: P) {
    const scrollManager = useContext(ScrollManagerContext)
    return (
      <WrappedComponent {...props} scrollManager={scrollManager}/>
    )
  }
}

function withScrollManager<P extends WithScrollManagerAndLocationProps = WithScrollManagerAndLocationProps> (
  WrappedComponent: React.ComponentType<P>
) {
  return withRouter(addScrollManager(WrappedComponent))
}

export {
  withScrollManager
}

export default withRouter(ScrollManager)
