import * as React from 'react'
import classnames from 'classnames'
import { MdTextFields, MdImage, MdInsertLink, MdLabel, MdQuestionAnswer, MdViewCarousel } from 'react-icons/md'
import HubIcon from 'components/HubIcon/HubIcon'

import './style.css'

function CreateContentMenuItem(props) {
  return (
    <li
      className={classnames({ disabled: !props.enabled }, 'CreateContentMenu-box')}
      onClick={() => props.enabled && props.createContent(props.languages[0])}
    >
      {renderIcon(props.itemIcon)}
      <span className='CreateContentMenu-text'>{props.itemText}</span>
    </li>
  )
}

function renderIcon(IconComponent) {
  return <IconComponent className='CreateContentMenu-icon' />
}

function CreateContentMenu(props) {
  return (
    <div className='CreateContentMenu'>
      <h1 className='CreateContentMenu-title'>Add Element</h1>
      <ul>
        {props.menuItems.map(menuItem => (
          <CreateContentMenuItem key={menuItem.itemText} {...menuItem} />
        ))}
      </ul>
    </div>
  )
}

const buttons = (props) => [
  {
    createContent: props.createTextContent,
    itemText: 'Text',
    itemIcon: MdTextFields,
    enabled: props.canCreateSimple,
    languages: props.languages,
  },
  {
    createContent: props.createButtonContent,
    itemText: 'Buttons',
    itemIcon: MdInsertLink,
    enabled: props.canCreateSimple,
    languages: props.languages,
  },
  {
    createContent: props.createCarouselContent,
    itemText: 'Carousel',
    itemIcon: MdViewCarousel,
    enabled: props.canCreateSimple,
    languages: props.languages,
  },
  {
    createContent: props.createImageContent,
    itemText: 'Picture',
    itemIcon: MdImage,
    enabled: props.canCreateSimple,
    languages: props.languages,
  },
  ...(props.createSkillContent
    ? [
        {
          createContent: props.createSkillContent,
          itemText: 'Skill',
          itemIcon: HubIcon,
          enabled: props.canCreateSimple,
          languages: props.languages,
        },
      ]
    : []),
  ...(props.createQRContent
    ? [
        {
          createContent: props.createQRContent,
          itemText: 'Question',
          itemIcon: MdQuestionAnswer,
          enabled: props.canCreateQR,
          languages: props.languages,
        },
      ]
    : []),
    {
      createContent: props.createTagAgentContent,
      itemText: 'Agent Label',
      itemIcon: MdLabel,
      enabled: props.canCreateSimple,
      languages: props.languages,
    }
]

export default function(props) {
  return <CreateContentMenu menuItems={buttons(props)} />
}
