import React from 'react'
import ReactHighcharts from 'react-highcharts'
import defaultStyle from '../chartStyle'

const hours = []
for (let i = 0; i < 24; i += 1) {
  hours.push(i)
}

const config = {
  ...defaultStyle,
  chart: {
    type: 'column'
  },
  yAxis: [
    {
      title: {
        text: 'Messages'
      }
    },
    {
      title: {
        text: 'Users'
      },
      opposite: true
    }
  ],
  xAxis: {
    categories: hours,
    crosshair: true
  }
}

export default function DataGroupedByHour({ messages = null, conversations = null, users = null }) {
  const series = []

  if (messages) {
    series.push({
      animation: false,
      name: 'Messages',
      data: messages.map(elem => [elem.hour, elem.count])
    })
  }

  if (conversations) {
    series.push({
      animation: false,
      name: 'Conversations',
      data: conversations.map(elem => [elem.hour, elem.count])
    })
  }

  if (users) {
    series.push({
      type: 'spline',
      yAxis: 1,
      animation: false,
      name: 'Users',
      data: users.map(elem => [elem.hour, elem.count])
    })
  }

  return <ReactHighcharts config={{ ...config, series }} domProps={{ id: 'DataGroupedByHour' }} />
}
