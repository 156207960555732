import axios from 'axios'
import { maybe } from '@sbizeul/fp-flow'

import actions from '../actions'
import isResValid from '../../../_shared/isResValid'
import formatFilters from '../../../_shared/formatFilters'
import bot from '../../../../modules/bot'
import { legacyApiUrl } from 'helpers/effects'

const fetchRetention = rawFilters => (dispatch, getState) => {
  const { channelId, start, end, period } = formatFilters(rawFilters)
  const botId = bot.selectors.getSelectedId(getState())

  dispatch(actions.fetchPerformanceRetentionRequest())
  return axios({
    url: `${legacyApiUrl}/analytics/${maybe.get(botId)}/${channelId}/retention`,
    params: { start, end, period },
    method: 'get',
    responseType: 'json'
  })
    .then(response => {
      if (isResValid(response)) {
        const formatedResponse = {
          data: response.data.result.data,
          count: response.data.result.count
        }
        dispatch(actions.fetchPerformanceRetentionSuccess(formatedResponse))
      } else {
        dispatch(actions.fetchPerformanceRetentionFailure())
      }
    })
    .catch(err => {
      dispatch(actions.fetchPerformanceRetentionFailure(err))
    })
}

export default fetchRetention
