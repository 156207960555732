import React from 'react'
import { Editor, EditorState, ContentState } from 'draft-js'
import { urlIsValid } from '../../helpers'
import ElementTextContainer, { CurrentLengthContainer } from './styles'

const getTextFromEditorState = editorState => editorState.getCurrentContent().getPlainText('\n')

const createEditorStateFromText = text => EditorState.createWithContent(ContentState.createFromText(text))

class ElementText extends React.Component {
  state = {
    editorState: createEditorStateFromText(this.props.text),
    computedFontSize: 0,
    inputHeight: null,
    error: false
  }

  componentDidMount() {
    if (this.container) this.updateLayout()
    if (this.props.rootElementText && !this.props.text) {
      this.setState(({ editorState }) => ({ editorState: EditorState.moveFocusToEnd(editorState) }))
    }
  }

  componentDidUpdate() {
    if (this.container) this.updateLayout()
  }

  updateLayout = () => {
    // we need to get the computed font size to modify the height of the component accordingly
    // cf ElementTextContainer in ../styles
    const newComputedFontSize = Math.ceil(window.getComputedStyle(this.container).fontSize.replace('px', ''))

    const newInputHeight = this.container.scrollHeight
    const { computedFontSize, inputHeight } = this.state
    const fontSizeChanged = newComputedFontSize !== computedFontSize
    const inputHeightChanged = newInputHeight !== inputHeight
    if (fontSizeChanged || inputHeightChanged) {
      this.setState({
        computedFontSize: newComputedFontSize,
        inputHeight: newInputHeight
      })
    }
  }

  handleBeforeInput = () => {
    const { maxLength } = this.props
    const currentlength = getTextFromEditorState(this.state.editorState).length
    const tooLong = maxLength && currentlength >= maxLength

    if (tooLong) {
      return 'handled'
    }
  }

  handleEditorChange = editorState => {
    const { text, changeText } = this.props

    // send the new plain text
    const newText = getTextFromEditorState(editorState).trim()
    const changed = newText !== text

    this.setState(
      ({ error }) => ({
        editorState, // internal state
        error: changed ? false : error
      }),
      () => {
        if (changed) changeText(newText) // redux state
      }
    )
  }

  handleBlur = () => {
    const { type, text, isUrl } = this.props
    if (!text) {
      this.setState({
        error: isUrl ? `Your ${type} is empty. By the way, it needs to start with https://` : `Your ${type} is empty`
      })
      return
    }
    if (isUrl) {
      if (!urlIsValid(text)) {
        this.setState({ error: `Your ${type} needs to start with https://` })
      }
    }
  }

  render() {
    const { text, forCarousel, maxLength, placeholder, rootElementText, canEdit = true } = this.props
    const { editorState, computedFontSize, inputHeight, error } = this.state
    return (
      <ElementTextContainer
        withMaxLength={maxLength > 0}
        forCarousel={forCarousel}
        computedFontSize={computedFontSize}
        ref={c => (this.container = c)}
        inputHeight={inputHeight}
        minLength={placeholder.length}
        letters={text.length || 0}
        error={error}
        rootElementText={rootElementText}
      >
        <Editor
          ref={e => (this.editor = e)}
          editorState={editorState}
          handleBeforeInput={this.handleBeforeInput}
          onChange={this.handleEditorChange}
          placeholder={placeholder}
          onBlur={this.handleBlur}
          readOnly={!canEdit}
        />
        {maxLength && <CurrentLengthContainer>{maxLength - text.length}</CurrentLengthContainer>}
      </ElementTextContainer>
    )
  }
}

export default ElementText
