import { maybe } from '@sbizeul/fp-flow'

import get from '../../_shared/get'
import formatFilters from '../../_shared/formatFilters'
import actions from './actions'
import bot from '../../../modules/bot'

const fetchReferral = rawFilters => {
  const { channelId, start, end, period } = formatFilters(rawFilters)

  return async (dispatch, getState) => {
    const botId = bot.selectors.getSelectedId(getState())
    dispatch(actions.fetchReferralRequest())
    try {
      const response = await get(`analytics/${maybe.get(botId)}/${channelId}/events/facebook_messaging/referal`, {
        start,
        end,
        period
      })
      dispatch(actions.fetchReferralSuccess(response))
    } catch (e) {
      dispatch(actions.fetchReferralFailure(e))
    }
  }
}

const fetchReferralUtil = rawFilters => {
  const { channelId, start, end, period } = formatFilters(rawFilters)

  return async (dispatch, getState) => {
    const botId = bot.selectors.getSelectedId(getState())
    dispatch(actions.fetchReferralUtilRequest())
    try {
      const response = await get(`analytics/${maybe.get(botId)}/${channelId}/events/facebook_messaging`, {
        start,
        end,
        period
      })
      dispatch(actions.fetchReferralUtilSuccess(response))
    } catch (e) {
      dispatch(actions.fetchReferralUtilFailure(e))
    }
  }
}

export default { fetchReferral, fetchReferralUtil }
