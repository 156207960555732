import React from 'react'
import ReactHighcharts from 'react-highcharts'
import moment from 'moment'
import defaultStyle from '../chartStyle'

const getConfig = averageUsersTotal => ({
  ...defaultStyle,
  chart: {
    type: 'areaspline'
  },
  yAxis: [
    {
      className: 'highcharts-color-7',
      title: {
        text: 'Users'
      },
      plotLines: [
        {
          color: '#DC4576',
          dashStyle: 'Dash',
          width: 2,
          value: averageUsersTotal,
          label: {
            text: 'Daily Average Users',
            align: 'left',
            x: +16,
            style: {
              color: '#DC4576',
              fontWeight: 'bold'
            }
          },
          zIndex: 5
        }
      ]
    }
  ],
  xAxis: {
    type: 'datetime'
  },
  tooltip: {
    split: true
  }
})

export default function AllUsers({ allUsers, newUsers, totalUsers, period }) {
  const series = [
    {
      animation: false,
      name: 'New Users',
      data: newUsers.map(elem => [moment.utc(elem.time).valueOf(), elem.total])
    },
    {
      animation: false,
      name: 'Returning Users',
      data:
        newUsers.length === allUsers.length
          ? allUsers.map((elem, i) => [moment.utc(elem.time).valueOf(), elem.total - newUsers[i].total])
          : allUsers.map((elem, i) => [moment.utc(elem.time).valueOf(), elem.total])
    }
  ]
  if (series.length === 0) {
    getConfig.noData = {
      attr: 'sdf.svg'
    }
  }
  const averageUsersTotal = Math.round(totalUsers / allUsers.length)
  return (
    <ReactHighcharts config={{ ...getConfig(averageUsersTotal), series }} domProps={{ id: 'AllUsersHighcharts' }} />
  )
}
