import types from './types'

const initialState = {
  fetching: false,
  fetchingPreviousPeriod: false,
  fetchingCountByHour: false,
  conversationsByDay: []
}

const conversationsReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.FETCH_CONVERSATIONS_REQUEST:
    return {
      ...state,
      fetching: true,
      data: null,
      count: null
    }
  case types.FETCH_CONVERSATIONS_NO_DATA:
    return {
      ...state,
      fetching: false
    }
  case types.FETCH_CONVERSATIONS_SUCCESS:
    return {
      ...state,
      fetching: false,
      data: action.payload.data,
      count: action.payload.totalCount
    }

  case types.FETCH_CONVERSATIONS_PREVIOUS_PERIOD_REQUEST:
    return { ...state, fetchingPreviousPeriod: true, countPreviousPeriod: null }
  case types.FETCH_CONVERSATIONS_PREVIOUS_PERIOD_NO_DATA:
    return {
      ...state,
      fetchingPreviousPeriod: false
    }
  case types.FETCH_CONVERSATIONS_PREVIOUS_PERIOD_SUCCESS:
    return {
      ...state,
      fetchingPreviousPeriod: false,
      countPreviousPeriod: action.payload.count
    }

  case types.FETCH_CONVERSATIONS_COUNT_BY_HOUR_REQUEST:
    return {
      ...state,
      fetchingCountByHour: true,
      countByDay: null,
      countByHour: null,
      countByHourByDay: null
    }
  case types.FETCH_CONVERSATIONS_COUNT_BY_HOUR_NO_DATA:
    return {
      ...state,
      fetchingCountByHour: false
    }
  case types.FETCH_CONVERSATIONS_COUNT_BY_HOUR_SUCCESS:
    return {
      ...state,
      fetchingCountByHour: false,
      countByDay: action.payload.conversationsCountByDay,
      countByHour: action.payload.conversationsCountByHour,
      countByHourByDay: action.payload.conversationsCountByHourByDay
    }
  case types.FETCH_CONVERSATIONS_COUNT_BY_DAY_REQUEST:
    return {
      ...state,
      fetchingCountByDay: true,
      countByDay: null
    }
  case types.FETCH_CONVERSATIONS_COUNT_BY_DAY_NO_DATA:
    return {
      ...state,
      fetchingCountByDay: false
    }
  case types.FETCH_CONVERSATIONS_COUNT_BY_DAY_SUCCESS:
    return {
      ...state,
      fetchingCountByDay: false,
      conversationsByDay: action.payload
    }
  default:
    return state
  }
}

export default conversationsReducer
