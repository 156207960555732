import 'react-dates/initialize'
import React from 'react'
import ExportData from 'highcharts-export-data'
import highchartsSankey from 'highcharts-sankey'
import HighchartsExporting from 'highcharts-exporting'
import { Route, Switch } from 'react-router-dom'
import ReactHighcharts from 'react-highcharts'

import EventsAnalytics from './Events'
import Traffic from './Traffic'
import Trends from './Trends'
import Performance from './Performance'

highchartsSankey(ReactHighcharts.Highcharts)
HighchartsExporting(ReactHighcharts.Highcharts)
ExportData(ReactHighcharts.Highcharts)

const AnalyticsPage = () => (
  <div className="AnalyticsPage pageContainer">
    <Switch>
      <Route path="/analytics/traffic" component={Traffic} />
      <Route path="/analytics/trends" component={Trends} />
      <Route path="/analytics/performance" component={Performance} />
      <Route path="/analytics/events" component={EventsAnalytics} />
    </Switch>
  </div>
)

export default AnalyticsPage
