import React, { FunctionComponent } from 'react'
import { maybe, Maybe, RemoteData, remoteData } from '@sbizeul/fp-flow'
import SaveButton from 'components/SaveButton'
import BackButton from 'components/BackButton'
import * as webchatActiveStateUpdater from './WebchatActiveStateUpdater'
import { isValid } from 'modules/bot/models.typed'
import { renderFailure, renderLoader, useEffectOnce } from 'helpers/react'
import { ServerFailure } from 'types'
import { Webchat } from 'modules/bot/types'
import { UploadImageRequestPayload } from 'modules/answer/types'

import WebchatUpdater from '../WebchatUpdater'
import Script from '../Script'

import './styles.css'

export type Props = Readonly<{
  isModified: boolean
  webchat: RemoteData<ServerFailure, Webchat>
  webchatUrl: Maybe<string>

  fetchWebchat: () => unknown
  uploadImage: (image: UploadImageRequestPayload) => unknown
  updateWebchat: (webchat: Webchat) => unknown
  saveWebchat: (webchat: Webchat) => unknown
  cleanWebchat: () => unknown
}>

type PropsWithoutMonadic = Readonly<{
  isModified: boolean
  webchat: Webchat
  webchatUrl: Maybe<string>

  uploadImage: (image: UploadImageRequestPayload) => unknown
  updateWebchat: (webchat: Webchat) => unknown
  saveWebchat: (webchat: Webchat) => unknown
}>

const WebchatConfig: FunctionComponent<PropsWithoutMonadic> = ({
  isModified,
  saveWebchat,
  webchat,
  webchatUrl,
  updateWebchat,
  uploadImage
}) => {
  return (
    <>
      <div className="WebchatConfig">
        <div className="WebchatConfig--container">
          <div className="WebchatConfig--title-container">
            <div className="WebchatConfig--title-container-left-content">
              <h1 className="WebchatConfig--title">
                <BackButton label="Webchat" />
              </h1>
              <webchatActiveStateUpdater.Component
                webchat={webchat}
                isModified={isModified}
                updateWebchat={updateWebchat}
              />
            </div>
            <SaveButton
              save={() => {
                saveWebchat(webchat)
              }}
              canBeSaved={isValid(webchat) && isModified}
            />
          </div>
          <WebchatUpdater
            isModified={isModified}
            webchat={webchat}
            uploadImage={uploadImage}
            updateWebchat={updateWebchat}
          />
          {maybe.fold<string, JSX.Element>(
            () =>
              renderFailure(
                'Please refresh the page, if the problem persists, contact your administrator.'
              ),
            (url) => <Script url={url} />
          )(webchatUrl)}
        </div>
      </div>
    </>
  )
}

const WebchatConfigHandler: FunctionComponent<Props> = ({
  isModified,
  saveWebchat,
  cleanWebchat,
  webchat,
  webchatUrl,
  fetchWebchat,
  uploadImage,
  updateWebchat
}) => {
  useEffectOnce(() => {
    fetchWebchat()
    return () => {
      cleanWebchat()
    }
  })
  return remoteData.fold({
    Failure: renderFailure,
    Loading: () => renderLoader('please wait...'),
    NotAsked: () => renderLoader('please wait...'),
    Success: (webchat: Webchat) => (
      <WebchatConfig
        isModified={isModified}
        webchat={webchat}
        webchatUrl={webchatUrl}
        updateWebchat={updateWebchat}
        uploadImage={uploadImage}
        saveWebchat={saveWebchat}
      />
    )
  })(webchat)
}
export default WebchatConfigHandler
