import React from 'react'
import { FaSave } from 'react-icons/fa'
import * as O from 'fp-ts/lib/Option'

import Button from 'components/Button'
import BackButton from 'components/BackButton'

import { AppField, AppInformation } from '../../types'

type SettingsFormProps = Readonly<{
  name: string
  appId: string
  fields: ReadonlyArray<AppField>
  appInformation: O.Option<AppInformation>
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => unknown
}>

const SettingsForm: React.FunctionComponent<SettingsFormProps> = ({
  name,
  appId,
  fields,
  appInformation,
  onSubmit
}) => (
  <form onSubmit={onSubmit}>
    <header className="appsettings__header">
      <BackButton label={`${name} Settings`} />
      <Button type="submit" className="rounded primary">
        <FaSave />
        &nbsp;Save
      </Button>
    </header>
    <div className="appsettings__content">
      <h1 className="appsettings__title">Set your app</h1>
      <h2 className="appsettings__subtitle">
        Complete this form to connect your assistant to {name}. If you need
        guidance, consider{' '}
        <a
          className="appsettings__subtitlelink"
          href="https://academy.tolk.ai/kb/fr"
          target="_blank"
          rel="noopener noreferrer"
        >
          visit our help center
        </a>
        .
      </h2>
      {fields.map((fieldObject, index) => (
        <p key={index} className="appsettings__inputrow">
          <label htmlFor={fieldObject.name} className="appsettings__inputlabel">
            {fieldObject.title}
          </label>
          <input
            className="appsettings__input"
            type="text"
            id={fieldObject.name}
            name={fieldObject.name}
            datatype={fieldObject.datatype}
            placeholder={fieldObject.placeholder}
            defaultValue={O.fold(
              () => '',
              (appInfo: AppInformation) =>
                appInfo.config.find(
                  (configObject) => configObject.key === fieldObject.name
                )?.value || ''
            )(appInformation)}
          />
        </p>
      ))}
    </div>
  </form>
)

export { SettingsForm }
