import React from 'react'
import { Grid, Segment } from 'semantic-ui-react'
import isEmpty from 'lodash/isEmpty'
import CustomTable from '../../components/Table/CustomTable'
import Tiles from '../../components/Tiles'
import text from '../../../../../config/translations/en.json'
import AllIntents from '../../components/AreaCharts/AllIntentsHighcharts'
import MoreInfoPopup from '../../../../components/MoreInfoPopup'
import HumanHandover from '../../components/Spline/HumanHandover'
import Unsatisfaction from '../../components/Spline/Unsatisfaction'

const style = {
  borderRadius: '3px',
  border: 'none',
  boxShadow: '0 2px 4px rgba(50, 50, 93,.1)',
  padding: '5px'
}

const Understanding = ({
  botId,
  messagesMisunderstood,
  fetchingMisunderstood,
  unsatisfactionData,
  escaladesBegin,
  escaladesEnd,
  nothandledData,
  noIntentsData
}) => (
  <div style={{ fontFamily: 'Roboto' }}>
    <br />
    <Grid columns={3}>
      <Grid.Row>
        <Grid.Column mobile={8} tablet={4} computer={4} widescreen={4}>
          {messagesMisunderstood.countAverage ? (
            <Tiles
              name='Handled messages'
              moreInfo={text.performance.messages_understanding}
              value={Math.round(messagesMisunderstood.countAverage.average * 10) / 10}
              suffixe={'%'}
              noData={noIntentsData}
              loading={fetchingMisunderstood}
            />
          ) : null}
        </Grid.Column>
      </Grid.Row>
    </Grid>
    <Grid>
      <Grid.Row>
        <Grid.Column mobile={16} tablet={16} computer={16} widescreen={16}>
          <h1>
            Messages Understanding
            <MoreInfoPopup content={text.performance.messages_understanding} />
          </h1>
          <Segment style={style}>
            {messagesMisunderstood ? <AllIntents misunderstood={messagesMisunderstood.dataAverage} /> : ''}
          </Segment>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column mobile={16} tablet={16} computer={16} widescreen={16}>
          <h1>
            User Unsatisfaction
            <MoreInfoPopup content={text.performance.user_unsatisfaction} />
          </h1>
          <Segment style={style}>
            {unsatisfactionData ? (
              <Unsatisfaction data={unsatisfactionData} noData={isEmpty(unsatisfactionData)} />
            ) : (
              ''
            )}
          </Segment>
        </Grid.Column>
      </Grid.Row>
      {botId === '59f09b39bed87007d10138b8' ||
      botId === '58e787b77b60a536a51a4606' ||
      botId === '5af0101102ed620dc1ba0031' ||
      botId === '5a1be30759281f10d33130a0' ||
      botId === '5a9d2c13940d2a603181dc9a' ||
      botId === '5b6331bdf6c8d77c7ed20496' ||
      botId === '5b682311b75ca705e78b0854' ||
      botId === '59807497203b3933d159beb8' ? (
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={16} widescreen={16}>
              <h1>Human Handover</h1>
              <Segment style={style}>
                <HumanHandover begin={escaladesBegin} end={escaladesEnd} />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        ) : (
          true
        )}
    </Grid>
    <h1>Messages that did not match an intent</h1>
    {nothandledData ? <CustomTable data={nothandledData} header={['sentence', 'total', 'total_users']} /> : ''}
  </div>
)

export default Understanding
