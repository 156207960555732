import { v4 as uuid } from 'uuid'

export const push = (message, options = {}) => ({
  type: 'notifications/PUSH_NOTIFICATION',
  payload: {
    message,
    id: uuid(),
    ...options
  }
})

export const pop = id => ({
  type: 'notifications/POP_NOTIFICATION',
  payload: id
})
