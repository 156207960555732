import * as broadcastActionTypes from 'modules/broadcast/actions'
import * as notificationActions from 'modules/notifications/actions'
import * as broadcastActions from 'modules/broadcast/actions'

export const notificationsMiddleware = store => next => action => {
  switch (action.type) {
  case broadcastActionTypes.BROADCAST_SAVE_CAMPAIGN_SUCCESS:
    store.dispatch(notificationActions.push('Campaign saved'))
    return next(action)
  case broadcastActionTypes.BROADCAST_DELETE_CAMPAIGN_SUCCESS:
    const onCancel = () => store.dispatch(broadcastActions.cancelCampaignDeleteRequest(action.payload))
    store.dispatch(notificationActions.push('Campaign deleted', { onCancel }))
    return next(action)
  case broadcastActionTypes.BROADCAST_SEND_PREVIEW_SUCCESS:
    store.dispatch(notificationActions.push('Preview sent'))
    return next(action)
  default:
    return next(action)
  }
}
