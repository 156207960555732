import { maybe } from '@sbizeul/fp-flow'

import { saveAs } from 'file-saver'
import { call, put, select, takeLatest, takeEvery } from 'redux-saga/effects'

import Api from 'services/tolkApiLegacy'

import { toShortFormat } from 'helpers/moment'

import * as conversationActions from './actions'
import bot from '../bot'
import {
  getCurrentPage,
  getNbResultsPerPage,
  getCurrentLang,
  getStartDate,
  getEndDate,
  matchSelector,
  getSearchValue
} from './selectors'

export function* selectBot() {
  const route = yield select(matchSelector)
  if (route !== null) {
    yield put(conversationActions.firstPage())
    yield put(conversationActions.fetchConversationsRequest())
  }
}

export function* fetch(method, otherParams = {}, overload = {}) {
  const botId = maybe.getOrElse(() => 'unknown', yield select(bot.selectors.getSelectedId))

  const page = yield select(getCurrentPage)
  const nbResultsPerPage = yield select(getNbResultsPerPage)
  const lang = yield select(getCurrentLang)
  const startDate = yield select(getStartDate)
  const endDate = yield select(getEndDate)
  const searchValue = yield select(getSearchValue)

  const params = {
    page,
    limit: nbResultsPerPage,
    start: toShortFormat(startDate),
    end: toShortFormat(endDate),
    lang,
    ...(searchValue && { search: searchValue }),
    ...otherParams
  }
  const { data } = yield call(method, `/bot/${botId}/conversations`, { params, ...overload })
  return data
}

export function* fetchConversations() {
  try {
    const { data } = yield call(fetch, Api.get)
    yield put(conversationActions.fetchConversationsSuccess(data))
  } catch (e) {
    yield put(conversationActions.fetchConversationsFailure(e))
  }
}

export function* downloadCSVConversations() {
  try {
    const data = yield call(fetch, Api.get, { export: 'csv', limit:1000000 }, { responseType: 'blob' })
    yield call(saveAs, data, 'export.csv')
    yield put(conversationActions.downloadCSVSuccess())
  } catch (e) {
    yield put(conversationActions.downloadCSVFailure(e))
  }
}

export function* searchConversations() {
  yield put(conversationActions.firstPage())
  yield put(conversationActions.fetchConversationsRequest())
}

export function* root() {
  yield takeLatest(conversationActions.FETCH_CONVERSATIONS_REQUEST, fetchConversations)
  yield takeEvery(conversationActions.CHANGE_DATES, fetchConversations)
  yield takeLatest(bot.actions.SELECT, selectBot)
  yield takeEvery(conversationActions.NEXT_PAGE, fetchConversations)
  yield takeEvery(conversationActions.PREVIOUS_PAGE, fetchConversations)
  yield takeEvery(conversationActions.CHANGE_NUMBER_CONVERSATIONS_PER_PAGE, fetchConversations)
  yield takeLatest(conversationActions.SEARCH, searchConversations)
  yield takeLatest(conversationActions.DOWNLOAD_CSV_REQUEST, downloadCSVConversations)
}
