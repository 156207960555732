import styled from 'styled-components'
import OutlinedUI from '../OutlinedUI'

const Caption = styled.h3`
  font-family: inherit;
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.white};
  font-weight: 600 !important;
`

const OutlinedUIStyled = styled(OutlinedUI)`
  background: linear-gradient(to right, rgba(106, 55, 197, 1), rgba(109, 223, 155, 1));
  color: ${({ theme }) => theme.color.white};
`

export { Caption, OutlinedUIStyled }
