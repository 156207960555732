import moment from 'moment-timezone'

const rehydrateCampaignsTest = {
  original: [
    {
      id: 1,
      updated: false,
      title: 'Campagne 1',
      elements: [],
      cohorts: [],
      date: '2019-06-03T00:00:00.000Z',
      kpis: {
        sent: 0,
        read: 0,
        targeted: 0
      },
      status: 'draft'
    },
    {
      id: 2,
      updated: false,
      title: 'Campagne 2',
      elements: [],
      cohorts: [],
      date: '2019-06-03T00:00:00.000Z',
      kpis: {
        sent: 0,
        read: 0,
        targeted: 0
      },
      status: 'scheduled'
    }
  ],
  updated: [
    {
      id: 1,
      updated: false,
      title: 'Campagne 1',
      elements: [],
      cohorts: [],
      date: moment('2019-06-03T00:00:00.000Z').tz('Europe/Amsterdam'),
      timeZone: 'Europe/Amsterdam',
      sendNow: true,
      kpis: {
        sent: 0,
        read: 0,
        targeted: 0
      },
      status: 'draft',
      recurrence: 'day'
    },
    {
      id: 2,
      updated: false,
      title: 'Campagne 2',
      elements: [],
      cohorts: [],
      date: moment('2019-06-03T00:00:00.000Z').tz('Europe/Amsterdam'),
      timeZone: 'Europe/Amsterdam',
      sendNow: true,
      kpis: {
        sent: 0,
        read: 0,
        targeted: 0
      },
      status: 'scheduled',
      recurrence: 'day'
    }
  ]
}

export default rehydrateCampaignsTest
