import { Id, ServerFailure } from 'types'
import { Either } from 'fp-ts/lib/Either'
import * as O from 'fp-ts/lib/Option'
import { RemoteData } from '@sbizeul/fp-flow'
import { channelsConfig } from './config'

export const FETCH_CHANNEL_INFORMATION_REQUEST = 'channels/FETCH_CHANNEL_INFORMATION_REQUEST'
export const FETCH_CHANNEL_INFORMATION_SUCCESS = 'channels/FETCH_CHANNEL_INFORMATION_SUCCESS'
export const FETCH_CHANNEL_INFORMATION_FAILURE = 'channels/FETCH_CHANNEL_INFORMATION_FAILURE'

export const SAVE_CHANNEL_INFORMATION_REQUEST = 'channels/SAVE_CHANNEL_INFORMATION_REQUEST'
export const SAVE_CHANNEL_INFORMATION_SUCCESS = 'channels/SAVE_CHANNEL_INFORMATION_SUCCESS'
export const SAVE_CHANNEL_INFORMATION_FAILURE = 'channels/SAVE_CHANNEL_INFORMATION_FAILURE'

export type FetchChannelInformationRequestParam = string
export type FetchChannelInformationSuccessParam = O.Option<ChannelInformation>
export type FetchChannelInformationFailureParam = ServerFailure
export type SaveChannelInformationFailureParam = ServerFailure
export type SaveChannelInformationSuccessParam = string
export type SaveChannelInformationRequestParam = UpdatedChannelInformation

export type UpdatedChannelInformation = Readonly<{
  name: string,
  options: Record<string, string>
}>

export type ChannelInformation = UpdatedChannelInformation & Readonly<{
  botId: Id
}>

export type FetchChannelInformationRequest = Readonly<{
  type: typeof FETCH_CHANNEL_INFORMATION_REQUEST,
  payload: FetchChannelInformationRequestParam
}>

export type FetchChannelInformationSuccess = Readonly<{
  type: typeof FETCH_CHANNEL_INFORMATION_SUCCESS,
  payload: FetchChannelInformationSuccessParam
}>

export type FetchChannelInformationFailure = Readonly<{
  type: typeof FETCH_CHANNEL_INFORMATION_FAILURE,
  payload: FetchChannelInformationFailureParam
}>

export type SaveChannelInformationRequest = Readonly<{
  type: typeof SAVE_CHANNEL_INFORMATION_REQUEST,
  payload: SaveChannelInformationRequestParam
}>

export type SaveChannelInformationSuccess = Readonly<{
  type: typeof SAVE_CHANNEL_INFORMATION_SUCCESS,
  payload: SaveChannelInformationSuccessParam
}>

export type SaveChannelInformationFailure = Readonly<{
  type: typeof SAVE_CHANNEL_INFORMATION_FAILURE,
  payload: SaveChannelInformationFailureParam
}>

export type ChannelsEntityAction =
  | FetchChannelInformationRequest
  | FetchChannelInformationSuccess
  | FetchChannelInformationFailure

export type ChannelsUIAction =
  | SaveChannelInformationRequest
  | SaveChannelInformationSuccess
  | SaveChannelInformationFailure

export type ChannelSettingsEntitiesState = Readonly<{
  channelInfo: RemoteData<FetchChannelInformationFailureParam, FetchChannelInformationSuccessParam>
}>

export type ChannelSettingsUIState = Readonly<{
  saving: Either<ServerFailure, boolean>
}>

export type ChannelSettingsState = Readonly<{
  entities: {
    channels: ChannelSettingsEntitiesState
  },
  ui: {
    channels: ChannelSettingsUIState
  }
}>

export type ChannelField = Readonly<{
  title: string
  name: string
  placeholder: string
}>

export type ChannelSlugs = keyof typeof channelsConfig

export type ChannelSettingsProps = Readonly<{
  channelSlug: ChannelSlugs
  channelName: string
  channelFields: ReadonlyArray<ChannelField>
  saving: Either<ServerFailure, boolean>
  channelInfo: RemoteData<ServerFailure, O.Option<ChannelInformation>>

  fetchChannelInfo: (channelName: string) => unknown
  saveChannelInfo: (channelInfo: UpdatedChannelInformation) => unknown
}>
