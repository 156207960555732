import React, { useState } from 'react'
import { MdLoop } from 'react-icons/md'
import { IoMdArrowDropleft } from 'react-icons/io'

import './styles.css'

type Props = {
  title: string,
  open: boolean,
  children: React.ReactNode
}

const Collapsible = (props: Props) => {
  const [isOpen, setIsOpen] = useState(props.open)

  return <div className='Collapsible-container'>
    <div className='Collapsible-container-header' onClick={() => setIsOpen(previousState => !previousState)}>
      <div className='Collapsible-icon-round'>
        <MdLoop size='20' className='Collapsible-icon-round-loop' />
      </div>
      <p className='Collapsible-container-title'>{props.title}</p>
      <IoMdArrowDropleft size='25' className={`Collapsible-container-arrow-icon ${isOpen && 'open'}`} />
    </div>
    {isOpen &&
      <div className='Collapsible-content-container'>
        {props.children}
      </div>
    }
  </div>
}

export default Collapsible
