import _ from 'lodash'
import React, { Component } from 'react'
import { Table, Icon, Popup, Modal, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { maybe, pipe } from '@sbizeul/fp-flow'

import FilterBar from './FilterBar'
import intentsOperations from '../../../../redux/intents/operations'
import bot from '../../../../modules/bot'
import './intentstable.css'

class IntentsTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      column: null,
      data: props.data,
      direction: null,
      open: false,
      selectedIntent: null
    }
  }

  getBotId() {
    return maybe.getOrElse(() => 'unknownBotId', this.props.botId)
  }

  isRecast() {
    return pipe(
      maybe.map(nlp => nlp === 'recast'),
      maybe.getOrElse(() => false)
    )(this.props.nlpEngine)
  }

  close = () => this.setState({ open: false })

  show = size => () => this.setState({ size, open: true })

  handleClickYes = (botId, intent) => {
    this.props.deleteIntent(botId, intent)
    this.props.data.forEach((elem, k) => {
      if (elem.slug === intent) {
        this.props.data.splice(k, 1)
      }
    })
    this.setState({ data: this.props.data })
    this.close()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.props.data) {
      this.setState({ data: nextProps.data })
    }
  }

  handleSort = clickedColumn => () => {
    const { column, data, direction } = this.state

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        data: _.sortBy(data, [clickedColumn]),
        direction: 'ascending'
      })
      return
    }

    this.setState({
      data: data.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending'
    })
  }

  handleChange = (botId, intent, activated) => () => {
    this.props.toggleIntent(botId, intent, !activated)
    this.props.data.forEach(elem => {
      if (elem.slug === intent) {
        elem.isActive = !elem.isActive
      }
    })
    this.setState({ data: this.props.data })
  }

  handleClickTrash = selectedIntent => {
    this.setState({ selectedIntent, open: true })
  }

  render() {
    const { column, data, direction, open } = this.state

    return (
      <div>
        <Modal size='tiny' open={open} onClose={this.close}>
          <Modal.Header>Warning</Modal.Header>
          <Modal.Content>
            <p>Are you sure you want to delete this intent?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button negative onClick={this.close}>
              No
            </Button>
            <Button
              positive
              icon='checkmark'
              labelPosition='right'
              content='Yes'
              onClick={() => this.handleClickYes(this.getBotId(), this.state.selectedIntent)}
            />
          </Modal.Actions>
        </Modal>
        <Table sortable fixed singleLine>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                style={{ align: 'center' }}
                sorted={column === 'name' ? direction : null}
                onClick={this.handleSort('name')}
              >
                Intent
              </Table.HeaderCell>
              <Table.HeaderCell>Description</Table.HeaderCell>
              {this.isRecast() && (
                <Table.HeaderCell
                  sorted={column === 'expressionsCount' ? direction : null}
                  onClick={this.handleSort('expressionsCount')}
                >
                  Training
                </Table.HeaderCell>
              )}
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.map(({ isActive, expressionsCount, editable, description, name, id, slug }) => (
              <Table.Row key={id}>
                <Table.Cell>
                  <div>
                    {editable ? (
                      <Link className='link-intents-table' to={`/intents/edit/${slug}`}>
                        {name}
                      </Link>
                    ) : (
                      <Link className='link-intents-table' to={`/intents/editr/${slug}`}>
                        {name}
                      </Link>
                    )}
                  </div>
                </Table.Cell>
                <Table.Cell>
                  <div>{description}</div>
                </Table.Cell>
                {this.isRecast() && (
                  <Table.Cell>
                    <div>
                      <FilterBar data={expressionsCount} />
                    </div>
                  </Table.Cell>
                )}
                <Table.Cell>
                  {editable ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      {this.isRecast() && (
                        <Popup
                          trigger={
                            <div className='ui toggle checkbox'>
                              <input
                                type='checkbox'
                                name='public'
                                checked={isActive}
                                onClick={this.handleChange(this.getBotId(), slug, isActive)}
                              />
                              <label></label>
                            </div>
                          }
                          content={'Disable the intent'}
                          basic
                        />
                      )}
                      <Popup
                        trigger={
                          <Icon
                            className='small trash alternate link icon'
                            onClick={() => this.handleClickTrash(slug)}
                          />
                        }
                        content={'Delete the intent'}
                        basic
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  toggleIntent: intentsOperations.toggleIntent,
  deleteIntent: intentsOperations.deleteIntent,
  botId: bot.selectors.getSelectedId(state),
  nlpEngine: bot.selectors.getNlpEngine(state)
})

export default connect(mapStateToProps, intentsOperations)(IntentsTable)
