import React from 'react'
import { List, Label } from 'semantic-ui-react'
import _ from 'lodash'
import moment from 'moment'

const selectedStyle = { background: 'rgba(0,0,0,.03)', marginBottom: '10px' }

function UserDefectsList({ data = [], type, selectedUser, handleClick }) {
  return (
    <List selection>
      {data.map((user, index) => {
        const userData = _.get(user, `data.${type}`, [])
        const lastReport = userData[userData.length - 1]
        return (
          <List.Item
            key={user.user_id}
            onClick={() => handleClick(index)}
            style={selectedUser === index ? selectedStyle : { marginBottom: '10px' }}
          >
            <List.Content floated='right'>
              <Label>{user.data[type].length}</Label>
            </List.Content>
            <List.Content>
              <List.Header>{user.foreign_id}</List.Header>
              <List.Description>
                Last Report: {moment(_.get(lastReport, 'createdAt', '-')).format('D/M/Y')}
              </List.Description>
            </List.Content>
          </List.Item>
        )
      })}
    </List>
  )
}

export default UserDefectsList
