import * as React from 'react'
import { FaAngleDown } from 'react-icons/fa'
import { SelectOutlinedUIStyled } from './styles'

const SelectOutlinedUI = ({ children, ...props }) => {
  return (
    <SelectOutlinedUIStyled {...props}>
      {children}
      <div className='Navbar-menuItem'>
        <FaAngleDown />
      </div>
    </SelectOutlinedUIStyled>
  )
}

export default SelectOutlinedUI
