import React from 'react'
import { FaEye, FaRegPaperPlane, FaUser } from 'react-icons/fa'
import { OutlinedUI, Indicator, OutlinedUIWithIndicator } from '..'
import { formatTitleDisplayed, formatKpi } from '../../helpers'

const CampaignSent = ({ onClick, selected, campaign }) => (
  <OutlinedUIWithIndicator>
    <OutlinedUI value={formatTitleDisplayed(campaign)} selected={selected} left onClick={() => onClick(campaign.id)} />
    <Indicator value={formatKpi(campaign.kpis.targeted)} Icon={FaUser} />
    <Indicator value={formatKpi(campaign.kpis.sent)} Icon={FaRegPaperPlane} />
    <Indicator value={formatKpi(campaign.kpis.read)} Icon={FaEye} />
  </OutlinedUIWithIndicator>
)

export default CampaignSent
