import React from 'react'
import * as reactRouter from 'react-router-dom'
import type * as Axios from 'axios'

import '@tolk-ai/bot-management-react-customer-api-form/dist/bot-management-react-customer-api-form.esm.css'

import * as utils from '../../utils'

const component = (
  cookieDomain: string, 
  botManagementExpressAxios: Axios.AxiosInstance, 
  authManagementExpressBaseUrl: string
): React.FC => () => {
  const history = reactRouter.useHistory()

  const CustomerApiForm = utils.customerApiFormComponent(
    botManagementExpressAxios,
    document,
    authManagementExpressBaseUrl,
    cookieDomain,
    history
  )

  const botId = localStorage.getItem('botId') || ''

    return (
      <CustomerApiForm botId={botId} />
    )
}

export { component }