import React, { Component } from 'react'
import { connect } from 'react-redux'
import operations from '../../../../../../redux/analytics/messages/operations'
import getSafe from '../../../../../utils/getSafe'
import Conversations from './Conversations'

class ConversationsContainer extends Component {
  render() {
    return (
        <Conversations {...this.props} />
    )
  }
}

const mapStateToProps = state => ({
  filters: state.main.filters,
  usersData: getSafe(() => state.analytics.users.data),
})

export default connect(mapStateToProps, operations)(ConversationsContainer)
