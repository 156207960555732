import * as React from 'react'
import classNames from 'classnames'
import onClickOutside from 'react-onclickoutside'
import { maybe } from '@sbizeul/fp-flow'
import { Loader } from 'semantic-ui-react'
import { FiAlertCircle } from 'react-icons/fi'
import * as R from 'ramda'

import { renderNothing } from 'helpers/react'
import './styles.css'

export class TextInputMaterial extends React.Component {
  state = {
    hasFocus: !!this.props.autoFocus,
    text: this.props.initialValue || '',
    hasValidationErrors: false
  }

  input = maybe.nothing()

  componentDidUpdate() {
    if (this.state.hasFocus) maybe.map(input => input.focus(), this.input)
    if (this.state.hasValidationErrors && this.isInputValid(this.state.text))
      this.setState({ hasValidationErrors: false })
  }

  render() {
    return (
      <div className={classNames('TextInputMaterial', this.props.className)}>
        <div
          className={classNames('TextInputMaterial-wrapper', this.getWrapperClassNames(this.state.hasValidationErrors))}
          onClick={this.handleInputFocus}
        >
          <input
            ref={node => (this.input = maybe.fromNullable(node))}
            className={classNames(
              'TextInputMaterial-wrapper--input',
              this.getInputClassnames(this.state.text, this.state.hasFocus)
            )}
            type='input'
            name={this.props.name}
            value={this.state.text}
            onChange={this.handleChangeInput}
            onKeyDown={this.handleKeyDown}
            onKeyUp={this.props.onKeyUp}
            onBlur={this.handleInputBlur}
            disabled={this.props.disabled || this.props.isLoading}
            autoFocus={this.props.autoFocus}
          />
          <span
            className={this.getPlaceholderClassnames(this.state.text, this.state.hasFocus, this.props.isLoading)}
            data-testid='TextInputMaterial-placeholder'
          >
            {this.props.placeholder}
          </span>
          <FiAlertCircle
            className={classNames(
              'TextInputMaterial-wrapper--alert-icon',
              this.getAlertIconClassnames(this.state.hasValidationErrors)
            )}
          />
        </div>
        <div className='TextInputMaterial-loader'>
          {this.props.isLoading ? <Loader size='mini' inline active /> : renderNothing()}
        </div>
      </div>
    )
  }

  handleClickOutside() {
    if (this.state.hasFocus && !this.state.text.length) {
      this.setState({ hasFocus: false })
    }
  }

  handleChangeInput = e => {
    e.persist()
    const { value: text } = e.target
    this.setState({ text }, () => {
      if (this.props.onChange) this.props.onChange(e)
      if (this.props.onChangeText) this.props.onChangeText(text)
    })
  }

  handleInputFocus = () => {
    if (!this.state.hasFocus && (!this.props.disabled || !this.props.isLoading)) {
      this.setState({ hasFocus: true })
    }
  }

  handleInputBlur = e => {
    this.setState({ hasFocus: false })
    this.props.onBlur && this.props.onBlur(e)
  }

  handleKeyDown = e => {
    e.persist()
    const { text } = this.state
    const { onEnter, onKeyDown } = this.props
    if (e.key === 'Enter') {
      if (this.isInputValid(text)) {
        if (onEnter) onEnter(text, e)
        this.setState({ text: '', hasFocus: false, hasValidationErrors: false })
      } else {
        this.setState({ hasValidationErrors: true })
      }
    }
    if (onKeyDown) onKeyDown(e)
  }

  isInputValid = text => (this.props.validators ? R.allPass(this.props.validators)(text.trim()) : true)

  getPlaceholderClassnames = (text, hasFocus, isLoading) =>
    classNames('TextInputMaterial-wrapper--flyingPlaceholder', {
      'TextInputMaterial-wrapper--flyingPlaceholder---up': !isLoading && (text.length || hasFocus),
      'TextInputMaterial-wrapper--flyingPlaceholder---default': isLoading || (!text.length && !hasFocus),
      'TextInputMaterial-wrapper--flyingPlaceholder---up----focuslost': text.length && !hasFocus,
      'TextInputMaterial-wrapper--hidden': hasFocus
    })

  getWrapperClassNames = hasValidationErrors => ({
    'TextInputMaterial-wrapper---invalid': hasValidationErrors
  })

  getAlertIconClassnames = hasValidationErrors => ({
    'TextInputMaterial-wrapper--hidden': !hasValidationErrors
  })

  getInputClassnames = (text, hasFocus) => ({
    'TextInputMaterial-wrapper--hidden': !text.length && !hasFocus
  })
}

export default onClickOutside(TextInputMaterial)
