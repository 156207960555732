import { PinRequest, UnPinRequest } from './components/PinQuestion/types'
import {
  PIN_TO_HOTTOPIC_REQUEST,
  UNPIN_TO_HOTTOPIC_REQUEST
} from './components/PinQuestion/PinToHotTopic'
import {
  PIN_TO_TAPFLOW_REQUEST,
  UNPIN_TO_TAPFLOW_REQUEST
} from './components/PinQuestion/PinToTapFlow'
import {
  ARCHIVE_QUESTION_REQUEST,
  ArchiveQuestionParam,
  ArchiveQuestionRequest,
  CREATE_FAILURE,
  CREATE_REQUEST,
  CREATE_SECTION_FAILURE,
  CREATE_SECTION_REQUEST,
  CREATE_SECTION_SUCCESS,
  CREATE_SUCCESS,
  CreateFailure,
  CreateFailureParam,
  CreateRequest,
  CreateRequestParam,
  CreateSection,
  CreateSectionFailure,
  CreateSectionFailureParam,
  CreateSectionParam,
  CreateSectionSuccess,
  CreateSectionSuccessParam,
  CreateSuccess,
  CreateSuccessParam,
  DELETE_SECTION_FAILURE,
  DELETE_SECTION_REQUEST,
  DELETE_SECTION_SUCCESS,
  DeleteSectionFailure,
  DeleteSectionFailureParam,
  DeleteSectionParam,
  DeleteSectionRequest,
  DeleteSectionSuccess,
  DeleteSectionSuccessParam,
  FETCH_ALL_FAILURE,
  FETCH_ALL_REQUEST,
  FETCH_ALL_SUCCESS,
  FETCH_LABELED_FAILURE,
  FETCH_LABELED_REQUEST,
  FETCH_LABELED_SUCCESS,
  FETCH_ONE_FAILURE,
  FETCH_ONE_REQUEST,
  FETCH_ONE_SUCCESS,
  FetchAllFailure,
  FetchAllFailureParam,
  FetchAllRequest,
  FetchAllSuccess,
  FetchAllSuccessParam,
  FetchLabeledFailure,
  FetchLabeledFailureParam,
  FetchLabeledRequest,
  FetchLabeledSuccess,
  FetchLabeledSuccessParam,
  FetchOneFailure,
  FetchOneFailureParam,
  FetchOneRequest,
  FetchOneRequestParam,
  FetchOneSuccess,
  FetchOneSuccessParam,
  MOVE_SECTION_REQUEST,
  MoveSectionParam,
  MoveSectionRequest,
  SAVE_ONE_FAILURE,
  SAVE_ONE_REQUEST,
  SAVE_ONE_SUCCESS,
  SAVE_SECTION_FAILURE,
  SAVE_SECTION_REQUEST,
  SAVE_SECTION_SUCCESS,
  SaveOneFailure,
  SaveOneFailureParam,
  SaveOneParam,
  SaveOneRequest,
  SaveOneSuccess,
  SaveOneSuccessParam,
  SaveSectionFailure,
  SaveSectionFailureParam,
  SaveSectionParam,
  SaveSectionRequest,
  SaveSectionSuccess,
  SaveSectionSuccessParam,
  SELECT,
  SelectQuestion,
  SelectQuestionParam,
  SORT_BY_CREATION_DESC,
  SortByCreationDesc,
  UNARCHIVE_QUESTION_REQUEST,
  UnarchiveQuestionParam,
  UnarchiveQuestionRequest,
  MERGE_QUESTION_REQUEST,
  MERGE_QUESTION_SUCCESS,
  MERGE_QUESTION_FAILURE,
  MergeQuestionParam,
  MergeQuestionRequest,
  MergeQuestionSuccess,
  MergeQuestionSuccessParam,
  MergeQuestionFailure,
  MergeQuestionFailureParam,
  FETCH_QUESTION_LINK_REQUEST,
  FETCH_QUESTION_LINK_SUCCESS,
  FETCH_QUESTION_LINK_FAILURE,
  QuestionId,
  FetchQuestionReferencesRequest,
  FetchQuestionReferencesSuccessParam,
  FetchQuestionReferencesSuccess,
  FetchQuestionReferencesFailureParam,
  FetchQuestionReferencesFailure,
  Question
} from './types'

export const fetchOne: (payload: FetchOneRequestParam) => FetchOneRequest = (
  payload
) => ({
  type: FETCH_ONE_REQUEST,
  payload
})

export const fetchOneSuccess: (
  payload: FetchOneSuccessParam
) => FetchOneSuccess = (payload) => ({
  type: FETCH_ONE_SUCCESS,
  payload
})

export const fetchOneFailure: (
  payload: FetchOneFailureParam
) => FetchOneFailure = (payload) => ({
  type: FETCH_ONE_FAILURE,
  payload
})

export const fetchAll: () => FetchAllRequest = () => ({
  type: FETCH_ALL_REQUEST
})

export const fetchAllSuccess: (
  payload: FetchAllSuccessParam
) => FetchAllSuccess = (payload) => ({
  type: FETCH_ALL_SUCCESS,
  payload
})

export const fetchAllFailure: (
  payload: FetchAllFailureParam
) => FetchAllFailure = (payload) => ({
  type: FETCH_ALL_FAILURE,
  payload
})

export const fetchLabeledQuestions: () => FetchLabeledRequest = () => ({
  type: FETCH_LABELED_REQUEST
})

export const fetchLabeledQuestionsSuccess: (
  payload: FetchLabeledSuccessParam
) => FetchLabeledSuccess = (payload) => ({
  type: FETCH_LABELED_SUCCESS,
  payload
})

export const fetchLabeledQuestionsFailure: (
  payload: FetchLabeledFailureParam
) => FetchLabeledFailure = (payload) => ({
  type: FETCH_LABELED_FAILURE,
  payload
})

export const fetchQuestionReferences: (
  payload: QuestionId
) => FetchQuestionReferencesRequest = (payload) => ({
  type: FETCH_QUESTION_LINK_REQUEST,
  payload
})

export const fetchQuestionReferencesSuccess: (
  payload: FetchQuestionReferencesSuccessParam
) => FetchQuestionReferencesSuccess = (payload) => ({
  type: FETCH_QUESTION_LINK_SUCCESS,
  payload
})

export const fetchQuestionReferencesFailure: (
  payload: FetchQuestionReferencesFailureParam
) => FetchQuestionReferencesFailure = (payload) => ({
  type: FETCH_QUESTION_LINK_FAILURE,
  payload
})

export const create: (payload: CreateRequestParam) => CreateRequest = (
  payload
) => ({
  type: CREATE_REQUEST,
  payload
})

export const createSuccess: (payload: CreateSuccessParam) => CreateSuccess = (
  payload
) => ({
  type: CREATE_SUCCESS,
  payload
})

export const createFailure: (payload: CreateFailureParam) => CreateFailure = (
  payload
) => ({
  type: CREATE_FAILURE,
  payload
})

export const sortByCreationDesc: () => SortByCreationDesc = () => ({
  type: SORT_BY_CREATION_DESC
})

export const selectQuestion: (
  payload: SelectQuestionParam
) => SelectQuestion = (payload) => ({
  type: SELECT,
  payload
})

export const saveOne: (payload: SaveOneParam) => SaveOneRequest = (
  payload
) => ({
  type: SAVE_ONE_REQUEST,
  payload
})

export const saveOneSuccess: (
  payload: SaveOneSuccessParam
) => SaveOneSuccess = (payload) => ({
  type: SAVE_ONE_SUCCESS,
  payload
})

export const saveOneFailure: (
  payload: SaveOneFailureParam
) => SaveOneFailure = (payload) => ({
  type: SAVE_ONE_FAILURE,
  payload
})

export const pinQuestionToTapFlow: (payload: Question) => PinRequest = (
  payload
) => ({
  type: PIN_TO_TAPFLOW_REQUEST,
  payload
})
export const unpinQuestionToTapFlow: (payload: Question) => UnPinRequest = (
  payload
) => ({
  type: UNPIN_TO_TAPFLOW_REQUEST,
  payload
})

export const pinQuestionToHottopic: (payload: Question) => PinRequest = (
  payload
) => ({
  type: PIN_TO_HOTTOPIC_REQUEST,
  payload
})
export const unpinQuestionToHottopic: (payload: Question) => UnPinRequest = (
  payload
) => ({
  type: UNPIN_TO_HOTTOPIC_REQUEST,
  payload
})

export const moveSection: (payload: MoveSectionParam) => MoveSectionRequest = (
  payload
) => ({
  type: MOVE_SECTION_REQUEST,
  payload
})

export const archiveQuestion: (
  payload: ArchiveQuestionParam
) => ArchiveQuestionRequest = (payload) => ({
  type: ARCHIVE_QUESTION_REQUEST,
  payload
})

export const unarchiveQuestion: (
  payload: UnarchiveQuestionParam
) => UnarchiveQuestionRequest = (payload) => ({
  type: UNARCHIVE_QUESTION_REQUEST,
  payload
})

export const mergeQuestion: (
  payload: MergeQuestionParam
) => MergeQuestionRequest = (payload) => ({
  type: MERGE_QUESTION_REQUEST,
  payload
})

export const mergeQuestionSuccess: (
  payload: MergeQuestionSuccessParam
) => MergeQuestionSuccess = (payload) => ({
  type: MERGE_QUESTION_SUCCESS,
  payload
})

export const mergeQuestionFailure: (
  payload: MergeQuestionFailureParam
) => MergeQuestionFailure = (payload) => ({
  type: MERGE_QUESTION_FAILURE,
  payload
})

export const createSection: (payload: CreateSectionParam) => CreateSection = (
  payload
) => ({
  type: CREATE_SECTION_REQUEST,
  payload
})

export const createSectionSuccess: (
  payload: CreateSectionSuccessParam
) => CreateSectionSuccess = (payload) => ({
  type: CREATE_SECTION_SUCCESS,
  payload
})

export const createSectionFailure: (
  payload: CreateSectionFailureParam
) => CreateSectionFailure = (payload) => ({
  type: CREATE_SECTION_FAILURE,
  payload
})

export const saveSection: (payload: SaveSectionParam) => SaveSectionRequest = (
  payload
) => ({
  type: SAVE_SECTION_REQUEST,
  payload
})

export const saveSectionSuccess: (
  payload: SaveSectionSuccessParam
) => SaveSectionSuccess = (payload) => ({
  type: SAVE_SECTION_SUCCESS,
  payload
})

export const saveSectionFailure: (
  payload: SaveSectionFailureParam
) => SaveSectionFailure = (payload) => ({
  type: SAVE_SECTION_FAILURE,
  payload
})

export const deleteSection: (
  payload: DeleteSectionParam
) => DeleteSectionRequest = (payload) => ({
  type: DELETE_SECTION_REQUEST,
  payload
})

export const deleteSectionSuccess: (
  payload: DeleteSectionSuccessParam
) => DeleteSectionSuccess = (payload) => ({
  type: DELETE_SECTION_SUCCESS,
  payload
})

export const deleteSectionFailure: (
  payload: DeleteSectionFailureParam
) => DeleteSectionFailure = (payload) => ({
  type: DELETE_SECTION_FAILURE,
  payload
})

export {
  ARCHIVE_QUESTION_REQUEST,
  CREATE_FAILURE,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  CREATE_SECTION_FAILURE,
  CREATE_SECTION_REQUEST,
  CREATE_SECTION_SUCCESS,
  DELETE_SECTION_FAILURE,
  DELETE_SECTION_REQUEST,
  DELETE_SECTION_SUCCESS,
  FETCH_ALL_FAILURE,
  FETCH_ALL_REQUEST,
  FETCH_ALL_SUCCESS,
  FETCH_LABELED_FAILURE,
  FETCH_LABELED_REQUEST,
  FETCH_LABELED_SUCCESS,
  FETCH_QUESTION_LINK_FAILURE,
  FETCH_QUESTION_LINK_REQUEST,
  FETCH_QUESTION_LINK_SUCCESS,
  FETCH_ONE_FAILURE,
  FETCH_ONE_REQUEST,
  FETCH_ONE_SUCCESS,
  MOVE_SECTION_REQUEST,
  PIN_TO_HOTTOPIC_REQUEST,
  UNPIN_TO_HOTTOPIC_REQUEST,
  PIN_TO_TAPFLOW_REQUEST,
  UNPIN_TO_TAPFLOW_REQUEST,
  SAVE_ONE_FAILURE,
  SAVE_ONE_REQUEST,
  SAVE_ONE_SUCCESS,
  SAVE_SECTION_FAILURE,
  SAVE_SECTION_REQUEST,
  SAVE_SECTION_SUCCESS,
  SELECT,
  SORT_BY_CREATION_DESC,
  UNARCHIVE_QUESTION_REQUEST,
  MERGE_QUESTION_REQUEST,
  MERGE_QUESTION_SUCCESS,
  MERGE_QUESTION_FAILURE
}
