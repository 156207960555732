import { RemoteData, Maybe } from '@sbizeul/fp-flow'

import { IsoLanguage } from 'helpers/language'
import { Map } from 'helpers/map'
import { ServerFailure, Id } from 'types'
import { UploadImageRequestPayload, UploadImageSuccess } from 'modules/answer/types'

export const FETCH_ALL_REQUEST = 'bot/FETCH_ALL_REQUEST'
export const FETCH_ALL_SUCCESS = 'bot/FETCH_ALL_SUCCESS'
export const FETCH_ALL_FAILURE = 'bot/FETCH_ALL_FAILURE'

export const FETCH_ONE_REQUEST = 'bot/FETCH_ONE_REQUEST'
export const FETCH_ONE_SUCCESS = 'bot/FETCH_ONE_SUCCESS'
export const FETCH_ONE_FAILURE = 'bot/FETCH_ONE_FAILURE'

export const SELECT = 'bot/SELECT'

export const FETCH_PUBLISH_STATUS_REQUEST = 'bot/FETCH_PUBLISH_STATUS_REQUEST'
export const FETCH_PUBLISH_STATUS_SUCCESS = 'bot/FETCH_PUBLISH_STATUS_SUCCESS'
export const FETCH_PUBLISH_STATUS_FAILURE = 'bot/FETCH_PUBLISH_STATUS_FAILURE'

export const PUBLISH_REQUEST = 'bot/PUBLISH_REQUEST'
export const PUBLISH_SUCCESS = 'bot/PUBLISH_SUCCESS'
export const PUBLISH_FAILURE = 'bot/PUBLISH_FAILURE'

export const REGISTER_PUBLISH_STATUS = 'bot/REGISTER_PUBLISH_STATUS'
export const UNREGISTER_PUBLISH_STATUS = 'bot/UNREGISTER_PUBLISH_STATUS'

export const BOT_CREATION_UPDATE_BOTNAME = 'bot/UPDATE_BOTNAME'
export const BOT_CREATION_UPDATE_GREETINGS = 'bot/UPDATE_GREETINGS'
export const BOT_CREATION_UPDATE_LANGUAGE = 'bot/UPDATE_LANGUAGE'
export const BOT_CREATION_UPDATE_USE_CASE = 'bot/UPDATE_USE_CASE'
export const BOT_CREATION_REQUEST = 'bot/REQUEST'
export const BOT_CREATION_SUCCESS = 'bot/SUCCESS'
export const BOT_CREATION_FAILURE = 'bot/FAILURE'
export const BOT_CREATION_ENDED = 'bot/CREATION_ENDED'
export const BOT_CREATION_UPDATE_THEME = 'bot/UPDATE_THEME'
export const BOT_CREATION_UPDATE_AVATAR = 'bot/UPDATE_AVATAR'
export const BOT_CREATION_GET_LAST_BOT = 'bot/GET_LAST_BOT'

export const UPDATE_WEBCHAT = 'bot/UPDATE_WEBCHAT'

export const SAVE_WEBCHAT = 'bot/SAVE_WEBCHAT'
export const SAVE_WEBCHAT_SUCCESS = 'bot/SAVE_WEBCHAT_SUCCESS'
export const SAVE_WEBCHAT_FAILURE = 'bot/SAVE_WEBCHAT_FAILURE'

export const FETCH_WEBCHAT_REQUEST = 'bot/FETCH_WEBCHAT_REQUEST'
export const FETCH_WEBCHAT_SUCCESS = 'bot/FETCH_WEBCHAT_SUCCESS'
export const FETCH_WEBCHAT_FAILURE = 'bot/FETCH_WEBCHAT_FAILURE'
export const CLEAN_WEBCHAT = 'bot/CLEAN_WEBCHAT'

export const FETCH_USE_CASES_REQUEST = 'bot/FETCH_USE_CASES_REQUEST'
export const FETCH_USE_CASES_SUCCESS = 'bot/FETCH_USE_CASES_SUCCESS'
export const FETCH_USE_CASES_FAILURE = 'bot/FETCH_USE_CASES_FAILURE'

export type BotEvent = {}

export type Feature = Readonly<{
  id: Id
  enabled: boolean
}>

export type NlpEngine = 'recast' | 'luis' | 'heimdall'

export type BotOptions = Readonly<{
  accessToken: string
  botSlug: string
  developerToken: string
  nlpEngine: NlpEngine
  userSlug: string
  greetings?: ReadonlyArray<string>
}>

export type MongoId = Readonly<{
  $oid: string
}>

enum Status {
  NEEDS_TO_BE_PUBLISHED = 'Needs to be published',
  IN_PROGRESS = 'In progress',
  UP_TO_DATE = 'Up-to-date'
}
export const NEEDS_TO_BE_PUBLISHED: Status = Status.NEEDS_TO_BE_PUBLISHED
export const IN_PROGRESS: Status = Status.IN_PROGRESS
export const UP_TO_DATE: Status = Status.UP_TO_DATE

export type PublishStatus = Readonly<{
  status: Status
}>

export type Bot = Readonly<{
  bot_id: Id
  createdAt: string
  description: string
  events: ReadonlyArray<BotEvent>
  features?: ReadonlyArray<Feature>
  isActivate: boolean
  webchatUrl?: string
  language: ReadonlyArray<IsoLanguage>
  name: string
  options: BotOptions
  password: string
  treeName: string
  updatedAt: string
  channels: ReadonlyArray<Id>
  _id: MongoId
  version?: number
  archived?: boolean
}>

export type Avatar = Readonly<{
  pics: string
}>

export type Colours = {
  firstColor: string
  secondColor?: string
  thirdColor?: string
}

export type Theme = Readonly<{
  firstColor: string
  secondColor: string
  thirdColor: string
}>

export type WebchatTheme = Readonly<{
  firstColour: string
  secondColour: string
  thirdColour: string
  textColour: string
}>

export type Settings = Readonly<{
  avatar: Avatar
  color1: string
  color2: string
  title: string
}>

export type Webchat = Readonly<{
  bot_id: Id
  webchat_id: Id
  channel: Id
  settings: Settings
  active?: boolean
}>

export type Info = Readonly<{
  botId: Id
  webchatUrl: string
}>

export type BotEntityState = Readonly<{
  byId: RemoteData<ServerFailure, Map<Bot>>
  webchat: RemoteData<ServerFailure, Webchat>
  publishStatus: RemoteData<ServerFailure, PublishStatus>
  info: RemoteData<ServerFailure, Info>
  availableUseCases: RemoteData<ServerFailure, ReadonlyArray<UseCase>>
  isModified: boolean
}>

export type UseCase = string

export type BotCreation = Readonly<{
  avatar: string
  botName: string
  greetings: string
  language: IsoLanguage
  theme: Theme
  useCase: UseCase
}>

export type Environment = 'dev' | 'staging' | 'production'

export type BotUiState = Readonly<{
  selectedId: Maybe<Id>
  botCreation: BotCreation
}>

export type AppState = Readonly<{
  entities: {
    bot: BotEntityState
  }
  ui: {
    bot: BotUiState
  }
}>

export type UpdateWebchatParams = (webchat: Webchat) => unknown
export type UploadImageParams = (image: UploadImageRequestPayload) => unknown

export type UpdateWebchatPayload = Webchat

export type UpdateWebchat = Readonly<{
  type: typeof UPDATE_WEBCHAT
  payload: UpdateWebchatPayload
}>

export type SaveWebchatPayload = Webchat

export type SaveWebchat = Readonly<{
  type: typeof SAVE_WEBCHAT
  payload: SaveWebchatPayload
}>

export type SaveWebchatFailure = Readonly<{
  type: typeof SAVE_WEBCHAT_FAILURE
  payload: ServerFailure
}>

export type SaveWebchatSuccessPayload = Id

export type SaveWebchatSuccess = Readonly<{
  type: typeof SAVE_WEBCHAT_SUCCESS
  payload: SaveWebchatSuccessPayload
}>

export type FetchWebchat = Readonly<{
  type: typeof FETCH_WEBCHAT_REQUEST
}>

export type FetchWebchatFailure = Readonly<{
  type: typeof FETCH_WEBCHAT_FAILURE
  payload: ServerFailure
}>

export type FetchWebchatSuccessPayload = Webchat

export type FetchWebchatSuccess = Readonly<{
  type: typeof FETCH_WEBCHAT_SUCCESS
  payload: FetchWebchatSuccessPayload
}>

export type CleanWebchat = Readonly<{
  type: typeof CLEAN_WEBCHAT
}>

export type Route = string

export type FetchAllRequestPayload = Readonly<{ userId: string }>

export type FetchAllRequest = Readonly<{
  type: typeof FETCH_ALL_REQUEST
  payload: FetchAllRequestPayload
}>


export type FetchOneRequestPayload = Readonly<{ userId: string }>

export type FetchOneRequest = Readonly<{
  type: typeof FETCH_ONE_REQUEST
  payload: FetchOneRequestPayload
}>

export type RestBot = {
  readonly id: string
  readonly name: string
  readonly status: 'modified' | 'published'
  readonly webchatUrl: string
}

export type FetchAllSuccessPayload = readonly RestBot[]

export type FetchAllSuccess = Readonly<{
  type: typeof FETCH_ALL_SUCCESS
  payload: FetchAllSuccessPayload
}>

export type FetchOneSuccessPayload = RestBot

export type FetchOneSuccess = Readonly<{
  type: typeof FETCH_ONE_SUCCESS
  payload: FetchOneSuccessPayload
}>

export type FetchAllFailure = Readonly<{
  type: typeof FETCH_ALL_FAILURE
  payload: ServerFailure
}>

export type FetchOneFailure = Readonly<{
  type: typeof FETCH_ONE_FAILURE
  payload: ServerFailure
}>

export type SelectPayload = string

export type Select = Readonly<{
  type: typeof SELECT
  payload: SelectPayload
}>

export type FetchPublishStatus = Readonly<{
  type: typeof FETCH_PUBLISH_STATUS_REQUEST
}>

export type FetchPublishStatusSuccessPayload = PublishStatus

export type FetchPublishStatusSuccess = Readonly<{
  type: typeof FETCH_PUBLISH_STATUS_SUCCESS
  payload: FetchPublishStatusSuccessPayload
}>

export type FetchPublishStatusFailure = Readonly<{
  type: typeof FETCH_PUBLISH_STATUS_FAILURE
  payload: ServerFailure
}>

export type PublishPayload = Id

export type Publish = Readonly<{
  type: typeof PUBLISH_REQUEST
  payload: PublishPayload
}>

export type PublishSuccessPayload = PublishStatus

export type PublishSuccess = Readonly<{
  type: typeof PUBLISH_SUCCESS
  payload: PublishSuccessPayload
}>

export type PublishFailure = Readonly<{
  type: typeof PUBLISH_FAILURE
  payload: ServerFailure
}>

export type RegisterCreatedBotRequest = Readonly<{
  type: typeof BOT_CREATION_REQUEST
}>

export type RegisterCreatedBotSuccessPayload = Info

export type RegisterCreatedBotSuccess = Readonly<{
  type: typeof BOT_CREATION_SUCCESS
  payload: RegisterCreatedBotSuccessPayload
}>

export type RegisterCreatedBotFailure = Readonly<{
  type: typeof BOT_CREATION_FAILURE
  payload: ServerFailure
}>

export type RegisterCreatedBotEnded = Readonly<{
  type: typeof BOT_CREATION_ENDED
}>

export type UpdateBotNamePayload = string

export type UpdateBotName = Readonly<{
  type: typeof BOT_CREATION_UPDATE_BOTNAME,
  payload: UpdateBotNamePayload
}>

export type UpdateGreetingsPayload = string

export type UpdateGreetings = Readonly<{
  type: typeof BOT_CREATION_UPDATE_GREETINGS,
  payload: UpdateGreetingsPayload
}>

export type UpdateLanguagePayload = IsoLanguage

export type UpdateLanguage = Readonly<{
  type: typeof BOT_CREATION_UPDATE_LANGUAGE,
  payload: UpdateLanguagePayload
}>

export type UpdateThemePayload = Theme

export type UpdateTheme = Readonly<{
  type: typeof BOT_CREATION_UPDATE_THEME,
  payload: UpdateThemePayload
}>

export type UpdateAvatarPayload = string

export type UpdateAvatar = Readonly<{
  type: typeof BOT_CREATION_UPDATE_AVATAR,
  payload: UpdateAvatarPayload
}>

export type UpdateUseCasePayload = string

export type UpdateUseCase = Readonly<{
  type: typeof BOT_CREATION_UPDATE_USE_CASE
  payload: UpdateUseCasePayload
}>

export type GetLastBot = Readonly<{
  type: typeof BOT_CREATION_GET_LAST_BOT
}>

export type RegisterPublishStatus = Readonly<{
  type: typeof REGISTER_PUBLISH_STATUS
}>

export type UnregisterPublishStatus = Readonly<{
  type: typeof UNREGISTER_PUBLISH_STATUS
}>

export type FetchUseCasesRequest = Readonly<{
  type: typeof FETCH_USE_CASES_REQUEST
}>

export type FetchUseCasesSuccessPayload = ReadonlyArray<UseCase>

export type FetchUseCasesSuccess = Readonly<{
  type: typeof FETCH_USE_CASES_SUCCESS
  payload: FetchUseCasesSuccessPayload
}>

export type FetchUseCasesFailure = Readonly<{
  type: typeof FETCH_USE_CASES_FAILURE
  payload: ServerFailure
}>

export type BotEntityAction =
  | CleanWebchat
  | FetchAllRequest
  | FetchAllSuccess
  | FetchAllFailure
  | FetchOneRequest
  | FetchOneSuccess
  | FetchOneFailure
  | FetchPublishStatus
  | FetchPublishStatusSuccess
  | FetchPublishStatusFailure
  | FetchUseCasesFailure
  | FetchUseCasesRequest
  | FetchUseCasesSuccess
  | FetchWebchat
  | FetchWebchatSuccess
  | FetchWebchatFailure
  | Publish
  | PublishSuccess
  | PublishFailure
  | RegisterCreatedBotRequest
  | RegisterCreatedBotSuccess
  | RegisterCreatedBotFailure
  | RegisterCreatedBotEnded
  | UpdateWebchat
  | UploadImageSuccess

export type BotUiAction =
  | RegisterCreatedBotEnded
  | Select
  | UpdateAvatar
  | UpdateBotName
  | UpdateGreetings
  | UpdateLanguage
  | UpdateTheme
  | UpdateUseCase
