import React from 'react'

import { BASE_SKILL_LABELS, TAPFLOW } from '../../types'

const TapFlowButton = () => (
  <div className='skill-default--button skill-tap-flow--button'>
    <p className='skill-default--label'>{BASE_SKILL_LABELS[TAPFLOW]}</p>
  </div>
)

export default TapFlowButton
