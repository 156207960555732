import React from 'react'
import { Menu } from 'semantic-ui-react'

import { fromUtcToLocal } from 'helpers/moment'

const ConversationList = ({ conversations, activeID, onSelect }) => (
  <Menu className='ui vertical menu ConversationList'>
    {conversations.map(conv => (
      <Menu.Item
        key={conv.id}
        id={conv.id}
        content={`${fromUtcToLocal(conv.lastDateInRange, 'lll')}`}
        active={activeID === conv.id}
        onClick={() => onSelect(conv.id)}
      />
    ))}
  </Menu>
)

export default ConversationList
