import { either, maybe, remoteData } from '@sbizeul/fp-flow'
import * as R from 'ramda'

import synonym from 'modules/synonym'
import { AddSynonymFailure, DeleteSynonymFailure } from 'modules/synonym/types'

import * as actions from './actions'
import * as keywordActions from './action.typed'
import { KeywordUiState, KeywordUiAction, SelectKeyword, CreateFailure, FetchLinkedKeywordElementSuccess, FetchLinkedKeywordElementFailure } from './types'

export const initialState: KeywordUiState = {
  selectedKeywordId: maybe.nothing(),
  linkedKeywordElement: remoteData.notAsked(),
  isKeywordCreating: either.of(false),
  isOpenCreateForm: false,
  isDeletingSynonym: either.right(false),
  isSynonymAdding: either.of(false)
}

const selectedKeywordId = R.lensProp('selectedKeywordId')
const linkedKeywordElement = R.lensProp('linkedKeywordElement')
const isKeywordCreating = R.lensProp('isKeywordCreating')
const isOpenCreateForm = R.lensProp('isOpenCreateForm')
const isSynonymAdding = R.lensProp('isSynonymAdding')
const isDeletingSynonym = R.lensProp('isDeletingSynonym')

const selectKeyword = (state: KeywordUiState, action: SelectKeyword) => R.set(selectedKeywordId, maybe.just(action.payload), state)

const unselectKeyword = (state: KeywordUiState) => R.set(selectedKeywordId, maybe.nothing(), state)

const openCreateForm = (state: KeywordUiState) => R.set(isOpenCreateForm, true, state)

const closeCreateForm = (state: KeywordUiState) => R.set(isOpenCreateForm, false, state)

const fetchLinkedKeywordElementRequest = (state: KeywordUiState) => R.set(linkedKeywordElement, remoteData.loading(), state)

const fetchLinkedKeywordElementSuccess = (state: KeywordUiState, action: FetchLinkedKeywordElementSuccess) => R.set(linkedKeywordElement, remoteData.success(action.payload), state)

const fetchLinkedKeywordElementFailure = (state: KeywordUiState, action: FetchLinkedKeywordElementFailure) => R.set(linkedKeywordElement, remoteData.failure(action.payload), state)

const createKeywordRequest = (state: KeywordUiState) => R.set(isKeywordCreating, either.of(true), state)

const createKeywordSuccess = (state: KeywordUiState) => R.set(isKeywordCreating, either.of(false), state)

const createKeywordFailure = (state: KeywordUiState, action: CreateFailure) => R.set(isKeywordCreating, either.left(action.payload), state)

const addingRequest = (state: KeywordUiState) => R.set(isSynonymAdding, either.of(true), state)

const addingSuccess = (state: KeywordUiState) => R.set(isSynonymAdding, either.of(false), state)

const addingFailure = (state: KeywordUiState, action: AddSynonymFailure) => R.set(isSynonymAdding, either.left(action.payload), state)

const deleteSynonymFailure = (state: KeywordUiState, action: DeleteSynonymFailure) => {
  return R.set(isDeletingSynonym, either.left(action.payload), state)
}

const deleteSynonymRequest = (state: KeywordUiState) => {
  return R.set(isDeletingSynonym, either.right(true), state)
}

const deleteSynonymSuccess = (state: KeywordUiState) => {
  return R.set(isDeletingSynonym, either.right(false), state)
}

export default function (state = initialState, action: KeywordUiAction) {
  switch (action.type) {
    case actions.SELECT_KEYWORD:
      return selectKeyword(state, action)
    case actions.UNSELECT_KEYWORD:
      return unselectKeyword(state)
    case actions.OPEN_CREATE_FORM:
      return openCreateForm(state)
    case actions.CLOSE_CREATE_FORM:
      return closeCreateForm(state)
    case actions.CREATE_REQUEST:
      return createKeywordRequest(state)
    case actions.CREATE_SUCCESS:
      return createKeywordSuccess(state)
    case actions.CREATE_FAILURE:
      return createKeywordFailure(state, action)
    case keywordActions.FETCH_LINKED_KEYWORD_ELEMENT_REQUEST:
      return fetchLinkedKeywordElementRequest(state)
    case keywordActions.FETCH_LINKED_KEYWORD_ELEMENT_SUCCESS:
      return fetchLinkedKeywordElementSuccess(state, action)
    case keywordActions.FETCH_LINKED_KEYWORD_ELEMENT_FAILURE:
      return fetchLinkedKeywordElementFailure(state, action)
    case synonym.actions.ADD_SYNONYM_REQUEST:
      return addingRequest(state)
    case synonym.actions.ADD_SYNONYM_SUCCESS:
      return addingSuccess(state)
    case synonym.actions.ADD_SYNONYM_FAILURE:
      return addingFailure(state, action)
    case synonym.actions.DELETE_SYNONYM_REQUEST:
      return deleteSynonymRequest(state)
    case synonym.actions.DELETE_SYNONYM_FAILURE:
      return deleteSynonymFailure(state, action)
    case synonym.actions.DELETE_SYNONYM_SUCCESS:
      return deleteSynonymSuccess(state)
    default:
      return state
  }
}
