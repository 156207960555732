import * as React from 'react'
import { Menu, Tab } from 'semantic-ui-react'
import { either } from '@sbizeul/fp-flow'

import DismissErrorMessage from 'components/Message/DismissErrorMessage'
import { renderNothing } from 'helpers/react'

import synonym from 'modules/synonym'

import ByLanguageCounter from '../ByLanguageCounter'
import KeywordBreadcrumb from '../KeywordBreadcrumb'
import {
  getSynonymCountByLanguage,
  groupSynonymsByLanguages,
  sortByAlphabetical
} from '../../models'

import './styles.css'

function Keyword(props) {
  const synonymsByLanguages = groupSynonymsByLanguages(
    props.languages,
    sortByAlphabetical('value')(props.synonyms)
  )
  const synonymsByLanguagesPanes = props.languages.map(tabPaneForLanguage)

  return (
    <div className='Keyword'>
      <KeywordBreadcrumb keyword={props.keyword} />
      <div className='Keyword-content'>
        <Tab panes={synonymsByLanguagesPanes} className='Keyword-content--tab' />
      </div>
    </div>
  )

  function tabPaneForLanguage(language) {
    return {
      menuItem: (
        <Menu.Item key={language} className='Keywords-tabItem'>
          <ByLanguageCounter language={language} count={getSynonymCountByLanguage(synonymsByLanguages, language)} />
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane>
          {either.fold(renderErrorMessage, renderNothing, props.isDeletingSynonym)}
          <synonym.components.AddSynonym
            language={language}
            addSynonym={props.addSynonym}
            isAdding={props.isSynonymAdding}
          />
          {props.synonyms && (
            <synonym.components.Synonyms
              keywordID={props.keyword.id}
              synonyms={synonymsByLanguages[language]}
              isDeletingSynonym={props.isDeletingSynonym}
              onDelete={props.onDeleteSynonym}
              updateSynonym={props.updateSynonym}
            />
          )}
        </Tab.Pane>
      )
    }
  }
}

function renderErrorMessage(message) {
  return <DismissErrorMessage header={message} />
}

export default Keyword
