const SET_FETCHING_ALL_DATA = 'tcfdash/analytics/SET_FETCHING_ALL_DATA'

const FETCH_USERS_REQUEST = 'tcfdash/analytics/FETCH_USERS_REQUEST'
const FETCH_USERS_SUCCESS = 'tcfdash/analytics/FETCH_USERS_SUCCESS'
const FETCH_USERS_NO_DATA = 'tcfdash/analytics/FETCH_USERS_NO_DATA'

const FETCH_BOT_CONFIG_REQUEST = 'tcfdash/analytics/FETCH_BOT_CONFIG_REQUEST'
const FETCH_BOT_CONFIG_SUCCESS = 'tcfdash/analytics/FETCH_BOT_CONFIG_SUCCESS'
const FETCH_BOT_CONFIG_NO_DATA = 'tcfdash/analytics/FETCH_BOT_CONFIG_NO_DATA'

const SET_DATE_RANGE = 'tcfdash/analytics/SET_DATE_RANGE'
const SET_ACTIVE_PERIOD = 'tcfdash/analytics/SET_ACTIVE_PERIOD'

const SET_ACTIVE_BOT = 'tcfdash/analytics/SET_ACTIVE_BOT'
const SET_ACTIVE_CHANNEL = 'tcfdash/analytics/SET_ACTIVE_CHANNEL'
const SET_ACTIVE_INTENT = 'tcfdash/analytics/SET_ACTIVE_INTENT'
const SET_ACTIVE_LANGUAGE = 'tcfdash/analytics/SET_ACTIVE_LANGUAGE'

const CLEAR_INTENT = 'tcfdash/training/CLEAR_INTENT'
const CLEAR_INTENT_LINE = 'tcfdash/training/CLEAR_INTENT_LINE'
const CLEAR_INTENT_SELECTED = 'tcfdash/training/CLEAR_INTENT_SELECTED'

const SET_DATA_FILTERS = 'tcfdash/analytics/SET_DATA_FILTERS'

const GET_TRAINING_SENTENCE_REQUEST = 'tcfdash/training/GET_TRAINING_SENTENCE_REQUEST'
const GET_TRAINING_SENTENCE_SUCCESS = 'tcfdash/training/GET_TRAINING_SENTENCE_SUCCESS'
const GET_TRAINING_SENTENCE_FAILURE = 'tcfdash/training/GET_TRAINING_SENTENCE_FAILURE'

const GET_TRAINING_SENTENCENOT_REQUEST = 'tcfdash/training/GET_TRAINING_SENTENCENOT_REQUEST'
const GET_TRAINING_SENTENCENOT_SUCCESS = 'tcfdash/training/GET_TRAINING_SENTENCENOT_SUCCESS'
const GET_TRAINING_SENTENCENOT_FAILURE = 'tcfdash/training/GET_TRAINING_SENTENCENOT_FAILURE'

const GET_TRAINING_SENTENCESTANDBY_REQUEST = 'tcfdash/training/GET_TRAINING_SENTENCESTANDBY_REQUEST'
const GET_TRAINING_SENTENCESTANDBY_SUCCESS = 'tcfdash/training/GET_TRAINING_SENTENCESTANDBY_SUCCESS'
const GET_TRAINING_SENTENCESTANDBY_FAILURE = 'tcfdash/training/GET_TRAINING_SENTENCESTANDBY_FAILURE'

const FETCH_BOT_INTENT_REQUEST = 'tcfdash/training/FETCH_BOT_INTENT_REQUEST'
const FETCH_BOT_INTENT_SUCCESS = 'tcfdash/training/FETCH_BOT_INTENT_SUCCESS'
const FETCH_BOT_INTENT_FAILURE = 'tcfdash/training/FETCH_BOT_INTENT_FAILURE'

const TRAIN_INTENT_REQUEST = 'tcfdash/training/TRAIN_INTENT_REQUEST'
const TRAIN_INTENT_SUCCESS = 'tcfdash/training/TRAIN_INTENT_SUCCESS'
const TRAIN_INTENT_FAILURE = 'tcfdash/training/TRAIN_INTENT_FAILURE'

const ARCHIVED_REQUEST = 'tcfdash/training/ARCHIVED_REQUEST'
const ARCHIVED_SUCCESS = 'tcfdash/training/ARCHIVED_SUCCESS'
const ARCHIVED_FAILURE = 'tcfdash/training/ARCHIVED_FAILURE'

const DELETED_REQUEST = 'tcfdash/training/DELETED_REQUEST'
const DELETED_SUCCESS = 'tcfdash/training/DELETED_SUCCESS'
const DELETED_FAILURE = 'tcfdash/training/DELETED_FAILURE'

const ARCHIVE_LINE_REQUEST = 'tcfdash/training/ARCHIVE_LINE_REQUEST'
const ARCHIVE_LINE_SUCCESS = 'tcfdash/training/ARCHIVE_LINE_SUCCESS'
const ARCHIVE_LINE_FAILURE = 'tcfdash/training/ARCHIVE_LINE_FAILURE'

const DELETE_LINE_REQUEST = 'tcfdash/training/DELETE_LINE_REQUEST'
const DELETE_LINE_SUCCESS = 'tcfdash/training/DELETE_LINE_SUCCESS'
const DELETE_LINE_FAILURE = 'tcfdash/training/DELETE_LINE_FAILURE'

const CHANGE_PHRASE_INTENT_REQUEST = 'tcfdash/training/CHANGE_PHRASE_INTENT_REQUEST'
const CHANGE_PHRASE_INTENT_SUCCESS = 'tcfdash/training/CHANGE_PHRASE_INTENT_SUCCESS'
const CHANGE_PHRASE_INTENT_FAILURE = 'tcfdash/training/CHANGE_PHRASE_INTENT_FAILURE'

const SEND_PHRASE_INTENT_REQUEST = 'tcfdash/training/SEND_PHRASE_INTENT_REQUEST'
const SEND_PHRASE_INTENT_SUCCESS = 'tcfdash/training/SEND_PHRASE_INTENT_SUCCESS'
const SEND_PHRASE_INTENT_FAILURE = 'tcfdash/training/SEND_PHRASE_INTENT_FAILURE'

const CHANGE_GLOBAL_INTENT_REQUEST = 'tcfdash/training/CHANGE_GLOBAL_INTENT_REQUEST'
const CHANGE_GLOBAL_INTENT_SUCCESS = 'tcfdash/training/CHANGE_GLOBAL_INTENT_SUCCESS'
const CHANGE_GLOBAL_INTENT_FAILURE = 'tcfdash/training/CHANGE_GLOBAL_INTENT_FAILURE'

const SEND_GLOBAL_INTENT_REQUEST = 'tcfdash/training/SEND_GLOBAL_INTENT_REQUEST'
const SEND_GLOBAL_INTENT_SUCCESS = 'tcfdash/training/SEND_GLOBAL_INTENT_SUCCESS'
const SEND_GLOBAL_INTENT_FAILURE = 'tcfdash/training/SEND_GLOBAL_INTENT_FAILURE'

const TOGGLE_LINE = 'tcfdash/training/TOGGLE_LINE'
const TOGGLE_ALL = 'tcfdash/training/TOGGLE_ALL'
const ASSIGN_SELECTED = 'tcfdash/training/ASSIGN_SELECTED'
const ASSIGN_LINE = 'tcfdash/training/ASSIGN_LINE'
const ASSIGN_LIST_NAME = 'tcfdash/training/ASSIGN_LIST_NAME'

const TRAIN_LINE_REQUEST = 'tcfdash/training/TRAIN_LINE_REQUEST'
const TRAIN_LINE_SUCCESS = 'tcfdash/training/TRAIN_LINE_SUCCESS'
const TRAIN_LINE_FAILURE = 'tcfdash/training/TRAIN_LINE_FAILURE'

const TRAIN_SELECTED_REQUEST = 'tcfdash/training/TRAIN_SELECTED_REQUEST'
const TRAIN_SELECTED_SUCCESS = 'tcfdash/training/TRAIN_SELECTED_SUCCESS'
const TRAIN_SELECTED_FAILURE = 'tcfdash/training/TRAIN_SELECTED_FAILURE'

const ARCHIVE_SELECTED_REQUEST = 'tcfdash/training/ARCHIVE_SELECTED_REQUEST'
const ARCHIVE_SELECTED_SUCCESS = 'tcfdash/training/ARCHIVE_SELECTED_SUCCESS'
const ARCHIVE_SELECTED_FAILURE = 'tcfdash/training/ARCHIVE_SELECTED_FAILURE'

const DELETE_SELECTED_REQUEST = 'tcfdash/training/DELETE_SELECTED_REQUEST'
const DELETE_SELECTED_SUCCESS = 'tcfdash/training/DELETE_SELECTED_SUCCESS'
const DELETE_SELECTED_FAILURE = 'tcfdash/training/DELETE_SELECTED_FAILURE'

export default {
  SET_FETCHING_ALL_DATA,

  SET_DATE_RANGE,
  SET_ACTIVE_PERIOD,

  TRAIN_LINE_REQUEST,
  TRAIN_LINE_SUCCESS,
  TRAIN_LINE_FAILURE,

  TRAIN_SELECTED_REQUEST,
  TRAIN_SELECTED_SUCCESS,
  TRAIN_SELECTED_FAILURE,

  ARCHIVE_SELECTED_REQUEST,
  ARCHIVE_SELECTED_SUCCESS,
  ARCHIVE_SELECTED_FAILURE,

  DELETE_SELECTED_REQUEST,
  DELETE_SELECTED_SUCCESS,
  DELETE_SELECTED_FAILURE,

  SET_DATA_FILTERS,

  SET_ACTIVE_BOT,
  SET_ACTIVE_CHANNEL,
  SET_ACTIVE_INTENT,
  SET_ACTIVE_LANGUAGE,

  CLEAR_INTENT,
  CLEAR_INTENT_LINE,
  CLEAR_INTENT_SELECTED,

  GET_TRAINING_SENTENCE_REQUEST,
  GET_TRAINING_SENTENCE_SUCCESS,
  GET_TRAINING_SENTENCE_FAILURE,

  GET_TRAINING_SENTENCENOT_REQUEST,
  GET_TRAINING_SENTENCENOT_SUCCESS,
  GET_TRAINING_SENTENCENOT_FAILURE,

  GET_TRAINING_SENTENCESTANDBY_REQUEST,
  GET_TRAINING_SENTENCESTANDBY_SUCCESS,
  GET_TRAINING_SENTENCESTANDBY_FAILURE,

  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_NO_DATA,

  FETCH_BOT_CONFIG_REQUEST,
  FETCH_BOT_CONFIG_SUCCESS,
  FETCH_BOT_CONFIG_NO_DATA,

  FETCH_BOT_INTENT_REQUEST,
  FETCH_BOT_INTENT_SUCCESS,
  FETCH_BOT_INTENT_FAILURE,

  TRAIN_INTENT_REQUEST,
  TRAIN_INTENT_SUCCESS,
  TRAIN_INTENT_FAILURE,

  ARCHIVED_REQUEST,
  ARCHIVED_SUCCESS,
  ARCHIVED_FAILURE,

  DELETED_REQUEST,
  DELETED_SUCCESS,
  DELETED_FAILURE,

  ARCHIVE_LINE_REQUEST,
  ARCHIVE_LINE_SUCCESS,
  ARCHIVE_LINE_FAILURE,
  DELETE_LINE_REQUEST,
  DELETE_LINE_SUCCESS,
  DELETE_LINE_FAILURE,
  CHANGE_PHRASE_INTENT_REQUEST,
  CHANGE_PHRASE_INTENT_SUCCESS,
  CHANGE_PHRASE_INTENT_FAILURE,
  SEND_PHRASE_INTENT_REQUEST,
  SEND_PHRASE_INTENT_SUCCESS,
  SEND_PHRASE_INTENT_FAILURE,
  CHANGE_GLOBAL_INTENT_REQUEST,
  CHANGE_GLOBAL_INTENT_SUCCESS,
  CHANGE_GLOBAL_INTENT_FAILURE,
  SEND_GLOBAL_INTENT_REQUEST,
  SEND_GLOBAL_INTENT_SUCCESS,
  SEND_GLOBAL_INTENT_FAILURE,

  TOGGLE_LINE,
  TOGGLE_ALL,
  ASSIGN_SELECTED,
  ASSIGN_LINE,
  ASSIGN_LIST_NAME
}
