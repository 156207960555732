import { either } from '@sbizeul/fp-flow'

import * as actions from './actions'

export const initialState = {
  isPublishing: either.of(false)
}

export default function(state = initialState, action) {
  switch (action.type) {
  case actions.PUBLISH_REQUEST:
    return { isPublishing: either.of(true) }
  case actions.PUBLISH_SUCCESS:
    return { isPublishing: either.of(false) }
  case actions.PUBLISH_FAILURE:
    return { isPublishing: either.left(action.payload) }
  default:
    return state
  }
}
