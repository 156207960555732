import { connect } from 'react-redux'

import bot from 'modules/bot'
import question from 'modules/question'
import trainingQuestion from 'modules/trainingQuestion'

import QaQuestionPage from '../components/QaQuestionPage'
import * as actions from '../actions'
import * as typedActions from '../actions.typed'
import * as selectors from '../selectors'

const mapStateToProps = state => ({
  labeledQuestions: question.selectors.getLabeledQuestions(state),
  questionReferences: question.selectors.getQuestionReferences(state),
  isPostingQuestion: question.selectors.getIsPosting(state),
  isCreatingSection: selectors.getIsCreatingSection(state),
  isUpdatingSection: selectors.getIsUpdatingSection(state),
  isDeletingSection: selectors.getIsDeletingSection(state),
  isImporting: selectors.getIsImporting(state),
  isDeleting: selectors.getIsDeleting(state),
  isUpdatingQuestion: selectors.getIsUpdatingQuestion(state),
  trainingQuestionsById: trainingQuestion.selectors.getById(state),
  isChangingTrainingQuestion: trainingQuestion.selectors.getIsChanging(state),
  collapsibleSectionsOpeningStatus: state.ui.qa.collapsibleSectionsOpeningStatus
})

const mapDispatchToProps = {
  fetchLabeledQuestions: question.actions.fetchLabeledQuestions,
  fetchQuestionReferences: question.actions.fetchQuestionReferences,
  importFile: actions.importFile,
  createQuestion: question.actions.create,
  createSection: question.actions.createSection,
  navigateToQuestion: question.actions.selectQuestion,
  fetchTrainingQuestions: trainingQuestion.actions.fetchAll,
  createTraining: trainingQuestion.actions.createOne,
  deleteQa: actions.deleteOneQnA,
  deleteTraining: trainingQuestion.actions.deleteOne,
  saveTraining: trainingQuestion.actions.saveOne,
  saveQuestion: question.actions.saveOne,
  moveSection: question.actions.moveSection,
  updateSection: question.actions.saveSection,
  deleteSection: question.actions.deleteSection,
  archiveQuestion: question.actions.archiveQuestion,
  unarchiveQuestion: question.actions.unarchiveQuestion,
  saveOpeningStatus: typedActions.saveOpeningStatus,
  mergeQuestion: question.actions.mergeQuestion
}

export default connect(mapStateToProps, mapDispatchToProps)(QaQuestionPage)
