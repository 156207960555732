
import * as React from 'react'
import { either, remoteData } from '@sbizeul/fp-flow'
import * as R from 'ramda'

import TextInputMaterial from 'components/TextInputMaterial'
import { didMount, renderInlineLoader, renderServerFailure } from 'helpers/react'
import TrainingQuestion from '../TrainingQuestion'

import { ListTrainingQuestionsProps, TrainingQuestion as TrainingQuestionT } from '../../types'

import './styles.css'
import { Question } from 'modules/question/types'

const MIN_INPUT_LENGTH = 2
const validateTQInputLength = (text: string) => R.not(R.isEmpty(text)) && text.length >= MIN_INPUT_LENGTH
const List = ({
  createTraining,
  deleteTraining,
  saveTraining,

  isChangingTrainingQuestion,
  questionId,
  trainingQuestions,
  questionTemplateId
}: ListTrainingQuestionsProps) => {
  const handleEnter = (text: string) => createTraining({ questionId, text })

  const isLoading = R.either(remoteData.isLoading, remoteData.isNotAsked)

  const renderInput = () => (
    <TextInputMaterial
      onEnter={handleEnter}
      className='trainingQuestion--input'
      placeholder='e.g. « Can I talk to a human? »'
      isLoading={either.getOrElse(() => false, isChangingTrainingQuestion)}
      disabled={isLoading(trainingQuestions)}
      autoFocus
      validators={[validateTQInputLength]}
    />
  )

  const renderTitle = () => (
    <h2 className='trainingQuestion--title'>Add training</h2>
  )

  const renderTemplateInfo = () => {
    return (
      questionTemplateId && <div className='trainingQuestion--parent-block'>
        <div className='trainingQuestion--pretrained-bg'>
          <div className='trainingQuestion--pretrained-bg-bubble trainingQuestion--pretrained-bg-bubble-dark'></div>
          <div className='trainingQuestion--pretrained-bg-bubble trainingQuestion--pretrained-bg-bubble-normal'></div>
          <div className='trainingQuestion--pretrained-bg-bubble trainingQuestion--pretrained-bg-bubble-clear'></div>
        </div>
        <div className='trainingQuestion--pretrained-content'>
          <span role="img" arial-label="Cloud" aria-labelledby="pre-trained logs">☁️</span>
          <span> +80 pre-trained logs </span>
        </div>
        </div>
    )
  }

  const renderTrainingQuestion = (trainingQuestion: TrainingQuestionT) => {
    return (
      <TrainingQuestion
        deleteTraining={deleteTraining}
        saveTraining={saveTraining}
        trainingQuestion={trainingQuestion}
        key={trainingQuestion.id}
      />
    )
  }
  const renderList = (trainingQuestions: ReadonlyArray<TrainingQuestionT>) => (
    <React.Fragment>
      {renderTitle()}
      {renderInput()}
      {renderTemplateInfo()}
      {R.map(renderTrainingQuestion, trainingQuestions)}
    </React.Fragment>
  )

  return (
    <div className='trainingQuestions'>
      {remoteData.fold({
        Success: renderList,
        Loading: () => renderInlineLoader('fetching training questions'),
        NotAsked: () => renderInlineLoader('fetching training questions'),
        Failure: renderServerFailure
      })(trainingQuestions)}
    </div>
  )
}

export default didMount((props: ListTrainingQuestionsProps) => props.fetchTrainingQuestions(props.questionId), List)
