import * as R from 'ramda'
import { maybe, remoteData, pipe } from '@sbizeul/fp-flow'
import { createSelector } from 'reselect'

import * as map from '../../helpers/map'
import { toOption } from './models'

import bot from '../bot'

export const getState = state => state.channel

export const getAll = createSelector(getState, state => remoteData.map(map.toArray, state.all))

export const getByBotId = R.curry((state, botId) =>
  pipe(map.get(botId), maybe.getOrElse(remoteData.notAsked))(state.channel.byBotId)
)

export const getBySelectedBot = state =>
  pipe(
    maybe.chain(id => map.get(id, state.channel.byBotId)),
    maybe.getOrElse(remoteData.notAsked)
  )(bot.selectors.getSelectedId(state))

export const getBySelectedBotToOption = createSelector([getBySelectedBot], channels =>
  R.map(
    toOption,
    remoteData.getOrElse(() => [], channels)
  )
)
