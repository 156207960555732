import React from 'react'
import * as R from 'ramda'
import { DateRangePicker } from 'react-dates'

import { isOutOfRange } from '../../helpers/moment'
import 'react-dates/lib/css/_datepicker.css'
import './DateRangeComponent.css'
import PreselectButtons from './PreselectButtons'

export default class DateRangeComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      focusedInput: undefined
    }
  }
  render() {
    const { startDate, endDate, onDatesChange } = this.props
    const outOfRange = isOutOfRange(1000)
    const resetFocusInput = () => this.setState({ focusedInput: undefined })

    return (
      <div className='DateRangeComponent'>
        <DateRangePicker
          showClearDates
          showDefaultInputIcon
          reopenPickerOnClearDates
          hideKeyboardShortcutsPanel
          displayFormat='MMM D'
          minimumNights={0}
          isOutsideRange={day =>
            outOfRange({
              startDate,
              endDate,
              focusedInput: this.state.focusedInput,
              day
            })
          }
          startDate={startDate}
          startDateId={'startDate'}
          endDate={endDate}
          endDateId={'endDate'}
          onDatesChange={onDatesChange}
          focusedInput={this.state.focusedInput}
          onFocusChange={focusedInput => this.setState({ focusedInput })}
          firstDayOfWeek={1}
          renderCalendarInfo={() => <PreselectButtons onDatesChange={R.pipe(onDatesChange, resetFocusInput)} />}
        />
      </div>
    )
  }
}
