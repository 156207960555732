export const SELECT_USER = 'admin/SELECT_USER'
export const UNSELECT_USER = 'admin/UNSELECT_USER'
export const SET_MODE = 'admin/SET_MODE'
export const NAVIGATE_TO_USER_LIST = 'admin/NAVIGATE_TO_USER_LIST'
export const SUBMIT_USER_REQUEST = 'admin/SUBMIT_USER_REQUEST'
export const SUBMIT_USER_SUCCESS = 'admin/SUBMIT_USER_SUCCESS'
export const SUBMIT_USER_FAILURE = 'admin/SUBMIT_USER_FAILURE'

export const DELETE_USER_REQUEST = 'admin/DELETE_USER_REQUEST'
export const DELETE_USER_SUCCESS = 'admin/DELETE_USER_SUCCESS'
export const DELETE_USER_FAILURE = 'admin/DELETE_USER_FAILURE'

export const selectUser = id => {
  return {
    type: SELECT_USER,
    payload: id
  }
}

export const unselectUser = () => {
  return {
    type: UNSELECT_USER
  }
}

export const setMode = mode => {
  return {
    type: SET_MODE,
    payload: mode
  }
}

export const navigateToUserList = () => {
  return { type: NAVIGATE_TO_USER_LIST }
}

export const submitUser = user => {
  return {
    type: SUBMIT_USER_REQUEST,
    payload: user
  }
}

export const submitUserSuccess = () => {
  return {
    type: SUBMIT_USER_SUCCESS
  }
}

export const submitUserFailure = err => {
  return {
    type: SUBMIT_USER_FAILURE,
    payload: err
  }
}

export const deleteUser = userId => ({ type: DELETE_USER_REQUEST, payload: { userId } })

export const deleteUserSuccess = () => ({ type: DELETE_USER_SUCCESS })

export const deleteUserFailure = error => ({ type: DELETE_USER_FAILURE, payload: error })
