import { Maybe } from '@sbizeul/fp-flow'
import { Option } from 'fp-ts/lib/Option'

import { ServerFailure, Id } from 'types'
import {
  SaveOneParam as SaveDefaultParam,
  Answer
} from 'modules/answer/types'

import {
  AnswerInputContent,
  CreateFormSkill,
  CreateFormSkillSuccess,
  CreateFormSkillFailure,
  CreateFormContent,
  CreateFormEmailContent,
  CreateFormFileContent,
  CreateButtonContent,
  CreateImageContent,
  CreateTagAgentContent,
  CreateTextContent,
  CreateQuestionContent,
  CreateScenarioAction,
  DefaultSkill,
  DownloadFormDataAsExcelRequest,
  DownloadFormDataAsExcelFailure,
  DownloadFormDataAsExcelSuccess,
  FetchDefaultRequest,
  FetchDefaultSuccess,
  FetchDefaultFailure,
  FetchFormsRequest,
  FormSkill,
  FetchFormSuccess,
  FetchFormsSuccess,
  FetchFormsFailure,
  FetchFormRequest,
  FormSkillContent,
  FetchFormFailure,
  FetchAllDefaultRequest,
  FetchAllDefaultSuccess,
  ParamsId,
  FetchAllDefaultFailure,
  FetchAllRequest,
  FetchAllSuccess,
  FetchAllFailure,
  FetchSkillSuccess,
  FetchSkillRequest,
  FetchSkillFailure,
  InputId,
  QuestionAndButtonIDs,
  RemoveFormContent,
  ReceivedSkill,
  RemoveQuestionContent,
  RemoveScenarioAction,
  SaveDefaultRequest,
  SaveDefaultSuccess,
  SaveDefaultFailure,
  SaveFormRequest,
  SaveFormSuccess,
  SaveFormFailure,
  SaveSkillRequest,
  SaveSkillSuccess,
  SaveSkillFailure,
  Skill,
  UpdateAnswerContent,
  Question,
  UpdateFormContent,
  CreateAdvancedSkillRequest,
  CreateAdvancedSkillSuccess,
  CreateAdvancedSkillFailure,
  FetchAllAdvancedRequest,
  FetchAllAdvancedSuccess,
  FetchAllAdvancedFailure,
  UploadImageRequestPayload,
  UploadImageSuccessPayload,
  UploadImageFailurePayload,
  UpdateQuestionAction,
  UpdateQuestionContent,
  UpdateQuestionContentParams,
  UploadImageRequest,
  UploadImageSuccess,
  UploadImageFailure,
  FetchAllSimplifiedSkills,
  FetchAllSimplifiedTags,
  UpdateFormConclusion,
  FormResponseContent,
  SaveFormResponseRequest,
  SaveFormResponseParams,
  FetchFormResponseRequest,
  FetchFormResponseSuccess,
  FetchFormResponseFailure,
  SaveFormResponseSuccess,
  SaveFormResponseFailure,
  FetchSkillReferencesFailure,
  FetchSkillReferencesFailureParam,
  FetchSkillReferencesRequest,
  FetchSkillReferencesRequestParam,
  FetchSkillReferencesSuccess,
  FetchSkillReferencesSuccessParam,
  DeleteSkillRequest,
  DeleteSkillRequestParam,
  DeleteSkillSuccess,
  DeleteSkillSuccessParam,
  DeleteSkillFailure,
  DeleteSkillFailureParam,
  FetchFormReferencesFailure,
  FetchFormReferencesFailureParam,
  FetchFormReferencesRequest,
  FetchFormReferencesRequestParam,
  FetchFormReferencesSuccess,
  FetchFormReferencesSuccessParam,
  DeleteFormRequest,
  DeleteFormRequestParam,
  DeleteFormSuccess,
  DeleteFormSuccessParam,
  DeleteFormFailure,
  DeleteFormFailureParam
} from './types'

import { emptyQuestion, emptyAnswer } from './models'

export const FETCH_DEFAULT_SKILL_REQUEST = 'skill/FETCH_DEFAULT_SKILL_REQUEST'
export const FETCH_DEFAULT_SKILL_FAILURE = 'skill/FETCH_DEFAULT_SKILL_FAILURE'
export const FETCH_DEFAULT_SKILL_SUCCESS = 'skill/FETCH_DEFAULT_SKILL_SUCCESS'

export const FETCH_SKILL_REQUEST = 'skill/FETCH_SKILL_REQUEST'
export const FETCH_SKILL_FAILURE = 'skill/FETCH_SKILL_FAILURE'
export const FETCH_SKILL_SUCCESS = 'skill/FETCH_SKILL_SUCCESS'

export const FETCH_FORM_SKILLS_REQUEST = 'skill/FETCH_FORM_SKILLS_REQUEST'
export const FETCH_FORM_SKILLS_FAILURE = 'skill/FETCH_FORM_SKILLS_FAILURE'
export const FETCH_FORM_SKILLS_SUCCESS = 'skill/FETCH_FORM_SKILLS_SUCCESS'

export const FETCH_FORM_SKILL_REQUEST = 'skill/FETCH_FORM_SKILL_REQUEST'
export const FETCH_FORM_SKILL_FAILURE = 'skill/FETCH_FORM_SKILL_FAILURE'
export const FETCH_FORM_SKILL_SUCCESS = 'skill/FETCH_FORM_SKILL_SUCCESS'

export const FETCH_FORM_RESPONSE_REQUEST = 'skill/FETCH_FORM_RESPONSE_REQUEST'
export const FETCH_FORM_RESPONSE_SUCCESS = 'skill/FETCH_FORM_RESPONSE_SUCCESS'
export const FETCH_FORM_RESPONSE_FAILURE = 'skill/FETCH_FORM_RESPONSE_FAILURE'

export const FETCH_ALL_DEFAULT_SKILLS_REQUEST = 'skill/FETCH_ALL_DEFAULT_SKILLS_REQUEST'
export const FETCH_ALL_DEFAULT_SKILLS_FAILURE = 'skill/FETCH_ALL_DEFAULT_SKILLS_FAILURE'
export const FETCH_ALL_DEFAULT_SKILLS_SUCCESS = 'skill/FETCH_ALL_DEFAULT_SKILLS_SUCCESS'

export const FETCH_ALL_SKILLS_REQUEST = 'skill/FETCH_ALL_SKILLS_REQUEST'
export const FETCH_ALL_SKILLS_FAILURE = 'skill/FETCH_ALL_SKILLS_FAILURE'
export const FETCH_ALL_SKILLS_SUCCESS = 'skill/FETCH_ALL_SKILLS_SUCCESS'

export const FETCH_ALL_SIMPLIFIED_SKILLS_REQUEST = 'skill/FETCH_ALL_SIMPLIFIED_SKILLS_REQUEST'

export const FETCH_ALL_SIMPLIFIED_TAGS_REQUEST = 'skill/FETCH_ALL_SIMPLIFIED_TAGS_REQUEST'

export const SAVE_DEFAULT_SKILL_REQUEST = 'skill/SAVE_DEFAULT_SKILL_REQUEST'
export const SAVE_DEFAULT_SKILL_SUCCESS = 'skill/SAVE_DEFAULT_SKILL_SUCCESS'
export const SAVE_DEFAULT_SKILL_FAILURE = 'skill/SAVE_DEFAULT_SKILL_FAILURE'

export const CREATE_REQUEST = 'skill/CREATE_REQUEST'
export const CREATE_SUCCESS = 'skill/CREATE_SUCCESS'
export const CREATE_FAILURE = 'skill/CREATE_FAILURE'

export const CREATE_FORM_REQUEST = 'skill/CREATE_FORM_REQUEST'
export const CREATE_FORM_SUCCESS = 'skill/CREATE_FORM_SUCCESS'
export const CREATE_FORM_FAILURE = 'skill/CREATE_FORM_FAILURE'

export const CREATE_ADVANCED_REQUEST = 'skill/CREATE_ADVANCED_REQUEST'
export const CREATE_ADVANCED_SUCCESS = 'skill/CREATE_ADVANCED_SUCCESS'
export const CREATE_ADVANCED_FAILURE = 'skill/CREATE_ADVANCED_FAILURE'

export const CREATE_FORM_CONTENT = 'skill/CREATE_FORM_CONTENT'
export const UPDATE_FORM_CONTENT = 'skill/UPDATE_FORM_CONTENT'
export const REMOVE_FORM_CONTENT = 'skill/REMOVE_FORM_CONTENT'

export const CREATE_FORM_EMAIL_CONTENT = 'skill/CREATE_FORM_EMAIL_CONTENT'
export const CREATE_FORM_FILE_CONTENT = 'skill/CREATE_FORM_FILE_CONTENT'

export const UPDATE_FORM_CONCLUSION = 'skill/UPDATE_FORM_CONCLUSION'

export const SAVE_FORM_RESPONSE_REQUEST = 'skill/SAVE_FORM_RESPONSE_REQUEST'
export const SAVE_FORM_RESPONSE_SUCCESS = 'skill/SAVE_FORM_RESPONSE_SUCCESS'
export const SAVE_FORM_RESPONSE_FAILURE = 'skill/SAVE_FORM_RESPONSE_FAILURE'

export const CREATE_SCENARIO = 'skill/CREATE_SCENARIO'

export const CREATE_QUESTION_CONTENT = 'skill/CREATE_QUESTION_CONTENT'
export const UPDATE_QUESTION_CONTENT = 'skill/UPDATE_QUESTION_CONTENT'
export const REMOVE_QUESTION_CONTENT = 'skill/REMOVE_QUESTION_CONTENT'

export const CREATE_ANSWER_BUTTON_CONTENT = 'skill/CREATE_ANSWER_BUTTON_CONTENT'
export const CREATE_ANSWER_IMAGE_CONTENT = 'skill/CREATE_ANSWER_IMAGE_CONTENT'
export const CREATE_ANSWER_TAG_CONTENT = 'skill/CREATE_ANSWER_TAG_CONTENT'
export const CREATE_ANSWER_TEXT_CONTENT = 'skill/CREATE_ANSWER_TEXT_CONTENT'

export const UPDATE_ANSWER_SKILL_CONTENT = 'skill/UPDATE_ANSWER_SKILL_CONTENT'

export const UPDATE_QUESTION = 'skill/UPDATE_QUESTION'

export const UPLOAD_IMAGE_REQUEST = 'skill/UPLOAD_IMAGE_REQUEST'
export const UPLOAD_IMAGE_SUCCESS = 'skill/UPLOAD_IMAGE_SUCCESS'
export const UPLOAD_IMAGE_FAILURE = 'skill/UPLOAD_IMAGE_FAILURE'

export const REMOVE_SCENARIO = 'skill/REMOVE_SCENARIO'

export const SAVE_FORM_REQUEST = 'skill/SAVE_FORM_REQUEST'
export const SAVE_FORM_SUCCESS = 'skill/SAVE_FORM_SUCCESS'
export const SAVE_FORM_FAILURE = 'skill/SAVE_FORM_FAILURE'

export const SAVE_SKILL_REQUEST = 'skill/SAVE_SKILL_REQUEST'
export const SAVE_SKILL_SUCCESS = 'skill/SAVE_SKILL_SUCCESS'
export const SAVE_SKILL_FAILURE = 'skill/SAVE_SKILL_FAILURE'

export const DOWNLOAD_FORM_DATA_REQUEST = 'skill/DOWNLOAD_FORM_DATA_REQUEST'
export const DOWNLOAD_FORM_DATA_FAILURE = 'skill/DOWNLOAD_FORM_DATA_FAILURE'
export const DOWNLOAD_FORM_DATA_SUCCESS = 'skill/DOWNLOAD_FORM_DATA_SUCCESS'

export const FETCH_ALL_ADVANCED_REQUEST = 'skill/FETCH_ALL_ADVANCED_REQUEST'
export const FETCH_ALL_ADVANCED_FAILURE = 'skill/FETCH_ALL_ADVANCED_FAILURE'
export const FETCH_ALL_ADVANCED_SUCCESS = 'skill/FETCH_ALL_ADVANCED_SUCCESS'

export const FETCH_SKILL_REFERENCES_REQUEST = 'skill/FETCH_SKILL_REFERENCES_REQUEST'
export const FETCH_SKILL_REFERENCES_SUCCESS = 'skill/FETCH_SKILL_REFERENCES_SUCCESS'
export const FETCH_SKILL_REFERENCES_FAILURE = 'skill/FETCH_SKILL_REFERENCES_FAILURE'

export const DELETE_SKILL_REQUEST = 'skill/DELETE_SKILL_REQUEST'
export const DELETE_SKILL_SUCCESS = 'skill/DELETE_SKILL_SUCCESS'
export const DELETE_SKILL_FAILURE = 'skill/DELETE_SKILL_FAILURE'

export const FETCH_FORM_REFERENCES_REQUEST = 'skill/FETCH_FORM_REFERENCES_REQUEST'
export const FETCH_FORM_REFERENCES_SUCCESS = 'skill/FETCH_FORM_REFERENCES_SUCCESS'
export const FETCH_FORM_REFERENCES_FAILURE = 'skill/FETCH_FORM_REFERENCES_FAILURE'

export const DELETE_FORM_REQUEST = 'skill/DELETE_FORM_REQUEST'
export const DELETE_FORM_SUCCESS = 'skill/DELETE_FORM_SUCCESS'
export const DELETE_FORM_FAILURE = 'skill/DELETE_FORM_FAILURE'

export const fetchDefault: () => FetchDefaultRequest = () => ({
  type: FETCH_DEFAULT_SKILL_REQUEST
})

export const fetchDefaultSuccess: (defaultSkill: DefaultSkill) => FetchDefaultSuccess = defaultSkill => ({
  type: FETCH_DEFAULT_SKILL_SUCCESS,
  payload: defaultSkill
})

export const fetchDefaultFailure: (error: ServerFailure) => FetchDefaultFailure = error => ({
  type: FETCH_DEFAULT_SKILL_FAILURE,
  payload: error
})

export const fetchForms: () => FetchFormsRequest = () => ({
  type: FETCH_FORM_SKILLS_REQUEST
})

export const fetchFormsSuccess: (formSkill: FormSkill[]) => FetchFormsSuccess = formSkill => ({
  type: FETCH_FORM_SKILLS_SUCCESS,
  payload: formSkill
})

export const fetchFormsFailure: (error: ServerFailure) => FetchFormsFailure = error => ({
  type: FETCH_FORM_SKILLS_FAILURE,
  payload: error
})

export const fetchSkill: (id: Option<Id>) => FetchSkillRequest = id => ({
  type: FETCH_SKILL_REQUEST,
  payload: id
})

export const fetchSkillSuccess: (skill: Skill) => FetchSkillSuccess = skill => ({
  type: FETCH_SKILL_SUCCESS,
  payload: skill
})

export const fetchSkillFailure: (error: ServerFailure) => FetchSkillFailure = error => ({
  type: FETCH_SKILL_FAILURE,
  payload: error
})

export const fetchAllSimplifiedSkills: () => FetchAllSimplifiedSkills = () => ({
  type: FETCH_ALL_SIMPLIFIED_SKILLS_REQUEST
})

export const fetchAllSimplifiedTags: () => FetchAllSimplifiedTags = () => ({
  type: FETCH_ALL_SIMPLIFIED_TAGS_REQUEST
})

export const fetchForm: (id: Maybe<Id>) => FetchFormRequest = id => ({
  type: FETCH_FORM_SKILL_REQUEST,
  payload: id
})

export const fetchFormSuccess: (formSkill: FormSkillContent) => FetchFormSuccess = formSkill => ({
  type: FETCH_FORM_SKILL_SUCCESS,
  payload: formSkill
})

export const fetchFormFailure: (error: ServerFailure) => FetchFormFailure = error => ({
  type: FETCH_FORM_SKILL_FAILURE,
  payload: error
})

export const fetchFormResponse: (id: Maybe<Id>) => FetchFormResponseRequest = id => ({
  type: FETCH_FORM_RESPONSE_REQUEST,
  payload: id
})

export const fetchFormResponseSuccess: (formResponse: FormResponseContent) => FetchFormResponseSuccess = formResponse => ({
  type: FETCH_FORM_RESPONSE_SUCCESS,
  payload: formResponse
})

export const fetchFormResponseFailure: (error: ServerFailure) => FetchFormResponseFailure = error => ({
  type: FETCH_FORM_RESPONSE_FAILURE,
  payload: error
})

export const fetchAllDefault: () => FetchAllDefaultRequest = () => ({
  type: FETCH_ALL_DEFAULT_SKILLS_REQUEST
})

export const fetchAllDefaultSuccess: (skills: ReceivedSkill[]) => FetchAllDefaultSuccess = skills => ({
  type: FETCH_ALL_DEFAULT_SKILLS_SUCCESS,
  payload: skills
})

export const fetchAllDefaultFailure: (error: ServerFailure) => FetchAllDefaultFailure = error => ({
  type: FETCH_ALL_DEFAULT_SKILLS_FAILURE,
  payload: error
})

export const fetchAll: () => FetchAllRequest = () => ({
  type: FETCH_ALL_SKILLS_REQUEST
})

export const fetchAllSuccess: (skills: FormSkill[]) => FetchAllSuccess = skills => ({
  type: FETCH_ALL_SKILLS_SUCCESS,
  payload: skills
})

export const fetchAllFailure: (error: ServerFailure) => FetchAllFailure = error => ({
  type: FETCH_ALL_SKILLS_FAILURE,
  payload: error
})

export const save: (newContent: SaveDefaultParam) => SaveDefaultRequest = newContent => ({
  type: SAVE_DEFAULT_SKILL_REQUEST,
  payload: newContent
})

export const saveSuccess: (id: Id) => SaveDefaultSuccess = id => ({
  type: SAVE_DEFAULT_SKILL_SUCCESS,
  payload: id
})

export const saveFailure: (error: ServerFailure) => SaveDefaultFailure = error => ({
  type: SAVE_DEFAULT_SKILL_FAILURE,
  payload: error
})

export const createFormSkill: (name: string) => CreateFormSkill = name => ({
  type: CREATE_FORM_REQUEST,
  payload: name
})

export const createFormSkillSuccess: (id: Id) => CreateFormSkillSuccess = id => ({
  type: CREATE_FORM_SUCCESS,
  payload: id
})

export const createFormSkillFailure: (error: ServerFailure) => CreateFormSkillFailure = error => ({
  type: CREATE_FORM_FAILURE,
  payload: error
})

export const createFormContent: () => CreateFormContent = () => ({
  type: CREATE_FORM_CONTENT
})

export const createFormEmailContent: () => CreateFormEmailContent = () => ({
  type: CREATE_FORM_EMAIL_CONTENT
})

export const createFormFileContent: () => CreateFormFileContent = () => ({
  type: CREATE_FORM_FILE_CONTENT
})

export const updateFormContent: (content: { content: InputId }) => UpdateFormContent = content => ({
  type: UPDATE_FORM_CONTENT,
  payload: content
})

export const updateFormConclusion: (conclusionMessage: Maybe<Answer>) => UpdateFormConclusion = conclusionMessage => ({
  type: UPDATE_FORM_CONCLUSION,
  payload: conclusionMessage
})

export const saveFormReponse: (formResponse: SaveFormResponseParams) => SaveFormResponseRequest = formResponse => ({
  type: SAVE_FORM_RESPONSE_REQUEST,
  payload: formResponse
})

export const saveFormResponseSuccess: () => SaveFormResponseSuccess = () => ({
  type: SAVE_FORM_RESPONSE_SUCCESS
})

export const saveFormResponseFailure: (error: ServerFailure) => SaveFormResponseFailure = error => ({
  type: SAVE_FORM_RESPONSE_FAILURE,
  payload: error
})

export const removeFormContent: (id: ParamsId) => RemoveFormContent = id => ({
  type: REMOVE_FORM_CONTENT,
  payload: id
})

export const createScenario: (scenarios: ReadonlyArray<QuestionAndButtonIDs>) => CreateScenarioAction = (scenarios) => ({
  type: CREATE_SCENARIO,
  payload: emptyAnswer(scenarios)
})

export const createQuestionContent: () => CreateQuestionContent = () => ({
  type: CREATE_QUESTION_CONTENT,
  payload: emptyQuestion()
})

export const updateQuestionContent: (input: UpdateQuestionContentParams) => UpdateQuestionContent = input => ({
  type: UPDATE_QUESTION_CONTENT,
  payload: input
})

export const removeQuestionContent: (id: ParamsId) => RemoveQuestionContent = id => ({
  type: REMOVE_QUESTION_CONTENT,
  payload: id
})

export const createButtonContent: (index: number) => CreateButtonContent = index => ({
  type: CREATE_ANSWER_BUTTON_CONTENT,
  payload: { index }
})
export const createImageContent: (index: number) => CreateImageContent = index => ({
  type: CREATE_ANSWER_IMAGE_CONTENT,
  payload: { index }
})
export const createTagAgentContent: (index: number) => CreateTagAgentContent = index => ({
  type: CREATE_ANSWER_TAG_CONTENT,
  payload: { index }
})
export const createTextContent: (index: number) => CreateTextContent = index => ({
  type: CREATE_ANSWER_TEXT_CONTENT,
  payload: { index }
})

export const updateAnswerContent: (input: AnswerInputContent) => UpdateAnswerContent = (input) => ({
  type: UPDATE_ANSWER_SKILL_CONTENT,
  payload: input
})

export const uploadImageRequest: (image: UploadImageRequestPayload) => UploadImageRequest = image => ({
  type: UPLOAD_IMAGE_REQUEST,
  payload: image
})

export const uploadImageSuccess: (url: UploadImageSuccessPayload) => UploadImageSuccess = url => ({
  type: UPLOAD_IMAGE_SUCCESS,
  payload: url
})

export const uploadImageFailure: (error: UploadImageFailurePayload) => UploadImageFailure = error => ({
  type: UPLOAD_IMAGE_FAILURE,
  payload: error
})

export const updateQuestion: (question: Question) => UpdateQuestionAction = question => ({
  type: UPDATE_QUESTION,
  payload: question
})

export const removeScenario: (index: number) => RemoveScenarioAction = index => ({
  type: REMOVE_SCENARIO,
  payload: index
})

export const saveFormRequest: (form: FormSkillContent) => SaveFormRequest = form => ({
  type: SAVE_FORM_REQUEST,
  payload: form
})
export const saveFormSuccess: () => SaveFormSuccess = () => ({
  type: SAVE_FORM_SUCCESS
})
export const saveFormFailure: (error: ServerFailure) => SaveFormFailure = error => ({
  type: SAVE_FORM_FAILURE,
  payload: error
})

export const saveSkillRequest: (skill: Skill) => SaveSkillRequest = skill => ({
  type: SAVE_SKILL_REQUEST,
  payload: skill
})
export const saveSkillSuccess: () => SaveSkillSuccess = () => ({
  type: SAVE_SKILL_SUCCESS
})
export const saveSkillFailure: (error: ServerFailure) => SaveSkillFailure = error => ({
  type: SAVE_SKILL_FAILURE,
  payload: error
})

export const downloadFormDataAsExcelRequest:
  ({ skillId, skillName }: { skillId: Id, skillName: string }) => DownloadFormDataAsExcelRequest =
  ({ skillId, skillName }) => ({
    type: DOWNLOAD_FORM_DATA_REQUEST,
    payload: { skillId, skillName }
  })

export const downloadFormDataAsExcelFailure:
  ({ skillId, error }: { skillId: Id, error: ServerFailure }) => DownloadFormDataAsExcelFailure =
  ({ skillId, error }) => ({
    type: DOWNLOAD_FORM_DATA_FAILURE,
    payload: { skillId, error }
  })

export const downloadFormDataAsExcelSuccess: ({ skillId }: { skillId: Id }) => DownloadFormDataAsExcelSuccess =
  ({ skillId }) => ({
    type: DOWNLOAD_FORM_DATA_SUCCESS,
    payload: { skillId }
  })

export const createAdvancedSkillRequest: (name: string) => CreateAdvancedSkillRequest = (name) => ({
  type: CREATE_ADVANCED_REQUEST,
  payload: name
})

export const createAdvancedSkillSuccess: (id: Id) => CreateAdvancedSkillSuccess = (id) => ({
  type: CREATE_ADVANCED_SUCCESS,
  payload: id
})

export const createAdvancedSkillFailure: (error: ServerFailure) => CreateAdvancedSkillFailure = (error) => ({
  type: CREATE_ADVANCED_FAILURE,
  payload: error
})

export const fetchAllAdvanced: () => FetchAllAdvancedRequest = () => ({
  type: FETCH_ALL_ADVANCED_REQUEST
})

export const fetchAllAdvancedSuccess: (skills: ReadonlyArray<Skill>) => FetchAllAdvancedSuccess = skills => ({
  type: FETCH_ALL_ADVANCED_SUCCESS,
  payload: skills
})

export const fetchAllAdvancedFailure: (error: ServerFailure) => FetchAllAdvancedFailure = error => ({
  type: FETCH_ALL_ADVANCED_FAILURE,
  payload: error
})

export const fetchSkillReferencesRequest = (fetchSkillReferencesRequestParam: FetchSkillReferencesRequestParam): FetchSkillReferencesRequest => {
  return {
    type: FETCH_SKILL_REFERENCES_REQUEST,
    payload: fetchSkillReferencesRequestParam
  }
}

export const fetchSkillReferencesSuccess = (fetchSkillReferencesSuccessParam: FetchSkillReferencesSuccessParam): FetchSkillReferencesSuccess => {
  return {
    type: FETCH_SKILL_REFERENCES_SUCCESS,
    payload: fetchSkillReferencesSuccessParam
  }
}

export const fetchSkillReferencesFailure = (fetchSkillReferencesFailureParam: FetchSkillReferencesFailureParam): FetchSkillReferencesFailure => {
  return {
    type: FETCH_SKILL_REFERENCES_FAILURE,
    payload: fetchSkillReferencesFailureParam
  }
}

export const deleteSkillRequest = (deleteSkillRequestParam: DeleteSkillRequestParam): DeleteSkillRequest => {
  return {
    type: DELETE_SKILL_REQUEST,
    payload: deleteSkillRequestParam
  }
}

export const deleteSkillSuccess = (deleteSkillSuccessParam: DeleteSkillSuccessParam): DeleteSkillSuccess => {
  return {
    type: DELETE_SKILL_SUCCESS,
    payload: deleteSkillSuccessParam
  }
}

export const deleteSkillFailure = (deleteSkillFailureParam: DeleteSkillFailureParam): DeleteSkillFailure => {
  return {
    type: DELETE_SKILL_FAILURE,
    payload: deleteSkillFailureParam
  }
}

export const fetchFormReferencesRequest = (fetchFormReferencesRequestParam: FetchFormReferencesRequestParam): FetchFormReferencesRequest => {
  return {
    type: FETCH_FORM_REFERENCES_REQUEST,
    payload: fetchFormReferencesRequestParam
  }
}

export const fetchFormReferencesSuccess = (fetchFormReferencesSuccessParam: FetchFormReferencesSuccessParam): FetchFormReferencesSuccess => {
  return {
    type: FETCH_FORM_REFERENCES_SUCCESS,
    payload: fetchFormReferencesSuccessParam
  }
}

export const fetchFormReferencesFailure = (fetchFormReferencesFailureParam: FetchFormReferencesFailureParam): FetchFormReferencesFailure => {
  return {
    type: FETCH_FORM_REFERENCES_FAILURE,
    payload: fetchFormReferencesFailureParam
  }
}

export const deleteFormRequest = (deleteFormRequestParam: DeleteFormRequestParam): DeleteFormRequest => {
  return {
    type: DELETE_FORM_REQUEST,
    payload: deleteFormRequestParam
  }
}

export const deleteFormSuccess = (deleteFormSuccessParam: DeleteFormSuccessParam): DeleteFormSuccess => {
  return {
    type: DELETE_FORM_SUCCESS,
    payload: deleteFormSuccessParam
  }
}

export const deleteFormFailure = (deleteFormFailureParam: DeleteFormFailureParam): DeleteFormFailure => {
  return {
    type: DELETE_FORM_FAILURE,
    payload: deleteFormFailureParam
  }
}