import * as React from 'react'
import { Link } from 'react-router-dom'

import './styles.css'

function LabeledName({ label, to }) {
  return (
    <Link className='LabeledName' to={to}>
      {label}
    </Link>
  )
}

export default LabeledName
