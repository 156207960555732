import React from 'react'
import { Helmet } from 'react-helmet'

import { Id } from 'types'

import { scriptUrlForWebChat } from '../../models'
import { Environment } from '../../types'

import './style.css'

type WebchatComponentProps = Readonly<{
  webchatId: Id
  webchatParamEnv: Environment
}>

type WebchatParam = Readonly<{
  behaviour?: 'default'
  env?: Environment
  debug?: boolean
}>

const WebchatComponent = (props: WebchatComponentProps) => (
  <Helmet>
    <script>{webchatScript(props)}</script>
  </Helmet>
)

const webchatScript = ({
  webchatId,
  webchatParamEnv
}: WebchatComponentProps) => `
  var display = 'iframe';
  var script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = '${scriptUrlForWebChat(webchatParamEnv)}';
  document.body.appendChild(script);
  var tcfbot = "${webchatId}";
  var TcfWbchtParams = ${JSON.stringify(webchatParamFromEnv(webchatParamEnv))};
`

const webchatParamFromEnv: (environment: Environment) => WebchatParam = (
  environment
) => {
  switch (environment) {
    case 'production':
      return {
        isDisplayForced: true
      }

    default:
      return {
        env: environment,
        isDisplayForced: true,
        debug: true
      }
  }
}

export default WebchatComponent
