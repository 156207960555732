import React from 'react'
import { Grid, Segment } from 'semantic-ui-react'

import text from '../../../../../../config/translations/en.json'
import MoreInfoPopup from '../../../../../components/MoreInfoPopup'
import DataGroupedByHour from '../../../components/BarCharts/DataGroupedByHour'
import NoDataSegment from '../../../components/NoDataSegment'

import '../../index.css'


const style = {
  borderRadius: '3px',
  border: 'none',
  boxShadow: '0 2px 4px rgba(50, 50, 93,.1)',
  padding: '5px'
}

const Day = ({
  messagesByHour,
  usersByHour,
}) => {
  return(
  <Grid.Row>
    <Grid.Column>
      <h1>
        Typical Day <MoreInfoPopup content={text.traffic.typicalday} />
      </h1>
      {!messagesByHour || !usersByHour ? (
        <NoDataSegment />
      ) : (
        <Segment style={style}>
          <DataGroupedByHour messages={messagesByHour} users={usersByHour} />
        </Segment>
      )}
    </Grid.Column>
  </Grid.Row>
)}

export default Day
