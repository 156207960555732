import * as R from 'ramda'

import * as answerActions from './actions'

export const initialState = {
  byIntentSlug: {},
  isFetching: false,
  error: null
}

const saveRequest = state => {
  return {
    ...state,
    isFetching: true
  }
}

const saveSuccess = (state, action) => {
  return {
    ...state,
    isFetching: false
  }
}

const saveFailure = (state, action) => {
  return {
    ...state,
    isFetching: false,
    error: action.payload
  }
}

const deleteCustomRequest = state => {
  return {
    ...state,
    isFetching: true
  }
}

const deleteCustomSuccess = (state, action) => {
  return {
    ...state,
    isFetching: false
  }
}

const deleteCustomFailure = (state, action) => {
  return {
    ...state,
    isFetching: false,
    error: action.payload
  }
}

const createAnswer = (state, action) => {
  const { answer, intentSlug } = action.payload
  const lens = R.lensPath(['byIntentSlug', intentSlug])
  return R.set(lens, R.append(answer, R.view(lens, state)), state)
}

const updateAnswer = (state, action) => {
  const { answer, intentSlug } = action.payload
  const lens = R.lensPath(['byIntentSlug', intentSlug])
  const index = R.findIndex(a => a.id === answer.id, R.view(lens, state))
  const path = R.compose(lens, R.lensPath([index]))
  return R.set(path, action.payload.answer, state)
}

const removeAnswer = (state, action) => {
  const { intentSlug, id } = action.payload
  const lens = R.lensPath(['byIntentSlug', intentSlug])
  return R.set(
    lens,
    R.filter(a => a.id !== id, R.view(lens, state)),
    state
  )
}

const setCustom = (state, action) => {
  // return {
  //   ...state,
  //   byIntentSlug: R.pipe(R.map(object.insertId), array.toMapById)(action.payload)
  // }
  const intentSlug = action.payload.intentSlug
  return R.set(R.lensPath(['byIntentSlug', intentSlug]), action.payload.answers, state)
}

export default function(state = initialState, action) {
  switch (action.type) {
  case answerActions.SAVE_ANSWERS_REQUEST:
    return saveRequest(state)
  case answerActions.SAVE_ANSWERS_SUCCESS:
    return saveSuccess(state, action)
  case answerActions.SAVE_ANSWERS_FAILURE:
    return saveFailure(state, action)
  case answerActions.DELETE_ANSWERS_REQUEST:
    return deleteCustomRequest(state)
  case answerActions.DELETE_ANSWERS_SUCCESS:
    return deleteCustomSuccess(state, action)
  case answerActions.DELETE_ANSWERS_FAILURE:
    return deleteCustomFailure(state, action)
  case answerActions.CREATE_BUTTON:
  case answerActions.CREATE_QUICK_REPLY:
  case answerActions.CREATE_SIMPLE_TEXT:
    return createAnswer(state, action)
  case answerActions.UPDATE_ANSWER:
    return updateAnswer(state, action)
  case answerActions.REMOVE_ANSWER:
    return removeAnswer(state, action)
  case answerActions.SET_CUSTOM:
    return setCustom(state, action)
  case answerActions.RESET_STATE:
    return initialState
  default:
    return state
  }
}
