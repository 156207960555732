import { remoteData } from '@sbizeul/fp-flow'
import * as R from 'ramda'

import * as userActions from './actions'
import * as array from '../../helpers/array'

export const initialState = {
  me: remoteData.notAsked(),
  all: remoteData.notAsked(),
  byId: {}
}

const me = R.lensProp('me')
const all = R.lensProp('all')
const byId = R.lensPath(['byId'])

const login = (state, action) => {
  return R.over(me, remoteData.loading, state)
}

const loginSuccess = (state, action) => {
  return R.set(me, remoteData.success(action.payload), state)
}

const loginFailure = (state, action) => {
  return R.set(me, remoteData.failure(action.payload), state)
}

const logout = (state, action) => {
  return R.over(me, remoteData.notAsked, state)
}

const fetchOne = (state, action) => {
  const lensPath = R.compose(byId, R.lensPath([action.payload]))
  return R.over(lensPath, remoteData.loading, state)
}

const fetchOneSuccess = (state, action) => {
  const lensPath = R.compose(byId, R.lensPath([action.payload._id.$oid]))
  return R.set(lensPath, remoteData.success(action.payload), state)
}

const fetchOneFailure = (state, action) => {
  const lensPath = R.compose(byId, R.lensPath([action.payload.userId]))
  return R.set(lensPath, remoteData.failure(action.payload.error), state)
}

const fetchAll = (state, action) => {
  return R.over(all, remoteData.loading, state)
}

const fetchAllSuccess = (state, action) => {
  return R.set(all, R.pipe(array.toMapByUnderscoreId, remoteData.success)(action.payload), state)
}

const fetchAllFailure = (state, action) => {
  return R.set(all, remoteData.failure(action.payload), state)
}

export default function user(state = initialState, action) {
  switch (action.type) {
  case userActions.LOGIN_REQUEST:
    return login(state, action)
  case userActions.LOGIN_SUCCESS:
    return loginSuccess(state, action)
  case userActions.LOGIN_FAILURE:
    return loginFailure(state, action)
  case userActions.LOGOUT:
    return logout(state, action)
  case userActions.FETCH_ONE_REQUEST:
    return fetchOne(state, action)
  case userActions.FETCH_ONE_SUCCESS:
    return fetchOneSuccess(state, action)
  case userActions.FETCH_ONE_FAILURE:
    return fetchOneFailure(state, action)
  case userActions.FETCH_ALL_REQUEST:
    return fetchAll(state, action)
  case userActions.FETCH_ALL_SUCCESS:
    return fetchAllSuccess(state, action)
  case userActions.FETCH_ALL_FAILURE:
    return fetchAllFailure(state, action)
  default:
    return state
  }
}
