import React, { useState } from 'react'
import * as R from 'ramda'
import { either } from '@sbizeul/fp-flow'

import { TopBarProps } from '../../types'
import AddSectionButton from '../AddSectionButton'
import CreateSection from '../CreateSection'
import ImportButton from '../ImportButton'
import InputQuestion from '../InputQuestion'

import './styles.css'

const TopBar = ({
  isPostingQuestion,
  isImporting,
  isCreatingSection,
  onInputKeyEnter,
  importFile,
  createSection
}: TopBarProps) => {
  const [addSectionVisible, setAddSectionVisible] = useState(false)

  const showAddSection = () => setAddSectionVisible(true)

  const hideAddSection = () => setAddSectionVisible(false)

  const isAddSectionVisible = () => addSectionVisible || either.fold(R.T, R.identity, isCreatingSection)

  const onSectionCreated = (label: string) => {
    createSection({ label })
    hideAddSection()
  }

  return (
    <>
      <div className='QaTopBar-container'>
        <h1 className='QaTopBar-title'>Create a new Q&A</h1>
        <div className='QaTopBar-top'>
          <div className='QaTopBar-top-input'>
            <InputQuestion onKeyEnter={onInputKeyEnter} isPostingQuestion={isPostingQuestion}/>
          </div>
          <ImportButton importFile={importFile} isImporting={isImporting}/>
          <AddSectionButton onClick={showAddSection} />
        </div>
      </div>
      {isAddSectionVisible() && <CreateSection
        isCreatingSection={isCreatingSection}
        onSectionCreated={onSectionCreated}
        onEscapeKeyPressed={hideAddSection}
      />}
    </>
  )
}

export {
  TopBar
}
