import { Id, ServerFailure } from 'types'
import { Either } from 'fp-ts/lib/Either'
import * as O from 'fp-ts/lib/Option'
import { RemoteData } from '@sbizeul/fp-flow'
import { appsConfig } from './config'

export const FETCH_APP_INFORMATION_REQUEST = 'apps/FETCH_APP_INFORMATION_REQUEST'
export const FETCH_APP_INFORMATION_SUCCESS = 'apps/FETCH_APP_INFORMATION_SUCCESS'
export const FETCH_APP_INFORMATION_FAILURE = 'apps/FETCH_APP_INFORMATION_FAILURE'

export const SAVE_APP_INFORMATION_REQUEST = 'apps/SAVE_APP_INFORMATION_REQUEST'
export const SAVE_APP_INFORMATION_SUCCESS = 'apps/SAVE_APP_INFORMATION_SUCCESS'
export const SAVE_APP_INFORMATION_FAILURE = 'apps/SAVE_APP_INFORMATION_FAILURE'

export type FetchAppInformationRequestParam = string
export type FetchAppInformationSuccessParam = O.Option<AppInformation>
export type FetchAppInformationFailureParam = ServerFailure
export type SaveAppInformationFailureParam = ServerFailure
export type SaveAppInformationSuccessParam = string
export type SaveAppInformationRequestParam = UpdatedAppInformation

export type UpdatedAppInformation = Readonly<{
  actionId: string
  config: readonly {
    key: string,
    value: string
  }[]
}>

export type AppInformation = UpdatedAppInformation & Readonly<{
  bot_id: Id
}>

export type FetchAppInformationRequest = Readonly<{
  type: typeof FETCH_APP_INFORMATION_REQUEST,
  payload: FetchAppInformationRequestParam
}>

export type FetchAppInformationSuccess = Readonly<{
  type: typeof FETCH_APP_INFORMATION_SUCCESS,
  payload: FetchAppInformationSuccessParam
}>

export type FetchAppInformationFailure = Readonly<{
  type: typeof FETCH_APP_INFORMATION_FAILURE,
  payload: FetchAppInformationFailureParam
}>

export type SaveAppInformationRequest = Readonly<{
  type: typeof SAVE_APP_INFORMATION_REQUEST,
  payload: SaveAppInformationRequestParam
}>

export type SaveAppInformationSuccess = Readonly<{
  type: typeof SAVE_APP_INFORMATION_SUCCESS,
  payload: SaveAppInformationSuccessParam
}>

export type SaveAppInformationFailure = Readonly<{
  type: typeof SAVE_APP_INFORMATION_FAILURE,
  payload: SaveAppInformationFailureParam
}>

export type AppsEntityAction =
  | FetchAppInformationRequest
  | FetchAppInformationSuccess
  | FetchAppInformationFailure

export type AppsUIAction =
  | SaveAppInformationRequest
  | SaveAppInformationSuccess
  | SaveAppInformationFailure

export type AppSettingsEntitiesState = Readonly<{
  appInfo: RemoteData<FetchAppInformationFailureParam, FetchAppInformationSuccessParam>
}>

export type AppSettingsUIState = Readonly<{
  saving: Either<ServerFailure, boolean>
}>

export type AppSettingsState = Readonly<{
  entities: {
    apps: AppSettingsEntitiesState
  },
  ui: {
    apps: AppSettingsUIState
  }
}>

export type AppField = Readonly<{
  datatype?: string
  title: string
  name: string
  placeholder: string
}>

export type AppSlugs = keyof typeof appsConfig

export type AppSettingsProps = Readonly<{
  appSlug: AppSlugs
  appName: string
  appId: string
  appFields: ReadonlyArray<AppField>
  enabled: boolean
  saving: Either<ServerFailure, boolean>
  appInfo: RemoteData<ServerFailure, O.Option<AppInformation>>

  fetchAppInfo: (appName: string, appId: string, enabled: boolean) => unknown
  saveAppInfo: (appInfo: UpdatedAppInformation) => unknown
}>
