
import React from 'react'
import { remoteData } from '@sbizeul/fp-flow'

import Api from 'services/tolkApiLegacy'

import TestPanel from './TestPanel'

//// Note: We will do all the effects here, but it should be in
//// a saga, with connection to redux.

export default class TestPanelWrapper extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      input: '',
      userText: '',
      nluAnswer: remoteData.notAsked(),
      confidence: ''
    }
  }

  handleEnterKeyPressed = () => {
    const user = {
      text: this.state.input,
      language: 'fr'
    }
    if (user.text) {
      this.setState({ nluAnswer: remoteData.loading() })
      Api.post(`/bot/${this.props.botId}/nlu/check`, user)
        .then(res => {
          const intents = res.data.intents
          if (intents && intents.length > 0) {
            this.setState({ userText: this.state.input })
            this.setState({ nluAnswer: remoteData.of(intents[0].name) })
            this.setState({ confidence: intents[0].confidence })
          } else {
            this.setState({ nluAnswer: remoteData.failure('Unknown question') })
          }
        })
        .catch(e => this.setState({ nluAnswer: remoteData.failure(e) }))
    }
  }

  render() {
    return (
      <TestPanel
        onTextChange={e => this.setState({ input: e.target.value })}
        onEnterKeyPressed={this.handleEnterKeyPressed}
        recastAnswer={this.state.nluAnswer}
        userInput={this.state.userText}
        recastAnswerConfidence={this.state.confidence}
      />
    )
  }
}
