import { maybe } from '@sbizeul/fp-flow'

import actions from '../actions'
import isResValid from '../../../_shared/isResValid'
import formatFilters from '../../../_shared/formatFilters'
import { groupUsersByHour, groupMessagesByDay } from '../../../../views/pages/Analytics/utils'
import bot from '../../../../modules/bot'
import api from 'services/tolkApiLegacy'

const fetchUsersByHour = rawFilters => (dispatch, getState) => {
  const { channelId, start, end } = formatFilters(rawFilters)
  const botId = bot.selectors.getSelectedId(getState())

  dispatch(actions.fetchUsersByHourRequest())
  return api.get(
    `/analytics/${maybe.get(botId)}/${channelId}/users`,
    {
      params: {
        start,
        end,
        period: 'hours'
      }
    }
  ).then(response => {
      if (isResValid(response)) {
        const usersByHour = groupUsersByHour(response.data.result.data)
        const usersCountByDay = groupMessagesByDay(response.data.result.data)
        dispatch(
          actions.fetchUsersByHourSuccess({
            usersByHour,
            usersCountByDay
          })
        )
      } else {
        dispatch(actions.fetchUsersByHourFailure())
      }
    })
    .catch(err => {
      dispatch(actions.fetchUsersByHourFailure(err))
    })
}

export default fetchUsersByHour
