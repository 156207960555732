import types from './types'

const fetchReferralRequest = () => ({ type: types.FETCH_REFERRAL_REQUEST })
const fetchReferralSuccess = payload => ({ type: types.FETCH_REFERRAL_SUCCESS, payload })
const fetchReferralFailure = errors => ({ type: types.FETCH_REFERRAL_NO_DATA, errors })

const fetchReferralUtilRequest = () => ({ type: types.FETCH_REFERRALUTIL_REQUEST })
const fetchReferralUtilSuccess = payload => ({ type: types.FETCH_REFERRALUTIL_SUCCESS, payload })
const fetchReferralUtilFailure = errors => ({ type: types.FETCH_REFERRALUTIL_NO_DATA, errors })

export default {
  fetchReferralRequest,
  fetchReferralSuccess,
  fetchReferralFailure,
  fetchReferralUtilRequest,
  fetchReferralUtilSuccess,
  fetchReferralUtilFailure
}
