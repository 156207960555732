import moment from 'moment-timezone'
import stripDateDetails from '../../_shared/stripDateDetails'

const getComparisonDateRange = dateRange => {
  const today = stripDateDetails(moment.tz('Europe/Paris'))
  if (dateRange.start && dateRange.end) {
    const numberOfDays = dateRange.end.diff(dateRange.start, 'days')
    return {
      start: dateRange.start
        .clone()
        .subtract(numberOfDays + 1, 'days')
        .format('YYYY-MM-DD'),
      end: dateRange.end
        .clone()
        .subtract(numberOfDays + 1, 'days')
        .format('YYYY-MM-DD')
    }
  } else {
    return {
      start: moment(today)
        .subtract(1, 'days')
        .format('YYYY-MM-DD'),
      end: moment(today)
        .subtract(1, 'days')
        .format('YYYY-MM-DD')
    }
  }
}

export default getComparisonDateRange
