export const FETCH_ALL_REQUEST = 'channel/FETCH_ALL_REQUEST'
export const FETCH_ALL_SUCCESS = 'channel/FETCH_ALL_SUCCESS'
export const FETCH_ALL_FAILURE = 'channel/FETCH_ALL_FAILURE'
export const FETCH_BY_BOT_REQUEST = 'channel/FETCH_BY_BOT_REQUEST'
export const FETCH_BY_BOT_SUCCESS = 'channel/FETCH_BY_BOT_SUCCESS'
export const FETCH_BY_BOT_FAILURE = 'channel/FETCH_BY_BOT_FAILURE'

export const fetchAll = userId => {
  return {
    type: FETCH_ALL_REQUEST,
    payload: userId
  }
}

export const fetchAllSuccess = channels => {
  return {
    type: FETCH_ALL_SUCCESS,
    payload: channels
  }
}

export const fetchAllFailure = error => {
  return {
    type: FETCH_ALL_FAILURE,
    payload: error
  }
}

export const fetchByBot = botId => {
  return {
    type: FETCH_BY_BOT_REQUEST,
    payload: botId
  }
}

export const fetchByBotSuccess = (botId, channels) => {
  return {
    type: FETCH_BY_BOT_SUCCESS,
    payload: {
      channels,
      botId
    }
  }
}

export const fetchByBotFailure = (botId, error) => {
  return {
    type: FETCH_BY_BOT_FAILURE,
    payload: {
      error,
      botId
    }
  }
}
