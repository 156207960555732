export type IsoLanguage = 'fr_FR' | 'en_GB' | 'nl_NL' | 'pt_PT'

export const isoToCountry = (iso: IsoLanguage) => {
  switch (iso) {
    case 'fr_FR':
      return 'French'
    case 'en_GB':
      return 'English'
    case 'nl_NL':
      return 'Dutch'
    case 'pt_PT':
      return 'Portuguese'
    default:
      return 'N/A'
  }
}
