import React from 'react'
import classNames from 'classnames'
import { Dimmer, Loader as SemanticLoader } from 'semantic-ui-react'

import './styles.css'

function Loader({ description, className = undefined }) {
  return (
    <Dimmer className={classNames('Loading-Loader', className)} active inverted>
      <SemanticLoader size='big'>{description}</SemanticLoader>
    </Dimmer>
  )
}

export default Loader
