const INTENTS_FETCH_REQUEST = 'tcfdash/intents/INTENTS_FETCH_REQUEST'
const INTENTS_FETCH_SUCCESS = 'tcfdash/intents/INTENTS_FETCH_SUCCESS'
const INTENTS_FETCH_FAILURE = 'tcfdash/intents/INTENTS_FETCH_FAILURE'

const INTENT_DATA_FETCH_REQUEST = 'tcfdash/intents/INTENT_DATA_FETCH_REQUEST'
const INTENT_DATA_FETCH_SUCCESS = 'tcfdash/intents/INTENT_DATA_FETCH_SUCCESS'
const INTENT_DATA_FETCH_FAILURE = 'tcfdash/intents/INTENT_DATA_FETCH_FAILURE'

const GET_DIVERSITY_REQUEST = 'tcfdash/intents/GET_DIVERSITY_REQUEST'
const GET_DIVERSITY_SUCCESS = 'tcfdash/intents/GET_DIVERSITY_SUCCESS'
const GET_DIVERSITY_FAILURE = 'tcfdash/intents/GET_DIVERSITY_FAILURE'

const POST_INTENT_REQUEST = 'tcfdash/analytics/POST_INTENT_REQUEST'
const POST_INTENT_SUCCESS = 'tcfdash/analytics/POST_INTENT_SUCCESS'
const POST_INTENT_FAILURE = 'tcfdash/analytics/POST_INTENT_FAILURE'

const PUT_INTENT_REQUEST = 'tcfdash/analytics/PUT_INTENT_REQUEST'
const PUT_INTENT_SUCCESS = 'tcfdash/analytics/PUT_INTENT_SUCCESS'
const PUT_INTENT_FAILURE = 'tcfdash/analytics/PUT_INTENT_FAILURE'

const PUT_INTENT_INFOS_REQUEST = 'tcfdash/analytics/PUT_INTENT_INFOS_REQUEST'
const PUT_INTENT_INFOS_SUCCESS = 'tcfdash/analytics/PUT_INTENT_INFOS_SUCCESS'
const PUT_INTENT_INFOS_FAILURE = 'tcfdash/analytics/PUT_INTENT_INFOS_FAILURE'

const POST_INTENT_EXAMPLE_REQUEST = 'tcfdash/intents/POST_INTENT_EXAMPLE_REQUEST'
const POST_INTENT_EXAMPLE_SUCCESS = 'tcfdash/intents/POST_INTENT_EXAMPLE_SUCCESS'
const POST_INTENT_EXAMPLE_FAILURE = 'tcfdash/intents/POST_INTENT_EXAMPLE_FAILURE'

const PUT_INTENT_EXAMPLE_REQUEST = 'tcfdash/intents/PUT_INTENT_EXAMPLE_REQUEST'
const PUT_INTENT_EXAMPLE_SUCCESS = 'tcfdash/intents/PUT_INTENT_EXAMPLE_SUCCESS'
const PUT_INTENT_EXAMPLE_FAILURE = 'tcfdash/intents/PUT_INTENT_EXAMPLE_FAILURE'

const DELETE_INTENT_EXAMPLE_REQUEST = 'tcfdash/intents/DELETE_INTENT_EXAMPLE_REQUEST'
const DELETE_INTENT_EXAMPLE_SUCCESS = 'tcfdash/intents/DELETE_INTENT_EXAMPLE_SUCCESS'
const DELETE_INTENT_EXAMPLE_FAILURE = 'tcfdash/intents/DELETE_INTENT_EXAMPLE_FAILURE'

const DELETE_INTENT_ANSWER_REQUEST = 'tcfdash/intents/DELETE_INTENT_ANSWER_REQUEST'
const DELETE_INTENT_ANSWER_SUCCESS = 'tcfdash/intents/DELETE_INTENT_ANSWER_SUCCESS'
const DELETE_INTENT_ANSWER_FAILURE = 'tcfdash/intents/DELETE_INTENT_ANSWER_FAILURE'

const FETCH_USER_REQUEST = 'tcfdash/intents/FETCH_USER_REQUEST'
const FETCH_USER_SUCCESS = 'tcfdash/intents/FETCH_USER_SUCCESS'
const FETCH_USER_FAILURE = 'tcfdash/intents/FETCH_USER_FAILURE'

const SELECT_INTENT_LANGUAGE = 'tcfdash/intents/SELECT_INTENT_LANGUAGE'

const POST_USER_REQUEST = 'tcfdash/intents/POST_USER_REQUEST'
const POST_USER_SUCCESS = 'tcfdash/intents/POST_USER_SUCCESS'
const POST_USER_FAILURE = 'tcfdash/intents/POST_USER_FAILURE'

const EDIT_USER_REQUEST = 'tcfdash/intents/EDIT_USER_REQUEST'
const EDIT_USER_SUCCESS = 'tcfdash/intents/EDIT_USER_SUCCESS'
const EDIT_USER_FAILURE = 'tcfdash/intents/EDIT_USER_FAILURE'

const POST_PASSWORD_REQUEST = 'tcfdash/intents/POST_PASSWORD_REQUEST'
const POST_PASSWORD_SUCCESS = 'tcfdash/intents/POST_PASSWORD_SUCCESS'
const POST_PASSWORD_FAILURE = 'tcfdash/intents/POST_PASSWORD_FAILURE'

const CREATE_PASSWORD_REQUEST = 'tcfdash/intents/CREATE_PASSWORD_REQUEST'
const CREATE_PASSWORD_SUCCESS = 'tcfdash/intents/CREATE_PASSWORD_SUCCESS'
const CREATE_PASSWORD_FAILURE = 'tcfdash/intents/CREATE_PASSWORD_FAILURE'

const INTENT_TOGGLE_REQUEST = 'tcfdash/intents/INTENT_TOGGLE_REQUEST'
const INTENT_TOGGLE_SUCCESS = 'tcfdash/intents/INTENT_TOGGLE_SUCCESS'
const INTENT_TOGGLE_FAILURE = 'tcfdash/intents/INTENT_TOGGLE_FAILURE'

const INTENT_DELETE_REQUEST = 'tcfdash/intents/INTENT_DELETE_REQUEST'
const INTENT_DELETE_SUCCESS = 'tcfdash/intents/INTENT_DELETE_SUCCESS'
const INTENT_DELETE_FAILURE = 'tcfdash/intents/INTENT_DELETE_FAILURE'

export default {
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,

  INTENTS_FETCH_REQUEST,
  INTENTS_FETCH_SUCCESS,
  INTENTS_FETCH_FAILURE,

  INTENT_DATA_FETCH_REQUEST,
  INTENT_DATA_FETCH_SUCCESS,
  INTENT_DATA_FETCH_FAILURE,

  GET_DIVERSITY_REQUEST,
  GET_DIVERSITY_SUCCESS,
  GET_DIVERSITY_FAILURE,

  POST_INTENT_EXAMPLE_REQUEST,
  POST_INTENT_EXAMPLE_SUCCESS,
  POST_INTENT_EXAMPLE_FAILURE,

  PUT_INTENT_EXAMPLE_REQUEST,
  PUT_INTENT_EXAMPLE_SUCCESS,
  PUT_INTENT_EXAMPLE_FAILURE,

  DELETE_INTENT_EXAMPLE_REQUEST,
  DELETE_INTENT_EXAMPLE_SUCCESS,
  DELETE_INTENT_EXAMPLE_FAILURE,

  DELETE_INTENT_ANSWER_REQUEST,
  DELETE_INTENT_ANSWER_SUCCESS,
  DELETE_INTENT_ANSWER_FAILURE,

  POST_INTENT_REQUEST,
  POST_INTENT_SUCCESS,
  POST_INTENT_FAILURE,

  PUT_INTENT_REQUEST,
  PUT_INTENT_SUCCESS,
  PUT_INTENT_FAILURE,

  PUT_INTENT_INFOS_REQUEST,
  PUT_INTENT_INFOS_SUCCESS,
  PUT_INTENT_INFOS_FAILURE,

  SELECT_INTENT_LANGUAGE,

  POST_USER_REQUEST,
  POST_USER_SUCCESS,
  POST_USER_FAILURE,

  CREATE_PASSWORD_REQUEST,
  CREATE_PASSWORD_SUCCESS,
  CREATE_PASSWORD_FAILURE,

  EDIT_USER_REQUEST,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAILURE,

  POST_PASSWORD_REQUEST,
  POST_PASSWORD_SUCCESS,
  POST_PASSWORD_FAILURE,

  INTENT_TOGGLE_REQUEST,
  INTENT_TOGGLE_SUCCESS,
  INTENT_TOGGLE_FAILURE,

  INTENT_DELETE_REQUEST,
  INTENT_DELETE_SUCCESS,
  INTENT_DELETE_FAILURE
}
