import React from 'react'
import { Link } from 'react-router-dom'

import { BASE_SKILL_LABELS, HUMANHANDOVER } from '../../types'
import * as routes from '../../routes'

const HumanHandoverButton = () => (
  <Link className='skill-default--button skill-human-handover--button' to={routes.skillRoute(HUMANHANDOVER)}>
    <p className='skill-default--label'>{BASE_SKILL_LABELS[HUMANHANDOVER]}</p>
  </Link>
)

export default HumanHandoverButton
