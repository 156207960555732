import * as actions from './actions'
import * as saga from './saga'
import * as entityReducer from './reducer.entity'
import * as uiReducer from './reducer.ui'
import SimpleAnswerEditor from './components/SimpleAnswerEditor'
import AnswerEditor from './components/AnswerEditor'
import KeywordDropdown from './components/QRAnswerEditor/KeywordDropdown'
import { AnswerEditorContainer, AnswerEditorSimpleContainer } from './containers'
import * as models from './models'
import * as modelsTyped from './models.typed'
import * as selectors from './selectors'
import * as utils from './utils'

export default {
  actions,
  models: {
    ...models,
    ...modelsTyped,
  },
  reducers: {
    entity: entityReducer,
    ui: uiReducer,
  },
  saga,
  selectors,
  components: {
    AnswerEditor,
    KeywordDropdown,
    SimpleAnswerEditor,
    AnswerEditorContainer,
    AnswerEditorSimpleContainer
  },
  utils
}
