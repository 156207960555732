import * as R from 'ramda'

import { FormSkillContent, Skill } from './types'
import { Predicate } from 'fp-ts/lib/function'
import { v4 as uuid } from 'uuid'
import { Id } from 'types'
import { IsoLanguage } from 'helpers/language'

const isFormContentEmpty: Predicate<FormSkillContent> = R.compose(
  R.isEmpty, R.prop('content')
)

const isInputEmpty: Predicate<string> = R.compose(R.isEmpty, R.trim)

const areInputsEmpty: Predicate<ReadonlyArray<string>> = R.either(
  R.isEmpty,
  R.any(isInputEmpty)
)

const areSkillContentInputsEmpty: Predicate<FormSkillContent> = skillForm => R.any(
  R.pipe(
    R.prop('text'),
    areInputsEmpty
  ), skillForm.content)

const isFormSkillValid: Predicate<FormSkillContent> = R.compose(
  R.not, R.anyPass([isFormContentEmpty, areSkillContentInputsEmpty])
)

const emptyAdvancedSkill: (botId: Id, language: IsoLanguage, name: string) => Skill = (botId, language, name) => ({
  answers: [],
  botId,
  id: uuid(),
  language,
  name,
  questions: []
})

export {
  emptyAdvancedSkill,
  isFormSkillValid
}
