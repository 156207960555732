import { maybe } from '@sbizeul/fp-flow'

import api from 'services/tolkApiLegacy'
import formatFilters from '../../_shared/formatFilters'
import actions from './actions'
import bot from '../../../modules/bot'

const fetchEscaladesHeat = rawFilters => {
  const { channelId, start, end } = formatFilters(rawFilters)

  return async (dispatch, getState) => {
    const botId = bot.selectors.getSelectedId(getState())
    dispatch(actions.fetchEscaladesHeatRequest())
    try {
      const response = await api.get(`analytics/${maybe.get(botId)}/${channelId}/escaladesheat`, { start, end })
      const { data, count } = response
      dispatch(actions.fetchEscaladesHeatSuccess({ data, count }))
    } catch (e) {
      dispatch(actions.fetchEscaladesHeatFailure(e))
    }
  }
}

export default { fetchEscaladesHeat }
