import React, { forwardRef } from 'react'
import classNames from 'classnames'
import * as R from 'ramda'

import { call } from 'helpers/function'

import './TextInput.css'

export type KeyEvent = React.KeyboardEvent<HTMLInputElement>

type OnKeyPressed = (keyEvent: KeyEvent) => unknown

export type TextInputProps = Readonly<{
  value: string
  className?: string
  required?: boolean
  placeholder?: string
  name?: string
  type?: 'default' | 'rounded'
  autoFocus?: boolean
  decoratorChild?: React.ReactNode
  emptyDecoratorChild?: React.ReactNode
  withBorder?: boolean
  disabled?: boolean
  autocomplete?: 'off' | 'on'
  pattern?: string

  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => unknown
  onChangeText?: (value: string) => unknown
  onEnterKeyPressed?: () => unknown
  onEscapeKeyPressed?: () => unknown
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  onDecoratorClick?: (value: string) => unknown
}>

const isKeyPressed: (expectedKey: string) => (onKeyPressed?: OnKeyPressed) => (keyEvent: KeyEvent) => boolean =
  expectedKey => onKeyPressed => keyEvent => R.not(R.isNil(onKeyPressed)) && R.equals(keyEvent.key, expectedKey)

const TextInput = forwardRef<HTMLInputElement, TextInputProps>((props, ref) => {
  const renderDecorator: (decorator?: React.ReactNode) => React.ReactNode =
    decorator => (decorator ? <div className='TextInput-decorator' onClick={handleDecoratorClick}>{decorator}</div> : null)

  const handleChange: (event: React.ChangeEvent<HTMLInputElement>) => unknown = event => {
    call(props.onChange, event)
    call(props.onChangeText, event.target.value)
  }

  const handleDecoratorClick: () => unknown = () => call(props.onDecoratorClick, props.value)

  const isEnterKeyPressed = isKeyPressed('Enter')(props.onEnterKeyPressed)

  const isEscapePressed = isKeyPressed('Escape')(props.onEscapeKeyPressed)

  const handleKeyPressed: (keyboardEvent: KeyEvent) => unknown =
    keyboardEvent => {
      keyboardEvent.persist()
      R.cond<KeyEvent, unknown>([
        [isEnterKeyPressed, () => call(props.onEnterKeyPressed)],
        [isEscapePressed, () => call(props.onEscapeKeyPressed)]
      ])(keyboardEvent)
    }

  return (
    <div
      className={classNames(
        'TextInput-container',
        { 'TextInput-rounded': props.type === 'rounded' },
        { 'TextInput-border': props.withBorder },
        { 'TextInput-disabled': props.disabled },
        props.className
      )}
    >
      <input
        data-testid='text-input'
        ref={ref}
        className='TextInput'
        type='input'
        name={props.name}
        value={props.value}
        onChange={handleChange}
        onKeyDown={handleKeyPressed}
        onBlur={props.onBlur}
        required={props.required}
        pattern={props.pattern}
        placeholder={props.placeholder}
        autoFocus={props.autoFocus}
        disabled={props.disabled}
        autoComplete={props.autocomplete}
      />
      {renderDecorator(R.isEmpty(props.value) && props.emptyDecoratorChild ? props.emptyDecoratorChild : props.decoratorChild)}
    </div>
  )
}
)

export default TextInput
