import React from 'react'
import { useHistory } from 'react-router-dom'
import { remoteData } from '@sbizeul/fp-flow'
import * as uiLibrary from '@tolk-ai/ui-library-react'
import '@tolk-ai/ui-library-react/dist/ui-library-react.esm.css'

import qa from 'modules/qa'
import * as menu from './components/menu'
import * as botList from './components/bot-list'
import ComponentTolkMenu from './components/tolk-menu/index'

import './style.css'

const Topbar = ({
  isLoggedIn,
  bots,
  selectBot,
  logout,
  isAdmin,
  botId,
  botName,
  PublishButton,
  webchatUrl,
  userName
}) => {
  const [state, setState] =  React.useState({
    botId,
    botName
  })

  const handleOnBotNameClick = (id) => (event) => {
    const selectedBot = bots.find((bot) => bot.id === id)
    if(selectedBot === undefined) {
      return
    }

    setState({botId: selectedBot.id, botName: selectedBot.name})
    selectBot(id)
  }
  
  const history = useHistory()

  const historyPush = (path) => () => {
    history.push(path)
  }

  return (
    <uiLibrary.Topbar
      className="ui-library-topbar"
      leftContent={
        <>
          <botList.Component
          state={state}
            isLoggedIn={isLoggedIn}
            botName={botName}
            webchatUrl={webchatUrl}
            bots={bots}
            handleOnBotNameClick={handleOnBotNameClick}
          />
        </>
      }
      logo={
        <button
          className={'ui-library-topbar__home-button'}
          onClick={historyPush(qa.routes.QA_QUESTIONS)}
        >
          <uiLibrary.TopbarBot />
        </button>
      }
      menu={
        <menu.Component
          historyPush={historyPush}
          isLoggedIn={isLoggedIn}
          userName={userName}
          logout={logout}
          isAdmin={isAdmin}
        />
      }
      rightContent={PublishButton}
      tolkMenu={<ComponentTolkMenu isLoggedIn={isLoggedIn} />}
    />
  )
}

Topbar.defaultProps = {
  bots: remoteData.of([])
}

export default Topbar
