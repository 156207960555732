import React from 'react'
import classNames from 'classnames'
import * as option from 'fp-ts/lib/Option'
import * as channels from '@tolk-ai/bot-management-react-channels'
import '@tolk-ai/bot-management-react-channels/dist/bot-management-react-channels.esm.css'
import * as connectorsConfigAgent from '@tolk-ai/connectors-config-agent'
import * as authenticatedRequest from 'services/authenticated-request'

import ChannelCard from './../ChannelCard/ChannelCard'
import './ChannelsList.css'

const ChannelsList: React.FunctionComponent<{}> = () => {
  const botId = localStorage.getItem('botId') || ''
  const AgentStatus = channels.agentStatus.container(
    connectorsConfigAgent.getOneAgentConfig(
      connectorsConfigAgent.axiosBotsRepository.axiosGetOneAgentConfig(
        authenticatedRequest.botManagement
      )
    )
  )

  return (
    <div className="pageContainer">
      <div className="channelslist">
        <h1 className="channelslist__title">Apps & Channels</h1>
        <h2 className="channelslist__subtitle">
          Configure your apps and channels integrations
        </h2>
        <h3 className="channelslist__listtitle">Tolk.ai Apps & Channels</h3>
        <ul className="channelslist__list">
          <li className="channelslist__listitem">
            <ChannelCard
              icon={'/img/channels/tolk.png'}
              title={'Webchat'}
              route={option.some('/webchat')}
            />
          </li>
          <li className="channelslist__listitem">
            <ChannelCard
              icon={'/img/channels/genii.png'}
              title={'genii'}
              route={option.some('/apps/genii_fallback')}
            />
          </li>
          <li className="channelslist__listitem channelslist__listitem--agentstatus">
            <AgentStatus
              botId={botId}
              onButtonClick={() => {
                window.open(
                  'https://www.tolk.ai/agent-plateforme-livechat',
                  '_blank'
                )
              }}
            />
          </li>
        </ul>
        <h3 className="channelslist__listtitle">Channels</h3>
        <ul className="channelslist__list">
          <li className="channelslist__listitem">
            <ChannelCard
              icon={'/img/channels/instagram.png'}
              title={'Instagram (beta)'}
              route={option.some('/channels/instagram')}
            />
          </li>
          <li className="channelslist__listitem">
            <ChannelCard
              icon={'/img/channels/messenger.png'}
              title={'Messenger'}
              route={option.some('/channels/messenger')}
            />
          </li>
          <li className="channelslist__listitem">
            <ChannelCard
              icon={'/img/channels/twitter.png'}
              title={'Twitter'}
              route={option.some('/channels/twitter')}
            />
          </li>
        </ul>
        <h3 className="channelslist__listtitle">Partners Apps</h3>
        <ul className="channelslist__list">
          <li className="channelslist__listitem">
            <ChannelCard
              icon={'/img/channels/shopify.png'}
              title={'Shopify'}
              route={option.some('/channels/shopify')}
            />
          </li>
          <li className="channelslist__listitem">
            <ChannelCard
              icon={'/img/channels/iadvize.png'}
              title={'iAdvize'}
              route={option.some('/channels/iadvize')}
            />
          </li>
          <li className="channelslist__listitem">
            <ChannelCard
              icon={'/img/channels/dimelo.png'}
              title={'Dimelo'}
              route={option.some('/channels/dimelo')}
            />
          </li>
          <li className="channelslist__listitem">
            <ChannelCard
              icon={'/img/channels/zendesk.png'}
              title={'Zendesk'}
              route={option.some('/channels/zendesk')}
            />
          </li>
          <li className="channelslist__listitem">
            <ChannelCard
              icon={'/img/channels/shipup.png'}
              title={'Shipup'}
              route={option.some('/apps/shipup')}
            />
          </li>
          <li className="channelslist__listitem">
            <ChannelCard
              icon={'/img/channels/magento.png'}
              title={'Magento 1 (REST)'}
              route={option.some('/apps/magento_1_rest')}
            />
          </li>
          <li className="channelslist__listitem">
            <ChannelCard
              icon={'/img/channels/prestashop.png'}
              title={'Prestashop 1.7'}
              route={option.some('/apps/prestashop_1_7')}
            />
          </li>
          <li className="channelslist__listitem">
            <ChannelCard
              icon={'/img/channels/openweather.png'}
              title={'Open Weather'}
              route={option.some('/apps/openweather')}
            />
          </li>
          {/* <li className="channelslist__listitem">
            <ChannelCard
              icon={'/img/channels/salesforce.png'}
              title={'Salesforce'}
              route={option.none}
            />
          </li>
          <li className="channelslist__listitem">
            <ChannelCard
              icon={'/img/channels/easiware.png'}
              title={'Easiware'}
              route={option.none}
            />
          </li>
          <li className="channelslist__listitem">
            <ChannelCard
              icon={'/img/channels/servicenow.png'}
              title={'ServiceNow'}
              route={option.none}
            />
          </li>
          <li className="channelslist__listitem">
            <ChannelCard
              icon={'/img/channels/uipath.png'}
              title={'UiPath'}
              route={option.none}
            />
          </li> */}
        </ul>
      </div>
    </div>
  )
}

export default ChannelsList
