import styled from 'styled-components'
import TrashStyled from '../Trash/styles'

const MoveHandleStyled = styled(TrashStyled)`
  margin-top: ${({ theme }) => -theme.margin.top.trashNotCentered}px;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
`

export default MoveHandleStyled
