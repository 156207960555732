import axios from 'axios'
import { maybe } from '@sbizeul/fp-flow'
import formatFilters from '../../_shared/formatFilters'
import actions from './actions'
import bot from '../../../modules/bot'
import { legacyApiUrl } from 'helpers/effects'

const fetchWordsCount = rawFilters => (dispatch, getState) => {
  const { channelId, start, end } = formatFilters(rawFilters)
  const botId = bot.selectors.getSelectedId(getState())

  dispatch(actions.fetchWordsCountRequest())
  return axios({
    url: `${legacyApiUrl}/analytics/${maybe.get(botId)}/${channelId}/words`,
    params: {
      start,
      end,
      period: 'days'
    },
    method: 'get',
    responseType: 'json'
  })
    .then(response => {
      if (response.status === 200) {
        dispatch(actions.fetchWordsCountSuccess(response.data))
      } else {
        dispatch(actions.fetchWordsCountFailure())
      }
    })
    .catch(err => {
      dispatch(actions.fetchWordsCountFailure(err))
    })
}

export default { fetchWordsCount }
