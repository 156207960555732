import { maybe } from '@sbizeul/fp-flow'

import actions from './actions'
import isResValid from '../../_shared/isResValid'
import formatFilters from '../../_shared/formatFilters'
import bot from '../../../modules/bot'
import api from 'services/tolkApiLegacy'

const fetchMessageHeat = rawFilters => (dispatch, getState) => {
  const { channelId, start, end } = formatFilters(rawFilters)
  const botId = bot.selectors.getSelectedId(getState())

  dispatch(actions.fetchMessagesHeatRequest())
  return api.get(`/analytics/${maybe.get(botId)}/${channelId}/messagesheat`,
  { params: {
    start,
    end
  }}).then(response => {
      if (isResValid(response)) {
        const formatedResponse = {
          data: response.data.result.data,
          count: response.data.result.count
        }
        dispatch(actions.fetchMessagesHeatSuccess(formatedResponse))
      } else {
        dispatch(actions.fetchMessagesHeatFailure())
      }
    })
    .catch(err => {
      dispatch(actions.fetchMessagesHeatFailure(err))
    })
}

export default { fetchMessageHeat }
