import React from 'react'
import onClickOutside from 'react-onclickoutside'
import {
  Image,
  ModaleContainer,
  ModaleStyled,
  ModaleMessageStyled,
  ButtonsContainer,
  PostScriptum,
  ButtonCross
} from './styles'

class ModaleContent extends React.Component {
  state = { isVisible: false }

  componentDidMount() {
    this.setState({ isVisible: true })
  }

  handleClickOutside = () => {
    this.props.resetModale()
  }

  render() {
    const { image, title, message, buttons, postScriptum, short, important, resetModale } = this.props

    return (
      <ModaleStyled short={short} isVisible={this.state.isVisible}>
        <ButtonCross onClick={resetModale}>
          <div />
          <div />
        </ButtonCross>
        {image && <Image src={image} />}
        <ModaleMessageStyled visible important={important}>
          <span>{title}</span>
          <p dangerouslySetInnerHTML={{ __html: message.join('<br />') }} />
        </ModaleMessageStyled>
        <ButtonsContainer numberOfButtons={buttons.length}>
          {buttons.map(({ Component, props }, ind) => (
            <Component key={ind} {...props} />
          ))}
        </ButtonsContainer>
        {postScriptum && <PostScriptum>{postScriptum}</PostScriptum>}
      </ModaleStyled>
    )
  }
}

const ModaleContentTuned = onClickOutside(ModaleContent)

const Modale = props => (
  <ModaleContainer>
    <ModaleContentTuned {...props} />
  </ModaleContainer>
)

export default Modale
