import { Map, fromJS } from 'immutable'
import moment from 'moment'

const initialState = Map(
  fromJS({
    filters: {
      channel: {
        name: null,
        id: null
      },
      intent: {
        name: null,
        id: null
      },
      language: {
        name: null,
        id: null
      },
      dateRange: {
        start: moment().subtract(1, 'week'),
        end: moment()
      },
      periods: {
        visible: [1, 2],
        active: 2,
        data: [
          {
            id: 0,
            name: 'min.'
          },
          {
            id: 1,
            name: 'hours'
          },
          {
            id: 2,
            name: 'days'
          }
        ]
      }
    },
    data: {
      filters: {
        dateRange: {},
        comparisonDateRange: {}
      },
      users: {},
      messages: {},
      conversations: {},
      intents: {},
      delays: {},
      payloads: {},
      demographics: {},
      events: {
        config: [],
        data: [],
        fetching: false,
        noData: true
      }
    },
    isPostingEventChart: false,
    noNewUsersData: false,
    noMessagesPreviousPeriodData: false,
    noConversationsData: false,
    noPayloadsData: false,
    noMessagesCountByHourData: false,
    noDelaysData: false,
    noUsersData: false,
    noDemographicsData: false,
    noMessagesData: false,
    noIntentsData: false,
    fetchingAllData: false
  })
)

export default initialState
