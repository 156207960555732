import moment from 'moment'

const date = moment([2019, 9, 16, 15, 6])

const editVideoElementTemporaryUrlTest = {
  initState: {
    campaigns: [
      {
        id: 1,
        updated: true,
        title: 'Campagne 1',
        elements: [
          {
            type: 'text',
            text: 'plafiplouf'
          },
          {
            type: 'video',
            content: {
              url: '',
              temporaryUrl: ''
            }
          }
        ],
        cohorts: [],
        date,
        kpis: {
          sent: 0,
          read: 0
        },
        status: 'draft'
      },
      {
        id: 2,
        updated: true,
        title: 'Campagne 2',
        elements: [],
        cohorts: [],
        date,
        kpis: {
          sent: 0,
          read: 0
        },
        status: 'scheduled'
      }
    ],
    currentId: 1
  },
  action: {
    index: 1,
    temporaryUrl: 'a url'
  },
  reducedState: {
    campaigns: [
      {
        id: 1,
        updated: true,
        title: 'Campagne 1',
        elements: [
          {
            type: 'text',
            text: 'plafiplouf'
          },
          {
            type: 'video',
            content: {
              url: '',
              temporaryUrl: 'a url'
            }
          }
        ],
        cohorts: [],
        date,
        kpis: {
          sent: 0,
          read: 0
        },
        status: 'draft'
      },
      {
        id: 2,
        updated: true,
        title: 'Campagne 2',
        elements: [],
        cohorts: [],
        date,
        kpis: {
          sent: 0,
          read: 0
        },
        status: 'scheduled'
      }
    ],
    currentId: 1
  }
}

export default editVideoElementTemporaryUrlTest
