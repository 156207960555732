import types from './types'

const fetchFunnelRequest = () => ({
  type: types.FETCH_FUNNEL_REQUEST
})
const fetchFunnelSuccess = payload => ({
  type: types.FETCH_FUNNEL_SUCCESS,
  payload
})
const fetchFunnelFailure = errors => ({
  type: types.FETCH_FUNNEL_NO_DATA,
  payload: errors
})

export default {
  fetchFunnelRequest,
  fetchFunnelSuccess,
  fetchFunnelFailure
}
