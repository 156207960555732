import React, { Component } from 'react'
import { Field } from 'redux-form'
import { Divider, Dropdown, Header } from 'semantic-ui-react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { maybe } from '@sbizeul/fp-flow'

import InputField from '../../../components/InputField'
import SelectField from '../../../components/SelectField'
import CustomForm from './CustomForm'
import { getRessources } from '../../../../redux/selectors'
import operations from '../../../../redux/ressources/operations'
import bot from '../../../../modules/bot'

const required = value => (!_.trim(value) ? 'Required' : undefined)

const getComputedFields = (formType = '', forms) => {
  const getFields = forms.filter(form => form.name === formType)
  if (getFields.length === 0) {
    return []
  }
  return getFields[0].fields.map((field, index) => {
    if (field.type === 'select') {
      return (
        <Field
          key={index}
          type='select'
          name={field.name}
          label={field.name}
          validate={field.required ? required : null}
          required={field.required || false}
          options={field.values.map((value, ind) => ({ key: ind, text: value, value }))}
          component={SelectField}
        />
      )
    } else if (field.type === 'input') {
      return (
        <Field
          key={index}
          type='text'
          name={field.name}
          validate={field.required ? required : null}
          required={field.required || false}
          label={field.name}
          component={InputField}
        />
      )
    }
    return null
  })
}

class AddRessource extends Component {
  state = {}

  onSubmit = data => {
    const formatedData = {
      type: data.formType,
      bot_id: maybe.getOrElse(() => 'unknownBot', this.props.botId),
      ressources: {
        ...data
      }
    }
    this.props.postRessource(formatedData)
  }

  handleChange = (e, { value }) => this.setState({ formType: value })

  render() {
    const ressources = this.props.ressources
    if (!ressources.forms.length) {
      return null
    }

    const formType = this.state.formType

    const availableForms = ressources.forms.map((form, index) => ({
      key: index,
      text: form.name,
      value: form.name
    }))

    const forms = ressources.forms.map(form => (
      <CustomForm
        key={form.name}
        onSubmit={this.onSubmit}
        fields={getComputedFields(form.name, ressources.forms)}
        form={`form-${form.name}`}
        initialValues={{ formType: form.name }}
        loading={this.props.isPosting}
      />
    ))

    return (
      <div>
        <Header as='h3'>1. Choisir le type de données</Header>
        <Dropdown
          onChange={this.handleChange}
          options={availableForms}
          placeholder='Choose ressource to add'
          selection
          value={formType}
        />
        <Divider />
        <Header as='h3'>2. Envoyer le formulaire</Header>
        {formType && forms.filter(form => form.key === formType)}
        <br />
      </div>
    )
  }
}

AddRessource.defaultProps = {
  ressources: {
    forms: []
  }
}

const mapStateToProps = state => ({
  isPosting: state.ressources.get('isPosting'),
  ressources: getRessources(state),
  botId: bot.selectors.getSelectedId(state)
})

const connectedAddRessource = connect(mapStateToProps, operations)(AddRessource)

export default connectedAddRessource
