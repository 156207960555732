import React from 'react'
import { Switch, Route } from 'react-router'

import QaQuestionPageContainer from './QaQuestionPageContainer'
import QaAnswerPageContainer from './QaAnswerPageContainer'
import * as routes from '../routes'

export default function QaRoot() {
  return (
    <div className='QaRoot'>
      <Switch>
        <Route exact path={routes.QA_QUESTIONS} component={QaQuestionPageContainer} />
        <Route exact path={routes.QA_QnA} component={QaAnswerPageContainer} />
      </Switch>
    </div>
  )
}
