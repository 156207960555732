import types from './types'

const initialState = {
  fetching: false,
  fetchingPreviousPeriod: false,
  fetchingOverTime: false
}

const intentsReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.FETCH_INTENTS_REQUEST:
    return {
      ...state,
      fetching: true,
      data: null,
      count: null
    }
  case types.FETCH_INTENTS_NO_DATA:
    return {
      ...state,
      fetching: false
    }
  case types.FETCH_INTENTS_SUCCESS:
    return {
      ...state,
      fetching: false,
      data: action.payload.data,
      count: action.payload.count
    }

  case types.FETCH_INTENTS_PREVIOUS_PERIOD_REQUEST:
    return {
      ...state,
      fetchingPreviousPeriod: true,
      countPreviousPeriod: null
    }
  case types.FETCH_INTENTS_PREVIOUS_PERIOD_NO_DATA:
    return {
      ...state,
      fetchingPreviousPeriod: false
    }
  case types.FETCH_INTENTS_PREVIOUS_PERIOD_SUCCESS:
    return {
      ...state,
      fetchingPreviousPeriod: false,
      countPreviousPeriod: action.payload.count
    }

  case types.FETCH_INTENTS_OVER_TIME_REQUEST:
    return {
      ...state,
      fetchingOverTime: true,
      all: null
    }
  case types.FETCH_INTENTS_OVER_TIME_NO_DATA:
    return {
      ...state,
      fetchingOverTime: false
    }
  case types.FETCH_INTENTS_OVER_TIME_SUCCESS:
    return {
      ...state,
      fetchingOverTime: false,
      all: action.payload
    }

  default:
    return state
  }
}

export default intentsReducer
