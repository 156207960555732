import React, { FunctionComponent, useState, useCallback, useEffect } from 'react'
import { RemoteData, remoteData } from '@sbizeul/fp-flow'
import * as R from 'ramda'
import { FileRejection, useDropzone } from 'react-dropzone'
import { MdClose, MdEdit, MdLink, MdSave, MdAdd } from 'react-icons/md'
import { Modal, TextArea } from 'semantic-ui-react'
import classNames from 'classnames'
import * as O from 'fp-ts/lib/Option'

import Button from 'components/Button'
import ButtonContent from 'components/ButtonContentEditor/ButtonContent'
import TextInput from 'components/TextInput'
import { RemoveContentParam, ButtonAttachmentContent, UploadImageRequestPayload, ButtonsAttachmentContent } from 'modules/answer/types'
import { Question } from 'modules/question/types'
import { ServerFailure, Id } from 'types'
import { renderServerFailure } from 'helpers/react'
import { Map } from 'helpers/map'

import { CarouselAttachment } from './types'

import './styles.css'

type CloseCarousel = (id: Id) => unknown

type Props = Readonly<{
  content: CarouselAttachment
  questions: RemoteData<ServerFailure, ReadonlyArray<Question>>
  imagesUrl: Map<RemoteData<ServerFailure, string>>

  fetchQuestions: () => unknown
  removeContent: (params: RemoveContentParam) => unknown
  close: O.Option<CloseCarousel>
  updateContent: (params: CarouselAttachment) => unknown
  uploadImage: (params: UploadImageRequestPayload) => unknown
}>

const isFirstButtonTitleNotEmpty: (buttonContent: ButtonsAttachmentContent) => boolean = R.compose(R.isEmpty, R.path([0, 'title', 0]))

const CarouselCard: FunctionComponent<Props> = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isImageReject, setImageReject] = useState(false)

  const onDropAccepted = useCallback((acceptedFiles: File[]) => {
    const acceptedFile = acceptedFiles[0]
    if (!acceptedFile) return
    setImageReject(false)
    props.uploadImage({ id: props.content.id, file: acceptedFile })
  }, [props])

  const onDropRejected = useCallback((rejectedFiles: FileRejection[]) => {
    const rejectedFile = rejectedFiles[0]
    if (!rejectedFile) return
    setImageReject(true)
  }, [])

  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({ onDropAccepted, onDropRejected })

  useEffect(() => {
    const handleUpdateImageContent = (imageUrl: string) => {
      const updatedContent = R.set(R.lensProp('image'), imageUrl, props.content)
      props.updateContent(updatedContent)
    }

    props.imagesUrl[props.content.id] && remoteData.map(imageUrl => {
      const previousImageUrl = props.content.image
      if (!R.isEmpty(imageUrl) && imageUrl !== previousImageUrl) {
        handleUpdateImageContent(imageUrl)
      }
    }, props.imagesUrl[props.content.id])
  }, [props.imagesUrl])

  const updateButtons: (buttonsContent: ReadonlyArray<ButtonAttachmentContent>, carouselContent: CarouselAttachment) => unknown =
    (buttonsContent, carouselContent) => {
      const buttons = R.set(R.lensPath(['buttons']), buttonsContent, carouselContent)
      return props.updateContent(buttons)
    }

  const renderCloseButton = () => O.fold(
    () => <div className='CarouselCard-icon-empty--container'/>,
    (close: CloseCarousel) => (<div className='CarouselCard-icon--container'>
      <MdClose
        size='20'
        className='CarouselCard-icon--delete'
        onClick={() => close(R.prop('id', props.content))}
      />
    </div>))(props.close)

  return (
    <div className='CarouselCard-container'>
      {renderCloseButton()}
      <div className='CarouselCard-card--container'>
        <div className='CarouselCard-image--container'>
          <div
            {...getRootProps()}
            className={classNames('CarouselCard-dropzone', {
              'CarouselCard-dropzone-active': isDragActive,
              'CarouselCard-dropzone-forbidden': isDragReject
            })}
            style={!props.content.image ? ({
              backgroundImage: ''
            }) : ({
              backgroundImage: `url(${props.content.image})`,
              width: '3rem'
            })
          }
          >
            <input {...getInputProps()} multiple={false} />
            {!props.content.image && (
              <p className='CarouselCard-dropzone-placeholder'>
                Add a picture
              </p>
            )}
            {isDragActive && !isDragReject && <p>Drop it here</p>}
            {isDragReject && <div>Only image file is accepted</div>}
          </div>
          {isImageReject && renderServerFailure({ message: 'The max image size is 5MB' })}
          {props.content.image &&
            <div className='CarouselCard-Remove' onClick={() => props.updateContent(R.dissoc('image', props.content))}>
              Remove
            </div>}
        </div>
        <TextInput
          className='CarouselCard-input-title'
          type='rounded'
          name='title'
          value={props.content.title}
          onChangeText={title => props.updateContent(R.set(R.lensProp('title'), title, props.content))}
          placeholder='Title'
        />
        <TextArea
          className='CarouselCard-TextArea TextArea TextArea-rounded'
          type='rounded'
          name='description'
          value={props.content.description}
          onChange={(_, evt) => props.updateContent(R.set(R.lensProp('description'), evt.value, props.content))}
          placeholder='Description'
        />
        <div>
          <button className='CarouselCard-button' onClick={() => setIsModalOpen(true)}>
            <div className='CarouselCard-button--add'>{isFirstButtonTitleNotEmpty(props.content.buttons) ? <MdAdd size='20' /> : <MdEdit size='20' />}</div>
            <div className='CarouselCard-button--text'>{isFirstButtonTitleNotEmpty(props.content.buttons) ? 'Create a button' : 'Edit a button'}</div>
          </button>
        </div>
        <Modal
          className='AnswerModal--modal'
          open={isModalOpen}
          closeOnEscape={true}
          closeOnDimmerClick={true}
          onClose={() => setIsModalOpen(false)}
        >
          <div className='CarouselCard-buttonContent--container'>
            <ButtonContent
              buttonsContent={props.content.buttons}
              fetchQuestions={props.fetchQuestions}
              removeContent={props.removeContent}
              updateContent={buttonsContent => updateButtons(buttonsContent, props.content)} questions={props.questions}>
              <MdLink size='30' />
            </ButtonContent>
          </div>
          <Button className='AnswerModal--button' onClick={() => setIsModalOpen(false)}>
            <MdSave className='AnswerModal--icon' size='20' />
            SAVE
          </Button>
        </Modal>
      </div>
    </div>
  )
}

export default CarouselCard
