import { maybe } from '@sbizeul/fp-flow'

import actions from '../actions'
import isResValid from '../../../_shared/isResValid'
import formatFilters from '../../../_shared/formatFilters'
import { addNullValuesByPeriod, groupDataByDay } from '../../../../views/pages/Analytics/utils'
import bot from '../../../../modules/bot'
import api from 'services/tolkApiLegacy'

const fetchUsers = rawFilters => (dispatch, getState) => {
  const { channelId, start, end, period } = formatFilters(rawFilters)
  const botId = bot.selectors.getSelectedId(getState())

  dispatch(actions.fetchUsersRequest())
  return api.get(
    `/analytics/${maybe.get(botId)}/${channelId}/users`,
    {
      params: {
        start,
        end,
        period: 'hours'
      }
    }
  ).then(response => {
      if (isResValid(response)) {
        const formatedData = addNullValuesByPeriod(response.data.result.data, rawFilters.dateRange, period)
        const formatedResponse = {
          data: formatedData,
          usersByDay: groupDataByDay(response.data.result.data),
          count: response.data.result.count
        }
        dispatch(actions.fetchUsersSuccess(formatedResponse))
      } else {
        dispatch(actions.fetchUsersFailure())
      }
    })
    .catch(err => {
      dispatch(actions.fetchUsersFailure(err))
    })
}

export default fetchUsers
