/* tslint:disable no-floating-promises */
import React, { ChangeEventHandler, FocusEventHandler, useEffect, useState } from 'react'
import * as option from 'fp-ts/lib/Option'
import { Checkbox } from 'semantic-ui-react'
import { Maybe } from '@sbizeul/fp-flow'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import * as R from 'ramda'
import axios from 'axios'

import api from 'services/tolkApi'

import useDebounce from 'helpers/useDebounce'
import FormInput from 'components/FormInput'
import { FormResponseContent, SaveFormResponseParams } from 'modules/skill/types'
import { Id, ServerFailure } from 'types'
import { STRING, HTTP_ERROR } from 'helpers/constants'

import './styles.css'
import { renderServerFailure } from 'helpers/react'
import { ZendeskSupportForm } from './components/ZendeskSupportForm'

type Props = Readonly<{
  formId: Maybe<Id>
  formResponse: FormResponseContent
  formResponseError: option.Option<ServerFailure>

  saveFormResponse: (formReponseParam: SaveFormResponseParams) => unknown
}>

export type ZendeskSupportConfigPriority = 'urgent' | 'high' | 'normal' | 'low'

export type ZendeskSupportConfigType =
  | 'problem'
  | 'task'
  | 'incident'
  | 'question'

type ZendeskSupportConfig = Readonly<{
  tags: ReadonlyArray<string>,
  priority: ZendeskSupportConfigPriority,
  type: ZendeskSupportConfigType,
  isEnabled: boolean
}>

type ZendeskSupportConfigOptional = Readonly<{
  tags?: ReadonlyArray<string>,
  priority?: ZendeskSupportConfigPriority,
  type?: ZendeskSupportConfigType,
  isEnabled?: boolean
}>

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Please enter a valid e-mail'),
  url: Yup.string().matches(/^https*:\/\/.+\..+/, 'Please enter a valid url')
})

const baseUrl = (window['_env_' as any] as any)?.TOLK_API_URL || ''

const getOneZendeskSupportConfig = async (botId: string, formId: string) => {
  const result = await api.get(`${baseUrl}/bots/${botId}/forms/${formId}/response-reception/zendesk-support`)
  return result.data
}

const saveOneZendeskSupportConfig = async (
  botId: string,
  formId: string,
  tags: ReadonlyArray<string>,
  priority: ZendeskSupportConfigPriority,
  type: ZendeskSupportConfigType,
  isEnabled: boolean
) => {
  await api.put(
    `${baseUrl}/bots/${botId}/forms/${formId}/response-reception/zendesk-support`,
    {
      tags,
      priority,
      isEnabled,
      type
    }
  )
}

const FormResponse: React.FunctionComponent<Props> = ({ formResponse, saveFormResponse, formId, formResponseError }) => {
  const formResponseEmail = option.getOrElse(() => ({ address: STRING.EMPTY as string, isEnabled: false }))(formResponse.email)
  const formResponseZapier = option.getOrElse(() => ({ url: STRING.EMPTY as string, isEnabled: false }))(formResponse.zapier)

  const [debouncedEmailResponse, emailResponse, setEmailResponse] = useDebounce(formResponseEmail, 1000)
  const [debouncedZapierResponse, zapierResponse, setZapierResponse] = useDebounce(formResponseZapier, 1000)
  const formik = useFormik({
    initialValues: {
      email: emailResponse.address,
      url: zapierResponse.url
    },
    validationSchema,
    onSubmit: inputs => { setEmailResponse({ ...emailResponse, address: inputs.email }) }
  })

  useEffect(() => {
    if (debouncedEmailResponse !== formResponseEmail && formik.isValid) saveFormResponse({ id: formId, email: debouncedEmailResponse })
  }, [debouncedEmailResponse])

  useEffect(() => {
    if (debouncedZapierResponse !== formResponseZapier && formik.isValid) saveFormResponse({ id: formId, zapier: debouncedZapierResponse })
  }, [debouncedZapierResponse])

  const isValidEmail = emailResponse.address.length > 0 && formik.isValid
  const isValidUrl = zapierResponse.url.length > 0 && formik.isValid

  const [priority, setPriority] = useState<typeof STRING.EMPTY | ZendeskSupportConfigPriority>(STRING.EMPTY)
  const [type, setType] = useState<typeof STRING.EMPTY | ZendeskSupportConfigType>(STRING.EMPTY)
  const [tags, setTags] = useState<string>(STRING.EMPTY)
  const [status, setStatus] = useState(false)
  const [message, setMessage] = useState<string>(STRING.EMPTY)

  const onStatusChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setStatus(e.target.checked)
    onZendeskChange({ isEnabled: e.target.checked })
  }
  const onTypeChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
    setType(e.target.value as ZendeskSupportConfigType)
    onZendeskChange({ type: e.target.value as ZendeskSupportConfigType })
  }
  const onPriorityChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
    setPriority(e.target.value as ZendeskSupportConfigPriority)
    onZendeskChange({ priority: e.target.value as ZendeskSupportConfigPriority })
  }
  const onTagsChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setTags(e.target.value)
  }
  const onTagsBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    onZendeskChange({ tags: e.target.value.split(',').map(tag => tag.trim()) })
  }
  const onZendeskInit = async () => {
    try {
      setMessage(STRING.EMPTY)
      const result: ZendeskSupportConfig = await getOneZendeskSupportConfig(localStorage.getItem('botId') as string, (formId as any).value)
      setStatus(result.isEnabled)
      setType(result.type)
      setTags(result.tags.join(', '))
      setPriority(result.priority)
    } catch (error) {
      if (error?.response?.status !== HTTP_ERROR.BAD_REQUEST) {
        setMessage('Error. Unable to get your Zendesk Support configuration.')
      }
      setType('incident')
      setPriority('normal')
    }
  }

  const onZendeskChange = async (zendeskSupportConfigOptional: ZendeskSupportConfigOptional) => {
    try {
      if (priority === STRING.EMPTY || type === STRING.EMPTY) {
        return
      }
      setMessage(STRING.EMPTY)
      await saveOneZendeskSupportConfig(
        localStorage.getItem('botId') as string,
        (formId as any).value,
        zendeskSupportConfigOptional.tags || tags.split(',').map(tag => tag.trim()),
        zendeskSupportConfigOptional.priority || priority,
        zendeskSupportConfigOptional.type || type,
        zendeskSupportConfigOptional.isEnabled !== undefined ? zendeskSupportConfigOptional.isEnabled : status
      )
      setMessage('La configuration Zendesk Support est sauvegardée !')
    } catch (error) {
      if (error?.response?.status === 400) {
        switch (error.response.data.error_description) {
          case 'inexistingForm':
            setMessage('You must save your form before before enabling Zendesk Support connection.')
            break
          case 'inexistingZendeskConfig':
            setStatus(false)
            setMessage('You must configure your Zendesk account before enabling Zendesk Support connection.')
            break
        }
        return
      }

      setMessage('Error. Unable to save your Zendesk Support configuration.')
    }
  }

  useEffect(() => {
    onZendeskInit()
  }, [])

  return (
  <div className='FormResponse-component-container'>
    <h1 className='FormResponse-component-title'>What’s next?</h1>
    <hr className='FormBuilder-content--separator'/>
    <div className='FormResponse-email-container'>
      <Checkbox
        toggle
        className='FormResponse-checkbox'
        type='checkbox'
        checked={emailResponse.isEnabled}
        onClick={(_, data) => isValidEmail && setEmailResponse({ ...emailResponse, isEnabled: data.checked || false })}
        disabled={!isValidEmail}
      />
      <p className='FormResponse-title'>E-mail notification</p>
      <p className='FormResponse-description'>Notify me by e-mail every new entry</p>
        <FormInput
          name='email'
          type='text'
          placeholder='Enter your email address here'
          onChange={(val: React.FormEvent<HTMLInputElement>) => [
            formik.handleChange(val),
            setEmailResponse({ address: val.currentTarget.value, isEnabled: R.isEmpty(val.currentTarget.value) ? false : emailResponse.isEnabled })
          ]}
          value={formik.values.email}
          error={formik.errors.email}
        />
    </div>
    <div className='FormResponse-zapier-container'>
      <Checkbox
        toggle
        className='FormResponse-checkbox'
        type='checkbox'
        checked={zapierResponse.isEnabled}
        onClick={(_, data) => isValidUrl && setZapierResponse({ ...zapierResponse, isEnabled: data.checked || false })}
        disabled={!isValidUrl}
      />
      <p className='FormResponse-title'>Zapier automation</p>
      <p className='FormResponse-description'>Paste your Zapier URL</p>
        <FormInput
          name='url'
          type='text'
          placeholder='Enter your Zapier URL here'
          onChange={(val: React.FormEvent<HTMLInputElement>) => [
            formik.handleChange(val),
            setZapierResponse({ url: val.currentTarget.value, isEnabled: R.isEmpty(val.currentTarget.value) ? false : zapierResponse.isEnabled })
          ]}
          value={formik.values.url}
          error={formik.errors.url}
        />
    </div>
    <ZendeskSupportForm
      isStatusEnabled={status}
      type={type}
      priority={priority}
      tags={tags}
      onStatusChange={onStatusChange}
      onPriorityChange={onPriorityChange}
      onTagsChange={onTagsChange}
      onTagsBlur={onTagsBlur}
      onTypeChange={onTypeChange}
    />
    { message.length > 0 && (
      <p className='FormResponse-errorMessage'>{message}</p>
    )}
    {
      option.fold(
        () => null,
        (error: ServerFailure) => renderServerFailure(error)
        )(formResponseError)
    }
  </div>)
}

export default FormResponse
