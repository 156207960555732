import axios, { AxiosRequestConfig } from 'axios'
import * as browserUtils from '@tolk-genii/browser-utils'
import * as genericUtils from '@tolk-genii/generic-utils'
import * as jwt from 'jsonwebtoken'
import { authManagementBaseUrl, getEnv } from 'helpers/effects'

const oauthAuthorizationHeader = (token: string | null) => {
  return { Authorization: `Bearer ${token}` }
}

const oauthApi = (getToken: () => Promise<string | null>) => (baseUrl: string, config?: Record<string, unknown>) => {
  const axiosInstance = async () => {
    const token = await getToken()
    const oauthHeaders = {
      ...oauthAuthorizationHeader(token)
    }

    return axios.create({
      ...config,
      baseURL: baseUrl,
      headers: oauthHeaders
    })
  }

  const get = async (url: string, config: AxiosRequestConfig = {}) => {
    return (await axiosInstance()).get(url, {
      ...config
    })
  }

  const post = async (url: string, data: any = {}, config: AxiosRequestConfig = {}) =>
  (await axiosInstance()).post(url, data, {
      ...config
    })

  const put = async (url: string, data: any = {}, config: AxiosRequestConfig = {}) =>
  (await axiosInstance()).put(url, data, {
      ...config
    })

  const patch = async (url: string, data: any = {}, config: AxiosRequestConfig = {}) =>
  (await axiosInstance()).patch(url, data, {
      ...config
    })

  const del = async (url: string, config: AxiosRequestConfig = {}) =>
  (await axiosInstance(
    )).delete(url, {
      ...config
    })

  return {
    get,
    put,
    patch,
    post,
    delete: del
  }
}

// @ts-ignore
const AUTH_MANAGEMENT_EXPRESS_BASE_URL = authManagementBaseUrl

const getToken = (authManagementExpressBaseUrl: string) => async (): Promise<string | null> => {
  const accessToken = browserUtils.getCookie(document)('tolk_access_token')
  if (accessToken === null) {
    return null
  }

  const decodedToken = jwt.decode(accessToken)

  if (decodedToken === null || !genericUtils.isObject(decodedToken)) {
    return null
  }

  if (typeof decodedToken?.exp !== 'number') {
    return null
  }

  if (decodedToken.exp * 1000 > Date.now()) {
    return accessToken
  }
  // @ts-ignore
  const cookieDomain = getEnv('COOKIE_DOMAIN').value || ''
  await browserUtils.refreshAccessToken(
    authManagementExpressBaseUrl,
    browserUtils.removeCookie(document, cookieDomain),
    browserUtils.getCookie(document),
    browserUtils.setCookie(document, cookieDomain)
  )()
  const updatedAccessToken = browserUtils.getCookie(document)('tolk_access_token')
  return updatedAccessToken
}

const removeAuthenticationInfos = (localStorage: typeof window.localStorage) => {
  localStorage.removeItem('tolk_authorization_infos')
  // @ts-ignore
  const cookieDomain = getEnv('COOKIE_DOMAIN').value || ''
  browserUtils.removeCookie(document, cookieDomain)('tolk_access_token')
  browserUtils.removeCookie(document, cookieDomain)('tolk_id_token')
  browserUtils.removeCookie(document, cookieDomain)('tolk_refresh_token')
}

const api = oauthApi(getToken(AUTH_MANAGEMENT_EXPRESS_BASE_URL))

export {
  api,
  removeAuthenticationInfos
}
