import _ from 'lodash'
import axios from 'axios'
import React, { Component } from 'react'
import { Table, Modal, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { legacyApiUrl } from 'helpers/effects'

export default class UsersTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      column: null,
      data: props.data,
      direction: null
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.props.data) {
      this.setState({ data: nextProps.data })
    }
  }

  handleSort = clickedColumn => () => {
    const { column, data, direction } = this.state

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        data: _.sortBy(data, [clickedColumn]),
        direction: 'ascending'
      })

      return
    }

    this.setState({
      data: data.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending'
    })
  }

  onDelete = userId => () => {
    this.props.deleteUser(userId)
  }

  handleDisable = (id, activated) => () => {
    this.props.data.forEach(elem => {
      if (elem._id.$oid === id) {
        elem.is_disabled = !elem.is_disabled
        return axios({
          url: `${legacyApiUrl}/admin/user/${id}/toggle`,
          method: 'post',
          responseType: 'json',
          data: {
            is_disabled: activated
          }
        })
      }
    })
    this.setState({ data: this.props.data })
  }

  render() {
    const { column, data, direction } = this.state
    return (
      <Table sortable celled fixed>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell sorted={column === 'login' ? direction : null} onClick={this.handleSort('login')}>
              Username
            </Table.HeaderCell>
            <Table.HeaderCell>Number of bots</Table.HeaderCell>
            <Table.HeaderCell>Last connection</Table.HeaderCell>
            <Table.HeaderCell>Delete</Table.HeaderCell>
            <Table.HeaderCell>Activated</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {_.map(data, ({ _id, username, bot, is_disabled, last_login }) => (
            <Table.Row key={_id.$oid}>
              <Table.Cell>
                <div>
                  <Link style={{ color: '#434B65' }} to={`/admin/user/edit/${_id.$oid}`}>
                    {username}
                  </Link>
                </div>
              </Table.Cell>
              <Table.Cell>
                <div>{bot.length}</div>
              </Table.Cell>
              <Table.Cell>
                <div>{last_login}</div>
              </Table.Cell>
              <Table.Cell>
                <Modal size={'tiny'} trigger={<button>Delete</button>} closeIcon>
                  <Modal.Header>Delete user</Modal.Header>
                  <Modal.Content>Are you sure you want to delete {username}?</Modal.Content>
                  <Modal.Actions>
                    <Button
                      positive
                      icon='checkmark'
                      labelPosition='right'
                      content='Yes'
                      onClick={this.onDelete(_id.$oid)}
                    />
                  </Modal.Actions>
                </Modal>
              </Table.Cell>
              <Table.Cell>
                <div className='ui toggle checkbox'>
                  <input
                    type='checkbox'
                    name='public'
                    checked={!is_disabled}
                    onChange={this.handleDisable(_id.$oid, !is_disabled)}
                  />
                  <label />
                </div>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    )
  }
}
