import React from 'react'
import { Segment, Popup, Loader } from 'semantic-ui-react'
import moment from 'moment'
import isNil from 'lodash/isNil'
import MoreInfoPopup from '../../../components/MoreInfoPopup'

const style = {
  borderRadius: '3px',
  border: 'none',
  boxShadow: '0 2px 4px rgba(50, 50, 93,.1)',
  padding: '32px 15px',
  height: '135px'
}

const textStyle = [
  [
    { color: '#f32c2e', fontSize: '15px' },
    { color: '#00ce60', fontSize: '15px' }
  ],
  [
    { color: '#00ce60', fontSize: '15px' },
    { color: '#f32c2e', fontSize: '15px' }
  ]
]

const ComparisonPopup = ({ trigger, content }) => (
  <Popup
    inverted
    position='bottom right'
    style={{ padding: '5px 7px 3px 7px', radius: '1px', fontSize: '0.9rem' }}
    trigger={trigger}
    content={content}
  />
)

function textWithVariation(
  value,
  { increaseIsGood = 1, variation = undefined, prevValue = undefined, comparisonDateRange },
  suffixe = ''
) {
  if (!isFinite(variation)) {
    return <h6>{value + suffixe}</h6>
  } else if (variation >= 0) {
    return (
      <h6>
        {value}
        {suffixe}
        <ComparisonPopup
          trigger={<span style={textStyle[increaseIsGood][0]}> +{variation}%</span>}
          content={`${moment(comparisonDateRange.start).format('MMM D')} - ${moment(comparisonDateRange.end).format(
            'MMM D'
          )}: ${prevValue}`}
        />
      </h6>
    )
  } else if (variation < 0) {
    return (
      <h6>
        {value + suffixe}
        <ComparisonPopup
          trigger={<span style={textStyle[increaseIsGood][1]}> {variation}%</span>}
          content={`${moment(comparisonDateRange.start).format('MMM D')} - ${moment(comparisonDateRange.end).format(
            'MMM D'
          )}: ${prevValue}`}
        />
      </h6>
    )
  }
  return <h6>{value + suffixe}</h6>
}

const Tiles = ({ name, noData, comparison = {}, value, moreInfo, suffixe, loading }) => {
  if (loading) {
    return (
      <Segment style={style}>
        <h1>
          {name}
          {moreInfo && <MoreInfoPopup content={moreInfo} />}
        </h1>
        <Loader active inline='centered' />
      </Segment>
    )
  }
  if (isNil(value) || isNaN(value)) {
    return (
      <Segment style={style}>
        <h1>
          {name}
          {moreInfo && <MoreInfoPopup content={moreInfo} />}
        </h1>
        <h6>0</h6>
      </Segment>
    )
  }
  if (value) {
    return (
      <Segment style={style}>
        <h1>
          {name}
          {moreInfo && <MoreInfoPopup content={moreInfo} />}
        </h1>
        {textWithVariation(value, comparison, suffixe)}
      </Segment>
    )
  }
  return (
    <Segment style={style}>
      <h1>
        {name}
        {moreInfo && <MoreInfoPopup content={moreInfo} />}
      </h1>
      {textWithVariation(value, comparison, suffixe)}
    </Segment>
  )
}

export default Tiles
