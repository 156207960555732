import * as E from 'fp-ts/lib/Either'
import * as R from 'ramda'

import {
  SaveChannelInformationFailure,
  SAVE_CHANNEL_INFORMATION_REQUEST,
  SAVE_CHANNEL_INFORMATION_SUCCESS,
  SAVE_CHANNEL_INFORMATION_FAILURE, ChannelSettingsUIState, ChannelsUIAction
} from './types'

export const initialState: ChannelSettingsUIState = {
  saving: E.right(false)
}

const saveRequest = R.assoc('saving', E.right(true))

const saveSuccess = R.assoc('saving', E.right(false))

const saveFailure = (state: ChannelSettingsUIState, action: SaveChannelInformationFailure) =>
  R.assoc('saving', E.left(action.payload), state)

export default function channels (state: ChannelSettingsUIState = initialState, action: ChannelsUIAction) {
  switch (action.type) {
    case SAVE_CHANNEL_INFORMATION_REQUEST:
      return saveRequest(state)
    case SAVE_CHANNEL_INFORMATION_SUCCESS:
      return saveSuccess(state)
    case SAVE_CHANNEL_INFORMATION_FAILURE:
      return saveFailure(state, action)
    default:
      return state
  }
}
