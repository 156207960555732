import {
  call,
  put,
  race,
  select,
  spawn,
  take,
  takeLatest,
  takeEvery,
  all
} from 'redux-saga/effects'
import { maybe, remoteData } from '@sbizeul/fp-flow'
import * as R from 'ramda'
import { pipe } from 'fp-ts/lib/pipeable'

import { setItem } from 'helpers/effects'
import Api from 'services/tolkApi'
import AccountApi from 'services/accountManagementApi'
import botManagementApi from 'services/botManagementApi'
import ApiLegacy from 'services/tolkApiLegacy'
import { createSocketChannel, socketIoClient } from 'utils/websocket'
import user from 'modules/user'
import { AxiosErrorResponse, ServerFailure } from 'types'
import * as browserUtils from '@tolk-genii/browser-utils'

import * as actions from './actions'
import { getFromLocalStorage, getWebchatIdFromUrl } from './models'
import * as selectors from './selectors'
import { botCreationToApi } from './saga.acl'
import { FetchAllRequest, Publish, SaveWebchat, Select, FetchOneRequest } from './types'

import * as utils from './components/WebchatConfig/utils'

const toServerFailure: (
  axiosError: AxiosErrorResponse<ServerFailure>
) => ServerFailure = (axiosError) => axiosError.response.data

export function* publishStatusEndpoint() {
  const botId = maybe.getOrElse(
    () => 'unknown',
    yield select(selectors.getSelectedId)
  )
  return `/bot/${botId}/qa/publish`
}

export function* fetchOne(action: FetchOneRequest) {
  type Bot = {
    name: string
    id: string
  }

  const userId = action.payload.userId
  try {
    const getClientInfos = yield call(
      AccountApi.get,
      `/v1/users/${userId}/client-details`
    )

    const selectedBot = getFromLocalStorage(getClientInfos.data.bots)

    const getBotConfiguration = yield call(
      botManagementApi.get,
      `/v1/bots/${selectedBot.id}`
    )

    yield put(actions.fetchOneSuccess(getBotConfiguration.data))

    yield put(actions.select(getBotConfiguration.data.id))
  } catch (error) {
    yield put(actions.fetchOneFailure(toServerFailure(error)))
  }
}

export function* fetchAll(action: FetchAllRequest) {
  const userId = action.payload.userId
  try {
    const userBotsResult = yield call(
      AccountApi.get,
      `/v1/users/${userId}/bots`
    )

    const results = yield all(
      userBotsResult.data.bots.map((botId: string) => {
        return call(botManagementApi.get, `/v1/bots/${botId}`)
      })
    )

    // @ts-ignore
    const result = results.reduce((accumulator, currentValue) => {
      return [...accumulator, currentValue.data]
    }, [])

    yield put(actions.fetchAllSuccess(result))

    if (result.length === 0) {
      localStorage.removeItem('botId')
      return
    }
    const selectedBot = getFromLocalStorage(result)
    yield put(actions.select(selectedBot.id))
  } catch (error) {
    yield put(actions.fetchAllFailure(toServerFailure(error)))
  }
}

export function* selectBot(action: Select) {
  yield put(actions.unregisterPublishStatus())
  yield call(setItem, 'botId', action.payload)
  const getBotConfiguration = yield call(
    botManagementApi.get,
    `/v1/bots/${action.payload}`
  )
  yield put(actions.fetchOneSuccess(getBotConfiguration.data))
  yield put(actions.fetchPublishStatus())
  yield put(actions.registerPublishStatus())
}

export function* fetchPublishStatus() {
  // @ts-ignore
  const getPublishStatusEndpoint = yield call(publishStatusEndpoint)
  try {
    const { data } = yield call(Api.get, getPublishStatusEndpoint)
    yield put(actions.fetchPublishStatusSuccess(data))
  } catch (error) {
    yield put(actions.fetchPublishStatusFailure(toServerFailure(error)))
  }
}

export function* publish(action: Publish) {
  const getPublishStatusEndpoint = `/bot/${action.payload}/qa/publish`
  try {
    const { data } = yield call(Api.post, getPublishStatusEndpoint)
    yield put(actions.publishSuccess(data))
  } catch (error) {
    yield put(actions.publishFailure(toServerFailure(error)))
  }
}

export function* publishStatusWatcher() {
  while (true) {
    yield take(actions.REGISTER_PUBLISH_STATUS)
    yield race({
      task: call(watchPublishStatus),
      cancel: take(actions.UNREGISTER_PUBLISH_STATUS)
    })
  }
}

export function* watchPublishStatus() {
  const botId = maybe.getOrElse(
    () => 'unknown',
    yield select(selectors.getSelectedId)
  )
  const publishChannel = yield call(
    createSocketChannel,
    `publishStatus:${botId}`
  )
  const socketChannel = yield call(publishChannel, socketIoClient)

  while (true) {
    try {
      const publishStatus = yield take(socketChannel)
      yield put(actions.fetchPublishStatusSuccess(publishStatus))
    } catch (error) {
      socketChannel.close()
    }
  }
}

export function* createBot() {
  try {
    const userId = browserUtils.getAuthenticatedUserId()
    const url = '/bot'
    const botCreationState = yield select(selectors.getBotCreation)
    const botCreationStateSplit = pipe(
      botCreationState,
      R.over(R.lensProp('greetings'), R.split('\n\n')),
      R.set(R.lensProp('userID'), userId),
      botCreationToApi
    )

    const { data } = yield call(Api.post, url, botCreationStateSplit)
    yield put(actions.registerCreatedBotSuccess(data))
  } catch (error) {
    yield put(actions.registerCreatedBotFailure(toServerFailure(error)))
  }
}

export function* getLastBot() {
  try {
    const selectedUser = remoteData.getOrElse(
      () => ({ userId: 'error' }),
      yield select(user.selectors.getUser)
    )
    // eslint-disable-next-line no-console
    const botId = yield select(selectors.getBotId)
    // eslint-disable-next-line no-console

    yield put(actions.fetchAll(selectedUser))

    yield put(actions.select(botId))
  } catch (error) {
    yield put(actions.fetchAllFailure(toServerFailure(error)))
  }
}

export function* fetchWebchat() {
  const webchatId = R.pipe(
    maybe.map(getWebchatIdFromUrl),
    maybe.getOrElse(() => 'unknown')
  )(yield select(selectors.getWebchatUrl))
  try {
    const { data } = yield call(
      ApiLegacy.get,
      `/webchat/${webchatId}?cache=clear`
    )
    yield put(actions.fetchWebchatSuccess(data))
  } catch (error) {
    yield put(actions.fetchWebchatFailure(toServerFailure(error)))
  }
}

export function* saveWebchat(action: SaveWebchat) {
  const botId = action.payload.bot_id
  const botName = action.payload.settings.title

  const colours = {
    primaryColour: action.payload.settings.color1,
    secondaryColour: action.payload.settings.color2,
    textColour: '#EBEAEF'
  }

  const avatar = action.payload.settings.avatar.pics

  const active = action.payload.active
  const params = {
    active,
    avatar,
    botName,
    colours
  }

  try {
    ;(window as any).botSDK.cleanStorage()
    yield call(Api.post, `/bot/${botId}`, params)
    yield put(actions.saveWebchatSuccess(botId))
  } catch (error) {
    yield put(actions.saveWebchatFailure(toServerFailure(error)))
  }
}

export function* fetchUseCases() {
  const getUseCasesEndpoint = '/admin/use-cases'
  try {
    const { data } = yield call(Api.get, getUseCasesEndpoint)
    yield put(actions.fetchUseCasesSuccess(data))
  } catch (error) {
    const errorMessage: ServerFailure = { message: error.response.data }
    yield put(actions.fetchUseCasesFailure(errorMessage))
  }
}

export function* refreshWebchat(action: SaveWebchat) {
  const webchatId = R.pipe(
    maybe.map(getWebchatIdFromUrl),
    maybe.getOrElse(() => 'unknown')
  )(yield select(selectors.getWebchatUrl))

  utils.removeBot()

  utils.addBot(webchatId)
}

export function* root() {
  yield takeLatest(actions.FETCH_ALL_REQUEST, fetchAll)
  yield takeLatest(actions.FETCH_USE_CASES_REQUEST, fetchUseCases)
  yield takeLatest(actions.FETCH_PUBLISH_STATUS_REQUEST, fetchPublishStatus)
  yield takeLatest(actions.PUBLISH_REQUEST, publish)
  yield takeLatest(actions.BOT_CREATION_REQUEST, createBot)
  yield takeLatest(actions.BOT_CREATION_GET_LAST_BOT, getLastBot)
  yield takeLatest(actions.FETCH_WEBCHAT_REQUEST, fetchWebchat)
  yield takeLatest(actions.SAVE_WEBCHAT, saveWebchat)
  yield takeLatest(actions.SAVE_WEBCHAT_SUCCESS, fetchWebchat)
  yield takeLatest(actions.SAVE_WEBCHAT_SUCCESS, refreshWebchat)
  yield takeEvery(actions.SELECT, selectBot)
  yield takeEvery(user.actions.LOGIN_SUCCESS, fetchOne)
  yield spawn(publishStatusWatcher)
}
