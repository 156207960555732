import { createSelector } from 'reselect'
import { remoteData } from '@sbizeul/fp-flow'

import * as map from '../../helpers/map'

export const getState = state => state.log

export const getAll = createSelector(getState, state => remoteData.map(map.toArray, state.byId))

export const getPage = createSelector(getState, state => state.page)
