import React, { Component } from 'react'
import { Button, Tab } from 'semantic-ui-react'
import { connect } from 'react-redux'
import operations from '../../../redux/psadata/operations'

import { commentairesSelector } from '../../../redux/selectors'

import PostPane from './components/PostPane'

class PsaCommentaires extends Component {
  componentDidMount() {
    const { getCommentaires } = this.props
    getCommentaires()
  }

  render() {
    const {
      commentairesData,

      isFetchingCreate,

      getCommentaires
    } = this.props

    const panes = [
      {
        menuItem: 'Commentaires',
        pane: (
          <Tab.Pane style={{ height: 'calc(100% - 100px)', marginBottom: '0px' }}>
            <PostPane data={commentairesData} type='defaut_data' isFetching={isFetchingCreate} />
          </Tab.Pane>
        )
      }
    ]

    return (
      <div style={{ height: '100%' }}>
        <Button
          content='Refresh'
          onClick={() => {
            getCommentaires()
          }}
        />
        <br />
        <br />
        <Tab style={{ height: 'calc(100% - 50px)' }} panes={panes} renderActiveOnly={false} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  commentairesData: commentairesSelector(state),
  isFetchingCreate: state.psa.getIn(['prisePoste', 'isFetching'])
})

const ConnectedPsaPoste = connect(mapStateToProps, operations)(PsaCommentaires)

export default ConnectedPsaPoste
