import { css } from 'styled-components'

const initCss = css`
  *,
  *:before,
  *:after {
    box-sizing: inherit;
    position: relative;
    margin: 0;
    padding: 0;
    outline: none;
  }
`
const stateHover = css`
  :hover {
    opacity: 0.25;
  }
`

const initButton = css`
  font-family: inherit;
  font-weight: inherit;
  color: inherit;
  font-weight: inherit;
  background: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
`

const initInput = css`
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
`

const initA = css`
  text-decoration: none;
  color: inherit;
`

export { initButton, initInput, initCss, initA, stateHover }
