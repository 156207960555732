import React from 'react'
import * as R from 'ramda'

import './style.css'

const renderNotification = popNotification => notification => {
  const delayedPop = setTimeout(() => popNotification(notification.id), 5000)
  const hideNotification = notificationId => () => {
    clearTimeout(delayedPop)
    popNotification(notificationId)
  }
  return (
    <div className='notification' key={notification.id}>
      <div className='notification-wrapper notification-element'>
        <div className='notification-message'>{notification.message}</div>
        {notification.onCancel && (
          <button
            className='notification-element button--cancel'
            onClick={R.pipe(notification.onCancel, hideNotification(notification.id))}
          >
            CANCEL
          </button>
        )}
        <button className='notification-element' onClick={hideNotification(notification.id)}>
          HIDE
        </button>
      </div>
    </div>
  )
}

const limitNotificationsDisplayed = 3

const Notifications = ({ notifications, popNotification }) => (
  <div className='Notifications'>
    {R.pipe(R.slice(0, limitNotificationsDisplayed), R.map(renderNotification(popNotification)))(notifications)}
  </div>
)

export default Notifications
