import styled, { css } from 'styled-components'
import OutlinedUI from '../OutlinedUI'

const cssForCarousel = css`
  width: 100% !important;
  border: none;
  border-radius: unset !important;
`

const withoutThumbnail = ({ url, isDragActive }) => isDragActive || !url

const DropzoneContainer = styled.div`
  height: ${({ theme }) => theme.height.elementImage}px !important;
  width: ${({ theme }) => theme.width.elementImage}px !important;
  background: ${props => (withoutThumbnail(props) ? props.theme.color.white : '')};
  background-image: ${props => (withoutThumbnail(props) ? '' : `url('${props.url}')`)};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: ${({ theme }) => theme.borderRadius.elementImage}px !important;
  border-color: ${({ theme }) => theme.color.grey} !important;
  border-style: ${props => (withoutThumbnail(props) ? 'dashed' : 'solid')} !important;
  border-width: ${props => (withoutThumbnail(props) ? '2px' : '1px')} !important;
  justify-content: flex-end !important;
  overflow: hidden;
  &.dropzone-forbidden {
    border-color: red !important;
  }
  &.dropzone-forbidden * {
    color: red !important;
  }
  ${props => props.forCarousel && cssForCarousel}
`

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 250ms ease-in-out;
  &.isVisible {
    opacity: 1;
    visibility: visible;
    > * {
      pointer-events: auto;
    }
  }
  &.isForbidden {
    background: red;
  }
`

const ReplaceButton = styled(OutlinedUI)`
  pointer-events: none;
`

const SaveButton = styled(OutlinedUI)``
const DeleteButton = styled(OutlinedUI)``

export { DropzoneContainer, ReplaceButton, SaveButton, DeleteButton, ButtonsContainer }
