
import React from 'react'
import * as R from 'ramda'
import { AreaChart, Area, Tooltip, ResponsiveContainer, XAxis } from 'recharts'
import { withTheme } from 'styled-components'

import {
  ChartContainer,
  LegendContainer,
  LegendColorIndicator,
  LegendSection,
  TimeButton,
  Legend,
  LegendText
} from './styles'
import { getHourInterval, getDayInterval, getWeekInterval, nextLimits, intervalLabels } from '../../helpers'

const initialState = {
  selectedTime: 'hour'
}

const colors = ['#3a86ff', '#e2574c', '#1a2b63', '#747a8d', '#b2b1b1']

const formats = {
  hour: 'LT',
  day: 'DD/MM/YY h:mm a',
  week: 'll',
  verbose: { hour: 'LLL', day: 'LLL', week: 'LLL' }
}

const getColor = i => colors[i] || '#434b65'

const getTotal = payload => payload.reduce((result, entry) => result + entry.value, 0)

const renderTooltipContent = R.curry((render, chartPayload) => {
  const payload = R.propOr([], 'payload')(chartPayload)
  const total = getTotal(payload)
  return (
    <div className='AnswersChart-tooltip-content'>
      <p
        className='AnswersChart-tooltip-total'
        dangerouslySetInnerHTML={{
          __html: render[chartPayload.label]
        }}
      ></p>
      <p className='AnswersChart-tooltip-total'>Total: {total}</p>
      <ul className='AnswersChart-list'>
        {payload.map((entry, i) => (
          <li key={i} style={{ color: entry.color }}>
            {`${entry.name}: ${entry.value}`}
          </li>
        ))}
      </ul>
    </div>
  )
})

const CustomizedAxisTick = labels => ({ payload, x, y }) => {
  return (
    <g transform={`translate(${x},${y})`} style={{ fontSize: '12px' }}>
      <text font-size='12'>
        {labels[payload.value].split('<br />').map((e, i) => (
          <tspan key={1} x={0} y={i * 8} dy={16} textAnchor='middle'>
            {e}
          </tspan>
        ))}
      </text>
    </g>
  )
}

const formattedDataFn = { hour: getHourInterval, day: getDayInterval, week: getWeekInterval }

class AnalyticsStackChart extends React.Component {
  state = initialState

  setSelectedTime = selectedTime => this.setState({ selectedTime })

  handleTimeChange = time => () => this.setSelectedTime(time)

  formatDate = R.curry((verbose, time, startDate, step) => {
    const newDate = nextLimits[time](startDate, step)
    return newDate.format(verbose ? formats.verbose[time] : formats[time])
  })

  formatDateLight = this.formatDate(false)
  formatDateVerbose = this.formatDate(true)

  render() {
    const { data, sentDate } = this.props
    const { selectedTime } = this.state
    const formattedData = formattedDataFn[selectedTime](data, sentDate)
    const dataKeys = R.keys(R.head(formattedData))

    const relevantIntervalLabels = intervalLabels[selectedTime](sentDate)
    const relevantIntervalDetails = intervalLabels.details[selectedTime](sentDate)

    return (
      <ChartContainer>
        <LegendContainer>
          <LegendSection>
            <TimeButton onClick={this.handleTimeChange('hour')} active={selectedTime === 'hour'}>
              Hour
            </TimeButton>
            <TimeButton onClick={this.handleTimeChange('day')} active={selectedTime === 'day'}>
              Day
            </TimeButton>
            <TimeButton onClick={this.handleTimeChange('week')} active={selectedTime === 'week'}>
              Week
            </TimeButton>
          </LegendSection>
          <LegendSection>
            {dataKeys.map((dataKey, i) => (
              <Legend key={dataKey}>
                <LegendColorIndicator color={getColor(i)} />
                <LegendText>{dataKey}</LegendText>
              </Legend>
            ))}
          </LegendSection>
        </LegendContainer>
        <ResponsiveContainer width='100%'>
          <AreaChart
            width={500}
            height={300}
            data={formattedData}
            margin={{
              top: 10,
              right: 0,
              left: 0,
              bottom: 0
            }}
          >
            <XAxis height={60} tick={CustomizedAxisTick(relevantIntervalLabels)} />
            <Tooltip content={renderTooltipContent(relevantIntervalDetails)} />
            {dataKeys.map((dataKey, i) => (
              <Area
                key={dataKey}
                type='monotone'
                dataKey={dataKey}
                stackId='1'
                stroke={getColor(i)}
                fill={getColor(i)}
                activeDot={{ r: 8 }}
              />
            ))}
          </AreaChart>
        </ResponsiveContainer>
      </ChartContainer>
    )
  }
}

export default withTheme(AnalyticsStackChart)
