import moment from 'moment'
import { List } from 'immutable'

export const stripDateDetails = momentDate => momentDate && momentDate.clone().set({ H: 0, m: 0, s: 0, ms: 0 })

export const getTimeDelta = (start, end) => moment.duration(end.diff(start))

export const visiblePeriods = (start, end) => {
  const timeDelta = getTimeDelta(start, end)
  if (timeDelta.asHours() < 48) {
    return [0, 1, 2]
  }
  if (timeDelta.asDays() >= 15) {
    return [2]
  }
  return [1, 2]
}

export const getComparisonDateRange = dateRange => {
  const numberOfDays = dateRange.end.diff(dateRange.start, 'days')
  return {
    start: dateRange.start
      .clone()
      .subtract(numberOfDays + 1, 'days')
      .format('YYYY-MM-DD'),
    end: dateRange.end
      .clone()
      .subtract(numberOfDays + 1, 'days')
      .format('YYYY-MM-DD')
  }
}

export const serieExists = (state, serieId) => {
  return state.getIn(['data', 'event', 'data'], List()).filter(serie => serieId === serie.get('id')).size !== 0
}
