import * as React from 'react'
import classNames from 'classnames'

import './style.css'

function LinkAction({ className, children, to }) {
  return (
    <div className={classNames('LinkAction', className)} onClick={() => to()}>
      {children}
    </div>
  )
}

export default LinkAction
