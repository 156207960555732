import types from './types'
import initialState from './initialState'

const globalReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.SET_FETCH_ERROR:
    return state.set('fetchError', true).set('messageError', action.payload)
  case types.REMOVE_FETCH_ERROR:
    return state.set('fetchError', false)
  case types.SET_LOADING:
    if (action.message) {
      return state.set('loading', true).set('loadingMessage', action.message)
    }
    return state.set('loading', true)
  case types.REMOVE_LOADING:
    return state.set('loading', false).set('loadingMessage', undefined)
  default:
    return state
  }
}

export default globalReducer
