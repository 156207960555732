import React, { useEffect } from 'react'
import { remoteData } from '@sbizeul/fp-flow'
import * as R from 'ramda'
import { v4 as uuid } from 'uuid'

import { renderNothing, renderInlineLoader, renderFailure } from 'helpers/react'
import {
  QuestionId,
  QuestionReferencesElements,
  RemoteDataQuestionReferencesElements
} from 'modules/question/types'

export type Props = Readonly<{
  questionReferences: RemoteDataQuestionReferencesElements
  questionId: QuestionId

  fetchQuestionReferences: (questionId: QuestionId) => unknown
}>

const renderQuestionReferencesModalContent: (
  questionReferences: QuestionReferencesElements
) => JSX.Element = (questionReferences) =>
  questionReferences.questionNames.length === 0 &&
  questionReferences.advancedSkillNames.length === 0 ? (
    <p>Are you sure to delete this question</p>
  ) : (
    <div>
      <p className="DeleteQuestion-linkedElement--title">
        You should remove the question in the content linked bellow :
      </p>
      <ul>
        {R.map((questionName: string) => (
          <li className="Question-linkedElement--list" key={uuid()}>
            <p className="Question-linkedElement--title">
              Name: {questionName}
            </p>
            <p className="Question-linkedElement--type">
              Type of content: {'Q&A'}
            </p>
          </li>
        ))(questionReferences.questionNames)}
        {R.map((advancedSkillName: string) => (
          <li className="Question-linkedElement--list" key={uuid()}>
            <p className="Question-linkedElement--title">
              Name: {advancedSkillName}
            </p>
            <p className="Question-linkedElement--type">
              Type of content: {'Skill'}
            </p>
          </li>
        ))(questionReferences.advancedSkillNames)}
      </ul>
    </div>
  )

const RemoteQuestionModalContent = (props: Props) => {
  useEffect(() => {
    props.fetchQuestionReferences(props.questionId)
  }, [props.questionId])
  return remoteData.fold(
    {
      Success: (questionReferences: QuestionReferencesElements) =>
        renderQuestionReferencesModalContent(questionReferences),
      NotAsked: renderNothing,
      Loading: () => renderInlineLoader('Please wait...'),
      Failure: (error) => renderFailure(error.error_description)
    },
    props.questionReferences
  )
}

export default RemoteQuestionModalContent
