import React, { Component } from 'react'
import { connect } from 'react-redux'
import operations from '../../../../../../redux/analytics/messages/operations'
import getSafe from '../../../../../utils/getSafe'
import Messages from './Messages'

class MessagesContainer extends Component {
  render() {
    return (
        <Messages {...this.props} />
    )
  }
}

const mapStateToProps = state => ({
  messagesData: getSafe(() => state.analytics.messages.data),
})

export default connect(mapStateToProps, operations)(MessagesContainer)
