import React from 'react'
import * as R from 'ramda'
import { Field, reduxForm } from 'redux-form'
import { Form, Button } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { MdArrowForward, MdRemoveRedEye, MdClear, MdCheck } from 'react-icons/md'
import { remoteData } from '@sbizeul/fp-flow'

import contentOperations from '../../../../redux/intents/operations'

import './styles.css'

const required = value => (value ? undefined : 'Required')
const noEmpty = value => (R.trim(value) ? undefined : 'No Empty')
const length = value => (value.length >= 14 ? undefined : 'Too short, 14 characters')
const maj = value => (value.replace(/[^A-Z]/g, '').length > 0 ? undefined : 'One Uppercase')
const char = value => (value.replace(/[^~!@#$%^&*()\-_=+[\]{};:,.<>/?|]/g, '').length > 0 ? undefined : 'One Special Char')
const num = value => (value.replace(/[^0-9]/g, '').length > 0 ? undefined : 'One Number')

const passwordField = ({
  className,
  icon,
  input,
  label,
  required,
  type,
  meta: { touched, error, warning }
}) => (
  <Form.Field>
    {required ? <label htmlFor={input.name}>{`${label}*`}</label> : <label htmlFor={input.name}>{label}</label>}
    <div className='PasswordField-container'>
      <Form.Input
        {...input}
        className={className}
        placeholder={label}
        type={type}
        value={input.value ? input.value : ''}
        error={touched && error ? true : null}
        icon={icon}
      />
      {touched && (error || warning) && <MdClear className='PasswordField-icon' size='20' color='red' />}
      {touched && !error && <MdCheck className='PasswordField-icon' size='20' color='green' />}
    </div>
  </Form.Field>
)

class PassForm extends React.Component {
  state = {
    showFirstPassword: false,
    showSecondPassword: false,
    showThirdPassword: false
  }

  render() {
    const { showFirstPassword, showSecondPassword, showThirdPassword } = this.state
    const { postPassword, handleSubmit, pristine, submitting } = this.props
    const isPasswordShow = showPassword => (showPassword ? 'text' : 'password')
    return (
      <div className='Profile-container'>
        <p className='Profile-text'>Update your password</p>
        <Form onSubmit={handleSubmit(postPassword)}>
          <Field
            className='Profile-input'
            icon={
              <MdRemoveRedEye
                className={`Profile-icon ${showFirstPassword && 'Profile-icon-active'}`}
                onClick={() => this.setState({ showFirstPassword: !showFirstPassword })}
              />
            }
            validate={[required, noEmpty]}
            name='passwordold'
            label='Your actual password'
            component={passwordField}
            type={isPasswordShow(showFirstPassword)}
          />
          <Field
            className='Profile-input'
            icon={
              <MdRemoveRedEye
                className={`Profile-icon ${showSecondPassword && 'Profile-icon-active'}`}
                onClick={() => this.setState({ showSecondPassword: !showSecondPassword })}
              />
            }
            validate={[required, noEmpty, length, maj, char, num]}
            name='password'
            label='Your new password'
            component={passwordField}
            type={isPasswordShow(showSecondPassword)}
          />
          <Field
            className='Profile-input'
            icon={
              <MdRemoveRedEye
                className={`Profile-icon ${showThirdPassword && 'Profile-icon-active'}`}
                onClick={() => this.setState({ showThirdPassword: !showThirdPassword })}
              />
            }
            validate={[required, noEmpty]}
            name='password2'
            component={passwordField}
            label='Confirm your new password'
            type={isPasswordShow(showThirdPassword)}
          />
          <p className='Profile-password-instruction'>
            * Your password should have 14 characters, one uppercase, one number and one special character
          </p>
          <Button className='Profile-button' type='submit' disabled={pristine || submitting}>
            <p>SUBMIT</p>
            <MdArrowForward size='20' />
          </Button>
        </Form>
      </div>
    )
  }
}

const mapDispatchToProps = {
  postPassword: contentOperations.postPassword
}

let InitializeFromStateForm = reduxForm({
  enableReinitialize: true,
  form: 'passForm'
})(PassForm)

InitializeFromStateForm = connect(
  state => ({
    initialValues: {
      user: remoteData.getOrElse(() => 'notLoggedUser', state.user.me)
    }
  }),
  {}
)(InitializeFromStateForm)

export default connect(null, mapDispatchToProps)(InitializeFromStateForm)
