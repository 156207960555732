import actions from '../actions'
import words from '../words/operations'
import intents from '../intents/operations'
import entities from '../entities/operations'
import payloads from '../payloads/operations'
import answers from '../answers/operations'
import wordsusers from '../wordsusers/operations'

const fetchTrends = filters => async dispatch => {
  dispatch(actions.fetchTrendsRequest())
  try {
    const wordsPromise = dispatch(words.fetchWordsCount(filters))
    const intentsPromise = dispatch(intents.fetchIntents(filters))
    const entitiesPromise = dispatch(entities.fetchEntities(filters))
    const payloadsPromise = dispatch(payloads.fetchPayloads(filters))
    const answersPromise = dispatch(answers.fetchAnswers(filters))
    const wordsusersPromise = dispatch(wordsusers.fetchWordsusers(filters))
    await Promise.all([
      wordsPromise,
      intentsPromise,
      entitiesPromise,
      payloadsPromise,
      answersPromise,
      wordsusersPromise
    ])
    // await dispatch(actions.fetchCustomEventsData(filters))
    await dispatch(actions.fetchTrendsSuccess())
  } catch (e) {
    await dispatch(actions.fetchTrendsFailure())
  }
}

export default fetchTrends
