import React from 'react'
import ReactHighcharts from 'react-highcharts'
import defaultStyle from '../chartStyle'
import NoDataSegment from '../NoDataSegment'

const config = {
  ...defaultStyle,
  chart: {
    type: 'column'
  },
  xAxis: {
    crosshair: true
  },
  yAxis: {
    max: 100,
    title: false,
    labels: {
      format: '{value}%'
    }
  },
  tooltip: {
    valueSuffix: '%',
    followPointer: true
  }
}

export default function RetentionGraphEntities({ entities = null, noDataDisplay }) {
  if (noDataDisplay) {
    return <NoDataSegment />
  }
  const series = []
  if (entities) {
    series.push({
      animation: false,
      name: 'Users',
      data: entities.map(elem => elem.value)
    })
  }
  config.xAxis.categories = entities.map(elem => elem.name)
  return <ReactHighcharts config={{ ...config, series }} domProps={{ id: 'RetentionGraphEntities' }} />
}
