export const toOption = channel => {
  return {
    text: channel.name || channel.slug,
    value: channel._id
  }
}

export const toMapById = channels => {
  return channels.reduce((acc, it) => {
    acc[it._id] = it
    return acc
  }, {})
}
