import { maybe } from '@sbizeul/fp-flow'

import actions from '../actions'
import isResValid from '../../../_shared/isResValid'
import formatFilters from '../../../_shared/formatFilters'
import { addNullValuesToMessagesByPeriod } from '../../../../views/pages/Analytics/utils'
import bot from '../../../../modules/bot'
import api from 'services/tolkApiLegacy'

const fetchMessages = rawFilters => (dispatch, getState) => {
  const { channelId, start, end, period } = formatFilters(rawFilters)
  const botId = bot.selectors.getSelectedId(getState())

  dispatch(actions.fetchMessagesRequest())
  return api.get(`/analytics/${maybe.get(botId)}/${channelId}/messages`,
    { params: {
      start,
      end,
      period
    }}).then(response => {
      if (isResValid(response)) {
        const formatedData = addNullValuesToMessagesByPeriod(response.data.result.data, rawFilters.dateRange, period)
        const formatedResponse = {
          data: formatedData,
          count: response.data.result.count
        }

        dispatch(actions.fetchMessagesSuccess(formatedResponse))
      } else {
        dispatch(actions.fetchMessagesFailure())
      }
    })
    .catch(err => {
      dispatch(actions.fetchMessagesFailure(err))
    })
}

export default fetchMessages
