import moment from 'moment'

const date = moment([2019, 9, 16, 15, 6])

const addTextElementWhenNoElementBeforeTest = {
  initState: {
    campaigns: [
      {
        id: 1,
        updated: true,
        title: 'Campagne 1',
        elements: [],
        cohorts: [],
        date,
        kpis: {
          sent: 0,
          read: 0,
          targeted: 0
        },
        status: 'draft'
      },
      {
        id: 2,
        updated: true,
        title: 'Campagne 2',
        elements: [],
        cohorts: [],
        date,
        kpis: {
          sent: 0,
          read: 0,
          targeted: 0
        },
        status: 'scheduled'
      }
    ],
    currentId: 1
  },
  action: {
    type: 'carousel'
  },
  reducedState: {
    campaigns: [
      {
        id: 1,
        updated: true,
        title: 'Campagne 1',
        elements: [
          // updated
          {
            type: 'carousel',
            content: {
              attachments: [
                {
                  // id: 'an-uid',
                  title: '',
                  text: '',
                  image: {
                    url: '',
                    temporaryUrl: ''
                  },
                  buttons: [
                    {
                      type: 'url',
                      title: '',
                      value: ''
                    }
                  ]
                }
              ]
            }
          }
        ],
        cohorts: [],
        date,
        kpis: {
          sent: 0,
          read: 0,
          targeted: 0
        },
        status: 'draft'
      },
      {
        id: 2,
        updated: true,
        title: 'Campagne 2',
        elements: [],
        cohorts: [],
        date,
        kpis: {
          sent: 0,
          read: 0,
          targeted: 0
        },
        status: 'scheduled'
      }
    ],
    currentId: 1
  }
}

export default addTextElementWhenNoElementBeforeTest
