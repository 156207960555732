import * as React from 'react'
import { remoteData } from '@sbizeul/fp-flow'
import * as R from 'ramda'
import { withFormik } from 'formik'
import * as Yup from 'yup'

import bot from '../../../bot'
import Loading from '../../../../views/pages/Loading'
import FormInput from '../../../../components/FormInput'
import MultiSelect from '../../../../components/MultiSelect'
import Form from '../../../../components/Form'
import FormLabel from '../../../../components/FormLabel'

import './styles.css'

export const UserForm = props => {
  const getOptions = R.pipe(
    remoteData.map(R.map(bot.models.toOption)),
    remoteData.getOrElse(() => [])
  )

  const handleSubmit = () => {
    props.onSubmit(props.values)
  }

  const handleSelectBot = botIds => {
    props.setTouched({ ...props.touched, bot: true })
    props.setFieldValue('bot', botIds)
  }

  const getUsernameError = () => {
    return props.touched.username && props.errors.username ? props.errors.username : null
  }

  const getPasswordError = () => {
    return props.touched.password && props.errors.password ? props.errors.password : null
  }

  const getBotsError = () => {
    return props.touched.bot && props.errors.bot ? props.errors.bot : null
  }

  const renderForm = () => {
    return (
      <Form className='UserForm-form' onSubmit={handleSubmit} onCancel={props.onCancel} isSubmittable={props.isValid}>
        <FormInput
          name='username'
          label='Login'
          onChange={props.handleChange}
          onBlur={() => props.setTouched({ ...props.touched, username: true })}
          value={props.values.username}
          error={getUsernameError()}
          required
        />
        <FormInput
          name='password'
          label='Password'
          type='password'
          onBlur={() => props.setTouched({ ...props.touched, password: true })}
          onChange={props.handleChange}
          value={props.values.password}
          error={getPasswordError()}
          required
        />
        <div>
          <FormLabel label='Bots' />
          <MultiSelect
            placeholder='Bot List'
            values={props.values.bot}
            options={getOptions(props.bot)}
            onChange={handleSelectBot}
            error={getBotsError()}
          />
        </div>
      </Form>
    )
  }

  const match = remoteData.fold({
    Loading: () => <Loading />,
    Success: renderForm,
    Failure: err => <div>{err.message}</div>,
    NotAsked: renderForm
  })

  return <div className='UserForm'>{match(props.user)}</div>
}

const validationSchema = Yup.object().shape({
  username: Yup.string().required(),
  password: Yup.string()
    .min(8)
    .required(),
  bot: Yup.array()
})

const getUsername = R.pipe(
  remoteData.map(user => user.username),
  remoteData.getOrElse(() => '')
)

const getPassword = R.pipe(
  remoteData.map(user => user.password),
  remoteData.getOrElse(() => '')
)

const getBotIds = R.pipe(
  remoteData.map(user => user.bot),
  remoteData.getOrElse(() => [])
)

export default withFormik({
  mapPropsToValues: props => ({
    username: getUsername(props.user),
    password: getPassword(props.user),
    bot: getBotIds(props.user)
  }),
  validationSchema,
  enableReinitialize: true,
  validateOnMount: false,
  handleSubmit: () => {},
  displayName: 'UserForm'
})(UserForm)
