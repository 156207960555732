import type * as React from 'react'
import type * as Axios from 'axios'
import type * as History from 'history'
import * as customerApis from '@tolk-ai/bot-management-react-customer-apis'
import * as customerApiForm from '@tolk-ai/bot-management-react-customer-api-form'
import * as connectorsConfigCustomerApi from '@tolk-ai/connectors-config-customer-api'
import * as browserUtils from '@tolk-genii/browser-utils'

const customerApisComponent = (
  botManagementExpressAxios: Axios.AxiosInstance,
  document: Document,
  authManagementExpressBaseUrl: string,
  cookieDomain: string,
  history: History.History
): React.FC<customerApis.Properties> =>
  customerApis.component(
    history,
    connectorsConfigCustomerApi.customerApiAxios.getManyCustomerApi(
      browserUtils.authenticatedRequest(
        botManagementExpressAxios,
        authManagementExpressBaseUrl,
        browserUtils.removeCookie(document, cookieDomain),
        browserUtils.getCookie(document),
        browserUtils.setCookie(document, cookieDomain)
      )
    ),
  )

  const customerApiFormComponent = (
    botManagementExpressAxios: Axios.AxiosInstance,
    document: Document,
    authManagementExpressBaseUrl: string,
    cookieDomain: string,
    history: History.History
  ): React.FC<customerApiForm.Properties> =>
    customerApiForm.component(
      connectorsConfigCustomerApi.customerApiAxios.createOneCustomerApiInfo(
        browserUtils.authenticatedRequest(
          botManagementExpressAxios,
          authManagementExpressBaseUrl,
          browserUtils.removeCookie(document, cookieDomain),
          browserUtils.getCookie(document),
          browserUtils.setCookie(document, cookieDomain)
        )
      ),
      connectorsConfigCustomerApi.customerApiAxios.updateOneCustomerApiInfo(
        browserUtils.authenticatedRequest(
          botManagementExpressAxios,
          authManagementExpressBaseUrl,
          browserUtils.removeCookie(document, cookieDomain),
          browserUtils.getCookie(document),
          browserUtils.setCookie(document, cookieDomain)
        )
      ),
      connectorsConfigCustomerApi.customerApiAxios.getOneCustomerApiInfo(
        browserUtils.authenticatedRequest(
          botManagementExpressAxios,
          authManagementExpressBaseUrl,
          browserUtils.removeCookie(document, cookieDomain),
          browserUtils.getCookie(document),
          browserUtils.setCookie(document, cookieDomain)
        )
      ),
      connectorsConfigCustomerApi.customerApiAxios.testCustomerApi(
        browserUtils.authenticatedRequest(
          botManagementExpressAxios,
          authManagementExpressBaseUrl,
          browserUtils.removeCookie(document, cookieDomain),
          browserUtils.getCookie(document),
          browserUtils.setCookie(document, cookieDomain)
        )
      ),
      history
    )


  export {
    customerApis,
    customerApisComponent,
    customerApiFormComponent,
  }
