const LOGIN_REQUEST = 'tcfdash/auth/LOGIN_REQUEST'
const LOGIN_SUCCESS = 'tcfdash/auth/LOGIN_SUCCESS'
const LOGIN_FAILURE = 'tcfdash/auth/LOGIN_FAILURE'

const VERIFY_TCF_AUTH_HEADER_REQUEST = 'tcfdash/auth/VERIFY_TCF_AUTH_HEADER_REQUEST'
const VERIFY_TCF_AUTH_HEADER_SUCCESS = 'tcfdash/auth/VERIFY_TCF_AUTH_HEADER_SUCCESS'
const VERIFY_TCF_AUTH_HEADER_FAILURE = 'tcfdash/auth/VERIFY_TCF_AUTH_HEADER_FAILURE'

const SET_CURRENT_USER = 'tcfdash/auth/SET_CURRENT_USER'
const REMOVE_CURRENT_USER = 'tcfdash/auth/REMOVE_CURRENT_USER'

const LOGOUT = 'tcfdash/auth/LOGOUT'

export default {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,

  VERIFY_TCF_AUTH_HEADER_REQUEST,
  VERIFY_TCF_AUTH_HEADER_SUCCESS,
  VERIFY_TCF_AUTH_HEADER_FAILURE,

  SET_CURRENT_USER,
  REMOVE_CURRENT_USER,

  LOGOUT
}
