import styled from 'styled-components'

const BroadcastManagerContainerStyled = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  color: ${({ theme }) => theme.color.defaultColor};
  font-weight: 300;
  font-family: Open Sans, sans-serif;
  * {
    font-family: Open Sans, sans-serif;
    font-size: inherit;
    outline: none;
  }
`
export default BroadcastManagerContainerStyled
