import React from 'react'
import { Checkbox } from 'semantic-ui-react'
import { pipe } from '@sbizeul/fp-flow'
import classNames from 'classnames'

import './styles.css'

const extract = e => ({ [e.name]: e.checked })

const mergeEntity = entity => valueToUpdate => ({ ...entity, ...valueToUpdate })

export default function EntitySettings({ entity, update, className }) {
  const handleChange = pipe(extract, mergeEntity(entity), update)

  return (
    <div className={classNames('EntitySettings', className)}>
      <h1>Settings</h1>
      <Checkbox
        className='EntitySettings-setting'
        checked={entity.open}
        onChange={(_, e) => handleChange(e)}
        name='open'
        label='Open'
        toggle
      />
      <Checkbox
        className='EntitySettings-setting'
        checked={entity.enabled}
        onChange={(_, e) => handleChange(e)}
        name='enabled'
        label='Enabled'
        toggle
      />
    </div>
  )
}
