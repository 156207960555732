import React from 'react'
import { FaRegPaperPlane } from 'react-icons/fa'
import { OutlinedUIStyled, Caption } from './styles'

const ScheduleCampaign = ({ onClick, disabled, value }) => (
  <OutlinedUIStyled direction='row' onClick={onClick} disabled={disabled}>
    <Caption>{value}</Caption>
    <div className='Navbar-menuItem'>
      <FaRegPaperPlane />
    </div>
  </OutlinedUIStyled>
)

export default ScheduleCampaign
