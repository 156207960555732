import React from 'react'
import * as reactRouter from 'react-router-dom'
import type * as Axios from 'axios'

import '@tolk-ai/bot-management-react-customer-apis/dist/bot-management-react-customer-apis.esm.css'



import * as utils from '../../utils'

const component = (
  document: Document,
  cookieDomain: string, 
  botManagementExpressAxios: Axios.AxiosInstance, 
  authManagementExpressBaseUrl: string
): React.FC => () => {
  const history = reactRouter.useHistory()

  const CustomerApis = utils.customerApisComponent(botManagementExpressAxios, document, authManagementExpressBaseUrl, cookieDomain, history)
  const botId = localStorage.getItem('botId') || ''

    return (
      <CustomerApis botId={botId} />
    )
}

export { component }