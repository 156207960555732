import List from './components/List'
import * as actions from './actions'
import * as entity from './reducer.entity'
import * as ui from './reducer.ui'
import * as saga from './saga'
import * as selectors from './selectors'

export default {
  actions,
  components: {
    List
  },
  reducers: {
    entity,
    ui
  },
  saga,
  selectors
}
