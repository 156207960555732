import React from 'react'
import { Switch, Route } from 'react-router-dom'

import './index.css'
import Tabs from './Tabs'
import Trends from './Trends'
import Behaviours from './Behaviours'

const Trend = () => (
  <div>
    <Tabs />
    <Switch>
      <Route exact path='/analytics/trends/' component={Trends} />
      <Route exact path='/analytics/trends/behaviours' component={Behaviours} />
    </Switch>
  </div>
)

export default Trend
