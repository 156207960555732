import { fromJS, List, Map } from 'immutable'
import types from './types'
import initialState from './initialState'

const contentReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.INTENTS_FETCH_REQUEST:
    return state.setIn(['intents', 'isFetching'], true)

  case types.INTENTS_FETCH_FAILURE:
    return state.setIn(['intents', 'isFetching'], false).setIn(['intents', 'data'], fromJS([]))

  case types.INTENTS_FETCH_SUCCESS:
    return state.setIn(['intents', 'isFetching'], false).setIn(['intents', 'data'], fromJS(action.payload))

  case types.INTENT_DATA_FETCH_REQUEST:
    return state.setIn(['intent', 'isFetching'], true)

  case types.INTENT_DATA_FETCH_SUCCESS:
    return state.setIn(['intent', 'data'], fromJS(action.payload)).setIn(['intent', 'isFetching'], false)

  case types.INTENT_DATA_FETCH_FAILURE:
    return state.setIn(['intent', 'data'], Map()).setIn(['intent', 'isFetching'], false)

  case types.GET_DIVERSITY_REQUEST:
    return state.setIn(['intent', 'diversity', 'isFetching'], true)

  case types.GET_DIVERSITY_FAILURE:
    return state
      .setIn(['intent', 'diversity', 'isFetching'], false)
      .setIn(['intent', 'diversity', 'data'], fromJS([]))

  case types.GET_DIVERSITY_SUCCESS:
    return state
      .setIn(['intent', 'diversity', 'isFetching'], false)
      .setIn(['intent', 'diversity', 'data'], fromJS(action.payload))

  case types.POST_INTENT_REQUEST:
    return state

  case types.POST_INTENT_SUCCESS:
    return state

  case types.POST_INTENT_FAILURE:
    return state

  case types.PUT_INTENT_REQUEST:
    return state

  case types.PUT_INTENT_SUCCESS:
    return state

  case types.PUT_INTENT_FAILURE:
    return state

  case types.PUT_INTENT_INFOS_REQUEST:
    return state

  case types.PUT_INTENT_INFOS_SUCCESS:
    return state

  case types.PUT_INTENT_INFOS_FAILURE:
    return state

  case types.POST_INTENT_EXAMPLE_REQUEST:
    return state

  case types.POST_INTENT_EXAMPLE_SUCCESS:
    return state

  case types.POST_INTENT_EXAMPLE_FAILURE:
    return state

  case types.PUT_INTENT_EXAMPLE_REQUEST:
    return state

  case types.PUT_INTENT_EXAMPLE_SUCCESS:
    return state

  case types.PUT_INTENT_EXAMPLE_FAILURE:
    return state

  case types.DELETE_INTENT_EXAMPLE_REQUEST:
    return state

  case types.DELETE_INTENT_EXAMPLE_SUCCESS:
    return state

  case types.DELETE_INTENT_EXAMPLE_FAILURE:
    return state

  case types.DELETE_INTENT_ANSWER_REQUEST:
    return state

  case types.DELETE_INTENT_ANSWER_SUCCESS:
    return state

  case types.DELETE_INTENT_ANSWER_FAILURE:
    return state

  case types.DELETE_INTENT_REQUEST:
    return state

  case types.DELETE_INTENT_SUCCESS:
    return state

  case types.DELETE_INTENT_FAILURE:
    return state

  case types.SELECT_INTENT_LANGUAGE:
    return state.setIn(['intent', 'activeLanguage'], action.payload)

  case types.FETCH_USER_REQUEST:
    return state.setIn(['users', 'data', 'isFetching'], true)

  case types.FETCH_USER_FAILURE:
    return state.setIn(['users', 'isFetching'], false).setIn(['users', 'data'], fromJS([]))

  case types.FETCH_USER_SUCCESS:
    return state.setIn(['users', 'isFetching'], false).setIn(['users', 'data'], fromJS(action.payload))

  case types.POST_USER_REQUEST:
    return state

  case types.POST_USER_SUCCESS:
    return state

  case types.POST_USER_FAILURE:
    return state

  case types.EDIT_USER_REQUEST:
    return state

  case types.EDIT_USER_SUCCESS:
    return state

  case types.EDIT_USER_FAILURE:
    return state

  case types.POST_PASSWORD_REQUEST:
    return state

  case types.POST_PASSWORD_SUCCESS:
    return state

  case types.POST_PASSWORD_FAILURE:
    return state

  case types.CREATE_PASSWORD_REQUEST:
    return state

  case types.CREATE_PASSWORD_SUCCESS:
    return state

  case types.CREATE_PASSWORD_FAILURE:
    return state

  case types.INTENT_TOGGLE_REQUEST:
    return state

  case types.INTENT_TOGGLE_SUCCESS:
    return state

  case types.INTENT_TOGGLE_FAILURE:
    return state

  default:
    return state
  }
}

export default contentReducer

export const getIntents = state => {
  const intentsData = state.getIn(['intents', 'data'], List()).toJS()
  return intentsData.map(elem => ({
    id: elem.id,
    slug: elem.slug,
    name: elem.tcf_name,
    description: elem.description,
    isActive: elem.is_activated,
    editable: elem.tcf_editable || false,
    suggestionsCount: elem.suggestions_count,
    expressionsCount: elem.expressions_count
  }))
}

export const getUsers = state => {
  const usersData = state.getIn(['users', 'data'], List()).toJS()
  return usersData
}

export const isFetchingIntents = state => {
  return state.getIn(['intents', 'isFetching'])
}
