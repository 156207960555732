import * as React from 'react'
import { maybe } from '@sbizeul/fp-flow'
import * as R from 'ramda'
import { MdQuestionAnswer } from 'react-icons/md'

import { useEffectOnce } from 'helpers/react'

import {
  addNewButton,
  answerLens,
  removeButton,
  buttons,
  getTitle,
  oneButton,
  title,
  titleLens,
  truncate,
  newSimpleAnswer
} from '../../models'
import AnswerModal from './AnswerModal'
import DeleteButton from './DeleteButton'
import KeywordDropdown from './KeywordDropdown'
import QuickReply from './QuickReply'
import Title from './Title'

import './styles.css'

function QRAnswerEditor({ qrAnswer, removeContent, updateQRAnswer, fetchAllKeywords, keywords }) {
  const updateOneAnswer = index => optionalAnswer => maybe.map(
    newAnswer => {
      const oldAnswer = R.view(answerLens(index), qrAnswer)
      if (!R.equals(oldAnswer, newAnswer)) {
        updateProperty(answerLens(index), newAnswer)
      }
    }, optionalAnswer)

  const updateProperty = R.curry((lens, property) => updateQRAnswer({ qrAnswer: R.set(lens, property, qrAnswer) }))

  const updateText = updateProperty(titleLens)

  const updateButton = index => updateProperty(oneButton(index))

  const addButton = () => updateProperty(buttons, addNewButton(buttons)(qrAnswer))

  const removeOneButton = id => updateProperty(buttons, removeButton(id)(qrAnswer))

  const renderAnswer = (index) => (simpleAnswer) => (
    <AnswerModal
      text={R.pipe(
        getTitle,
        maybe.map(truncate(10)),
        maybe.getOrElse(() => '+ Create answer')
      )(simpleAnswer)}
      onAnswerUpdated={updateOneAnswer(index)}
      answer={maybe.fromNullable(simpleAnswer)}
    />
  )

  const renderKeyword = (index, button) => (
    <KeywordDropdown
      onSelectKeyword={selectedKeyword => {
        const newButton = maybe.fold(
          () => R.dissoc('keyword', button),
          keyword => R.assoc('keyword', keyword, button),
          selectedKeyword
        )
        updateButton(index)(newButton)
      }}
      selectedKeyword={button.keyword}
      keywords={keywords}
    />
  )

  const renderQuickReplies = R.curry((button, index) => (
    <QuickReply
      answer={button.answer || newSimpleAnswer(qrAnswer.bot_id)}
      key={index}
      onChangeText={(value) =>
        updateButton(index)({
          ...button,
          title: [value],
          payload: value,
        })
      }
      render={renderAnswer(index)}
      renderKeyword={renderKeyword(index, button)}
      title={button.title}
      removeButton={() => removeOneButton(button.id)}
    />
  ))

  useEffectOnce(() => { fetchAllKeywords() })

  return (
    <div className='QRAnswerEditor'>
      <div className='QuickReply'>
        <div className='QuickReply-icons-container'>
          <MdQuestionAnswer size='25' />
          <DeleteButton removeAnswer={() => removeContent({ id: qrAnswer.id })} className='QuickReply-remove' />
        </div>
        <Title onChangeText={updateText} value={title(qrAnswer)} />
        <div className='QuickReply-actions'>
          <div className='QuickReply-buttons'>
            {R.addIndex(R.map)(renderQuickReplies)(R.view(buttons, qrAnswer))}
            <div onClick={addButton} className='QuickReply-actions--add'>
              ＋
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default QRAnswerEditor
