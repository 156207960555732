import types from './types'

/* fetchConversations */
const fetchConversationsRequest = () => ({
  type: types.FETCH_CONVERSATIONS_REQUEST
})
const fetchConversationsSuccess = payload => ({
  type: types.FETCH_CONVERSATIONS_SUCCESS,
  payload
})
const fetchConversationsFailure = errors => ({
  type: types.FETCH_CONVERSATIONS_NO_DATA,
  errors
})

/* fetchConversationsCountByHour */
const fetchConversationsCountByHourRequest = () => ({
  type: types.FETCH_CONVERSATIONS_COUNT_BY_HOUR_REQUEST
})
const fetchConversationsCountByHourSuccess = payload => ({
  type: types.FETCH_CONVERSATIONS_COUNT_BY_HOUR_SUCCESS,
  payload
})
const fetchConversationsCountByHourFailure = errors => ({
  type: types.FETCH_CONVERSATIONS_COUNT_BY_HOUR_NO_DATA,
  errors
})

const fetchConversationsPreviousPeriodRequest = () => ({
  type: types.FETCH_CONVERSATIONS_PREVIOUS_PERIOD_REQUEST
})
const fetchConversationsPreviousPeriodSuccess = payload => ({
  type: types.FETCH_CONVERSATIONS_PREVIOUS_PERIOD_SUCCESS,
  payload
})
const fetchConversationsPreviousPeriodFailure = errors => ({
  type: types.FETCH_CONVERSATIONS_PREVIOUS_PERIOD_NO_DATA,
  errors
})

const fetchConversationsCountByDayRequest = () => ({
  type: types.FETCH_CONVERSATIONS_COUNT_BY_DAY_REQUEST
})
const fetchConversationsCountByDaySuccess = payload => {
  return({
  type: types.FETCH_CONVERSATIONS_COUNT_BY_DAY_SUCCESS,
  payload
})}
const fetchConversationsCountByDayFailure = errors => ({
  type: types.FETCH_CONVERSATIONS_COUNT_BY_DAY_NO_DATA,
  payload: errors
})

export default {
  fetchConversationsRequest,
  fetchConversationsSuccess,
  fetchConversationsFailure,
  fetchConversationsCountByHourRequest,
  fetchConversationsCountByHourSuccess,
  fetchConversationsCountByHourFailure,
  fetchConversationsPreviousPeriodRequest,
  fetchConversationsPreviousPeriodSuccess,
  fetchConversationsPreviousPeriodFailure,
  fetchConversationsCountByDayRequest,
  fetchConversationsCountByDaySuccess,
  fetchConversationsCountByDayFailure
}
