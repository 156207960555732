import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { Menu } from 'semantic-ui-react'

import * as routes from '../../../layouts/Routes'

const TrafficTabs = ({ location: { pathname } }) => (
  <div>
    <Menu pointing secondary position='center' style={{ fontFamily: 'Raleway', marginBottom: '21px' }}>
      <Menu.Item
        name='Messages'
        active={pathname === routes.ANALYTICS_TRAFFIC_MESSAGES}
        as={Link}
        to={{ pathname: routes.ANALYTICS_TRAFFIC_MESSAGES }}
      />
      <Menu.Item
        name='Users'
        active={pathname === routes.ANALYTICS_TRAFFIC_USERS}
        as={Link}
        to={{ pathname: routes.ANALYTICS_TRAFFIC_USERS }}
      />
    </Menu>
  </div>
)

export default withRouter(TrafficTabs)
