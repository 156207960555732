const stripDateDetails = momentDate =>
  momentDate &&
  momentDate.clone().set({
    H: 0,
    m: 0,
    s: 0,
    ms: 0
  })

export default stripDateDetails
