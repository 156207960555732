import { createSelector } from 'reselect'
import { createMatchSelector } from 'connected-react-router'
import { remoteData, pipe, maybe } from '@sbizeul/fp-flow'
import * as R from 'ramda'

import * as map from 'helpers/map'

const getKeywordsState = state => state.entities.keyword

const getAll = createSelector(getKeywordsState, state => remoteData.map(map.toArray, state.all))

const getOne = R.curry((state, id) =>
  pipe(map.get(id), maybe.fold(remoteData.notAsked, R.identity))(state.entities.keyword.byId)
)

const getUiState = state => state.ui.keyword

const isKeywordCreating = createSelector(getUiState, state => state.isKeywordCreating)

const isOpenCreateForm = createSelector(getUiState, state => state.isOpenCreateForm)

const getSelectedKeywordId = createSelector(getUiState, state => state.selectedKeywordId)

const getSelectedKeyword = createSelector([getSelectedKeywordId, getOne], (id, get) =>
  pipe(remoteData.fromMaybe, remoteData.chain(get))(id)
)

const isSynonymAdding = createSelector(getUiState, state => state.isSynonymAdding)

const getIsDeletingSynonym = createSelector(getUiState, state => state.isDeletingSynonym)

const getLinkedKeywordElement = createSelector(getUiState, state => state.linkedKeywordElement)

const matchSelector = createMatchSelector('/keywords')

const matchSelectKeywordRoute = createMatchSelector('/keywords/:id')

export {
  getKeywordsState,
  getAll,
  getOne,
  getLinkedKeywordElement,
  getSelectedKeyword,
  getSelectedKeywordId,
  isKeywordCreating,
  isOpenCreateForm,
  matchSelector,
  matchSelectKeywordRoute,
  isSynonymAdding,
  getIsDeletingSynonym
}
