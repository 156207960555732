import React from 'react'
import { MdDone } from 'react-icons/md'

import * as log from './models'

import './styles.css'

export type Props = Readonly<{
  logCount: number
}>

const LogSelectedLabel: React.FunctionComponent<Props> = ({ logCount }) => (
  <div className='LogSelected'>
    <MdDone className='LogSelected-done_icon' />
    {log.selectedText(logCount)}
  </div>
)

export default LogSelectedLabel
