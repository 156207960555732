import { createSelector } from 'reselect'
import { createMatchSelector } from 'connected-react-router'
import { remoteData, pipe, maybe } from '@sbizeul/fp-flow'
import * as R from 'ramda'

import * as map from '../../helpers/map'

const getEntitiesState = state => state.entities.entity

const getAll = createSelector(getEntitiesState, state => remoteData.map(map.toArray, state.all))

const getOne = R.curry((state, id) =>
  pipe(map.get(id), maybe.fold(remoteData.notAsked, R.identity))(state.entities.entity.byId)
)

const getUiState = state => state.ui.entity

const isEntityCreating = createSelector(getUiState, state => state.isEntityCreating)

const isOpenCreateForm = createSelector(getUiState, state => state.isOpenCreateForm)

const getSelectedEntityId = createSelector(getUiState, state => state.selectedEntityId)

const getSelectedEntity = createSelector([getSelectedEntityId, getOne], (id, get) =>
  pipe(remoteData.fromMaybe, remoteData.chain(get))(id)
)

const isSynonymAdding = createSelector(getUiState, state => state.isSynonymAdding)

const getIsDeletingSynonym = createSelector(getUiState, state => state.isDeletingSynonym)

const matchSelector = createMatchSelector('/entities')

const matchSelectEntityRoute = createMatchSelector('/entities/:id')

export {
  getEntitiesState,
  getAll,
  getOne,
  getSelectedEntity,
  getSelectedEntityId,
  isEntityCreating,
  isOpenCreateForm,
  matchSelector,
  matchSelectEntityRoute,
  isSynonymAdding,
  getIsDeletingSynonym
}
