import React, { useState } from 'react'
import { Button, Form as SemanticForm } from 'semantic-ui-react'

import './styles.css'

type Props = Readonly<{
  label: string
  createSkill: (name: string) => unknown
  onClose?: () => unknown
}>

const CreateSkill: React.FunctionComponent<Props> = ({ createSkill, label, onClose }) => {
  const [name, setName] = useState('')

  const handleChange: (event: React.ChangeEvent<HTMLInputElement>, { value }: {
    value: string
  }) => void = (_, { value }) => setName(value)

  const handleSubmit = () => createSkill(name)

  const fireOnClose = () => onClose && onClose()

  return (
    <div>
      <SemanticForm.Field required>
        <h2 className='create-skill-form--label'>{label}</h2>
        <SemanticForm.Input
          className='create-skill-form--input'
          placeholder='Enter a name...'
          name='name'
          value={name}
          autoComplete='off'
          onChange={handleChange}
        />
      </SemanticForm.Field>
      <div className='create-skill-form--actions'>
        <Button data-testid='cancel' onClick={fireOnClose}>
          Cancel
        </Button>
        <Button
          className='primary'
          data-testid='submit'
          onClick={handleSubmit}
          disabled={name.trim().length === 0}
        >
          Create
        </Button>
      </div>
    </div>
  )
}

export default CreateSkill
