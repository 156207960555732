import types from './types'

const fetchAnswersRequest = () => ({
  type: types.FETCH_ANSWERS_REQUEST
})

const fetchAnswersSuccess = payload => ({
  type: types.FETCH_ANSWERS_SUCCESS,
  payload
})

const fetchAnswersFailure = errors => ({
  type: types.FETCH_ANSWERS_NO_DATA,
  errors
})

export default {
  fetchAnswersRequest,
  fetchAnswersFailure,
  fetchAnswersSuccess
}
