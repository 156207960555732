// TEMPORARY
const mockCampaignsFetch = campaigns =>
  campaigns.map(campaign => ({
    elements: [],
    cohorts: [],
    kpis: {
      sent: 0,
      read: 0
    },
    updated: true,
    ...campaign
  }))

export default mockCampaignsFetch
