 import * as React from 'react'
import * as R from 'ramda'
import axios from 'axios'
import classNames from 'classnames'
import { botManagementBaseUrl, cookieDomain, authManagementBaseUrl } from 'helpers/effects'
import * as browserUtils from '@tolk-genii/browser-utils'
import * as uiLibrary from '@tolk-ai/ui-library-react'
import '@tolk-ai/ui-library-react/dist/ui-library-react.esm.css'

import { inProgress, needsToBePublished, uptodate } from '../../models'

import './style.css'

const disabled = R.pipe(needsToBePublished, R.not)

const label = publishStatus =>
  R.cond([
    [inProgress, R.always('Updating')],
    [needsToBePublished, R.always('Update')],
    [uptodate, R.always('Update')]
  ])(publishStatus)

function PublishButton({ publishStatus, publish }) {
  const [lastTrainingInfos, setLastTraininginfos] = React.useState('')

  const getHoursAndMinutes = (date) => {
    return date.toLocaleString(navigator.language, {hour: "2-digit", minute: "2-digit"})
  }

  const getDate = (date)  => {
    const day = 1000 * 60 * 60 * 24
    if(new Date().toLocaleDateString() === date.toLocaleDateString()) {
      return 'today'
    } else if(new Date(new Date() - day).toLocaleDateString() === date.toLocaleDateString()) {
      return 'yesterday'
    } else {
      return date.toLocaleString(navigator.language, {month: 'numeric', day: 'numeric'})
    }
  }

  const onMouseEnter = async () => {
    const botId = localStorage.getItem('botId')
    try {
      const botManagementAxios = axios.create({
        baseURL: botManagementBaseUrl
      })
      const authenticatedRequest = browserUtils.authenticatedRequest(
        botManagementAxios,
        authManagementBaseUrl,
        browserUtils.removeCookie(document, cookieDomain),
        browserUtils.getCookie(document),
        browserUtils.setCookie(document, cookieDomain))
      const response = await authenticatedRequest('get', `/v1/bots/${botId}/last-training-infos`)
      const date = response.date
      const setDate = getDate(new Date(date))
      const setHoursAndMinutes = getHoursAndMinutes(new Date(date))
      setLastTraininginfos(`Last training ${setDate}, ${setHoursAndMinutes}`)
    } catch (error) {
      setLastTraininginfos('Last training not found')
    }
  }

  const onMouseLeave = async () => {
    setTimeout(() => {
      setLastTraininginfos('')
    }, 300)
  }

  const [icon, setIcon] = React.useState(undefined)

  React.useEffect(() => {
    inProgress(publishStatus) ? setIcon({name: 'spinner', position: 'left-icon'}) : setIcon(undefined)
  }, [publishStatus])

  return (
    <span className={classNames("publish-button-container")} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} role="presentation">
      <uiLibrary.Button isRounded icon={icon} isDisabled={disabled(publishStatus)} onClick={publish}>
        {label(publishStatus)}
      </uiLibrary.Button>
      <span className={"publish-button__training-infos publish-button__training-infos--hover"}>
        {lastTrainingInfos}
      </span>
   </span>
  )
}

export default PublishButton
