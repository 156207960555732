import React from 'react'
import classnames from 'classnames'
import * as R from 'ramda'
import { Link } from 'react-router-dom'
import { MdArrowForward } from 'react-icons/md'
import { Icon } from 'semantic-ui-react'
import { RemoteData, remoteData } from '@sbizeul/fp-flow'

import { renderLoader, renderNothing, renderServerFailure, renderInlineLoader } from 'helpers/react'
import { restoreScroll, withScrollManager } from 'helpers/scroll-manager'
import EmptyStateContent from 'components/EmptyState'
import Button from 'components/Button'
import { Id, ServerFailure } from 'types'

import MonitorTabs from '../MonitorTabs'
import { toFormattedLogsByQuestion } from '../../models'
import { setAsMatched, setAsUnmatched, setQaAsDone, toTreated, toValidate } from './helpers'
import { ConfirmOneParam, MatchedLog, MatchedQuestion, MonitorLogs, Progress } from '../../types'
import './styles.css'
import { WithScrollManagerAndLocationProps } from 'helpers/scroll-manager/types'

type Props = Readonly<{
  logs: RemoteData<ServerFailure, MonitorLogs>
  progress: RemoteData<ServerFailure, Progress>

  dispatchConfirmedQuestion: (confirmOneParam: ConfirmOneParam) => unknown
  fetchLogs: () => unknown
}> & WithScrollManagerAndLocationProps

type MatchedQuestionState = Readonly<{
  wrongLogMatches: Id[]
  treatedQuestions: Id[]
  isRolled: boolean
}>

const renderNoLog = () => (
  <div className='Monitor-Qna-empty-container'>
    <EmptyStateContent
      img='/img/speech-bubble.svg'
      title='No message to show :('
      text='Here you will be able to monitor your assistant’s comprehension. Talk with your assistant or add the script to your website to start.'>
      <Link to='/webchat'>
        <Button className='primary'>
          Go to Channel
          <MdArrowForward className='Monitor-Qna-empty-container--forwardIcon' />
        </Button>
      </Link>
    </EmptyStateContent>
  </div>
)

class MatchedQuestions extends React.PureComponent<Props> {
  componentDidMount () {
    this.props.fetchLogs()
  }

  state: MatchedQuestionState = {
    wrongLogMatches: [],
    treatedQuestions: [],
    isRolled: false
  }

  changeRollerState = () => this.setState({ isRolled: R.not(this.state.isRolled) })

  confirmQuestionLogs = (questionWithLogs: MatchedLog) => {
    const formattedConfirmedLogs = R.map((log: MatchedQuestion) => ({
      log_id: log.id,
      understood: !R.includes(log.id, this.state.wrongLogMatches)
    }))(questionWithLogs.matchedLogs)

    this.props.dispatchConfirmedQuestion({
      questionId: questionWithLogs.id,
      confirmedLogs: formattedConfirmedLogs
    })
    this.setState({ treatedQuestions: setQaAsDone(questionWithLogs)(this.state.treatedQuestions) })
    this.setState({ state: this.state })
    this.props.fetchLogs()
  }

  renderMatchedQuestion = (wrongLogMatchesIds: Id[]) => (matchedQuestion: MatchedQuestion) => {
    const isMatched = R.complement(R.includes(matchedQuestion.id))(wrongLogMatchesIds)
    const wrongLogMatches = R.cond<string[], unknown>([
      [R.always(isMatched), setAsUnmatched(matchedQuestion.id)],
      [R.T, setAsMatched(matchedQuestion.id)]
    ])(wrongLogMatchesIds)

    return (
      <div
        className={classnames('Monitor-MatchedQuestion', { hoverVisible: isMatched })}
        key={matchedQuestion.id}
        onClick={() =>
          this.setState({
            wrongLogMatches: wrongLogMatches
          })
        }
      >
        <div className='Monitor-MatchedQuestion--delete'>╳</div>
        <div className={classnames('Monitor-MatchedQuestion--label', { MatchedQuestionCrossed: !isMatched })}>
          {matchedQuestion.textIn}
        </div>
      </div>
    )
  }

  renderMatchedLog = (questionWithLogs: MatchedLog) => (
    <div className='Monitor-Qna' key={questionWithLogs.id}>
      <div className='Monitor-Qna--title'>{questionWithLogs.associatedQuestion}</div>
      <div className='Monitor-Qna-matchedQuestions'>
        {!this.state.isRolled
          ? R.map(this.renderMatchedQuestion(this.state.wrongLogMatches))(questionWithLogs.matchedLogs)
          : renderNothing()}
      </div>
      {questionWithLogs.matchedLogs.length > 0 && !this.state.isRolled ? (
        <div className='Monitor-Qna-confirm'>
          <button onClick={() => this.confirmQuestionLogs(questionWithLogs)}>VALIDATE</button>
        </div>
      ) : (
        renderNothing()
      )}
    </div>
  )

  renderThreatedLog = (question: MatchedLog) => (
    <div className='Monitor-Qna' key={question.id}>
      <div className='Monitor-Qna--title'>{question.associatedQuestion}</div>
    </div>
  )

  renderLogs = (logs: MatchedLog[], treatedQuestions: MatchedLog[], progress: RemoteData<ServerFailure, Progress>) => () => (
    <div className='Monitor-ListQna'>
      {remoteData.fold({
        Success: (progress: Progress) => <MonitorTabs treatedLogs={progress.treatedLogs} total={progress.total} />,
        Loading: () => renderInlineLoader('fetching progress'),
        NotAsked: () => renderInlineLoader('fetching progress'),
        Failure: renderServerFailure
      })(progress)}
      <div className='Monitor-ToValidate clickable' onClick={this.changeRollerState}>
        To Validate ({logs.length} left)
        {this.state.isRolled ? (
          <Icon name='caret right' style={{ marginLeft: '5px' }} />
        ) : (
          <Icon name='caret down' style={{ marginLeft: '5px' }} />
        )}
      </div>
      {R.map(this.renderMatchedLog)(logs)}
      {R.map(this.renderThreatedLog)(treatedQuestions)}
    </div>
  )

  renderMatchedLogs = (progress: RemoteData<ServerFailure, Progress>) => (monitorLogs: MonitorLogs) => {
    const logsByQuestion = toFormattedLogsByQuestion(monitorLogs)
    const questionsToValidate: MatchedLog[] = toValidate(this.state.treatedQuestions)(logsByQuestion)
    const treatedQuestions = toTreated(this.state.treatedQuestions)(logsByQuestion)
    return R.cond([
      [R.pipe(R.length, R.gt(R.__, 0)), this.renderLogs(questionsToValidate, treatedQuestions, progress)],
      [R.T, renderNoLog]
    ])(monitorLogs)
  }

  render () {
    return (
      <div className='Monitor'>
        {remoteData.fold({
          Success: R.compose(restoreScroll(this.props), this.renderMatchedLogs(this.props.progress)),
          Loading: () => renderLoader('fetching user logs'),
          NotAsked: () => renderLoader('fetching user logs'),
          Failure: renderServerFailure
        })(this.props.logs)}
      </div>
    )
  }
}

export default withScrollManager(MatchedQuestions)
