import React, { useState, useEffect } from 'react'
import * as R from 'ramda'
import { maybe, Maybe } from '@sbizeul/fp-flow'

import CreateContentMenu from 'components/CreateContentMenu'
import { emptyCarouselContent } from 'components/CarouselContentEditor/models'
import { IsoLanguage } from 'helpers/language'
import { renderNothing } from 'helpers/react'

import QRAnswerEditor from '../QRAnswerEditor'
import { addContent, emptyGotoSkillContent, emptyQRAnswer, emptyButtonContent, emptyTagAgentContent, emptyTextContent, emptyImageContent, removeContent, updateContent, newSimpleAnswer } from '../../models.typed'
import { hasNoSkillReference, isSimpleAnswerMaybe, isSimpleAnswer, isEmptyAnswer } from '../../models'
import SimpleAnswerEditor from '../SimpleAnswerEditor'
import {
  Answer,
  AnswerContent,
  DispatchFromProps,
  QRAnswer,
  SimpleAnswer,
  StateFromProps,
  UpdateQRAnswerParam
} from '../../types'

import './styles.css'

export type Props = StateFromProps & DispatchFromProps

const cleanQrAnswer: (answer: Maybe<Answer>) => Maybe<SimpleAnswer> =
  maybe.map((ans: Answer) => newSimpleAnswer(ans.bot_id, ans.id))

const AnswerEditor: React.FunctionComponent<Props> = (props) => {
  const [answer, setAnswer] = useState(props.answer)
  useEffect(() => {
    props.onAnswerUpdated(answer)
  }, [answer])
  useEffect(() => {
    if (!maybe.equals(props.answer, answer)) {
      setAnswer(props.answer)
    }
  }, [props.answer])
  const handleSetState = (modifier: R.Arity1Fn) => R.pipe(
    modifier,
    setAnswer
  )(answer)
  const handleCreateContent = (model: AnswerContent) => handleSetState(addContent(model))

  const renderSimpleContent = (answer: SimpleAnswer) => (
    <SimpleAnswerEditor
      answer={answer}
      fetchQuestions={props.fetchQuestions}
      questions={props.questions}
      removeContent={R.compose(handleSetState, removeContent, R.prop('id'))}
      updateContent={R.compose(handleSetState, updateContent, R.prop('content'))}
      uploadImage={props.uploadImage}
      imagesUrl={props.imagesUrl}
      skills={props.skills}
      fetchAllSkills={props.fetchAllSkills}
      tags={props.tags}
      fetchAllTags={props.fetchAllTags}
      goToSkills={props.goToSkills}
    >
      {props.children}
    </SimpleAnswerEditor>
  )

  const renderQRContent = (qrAnswer: QRAnswer) =>
    <QRAnswerEditor
      qrAnswer={qrAnswer}
      updateQRAnswer={(qrAnswerParam: UpdateQRAnswerParam) => handleSetState(() => maybe.of(qrAnswerParam.qrAnswer))}
      removeContent={() => setAnswer(cleanQrAnswer(answer))}
      {...props}
    />

  const renderContent = (answer: Maybe<Answer>) => maybe.fold(
    renderNothing,
    ans => (isSimpleAnswer(ans) ? renderSimpleContent(ans as SimpleAnswer) : renderQRContent(ans as QRAnswer)),
    answer
  )

  return (
    <div className='AnswerEditor'>
      <div className='AnswerEditor-content'>{renderContent(answer)}</div>
      {props.children}
      <CreateContentMenu
        createButtonContent={(params: IsoLanguage) => handleCreateContent(emptyButtonContent(params))}
        createCarouselContent={(params: IsoLanguage) => handleCreateContent(emptyCarouselContent(params))}
        createTextContent={(params: IsoLanguage) => handleCreateContent(emptyTextContent(params))}
        createImageContent={(params: IsoLanguage) => handleCreateContent(emptyImageContent(params))}
        createSkillContent={() => handleCreateContent(emptyGotoSkillContent())}
        createQRContent={props.canCreateQR ? (params: IsoLanguage) => setAnswer(maybe.of(emptyQRAnswer(params))) : null}
        createTagAgentContent={() => handleCreateContent(emptyTagAgentContent())}
        canCreateSimple={R.allPass([isSimpleAnswerMaybe, hasNoSkillReference])(answer)}
        canCreateQR={isEmptyAnswer(answer)}
        languages={props.languages}
      />
    </div>
  )
}

export default AnswerEditor
