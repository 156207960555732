import moment from 'moment'

const date = moment([2019, 9, 16, 15, 6])

const sendCampaignTest = {
  initState: {
    campaigns: [
      {
        id: 1,
        updated: true,
        title: 'Campagne 1',
        elements: [
          {
            type: 'text',
            content: 'blablabla'
          }
        ],
        cohorts: [],
        date,
        kpis: {
          sent: 0,
          read: 0
        },
        status: 'draft'
      }
    ],
    currentId: 1
  },
  reducedState: {
    campaigns: [
      {
        id: 1,
        updated: true,
        title: 'Campagne 1',
        elements: [
          {
            type: 'text',
            content: 'blablabla'
          }
        ],
        cohorts: [],
        date,
        kpis: {
          sent: 0,
          read: 0
        },
        status: 'sent' // updated
      }
    ],
    currentId: 1
  }
}

export default sendCampaignTest
