import Color from 'color'
import { Predicate } from 'fp-ts/lib/function'
import * as R from 'ramda'

import { Map } from 'helpers/map'
import * as routes from './routes'
import { Bot, Colours, Route, Webchat, WebchatTheme, RestBot } from './types'

export const toMap: (arr: ReadonlyArray<Bot>) => Map<Bot> = R.reduce((acc, it) => {
  acc[it.bot_id] = it
  return acc
}, {} as Map<Bot>)

export const botToMap = (arr: readonly RestBot[]): Map<Bot> => {
  return arr.reduce(
    (accumulator: Map<Bot>, restBot: RestBot) => {
      const bot: Bot = {
        bot_id: restBot.id,
        createdAt: '',
        description: '',
        events: [],
        features: [],
        isActivate: true,
        archived: false,
        language: ['fr_FR'],
        name: restBot.name,
        options: {
          accessToken: 'string',
          botSlug: 'string',
          developerToken: 'string',
          nlpEngine: 'heimdall',
          userSlug: 'string'
        },
        password: '',
        treeName: 'string',
        updatedAt: 'string',
        channels: [],
        webchatUrl: restBot.webchatUrl,
        _id: { $oid: restBot.id }
      }

      return {
        ...accumulator,
        [restBot.id]: bot
      }
    }, {} as Map<Bot>)
}

export const convertRestBotToBot = (restBot: RestBot): Map<Bot> => {
  const bot: Bot = {
    bot_id: restBot.id,
    createdAt: '',
    description: '',
    events: [],
    features: [],
    isActivate: true,
    archived: false,
    language: ['fr_FR'],
    name: restBot.name,
    options: {
      accessToken: 'string',
      botSlug: 'string',
      developerToken: 'string',
      nlpEngine: 'heimdall',
      userSlug: 'string'
    },
    password: '',
    treeName: 'string',
    updatedAt: 'string',
    channels: [],
    webchatUrl: restBot.webchatUrl,
    _id: { $oid: restBot.id }
  }

  return {
    [restBot.id]: bot
  } 
}

const titleIsValid: Predicate<Webchat> = webchat =>
  webchat.settings.title !== ''

const titleIsNotTooLong: Predicate<Webchat> = webchat =>
  webchat.settings.title.length <= 40

const isValid: Predicate<Webchat> = R.allPass([titleIsValid, titleIsNotTooLong])

const isNotOnBotCreation: Predicate<Route> = route => route !== routes.BOT_CREATION

const isWebchatShowable: Predicate<Route> = R.allPass([isNotOnBotCreation])

const coloursToTheme: (colours: Colours) => WebchatTheme = ({ thirdColor= '#EBEAEF', ...colours }) => {
  const firstColour = Color(colours.firstColor)
  const textColour = firstColour.isLight() ? '#000' : '#FFF'
  const secondColour =
    colours.secondColor ||
    (firstColour.isLight()
      ? firstColour.darken(0.5).hex()
      : firstColour.lighten(0.5).hex())
  return {
    firstColour: colours.firstColor,
    secondColour,
    thirdColour: thirdColor,
    textColour
  }
}

export { coloursToTheme, isValid, isWebchatShowable, titleIsValid }
