import { maybe } from '@sbizeul/fp-flow'

import actions from './actions'
import isResValid from '../../_shared/isResValid'
import formatFilters from '../../_shared/formatFilters'
import { addNullValuesByPeriod } from '../../../views/pages/Analytics/utils'
import bot from '../../../modules/bot'
import api from 'services/tolkApiLegacy'

const fetchPayloads = rawFilters => (dispatch, getState) => {
  const { channelId, start, end, period } = formatFilters(rawFilters)
  const botId = bot.selectors.getSelectedId(getState())

  dispatch(actions.fetchPayloadsRequest())

  return api.get(
    `/analytics/${maybe.get(botId)}/${channelId}/payloads`,
    {
      params: {
        start,
        end,
        period
      }
    }
  ).then(response => {
      if (isResValid(response)) {
        const formatedData = response.data.result.data.map(elem => ({
          name: elem.payload.toString(),
          value: elem.total
        }))
        const formatedResponse = {
          data: formatedData,
          count: response.data.result.count
        }
        dispatch(actions.fetchPayloadsSuccess(formatedResponse))
      } else {
        dispatch(actions.fetchPayloadsFailure())
      }
    })
    .catch(err => {
      dispatch(actions.fetchPayloadsFailure(err))
    })
}

const fetchPayloadsOverTime = rawFilters => (dispatch, getState) => {
  const { channelId, start, end, period } = formatFilters(rawFilters)
  const botId = bot.selectors.getSelectedId(getState())

  dispatch(actions.fetchPayloadsOverTimeRequest())
  return api.get(
    `/analytics/${maybe.get(botId)}/${channelId}/payloads/all`,
    {
      params: {
        start,
        end,
        period
      }
    }
  ).then(response => {
      if (isResValid(response)) {
        const formatedData = addNullValuesByPeriod(response.data.result.data, rawFilters.dateRange, period)
        dispatch(actions.fetchPayloadsOverTimeSuccess(formatedData))
      } else {
        dispatch(actions.fetchPayloadsOverTimeFailure())
      }
    })
    .catch(err => {
      dispatch(actions.fetchPayloadsOverTimeFailure(err))
    })
}

export default { fetchPayloads, fetchPayloadsOverTime }
