import * as R from 'ramda'

const isMatchedQuestion = R.complement(R.includes)

const setAsMatched = matchedQuestionId => R.without([matchedQuestionId])

const setAsUnmatched = R.append

const setQaAsDone = log => R.append(log.id)

const toTreated = treatedQuestions => R.filter(R.pipe(R.prop('id'), id => R.includes(id, treatedQuestions)))

const toValidate = treatedQuestions =>
  R.filter(
    R.allPass([
      R.pipe(R.prop('id'), id => R.includes(id, treatedQuestions), R.not),
      R.pipe(R.prop('matchedLogs'), R.length, R.gt(R.__, 0))
    ])
  )

export { isMatchedQuestion, setQaAsDone, setAsMatched, setAsUnmatched, toTreated, toValidate }
