import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { maybe, remoteData } from '@sbizeul/fp-flow'
import { routerMiddleware } from 'connected-react-router'

import history from './history'
import rootReducer from '.'
import saga from './rootSaga'
import bot from '../modules/bot'
import auth from '../modules/auth'
import { notificationsMiddleware } from '../redux/notifications/notificationsMiddleware'
import qa from 'modules/qa'

const preloadState = () => {
  const token = localStorage.getItem('tcfAuthHeader')
  const qaCollapsibleSectionsOpeningStatus = localStorage.getItem('qaCollapsibleSectionsOpeningStatus')
    ? JSON.parse(localStorage.getItem('qaCollapsibleSectionsOpeningStatus'))
    : []
  return {
    ui: {
      bot: {
        ...bot.reducers.ui.initialState,
        selectedId: maybe.fromNullable(localStorage.getItem('botId'))
      },
      qa: {
        ...qa.reducers.ui.initialState,
        collapsibleSectionsOpeningStatus: qaCollapsibleSectionsOpeningStatus
      }
    },
    auth: {
      ...auth.reducer.initialState,
      token: token ? remoteData.success(token) : remoteData.notAsked()
    }
  }
}

const sagaMiddleware = createSagaMiddleware()

const configureStore = () => {
  const middlewares = [thunk, sagaMiddleware, routerMiddleware(history), notificationsMiddleware]

  const store = createStore(rootReducer(history), preloadState(), composeWithDevTools(applyMiddleware(...middlewares)))

  sagaMiddleware.run(saga)

  return store
}

export default configureStore
