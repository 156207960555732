import types from './types'

const initialState = {
  fetching: false
}

const entitiesReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.FETCH_ENTITIES_REQUEST:
    return {
      ...state,
      fetching: true,
      data: null,
      count: null
    }

  case types.FETCH_ENTITIES_NO_DATA:
    return {
      ...state,
      fetching: false
    }

  case types.FETCH_ENTITIES_SUCCESS:
    return {
      ...state,
      fetching: false,
      data: action.payload.data,
      count: action.payload.count
    }

  default:
    return state
  }
}

export default entitiesReducer
