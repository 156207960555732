import React from 'react'
import { remoteData, maybe, pipe } from '@sbizeul/fp-flow'
import * as R from 'ramda'

import Entity from './Entity'
import { Loader } from 'components/Loading'
import { renderFailure } from 'helpers/react'

import './styles.css'

class RemoteEntity extends React.PureComponent {
  renderEntity = ([selectedEntity, synonyms]) => (
    <Entity
      entity={selectedEntity}
      synonyms={synonyms}
      languages={this.props.languages}
      isSynonymAdding={this.props.isSynonymAdding}
      isDeletingSynonym={this.props.isDeletingSynonym}
      addSynonym={R.partial(this.props.addSynonym, [selectedEntity.id])}
      onDeleteSynonym={R.partial(this.props.onDeleteSynonym, [selectedEntity.id])}
      updateSynonym={R.partial(this.props.updateSynonym, [selectedEntity.id])}
      update={this.props.updateEntity}
    />
  )

  componentDidMount() {
    maybe.map(this.props.fetchEntity, this.props.selectedEntityId)
  }

  render() {
    return pipe(
      remoteData.map2(toTuple, this.props.entity),
      remoteData.fold({
        Success: this.renderEntity,
        NotAsked: renderLoading,
        Loading: renderLoading,
        Failure: renderFailure
      })
    )(this.props.synonyms)
  }
}

function toTuple(one, two) {
  return [one, two]
}

function renderLoading() {
  return <Loader description='Fetching entity ' />
}

export default RemoteEntity
