import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose } from '@sbizeul/fp-flow'

import bot from 'modules/bot'
import user from 'modules/user'
import Navbar from '../components/Navbar'

const mapStateToProps = state => ({
  botId: bot.selectors.getSelectedId(state),
  features: bot.selectors.getFeatures(state),
  nlpEngine: bot.selectors.getNlpEngine(state),
  isAdmin: user.selectors.getIsAdmin(state),
  userID: user.selectors.getId(state)
})

export default compose(withRouter, connect(mapStateToProps))(Navbar)
