import React, { useState } from 'react'
import { Checkbox } from 'semantic-ui-react'
import { maybe, remoteData } from '@sbizeul/fp-flow'
import * as R from 'ramda'

import answerModule from 'modules/answer'
import questionModule from 'modules/question'
import QnAHeader from 'components/Header'

import { didMount, renderFailure, renderLoader } from 'helpers/react'

import './styles.css'

const QaAnswerPage = ({ route, qna, answer, saveQnAn }) => {
  const [qnaState, setQnaState] = useState(qna)
  const [answerState, setAnswerState] = useState(answer)

  const [canBeSaved, setCanBeSaved] = useState(false)

  const onAnswerUpdated = updatedAnswer => {
    setAnswerState(updatedAnswer)
    setCanBeSaved(maybe.fold(() => false, answerModule.models.isValidAnswer, updatedAnswer))
  }

  const save = () => saveQnAn({ qna: qnaState, answer: answerState })

  const changeFeedbackStatus = askFeedback => R.pipe(
    questionModule.models.changeFeedbackStatus(askFeedback),
    setQnaState
  )(qnaState)

  const renderFeedbackCheckbox = () => (
    <Checkbox
      toggle
      className='SimpleAnswerEditor-checkbox'
      type='checkbox'
      label='Ask satisfaction'
      checked={qnaState.ask_feedback}
      onClick={(_, data) => changeFeedbackStatus(data.checked || false)}
    />
  )

  return (
    <div className='QaAnswerPage'>
      <QnAHeader route={route} label={qna.label} canItBeSaved={canBeSaved} save={save} />
      <answerModule.components.AnswerEditorContainer
        answer={answerState}
        onAnswerUpdated={onAnswerUpdated}
        canCreateQR
      >
        {renderFeedbackCheckbox()}
      </answerModule.components.AnswerEditorContainer>
    </div>
  )
}

const RemoteQaAnswerPage = ({ route, qna, answer, saveQnAn }) => {
  const render = qna => (
    <div className='QaAnswerPage'>
      <QaAnswerPage route={route} qna={qna} answer={answer} saveQnAn={saveQnAn} />
    </div>
  )

  return remoteData.fold({
    Success: render,
    NotAsked: () => renderLoader('Please wait...'),
    Loading: () => renderLoader('Please wait...'),
    Failure: renderFailure,
  })(qna)
}

const withDidMount = didMount(props => maybe.map(questionId => props.fetchQnA(questionId))(props.questionId))

export default withDidMount(RemoteQaAnswerPage)

export { QaAnswerPage }
