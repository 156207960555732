import React from 'react'
import ReactHighcharts from 'react-highcharts'
import moment from 'moment'
import defaultStyle from '../chartStyle'

const config = {
  ...defaultStyle,
  chart: {
    type: 'spline'
  },
  xAxis: {
    type: 'datetime'
  },
  yAxis: {
    title: null
  }
}

export default function MessagesSentSpline({ begin, end }) {
  const series = []
  if (begin) {
    series.push({
      stacking: 'normal',
      animation: false,
      name: 'Started',
      data: begin.map(elem => [moment.utc(elem.date).valueOf(), elem.total])
    })
  }
  if (end) {
    series.push({
      stacking: 'normal',
      animation: false,
      name: 'Completed',
      data: end.map(elem => [moment.utc(elem.date).valueOf(), elem.total])
    })
  }
  return <ReactHighcharts config={{ ...config, series }} domProps={{ id: 'IntentsPayloadsAndMessagesHighcharts' }} />
}
