import React, { Component } from 'react'
import { Field } from 'redux-form'
import { Button, Divider } from 'semantic-ui-react'
import _ from 'lodash'
import InputField from '../../../../components/InputField'
import SelectField from '../../../../components/SelectField'

const required = value => (!_.trim(value) ? 'Required' : undefined)

export default class addSeriesLineChart extends Component {
  componentWillMount() {
    const { fields } = this.props
    fields.removeAll()
    fields.push({})
  }

  render() {
    const { fields, eventConfig } = this.props
    return (
      <div>
        {fields.map((serie, ind) => (
          <div key={ind}>
            <Field
              type='text'
              name={`${serie}.name`}
              label='Name'
              validate={required}
              required
              component={InputField}
            />
            <Field
              type='select'
              name={`${serie}.event_slug`}
              label='Event Slug'
              validate={required}
              required
              options={eventConfig.map((event, ind) => ({
                key: ind,
                text: event.event_slug,
                value: event.event_slug
              }))}
              component={SelectField}
            />
            {fields.length > 1 && <Divider />}
          </div>
        ))}
        <br />
        <Button type='button' onClick={() => fields.push({})}>
          Add Serie
        </Button>
      </div>
    )
  }
}
