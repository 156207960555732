import * as R from 'ramda'
import { maybe } from '@sbizeul/fp-flow'

export type Map<A> = { [key: string]: A }

export function toArray<T> (map: Map<T>) {
  return Object.keys(map).map(x => map[x])
}

export const get = R.curry((key, map) => {
  return maybe.fromNullable(map[key])
})

export const set = R.curry((key, value, map) => {
  return {
    ...map,
    [key]: value
  }
})

export const remove = R.curry((key, map) => {
  return R.omit([key], map)
})
