const FETCH_ANALYTICS_REQUEST = 'tcfdash/analytics/FETCH_REQUEST'
const FETCH_ANALYTICS_SUCCESS = 'tcfdash/analytics/FETCH_SUCCESS'
const FETCH_ANALYTICS_FAILURE = 'tcfdash/analytics/FETCH_FAILURE'

const FETCH_ANALYTICS_TRAFFIC_MESSAGES_REQUEST = 'tcfdash/analytics/traffic/messages/FETCH_REQUEST'
const FETCH_ANALYTICS_TRAFFIC_MESSAGES_SUCCESS = 'tcfdash/analytics/traffic/messages/FETCH_SUCCESS'
const FETCH_ANALYTICS_TRAFFIC_MESSAGES_FAILURE = 'tcfdash/analytics/traffic/messages/FETCH_FAILURE'

const FETCH_ANALYTICS_TRAFFIC_USERS_REQUEST = 'tcfdash/analytics/traffic/users/FETCH_REQUEST'
const FETCH_ANALYTICS_TRAFFIC_USERS_SUCCESS = 'tcfdash/analytics/traffic/users/FETCH_SUCCESS'
const FETCH_ANALYTICS_TRAFFIC_USERS_FAILURE = 'tcfdash/analytics/traffic/users/FETCH_FAILURE'

const FETCH_TRENDS_REQUEST = 'tcfdash/analytics/trends/FETCH_REQUEST'
const FETCH_TRENDS_SUCCESS = 'tcfdash/analytics/trends/FETCH_SUCCESS'
const FETCH_TRENDS_FAILURE = 'tcfdash/analytics/trends/FETCH_FAILURE'

const FETCH_PERFORMANCE_DELAYS_REQUEST = 'tcfdash/analytics/performance/delays/FETCH_REQUEST'
const FETCH_PERFORMANCE_DELAYS_SUCCESS = 'tcfdash/analytics/performance/delays/FETCH_SUCCESS'
const FETCH_PERFORMANCE_DELAYS_FAILURE = 'tcfdash/analytics/performance/delays/FETCH_FAILURE'

const FETCH_BEHAVIOURS_REQUEST = 'tcfdash/analytics/behaviours/FETCH_REQUEST'
const FETCH_BEHAVIOURS_SUCCESS = 'tcfdash/analytics/behaviours/FETCH_SUCCESS'
const FETCH_BEHAVIOURS_FAILURE = 'tcfdash/analytics/behaviours/FETCH_FAILURE'

const FETCH_PERFORMANCE_RETENTION_REQUEST = 'tcfdash/analytics/performance/retention/FETCH_REQUEST'
const FETCH_PERFORMANCE_RETENTION_SUCCESS = 'tcfdash/analytics/performance/retention/FETCH_SUCCESS'
const FETCH_PERFORMANCE_RETENTION_FAILURE = 'tcfdash/analytics/performance/retention/FETCH_FAILURE'

const FETCH_PERFORMANCE_UNDERSTANDING_REQUEST = 'tcfdash/analytics/understanding/FETCH_REQUEST'
const FETCH_PERFORMANCE_UNDERSTANDING_SUCCESS = 'tcfdash/analytics/understanding/FETCH_SUCCESS'
const FETCH_PERFORMANCE_UNDERSTANDING_FAILURE = 'tcfdash/analytics/understanding/FETCH_FAILURE'

export default {
  FETCH_TRENDS_REQUEST,
  FETCH_TRENDS_SUCCESS,
  FETCH_TRENDS_FAILURE,

  FETCH_PERFORMANCE_DELAYS_REQUEST,
  FETCH_PERFORMANCE_DELAYS_SUCCESS,
  FETCH_PERFORMANCE_DELAYS_FAILURE,

  FETCH_PERFORMANCE_RETENTION_REQUEST,
  FETCH_PERFORMANCE_RETENTION_SUCCESS,
  FETCH_PERFORMANCE_RETENTION_FAILURE,

  FETCH_PERFORMANCE_UNDERSTANDING_REQUEST,
  FETCH_PERFORMANCE_UNDERSTANDING_SUCCESS,
  FETCH_PERFORMANCE_UNDERSTANDING_FAILURE,

  FETCH_BEHAVIOURS_REQUEST,
  FETCH_BEHAVIOURS_SUCCESS,
  FETCH_BEHAVIOURS_FAILURE,

  FETCH_ANALYTICS_REQUEST,
  FETCH_ANALYTICS_SUCCESS,
  FETCH_ANALYTICS_FAILURE,

  FETCH_ANALYTICS_TRAFFIC_MESSAGES_REQUEST,
  FETCH_ANALYTICS_TRAFFIC_MESSAGES_SUCCESS,
  FETCH_ANALYTICS_TRAFFIC_MESSAGES_FAILURE,

  FETCH_ANALYTICS_TRAFFIC_USERS_REQUEST,
  FETCH_ANALYTICS_TRAFFIC_USERS_SUCCESS,
  FETCH_ANALYTICS_TRAFFIC_USERS_FAILURE
}
