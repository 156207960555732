import React from 'react'
import * as array from 'fp-ts/lib/Array'
import * as option from 'fp-ts/lib/Option'
import * as R from 'ramda'
import { RemoteData, remoteData } from '@sbizeul/fp-flow'
import { MdArrowForward, MdClose, MdFormatListBulleted } from 'react-icons/md'

import Select from 'components/Select'
import LinkAction from 'components/LinkAction'

import './styles.css'
import { FormContent, GotoSkillContent, GotoSmartSkillContent, SimplifiedSkill, BaseSkillContent, RgpdContent, ActionContent, SimpleAnswer } from 'modules/answer/types'
import { Id, ServerFailure } from 'types'
import { renderFailure, renderInlineLoader } from 'helpers/react'
import { BASE_SKILL_LABELS, BaseSkillTypes } from 'modules/skill/types'

type UpdateContentParam = Readonly<{
  content: FormContent | GotoSkillContent | GotoSmartSkillContent | BaseSkillContent | RgpdContent | ActionContent
}>
type RemoveContentParam = Readonly<{
  id: Id
}>

type Props = Readonly<{
  content: FormContent | GotoSkillContent | GotoSmartSkillContent | BaseSkillContent | RgpdContent | ActionContent
  skills: RemoteData<ServerFailure, SimplifiedSkill[]>
  pathToId: R.Lens
  fetchAll: () => unknown
  removeContent: (params: RemoveContentParam) => unknown
  goToSkills: () => unknown
  updateContent: (params: UpdateContentParam) => unknown
  answer: SimpleAnswer
}>

const getId: (content: RgpdContent | FormContent | GotoSkillContent | GotoSmartSkillContent | BaseSkillContent | ActionContent) => Id = content => {
  switch (content.type) {
    case 'form':
      return content.objectAttachment.form_id
    case 'skill':
      return content.objectAttachment.skill_id
    case 'smartskill':
      return content.objectAttachment.smartskill_id
    case 'answer':
      return content.objectAttachment.answer_id
    case 'rgpd':
      return 'rgpd'
    case 'app':
      if (content.objectAttachment.name === 'agent') return 'app'
      return content.objectAttachment.app_id || 'app'
  }
}

const toFormOrGotoOrAnswerSkillContent = (id: Id) => (simplifiedSkill: SimplifiedSkill): FormContent | GotoSkillContent | GotoSmartSkillContent | BaseSkillContent | RgpdContent | ActionContent => {
  switch (simplifiedSkill.type) {
    case 'form':
      return ({
        id: id,
        type: 'form',
        objectAttachment: {
          form_id: simplifiedSkill.id
        }
      })
    case 'advanced':
      return ({
        id: id,
        type: 'skill',
        objectAttachment: {
          skill_id: simplifiedSkill.id
        }
      })
    case 'smartskill':
      return ({
        id: id,
        type: 'smartskill',
        objectAttachment: {
          smartskill_id: simplifiedSkill.id
        }
      })
    case 'base':
      return ({
        id: id,
        type: 'answer',
        objectAttachment: {
          answer_id: simplifiedSkill.id
        }
      })
    case 'rgpd':
      return ({
        id: id,
        type: 'rgpd'
      })
    case 'app':
      if (simplifiedSkill.id === "app" || simplifiedSkill.name === "Agent connect") {
        return ({
          id: id,
          type: 'app',
          objectAttachment: {
            name: 'agent',
            scenario: 'handover',
            app_id: 'app'
          }
        })
      } else {
        return ({
          id: id,
          type: 'app',
          objectAttachment: {
            type: 'packaged',
            name: simplifiedSkill.name,
            scenario: simplifiedSkill.name,
            app_id: simplifiedSkill.id
          }
        })
      }
  }
}

const findAndTransformSimplifiedSkill = (id: Id, data: { text: string, value: string }) => (skills: SimplifiedSkill[]): FormContent | GotoSmartSkillContent | GotoSkillContent | BaseSkillContent | RgpdContent | ActionContent => {
  const skill: SimplifiedSkill | undefined = skills.filter(skill => skill.id === data.value)[0]
  if (skill) {
    return toFormOrGotoOrAnswerSkillContent(id)(skill)
  } else {
    return {
      id: 'lol',
      type: 'form',
      objectAttachment: {
        form_id: 'lollol'
      }
    }
  }
}

class SkillContentEditor extends React.Component<Props> {
  componentDidMount () {
    this.props.fetchAll()
  }

  handleClickDelete = () =>
    this.props.removeContent({
      id: R.view(this.props.pathToId, this.props.content)
    })

  render () {
    const { content, skills, answer } = this.props
    return remoteData.fold({
      Failure: renderFailure,
      Loading: renderInlineLoader,
      NotAsked: renderInlineLoader,
      Success: (skills: SimplifiedSkill[]) => {
        const skillsWithoutCurrentSkill: SimplifiedSkill[] | undefined = skills.filter(skill => skill.id !== answer.id)
        const id = content.id
        const allSkills = R.pipe(
          R.map((skill: SimplifiedSkill) => ({
            text: BASE_SKILL_LABELS[skill.name as BaseSkillTypes] || skill.name,
            value: skill.id
          })),
          R.sortBy(R.pipe(R.prop('text'), R.toLower))
        )(skillsWithoutCurrentSkill)
        return (
          <div className='SkillContentEditor'>
            <div className='SkillContentEditor-top_bar'>
              <MdFormatListBulleted className='SkillContentEditor-top_bar--content_icon' />
              <MdClose
                data-testid='remove-content'
                className='SkillContentEditor-top_bar--remove_icon'
                onClick={this.handleClickDelete}
              />
            </div>
            <div className='SkillContentEditor-content'>
              <p className='SkillContentEditor-content--text'>
                At this step, the user will be redirected to a skill. <br />
                Settings are available into the skill panel.
          </p>
              <div className='SkillContentEditor-content--bottom'>
                <Select
                  className='SkillContentEditor-content--bottom---select'
                  name='Forms'
                  options={allSkills}
                  value={
                    option.getOrElse(
                      () => ({ id: 'lol' })
                    )(array.findFirst<SimplifiedSkill>(skill => skill.id === getId(content))(skillsWithoutCurrentSkill)).id}
                  onChange={
                    (data: { text: string, value: string, answer: any }) => {
                    this.props.updateContent({
                      content: findAndTransformSimplifiedSkill(id, data)(skillsWithoutCurrentSkill)
                    })
                  }}
                />
                <LinkAction className='SkillContentEditor-content--bottom---link' to={this.props.goToSkills}>
                  <span className='SkillContentEditor-content--bottom---link_text'>GO TO SKILL PANEL</span>
                  <MdArrowForward className='SkillContentEditor-content--bottom---link_icon' />
                </LinkAction>
              </div>
            </div>
          </div>
        )
      }
    })(skills)
  }
}

export default SkillContentEditor
