import { connect } from 'react-redux'

import Answers from '../components/Answers'
import * as answerActions from '../actions'
import * as answerSelectors from '../selectors'
import intent from '../../intent'

const mapStateToProps = (state, props) => ({
  answers: answerSelectors.getByIntentSlug(state, props.match.params.intent),
  intentSlug: props.match.params.intent,
  history: props.history,
  location: props.location
})

const mapDispatchToProps = {
  saveAnswers: answerActions.saveAnswers,
  fetchIntent: intent.actions.fetchOne,
  removeAnswer: answerActions.removeAnswer,
  updateAnswer: answerActions.updateAnswer,
  resetState: answerActions.resetState
}

export default connect(mapStateToProps, mapDispatchToProps)(Answers)
