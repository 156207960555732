import React from 'react'
import ReactHighcharts from 'react-highcharts'
import moment from 'moment'
import isEmpty from 'lodash/isEmpty'
import NoDataSegment from '../NoDataSegment'
import defaultStyle from '../chartStyle'

const config = {
  ...defaultStyle,
  chart: {
    type: 'spline'
  },
  xAxis: {
    type: 'datetime'
  },
  yAxis: {
    title: null
  }
}

// const loadingStyle = {
//   top: '110px',
//   height: '280px',
// }

export default function MessagesSentSpline({ messagesTotal, period }) {
  if (isEmpty(messagesTotal)) {
    return <NoDataSegment />
  }
  const series = []
  series.push({
    stacking: 'normal',
    animation: false,
    name: 'Messages Sent',
    data: messagesTotal.map((elem, i) => [moment.utc(elem.time.split(' ')[0]).valueOf(), messagesTotal[i].user])
  })
  return <ReactHighcharts config={{ ...config, series }} domProps={{ id: 'IntentsPayloadsAndMessagesHighcharts' }} />
}
