import types from './types'

const fetchPayloadsRequest = () => ({
  type: types.FETCH_PAYLOADS_REQUEST
})

const fetchPayloadsSuccess = payload => ({
  type: types.FETCH_PAYLOADS_SUCCESS,
  payload
})

const fetchPayloadsFailure = errors => ({
  type: types.FETCH_PAYLOADS_NO_DATA,
  errors
})

const fetchPayloadsOverTimeRequest = () => ({
  type: types.FETCH_PAYLOADS_OVER_TIME_REQUEST
})

const fetchPayloadsOverTimeSuccess = payload => ({
  type: types.FETCH_PAYLOADS_OVER_TIME_SUCCESS,
  payload
})

const fetchPayloadsOverTimeFailure = errors => ({
  type: types.FETCH_PAYLOADS_OVER_TIME_NO_DATA,
  errors
})

export default {
  fetchPayloadsRequest,
  fetchPayloadsSuccess,
  fetchPayloadsFailure,
  fetchPayloadsOverTimeRequest,
  fetchPayloadsOverTimeSuccess,
  fetchPayloadsOverTimeFailure
}
