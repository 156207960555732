import {
  FetchChannelInformationRequest,
  FetchChannelInformationSuccess,
  FetchChannelInformationFailure,
  SaveChannelInformationRequest,
  SaveChannelInformationSuccess,
  SaveChannelInformationFailure,
  FETCH_CHANNEL_INFORMATION_FAILURE,
  FETCH_CHANNEL_INFORMATION_REQUEST,
  FETCH_CHANNEL_INFORMATION_SUCCESS,
  SAVE_CHANNEL_INFORMATION_REQUEST,
  SAVE_CHANNEL_INFORMATION_SUCCESS,
  SAVE_CHANNEL_INFORMATION_FAILURE,
  FetchChannelInformationRequestParam,
  FetchChannelInformationSuccessParam,
  FetchChannelInformationFailureParam,
  SaveChannelInformationRequestParam,
  SaveChannelInformationSuccessParam,
  SaveChannelInformationFailureParam
} from './types'

export const fetchChannelInformationRequest = (data: FetchChannelInformationRequestParam): FetchChannelInformationRequest => ({
  type: FETCH_CHANNEL_INFORMATION_REQUEST,
  payload: data
})

export const fetchChannelInformationSuccess = (data: FetchChannelInformationSuccessParam): FetchChannelInformationSuccess => ({
  type: FETCH_CHANNEL_INFORMATION_SUCCESS,
  payload: data
})

export const fetchChannelInformationFailure = (error: FetchChannelInformationFailureParam): FetchChannelInformationFailure => ({
  type: FETCH_CHANNEL_INFORMATION_FAILURE,
  payload: error
})

export const saveChannelInformationRequest = (data: SaveChannelInformationRequestParam): SaveChannelInformationRequest => ({
  type: SAVE_CHANNEL_INFORMATION_REQUEST,
  payload: data
})

export const saveChannelInformationSuccess = (data: SaveChannelInformationSuccessParam): SaveChannelInformationSuccess => ({
  type: SAVE_CHANNEL_INFORMATION_SUCCESS,
  payload: data
})

export const saveChannelInformationFailure = (error: SaveChannelInformationFailureParam): SaveChannelInformationFailure => ({
  type: SAVE_CHANNEL_INFORMATION_FAILURE,
  payload: error
})
