import React, { FunctionComponent, useState, useEffect } from 'react'
import * as R from 'ramda'
import { RemoteData, remoteData } from '@sbizeul/fp-flow'
import { Dropdown } from 'semantic-ui-react'
import * as A from 'fp-ts/lib/ReadonlyArray'

import TextInput from 'components/TextInput'
import {
  RemoveContentParam,
  targetPath,
  typePath,
  titlePath,
  urlPath,
  externalQuestionIdPath,
  ButtonAttachmentContent,
  ButtonsAttachmentContent
} from 'modules/answer/types'
import { Question } from 'modules/question/types'
import { ServerFailure } from 'types'
import { useEffectOnce, renderNothing, renderLoader } from 'helpers/react'
import { isValidButtonsAttachment, hasTenButtonAttachment, hasTreeButtonAttachment, completeButtonsAttachment } from 'modules/answer/models.typed'

import './style.css'
export type Props = Readonly<{
  buttonsContent: ButtonsAttachmentContent,
  buttonContent?: any
  questions: RemoteData<ServerFailure, ReadonlyArray<Question>>

  fetchQuestions: () => unknown
  removeContent: (params: RemoveContentParam) => unknown
  updateContent: (params: ButtonsAttachmentContent) => unknown
}>

export const optionsDropdown = [
  { key: 1, text: 'URL Link', value: 'self' },
  { key: 2, text: 'URL in New Tab', value: 'blank' },
  { key: 3, text: 'Q&A Link', value: 'question_link' }
]

const toEmptyQuestionReferences: (index: number) => (buttonContent: ButtonsAttachmentContent) => ButtonsAttachmentContent =
  index => R.pipe(
    R.dissocPath<ButtonsAttachmentContent>([index, 'target']),
    R.dissocPath<ButtonsAttachmentContent>([index, 'url']),
    R.set(typePath(index), 'question_link'),
    R.assocPath([index, 'external_question_id'], '')
  )

const buttonContentUrl: (index: number) => (buttonContent: ButtonsAttachmentContent) => string =
  index => R.view(urlPath(index))

const isButtonContentUrlEmpty: (index: number) => (buttonContent: ButtonsAttachmentContent) => boolean =
  index => R.compose(R.isNil, buttonContentUrl(index))

const toButtonAttachmentUrl: (index: number, value: string) => (buttonContent: ButtonsAttachmentContent) => ButtonsAttachmentContent =
  (index, value) => buttonContent => R.pipe(
    R.dissocPath<ButtonsAttachmentContent>([index, 'external_question_id']),
    R.ifElse(
      isButtonContentUrlEmpty(index),
      R.set(urlPath(index), ''),
      R.identity
    ),
    R.set(targetPath(index), value),
    R.set(typePath(index), 'web_url')
  )(buttonContent)

  const ButtonContent: FunctionComponent<Props> = (props) => {
    const [buttonsAttachmentContent, setButtonsAttachmentContent] = useState(props.buttonsContent)
    useEffect(() => {
      if (props.buttonContent) { // si on est dans le cas d'une réponse de type "Buttons"
        const buttonsContent =
        (!hasTenButtonAttachment(props.buttonsContent) && isValidButtonsAttachment(props.buttonsContent))
          ? completeButtonsAttachment(props.buttonsContent)
          : props.buttonsContent
      setButtonsAttachmentContent(buttonsContent)
      } else { // sinon: par exemple dans le cas d'une réponse de type "Carousel"
        const buttonsContent =
        (!hasTreeButtonAttachment(props.buttonsContent) && isValidButtonsAttachment(props.buttonsContent))
          ? completeButtonsAttachment(props.buttonsContent)
          : props.buttonsContent
      setButtonsAttachmentContent(buttonsContent)
      }
  
    }, [props.buttonsContent])

  useEffectOnce(() => { props.fetchQuestions() })

  const questionsToDropdownFormat = (questions: ReadonlyArray<Question>) =>
    questions.map((questions, index) => ({ key: index, text: questions.label, value: questions.external_id }))

  const updateButtonContent = (buttonsContent: ButtonsAttachmentContent) => R.pipe(
    A.filter((buttonAttachement: ButtonAttachmentContent) => buttonAttachement.title[0].length > 0),
    props.updateContent
  )(buttonsContent)

  const updateButtonContentProperty = (lens: R.Lens) => (propertyValue: string) => {
    const buttonsContent = R.set(lens, propertyValue, buttonsAttachmentContent)
    updateButtonContent(buttonsContent)
  }
  const updateTitle = (index: number) => updateButtonContentProperty(titlePath(index))
  const updateUrl = (index: number) => updateButtonContentProperty(urlPath(index))
  const updateExternalQuestionId = (index: number, value: string) => updateButtonContentProperty(externalQuestionIdPath(index))(value)

  const isQnASelector = (index: number) => R.view(typePath(index), buttonsAttachmentContent) === 'question_link'

  const renderQuestions = (index: number) => remoteData.fold(
    {
      Success: (questions: ReadonlyArray<Question>) => <Dropdown
        className='ButtonContent-dropdown--qa'
        options={questionsToDropdownFormat(questions)}
        onChange={(_, { value }) => updateExternalQuestionId(index, R.defaultTo('')(value).toString())}
        placeholder='Choose an option'
        selection
        value={R.view(externalQuestionIdPath(index), buttonsAttachmentContent)}
      />,
      Loading: renderLoader,
      Failure: renderNothing,
      NotAsked: renderNothing
    }
  )(props.questions)

  return (
    <div className='ButtonContent--container'>
      {props.children}
      {R.addIndex(R.map)(
        (_, index: number) =>
          <div key={index} className='ButtonContent-row'>
            <TextInput
              className='ButtonContent-row--title'
              data-testid={`update-title-input${index}`}
              type='rounded'
              name='title'
              autocomplete='off'
              value={R.view(titlePath(index), buttonsAttachmentContent)}
              onChangeText={updateTitle(index)}
              pattern='^.{1,30}'
              placeholder='Button text'
            />
            <div className='ButtonContent-row-url--container'>
              <Dropdown
                className='naming-dropdown'
                options={optionsDropdown}
                onChange={(_, { value }) => R.equals(value)('question_link') ?
                  updateButtonContent(toEmptyQuestionReferences(index)(buttonsAttachmentContent))
                  :
                  updateButtonContent(toButtonAttachmentUrl(index, R.defaultTo('')(value).toString())(buttonsAttachmentContent))
                }
                placeholder='Choose an option'
                selection
                value={R.view(typePath(index), buttonsAttachmentContent) === 'web_url' ?
                  R.view(targetPath(index), buttonsAttachmentContent) : R.view(typePath(index), buttonsAttachmentContent)}
              />
              {!isQnASelector(index) ?
                <TextInput
                  className='ButtonContent-row--url'
                  data-testid={`update-url-input${index}`}
                  type='rounded'
                  name='url'
                  autocomplete='off'
                  value={R.view(urlPath(index), buttonsAttachmentContent)}
                  onChangeText={updateUrl(index)}
                  placeholder='URL'
                /> :
                renderQuestions(index)
              }
            </div>
          </div>
      )(buttonsAttachmentContent)}
    </div>
  )
}

export default ButtonContent
