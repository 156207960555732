import React from 'react'
import { List } from 'immutable'
import { Icon, Checkbox, Loader, Button, Popup } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { maybe } from '@sbizeul/fp-flow'
import { v4 as uuid } from 'uuid'

import * as operations from '../../../redux/training/operations'
import IntentModal from './_components/IntentModal'
import bot from '../../../modules/bot'
import matchingFlags from '../../../utils/matchingFlags'
import Select from '../../../components/Select'

import './react_train_overrides.css'
import './trainform.css'

class ConversationsList extends React.Component {
  componentDidMount() {
    this.props.assignListName(this.props.listName)
  }

  getList = () => {
    return this.props.intentList
      ? this.props.intentList.toJS().map(intent => ({
        key: uuid(),
        value: intent.slug,
        text: intent.name
      }))
      : []
  }

  getBotId = () => {
    return maybe.getOrElse('unknownBotId', this.props.botId)
  }

  trainSelected = () => {
    this.props.trainSelected(
      this.props.listName,
      this.props.listp.filter(line => line.selected),
      this.props.activeChannelId,
      this.props.intentId,
      this.props.activeLang[0],
      this.props.dateRange,
      this.props.activeLanguage,
      this.props.activeIntent
    )
  }

  trainLine = (conversation, index) => () => {
    this.props.trainLine(
      index,
      this.props.listName,
      conversation.id,
      this.props.activeChannelId,
      conversation.intentId,
      this.props.activeLang[0],
      this.props.dateRange,
      this.props.activeLanguage,
      this.props.activeIntent
    )
  }

  deleteLine = (conversation, index) => () => {
    this.props.deleteLine(
      conversation.id,
      index,
      this.props.listName,
      this.props.activeChannelId,
      this.props.activeLanguage,
      this.props.dateRange
    )
  }

  archiveLine = (conversation, index) => () => {
    this.props.archiveLine(
      conversation.id,
      index,
      this.props.listName,
      this.props.activeChannelId,
      this.props.activeLanguage,
      this.props.dateRange
    )
  }

  render() {
    return (
      <React.Fragment>
        {this.renderGlobalActions()}
        {this.props.listp.map(this.renderConversationLine)}
      </React.Fragment>
    )
  }

  renderConversationLine = (conversation, index) => (
    <div className='ConversationsList-listItem' key={index}>
      {this.renderSelectCheckbox(conversation, index)}
      {this.renderIntent(conversation)}
      {this.renderConversationText(conversation)}
      {this.renderChooseIntentDropdown(conversation, index)}
      {conversation.loading ? <Loader active inline /> : this.renderInlineActions(conversation, index)}
    </div>
  )

  renderSelectCheckbox(conversation, index) {
    return (
      <Checkbox
        checked={conversation.selected}
        type='checkbox'
        value={conversation.id}
        name='selectCheckBox'
        onChange={() => this.props.toggleLine(index, this.props.listName)}
      />
    )
  }

  renderIntent(conversation) {
    return (
      <div className='ConversationsList-listItem--intent'>
        {conversation.intent !== '' ? (
          <span className='ConversationsList-listItem--intent-label'>{conversation.intent}</span>
        ) : this.props.listName === 'trainingListNotMatched' ? (
          <IntentModal id={conversation.id} description={conversation.text_in} botId={this.getBotId()} />
        ) : null}
      </div>
    )
  }

  renderConversationText(conversation) {
    return (
      <div className='ConversationsList-listItem--text'>
        <div>{conversation.text_in}</div>
        <div className='ConversationsList-listItem--text-annotations'>
          <span className='ConversationsList-listItem--text-flag'>{matchingFlags[conversation.language]}</span>
          <img
            className='ConversationsList-listItem--text-annotations-image'
            src={`/img/platform/${conversation.source}.png`}
            alt='platform'
          />
          <span className='ConversationsList-listItem--text-annotations-date'>{conversation.datetime}</span>
        </div>
      </div>
    )
  }

  renderChooseIntentDropdown(conversation, index) {
    return (
      <Select
        placeholder='Assign to an intent...'
        value={conversation.intentId}
        options={this.getList()}
        onChange={({ value }) => {
          this.props.assignLine(index, this.props.listName, value)
        }}
        withSearch
        onClear={() => this.props.clearIntentLine(index, this.props.listName)}
      />
    )
  }

  renderInlineActions(conversation, index) {
    return (
      <React.Fragment>
        {this.renderTrainLineButton(conversation, index)}
        {this.renderDeleteButton(conversation, index)}
        {this.renderArchiveButton(conversation, index)}
      </React.Fragment>
    )
  }

  renderTrainLineButton(conversation, index) {
    return (
      <Icon
        className='big check square link icon ConversationsList-listItem--train'
        disabled={conversation.loading}
        onClick={this.trainLine(conversation, index)}
      />
    )
  }

  renderDeleteButton(conversation, index) {
    return (
      <Popup
        trigger={
          <Icon
            className='small trash alternate link icon'
            disabled={conversation.loading}
            onClick={this.deleteLine(conversation, index)}
          />
        }
        content='Delete'
        basic
      />
    )
  }

  renderArchiveButton(conversation, index) {
    return (
      (this.props.listName === 'trainingListNotMatched' || this.props.listName === 'trainingList') && (
        <Popup
          trigger={
            <Icon
              className='small archive link icon'
              disabled={conversation.loading}
              onClick={this.archiveLine(conversation, index)}
            />
          }
          content='Archive'
          basic
        />
      )
    )
  }

  renderGlobalActions() {
    return (
      <div className='ConversationsList-global'>
        <Checkbox
          type='checkbox'
          name='globalCheckBox'
          checked={this.props.checkedAll}
          onChange={() => this.props.toggleAll(this.props.listName)}
        />
        <Select
          className='ConversationsList-global--intent'
          placeholder='Assign to an intent...'
          value={this.props.intentId}
          options={this.getList()}
          onChange={({ value }) => {
            this.props.assignSelected(value, this.props.listName)
          }}
          withSearch
          onClear={() => this.props.clearIntentSelected(this.props.listName)}
        />
        <Button className='ui blue' loading={this.props.trainingSelectedLoading} onClick={this.trainSelected}>
          Train
        </Button>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  checkedAll: state.training.getIn([state.training.get('listName'), 'all']),
  botId: bot.selectors.getSelectedId(state),
  trainingSelectedLoading: state.training.getIn([state.training.get('listName'), 'loading']),
  activeChannelId: state.main.filters.channel.id,
  activeLanguage: state.training.getIn(['filters', 'language', 'id']),
  activeIntent: state.training.getIn(['filters', 'intent', 'id']),
  activeLang: bot.selectors.getLanguages(state),
  intentId: state.training.getIn([state.training.get('listName'), 'intentId']),
  intentList: state.training.getIn(['intentList', 'data'], List()),
  dateRange: state.training.getIn(['filters', 'dateRange'], List()).toJS()
})

const ConversationsListConnected = connect(mapStateToProps, operations)(ConversationsList)

export default ConversationsListConnected
