import {
    SimpleAnswer,
    AnswerContent,
    TagAgentContent,
    TextContent,
    ImageContent,
    ButtonContent,
    ButtonAttachmentContent,
    ButtonAttachmentQnAContent,
    ButtonAttachmentUrlContent,
    FormContent,
    CarouselContent,
    CarouselAttachment,
    GotoSkillContent,
    GotoSmartSkillContent,
    BaseSkillContent,
    ActionContent,
    RgpdContent,
    QRAnswer,
    Answer,
    ButtonWithAnswer} from '../types'
import * as Entities from './types-workspace'



const convertTextAnswer = (textContent: TextContent): Entities.TextContent => ({
        content: textContent.objectAttachment.text[0],
        type: textContent.type
})

const convertTagAgentAnswer = (tagAgentContent: TagAgentContent): Entities.TagAgentContent => ({
  tag_id: tagAgentContent.objectAttachment.tag_id,
  action: 'set',
  type: tagAgentContent.type
})

const convertImageAnswer = (imageContent: ImageContent): Entities.ImageContent => ({
    url: imageContent.objectAttachment.imageUrl,
    type: imageContent.type
})

const convertButtonQuestionLinkToQuestionButton = (buttonQuestionLink : ButtonAttachmentQnAContent): Entities.QuestionButton=> (
    {
        questionId: buttonQuestionLink.external_question_id,
        text: buttonQuestionLink.title[0],
        type: 'question'
    }
)

const convertButtonWebUrlToUrlButton = (buttonWebUrl: ButtonAttachmentUrlContent): Entities.UrlButton => (
    {
        target: buttonWebUrl.target,
        text: buttonWebUrl.title[0],
        type: 'url',
        url: buttonWebUrl.url
    }
)

const convertButtonAttachementToButtonList = (buttonAttachementContent : ButtonAttachmentContent) : Entities.Button => {
    switch (buttonAttachementContent.type) {
        case 'question_link':
            return convertButtonQuestionLinkToQuestionButton(buttonAttachementContent)
        case 'web_url':
            return convertButtonWebUrlToUrlButton(buttonAttachementContent)
        default:
            return buttonAttachementContent;
    }
}

const convertButtonAnswer = (buttonContent: ButtonContent): Entities.ButtonsContent => (
    {
        buttons: buttonContent.objectAttachment.buttons.map(button => convertButtonAttachementToButtonList(button)),
        text: buttonContent.objectAttachment.text[0] || undefined,
        type: 'buttons' as 'buttons'
    }
)

const convertCarouselAttachmentToCarrouselItems = (carouselAttachment: CarouselAttachment) : Entities.CarrouselItem => ({
    buttons: carouselAttachment.buttons.map(button=> convertButtonAttachementToButtonList(button)),
    description: carouselAttachment.description,
    imageUrl: carouselAttachment.image,
    title: carouselAttachment.title
})

const convertCarouselAnswer = (carouselContent: CarouselContent): Entities.CarrouselContent => ({
    items: carouselContent.objectAttachment.map(carousel => convertCarouselAttachmentToCarrouselItems(carousel)),
    type: 'carrousel'
})

const convertFormAnswer = (formContent: FormContent): Entities.FormContent => ({
    formId: formContent.objectAttachment.form_id,
    type: formContent.type
})

const convertSkillAnswer = (goToSkillContent: GotoSkillContent): Entities.SkillContent => ({
    skillId: goToSkillContent.objectAttachment.skill_id,
    type: goToSkillContent.type
})

const convertSmartSkillAnswer = (goToSmartSkillContent: GotoSmartSkillContent): Entities.SmartSkillContent => ({
    smartskillId: goToSmartSkillContent.objectAttachment.smartskill_id,
    type: goToSmartSkillContent.type
})

const convertGoToAnswer = (baseSkillContent: BaseSkillContent): Entities.GoToContent => ({
    answerId: baseSkillContent.objectAttachment.answer_id,
    type: baseSkillContent.type
})

const convertActionAnswer = (appContent: ActionContent) : Entities.ActionContent => {
  if (appContent.objectAttachment.name === 'agent') {
    return {
      appId: 'agent-handover',
      type: 'action',
    }
  }
  return {
    appId: appContent.objectAttachment.app_id,
    type: 'action',
  }
}

const convertRgpdAnswer = (rgpContent: RgpdContent) : Entities.ActionContent => ({
    type: 'action',
    appId: 'rgpd'
})

const convertAnswerItemsContent = (answerContent: AnswerContent): Entities.AnswerItemsContent =>
{
    switch (answerContent.type) {
        case 'text':
            return convertTextAnswer(answerContent)
        case 'image':
            return convertImageAnswer(answerContent)
        case 'tagagent':
            return convertTagAgentAnswer(answerContent)
        case 'button':
            return convertButtonAnswer(answerContent)
        case 'carousel':
            return convertCarouselAnswer(answerContent)
        case 'form':
            return convertFormAnswer(answerContent)
        case 'skill':
            return convertSkillAnswer(answerContent)
        case 'smartskill':
            return convertSmartSkillAnswer(answerContent)
        case 'answer':
            return convertGoToAnswer(answerContent)
        case 'app':
            return convertActionAnswer(answerContent)
        case 'rgpd':
            return convertRgpdAnswer(answerContent)
    }
}

const getTextMissingMessage = (textContent : TextContent): string => {
    return textContent.objectAttachment.text[0] || ''
}

const convertAnswerContentQuestionToAnsweritems = (buttonwithAnswer :ButtonWithAnswer): Entities.AnswerItems => {
    if(buttonwithAnswer.keyword) {
        return {
            quickReplyText: buttonwithAnswer.title[0],
            keywordId: buttonwithAnswer.keyword.id,
            content: (buttonwithAnswer.answer) ?
                buttonwithAnswer.answer.content.map(answerContent => convertAnswerItemsContent(answerContent)):
                []
        }
    }
    return {
        quickReplyText: buttonwithAnswer.title[0],
        content: (buttonwithAnswer.answer) ?
            buttonwithAnswer.answer.content.map(answerContent => convertAnswerItemsContent(answerContent)):
            []
}
}

const convertQuestionContent= (answer: QRAnswer): Entities.QuestionContent => {
  return {
       type: 'question',
       text: (answer.missing_message.content[0] && answer.missing_message.content[0].type=== 'text')  ?
        getTextMissingMessage(answer.missing_message.content[0]) :
        '',
       answers: answer.buttons.map(buttonwithAnswer => (convertAnswerContentQuestionToAnsweritems(buttonwithAnswer)))
   }
  }

const answerSimpleConversion = (answer: SimpleAnswer,feedback: boolean): Entities.Answer => {
    return {
        askFeedback : feedback,
        content: answer.content.map(
            contentItem => convertAnswerItemsContent(contentItem)
        )
    }
}

const answerQrConversion = (answer: QRAnswer,feedback: boolean): Entities.Answer => {

    return {
        askFeedback : feedback,
        content: [convertQuestionContent(answer)]
    }
}

const answerConversion = (answer: Answer, feedback: boolean): Entities.Answer => {
    switch (answer.type) {
        case 'Simple':
            return answerSimpleConversion(answer, feedback)
        case 'QR':
            return answerQrConversion(answer, feedback)
    }
}

export default answerConversion;
