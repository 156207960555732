import React from 'react'
import { ThemeProvider } from 'styled-components'
import theme from '../../style/theme'
import LeftPanel from '../LeftPanel'
import BroadcastManagerContainerStyled from './styles'
import MainContent from '../MainContent'

const BroadcastManagerScene = props => (
  <ThemeProvider theme={theme}>
    <React.Fragment>
      <BroadcastManagerContainerStyled>
        <LeftPanel {...props} />
        <MainContent {...props} />
      </BroadcastManagerContainerStyled>
    </React.Fragment>
  </ThemeProvider>
)

export default BroadcastManagerScene
