import axios from 'axios'
import { v4 } from 'uuid'
import { reset } from 'redux-form'
import { toastr } from 'react-redux-toastr'
import history from '../../../../config/history'
import toastrOptions from '../../../_shared/toastrOptions'
import actions from '../actions'
import { legacyApiUrl } from 'helpers/effects'

const postEventChart = (data, bot) => dispatch => {
  const computedData = {
    ...data,
    series: null,
    data: data.series.map(serie => ({ ...serie, id: v4() }))
  }
  dispatch(actions.postEventChartRequest())
  return axios({
    url: `${legacyApiUrl}/admin/${bot.bot_id}/analytics/events`,
    method: 'post',
    contentType: 'application/json',
    responseType: 'json',
    data: computedData
  })
    .then(res => {
      if (res.status === 200) {
        dispatch(actions.postEventChartSuccess(res.data))
        dispatch(reset('AddEventChartForm'))
        toastr.info(
          'Nouveau Chart Ajouté avec Succès',
          toastrOptions({
            icon: 'check'
          })
        )
        history.push('/analytics/events/')
      } else {
        dispatch(actions.postEventChartFailure())
        toastr.error(
          'Problème pour uploader la ressource',
          `Statut Erreur ${res.status}`,
          toastrOptions({
            icon: 'error'
          })
        )
      }
    })
    .catch(err => {
      dispatch(actions.postEventChartFailure())
      toastr.error(
        'Problème pour uploader la ressource',
        `Erreur ${err}`,
        toastrOptions({
          icon: 'error'
        })
      )
    })
}

export default postEventChart
