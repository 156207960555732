import React, { Component } from 'react'
import { maybe, remoteData, RemoteData, Maybe } from '@sbizeul/fp-flow'

import { Loader } from 'components/Loading'
import { renderFailure } from 'helpers/react'
import EmptyStateContent from 'components/EmptyState'
import { Id, ServerFailure } from 'types'

import { Conversation as ConversationState } from '../../types'
import ConversationList from '../ConversationList'
import Conversation from '../Conversation'
import ConversationFiltersContainer from '../../containers/ConversationFiltersContainer'

import './styles.css'

export const renderNoConversation = () => (
  <div data-testid='no-conversation' className='Conversations-emptyState-container'>
    <EmptyStateContent
      img='/img/chat.svg'
      title='No message to show :('
      text='Try a different date range or say hello to your assistant.'/>
  </div>
)

const renderLoading = () => <Loader data-testid='loading-conversations' description='Fetching conversations'/>

export type SelectedConversationProps = Readonly<{
  conversations: RemoteData<ServerFailure, ReadonlyArray<ConversationState>>,
  selectedConversation: Maybe<ConversationState>,

  setActiveConversation: (id: Id) => unknown
}>

const renderSelectedConversation = ({ selectedConversation, conversations, setActiveConversation }: SelectedConversationProps) => {
  const renderConversation = (conversation: ConversationState) => (
    <div className='Conversations-content--container'>
      <div className='Conversations-content--list'>
        <ConversationList
          conversations={remoteData.getOrElse(() => [], conversations)}
          activeID={conversation.id}
          onSelect={setActiveConversation}
        />
      </div>
      <div className='Conversations-content--messages'>
        <Conversation conversation={conversation}/>
      </div>
    </div>
  )

  return (
    <div className='Conversations'>
      <ConversationFiltersContainer/>
      {maybe.fold(
        renderNoConversation,
        renderConversation,
        selectedConversation
      )}
    </div>
  )
}

export type ConversationsProps = Omit<SelectedConversationProps, 'conversation'> & Readonly<{
  botId: Maybe<string>,

  fetchConversations: () => RemoteData<ServerFailure, ReadonlyArray<ConversationState>>
}>

export default class Conversations extends Component<ConversationsProps> {
  componentDidMount () {
    maybe.map(this.props.fetchConversations, this.props.botId)
  }

  render () {
    return remoteData.fold({
      Success: () => renderSelectedConversation(this.props),
      NotAsked: renderLoading,
      Loading: renderLoading,
      Failure: renderFailure
    })(this.props.conversations)
  }
}
