import * as React from 'react'
import { SortableHandle } from 'react-sortable-hoc'
import { FaArrowsAlt } from 'react-icons/fa'
import MoveHandleStyled from './styles'

const MoveHandle = ({ size, relative }) => (
  <MoveHandleStyled size={size} relative={relative}>
    <FaArrowsAlt />
  </MoveHandleStyled>
)

MoveHandle.defaultProps = {
  size: 16,
  relative: false
}

export default SortableHandle(MoveHandle)
