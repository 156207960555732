import { isEmpty } from 'lodash'
import axios from 'axios'
import { maybe } from '@sbizeul/fp-flow'

import actions from '../actions'
import bot from '../../../../modules/bot'
import { legacyApiUrl } from 'helpers/effects'

const fetchCustomEventsData = filters => async (dispatch, getState) => {
  const state = getState()
  const start = filters.dateRange.start.format('YYYY-MM-DD')
  const end = filters.dateRange.end.format('YYYY-MM-DD')
  const config = state.analytics.events.config
  const period = 'days'
  const botId = maybe.get(bot.selectors.getSelectedId(state))

  if (isEmpty(config)) {
    return Promise.resolve()
  }
  dispatch(actions.fetchCustomEventsRequest())
  return config.map(chart =>
    chart.data.map(serie => {
      const url =
        chart.chart === 'pie' && serie.event_property
          ? `${legacyApiUrl}/analytics/${botId}/${filters.channel.id}/events/${serie.event_slug}/${serie.event_property}`
          : `${legacyApiUrl}/analytics/${botId}/${filters.channel.id}/events/${serie.event_slug}`
      const periodObject = chart.chart !== 'table' ? { period } : {}
      return axios({
        url,
        method: 'get',
        params: {
          start,
          end,

          ...periodObject
        },
        responseType: 'json'
      })
        .then(response => {
          if (response.status === 200) {
            dispatch(
              actions.fetchCustomEventsSuccess({
                name: serie.name,
                id: serie.id,
                data: response.data.result
              })
            )
          } else {
            dispatch(actions.fetchCustomEventsFailure())
          }
        })
        .catch(err => {
          dispatch(actions.fetchCustomEventsFailure(err))
        })
    })
  )
}

export default fetchCustomEventsData
