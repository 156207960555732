import { call, put, takeLatest } from 'redux-saga/effects'
import Axios from 'axios'
import { push } from 'connected-react-router'

import apiLegacy from 'services/tolkApiLegacy'
import * as effects from 'helpers/effects'
import types from 'redux/intents/types'
import { getEnv } from 'helpers/effects'

import user from '../user'
import * as authActions from './actions'
import * as browserUtils from '@tolk-genii/browser-utils'

export function* authenticate({ payload }) {
  try {
    const { data: token } = yield call(apiLegacy.post, '/auth/token', payload)
    yield call(effects.setItem, 'tcfAuthHeader', token)
    yield put(authActions.authenticateSuccess(token))
  } catch (error) {
    yield put(authActions.authenticateFailure(error.response.data))
  }
}

export function* enterApp() {
  yield put(push('/'))
}

export function* removeToken() {
  const cookieDomain = getEnv('COOKIE_DOMAIN').value || ''

  browserUtils.removeCookie(document, cookieDomain)('tolk_access_token')
  browserUtils.removeCookie(document, cookieDomain)('tolk_id_token')
  browserUtils.removeCookie(document, cookieDomain)('tolk_refresh_token')
  window.localStorage.removeItem('tolk_authorization_infos')
  yield call(effects.removeItem, 'tcfAuthHeader')
  Axios.defaults.headers.common.authorization = null
}

export function* removeBotId() {
  localStorage.removeItem('botId')
}

export function* forceStateCleaning() {
  window.location.href = '/'
}

export function* root() {
  yield takeLatest(authActions.AUTHENTICATE_REQUEST, authenticate)
  yield takeLatest(types.POST_PASSWORD_SUCCESS, authenticate)
  yield takeLatest(authActions.AUTHENTICATE_SUCCESS, enterApp)
  yield takeLatest(user.actions.LOGIN_FAILURE, removeToken)
  yield takeLatest(user.actions.LOGIN_FAILURE, removeBotId)
  yield takeLatest(user.actions.LOGIN_FAILURE, forceStateCleaning)
  yield takeLatest(user.actions.LOGOUT, removeToken)
  yield takeLatest(user.actions.LOGOUT, removeBotId)
  yield takeLatest(user.actions.LOGOUT, forceStateCleaning)
}
