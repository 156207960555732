import styled from 'styled-components'

const IndicatorStyled = styled.div`
  display: flex;
  align-items: center;
  flex: 0;
  margin-left: ${({ theme }) => theme.margin.left.indicator}px;
  width: 100%;
  color: ${({ theme }) => theme.color.indicator};
  font-size: 0.7em;
  > * ~ * {
    margin-left: ${({ theme }) => theme.margin.left.indicator / 2}px;
  }
  > * {
    display: flex;
    align-items: center;
  }
`

export default IndicatorStyled
