import React, { Component } from 'react'
import { Button, Tab } from 'semantic-ui-react'
import { connect } from 'react-redux'
import operations from '../../../redux/psadata/operations'

import { andonSelector } from '../../../redux/selectors'

import PostPane from './components/PostPane'

class PsaAndon extends Component {
  componentDidMount() {
    const { getAndon } = this.props
    getAndon()
  }

  render() {
    const { andonData, isFetchingCreate, getAndon } = this.props

    const panes = [
      {
        menuItem: 'Problèmes Andon',
        pane: (
          <Tab.Pane style={{ height: 'calc(100% - 100px)', marginBottom: '0px' }}>
            <PostPane data={andonData} type='defaut_data' isFetching={isFetchingCreate} />
          </Tab.Pane>
        )
      }
    ]

    return (
      <div style={{ height: '100%' }}>
        <Button
          content='Refresh'
          onClick={() => {
            getAndon()
          }}
        />
        <br />
        <br />
        <Tab style={{ height: 'calc(100% - 50px)' }} panes={panes} renderActiveOnly={false} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  andonData: andonSelector(state),
  isFetchingCreate: state.psa.getIn(['prisePoste', 'isFetching'])
})

const ConnectedPsaPoste = connect(mapStateToProps, operations)(PsaAndon)

export default ConnectedPsaPoste
