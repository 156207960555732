import React, { FunctionComponent } from 'react'
import { v4 as uuid } from 'uuid'
import * as R from 'ramda'
import { MdEdit } from 'react-icons/md'

import { bucketUrl } from 'helpers/effects'
import { UploadImageRequestPayload } from 'modules/answer/types'
import { UpdateWebchatParams, UploadImageParams, Webchat } from '../../types'

type Props = Readonly<{
  webchat: Webchat

  uploadImage: UploadImageParams
  updateWebchat: UpdateWebchatParams
}>

const avatarStyle = (url: string, color: string) => ({
  backgroundImage: `url(${url})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundColor: color
})
const defaultAvatar = [
  `${bucketUrl}/bots/icon-white.png`,
  `${bucketUrl}/bots/icon-black.png`
]
const isDefaultAvatar = (webchat: Webchat) =>
  R.any(
    (avatar) =>
      avatar === R.view(R.lensPath(['settings', 'avatar', 'pics']), webchat)
  )(defaultAvatar)

const renderUploadInput = (
  uploadImage: (image: UploadImageRequestPayload) => unknown
) => (
  <input
    className="customize-icon-input"
    onChange={({ target }) =>
      target.files && uploadImage({ id: uuid(), file: target.files[0] })
    }
    accept="image/*"
    type="file"
  />
)

const AvatarSelection: FunctionComponent<Props> = ({
  webchat,
  updateWebchat,
  uploadImage
}) => (
  <div>
    <h2> Avatar</h2>
    <div className="BotConfig-avatar-container customize-icon-avatar">
      {defaultAvatar.map((avatar) => (
        <label
          key={uuid()}
          className="customize-icon"
          style={avatarStyle(avatar, webchat.settings.color1)}
        >
          <input
            onClick={() =>
              updateWebchat(
                R.set(
                  R.lensPath(['settings', 'avatar', 'pics']),
                  avatar,
                  webchat
                )
              )
            }
            defaultChecked={avatar === webchat.settings.avatar.pics}
            type="radio"
            name="avatar-group"
            className="customize-icon-input"
          />
        </label>
      ))}
      {!isDefaultAvatar(webchat) ? (
        <label
          className="BotConfig-avatar-custom-edit customize-icon"
          style={avatarStyle(
            webchat.settings.avatar.pics,
            webchat.settings.color1
          )}
        >
          <MdEdit className="BotConfig-avatar-custom-edit-icon" />
          {renderUploadInput(uploadImage)}
        </label>
      ) : (
        <label className="BotConfig-avatar-custom customize-icon">
          {renderUploadInput(uploadImage)}
        </label>
      )}
    </div>
  </div>
)

export default AvatarSelection
