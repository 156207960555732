import React, { Component } from 'react'
import { Progress } from 'semantic-ui-react'
import './filterbar.css'

class FilterBar extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    if (this.props.data > 19) {
      return (
        <Progress size='medium' value='30' total='30' progress='percent' success>
          Your intent is trained{' '}
          <span role='img' aria-label='awesome'>
            🎉
          </span>
        </Progress>
      )
    } else if (this.props.data > 9) {
      return <Progress size='medium' value={this.props.data} total='20' progress='ratio' warning />
    } else {
      return <Progress size='medium' value={this.props.data} total='20' progress='ratio' error />
    }
  }
}

export default FilterBar
