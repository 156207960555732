import React from 'react'
import { SortableContainer } from 'react-sortable-hoc'
import { Element, ElementText, ElementImage, ElementVideo, ElementCarousel } from '..'
import ElementsContainerStyled from './styles'
import { ElementTypes } from '../../types'

const Elements = ({
  settings,
  elements,
  deleteElement,
  dragging,
  editCarouselElementAddCard,
  editCarouselElementDeleteCard,
  editCarouselElementReorderCards,
  editCarouselElementImage,
  editCarouselElementTitle,
  editCarouselElementDescription,
  editCarouselElementAddButton,
  editCarouselElementDeleteButton,
  editCarouselElementButtonTitle,
  editCarouselElementButtonValue,
  editImageElement,
  editTextElement,
  editVideoElement,
  saveCarouselElementImageRequest,
  deleteCarouselElementImageRequest,
  saveImageElementRequest,
  deleteImageElementRequest,
  saveVideoElementRequest,
  deleteVideoElementRequest,
  canEdit
}) => (
  <ElementsContainerStyled dragging={dragging}>
    {!elements.length
      ? null
      : elements.map((element, index) => (
        <Element
          key={element.id}
          index={index}
          notDragable={elements.length === 1 || !canEdit}
          canEdit={canEdit}
          deleteElement={() => deleteElement(index)}
          render={() => {
            switch (element.type) {
            case ElementTypes.text:
              return (
                <ElementText
                  text={element.content}
                  type='text message'
                  changeText={text => editTextElement(index, text)}
                  placeholder='Your text here'
                  rootElementText
                  canEdit={canEdit}
                />
              )
            case ElementTypes.url:
              return (
                <ElementText
                  text={element.content}
                  type='url'
                  changeText={text => editTextElement(index, text)}
                  placeholder='Your url here'
                  rootElementText
                  isUrl
                  canEdit={canEdit}
                />
              )
            case ElementTypes.image:
              return (
                <ElementImage
                  settings={settings.image}
                  url={element.content.url}
                  temporaryUrl={element.content.temporaryUrl}
                  saveImage={file => saveImageElementRequest(index, file)}
                  deleteImage={file => deleteImageElementRequest(index)}
                  changeImage={url => editImageElement(index, url)}
                  canEdit={canEdit}
                />
              )
            case ElementTypes.video:
              return (
                <ElementVideo
                  settings={settings.video}
                  url={element.content.url}
                  temporaryUrl={element.content.temporaryUrl}
                  saveVideo={file => saveVideoElementRequest(index, file)}
                  deleteVideo={file => deleteVideoElementRequest(index)}
                  changeVideo={url => editVideoElement(index, url)}
                  containerProps={{
                    as: 'video',
                    src: element.content.url || element.content.temporaryUrl
                  }}
                  canEdit={canEdit}
                />
              )
            case ElementTypes.carousel:
              return (
                <ElementCarousel
                  settings={settings}
                  attachments={element.content.attachments}
                  addCarouselCard={() => editCarouselElementAddCard(index)}
                  deleteCarouselCard={cardIndex => editCarouselElementDeleteCard(index, cardIndex)}
                  reorderCarouselCards={(oldIndex, newIndex) =>
                    editCarouselElementReorderCards(index, oldIndex, newIndex)
                  }
                  changeImage={(cardIndex, url) => editCarouselElementImage(index, cardIndex, url)}
                  saveImage={(cardIndex, file) => saveCarouselElementImageRequest(index, cardIndex, file)}
                  deleteImage={(cardIndex, file) => deleteCarouselElementImageRequest(index, cardIndex)}
                  changeCarouselCardTitle={(cardIndex, title) => editCarouselElementTitle(index, cardIndex, title)}
                  changeCarouselCardDescription={(cardIndex, description) =>
                    editCarouselElementDescription(index, cardIndex, description)
                  }
                  addButton={cardIndex => editCarouselElementAddButton(index, cardIndex)}
                  deleteButton={(cardIndex, buttonIndex) =>
                    editCarouselElementDeleteButton(index, cardIndex, buttonIndex)
                  }
                  changeCarouselButtonTitle={(cardIndex, buttonIndex, title) =>
                    editCarouselElementButtonTitle(index, cardIndex, buttonIndex, title)
                  }
                  changeCarouselButtonValue={(cardIndex, buttonIndex, value) =>
                    editCarouselElementButtonValue(index, cardIndex, buttonIndex, value)
                  }
                  canEdit={canEdit}
                />
              )
            default:
              return null
            }
          }}
        />
      ))}
  </ElementsContainerStyled>
)

export default SortableContainer(Elements)
