import React from 'react'
import PropTypes from 'prop-types'

const VideoIcon = ({ width, height, fill }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 52 52'>
      <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Push-Copy-2' transform='translate(-1302.000000, -622.000000)' fill={fill} fillRule='nonzero'>
          <g id='video' transform='translate(1302.605469, 622.605469)'>
            <path
              d='M44.0671192,0 L6.48306758,0 C2.90831723,0 0,2.90831723 0,6.48306758 L0,44.0671192 C0,47.6418696 2.90831723,50.5501868 6.48306758,50.5501868 L44.0671192,50.5501868 C47.6418696,50.5501868 50.5501868,47.6418696 50.5501868,44.0671192 L50.5501868,6.48306758 C50.5501868,2.90831723 47.6418696,0 44.0671192,0 Z M6.48306758,2.96192501 L44.0671192,2.96192501 C46.0085685,2.96192501 47.5882618,4.54161835 47.5882618,6.48306758 L47.5882618,41.1075082 L2.96192501,41.1075082 L2.96192501,6.48306758 C2.96192501,4.54161835 4.54161835,2.96192501 6.48306758,2.96192501 Z M44.0671192,47.5882618 L6.48306758,47.5882618 C4.54238963,47.5882618 2.96346768,46.0101111 2.96192501,44.0694332 L47.5882618,44.0694332 C47.5867191,46.0101111 46.0077972,47.5882618 44.0671192,47.5882618 Z'
              id='Shape'
            ></path>
            <path
              d='M27.8741055,7.90270906 L22.6760813,7.90270906 C21.858081,7.90270906 21.1951188,7.23974691 21.1951188,6.42174655 C21.1951188,5.60374609 21.858081,4.94078405 22.6760813,4.94078405 L27.8741055,4.94078405 C28.6921059,4.94078405 29.355068,5.60374609 29.355068,6.42174655 C29.355068,7.23974691 28.6921059,7.90270906 27.8741055,7.90270906 Z'
              id='Shape'
            ></path>
            <path
              d='M21.1461391,33.3845202 C20.50516,33.3845202 19.8641809,33.2159836 19.279895,32.8785246 C18.1113229,32.2039925 17.4132651,30.9953111 17.4132651,29.6458611 L17.4132651,19.3863391 C17.4132651,18.0368892 18.1113229,16.8285935 19.279895,16.1536756 C20.4484669,15.4791435 21.8438113,15.4787579 23.012769,16.1536756 L31.897387,21.2834366 C33.0659589,21.9579688 33.7636311,23.1666502 33.7636311,24.5161001 C33.7636311,25.8655501 33.0659589,27.0742314 31.897387,27.7487636 L23.012769,32.8785246 C22.4280972,33.2159836 21.7871182,33.3845202 21.1461391,33.3845202 Z M21.1499958,18.6126905 C20.9722032,18.6126905 20.8310489,18.6782539 20.7608575,18.718749 C20.6447715,18.7858552 20.3751901,18.9848594 20.3751901,19.3863391 L20.3751901,29.6458611 C20.3751901,30.0473409 20.6447715,30.2463451 20.7608575,30.3134513 C20.8765577,30.3805574 21.1839345,30.5139983 21.5318065,30.3134513 L30.4164245,25.1836903 C30.7642963,24.9827575 30.801706,24.6499267 30.801706,24.5161001 C30.801706,24.3822735 30.7642963,24.0494427 30.4164245,23.84851 L21.5318065,18.718749 C21.3948945,18.6400729 21.2649246,18.6126905 21.1499958,18.6126905 Z'
              id='Shape'
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

VideoIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string
}

VideoIcon.defaultProps = {
  width: '1.5em',
  height: '1.5em',
  fill: 'currentColor'
}

export default VideoIcon
