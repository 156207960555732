import { createSelector } from 'reselect'
import { remoteData } from '@sbizeul/fp-flow'
import * as R from 'ramda'
import { createMatchSelector } from 'connected-react-router'

import * as map from '../../helpers/map'

export const getState = state => {
  return state.user
}

export const getUser = createSelector(getState, user => user.me)

export const getOne = (state, id) => {
  return remoteData.chain(remoteData.fromNullable, state.user.byId[id])
}

export const getId = createSelector(
  getUser,
  R.pipe(
    remoteData.map(user => {
      return user.sub
    }),
    remoteData.toMaybe
  )
)

export const getIsAdmin = createSelector(
  getUser,
  R.pipe(
    remoteData.map(user => false),
    remoteData.getOrElse(() => false)
  )
)

export const getBotIds = createSelector(
  getUser,
  R.pipe(
    remoteData.map(user => user.bot),
    remoteData.toMaybe
  )
)

export const getUsername = createSelector(
  getUser,
  R.pipe(
    remoteData.map(user => `${user.firstName} ${user.lastName}`),
    remoteData.toMaybe
  )
)

export const getBadLogin = createSelector(
  getUser,
  R.pipe(
    remoteData.map(user => user.bad_login),
    remoteData.toMaybe
  )
)

export const getLastLogin = createSelector(
  getUser,
  R.pipe(
    remoteData.map(user => user.last_login),
    remoteData.toMaybe
  )
)

export const getAll = createSelector(getState, state => remoteData.map(map.toArray, state.all))

export const matchUsersRoute = createMatchSelector({ path: '/admin/user', exact: true })
