import * as R from 'ramda'

import * as object from './object'

export const toMapByWantedKey = (array, key) => {
  return array.reduce((map, currentElement) => {
    map[currentElement[key]] = currentElement
    return map
  }, {})
}

export const toMapById = arr => {
  return toMapByWantedKey(arr, 'id')
}

export const toMapByUnderscoreId = arr => {
  return arr.reduce((acc, it) => {
    acc[it._id.$oid] = it
    return acc
  }, {})
}

export const toMapInsertIds = arr => {
  return R.pipe(R.map(object.insertId), toMapById)(arr)
}

export const deleteById = R.curry((idToDelete, tab) => R.filter(elem => elem.id !== idToDelete, tab))
