import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment-timezone'
import { DateRangePicker, isInclusivelyAfterDay } from 'react-dates'
import { Button } from 'semantic-ui-react'
import { v4 as uuid } from 'uuid'

import stripDateDetails from '../../../../redux/_shared/stripDateDetails'
import 'react-dates/lib/css/_datepicker.css'
import './react_date_overrides.css'
import operations from '../../../../redux/analytics/operations'

const availableRange = (day, allowedRange) =>
  isInclusivelyAfterDay(day, allowedRange.end) || isInclusivelyAfterDay(allowedRange.start, day)

class DateRangeComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      focusedInput: undefined,
      allowedRange: {
        start: moment('2017-11-12'),
        end: stripDateDetails(moment.tz('Europe/Paris').add(1, 'd'))
      }
    }
  }

  render() {
    const { dateRange, setDateRange, setDateRangeDropDown, filters, fetch } = this.props

    return (
      <div>
        <DateRangePicker
          startDateId={uuid()}
          endDateId={uuid()}
          displayFormat='MMM D'
          minimumNights={0}
          hideKeyboardShortcutsPanel
          initialVisibleMonth={() => stripDateDetails(moment.tz('Europe/Paris').subtract(1, 'month'))}
          isOutsideRange={day => availableRange(day, this.state.allowedRange)}
          startDate={dateRange.start}
          endDate={dateRange.end}
          onDatesChange={({ startDate, endDate }) => setDateRange({ start: startDate, end: endDate })}
          focusedInput={this.state.focusedInput}
          onFocusChange={focusedInput => this.setState({ focusedInput })}
          showClearDates
          showDefaultInputIcon
          firstDayOfWeek={1}
          reopenPickerOnClearDates
          keepOpenOnDateSelect
          renderCalendarInfo={() => (
            <div
              style={{
                padding: '10px',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center'
              }}
            >
              <Button
                color='teal'
                size='tiny'
                value='year'
                onClick={(e, { value }) =>
                  setDateRangeDropDown({
                    duration: value,
                    yesterday: stripDateDetails(moment.tz('Europe/Paris'))
                  })
                }
                style={{
                  fontFamily: 'Roboto',
                  fontWeight: 'lighter'
                }}
              >
                Last year
              </Button>
              <Button
                color='teal'
                size='tiny'
                value='m'
                onClick={(e, { value }) =>
                  setDateRangeDropDown({
                    duration: value,
                    yesterday: stripDateDetails(moment.tz('Europe/Paris'))
                  })
                }
                style={{
                  fontFamily: 'Roboto',
                  fontWeight: 'lighter'
                }}
              >
                Last month
              </Button>
              <Button
                color='teal'
                size='tiny'
                value='w'
                onClick={(e, { value }) =>
                  setDateRangeDropDown({
                    duration: value,
                    yesterday: stripDateDetails(moment.tz('Europe/Paris'))
                  })
                }
                style={{
                  fontFamily: 'Roboto',
                  fontWeight: 'lighter'
                }}
              >
                Last week
              </Button>
              <Button
                color='teal'
                size='tiny'
                value='y'
                onClick={(e, { value }) =>
                  setDateRangeDropDown({
                    duration: value,
                    yesterday: stripDateDetails(moment.tz('Europe/Paris'))
                  })
                }
                style={{
                  fontFamily: 'Roboto',
                  fontWeight: 'lighter'
                }}
              >
                Yesterday
              </Button>
              <Button
                color='teal'
                size='tiny'
                value='d'
                onClick={(e, { value }) =>
                  setDateRangeDropDown({
                    duration: value,
                    yesterday: stripDateDetails(moment.tz('Europe/Paris'))
                  })
                }
                style={{
                  fontFamily: 'Roboto',
                  fontWeight: 'lighter'
                }}
              >
                Today
              </Button>
              <div
                style={{
                  marginLeft: 'auto'
                }}
              >
                <Button
                  onClick={() => {
                    fetch(filters)
                    this.setState({ focusedInput: undefined })
                  }}
                  compact
                  size='medium'
                  color='blue'
                  style={{
                    fontFamily: 'Roboto'
                  }}
                >
                  Apply
                </Button>
              </div>
            </div>
          )}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  filters: state.main.filters,
  activeChannelId: state.main.filters.channel.id,
  periods: state.main.filters.periods,
  activePeriod: ['minutes', 'hours', 'days'][state.main.filters.periods.active]
})

const connectedDateRangeComponent = connect(mapStateToProps, operations)(DateRangeComponent)

export default connectedDateRangeComponent
