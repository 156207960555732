import React from 'react'
import * as O from 'fp-ts/lib/Option'
import { remoteData } from '@sbizeul/fp-flow'

import { renderLoader, useEffectOnce } from 'helpers/react'

import { ChannelSettingsProps, ChannelInformation } from '../../types'
import { ChannelSettings } from './ChannelSettings'

import './ChannelSettings.css'

const RemoteChannelSettings: React.FunctionComponent<ChannelSettingsProps> = (props) => {
  useEffectOnce(() => { props.fetchChannelInfo(props.channelSlug) })

  const renderSuccess = (channelInformation: O.Option<ChannelInformation>) => (
    <ChannelSettings
      {...props}
      channelInformation={channelInformation}
    />
  )

  return remoteData.fold({
    Success: renderSuccess,
    NotAsked: () => renderLoader('Please wait...'),
    Loading: () => renderLoader('Please wait...'),
    Failure: () => renderSuccess(O.none)
  })(props.channelInfo)
}

export {
  RemoteChannelSettings
}
