import React from 'react'
import { MdSave } from 'react-icons/md'

import { BASE_SKILL_LABELS } from 'modules/skill/types'

import Button from 'components/Button'
import BackButton from 'components/BackButton'
import { Error } from '../../components/SaveErrorMessage'

import './styles.css'

function QnAHeader(props) {
  return (
    <div className='Header'>
      <BackButton label={BASE_SKILL_LABELS[props.label] || props.label} />
      <Button onClick={props.save} className='primary Header--saveButton' disabled={!props.canItBeSaved}>
        <MdSave className='Header--icon' size='20' />
        SAVE
      </Button>
      {
        !props.isValidSkill && !BASE_SKILL_LABELS[props.label] && (
          <div className='Header--saveButtonError'>
            <Error header={props.saveInfo}/>
          </div>
        )
      }
    </div>

  )
}

export default QnAHeader
