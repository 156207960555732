import * as React from 'react'
import classNames from 'classnames'
import onClickOutside from 'react-onclickoutside'
import { VelocityComponent } from 'velocity-react'
import { FaCaretDown } from 'react-icons/fa'

import TextInput from '../TextInput'
import Tag from '../Tag'
import Option from '../Option/Option'
import FormError from '../FormError'
import './styles.css'

export class MultiSelect extends React.Component {
  state = {
    isOpen: false,
    textFilter: ''
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  handleClickOutside = () => {
    this.setState({ isOpen: false })
  }

  addOrRemove = option => {
    const matchedValue = this.props.values.find(value => option.value === value)
    if (matchedValue) {
      return this.props.values.filter(value => matchedValue !== value)
    }
    return this.props.values.concat(option.value)
  }

  handleClickOption = option => {
    const values = this.addOrRemove(option)
    this.props.onChange(values)
  }

  handleChangeText = text => {
    this.setState({ textFilter: text })
  }

  filterOptions = option => {
    return option.text.toLowerCase().includes(this.state.textFilter.toLowerCase())
  }

  renderTag = option => {
    const values = this.props.values.filter(value => option.value !== value)
    const handleRemove = () => this.props.onChange(values)
    return <Tag className='MultiSelect-control--tag' text={option.text} onRemove={handleRemove} key={option.value} />
  }

  renderTags() {
    return this.props.options.filter(o => this.props.values.includes(o.value)).map(this.renderTag)
  }

  renderOption = (option, idx) => {
    return (
      <Option
        key={`multiselect_option_${option.value}`}
        option={option}
        striped={idx % 2 === 0}
        onClick={this.handleClickOption}
      />
    )
  }

  renderOptions = options => {
    return (
      <div className='MultiSelect-options' style={{ top: this.props.withSearch ? 32 : 0 }} data-testid='options'>
        {options.map(this.renderOption)}
      </div>
    )
  }

  renderPlaceholder(placeholder) {
    return <span className='MultiSelect-control--placeholder'>{placeholder}</span>
  }

  renderDropdown() {
    return (
      <div className='MultiSelect-wrapper'>
        {this.props.withSearch && (
          <TextInput
            className='MultiSelect-textInput'
            onChangeText={this.handleChangeText}
            value={this.state.textFilter}
            placeholder='search...'
            autoFocus
          />
        )}
        {this.renderOptions(this.props.options.filter(this.filterOptions))}
      </div>
    )
  }

  render() {
    return (
      <div className={classNames('MultiSelect', this.props.className)}>
        <div
          className={classNames('MultiSelect-control', {
            'MultiSelect-control--open': this.state.isOpen,
            'MultiSelect-control--error': this.props.error
          })}
        >
          {this.props.values.length ? this.renderTags() : this.renderPlaceholder(this.props.placeholder)}
          <VelocityComponent animation={{ rotateZ: this.state.isOpen ? '180deg' : '0deg' }} duration={100}>
            <FaCaretDown className='MultiSelect-control--faCaret' onClick={this.toggle} />
          </VelocityComponent>
        </div>
        {this.state.isOpen && this.renderDropdown()}
        {Boolean(this.props.error) && <FormError msg={this.props.error} className='MultiSelect-formError' />}
      </div>
    )
  }
}

export default onClickOutside(MultiSelect)
