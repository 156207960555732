import types from './types'

/* postRessource */
const postRessourceRequest = () => ({
  type: types.POST_RESSOURCE_REQUEST
})
const postRessourceSuccess = () => ({
  type: types.POST_RESSOURCE_SUCCESS
})
const postRessourceFailure = () => ({
  type: types.POST_RESSOURCE_FAILURE
})

/* getRessource */
const getRessourceRequest = () => ({
  type: types.GET_RESSOURCE_REQUEST
})
const getRessourceSuccess = payload => ({
  type: types.GET_RESSOURCE_SUCCESS,
  payload
})
const getRessourceFailure = () => ({
  type: types.GET_RESSOURCE_FAILURE
})

/* deleteRessource */
const deleteRessourceRequest = () => ({
  type: types.DELETE_RESSOURCE_REQUEST
})
const deleteRessourceSuccess = () => ({
  type: types.DELETE_RESSOURCE_SUCCESS
})
const deleteRessourceFailure = () => ({
  type: types.DELETE_RESSOURCE_FAILURE
})

/* editRessource */
const editRessourceRequest = () => ({
  type: types.PUT_RESSOURCE_REQUEST
})
const editRessourceSuccess = payload => ({
  type: types.PUT_RESSOURCE_SUCCESS,
  payload
})
const editRessourceFailure = () => ({
  type: types.PUT_RESSOURCE_FAILURE
})

const closeModals = payload => ({
  type: types.CLOSE_MODALS,
  payload
})

export default {
  postRessourceRequest,
  postRessourceSuccess,
  postRessourceFailure,

  getRessourceRequest,
  getRessourceSuccess,
  getRessourceFailure,

  deleteRessourceRequest,
  deleteRessourceSuccess,
  deleteRessourceFailure,

  editRessourceRequest,
  editRessourceSuccess,
  editRessourceFailure,

  closeModals
}
