import React from 'react'
import { FaLock, FaUnlock } from 'react-icons/fa'

import './styles.css'

function OpenState({ open }) {
  return <div className='OpenState'>{open ? <FaUnlock /> : <FaLock />}</div>
}

OpenState.defaultProps = {
  open: true
}

export default OpenState
