import types from './types'

const fetchIntentsRequest = () => ({
  type: types.INTENTS_FETCH_REQUEST
})

const fetchIntentsSuccess = payload => ({
  type: types.INTENTS_FETCH_SUCCESS,
  payload
})

const fetchIntentsFailure = payload => ({
  type: types.INTENTS_FETCH_FAILURE,
  payload
})

const getDiversityRequest = () => ({
  type: types.GET_DIVERSITY_REQUEST
})

const getDiversitySuccess = payload => ({
  type: types.GET_DIVERSITY_SUCCESS,
  payload
})

const getDiversityFailure = payload => ({
  type: types.GET_DIVERSITY_FAILURE,
  payload
})

const postIntentRequest = () => ({
  type: types.POST_INTENT_REQUEST
})

const postIntentSuccess = () => ({
  type: types.POST_INTENT_SUCCESS
})

const postIntentFailure = () => ({
  type: types.POST_INTENT_FAILURE
})

const putIntentRequest = () => ({
  type: types.POST_INTENT_REQUEST
})

const putIntentSuccess = () => ({
  type: types.POST_INTENT_SUCCESS
})

const putIntentFailure = () => ({
  type: types.POST_INTENT_FAILURE
})

const putIntentInfosRequest = () => ({
  type: types.PUT_INTENT_INFOS_REQUEST
})

const putIntentInfosSuccess = () => ({
  type: types.PUT_INTENT_INFOS_SUCCESS
})

const putIntentInfosFailure = () => ({
  type: types.PUT_INTENT_INFOS_FAILURE
})

const postIntentExampleRequest = () => ({
  type: types.POST_INTENT_EXAMPLE_REQUEST
})

const postIntentExampleSuccess = () => ({
  type: types.POST_INTENT_EXAMPLE_SUCCESS
})

const postIntentExampleFailure = () => ({
  type: types.POST_INTENT_EXAMPLE_FAILURE
})

const putIntentExampleRequest = () => ({
  type: types.POST_INTENT_EXAMPLE_REQUEST
})

const putIntentExampleSuccess = () => ({
  type: types.POST_INTENT_EXAMPLE_SUCCESS
})

const putIntentExampleFailure = () => ({
  type: types.POST_INTENT_EXAMPLE_FAILURE
})

const deleteIntentExampleRequest = () => ({
  type: types.POST_INTENT_EXAMPLE_REQUEST
})

const deleteIntentExampleSuccess = () => ({
  type: types.POST_INTENT_EXAMPLE_SUCCESS
})

const deleteIntentExampleFailure = () => ({
  type: types.POST_INTENT_EXAMPLE_FAILURE
})

const deleteIntentAnswerRequest = () => ({
  type: types.DELETE_INTENT_ANSWER_REQUEST
})

const deleteIntentAnswerSuccess = () => ({
  type: types.DELETE_INTENT_ANSWER_SUCCESS
})

const deleteIntentAnswerFailure = () => ({
  type: types.DELETE_INTENT_ANSWER_FAILURE
})

const fetchIntentDataRequest = () => ({
  type: types.INTENT_DATA_FETCH_REQUEST
})

const fetchIntentDataSuccess = payload => ({
  type: types.INTENT_DATA_FETCH_SUCCESS,
  payload
})

const fetchIntentDataFailure = () => ({
  type: types.INTENT_DATA_FETCH_FAILURE
})

const selectIntentLanguage = payload => ({
  type: types.SELECT_INTENT_LANGUAGE,
  payload
})

const fetchUserDataRequest = () => ({
  type: types.FETCH_USER_REQUEST
})

const fetchUserDataSuccess = payload => ({
  type: types.FETCH_USER_SUCCESS,
  payload
})

const fetchUserDataFailure = payload => ({
  type: types.FETCH_USER_FAILURE,
  payload
})

const postUserRequest = () => ({
  type: types.POST_USER_REQUEST
})

const postUserSuccess = () => ({
  type: types.POST_USER_SUCCESS
})

const postUserFailure = () => ({
  type: types.POST_USER_FAILURE
})

const editUserRequest = () => ({
  type: types.POST_USER_REQUEST
})

const editUserSuccess = () => ({
  type: types.POST_USER_SUCCESS
})

const editUserFailure = () => ({
  type: types.POST_USER_FAILURE
})

const postPasswordRequest = () => ({
  type: types.POST_PASSWORD_REQUEST
})

const postPasswordSuccess = payload => ({
  type: types.POST_PASSWORD_SUCCESS,
  payload
})

const postPasswordFailure = () => ({
  type: types.POST_PASSWORD_FAILURE
})

const createPasswordRequest = () => ({
  type: types.CREATE_PASSWORD_REQUEST
})

const createPasswordSuccess = () => ({
  type: types.CREATE_PASSWORD_SUCCESS
})

const createPasswordFailure = () => ({
  type: types.CREATE_PASSWORD_FAILURE
})

const toggleIntentRequest = () => ({
  type: types.INTENT_TOGGLE_REQUEST
})

const toggleIntentSuccess = () => ({
  type: types.INTENT_TOGGLE_SUCCESS
})

const toggleIntentFailure = () => ({
  type: types.INTENT_TOGGLE_FAILURE
})

const deleteIntentRequest = () => ({
  type: types.INTENT_DELETE_REQUEST
})

const deleteIntentSuccess = () => ({
  type: types.INTENT_DELETE_SUCCESS
})

const deleteIntentFailure = () => ({
  type: types.INTENT_DELETE_FAILURE
})

export default {
  fetchUserDataRequest,
  fetchUserDataSuccess,
  fetchUserDataFailure,

  fetchIntentsRequest,
  fetchIntentsSuccess,
  fetchIntentsFailure,

  getDiversityRequest,
  getDiversitySuccess,
  getDiversityFailure,

  postIntentRequest,
  postIntentSuccess,
  postIntentFailure,

  putIntentRequest,
  putIntentSuccess,
  putIntentFailure,

  putIntentInfosRequest,
  putIntentInfosSuccess,
  putIntentInfosFailure,

  postIntentExampleRequest,
  postIntentExampleSuccess,
  postIntentExampleFailure,

  putIntentExampleRequest,
  putIntentExampleSuccess,
  putIntentExampleFailure,

  deleteIntentExampleRequest,
  deleteIntentExampleSuccess,
  deleteIntentExampleFailure,

  deleteIntentAnswerRequest,
  deleteIntentAnswerSuccess,
  deleteIntentAnswerFailure,

  fetchIntentDataRequest,
  fetchIntentDataSuccess,
  fetchIntentDataFailure,

  selectIntentLanguage,

  postUserRequest,
  postUserSuccess,
  postUserFailure,

  editUserRequest,
  editUserSuccess,
  editUserFailure,

  postPasswordRequest,
  postPasswordSuccess,
  postPasswordFailure,

  createPasswordRequest,
  createPasswordSuccess,
  createPasswordFailure,

  toggleIntentRequest,
  toggleIntentSuccess,
  toggleIntentFailure,

  deleteIntentRequest,
  deleteIntentSuccess,
  deleteIntentFailure
}
