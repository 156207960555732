import types from './types'

const fetchWordsusersRequest = () => ({
  type: types.FETCH_WORDSUSERS_REQUEST
})

const fetchWordsusersSuccess = payload => ({
  type: types.FETCH_WORDSUSERS_SUCCESS,
  payload
})

const fetchWordsusersFailure = errors => ({
  type: types.FETCH_WORDSUSERS_NO_DATA,
  errors
})

export default {
  fetchWordsusersRequest,
  fetchWordsusersSuccess,
  fetchWordsusersFailure
}
