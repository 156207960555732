import styled from 'styled-components'

const LeftPanelStyled = styled.section`
  width: ${({ theme }) => theme.width.leftPanel}px;
  height: 100%;
  background-color: ${({ theme }) => theme.color.leftPanelBg};
  padding: ${({ theme }) => theme.padding.leftPanel}px;
  overflow: auto;
`

const Title = styled.h2`
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.defaultColor};
  &:not(:first-of-type) {
    margin-top: ${({ theme }) => theme.margin.top.leftPanelTitle}px;
  }
  margin-bottom: ${({ theme }) => theme.margin.bottom.leftPanelTitle}px;
  font-weight: 600;
  display: flex;
  align-items: center;
  > * {
    margin-left: ${({ theme }) => theme.margin.left.leftPanelTitleChildren}px;
  }
`

const SubSection = styled.section`
  margin-left: ${({ theme }) => theme.margin.left.leftPanelSubSection}px;
  margin-bottom: ${({ theme }) => theme.margin.bottom.leftPanelSubSection}px;
`

export { LeftPanelStyled, Title, SubSection }
