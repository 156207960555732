import * as React from 'react'
import Error from './Error'

class DismissibleErrorMessage extends React.Component {
  state = { visible: true }

  handleDismiss = () => {
    this.setState({ visible: false })
  }

  render() {
    return this.state.visible ? (
      <div>
        <Error header={this.props.header} content={this.props.content} onClose={this.handleDismiss} />
      </div>
    ) : null
  }
}

export default DismissibleErrorMessage
