import React from 'react'
import { SectionActionTitle } from '../MainContent'
import { ToggleSwitchStyled, SwitchStyled, ToggleValue } from './styles'

const ToggleSwitch = ({ onChange, selected, disabled }) => (
  <SectionActionTitle as='div'>
    <ToggleValue disabled={disabled} onClick={() => onChange(true)}>
      Now
    </ToggleValue>
    <ToggleSwitchStyled disabled={disabled} onClick={() => onChange(!selected)}>
      <SwitchStyled selected={selected} />
    </ToggleSwitchStyled>
    <ToggleValue disabled={disabled} onClick={() => onChange(false)}>
      Later
    </ToggleValue>
  </SectionActionTitle>
)

export default ToggleSwitch
