import { combineReducers } from 'redux'

import main from './main/reducers'
import global from './global/reducers'
import intents from './intents/reducers'
import analytics from './analytics/reducers'
import conversations from './conversations/reducers'
import training from './training/reducers'
import psa from './psadata/reducers'
import ressources from './ressources/reducers'
import analytic from 'modules/analytics'
import answer from 'modules/answer'
import answer_old from 'modules/answer_old'
import question from 'modules/question'
import user from 'modules/user'
import bot from 'modules/bot'
import log from 'modules/log'
import channel from 'modules/channel'
import channels from 'modules/channels'
import apps from 'modules/apps'
import conversation from 'modules/conversation'
import skill from 'modules/skill'
import admin from 'modules/admin'
import entity from 'modules/entity'
import keyword from 'modules/keyword'
import synonym from 'modules/synonym'
import synonym_old from 'modules/synonym_old'
import train from 'modules/train'
import auth from 'modules/auth'
import intent from 'modules/intent'
import qa from 'modules/qa'
import broadcast from 'modules/broadcast'
import notifications from 'modules/notifications/reducer'
import trainingQuestion from 'modules/trainingQuestion'
import monitor from 'modules/monitor'
import register from 'modules/register'

const reducers = {
  main,
  global,
  analytics,
  psa,
  conversations,
  training,
  ressources,
  broadcast: broadcast.reducer,
  intents,
  answer_old: answer_old.reducer,
  user: user.reducer,
  log: log.reducer,
  channel: channel.reducer,
  conversation: conversation.reducer,
  admin: admin.reducer,
  ui: combineReducers({
    answer: answer.reducers.ui.default,
    bot: bot.reducers.ui.default,
    channels: channels.reducers.ui.default,
    apps: apps.reducers.ui.default,
    entity: entity.reducers.ui.default,
    keyword: keyword.reducers.ui.default,
    notifications,
    qa: qa.reducers.ui.default,
    question: question.reducers.ui.default,
    train: train.reducer,
    trainingQuestions: trainingQuestion.reducers.ui.default,
    register: register.reducers.ui.default,
    skill: skill.reducers.ui.default
  }),
  entities: combineReducers({
    analytics: analytic.reducers.entity.default,
    answer: answer.reducers.entity.default,
    bot: bot.reducers.entity.default,
    channels: channels.reducers.entity.default,
    apps: apps.reducers.entity.default,
    entity: entity.reducers.entity.default,
    keyword: keyword.reducers.entity.default,
    monitor: monitor.reducers.entity.default,
    qa: qa.reducers.entity.default,
    question: question.reducers.entity.default,
    skill: skill.reducers.entity.default,
    synonym: synonym.reducers.entity.default,
    synonym_old: synonym_old.reducers.entity.default,
    trainingQuestions: trainingQuestion.reducers.entity.default
  }),
  auth: auth.reducer.default,
  intent: intent.reducer
}

export default reducers
