import { Trackable, ServerFailure, Id } from 'types'
import { RemoteData, Maybe, Either } from '@sbizeul/fp-flow'
import { Map } from 'helpers/map'
import {
  AddSynonymFailure, AddSynonymRequest, AddSynonymSuccess,
  DeleteSynonymFailure, DeleteSynonymRequest, DeleteSynonymSuccess,
  UpdateSynonymFailure, UpdateSynonymRequest, UpdateSynonymSuccess
} from 'modules/synonym/types'

export type AppState = {
  entities: {
    keyword: KeywordEntitytState
  },
  ui: {
    keyword: KeywordUiState
  }
}

export type KeywordEntitytState = Readonly<{
  all: RemoteData<ServerFailure, Map<TrackableKeyword>>,
  byId: Map<RemoteData<ServerFailure, TrackableKeyword>>
}>

export type ActionType = Either<string, boolean>

export type KeywordUiState = Readonly<{
  selectedKeywordId: Maybe<string>,
  linkedKeywordElement: RemoteData<ServerFailure, LinkedElements>
  isKeywordCreating: Either<string, boolean>,
  isOpenCreateForm: boolean,
  isSynonymAdding: ActionType,
  isDeletingSynonym: Either<string, boolean>
}>

export type SynonymCountMap = { [language: string]: number }

export type ShortKeyword = Readonly<{
  name: string
}>

export type Keyword = ShortKeyword & Readonly<{
  synonyms: SynonymCountMap
  persisted?: boolean
  id?: string
}>

export type Keywords = RemoteData<ServerFailure, ReadonlyArray<TrackableKeyword>>

export type TrackableKeyword = Trackable & Keyword

export type LinkedElement = {
  type: 'advanced-skill' | 'QA'
  name: string
  id: string
}

export type LinkedElements = RemoteData<ServerFailure, ReadonlyArray<LinkedElement>>

export type LinkedElementsFromApi = ReadonlyArray<LinkedElement>

export const FETCH_ONE_REQUEST = 'keyword/FETCH_ONE_REQUEST'

export const CREATE_REQUEST = 'keyword/CREATE_REQUEST'
export const CREATE_SUCCESS = 'keyword/CREATE_SUCCESS'
export const CREATE_FAILURE = 'keyword/CREATE_FAILURE'

export const FETCH_LINKED_KEYWORD_ELEMENT_REQUEST = 'keyword/FETCH_LINKED_KEYWORD_ELEMENT_REQUEST'
export const FETCH_LINKED_KEYWORD_ELEMENT_SUCCESS = 'keyword/FETCH_LINKED_KEYWORD_ELEMENT_SUCCESS'
export const FETCH_LINKED_KEYWORD_ELEMENT_FAILURE = 'keyword/FETCH_LINKED_KEYWORD_ELEMENT_FAILURE'

export const DELETE_KEYWORD_REQUEST = 'keyword/DELETE_KEYWORD_REQUEST'
export const DELETE_KEYWORD_SUCCESS = 'keyword/DELETE_KEYWORD_SUCCESS'
export const DELETE_KEYWORD_FAILURE = 'keyword/DELETE_KEYWORD_FAILURE'

export const UPDATE_PERSIST_KEYWORD_REQUEST = 'keyword/UPDATE_PERSIST_KEYWORD_REQUEST'
export const UPDATE_PERSIST_KEYWORD_SUCCESS = 'keyword/UPDATE_PERSIST_KEYWORD_SUCCESS'
export const UPDATE_PERSIST_KEYWORD_FAILURE = 'keyword/UPDATE_PERSIST_KEYWORD_FAILURE'

export type FetchOneRequest = Readonly<{
  type: typeof FETCH_ONE_REQUEST,
  payload: Id
}>

export type KeywordId = Id
export type FetchLinkedKeywordElementRequestParam = KeywordId

export type FetchLinkedKeywordElementRequest = Readonly<{
  type: typeof FETCH_LINKED_KEYWORD_ELEMENT_REQUEST
  payload: KeywordId
}>

export type FetchLinkedKeywordElementSuccessParam = LinkedElementsFromApi

export type FetchLinkedKeywordElementSuccess = Readonly<{
  type: typeof FETCH_LINKED_KEYWORD_ELEMENT_SUCCESS
  payload: FetchLinkedKeywordElementSuccessParam
}>

export type FetchLinkedKeywordElementFailureParam = ServerFailure

export type FetchLinkedKeywordElementFailure = Readonly<{
  type: typeof FETCH_LINKED_KEYWORD_ELEMENT_FAILURE
  payload: FetchLinkedKeywordElementFailureParam
}>

export type DeleteKeywordRequestParam = KeywordId

export type DeleteKeywordRequest = Readonly<{
  type: typeof DELETE_KEYWORD_REQUEST
  payload: KeywordId
}>

export type DeleteKeywordSuccessParam = Boolean

export type DeleteKeywordSuccess = Readonly<{
  type: typeof DELETE_KEYWORD_SUCCESS
  payload: DeleteKeywordSuccessParam
}>

export type DeleteKeywordFailureParam = ServerFailure

export type DeleteKeywordFailure = Readonly<{
  type: typeof DELETE_KEYWORD_FAILURE
  payload: DeleteKeywordFailureParam
}>

export type UpdatePersistKeywordStatusRequestParam = TrackableKeyword

export type UpdatePersistKeywordStatusRequest = Readonly<{
  type: typeof UPDATE_PERSIST_KEYWORD_REQUEST
  payload: UpdatePersistKeywordStatusRequestParam
}>

export type UpdatePersistKeywordStatusSuccess = Readonly<{
  type: typeof UPDATE_PERSIST_KEYWORD_SUCCESS
}>

export type UpdatePersistKeywordStatusFailureParam = ServerFailure

export type UpdatePersistKeywordStatusFailure = Readonly<{
  type: typeof UPDATE_PERSIST_KEYWORD_FAILURE
  payload: UpdatePersistKeywordStatusFailureParam
}>

export type SelectKeyword = Readonly<{
  type: 'keyword/SELECT_KEYWORD',
  payload: string
}>

export type UnselectKeyword = Readonly<{
  type: 'keyword/UNSELECT_KEYWORD'
}>

export type OpenCreateForm = Readonly<{
  type: 'keyword/OPEN_CREATE_FORM'
}>

export type CloseCreateForm = Readonly<{
  type: 'keyword/CLOSE_CREATE_FORM'
}>

export type CreateRequest = Readonly<{
  type: typeof CREATE_REQUEST,
  payload: ShortKeyword
}>

export type CreateSuccess = Readonly<{
  type: typeof CREATE_SUCCESS ,
  payload: TrackableKeyword
}>

export type CreateFailure = Readonly<{
  type: typeof CREATE_FAILURE,
  payload: ServerFailure
}>

export type KeywordUiAction =
  | FetchLinkedKeywordElementRequest
  | FetchLinkedKeywordElementSuccess
  | FetchLinkedKeywordElementFailure
  | SelectKeyword
  | UnselectKeyword
  | OpenCreateForm
  | CloseCreateForm
  | CreateRequest
  | CreateSuccess
  | CreateFailure
  | AddSynonymRequest
  | AddSynonymSuccess
  | AddSynonymFailure
  | DeleteSynonymFailure
  | DeleteSynonymRequest
  | DeleteSynonymSuccess
  | UpdateSynonymRequest
  | UpdateSynonymSuccess
  | UpdateSynonymFailure
