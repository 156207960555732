const FETCH_MESSAGES_REQUEST = 'tcfdash/analytics/FETCH_MESSAGES_REQUEST'
const FETCH_MESSAGES_SUCCESS = 'tcfdash/analytics/FETCH_MESSAGES_SUCCESS'
const FETCH_MESSAGES_NO_DATA = 'tcfdash/analytics/FETCH_MESSAGES_NO_DATA'

const FETCH_MESSAGES_PREVIOUS_PERIOD_REQUEST = 'tcfdash/analytics/FETCH_MESSAGES_PREVIOUS_PERIOD_REQUEST'
const FETCH_MESSAGES_PREVIOUS_PERIOD_SUCCESS = 'tcfdash/analytics/FETCH_MESSAGES_PREVIOUS_PERIOD_SUCCESS'
const FETCH_MESSAGES_PREVIOUS_PERIOD_NO_DATA = 'tcfdash/analytics/FETCH_MESSAGES_PREVIOUS_PERIOD_NO_DATA'

const FETCH_MESSAGES_COUNT_BY_HOUR_REQUEST = 'tcfdash/analytics/FETCH_MESSAGES_COUNT_BY_HOUR_REQUEST'
const FETCH_MESSAGES_COUNT_BY_HOUR_SUCCESS = 'tcfdash/analytics/FETCH_MESSAGES_COUNT_BY_HOUR_SUCCESS'
const FETCH_MESSAGES_COUNT_BY_HOUR_NO_DATA = 'tcfdash/analytics/FETCH_MESSAGES_COUNT_BY_HOUR_NO_DATA'

const FETCH_MESSAGES_AVERAGE_BY_DAY_REQUEST = 'tcfdash/analytics/FETCH_MESSAGES_AVERAGE_BY_DAY_REQUEST'
const FETCH_MESSAGES_AVERAGE_BY_DAY_SUCCESS = 'tcfdash/analytics/FETCH_MESSAGES_AVERAGE_BY_DAY_SUCCESS'
const FETCH_MESSAGES_AVERAGE_BY_DAY_NO_DATA = 'tcfdash/analytics/FETCH_MESSAGES_AVERAGE_BY_DAY_NO_DATA'

export default {
  FETCH_MESSAGES_REQUEST,
  FETCH_MESSAGES_SUCCESS,
  FETCH_MESSAGES_NO_DATA,
  FETCH_MESSAGES_PREVIOUS_PERIOD_REQUEST,
  FETCH_MESSAGES_PREVIOUS_PERIOD_SUCCESS,
  FETCH_MESSAGES_PREVIOUS_PERIOD_NO_DATA,
  FETCH_MESSAGES_COUNT_BY_HOUR_REQUEST,
  FETCH_MESSAGES_COUNT_BY_HOUR_SUCCESS,
  FETCH_MESSAGES_COUNT_BY_HOUR_NO_DATA,
  FETCH_MESSAGES_AVERAGE_BY_DAY_REQUEST,
  FETCH_MESSAGES_AVERAGE_BY_DAY_SUCCESS,
  FETCH_MESSAGES_AVERAGE_BY_DAY_NO_DATA
}
