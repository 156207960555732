import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import momentz from 'moment-timezone'
import { DateRangePicker, isInclusivelyAfterDay } from 'react-dates'
import { Button } from 'semantic-ui-react'
import { maybe } from '@sbizeul/fp-flow'
import 'react-dates/lib/css/_datepicker.css'
import { v4 as uuid } from 'uuid'

import stripDateDetails from '../../../../redux/_shared/stripDateDetails'
import * as operations from '../../../../redux/training/operations'
import bot from '../../../../modules/bot'
import './react_date_overrides.css'

const availableRange = (day, allowedRange) =>
  isInclusivelyAfterDay(day, allowedRange.end) || isInclusivelyAfterDay(allowedRange.start, day)

class DateRangeComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      focusedInput: undefined,
      allowedRange: {
        start: moment('2017-11-12'),
        end: stripDateDetails(momentz.tz('Europe/Paris').add(1, 'd'))
      }
    }
  }

  getBotId() {
    return maybe.getOrElse(() => 'unknownBotId', this.props.botId)
  }

  render() {
    const {
      setDateRange2,
      activeChannelId,
      fetch,
      setDateRangeDropDown,
      dateRangeStart,
      dateRangeEnd,
      language,
      activeIntent
    } = this.props

    return (
      <DateRangePicker
        startDateId={uuid()}
        endDateId={uuid()}
        displayFormat='MMM D'
        minimumNights={0}
        hideKeyboardShortcutsPanel
        initialVisibleMonth={() => stripDateDetails(momentz.tz('Europe/Paris').subtract(1, 'month'))}
        isOutsideRange={day => availableRange(day, this.state.allowedRange)}
        startDate={dateRangeStart}
        endDate={dateRangeEnd}
        onDatesChange={({ startDate, endDate }) => setDateRange2({ startDate, endDate })}
        focusedInput={this.state.focusedInput}
        onFocusChange={focusedInput => this.setState({ focusedInput })}
        showClearDates
        showDefaultInputIcon
        firstDayOfWeek={1}
        reopenPickerOnClearDates
        keepOpenOnDateSelect
        renderCalendarInfo={() => (
          <div
            style={{
              padding: '10px',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center'
            }}
          >
            <Button
              color='teal'
              size='tiny'
              value='year'
              onClick={(e, { value }) =>
                setDateRangeDropDown({
                  duration: value,
                  yesterday: stripDateDetails(momentz.tz('Europe/Paris'))
                })
              }
              style={{
                fontFamily: 'Roboto',
                fontWeight: 'lighter'
              }}
            >
              Last year
            </Button>
            <Button
              color='teal'
              size='tiny'
              value='m'
              onClick={(e, { value }) =>
                setDateRangeDropDown({
                  duration: value,
                  yesterday: stripDateDetails(momentz.tz('Europe/Paris'))
                })
              }
              style={{
                fontFamily: 'Roboto',
                fontWeight: 'lighter'
              }}
            >
              Last month
            </Button>
            <Button
              color='teal'
              size='tiny'
              value='w'
              onClick={(e, { value }) =>
                setDateRangeDropDown({
                  duration: value,
                  yesterday: stripDateDetails(momentz.tz('Europe/Paris'))
                })
              }
              style={{
                fontFamily: 'Roboto',
                fontWeight: 'lighter'
              }}
            >
              Last week
            </Button>
            <Button
              color='teal'
              size='tiny'
              value='y'
              onClick={(e, { value }) =>
                setDateRangeDropDown({
                  duration: value,
                  yesterday: stripDateDetails(momentz.tz('Europe/Paris'))
                })
              }
              style={{
                fontFamily: 'Roboto',
                fontWeight: 'lighter'
              }}
            >
              Yesterday
            </Button>
            <Button
              color='teal'
              size='tiny'
              value='d'
              onClick={(e, { value }) => {
                setDateRangeDropDown({
                  duration: value,
                  yesterday: stripDateDetails(momentz.tz('Europe/Paris'))
                })
              }}
              style={{
                fontFamily: 'Roboto',
                fontWeight: 'lighter'
              }}
            >
              Today
            </Button>
            <div
              style={{
                marginLeft: 'auto'
              }}
            >
              <Button
                id='filter'
                onClick={() => {
                  fetch(activeChannelId, { start: dateRangeStart, end: dateRangeEnd }, language, activeIntent)
                  this.setState({ focusedInput: undefined })
                }}
                compact
                size='medium'
                color='blue'
                style={{
                  fontFamily: 'Roboto'
                }}
              >
                Apply
              </Button>
            </div>
          </div>
        )}
      />
    )
  }
}

const mapStateToProps = state => ({
  filters: state.training.getIn(['filters']),
  dateRange: state.training.getIn(['filters', 'dateRange']),
  dateRangeStart: state.training.getIn(['filters', 'dateRange', 'start']),
  dateRangeEnd: state.training.getIn(['filters', 'dateRange', 'end']),
  language: state.training.getIn(['filters', 'language', 'id']),
  activeIntent: state.training.getIn(['filters', 'intent', 'id']),
  botId: bot.selectors.getSelectedId(state),
  activeChannelId: state.main.filters.channel.id,
  periods: state.main.filters.periods,
  activePeriod: ['minutes', 'hours', 'days'][state.main.filters.periods.active]
})

const connectedDateRangeComponent = connect(mapStateToProps, operations)(DateRangeComponent)

export default connectedDateRangeComponent
