import * as React from 'react'
import { remoteData, pipe } from '@sbizeul/fp-flow'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import * as reactComponent from '@tolk-ai/react-components'
import '@tolk-ai/react-components/dist/react-components.esm.css'

import * as browserUtils from '@tolk-genii/browser-utils'
import * as authentication from '@tolk-genii/identity-and-access-management-authentication'
import axios from 'axios'
import Loader from '../../../views/layouts/Loader'
import * as authSelectors from '../selectors'
import { authManagementBaseUrl, accountManagementReactBaseUrl, oauthClientId, oauthRedirectUri } from 'helpers/effects'

const axiosAuthManagementExpressBaseUrl = axios.create({
  baseURL: authManagementBaseUrl
})

const cookieDomain = (window)._env_['COOKIE_DOMAIN']

const LoginComponent = reactComponent.login.container(window,
  authentication.createAuthorizationInfos(
    authentication.authorizationInfosModel.authorizationInfosFactory(authManagementBaseUrl, window.btoa),
    authentication.localstorageAuthorizationInfosRepository.saveOneAuthorizationInfos(window.localStorage)
    ),
    authentication.completeAuthenticationProcess(
      authentication.localstorageAuthorizationInfosRepository.getOneAuthorizationInfos(window.localStorage),
      authentication.localstorageAuthorizationInfosRepository.deleteOneAuthorizationInfos(window.localStorage),
      authentication.axiosAuthenticationService.exchangeAuthorizationCodeWithAccessToken(axiosAuthManagementExpressBaseUrl),
      authentication.cookiesAccessTokenResponseRepository.saveOneAccessTokenResponse(browserUtils.setCookie(document, cookieDomain)),
      oauthClientId,
      oauthRedirectUri,
      'openid profile email role:tolk-admin'
      ),
    oauthClientId,
    oauthRedirectUri,
    browserUtils.refreshAccessToken(
      authManagementBaseUrl,
      browserUtils.removeCookie(document, cookieDomain),
      browserUtils.getCookie(document),
      browserUtils.setCookie(document, cookieDomain)
    )
  )

export const wrap = WrappedComponent => {
  return class AuthWrapper extends React.Component {

    render() {
      return remoteData.fold(
        {
          NotAsked: () => {
            const urlSearchParams = new URLSearchParams(window.location.search)
            const shopId = urlSearchParams.get('shopId')
            if (
              window.location.pathname === '/channels/shopify'
              & typeof shopId === 'string' && shopId.length > 0
            ) {
              window.location.assign(`${accountManagementReactBaseUrl}/shopify-signup?shopId=${shopId}`)
              return
            }

            return <LoginComponent />
          },
          Failure: error => {
            console.error('login failure', error)
            return <LoginComponent />
          },
          Loading: () => {
            return <Loader message='Loading... please wait' />
          },
          Success: () => {
            return <WrappedComponent />
          }
        },
        this.props.token
      )
    }
  }
}

const mapStateToProps = state => ({ token: authSelectors.getToken(state) })

export const withAuth = pipe(wrap, connect(mapStateToProps), withRouter)
