import React from 'react'
import { Segment, Icon } from 'semantic-ui-react'

const style = {
  borderRadius: '3px',
  border: 'none',
  boxShadow: '0 2px 4px rgba(50, 50, 93,.1)',
  minHeight: '300px',
  padding: '1em 0em',
  paddingTop: '115px'
}

export default function NoDataSegment() {
  return (
    <Segment textAlign='center' style={style}>
      <Icon name='sliders' style={{ fontSize: '30px', color: '#3B86FF' }} />
      <h2>No data available... try to broaden filters!</h2>
    </Segment>
  )
}
