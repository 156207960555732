import * as React from 'react'
import classNames from 'classnames'

import Button from '../Button'
import './styles.css'

export default function Form(props) {
  const handleSubmit = e => {
    e.preventDefault()
    props.onSubmit(e)
  }

  return (
    <form onSubmit={handleSubmit} className={classNames('Form', props.className)}>
      {props.children}
      <div className='Form-submitBar'>
        <Button data-testid='cancel' onClick={props.onCancel}>
          Cancel
        </Button>
        <Button className='Form-submitBar--submit primary' type='submit' disabled={!props.isSubmittable}>
          Submit
        </Button>
      </div>
    </form>
  )
}
