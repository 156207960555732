import styled, { css } from 'styled-components'
import { initCss } from '../../style/globalStyles'

const cssForCarousel = css`
  border: none;
  border-radius: unset;
  width: 100%;
  min-height: unset;
  line-height: 1em;
  min-width: unset;
  max-width: unset;
  padding-left: 0;
  padding-right: 0;
  /* Description */
  &:last-of-type {
    /* font-size: 0.85em; */
    font-weight: 300;
  }
`

const cssForMaxLength = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const cssForError = css`
  border-color: red !important;
  &::after {
    content: "${props => props.error}";
    position: absolute;
    width: 300px;
    bottom: ${props => {
    if (props.rootElementText) return -21
    if (props.buttonContainer) return -11
    return -7
  }}px;
    left: 1.5em;
    font-size: 9px;
    font-weight: 400;
    color: red;
    z-index: 10;
  }
  &::before {
    content: "!";
    border: 1px solid red;
    border-radius: 100%;
    width: 1em;
    height: 1em;
    position: absolute;
    bottom: ${props => {
    if (props.rootElementText) return -11
    if (props.buttonContainer) return -6
    return -4
  }}px;
    left: 0;
    font-size: 9px;
    font-weight: 400;
    color: red;
    line-height: 1em;
    text-indent: 0.25em;
    z-index: 10;
  }
`

const ElementTextContainer = styled.div`
  ${initCss}
  position: relative;
  min-height: 2em;
  max-height: 6em;
  min-width: ${({ minLength }) => minLength * 1.3}ch;
  max-width: ${({ theme, letters }) => Math.min(letters, theme.width.elementText)}ch;

  ${props => props.error && cssForError}

  ${props => props.withMaxLength && cssForMaxLength}


  font-weight: 400;
  font-size: 1em;
  padding: 0.5em;
  line-height: ${({ height, computedFontSize }) => (height > 2 * computedFontSize ? '1.3em' : '2em')};

  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;

  background: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.borderRadius.titleInput}px;
  border: 1px solid ${({ theme }) => theme.color.grey};
  /* overflow: hidden; */

  .DraftEditor-root {
    flex-grow: 1;
  }
  .public-DraftEditorPlaceholder-root {
    font-style: italic;
    position: absolute;
    opacity: 0.5;
  }

  ${props => props.forCarousel && cssForCarousel}
`

export const CurrentLengthContainer = styled.div`
  font-size: 0.75em;
  height: 1rem;
  flex-grow: 0;
  flex-shrink: 0;
`

export default ElementTextContainer
