import { Either, Maybe, RemoteData } from '@sbizeul/fp-flow'
import * as moment from 'moment'

import { ServerFailure } from '../../types'

export const FETCH_CONVERSATIONS_REQUEST = 'conversation/FETCH_CONVERSATIONS_REQUEST'
export const FETCH_CONVERSATIONS_FAILURE = 'conversation/FETCH_CONVERSATIONS_FAILURE'
export const FETCH_CONVERSATIONS_SUCCESS = 'conversation/FETCH_CONVERSATIONS_SUCCESS'

export const SELECT = 'conversation/SELECT'

export const CHANGE_DATES = 'conversation/CHANGE_DATES'

export const NEXT_PAGE = 'conversation/NEXT_PAGE'
export const PREVIOUS_PAGE = 'conversation/PREVIOUS_PAGE'
export const FIRST_PAGE = 'conversation/FIRST_PAGE'

export const SEARCH = 'conversation/SEARCH'

export const CHANGE_SEARCH_VALUE = 'conversation/CHANGE_SEARCH_VALUE'

export const CHANGE_NUMBER_CONVERSATIONS_PER_PAGE = 'conversation/CHANGE_NUMBER_CONVERSATIONS_PER_PAGE'

export const DOWNLOAD_CSV_REQUEST = 'conversation/DOWNLOAD_CSV_REQUEST'
export const DOWNLOAD_CSV_FAILURE = 'conversation/DOWNLOAD_CSV_FAILURE'
export const DOWNLOAD_CSV_SUCCESS = 'conversation/DOWNLOAD_CSV_SUCCESS'

export type Message = Readonly<{
  text: string,
  date: string,
  is_bot: boolean,
  type?: string,
  quick_replies?: any,
  payload?: any
}>

export type Conversation = Readonly<{
  id: string,
  message: Message[]
}>

export type SortableConversation = Conversation & Readonly<{
  lastDateInRange: string
}>

export type ConversationState = Readonly<{
  startDate: moment.Moment,
  endDate: moment.Moment,
  nbResultsPerPage: number,
  channelID: Maybe<string>,
  page: number,
  lang: string,
  conversations: RemoteData<ServerFailure, SortableConversation[]>,
  selectedID: Maybe<string>,
  searchValue: string,
  isDownloadingCSV: Either<ServerFailure, boolean>
}>

export type AppState = {
  conversation: ConversationState
}

export type RouteLocation = {
  path: string,
  url: string,
  isExact: boolean,
  params: null
}

export type FetchConversationsSuccess = {
  type: typeof FETCH_CONVERSATIONS_SUCCESS
  payload: ReadonlyArray<Conversation>
}

export type FetchConversationsFailure = {
  type: typeof FETCH_CONVERSATIONS_FAILURE
  payload: ServerFailure
}

export type FetchConversationsRequest = {
  type: typeof FETCH_CONVERSATIONS_REQUEST
}

export type ChangeDates = {
  type: typeof CHANGE_DATES
  payload: { startDate: moment.Moment, endDate: moment.Moment }
}

export type Select = {
  type: typeof SELECT
  payload: string
}

export type NextPage = {
  type: typeof NEXT_PAGE
}

export type PreviousPage = {
  type: typeof PREVIOUS_PAGE
}

export type FirstPage = {
  type: typeof FIRST_PAGE
}

export type Search = {
  type: typeof SEARCH
}

export type ChangeSearchValue = {
  type: typeof CHANGE_SEARCH_VALUE
  payload: string
}

export type ChangeNumberConversationsPerPage = {
  type: typeof CHANGE_NUMBER_CONVERSATIONS_PER_PAGE
  payload: number
}

export type DownloadCSVRequest = {
  type: typeof DOWNLOAD_CSV_REQUEST
}

export type DownloadCSVFailure = {
  type: typeof DOWNLOAD_CSV_FAILURE
  payload: ServerFailure
}

export type DownloadCSVSuccess = {
  type: typeof DOWNLOAD_CSV_SUCCESS
}

export type ConversationAction =
  | FetchConversationsFailure
  | FetchConversationsRequest
  | FetchConversationsSuccess
  | Select
  | ChangeDates
  | NextPage
  | PreviousPage
  | FirstPage
  | Search
  | ChangeSearchValue
  | ChangeNumberConversationsPerPage
  | DownloadCSVRequest
  | DownloadCSVFailure
  | DownloadCSVSuccess
