import React from 'react'
import PropTypes from 'prop-types'

const TextIcon = ({ width, height, fill }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 56 53'>
      <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Push-Copy-2' transform='translate(-854.000000, -620.000000)' fill={fill} fillRule='nonzero'>
          <g id='speech-bubble-with-text-lines' transform='translate(854.890625, 620.525257)'>
            <path
              d='M44.9978606,0.0352043238 L9.56639235,0.0352043238 C4.29140708,0.0352043238 0,4.32676446 0,9.60174973 L0,31.4559817 C0,36.730967 4.29140708,41.0223741 9.56639235,41.0223741 L32.1343537,41.0225271 L42.9376423,51.8256627 C43.3037673,52.1919407 43.7929543,52.3861767 44.291019,52.3861767 C44.5374493,52.3861767 44.7860224,52.3384213 45.0226567,52.2403084 C45.7376106,51.9442859 46.2038383,51.2469342 46.2038383,50.4728983 L46.2038383,40.9467613 C50.9118809,40.3521144 54.5642529,36.3225968 54.5642529,31.4559817 L54.5642529,9.60174973 C54.5642529,4.32676446 50.2728459,0.0352043238 44.9978606,0.0352043238 Z M50.737696,31.4561348 C50.737696,34.6211566 48.1628824,37.1959702 44.9978606,37.1959702 L44.2905598,37.1959702 C43.2339709,37.1959702 42.3772813,38.0528128 42.3772813,39.1092487 L42.3772813,45.8537849 L34.2798277,37.7563312 C33.9210497,37.3974001 33.4346178,37.1959702 32.9269102,37.1959702 L9.56639235,37.1959702 C6.40137057,37.1959702 3.82655694,34.6211566 3.82655694,31.4561348 L3.82655694,9.60190279 C3.82655694,6.43688102 6.40137057,3.86206739 9.56639235,3.86206739 L44.9978606,3.86206739 C48.1628824,3.86206739 50.737696,6.43688102 50.737696,9.60190279 L50.737696,31.4561348 Z'
              id='Shape'
            ></path>
            <path
              d='M41.363856,13.5832057 L13.2003969,13.5832057 C12.143808,13.5832057 11.2871185,14.4397422 11.2871185,15.4964842 C11.2871185,16.5530731 12.143808,17.4097626 13.2003969,17.4097626 L41.363856,17.4097626 C42.4204449,17.4097626 43.2771345,16.5530731 43.2771345,15.4964842 C43.2771345,14.4397422 42.420598,13.5832057 41.363856,13.5832057 Z'
              id='Shape'
            ></path>
            <path
              d='M41.363856,24.2975651 L13.2003969,24.2975651 C12.143808,24.2975651 11.2871185,25.1541016 11.2871185,26.2108436 C11.2871185,27.2675856 12.143808,28.1241221 13.2003969,28.1241221 L41.363856,28.1241221 C42.4204449,28.1241221 43.2771345,27.2674325 43.2771345,26.2108436 C43.2771345,25.1542547 42.420598,24.2975651 41.363856,24.2975651 Z'
              id='Shape'
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

TextIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string
}

TextIcon.defaultProps = {
  width: '1.5em',
  height: '1.5em',
  fill: 'currentColor'
}

export default TextIcon
