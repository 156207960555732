/* ELEMENTS */

// Types
export const TEXT = 'text'
export const IMAGE = 'image'
export const VIDEO = 'video'
export const CAROUSEL = 'carousel'
export const URL = 'url'
export const POSTBACK = 'postback'

export const ElementTypes = {
  [TEXT]: TEXT,
  [IMAGE]: IMAGE,
  [VIDEO]: VIDEO,
  [CAROUSEL]: CAROUSEL,
  [URL]: URL
}

export const ButtonTypes = {
  [URL]: URL,
  [POSTBACK]: POSTBACK
}

// Analytics

// Structure

/* CAMPAIGN */

// Campaign status
export const DRAFT = 'draft'
export const TO_REVIEW = 'to-review'
export const SCHEDULED = 'scheduled'
export const SENT = 'sent'

export const CampaignStatus = {
  [DRAFT]: DRAFT,
  [TO_REVIEW]: TO_REVIEW,
  [SCHEDULED]: SCHEDULED,
  [SENT]: SENT
}

// Cohorts

// Structure

/* STATE */

/* SAGA ACTIONS */

/* ACTIONS */

/* COMMON COMPONENTS */
