import { connect } from 'react-redux'

import answer from 'modules/answer'

import * as actions from '../actions'
import QaAnswerPage from '../components/QaAnswerPage'
import * as selectors from '../selectors'
import * as routes from '../routes'

const mapStateToProps = state => ({
  route: routes.QA_QUESTIONS,
  questionId: selectors.getQuestionId(state),
  qna: selectors.getById(state),
  answer: answer.selectors.getAnswer(state)
})

const mapDispatchToProps = {
  fetchQnA: actions.fetchOne,
  saveQnAn: actions.saveOne
}

export default connect(mapStateToProps, mapDispatchToProps)(QaAnswerPage)
