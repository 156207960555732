import { connect } from 'react-redux'

import keyword from 'modules/keyword'
import { Keywords } from 'modules/keyword/types'

import * as actions from '../actions'
import * as selectors from '../selectors'
import { AppState } from '../types'
import SkillBuilder from '../components/SkillBuilder'
import { DispatchFromProps, StateFromProps } from '../components/SkillBuilder/types'

const mapStateToProps = (state: AppState) => ({
  isModified: selectors.getIsModified(state),
  keywords: keyword.selectors.getAll(state) as Keywords,
  skill: selectors.getSkill(state),
  skillId: selectors.getSkillId(state)
})

const mapDispatchToProps = {
  createQuestionContent: actions.createQuestionContent,
  createScenario: actions.createScenario,
  fetch: actions.fetchSkill,
  fetchKeywords: keyword.actions.fetchKeywordsRequest,
  removeQuestionContent: actions.removeQuestionContent,
  removeScenario: actions.removeScenario,
  saveSkill: actions.saveSkillRequest,
  updateAnswerContent: actions.updateAnswerContent,
  updateQuestion: actions.updateQuestion,
  updateQuestionContent: actions.updateQuestionContent
}

export default connect<StateFromProps, DispatchFromProps, void, AppState>(mapStateToProps, mapDispatchToProps)(SkillBuilder)
