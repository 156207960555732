import React from 'react'
import { FaPlus } from 'react-icons/fa'

import Button from 'components/Button'
import { Props as ButtonProps } from '../Button/Button'

import './styles.css'

const AddButton: React.FunctionComponent<ButtonProps> = props => (
  <Button {...props} className='primary'>
    <FaPlus className='AddButton-plusIcon'/> {props.children}
  </Button>
)

export default AddButton
