import * as React from 'react'
import { ConnectedRouter } from 'connected-react-router'
import { History } from 'history'

import ScrollManager from 'helpers/scroll-manager/ScrollManager'

export const withConnectedRouter = (history: History) => (WrappedComponent: React.ComponentType) => {
  return class ConnectedRouterEnhancer extends React.Component {
    render () {
      return (
        <ConnectedRouter history={history}>
          <ScrollManager elementID='App-content'>
            <WrappedComponent/>
          </ScrollManager>
        </ConnectedRouter>
      )
    }
  }
}
