import * as actions from './actions'
import * as entity from './reducer.entity'
import * as ui from './reducer.ui'
import * as saga from './saga'
import * as selectors from './selectors'
import EntityIndex from './containers/EntityIndex'

export default {
  actions,
  reducers: {
    entity,
    ui
  },
  saga,
  selectors,
  components: {
    EntityIndex
  }
}
