import React, { Component } from 'react'
import 'react-dates/initialize'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'

import log from '../../../modules/log'
import MainUserContent from './Main'
import operations from '../../../redux/intents/operations'
import admin from '../../../modules/admin'

class AdminPage extends Component {
  render() {
    return (
      <div className='Admin pageContainer'>
        <Switch>
          <Route exact path='/admin/user' component={MainUserContent} />
          <Route exact path='/admin/user/new' component={admin.components.UserFormContainer} />
          <Route exact path='/admin/user/edit/:userid' component={admin.components.UserFormContainer} />
          <Route exact path='/admin/logs' component={log.components.LogListContainer} />
        </Switch>
      </div>
    )
  }
}

const AdminContentPage = connect(null, operations)(AdminPage)

export default AdminContentPage
