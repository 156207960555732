import React from 'react'
import ReactHighcharts from 'react-highcharts'
import isEmpty from 'lodash/isEmpty'
import defaultStyle from '../chartStyle'
import NoDataSegment from '../NoDataSegment'

const config = {
  ...defaultStyle,
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: 'pie'
  },
  tooltip: {
    pointFormat: '{series.name}: <b>{point.percentage:.1f}% ({point.y} Users)</b>',
    shared: true
  },
  plotOptions: {
    pie: {
      cursor: 'pointer',
      dataLabels: {
        enabled: false
      },
      showInLegend: true
    }
  }
}

export default function GlobalSatisfaction({ satisfaction }) {
  if (isEmpty(satisfaction)) {
    return <NoDataSegment />
  }

  const value = Object.values(satisfaction)
  const data = []

  Object.keys(satisfaction).forEach((el, index) => {
    data.push([el, value[index]])
  })

  config.series = [
    {
      type: 'pie',
      animation: false,
      name: 'Global Satisfaction',
      data
    }
  ]

  return <ReactHighcharts config={config} domProps={{ id: 'GlobalSatisfaction' }} />
}
