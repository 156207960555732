
import { connect } from 'react-redux'

import * as actions from '../actions'
import * as selectors from '../selectors'
import MatchedQuestions from '../components/MatchedQuestions'

const mapStateToProps = state => ({
  logs: selectors.getLogs(state),
  progress: selectors.getProgress(state)
})

const mapDispatchToProps = {
  fetchLogs: actions.fetchRequest,
  dispatchConfirmedQuestion: actions.confirmOne
}

const MatchedQuestionsContainer = connect(mapStateToProps, mapDispatchToProps)(MatchedQuestions)

export default MatchedQuestionsContainer
