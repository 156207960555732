import React from 'react'
import { FaRegClock } from 'react-icons/fa'
import { OutlinedUI, Indicator, OutlinedUIWithIndicator } from '..'
import { formatTitleDisplayed, formatDate } from '../../helpers'

const CampaignScheduled = ({ onClick, deleteCampaignRequest, selected, campaign }) => (
  <OutlinedUIWithIndicator>
    <OutlinedUI
      withTrash
      left
      value={formatTitleDisplayed(campaign)}
      selected={selected}
      onClick={() => onClick(campaign.id)}
      onTrashClick={() => deleteCampaignRequest(campaign.id)}
    />
    <Indicator value={formatDate(campaign.date)} Icon={FaRegClock} />
  </OutlinedUIWithIndicator>
)

export default CampaignScheduled
