import React from 'react'
import styled from 'styled-components'
import { CarouselProvider, Slider, Slide, DotGroup } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import { initButton } from '../../style/globalStyles'
import { ModaleMessageStyled } from '../Modale'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`

const DotGroupStyled = styled(DotGroup)`
  position: absolute;
  top: 150px;
  display: flex;
  width: 100%;
  justify-content: center;
  button {
    ${initButton}
    background-color: ${({ theme }) => theme.color.carouselDot};
    width: ${({ theme }) => theme.width.carouselDot}px;
    height: ${({ theme }) => theme.height.carouselDot}px;
    border-radius: ${({ theme }) => theme.height.carouselDot}px;
    transition: all 250ms ease-in;
    &:nth-child(5), &:nth-child(6) {
      display: none;
    }
    & + button {
      margin-left: 10px;
    }
    &.carousel__dot--selected {
      background-color: ${({ theme }) => theme.color.carouselDotSelected};
      width: ${({ theme }) => 2 * theme.width.carouselDot}px;
    }
  }
`

const OpaqueMask = styled.div`
  position: absolute;
  top: 0;
  ${props => props.left && 'left: 0'}
  ${props => props.right && 'right: 0'}
  height: 100%;
  width: calc(100% / 3);

  background: linear-gradient(
    to ${props => (props.left ? 'right' : 'left')},
    rgba(250, 250, 250, 0.8) 90%,
    rgba(250, 250, 250, 0)
  );

  pointer-events: none;
`

const StyledCarouselProvider = styled(CarouselProvider)`
  position: relative;
  width: 100%;
`

const StyledSlider = styled(Slider)`
  height: 300px;
`

const Picture = styled(({ src, ...rest }) => <Slide {...rest} />)`
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(${({ src }) => src});
`

const WelcomeMessage = styled(ModaleMessageStyled)`
  position: absolute;
  top: 180px;
`

export { Container, StyledCarouselProvider, StyledSlider, Picture, DotGroupStyled, OpaqueMask, WelcomeMessage }
