import * as R from 'ramda'
import { put, takeLatest, select, call, takeEvery } from 'redux-saga/effects'
import moment from 'moment-timezone'

import bot from '../bot'
import channel from '../channel'
import * as broadcastActions from './actions'
import * as broadcastSelectors from './selectors'
import * as broadcastApi from './operations'
import * as broadcastHelpers from './helpers'
import { initCampaign } from './reducer'
import { CampaignStatus } from './types'

function* cancelDeleteCampaignSaga(action) {
  try {
    const { value: botId } = yield select(bot.selectors.getSelectedId)
    const rawCampaign = yield call(broadcastApi.cancelCampaignDelete, botId, action.payload)
    const rehydratedCampaign = broadcastHelpers.rehydrateCampaign(moment.tz.guess(true))(R.omit(['date'], rawCampaign))
    yield put(broadcastActions.cancelCampaignDeleteSuccess(rehydratedCampaign))
  } catch (error) {
    console.error('cancel campaign delete error', error)
    // TODO: display error message
  }
}
function* selectChannelSaga() {
  try {
    const { value: channelInfos } = yield select(channel.selectors.getBySelectedBot)
    // We can safely take the first element since broadcast bots are only using one channel for now
    const channelName = R.pipe(R.head, R.prop('name'))(channelInfos)
    yield put(broadcastActions.selectChannel(channelName))
  } catch (error) {
    console.error('An error occured trying to get bot channel', error)
    yield put({ type: 'NYE' })
  }
}

function* selectPreviewUsersSaga() {
  try {
    const { value: channelInfos } = yield select(channel.selectors.getBySelectedBot)
    const users = R.pipe(R.head, R.prop('usersPreview'))(channelInfos)
    yield put(broadcastActions.selectPreviewUsers(users))
  } catch (error) {
    console.error('An error occured trying to get bot channel', error)
    yield put({ type: 'NYE' })
  }
}

function* fetchCampaignsSaga() {
  try {
    const { value: botId } = yield select(bot.selectors.getSelectedId)
    const rawCampaigns = yield call(broadcastApi.getCampaigns, botId)
    const rehydratedCampaigns = broadcastHelpers.rehydrateCampaigns(rawCampaigns)
    yield put(broadcastActions.loadCampaignsSuccess(rehydratedCampaigns))
  } catch (error) {
    console.error('fetch campaigns error', error)
    yield put(broadcastActions.loadCampaignsFailure())
    // TODO: display error message
  }
}

function* fetchCohortsSaga() {
  try {
    const { value: botId } = yield select(bot.selectors.getSelectedId)
    const cohorts = yield call(broadcastApi.getCohorts, botId)
    yield put(broadcastActions.loadCohortsSuccess(cohorts))
  } catch (error) {
    console.error('fetch cohorts error', error)
    yield put(broadcastActions.loadCohortsFailure())
    // TODO: display error message
  }
}

function* createCampaignSaga() {
  try {
    const { value: botId } = yield select(bot.selectors.getSelectedId)
    const { value: channelInfos } = yield select(channel.selectors.getBySelectedBot)
    // We can safely take the first element since broadcast bots are only using one channel for now
    const channelName = R.pipe(R.head, R.prop('name'))(channelInfos)
    const { date, timeZone } = yield call(broadcastHelpers.createInitialDate)
    const { id } = yield call(broadcastApi.postCampaign, botId, initCampaign('', date, timeZone, channelName))
    yield put(broadcastActions.createNewCampaignSuccess(id, date, timeZone, channelName))
  } catch (e) {
    console.error('createCampaignSaga', e)
    yield put(broadcastActions.createNewCampaignFailure())
  }
}

function* sendPreviewSaga() {
  try {
    const { value: botId } = yield select(bot.selectors.getSelectedId)
    const users = yield select(broadcastSelectors.getPreviewUsers)
    const campaign = yield select(broadcastSelectors.getCurrentCampaign)
    const { error } = yield call(broadcastApi.sendPreview, botId, campaign, users)
    if (!error) {
      yield put(broadcastActions.sendPreviewSuccess())
    }
  } catch (error) {
    console.error('send preview error', error)
    // TODO: display error message
  }
}
function* scheduleCampaignSaga() {
  try {
    const { value: botId } = yield select(bot.selectors.getSelectedId)
    const campaign = yield select(broadcastSelectors.getCurrentCampaign)
    const { status } = yield call(broadcastApi.scheduleCampaign, botId, campaign)
    if (status === CampaignStatus.scheduled) {
      yield put(broadcastActions.scheduleCampaignSuccess())
    } else {
      yield put(broadcastActions.scheduleCampaignFailure())
      // TODO: display error message
    }
  } catch (error) {
    console.error('schedule campaign error', error)
    // TODO: display error message
  }
}

function* saveCampaignSaga() {
  try {
    const { value: botId } = yield select(bot.selectors.getSelectedId)
    const campaign = yield select(broadcastSelectors.getCurrentCampaign)
    const { status } = yield call(broadcastApi.postCampaign, botId, campaign)
    if (status !== CampaignStatus.sent) {
      yield put(broadcastActions.saveCampaignSuccess())
    } else {
      yield put(broadcastActions.saveCampaignFailure())
      // TODO: display error message
    }
  } catch (error) {
    console.error('schedule campaign error', error)
    yield put(broadcastActions.saveCampaignFailure())
    // TODO: display error message
  }
}

function* duplicateCampaignSaga() {
  try {
    const { value: botId } = yield select(bot.selectors.getSelectedId)
    const campaign = yield select(broadcastSelectors.getCurrentCampaign)
    const res = yield call(broadcastApi.duplicateCampaign, botId, campaign)
    if (!res.message) {
      //TODO: figure out why the date is a problem
      const newCampaign = broadcastHelpers.rehydrateCampaign(moment.tz.guess(true))(R.omit(['date'], res))
      yield put(broadcastActions.duplicateCampaignSuccess(newCampaign))
    } else {
      console.error(res)
      yield put(broadcastActions.duplicateCampaignFailure())
      // TODO: display error message
    }
  } catch (error) {
    console.error('duplicate campaign error', error)
    yield put(broadcastActions.duplicateCampaignFailure())
    // TODO: display error message
  }
}

function* sendCampaignSaga() {
  try {
    const { value: botId } = yield select(bot.selectors.getSelectedId)
    const campaign = yield select(broadcastSelectors.getCurrentCampaign)
    const rawCampaign = yield call(broadcastApi.sendCampaign, botId, campaign)
    const rehydratedCampaign = broadcastHelpers.rehydrateCampaign(moment.tz.guess(true))(rawCampaign)
    yield put(broadcastActions.sendCampaignSuccess(rehydratedCampaign))
  } catch (error) {
    console.error('schedule campaign error', error)
    yield put(broadcastActions.sendCampaignFailure())
    // TODO: display error message
  }
}

function* deleteCampaignSaga({ payload: campaignId }) {
  try {
    const { value: botId } = yield select(bot.selectors.getSelectedId)
    const { message } = yield call(broadcastApi.deleteCampaign, botId, campaignId)
    // an error message is returned if something went wrong
    if (!message) {
      yield put(broadcastActions.deleteCampaignSuccess(campaignId))
    } else {
      yield put(broadcastActions.deleteCampaignFailure())
      // TODO: display error message
    }
  } catch (error) {
    console.error('delete campaign error', error)
    yield put(broadcastActions.deleteCampaignFailure())
    // TODO: display error message
  }
}

function* saveFileSaga({ type, payload: { index, cardIndex, file } }) {
  try {
    const { value: botId } = yield select(bot.selectors.getSelectedId)
    const formData = new FormData()
    formData.append('image', file)
    const path = yield call(broadcastApi.uploadFile, botId, formData)
    const url = path
    switch (type) {
    case broadcastActions.BROADCAST_SAVE_CAROUSEL_ELEMENT_IMAGE_REQUEST:
      yield put(broadcastActions.saveCarouselElementImageSuccess(index, cardIndex, url))
      return
    case broadcastActions.BROADCAST_SAVE_IMAGE_ELEMENT_REQUEST:
      yield put(broadcastActions.saveImageElementSuccess(index, url))
      return
    case broadcastActions.BROADCAST_SAVE_VIDEO_ELEMENT_REQUEST:
      yield put(broadcastActions.saveVideoElementSuccess(index, url, file.size))
      return
    default:
      break
    }
  } catch (error) {
    console.error('save file error', error)
    yield put(broadcastActions.saveCarouselElementImageFailure())
    // TODO: display error message
  }
}

function* deleteFileSaga({ type, payload: { index, cardIndex } }) {
  try {
    // TODO
    // const { value: botId } = yield select(bot.selectors.getSelectedId)
    // const tcfUrl = yield call(broadcastApi.deletFile, botId, formData)
    switch (type) {
    case broadcastActions.BROADCAST_DELETE_CAROUSEL_ELEMENT_IMAGE_REQUEST:
      yield put(broadcastActions.saveCarouselElementImageSuccess(index, cardIndex, ''))
      return
    case broadcastActions.BROADCAST_DELETE_IMAGE_ELEMENT_REQUEST:
      yield put(broadcastActions.saveImageElementSuccess(index, ''))
      return
    case broadcastActions.BROADCAST_DELETE_VIDEO_ELEMENT_REQUEST:
      yield put(broadcastActions.saveVideoElementSuccess(index, ''))
      return
    default:
      break
    }
  } catch (error) {
    console.error('save file error', error)
    yield put(broadcastActions.saveCarouselElementImageFailure())
    // TODO: display error message
  }
}

function* fetchAnalyticsSaga({ type, payload }) {
  try {
    const campaign = yield select(broadcastSelectors.getCurrentCampaign)
    if (campaign.status === 'sent') {
      const analytics = yield call(broadcastApi.fetchAnalytics, campaign.botId, campaign.id)
      yield put(broadcastActions.fetchAnalyticsSuccess(analytics)) //mock
    }
  } catch (error) {
    console.error('fetch analytics error', error)
  }
}

export function* root() {
  yield takeEvery(broadcastActions.BROADCAST_CANCEL_CAMPAIGN_DELETE_REQUEST, cancelDeleteCampaignSaga)
  yield takeLatest(channel.actions.FETCH_BY_BOT_SUCCESS, selectChannelSaga)
  yield takeLatest(channel.actions.FETCH_BY_BOT_SUCCESS, selectPreviewUsersSaga)
  yield takeLatest(broadcastActions.BROADCAST_LOAD_CAMPAIGNS_REQUEST, fetchCampaignsSaga)
  yield takeLatest(broadcastActions.BROADCAST_SELECT_CAMPAIGN, fetchAnalyticsSaga)
  yield takeLatest(broadcastActions.BROADCAST_LOAD_COHORTS_REQUEST, fetchCohortsSaga)
  yield takeLatest(broadcastActions.BROADCAST_CREATE_NEW_CAMPAIGN_REQUEST, createCampaignSaga)
  yield takeLatest(broadcastActions.BROADCAST_SAVE_CAMPAIGN_REQUEST, saveCampaignSaga)
  yield takeLatest(broadcastActions.BROADCAST_DUPLICATE_CAMPAIGN_REQUEST, duplicateCampaignSaga)
  yield takeLatest(broadcastActions.BROADCAST_SCHEDULE_CAMPAIGN_REQUEST, scheduleCampaignSaga)
  yield takeLatest(broadcastActions.BROADCAST_SEND_CAMPAIGN_REQUEST, sendCampaignSaga)
  yield takeLatest(broadcastActions.BROADCAST_DELETE_CAMPAIGN_REQUEST, deleteCampaignSaga)
  yield takeLatest(broadcastActions.BROADCAST_SAVE_CAROUSEL_ELEMENT_IMAGE_REQUEST, saveFileSaga)
  yield takeLatest(broadcastActions.BROADCAST_DELETE_CAROUSEL_ELEMENT_IMAGE_REQUEST, deleteFileSaga)
  yield takeLatest(broadcastActions.BROADCAST_SAVE_IMAGE_ELEMENT_REQUEST, saveFileSaga)
  yield takeLatest(broadcastActions.BROADCAST_DELETE_IMAGE_ELEMENT_REQUEST, deleteFileSaga)
  yield takeLatest(broadcastActions.BROADCAST_SAVE_VIDEO_ELEMENT_REQUEST, saveFileSaga)
  yield takeLatest(broadcastActions.BROADCAST_DELETE_VIDEO_ELEMENT_REQUEST, deleteFileSaga)
  yield takeLatest(broadcastActions.BROADCAST_SEND_PREVIEW_REQUEST, sendPreviewSaga)
}
