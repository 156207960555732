import actions from '../actions'
import delays from '../delays/operations'
import entities from '../entities/operations'
import messages from '../messages/operations'
import retention from '../retention/operations'
import satisfaction from '../satisfaction/operations'

const fetchAnalyticsPerformanceRetention = filters => async dispatch => {
  dispatch(actions.fetchPerformanceRetentionRequest())
  try {
    const fetchDelaysPromise = dispatch(delays.fetchDelays(filters))
    const fetchDelaysPreviousPeriodPromise = dispatch(delays.fetchDelaysPreviousPeriod(filters))
    const fetchEntitiesPromise = dispatch(entities.fetchEntities(filters))
    const fetchMessagesPromise = dispatch(messages.fetchMessages(filters))
    const fetchRetentionPromise = dispatch(retention.fetchRetention(filters))
    const fetchSatisfactionPromise = dispatch(satisfaction.fetchSatisfaction(filters))
    const fetchGlobalSatisfactionPromise = dispatch(satisfaction.fetchGlobalSatisfaction(filters))

    await Promise.all([
      fetchDelaysPromise,
      fetchDelaysPreviousPeriodPromise,
      fetchEntitiesPromise,
      fetchMessagesPromise,
      fetchRetentionPromise,
      fetchSatisfactionPromise,
      fetchGlobalSatisfactionPromise
    ])
    await dispatch(actions.fetchPerformanceRetentionSuccess())
  } catch (e) {
    console.error(e)
    await dispatch(actions.fetchPerformanceRetentionFailure())
  }
}

export default fetchAnalyticsPerformanceRetention
