import React from 'react'

import BackButton from 'components/BackButton'

import './styles.css'

function KeywordBreadcrumb({ keyword }) {
  return (
    <div className='KeywordBreadcrumb'>
      <BackButton label={keyword.name} />
    </div>
  )
}

export default KeywordBreadcrumb
