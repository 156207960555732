import types from './types'
import initialState from './initialState'
import visiblePeriods from '../_shared/visiblePeriods'
import getComparisonDateRange from './utils/comparisonDateRange'

const authReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.SET_DATE_RANGE:
    if (!('dateRange' in action)) {
      return state
    }
    return {
      ...state,
      filters: {
        ...state.filters,
        dateRange: action.dateRange,
        comparisonDateRange: getComparisonDateRange(action.dateRange),
        periods: {
          ...state.filters.periods,
          visible: visiblePeriods(action.dateRange.start, action.dateRange.end)
        }
      }
    }

  case types.SET_ACTIVE_PERIOD:
    return {
      ...state,
      filters: {
        ...state.filters,
        periods: {
          ...state.filters.periods,
          active: action.index
        }
      }
    }

  case types.SET_ACTIVE_CHANNEL:
    return {
      ...state,
      filters: {
        ...state.filters,
        channel: {
          ...state.filters.channel,
          id: action.channel.id,
          name: action.channel.name
        }
      }
    }

  case types.LOGIN_REQUEST:
    return {
      ...state,
      fetching: true,
      loginError: null,
      isAuthenticated: false
    }

  case types.VERIFY_AUTH_REQUEST:
    return {
      ...state,
      fetching: true,
      verifyError: null,
      isAuthenticated: false
    }

  case types.LOGIN_FAILURE:
    return {
      ...state,
      fetching: false,
      loginError: action.error,
      isAuthenticated: false
    }

  case types.VERIFY_AUTH_FAILURE:
    return {
      ...state,
      fetching: false,
      verifyError: action.error,
      isAuthenticated: false
    }

  case types.LOGIN_SUCCESS:
  case types.VERIFY_AUTH_SUCCESS:
    return {
      ...state,
      fetching: false,
      loginError: null,
      verifyError: null,
      isAuthenticated: true,
      filters: {
        ...state.filters
        // bots: action.payload.bots,
      }
    }

  case types.LOGOUT:
    return initialState

  default:
    return state
  }
}

export default authReducer
