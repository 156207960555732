import styled, { css } from 'styled-components'

import { initButton, stateHover } from '../../style/globalStyles'

export const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 407px;
  border-radius: 7px;
  border: solid 1.6px #ececec;
  background-color: var(--white);
`

export const LegendContainer = styled.div`
  height: 98px;
  display: flex;
  justify-content: space-between;
`

export const LegendSection = styled.div`
  display: flex;
  padding: 0 12px;
  align-items: center;
`
export const timeButtonActive = css`
  background-color: #747a8d;
  color: white;
  pointer-events: none;
`
export const Legend = styled.div`
  margin: 18px;
  display: flex;
`

export const LegendColorIndicator = styled.div`
  width: 14px;
  height: 14px;
  background-color: #89a6ff;
  border-radius: 7px;
  ${props => `background-color: ${props.color}`}
  margin-right:   6px;
`

export const LegendText = styled.p`
  font-family: HiraginoSans-W6;
  font-size: 10px;
  color: #666666;
  text-transform: capitalize;
`

export const TimeButton = styled.button`
  ${initButton}
  padding: 4px 9px;
  margin: 0 10px;
  border-radius: 12.5px;
  width: 57px;
  height: 27px;
  font-size: 13.8px;
  font-weight: bold;
  color: #747a8d;
  :focus {
    outline: none;
  }
  ${props => props.active && timeButtonActive}
  ${stateHover}
`

// border: solid 1.6px ${({ theme }) => theme.color.veryLightPink};
// background-color: ${({ theme }) => theme.color.steel};
// color: ${({ theme }) => theme.color.steel};
