import React from 'react'

import './styles.css'

type Props = Readonly<{
  title: string
}>

const SkillList: React.FunctionComponent<Props> = ({ title, children }) => (
  <React.Fragment>
    <h1 className='SkillList-title'>{title}</h1>
    <div className='SkillList-list'>
      {children}
    </div>
  </React.Fragment>
)

export default SkillList
