import React from 'react'
import { Link } from 'react-router-dom'

import EnabledState from '../EnabledState'
import OpenState from '../OpenState'

import './styles.css'

function EntityBreadcrumb({ entity }) {
  return (
    <div className='EntityBreadcrumb'>
      <div className='EntityBreadcrumb-path'>
        <Link className='EntityBreadcrumb-link' to={'/entities'}>
          entities
        </Link>
        <span className='EntityBreadcrumb-link-separator'>/</span>
        <span>{entity.name}</span>
      </div>
      <EnabledState enabled={entity.enabled} />
      <OpenState open={entity.open} />
    </div>
  )
}

export default EntityBreadcrumb
