import * as R from 'ramda'

import { IsoLanguage } from 'helpers/language'
import { Bot, Environment, IN_PROGRESS, NEEDS_TO_BE_PUBLISHED, PublishStatus, UP_TO_DATE, RestBot } from './types'

export const NO_USE_CASE = 'Other'

export const toOption = (bot: Bot) => {
  return {
    text: bot.name,
    value: bot.bot_id
  }
}

export const getFromLocalStorage = (bots: ReadonlyArray<RestBot>) => {
  const botId = localStorage.getItem('botId')
  const bot = bots.find(bot => bot.id === botId)
  return bot ? bot : bots[0]
}

export const isVersionOne = R.equals(1)

const splitUrlInPaths = R.split('/')
const takeTheLatestElement: (urlElements: string[]) => string = R.last
export const getWebchatIdFromUrl: (url: string) => string = R.pipe(splitUrlInPaths, takeTheLatestElement)

export const needsToBePublished = (publishStatus: PublishStatus) => publishStatus.status === NEEDS_TO_BE_PUBLISHED

export const inProgress = (publishStatus: PublishStatus) => publishStatus.status === IN_PROGRESS

export const uptodate = (publishStatus: PublishStatus) => publishStatus.status === UP_TO_DATE

export const atLastStep = (currentStep: number, steps: number) => currentStep === steps - 1

export const atFirstStep = (currentStep: number) => currentStep === 0

export const toPercent = (step: number, stepsLength: number) => Math.floor((step * 100) / stepsLength)

export const placeholderLanguage = (language: IsoLanguage, botName: string) => {
  switch (language) {
    case 'fr_FR':
      return `Hello !\n\nJe suis ${botName}, votre assistant virtuel…`
    case 'nl_NL':
        return `Hallo !\n\nIk ben ${botName}, jouw virtuele assistent`
    case 'pt_PT':
      return `Olá!\n\nEu sou ${botName}, o que posso fazer por si?`
    default:
      return `Hello!\n\nI am ${botName}, your assistant! Here to answer all your question about…`
  }
}

const urlPrefixFromEnv: (environment: Environment) => string = environment => {
  switch (environment) {
    case 'production':
      return ''

    default:
      return `${environment}-`
  }
}

export const scriptUrlForWebChat: (environment: Environment) => string =
  environment => `https://${urlPrefixFromEnv(environment)}script.tolk.ai/iframe-latest.js`

export const filterOutArchived: (bots: ReadonlyArray<Bot>) => ReadonlyArray<Bot> =
  bots => R.reject(bot => !!bot.archived, bots)
