import React from 'react'
import * as R from 'ramda'
import ReactDOM from 'react-dom'
import { withTheme } from 'styled-components'
import moment from 'moment-timezone'

// import Button from 'components/Button'

import DuplicateIcon from '../../icons/Duplicate'
import PreviewIcon from '../../icons/preview'
import {
  PanelStyled,
  TopContainer,
  TitleContainer,
  Title,
  ButtonEdit,
  DuplicateButtonContainer,
  Section,
  SectionTitle,
  SectionActionTitle,
  SectionActions,
  TooltipContainer,
  PreviewButtonContainer,
  LeftSection
} from './styles'
import {
  ElementSelector,
  ScheduleCampaign,
  Elements,
  Cohorts,
  Onboarding,
  DatePicker,
  TimePicker,
  TimeZonePicker,
  ToggleSwitch,
  Modales,
  OutlinedUI,
  RecurringSelection,
  AnalyticsStackChart
} from '..'
import { CampaignStatus, SENT } from '../../types'

const initialState = {
  titleIsEditable: false,
  draggingElements: false,
  keyForElements: 1,
  confirmCampaignBefore: false,
  confirmCampaignAfter: false,
  showModale: false
}

class MainContent extends React.Component {
  state = initialState

  componentDidMount() {
    this.props.loadCampaignsRequest()
    this.props.loadCohortsRequest()
  }

  componentDidUpdate(prevProps) {
    if (this.state.titleIsEditable && this.inputTitle) {
      this.inputTitle.focus()
    }
    if (prevProps.currentCampaignId !== this.props.currentCampaignId) {
      this.setState(initialState)
      return
    }
    // if (prevProps.status === CampaignStatus.draft && this.props.status !== CampaignStatus.draft) {
    // TODO: replace by notification ?
    // this.handleCampaignConfirmAfter()
    // }
  }

  toggleInputTitleEditable = () => {
    this.setState(({ titleIsEditable }) => ({ titleIsEditable: !titleIsEditable }))
  }

  handleSortStart = () => {
    this.setState({ draggingElements: true })
  }

  handleSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ keyForElements }) => ({
      draggingElements: false,
      keyForElements: keyForElements + 1
    }))
    this.props.reorderElements(oldIndex, newIndex)
  }

  handleCampaignConfirmBefore = () => {
    this.setState({
      confirmCampaignBefore: true,
      showModale: true
    })
  }

  handleCampaignConfirmAfter = () => {
    this.setState({
      confirmCampaignAfter: true,
      showModale: true
    })
  }

  handleHideModale = () => {
    this.setState({
      confirmCampaignAfter: false,
      confirmCampaignBefore: false,
      showModale: false
    })
  }

  handleCampaignSend = () => {
    const { sendNow, sendCampaignRequest, scheduleCampaignRequest } = this.props
    sendNow ? sendCampaignRequest() : scheduleCampaignRequest()
    this.setState({ confirmCampaignBefore: false })
  }

  handleSendPreview = () => this.props.sendPreviewRequest()
  handleCampaignDuplicate = () => {
    this.props.duplicateCampaignRequest()
  }

  handleCampaignSave = () => {
    this.props.saveCampaignRequest()
  }

  renderConfirmModaleBefore = () => {
    const { title, cohorts, campaignCohorts, date, sendNow, theme } = this.props
    const { confirmCampaignBefore } = this.state
    if (this.root === null || this.root === undefined) return null
    if (!confirmCampaignBefore) return null

    if (this.root) {
      return ReactDOM.createPortal(
        <Modales.ConfirmCampaignBefore
          title={title}
          theme={theme}
          cohorts={cohorts}
          campaignCohorts={campaignCohorts}
          sendNow={sendNow}
          date={date}
          confirm={R.pipe(this.handleCampaignSend, this.handleCampaignConfirmAfter)}
          cancel={this.handleHideModale}
        />,
        this.root.parentNode
      )
    }
  }

  renderConfirmModaleAfter = () => {
    const { sendNow, createNewCampaignRequest } = this.props
    const { confirmCampaignAfter } = this.state
    if (this.root === null || this.root === undefined) return null
    if (!confirmCampaignAfter) return null

    if (this.root) {
      return ReactDOM.createPortal(
        <Modales.ConfirmCampaignAfter
          sendNow={sendNow}
          confirm={createNewCampaignRequest}
          cancel={this.handleHideModale}
        />,
        this.root.parentNode
      )
    }
  }

  render() {
    const {
      settings,
      status,
      noCurrentCampaign,
      buttonPreviewDisable,
      buttonSendDisable,
      buttonSaveDisable,
      previewIsSending,
      buttonPreviewAvailable,
      title,
      recurring,
      recurrence,
      currentCampaignId,
      currentAnalytics,
      changeCampaignTitle,
      date,
      changeCampaignDate,
      sendNow,
      toggleCampaignSendNow,
      cohorts,
      campaignCohorts,
      selectCohort,
      selectRecurring,
      selectRecurrence,
      createNewCampaignRequest,
      elements,
      addElement,
      deleteElement,
      editCarouselElementAddCard,
      editCarouselElementDeleteCard,
      editCarouselElementReorderCards,
      editCarouselElementImage,
      editCarouselElementTitle,
      editCarouselElementDescription,
      editCarouselElementAddButton,
      editCarouselElementDeleteButton,
      editCarouselElementButtonTitle,
      editCarouselElementButtonValue,
      editImageElement,
      editTextElement,
      editVideoElement,
      saveCarouselElementImageRequest,
      saveImageElementRequest,
      saveVideoElementRequest,
      sentDate,
      deleteCarouselElementImageRequest,
      deleteImageElementRequest,
      deleteVideoElementRequest
    } = this.props

    const { titleIsEditable, draggingElements, keyForElements, showModale } = this.state

    const isSent = status === SENT

    if (noCurrentCampaign)
      return (
        <PanelStyled>
          <Onboarding createNewCampaignRequest={createNewCampaignRequest} />
        </PanelStyled>
      )
    return (
      <PanelStyled ref={root => (this.root = root)} blurry={showModale} key={currentCampaignId}>
        <TopContainer>
          <TitleContainer onSubmit={this.toggleInputTitleEditable} as={titleIsEditable && !isSent ? 'form' : 'div'}>
            {!isSent && (
              <Title
                as='input'
                ref={r => (this.inputTitle = r)}
                value={title}
                visible={titleIsEditable}
                onChange={event => changeCampaignTitle(event.target.value)}
                onBlur={this.toggleInputTitleEditable}
              />
            )}
            <Title noBorder visible={!titleIsEditable || isSent} onClick={this.toggleInputTitleEditable}>
              {title}
            </Title>
            {!isSent && (
              <ButtonEdit type={titleIsEditable ? 'submit' : 'button'} onClick={this.toggleInputTitleEditable}>
                {titleIsEditable ? 'Confirm' : 'Edit'}
              </ButtonEdit>
            )}
          </TitleContainer>
          <LeftSection>
            {status !== CampaignStatus.draft && (
              <DuplicateButtonContainer onClick={this.handleCampaignDuplicate}>
                <TooltipContainer>Duplicate</TooltipContainer>
                <DuplicateIcon width='2em' height='2em' />
              </DuplicateButtonContainer>
            )}
            {buttonPreviewAvailable && (
              <PreviewButtonContainer onClick={this.handleSendPreview} disabled={buttonPreviewDisable}>
                <span>{previewIsSending ? '...SENDING' : 'SEND A PREVIEW'}</span>
                <PreviewIcon width='1em' height='1em' />
              </PreviewButtonContainer>
            )}
          </LeftSection>
        </TopContainer>
        <Section>
          <SectionTitle>What ?</SectionTitle>
          <Elements
            settings={settings}
            /* Dnd */
            key={elements.length + keyForElements}
            useDragHandle
            lockAxis='y'
            dragging={draggingElements}
            onSortEnd={this.handleSortEnd}
            onSortStart={this.handleSortStart}
            /* Elements data */
            elements={elements}
            deleteElement={deleteElement}
            editCarouselElementAddCard={editCarouselElementAddCard}
            editCarouselElementDeleteCard={editCarouselElementDeleteCard}
            editCarouselElementReorderCards={editCarouselElementReorderCards}
            editCarouselElementImage={editCarouselElementImage}
            editCarouselElementTitle={editCarouselElementTitle}
            editCarouselElementDescription={editCarouselElementDescription}
            editCarouselElementAddButton={editCarouselElementAddButton}
            editCarouselElementDeleteButton={editCarouselElementDeleteButton}
            editCarouselElementButtonTitle={editCarouselElementButtonTitle}
            editCarouselElementButtonValue={editCarouselElementButtonValue}
            editImageElement={editImageElement}
            editTextElement={editTextElement}
            editVideoElement={editVideoElement}
            saveCarouselElementImageRequest={saveCarouselElementImageRequest}
            deleteCarouselElementImageRequest={deleteCarouselElementImageRequest}
            saveImageElementRequest={saveImageElementRequest}
            deleteImageElementRequest={deleteImageElementRequest}
            saveVideoElementRequest={saveVideoElementRequest}
            deleteVideoElementRequest={deleteVideoElementRequest}
            canEdit={!isSent}
          />
          {!isSent && <SectionActionTitle>Add Element</SectionActionTitle>}
          {!isSent && <ElementSelector addElement={addElement} />}
        </Section>
        <Section>
          <SectionTitle>Who ?</SectionTitle>
          {!isSent && <SectionActionTitle>Select cohorts</SectionActionTitle>}
          <Cohorts cohorts={cohorts} selectCohort={selectCohort} campaignCohorts={campaignCohorts} canEdit={!isSent} />
        </Section>
        <Section>
          <SectionTitle>When ?</SectionTitle>
          <ToggleSwitch disabled={isSent} selected={sendNow} onChange={toggleCampaignSendNow} />
          <SectionActions deactivated={sendNow || isSent}>
            <DatePicker date={date} changeDate={changeCampaignDate} />
            <TimePicker disabled={sendNow || isSent} isSent={isSent} date={date} changeDate={changeCampaignDate} />
            <TimeZonePicker disabled={sendNow || isSent} date={date} changeDate={changeCampaignDate} />
          </SectionActions>
        </Section>
        <SectionActions deactivated={sendNow || isSent}>
          <RecurringSelection
            canEdit={!sendNow && !isSent}
            selectRecurring={selectRecurring}
            selectRecurrence={selectRecurrence}
            recurring={recurring}
            recurrence={recurrence}
          />
        </SectionActions>
        {(status === CampaignStatus.draft || status === CampaignStatus.scheduled) && (
          <Section block>
            <SectionActions>
              {status === CampaignStatus.draft && (
                <ScheduleCampaign
                  disabled={buttonSendDisable}
                  onClick={this.handleCampaignConfirmBefore}
                  value={sendNow ? 'Send it !' : 'Schedule it!'}
                />
              )}
              <OutlinedUI disabled={buttonSaveDisable} onClick={this.handleCampaignSave} value='Save' />
            </SectionActions>
          </Section>
        )}
        {status === CampaignStatus.sent && (
          <AnalyticsStackChart
            data={currentAnalytics}
            sentDate={moment(Number(sentDate))
              .tz(moment.tz.guess())
              .format()}
          />
        )}
        {this.renderConfirmModaleBefore()}
        {this.renderConfirmModaleAfter()}
      </PanelStyled>
    )
  }
}

export default withTheme(MainContent)
