import { connect } from 'react-redux'

import bot from 'modules/bot'
import user from 'modules/user'
import auth from 'modules/auth'
import Topbar from '../components/Topbar'

const mapStateToProps = state => ({
  botId: bot.selectors.getSelectedId(state),
  botName: bot.selectors.getName(state),
  webchatUrl: bot.selectors.getWebchatUrl(state),
  isLoggedIn: auth.selectors.getIsAuthenticated(state),
  isAdmin: user.selectors.getIsAdmin(state),
  userName: user.selectors.getUsername(state)
})

const mapDisptatchToProps = {
  logout: user.actions.logout,
  selectBot: bot.actions.select
}

export default connect(mapStateToProps, mapDisptatchToProps)(Topbar)
