import React from 'react'
import { MdDone } from 'react-icons/md'
import { Link } from 'react-router-dom'

import { useEffectOnce } from 'helpers/react'

type Props = Readonly<{
  botUrl: string
  getLastBot: () => unknown
  creationBotEnded: () => unknown
}>

const Congrats = ({ botUrl, getLastBot, creationBotEnded }: Props) => {
  const [isBotClicked, setBotClicked] = React.useState(false)

  useEffectOnce(() => () => { creationBotEnded() })

  const handleBot = () => setBotClicked(true)

  return (
    <div className='congrats-container'>
      <img className='congrats-astro' src='/img/astronaut.png' alt='astronaute'/>
      <h1 className='bot-creation-form-title'>
        Congratulations, your bot is set up{' '}
        <span role='img' aria-label='fire'>
            🎉
          </span>
      </h1>
      <p className='congrats-text bot-creation-form-text'>And now?</p>
      <ul>
        <li className='congrats-li congrats-li-valid'>
          <div className='congrats-li-round congrats-li-round-valid'>
            <MdDone className='congrats-li-done'/>
          </div>
          <span className='congrats-li-text'>Create your bot</span>
        </li>
        <li className={`congrats-li ${isBotClicked ? 'congrats-li-valid' : ''}`}>
          <div className={`congrats-li-round ${isBotClicked ? 'congrats-li-round-valid' : ''}`}>
            {isBotClicked && <MdDone className='congrats-li-done'/>}
          </div>
          <span className='congrats-li-text'>
              Test your{' '}
            <a onClick={handleBot} className='congrats-link' target='_blank' rel='noopener noreferrer' href={botUrl}>
                webchat
              </a>
            </span>
        </li>
        <li className='congrats-li'>
          <div className='congrats-li-round'/>
          <span className='congrats-li-text'>
              Import your first{' '}
            <Link onClick={getLastBot} className='congrats-link' to='/qa'>
                Q&A
              </Link>
            </span>
        </li>
      </ul>
    </div>
  )
}

export {
  Congrats
}
