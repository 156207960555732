import types from './types'

const initialState = {
  fetching: false,
  fetchingUtil: false
}

const referralReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.FETCH_REFERRAL_REQUEST:
    return {
      ...state,
      fetching: true,
      data: null
    }

  case types.FETCH_REFERRAL_NO_DATA:
    return { ...state, fetching: false }

  case types.FETCH_REFERRAL_SUCCESS:
    return {
      ...state,
      fetching: false,
      data: action.payload.data
    }

  case types.FETCH_REFERRALUTIL_REQUEST:
    return { ...state, fetchingUtil: true, util: null }

  case types.FETCH_REFERRALUTIL_NO_DATA:
    return { ...state, fetchingUtil: false }

  case types.FETCH_REFERRALUTIL_SUCCESS:
    return { ...state, fetchingUtil: false, util: action.payload.data }

  default:
    return state
  }
}

export default referralReducer
