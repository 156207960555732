import React from 'react'

import * as connectorsConfig from '@tolk-ai/bot-management-react-connectors-config'
import * as connectorsConfigZendesk from '@tolk-ai/connectors-config-zendesk'
import * as authenticatedRequest from 'services/authenticated-request'

import '@tolk-ai/bot-management-react-connectors-config/dist/bot-management-react-connectors-config.esm.css'
import BackButton from 'components/BackButton'

const ZendeskLogin = connectorsConfig.zendeskLogin.container(
  connectorsConfigZendesk.axiosZendesk.createOneZendeskLoginConfig(authenticatedRequest.botManagement),
  connectorsConfigZendesk.axiosZendesk.getOneZendeskLoginConfigConnected(authenticatedRequest.botManagement),
  connectorsConfigZendesk.axiosZendesk.deleteOneZendeskLoginConfigConnected(authenticatedRequest.botManagement),
  window
)

const Zendesk = () => {
  const botId = localStorage.getItem('botId') || ''

  return (
    <>
      <div className='pageContainer'>
        <section className='channelsettings'>
          <header className='channelsettings__header'>
            <BackButton label={`Zendesk Settings`} path='/channels' />
          </header>
          <ZendeskLogin botId={botId} />
        </section>
      </div>
    </>
  )
}

export default Zendesk
