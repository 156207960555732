const FETCH_BOTCONFIG_REQUEST = 'tcfdash/analytics/FETCH_BOTCONFIG_REQUEST'
const FETCH_BOTCONFIG_SUCCESS = 'tcfdash/analytics/FETCH_BOTCONFIG_SUCCESS'
const FETCH_BOTCONFIG_NO_DATA = 'tcfdash/analytics/FETCH_BOTCONFIG_NO_DATA'

const FETCH_CUSTOM_EVENTS_REQUEST = 'tcfdash/analytics/FETCH_CUSTOM_EVENTS_REQUEST'
const FETCH_CUSTOM_EVENTS_SUCCESS = 'tcfdash/analytics/FETCH_CUSTOM_EVENTS_SUCCESS'
const FETCH_CUSTOM_EVENTS_NO_DATA = 'tcfdash/analytics/FETCH_CUSTOM_EVENTS_NO_DATA'

const POST_EVENT_CHART_REQUEST = 'tcfdash/ressources/POST_EVENT_CHART_REQUEST'
const POST_EVENT_CHART_FAILURE = 'tcfdash/ressources/POST_EVENT_CHART_FAILURE'
const POST_EVENT_CHART_SUCCESS = 'tcfdash/ressources/POST_EVENT_CHART_SUCCESS'

export default {
  FETCH_BOTCONFIG_REQUEST,
  FETCH_BOTCONFIG_SUCCESS,
  FETCH_BOTCONFIG_NO_DATA,
  FETCH_CUSTOM_EVENTS_REQUEST,
  FETCH_CUSTOM_EVENTS_SUCCESS,
  FETCH_CUSTOM_EVENTS_NO_DATA,
  POST_EVENT_CHART_REQUEST,
  POST_EVENT_CHART_FAILURE,
  POST_EVENT_CHART_SUCCESS
}
