import { Map, List, fromJS } from 'immutable'

import types from './types'
import authTypes from '../auth/types'
import initialState from './initialState'
import { visiblePeriods } from './utils'
import mainActions from '../main/types'

const trainingReducer = (state = initialState, action) => {
  switch (action.type) {
  case authTypes.LOGIN_SUCCESS:
  case authTypes.VERIFY_TCF_AUTH_HEADER_SUCCESS:
    return state
      .setIn(['filters', 'bot', 'id'], fromJS(action.payload.bots[0].bot_id))
      .setIn(['filters', 'bot', 'name'], fromJS(action.payload.bots[0].name))
      .setIn(['filters', 'channel', 'id'], fromJS(action.payload.bots[0].channels[0]._id))
      .setIn(['filters', 'channel', 'name'], fromJS(action.payload.bots[0].channels[0].slug))
      .setIn(['bots'], fromJS(action.payload.bots))

  case types.SET_FETCHING_ALL_DATA:
    return state.set('fetchingAllData', action.payload)

  case types.FETCH_MESSAGES_REQUEST:
    return state.set('noMessagesData', false)

  case types.FETCH_MESSAGES_NO_DATA:
    return state.set('noMessagesData', true).setIn(['data', 'messages', 'data'], List())

  case types.FETCH_MESSAGES_SUCCESS:
    return state
      .set('noMessagesData', false)
      .setIn(['data', 'messages', 'data'], fromJS(action.payload.data))
      .setIn(['data', 'messages', 'count'], fromJS(action.payload.count))

  case types.SET_DATE_RANGE:
    if (!('dateRange' in action)) {
      return state
    }
    return state
      .setIn(['filters', 'dateRange'], Map(action.dateRange))
      .setIn(['filters', 'fetchDate'], true)
      .setIn(['filters', 'periods', 'visible'], List(visiblePeriods(action.dateRange.start, action.dateRange.end)))

  case types.SET_ACTIVE_PERIOD:
    return state.setIn(['filters', 'periods', 'active'], action.index)

  case types.SET_ACTIVE_BOT: {
    const channel = state
      .get('bots')
      .toJS()
      .filter(bot => bot.bot_id === action.payload.id)[0].channels[0]

    return state
      .setIn(['filters', 'bot', 'id'], action.payload.id)
      .setIn(['filters', 'bot', 'name'], action.payload.name)
      .setIn(['filters', 'channel', 'id'], channel._id)
      .setIn(['filters', 'channel', 'name'], channel.slug)
  }

  case mainActions.SET_ACTIVE_BOT: {
    return state.setIn(['filters', 'intent', 'id'], null).setIn(['filters', 'language', 'id'], null)
  }

  case types.GET_TRAINING_SENTENCE_REQUEST:
    return state.setIn(['trainingList', 'isFetching'], true).setIn(['trainingList', 'all'], false)

  case types.GET_TRAINING_SENTENCE_SUCCESS:
    return state.setIn(['trainingList', 'isFetching'], false).setIn(['trainingList', 'data'], fromJS(action.payload))
  case types.GET_TRAINING_SENTENCE_FAILURE:
    return state.setIn(['trainingList', 'isFetching'], false).setIn(['trainingList', 'data'], List())

  case types.GET_TRAINING_SENTENCENOT_REQUEST:
    return state.setIn(['trainingListNotMatched', 'isFetching'], true).setIn(['trainingListNotMatched', 'all'], false)
  case types.GET_TRAINING_SENTENCENOT_SUCCESS:
    return state
      .setIn(['trainingListNotMatched', 'isFetching'], false)
      .setIn(['trainingListNotMatched', 'data'], fromJS(action.payload))
  case types.GET_TRAINING_SENTENCENOT_FAILURE:
    return state
      .setIn(['trainingListNotMatched', 'isFetching'], false)
      .setIn(['trainingListNotMatched', 'data'], List())

  case types.GET_TRAINING_SENTENCESTANDBY_REQUEST:
    return state.setIn(['trainingListStandBy', 'isFetching'], true)
  case types.GET_TRAINING_SENTENCESTANDBY_SUCCESS:
    return state
      .setIn(['trainingListStandBy', 'isFetching'], false)
      .setIn(['trainingListStandBy', 'data'], fromJS(action.payload))
  case types.GET_TRAINING_SENTENCESTANDBY_FAILURE:
    return state.setIn(['trainingListStandBy', 'isFetching'], false).setIn(['trainingListStandBy', 'data'], List())

  case types.SET_ACTIVE_CHANNEL:
    return state
      .setIn(['filters', 'channel', 'id'], action.payload.id)
      .setIn(['filters', 'channel', 'name'], action.payload.name)
  case types.SET_ACTIVE_INTENT:
    return state.setIn(['filters', 'intent'], fromJS(action.payload))
  case types.SET_ACTIVE_LANGUAGE:
    return state.setIn(['filters', 'language'], fromJS(action.payload))

  case types.CLEAR_INTENT:
    return state.setIn(['filters', 'intent'], fromJS({ id: null, name: null }))

  case types.CLEAR_INTENT_LINE:
    return state.setIn([action.payload.listName, 'data', action.payload.index, 'intentId'], null)

  case types.CLEAR_INTENT_SELECTED:
    return state.setIn([action.payload, 'intentId'], null)

  case types.FETCH_BOT_CONFIG_REQUEST:
    return state.setIn(['data', 'events', 'noData'], true).setIn(['data', 'events', 'fetching'], true)

  case types.FETCH_BOT_CONFIG_NO_DATA:
    return state.setIn(['data', 'events', 'noData'], true).setIn(['data', 'events', 'fetching'], true)

  case types.FETCH_BOT_CONFIG_SUCCESS:
    return state
      .setIn(['data', 'events', 'noData'], false)
      .setIn(['data', 'events', 'fetching'], false)
      .setIn(['data', 'events', 'config'], fromJS(action.payload))

  case types.FETCH_BOT_INTENT_REQUEST:
    return state.setIn(['intentList', 'isFetching'], true)
  case types.FETCH_BOT_INTENT_SUCCESS:
    return state.setIn(['intentList', 'isFetching'], false).setIn(['intentList', 'data'], fromJS(action.payload))
  case types.FETCH_BOT_INTENT_FAILURE:
    return state.setIn(['intentList', 'isFetching'], false).setIn(['intentList', 'data'], List())

  case types.TRAIN_LINE_REQUEST:
  case types.ARCHIVE_LINE_REQUEST:
  case types.DELETE_LINE_REQUEST:
    return state.setIn([action.listName, 'data', action.index, 'loading'], true)

  case types.ARCHIVE_LINE_SUCCESS:
  case types.DELETE_LINE_SUCCESS:
  case types.TRAIN_LINE_SUCCESS:
    return state
      .deleteIn([action.listName, 'data', action.index])
      .setIn([action.listName, 'data', action.index, 'loading'], false)
    // TODO Failure

  case types.TRAIN_SELECTED_REQUEST:
  case types.ARCHIVE_SELECTED_REQUEST:
  case types.DELETE_SELECTED_REQUEST:
    return state.setIn([action.listName, 'loading'], true)
  case types.TRAIN_SELECTED_SUCCESS:
  case types.ARCHIVE_SELECTED_SUCCESS:
  case types.DELETE_SELECTED_SUCCESS:
    return state.setIn([action.listName, 'loading'], false)

  case types.TOGGLE_LINE:
    return state.setIn(
      [action.listName, 'data', action.index, 'selected'],
      !state.getIn([action.listName, 'data', action.index, 'selected'])
    )
  case types.TOGGLE_ALL:
    return state
      .updateIn([action.listName, 'data'], data =>
        data.map(Phrase => Phrase.set('selected', !state.getIn([action.listName, 'all'])))
      )
      .setIn([action.listName, 'all'], !state.getIn([action.listName, 'all']))
  case types.ASSIGN_LINE:
    return state.setIn([action.listName, 'data', action.index, 'intentId'], action.intentId)
  case types.ASSIGN_SELECTED:
    return state.setIn([action.listName, 'intentId'], action.intentId)
  case types.ASSIGN_LIST_NAME:
    return state.set('listName', action.listName)

  default:
    return state
  }
}

export default trainingReducer
