import React, { useState } from 'react'
import * as R from 'ramda'
import { MdKeyboardArrowRight } from 'react-icons/md'
import { FaTrash } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { remoteData } from '@sbizeul/fp-flow'
import { Button, Modal, Checkbox } from 'semantic-ui-react'

import EmptyStateContent from 'components/EmptyState'

import { Id } from 'types'
import { sortByAlphabetical } from '../../models'
import { LinkedElement, LinkedElements, TrackableKeyword, UpdatePersistKeywordStatusRequestParam } from '../../types'
import CreateKeywordButton from './CreateKeywordButton'
import RemoteKeywordModalContent from './RemoteKeywordModalContent'
import './styles.css'

const sortByNameKeywords: (keywords: ReadonlyArray<TrackableKeyword>) => ReadonlyArray<TrackableKeyword> = sortByAlphabetical('name')

type OpenCreateForm = () => unknown

export type Props = Readonly<{
  keywords: ReadonlyArray<TrackableKeyword>
  linkedKeywordElement: LinkedElements

  deleteKeyword: (id: Id) => unknown
  updatePersistKeywordStatus: (persistKeywordStatus: UpdatePersistKeywordStatusRequestParam) => unknown
  fetchLinkedKeywordElement: (id: Id) => unknown
  openCreateForm: OpenCreateForm
}>

const Keywords = (props: Props) => (
  <div className='Keywords'>
    {props.keywords.length ? renderAllKeywords(props) : renderNoKeywords(props.openCreateForm)}
  </div>
)

const renderNoKeywords = (openCreateForm: OpenCreateForm) => (
  <div data-testid='no-keywords'>
    <EmptyStateContent
      img='/img/keyword.svg'
      title='Make your first keyword!'
      text='Need to give your users a precise answer, train your assistant you ask for specific keywords!'
    >
      <CreateKeywordButton onClick={openCreateForm} />
    </EmptyStateContent>
  </div>
)

const renderAllKeywords = (props: Omit<Props, 'openCreateForm'>) => (
    <ul data-testid='all-keywords' className='Keywords-list'>
      {R.pipe(
        sortByNameKeywords,
        R.map((keyword: TrackableKeyword) => (
          <li key={keyword.id}>
            <KeywordRow
              keyword={keyword}
              linkedKeywordElement={props.linkedKeywordElement}
              fetchLinkedKeywordElement={props.fetchLinkedKeywordElement}
              updatePersistKeywordStatus={props.updatePersistKeywordStatus}
              deleteKeyword={props.deleteKeyword}
            />
          </li>
        ))
      )(props.keywords)}
    </ul>
  )

const KeywordRow = ({ keyword, linkedKeywordElement, fetchLinkedKeywordElement, deleteKeyword, updatePersistKeywordStatus }: { keyword: TrackableKeyword, linkedKeywordElement: LinkedElements, fetchLinkedKeywordElement: (id: Id) => unknown, deleteKeyword: (id: Id) => unknown, updatePersistKeywordStatus: (persistKeywordStatus: UpdatePersistKeywordStatusRequestParam) => unknown }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const isEmptyLinkedKeywordElement = (linkedKeywordElements: LinkedElements) => R.pipe(
    remoteData.getOrElse(() => []),
    (linkedKeywordElement: LinkedElement[]) => R.isEmpty(linkedKeywordElement)
  )(linkedKeywordElements)

  return (
    <div className='Keyword-row-container'>
      <Link className='Keyword-row-link-container' to={`/keywords/${keyword.id}`}>
        <ul className='Keywords-row'>
          <MdKeyboardArrowRight size='25' />
          <li className='Keyword-label'>
            <span className='Keyword-label-name'>{keyword.name}</span>
          </li>
        </ul>
      </Link>
      <div className='Keyword-row-icons-container'>
        <div className='Keyword-row-icons-checkbox-container'>
          <Checkbox
            toggle
            type='checkbox'
            label='Persistant'
            checked={keyword.persisted}
            onClick={(_, data) => updatePersistKeywordStatus(R.assoc('persisted', data.checked || false)(keyword))}
            className="Keyword-checkbox"
            />
        </div>
        <FaTrash className='Keyword-icons-trash' size='15' onClick={() => setIsModalOpen(true)} />
        <Modal
          className='Keyword-deletion--modal'
          open={isModalOpen}
          closeOnEscape={true}
          closeOnDimmerClick={true}
          onClose={() => setIsModalOpen(false)}
        >
          <Modal.Header className='Keyword-modal-header'>Delete your keyword</Modal.Header>
          <Modal.Content>
            {isModalOpen &&
            <RemoteKeywordModalContent
                linkedKeywordElements={linkedKeywordElement}
                keyword={keyword}
                fetchLinkedKeywordElement={fetchLinkedKeywordElement}
            />
            }
          </Modal.Content>
          <Modal.Actions>
            <Button negative content='Cancel' onClick={() => setIsModalOpen(false)} />
            {isEmptyLinkedKeywordElement(linkedKeywordElement) && <Button
                positive
                icon='checkmark'
                labelPosition='right'
                content='Yes'
                onClick={() => {
                  deleteKeyword(keyword.id)
                  setIsModalOpen(false)
                }}
            />
            }
          </Modal.Actions>
        </Modal>
      </div>
    </div>
  )
}

export default Keywords
