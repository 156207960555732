import React, { ChangeEventHandler, FC, FocusEventHandler } from 'react'

import { Dropdown } from '../Dropdown/index'

import './index.css'

const zendeskPriorities = [
  {
    name: '-',
    key: ''
  },
  {
    name: 'Basse',
    key: 'low'
  },
  {
    name: 'Normale',
    key: 'normal'
  },
  {
    name: 'Élevée',
    key: 'high'
  },
  {
    name: 'Urgente',
    key: 'urgent'
  }
]

const zendeskTypes = [
  {
    name: '-',
    key: ''
  },
  {
    name: 'Question',
    key: 'question'
  },
  {
    name: 'Incident',
    key: 'incident'
  },
  {
    name: 'Problème',
    key: 'problem'
  },
  {
    name: 'Tâche',
    key: 'task'
  }
]

type ZendeskSupportFormProps = {
  isStatusEnabled: boolean
  type: '' | 'question' | 'incident' | 'problem' | 'task'
  priority: '' | 'low' | 'normal' | 'high' | 'urgent'
  tags: string
  onStatusChange: ChangeEventHandler<HTMLInputElement>
  onTypeChange: ChangeEventHandler<HTMLSelectElement>
  onPriorityChange: ChangeEventHandler<HTMLSelectElement>
  onTagsChange: ChangeEventHandler<HTMLInputElement>
  onTagsBlur: FocusEventHandler<HTMLInputElement>
}

export const ZendeskSupportForm: FC<ZendeskSupportFormProps> = ({
  isStatusEnabled,
  type,
  priority,
  tags,
  onStatusChange,
  onTypeChange,
  onPriorityChange,
  onTagsChange,
  onTagsBlur
}) => {
  return (
    <section className='zendesk-support-form'>
      <div className='zendesk-support-form__input-container'>
        <label
          htmlFor='checkbox-toggle-zendesk'
          className='input-container__label'
        >
          <input
            type='checkbox'
            checked={isStatusEnabled}
            className='input-container__checkbox'
            onChange={onStatusChange}
            id='checkbox-toggle-zendesk'
          />
          <span className='input-container__slider'></span>
        </label>
        <svg className='input-container__logo' width='177px' height='177px' viewBox='0 0 177 177' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
          <title>Artboard</title>
          <g id='Artboard' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g id='Group' transform='translate(8.000000, 24.000000)'>
              <rect id='Rectangle' fill='#69A600' transform='translate(44.500000, 64.500000) rotate(16.000000) translate(-44.500000, -64.500000) ' x='15' y='6' width='59' height='117'></rect>
              <rect id='Rectangle' fill='#00373E' transform='translate(124.245966, 93.421289) rotate(-16.000000) translate(-124.245966, -93.421289) ' x='94.7459664' y='63.921289' width='59' height='59'></rect>
            </g>
          </g>
        </svg>
        <p className='input-container__text'>Zendesk Support</p>
      </div>

      <div className='zendesk-support-form___interractive-container'>
        <Dropdown
          label='Type'
          selectedValue={type}
          options={zendeskTypes}
          onChangeSelectedValue={onTypeChange}
        />
        <Dropdown
          label='Priority'
          selectedValue={priority}
          options={zendeskPriorities}
          onChangeSelectedValue={onPriorityChange}
        />
        <label
          htmlFor='input-tags'
          className='interractive-container__input-label'>
          <span className='interractive-container__input-text'>New tags</span>
          <input
            type='text'
            id='input-tags'
            name='input-tags'
            placeholder='Tag1, Tag2, Tag3, ...'
            className='interractive-container__input-tags'
            onChange={onTagsChange}
            onBlur={onTagsBlur}
            value={tags}
          />
        </label>
      </div>
    </section>
  )
}
