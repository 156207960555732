import React from 'react'
import { Grid, Segment } from 'semantic-ui-react'

import text from '../../../../../../config/translations/en.json'
import MoreInfoPopup from '../../../../../components/MoreInfoPopup'
import UsersGroupedByDay from '../../../components/AreaCharts/UsersGroupedByDayHighcharts'

import '../../index.css'


const style = {
  borderRadius: '3px',
  border: 'none',
  boxShadow: '0 2px 4px rgba(50, 50, 93,.1)',
  padding: '5px'
}

const Messages = ({
  messagesByDay,
  period,
  usersByDay
}) => {
  return(
    <Grid.Row>
        <Grid.Column>
          <h1>
            Typical Week
            <MoreInfoPopup content={text.traffic.typicalweek} />
          </h1>
          <Segment style={style}>
            <UsersGroupedByDay users={usersByDay} messages={messagesByDay} period={period} />
          </Segment>
        </Grid.Column>
      </Grid.Row>
)}

export default Messages
