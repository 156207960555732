import types from './types'

const fetchEscaladesBeginRequest = () => ({
  type: types.FETCH_ESCALADESBEGIN_REQUEST
})

const fetchEscaladesBeginSuccess = payload => ({
  type: types.FETCH_ESCALADESBEGIN_SUCCESS,
  payload
})

const fetchEscaladesBeginFailure = errors => ({
  type: types.FETCH_ESCALADESBEGIN_NO_DATA,
  errors
})

export default {
  fetchEscaladesBeginRequest,
  fetchEscaladesBeginSuccess,
  fetchEscaladesBeginFailure
}
