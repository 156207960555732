import * as R from 'ramda'
import { either } from '@sbizeul/fp-flow'

import {
  CREATE_ONE_FAILURE,
  CREATE_ONE_REQUEST,
  CREATE_ONE_SUCCESS,
  DELETE_ONE_REQUEST,
  DELETE_ONE_FAILURE,
  DELETE_ONE_SUCCESS,
  SAVE_ONE_REQUEST,
  SAVE_ONE_FAILURE,
  SAVE_ONE_SUCCESS
} from './actions'

export const initialState = {
  isChanging: either.right(false)
}

const isChanging = R.lensProp('isChanging')

const changeOne = R.set(isChanging, either.right(true))

const changeOneSuccess = state => R.set(isChanging, either.right(false), state)

const changeOneFailure = (action, state) => R.set(isChanging, either.left(action.payload), state)

export default function(state = initialState, action) {
  switch (action.type) {
  case CREATE_ONE_REQUEST:
    return changeOne(state)
  case CREATE_ONE_FAILURE:
    return changeOneFailure(action, state)
  case CREATE_ONE_SUCCESS:
    return changeOneSuccess(state)
  case DELETE_ONE_REQUEST:
    return changeOne(state)
  case DELETE_ONE_FAILURE:
    return changeOneFailure(action, state)
  case DELETE_ONE_SUCCESS:
    return changeOneSuccess(state)
  case SAVE_ONE_REQUEST:
    return changeOne(state)
  case SAVE_ONE_FAILURE:
    return changeOneFailure(action, state)
  case SAVE_ONE_SUCCESS:
    return changeOneSuccess(state)
  default:
    return state
  }
}
