import React from 'react'
import ReactHighcharts from 'react-highcharts'
import moment from 'moment'
import defaultStyle from '../chartStyle'
import NoDataSegment from '../NoDataSegment'

const config = {
  ...defaultStyle,
  chart: {
    type: 'areaspline'
  },
  xAxis: {
    type: 'datetime'
  },
  tooltip: {
    split: true
  }
}

export default function MisunderstoodCharts({ data, period, noDataDisplay }) {
  if (noDataDisplay) {
    return <NoDataSegment />
  }

  const allMessages = {
    animation: false,
    name: 'Total',
    data: data.map(elem => [moment.utc(elem.date).valueOf(), elem.total])
  }

  config.series = [allMessages]
  return <ReactHighcharts config={config} domProps={{ id: '1' }} />
}
