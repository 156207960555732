import React from 'react'
import { Link } from 'react-router-dom'
import { MdDone } from 'react-icons/md'
import { IoIosArrowRoundForward } from 'react-icons/io'

import { Id } from 'types'

import './style.css'

type Props = Readonly<{
  createdQuestionId: Id
}>

const SuccessfulLabel: React.FunctionComponent<Props> = ({ createdQuestionId }) => (
  <div className='SuccessfulLabel-container'>
    <MdDone className='SuccessfulLabel-done_icon' />Success! You have created a new Q&A 🎉
    <Link className='SuccessfulLabel-link' to={`/qa/${createdQuestionId}`}>
      Create an answer <IoIosArrowRoundForward size={20}/>
    </Link>
  </div>
)

export default SuccessfulLabel
