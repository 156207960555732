import type * as React from 'react'
import * as smartSkillsForm from '@tolk-ai/bot-management-react-smart-skills-form'
import * as smartSkills from '@tolk-ai/bot-management-react-smart-skills'
import * as contentManagementSmartSkills from '@tolk-ai/content-management-smart-skills'
import type * as Axios from 'axios'
import * as browserUtils from '@tolk-genii/browser-utils'
import type * as History from 'history'

const smartSkillsFormComponent = (
  botManagementExpressAxios: Axios.AxiosInstance,
  document: Document,
  authManagementExpressBaseUrl: string,
  cookieDomain: string,
  history: History.History
): React.FC<smartSkillsForm.Properties> =>
  smartSkillsForm.component(
    contentManagementSmartSkills.smartSkillAxios.getOneSmartSkillInfos(
      browserUtils.authenticatedRequest(
        botManagementExpressAxios,
        authManagementExpressBaseUrl,
        browserUtils.removeCookie(document, cookieDomain),
        browserUtils.getCookie(document),
        browserUtils.setCookie(document, cookieDomain)
      )
    ),
    contentManagementSmartSkills.smartSkillAxios.getSmartSkillFormDetails(
      browserUtils.authenticatedRequest(
        botManagementExpressAxios,
        authManagementExpressBaseUrl,
        browserUtils.removeCookie(document, cookieDomain),
        browserUtils.getCookie(document),
        browserUtils.setCookie(document, cookieDomain)
      )
    ),
    contentManagementSmartSkills.smartSkillAxios.createOneSmartSkill(
      browserUtils.authenticatedRequest(
        botManagementExpressAxios,
        authManagementExpressBaseUrl,
        browserUtils.removeCookie(document, cookieDomain),
        browserUtils.getCookie(document),
        browserUtils.setCookie(document, cookieDomain)
      )
    ),
    contentManagementSmartSkills.smartSkillAxios.updateOneSmartSkill(
      browserUtils.authenticatedRequest(
        botManagementExpressAxios,
        authManagementExpressBaseUrl,
        browserUtils.removeCookie(document, cookieDomain),
        browserUtils.getCookie(document),
        browserUtils.setCookie(document, cookieDomain)
      )
    ),
    history
  )

  const smartSkillsComponent = (
    botManagementExpressAxios: Axios.AxiosInstance,
    document: Document,
    authManagementExpressBaseUrl: string,
    cookieDomain: string,
    history: History.History
  ): React.FC<smartSkills.Properties> =>
    smartSkills.component(
      history,
      contentManagementSmartSkills.smartSkillAxios.getManySmartSkills(
        browserUtils.authenticatedRequest(
          botManagementExpressAxios,
          authManagementExpressBaseUrl,
          browserUtils.removeCookie(document, cookieDomain),
          browserUtils.getCookie(document),
          browserUtils.setCookie(document, cookieDomain)
        )
      )
    )

  export { smartSkillsFormComponent, smartSkillsComponent }
