import 'react-dates/initialize'
import React from 'react'
import { connect } from 'react-redux'
import { Grid, Segment } from 'semantic-ui-react'
import { maybe } from '@sbizeul/fp-flow'

import operations from '../../../../redux/main/operations'
import DateRangePicker from './DateRangePicker'
import PeriodSelector from './PeriodSelector'
import channel from '../../../../modules/channel'
import bot from '../../../../modules/bot'

const style = {
  borderRadius: '3px',
  border: 'none',
  boxShadow: '0 2px 4px rgba(50, 50, 93,.1)',
  padding: '10px'
}

const Filters = ({
  setDateRange,
  setDateRangeDropDown,
  setActivePeriod,
  setActiveChannel,
  filters,
  channels,
  fetch,
  selectChannel,
  botId
}) => {
  const handleSelectChannel = option => {
    const { value, text } = option
    setActiveChannel({ id: value, name: text })
    fetch(maybe.get(botId), text)
  }

  return (
    <Segment style={style}>
      <Grid padded={false} verticalAlign='middle' stackable>
        <Grid.Row>
          <Grid.Column>
            <i className='large sliders horizontal icon' style={{ marginLeft: '20px' }} />
          </Grid.Column>
          <Grid.Column computer={5} widescreen={5}>
            <DateRangePicker
              fetch={fetch}
              filters={filters}
              dateRange={filters.dateRange}
              setDateRange={setDateRange}
              setDateRangeDropDown={setDateRangeDropDown}
            />
          </Grid.Column>

          {channels && channels.length > 1 && (
            <Grid.Column textAlign='center' computer={4} widescreen={4}>
              <channel.components.ChannelSelect
                channels={channels}
                onSelect={handleSelectChannel}
                selectedId={filters.channel.id}
              />
            </Grid.Column>
          )}
          <Grid.Column textAlign='center' computer={2} widescreen={2} />
        </Grid.Row>
      </Grid>
    </Segment>
  )
}

const mapStateToProps = state => ({
  filters: state.main.filters,
  channels: channel.selectors.getBySelectedBotToOption(state),
  botId: bot.selectors.getSelectedId(state)
})

export default connect(mapStateToProps, operations)(Filters)
