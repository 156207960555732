import { connect } from 'react-redux'
import { maybe, remoteData } from '@sbizeul/fp-flow'

import bot from 'modules/bot'
import synonym from 'modules/synonym'

import { fetchOneRequest, update } from '../actions'
import Keyword from '../components/Keyword'
import { getSelectedKeyword, getSelectedKeywordId, isSynonymAdding, getIsDeletingSynonym } from '../selectors'

const mapStateToProps = state => {
  return {
    keyword: getSelectedKeyword(state),
    selectedKeywordId: getSelectedKeywordId(state),
    languages: bot.selectors.getLanguages(state),
    synonyms: maybe.fold(remoteData.notAsked, synonym.selectors.getByKeywordId(state), getSelectedKeywordId(state)),
    isSynonymAdding: isSynonymAdding(state),
    isDeletingSynonym: getIsDeletingSynonym(state)
  }
}

const mapDispatchToProps = {
  fetchKeyword: fetchOneRequest,
  fetchSynonyms: synonym.actions.fetchSynonymsRequest,
  addSynonym: synonym.actions.addSynonymRequest,
  onDeleteSynonym: synonym.actions.deleteSynonymRequest,
  updateSynonym: synonym.actions.updateSynonymRequest,
  updateKeyword: update
}

export default connect(mapStateToProps, mapDispatchToProps)(Keyword)
