const DELETE_SYNONYM_REQUEST = 'synonym/DELETE_SYNONYM_REQUEST'
const DELETE_SYNONYM_SUCCESS = 'synonym/DELETE_SYNONYM_SUCCESS'
const DELETE_SYNONYM_FAILURE = 'synonym/DELETE_SYNONYM_FAILURE'

const FETCH_SYNONYMS_REQUEST = 'synonym/FETCH_SYNONYMS_REQUEST'
const FETCH_SYNONYMS_SUCCESS = 'synonym/FETCH_SYNONYMS_SUCCESS'
const FETCH_SYNONYMS_FAILURE = 'synonym/FETCH_SYNONYMS_FAILURE'

const ADD_SYNONYM_REQUEST = 'synonym/ADD_SYNONYM_REQUEST'
const ADD_SYNONYM_SUCCESS = 'synonym/ADD_SYNONYM_SUCCESS'
const ADD_SYNONYM_FAILURE = 'synonym/ADD_SYNONYM_FAILURE'

const UPDATE_SYNONYM_REQUEST = 'synonym/UPDATE_SYNONYM_REQUEST'
const UPDATE_SYNONYM_SUCCESS = 'synonym/UPDATE_SYNONYM_SUCCESS'
const UPDATE_SYNONYM_FAILURE = 'synonym/UPDATE_SYNONYM_FAILURE'

const fetchSynonymsRequest = keywordId => {
  return {
    type: FETCH_SYNONYMS_REQUEST,
    payload: keywordId
  }
}

const fetchSynonymsSuccess = (keywordId, synonyms) => {
  return {
    type: FETCH_SYNONYMS_SUCCESS,
    payload: { synonyms, keywordId }
  }
}

const fetchSynonymsFailure = (error, keywordId) => {
  return {
    type: FETCH_SYNONYMS_FAILURE,
    payload: { error, keywordId }
  }
}

const addSynonymRequest = (keywordId, synonym) => {
  return {
    type: ADD_SYNONYM_REQUEST,
    payload: {
      synonym,
      keywordId
    }
  }
}

const addSynonymSuccess = synonym => {
  return {
    type: ADD_SYNONYM_SUCCESS,
    payload: synonym
  }
}

const addSynonymFailure = error => {
  return {
    type: ADD_SYNONYM_FAILURE,
    payload: error
  }
}

const updateSynonymRequest = (keywordId, synonym) => {
  return {
    type: UPDATE_SYNONYM_REQUEST,
    payload: {
      synonym,
      keywordId
    }
  }
}

const updateSynonymSuccess = synonym => {
  return {
    type: UPDATE_SYNONYM_SUCCESS,
    payload: synonym
  }
}

const updateSynonymFailure = error => {
  return {
    type: UPDATE_SYNONYM_FAILURE,
    payload: error
  }
}

const deleteSynonymRequest = (keywordId, synonymId) => {
  return {
    type: DELETE_SYNONYM_REQUEST,
    payload: {
      keywordId,
      synonymId
    }
  }
}

const deleteSynonymSuccess = () => {
  return {
    type: DELETE_SYNONYM_SUCCESS
  }
}

const deleteSynonymFailure = errorMessage => {
  return {
    type: DELETE_SYNONYM_FAILURE,
    payload: errorMessage
  }
}

export {
  FETCH_SYNONYMS_REQUEST,
  FETCH_SYNONYMS_SUCCESS,
  FETCH_SYNONYMS_FAILURE,
  fetchSynonymsRequest,
  fetchSynonymsSuccess,
  fetchSynonymsFailure,
  ADD_SYNONYM_REQUEST,
  ADD_SYNONYM_SUCCESS,
  ADD_SYNONYM_FAILURE,
  addSynonymRequest,
  addSynonymSuccess,
  addSynonymFailure,
  DELETE_SYNONYM_REQUEST,
  DELETE_SYNONYM_SUCCESS,
  DELETE_SYNONYM_FAILURE,
  deleteSynonymRequest,
  deleteSynonymSuccess,
  deleteSynonymFailure,
  UPDATE_SYNONYM_REQUEST,
  UPDATE_SYNONYM_SUCCESS,
  UPDATE_SYNONYM_FAILURE,
  updateSynonymRequest,
  updateSynonymSuccess,
  updateSynonymFailure
}
