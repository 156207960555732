import React from 'react'
import ReactHighcharts from 'react-highcharts'
import * as R from 'ramda'
import NoDataSegment from '../NoDataSegment'
import defaultStyle from '../chartStyle'
import { connect } from 'react-redux'
import { getFunnel } from '../../../../../redux/analytics/funnel/selectors'
import fetchFunnel from '../../../../../redux/analytics/funnel/operations/fetchFunnel'

const config = {
  ...defaultStyle
}

const isEmpty = data => {
  return R.either(R.isEmpty, R.isNil)(data)
}

class HotPath extends React.Component {
  componentDidMount() {
    const { dateRange, channel, periods } = this.props
    if (!isEmpty(dateRange.start) && !isEmpty(dateRange.end)) {
      this.props.fetchFunnel({ dateRange, channel, periods })
    }
  }

  componentDidUpdate(prevProps) {
    const { dateRange, channel, periods } = this.props
    if (
      !isEmpty(dateRange.start) &&
      !isEmpty(dateRange.end) &&
      (dateRange !== prevProps.dateRange || channel !== prevProps.channel)
    ) {
      this.props.fetchFunnel({ dateRange, channel, periods })
    }
  }

  render() {
    const funnel = this.props.funnel
    if (isEmpty(funnel)) {
      return <NoDataSegment />
    } else {
      config.series = [
        {
          data: funnel,
          title: 'Hot Path',
          animation: true,
          name: 'Hot Path',
          keys: ['from', 'to', 'weight'],
          type: 'sankey'
        }
      ]
      return <ReactHighcharts config={config} domProps={{ id: 'funnel' }} />
    }
  }
}

const mapStateToProps = state => ({
  funnel: getFunnel(state),
  channel: state.main.filters.channel,
  dateRange: state.main.filters.dateRange,
  periods: state.main.filters.periods
})

const mapDispatchToProps = {
  fetchFunnel
}

export default connect(mapStateToProps, mapDispatchToProps)(HotPath)
