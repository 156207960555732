import actions from '../../actions'
import users from '../../users/operations'
import messages from '../../messages/operations'
import payloads from '../../payloads/operations'
import conversations from '../../conversations/operations'
import events from '../../events/operations'
import messagesHeat from '../../messagesHeat/operations'
import escaladesHeat from '../../escaladesHeat/operations'

const fetchAnalyticsTrafficMessages = filters => async dispatch => {
  dispatch(actions.fetchAnalyticsTrafficMessagesRequest())
  try {
    const conversationsByDayPromise = dispatch(conversations.fetchConversationsCountByDay(filters))
    const conversationsPromise = dispatch(conversations.fetchConversations(filters))
    const conversationsPrevPeriodPromise = dispatch(conversations.fetchConversationsPreviousPeriod(filters))
    const messagesPromise = dispatch(messages.fetchMessages(filters))
    const messagesCountByHourPromise = dispatch(messages.fetchMessagesCountByHour(filters))
    const messagesAverageByDayPromise = dispatch(messages.fetchMessagesAverageByDay(filters))
    const messagesPreviousPeriod = dispatch(messages.fetchMessagesPreviousPeriod(filters))
    const messageHeatPromise = dispatch(messagesHeat.fetchMessageHeat(filters))
    const escaladesHeatPromise = dispatch(escaladesHeat.fetchEscaladesHeat(filters))
    const usersPromise = dispatch(users.fetchUsers(filters))
    const usersPreviousPeriodPromise = dispatch(users.fetchUsersPreviousPeriod(filters))
    const usersByHourPromise = dispatch(users.fetchUsersByHour(filters))
    const usersAverageByDayPromise = dispatch(users.fetchUsersAverageByDay(filters))
    const payloadsPromise = dispatch(payloads.fetchPayloads(filters))

    await Promise.all([
      conversationsByDayPromise,
      conversationsPromise,
      conversationsPrevPeriodPromise,
      messagesPromise,
      messagesCountByHourPromise,
      messagesPreviousPeriod,
      messagesAverageByDayPromise,
      messageHeatPromise,
      escaladesHeatPromise,
      usersPromise,
      usersPreviousPeriodPromise,
      usersByHourPromise,
      usersAverageByDayPromise,
      payloadsPromise
    ])

    await dispatch(events.fetchCustomEventsData(filters))
    await dispatch(actions.fetchAnalyticsTrafficMessagesSuccess())
  } catch (e) {
    await dispatch(actions.fetchAnalyticsTrafficMessagesFailure())
    console.error(e)
  }
}

export default fetchAnalyticsTrafficMessages
