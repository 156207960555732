import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import _ from 'lodash'
import { Form, Button } from 'semantic-ui-react'
import { connect } from 'react-redux'
import contentOperations from '../../../../redux/intents/operations'
import InputField from '../../../components/InputField'
import { getIntentData, getActiveIso } from '../../../../redux/selectors'

const required = value => (value ? undefined : 'Required')
const noEmpty = value => (_.trim(value) ? undefined : 'No Empty')

const UpdateIntentForm = ({ putIntentInfos, handleSubmit, pristine, submitting, intent }) => (
  <Form onSubmit={handleSubmit(putIntentInfos)} autoComplete='off'>
    <label>Name</label>
    <Field validate={[required, noEmpty]} name='name' label='New Intent Name' component={InputField} type='text' />
    <label>Description</label>
    <Field
      validate={[required, noEmpty]}
      name='description'
      label='New Intent Description'
      component={InputField}
      type='text'
    />

    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Button inverted size='small' color='green' type='submit' disabled={pristine || submitting}>
        Update
      </Button>
    </div>
  </Form>
)

UpdateIntentForm.propTypes = {
  putIntentInfos: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired
}
const mapDispatchToProps = {
  putIntentInfos: contentOperations.putIntentInfos
}

const mapStateToProps = state => ({
  initialValues: {
    answers: getIntentData(state, getActiveIso(state)).answers,
    examples: getIntentData(state, getActiveIso(state)).examples,
    intentSlug: getIntentData(state, getActiveIso(state)).slug
  }
})

const InitializeFromStateForm = reduxForm({
  enableReinitialize: true,
  form: 'UpdateIntentForm'
})(UpdateIntentForm)

export default connect(mapStateToProps, mapDispatchToProps)(InitializeFromStateForm)
