import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { Form, Button, Grid, Table } from 'semantic-ui-react'
import { connect } from 'react-redux'
import onClickOutside from 'react-onclickoutside'
import _ from 'lodash'
import contentOperations from '../../../../redux/intents/operations'
import InputFieldGroup from '../../../components/InputFieldGroup'

const required = value => (value ? undefined : 'Required')
const noEmpty = value => (value !== '' && _.trim(value) ? undefined : 'No Empty')

class EditExampleInPlaceForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hover: false,
      editing: false
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.initialValues.example !== this.props.initialValues.example) {
      this.setState({ hover: false, editing: false })
    }
  }

  handleClickOutside = () => {
    this.setState({ hover: false, editing: false })
  }

  render() {
    const { initialValues, handleSubmit, putExample, deleteExample, id } = this.props
    // console.log('dans examplesinplace', initialValues)
    if (this.state.hover && !this.state.editing) {
      return (
        <Table.Cell key={id} onMouseLeave={() => this.setState({ hover: false })} style={{ height: '60px' }}>
          <Grid>
            <Grid.Column verticalAlign='middle' width={12}>
              {initialValues.example}
            </Grid.Column>
            <Grid.Column width={4}>
              <Button.Group size='small' floated='right' labeled>
                <Button
                  size='tiny'
                  labelPosition='left'
                  icon='pencil'
                  content='Edit'
                  onClick={() => this.setState({ editing: true })}
                />
                <Button.Or />
                <Button
                  color='red'
                  labelPosition='right'
                  size='tiny'
                  icon='delete'
                  content='Delete'
                  onClick={() => deleteExample(initialValues)}
                />
              </Button.Group>
            </Grid.Column>
          </Grid>
        </Table.Cell>
      )
    }

    if (this.state.editing) {
      return (
        <Table.Cell key={id} style={{ height: '60px' }}>
          <Form onSubmit={handleSubmit(putExample)}>
            <Grid>
              <Grid.Column width={12}>
                <Field
                  fluid
                  validate={[required, noEmpty]}
                  name='example'
                  label=''
                  placeholder={initialValues.example}
                  component={InputFieldGroup}
                  type='text'
                />
              </Grid.Column>
              <Grid.Column width={4}>
                <Button.Group size='small' floated='right' labeled width={4}>
                  <Button color='green' labelPosition='left' size='tiny' icon='save' content='Save' />
                  <Button.Or />
                  <Button
                    color='red'
                    labelPosition='right'
                    size='tiny'
                    icon='cancel'
                    content='Cancel'
                    onClick={() => this.setState({ editing: false })}
                  />
                </Button.Group>
              </Grid.Column>
            </Grid>
          </Form>
        </Table.Cell>
      )
    }

    return (
      <Table.Cell key={id} onMouseEnter={() => this.setState({ hover: true })} style={{ height: '60px' }}>
        {initialValues.example}
      </Table.Cell>
    )
  }
}

const mapDispatchToProps = {
  putExample: contentOperations.putIntentExample,
  deleteExample: contentOperations.deleteIntentExample
}

const ConnectedEditExampleInPlaceForm = connect(null, mapDispatchToProps)(onClickOutside(EditExampleInPlaceForm))

export default reduxForm({})(ConnectedEditExampleInPlaceForm)
