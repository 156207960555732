import * as R from 'ramda'
import { remoteData } from '@sbizeul/fp-flow'

import * as questionActions from './actions'
import * as array from 'helpers/array'
import * as map from 'helpers/map'

import {
  FetchAllFailureParam,
  FetchAllSuccessParam,
  FetchLabeledFailureParam,
  FetchLabeledSuccessParam,
  FetchOneFailureParam,
  FetchOneRequestParam,
  FetchOneSuccessParam,
  FetchQuestionReferencesFailureParam,
  FetchQuestionReferencesSuccessParam,
  Question,
  QuestionEntityAction,
  QuestionEntityState,
  SaveOneFailureParam,
  SaveOneParam
} from './types'

export const initialState: QuestionEntityState = {
  all: remoteData.notAsked(),
  allLabeledQuestions: remoteData.notAsked(),
  questionReferences: remoteData.notAsked(),
  byId: {}
}

const all = R.lensProp('all')
const allLabeledQuestions = R.lensProp('allLabeledQuestions')
const questionReferences = R.lensProp('questionReferences')
const byId = R.lensPath(['byId'])

const fetchAllRequest = (state: QuestionEntityState) =>
  R.over(all, remoteData.loading, state)

const fetchAllSuccess = (
  state: QuestionEntityState,
  { payload }: { payload: FetchAllSuccessParam }
) => R.set(all, remoteData.success(array.toMapById(payload)), state)

const fetchAllFailure = (
  state: QuestionEntityState,
  { payload }: { payload: FetchAllFailureParam }
) => R.set(all, remoteData.failure(payload), state)

const fetchOneRequest = (
  state: QuestionEntityState,
  { payload }: { payload: FetchOneRequestParam }
) => {
  const path = R.compose(byId, R.lensPath([payload])) as R.Lens
  return R.over(path, remoteData.loading, state)
}

const fetchOneSuccess = (
  state: QuestionEntityState,
  { payload }: { payload: FetchOneSuccessParam }
) => {
  const path = R.compose(byId, R.lensPath([payload.id])) as R.Lens
  return R.set(path, remoteData.success(payload), state)
}

const fetchOneFailure = (
  state: QuestionEntityState,
  { payload }: { payload: FetchOneFailureParam }
) => {
  const path = R.compose(byId, R.lensPath([payload.id])) as R.Lens
  return R.set(path, remoteData.failure(payload.error), state)
}

const fetchLabeledRequest = (state: QuestionEntityState) =>
  R.over(allLabeledQuestions, remoteData.loading, state)

const fetchLabeledSuccess = (
  state: QuestionEntityState,
  { payload }: { payload: FetchLabeledSuccessParam }
) => R.set(allLabeledQuestions, remoteData.success(payload), state)

const fetchLabeledFailure = (
  state: QuestionEntityState,
  { payload }: { payload: FetchLabeledFailureParam }
) => R.set(allLabeledQuestions, remoteData.failure(payload), state)

const fetchQuestionReferencesRequest = (state: QuestionEntityState) =>
  R.over(questionReferences, remoteData.loading, state)

const fetchQuestionReferencesSuccess = (
  state: QuestionEntityState,
  { payload }: { payload: FetchQuestionReferencesSuccessParam }
) => R.set(questionReferences, remoteData.success(payload), state)

const fetchQuestionReferencesFailure = (
  state: QuestionEntityState,
  { payload }: { payload: FetchQuestionReferencesFailureParam }
) => R.set(questionReferences, remoteData.failure(payload), state)

const sortByCreationDesc: (
  state: QuestionEntityState
) => QuestionEntityState = (state) =>
  R.pipe(
    remoteData.map<map.Map<Question>, Question[], any>(map.toArray),
    remoteData.map(R.sortWith([R.descend(R.prop('created_at'))])),
    remoteData.map(array.toMapById),
    (questions) => R.set(all, questions, state)
  )(state.all)

const saveOne = (
  state: QuestionEntityState,
  { payload }: { payload: SaveOneParam }
) => {
  const path = R.lensPath([payload.question.id, 'label'])
  return R.over(all, remoteData.map(R.set(path, payload.text)), state)
}

const saveOneSuccess = R.identity

const saveOneFailure = (
  state: QuestionEntityState,
  { payload }: { payload: SaveOneFailureParam }
) => R.set(all, remoteData.failure(payload), state)

export default function question(
  state: QuestionEntityState = initialState,
  action: QuestionEntityAction
) {
  switch (action.type) {
    case questionActions.FETCH_ALL_REQUEST:
      return fetchAllRequest(state)
    case questionActions.FETCH_ALL_SUCCESS:
      return fetchAllSuccess(state, action)
    case questionActions.FETCH_ALL_FAILURE:
      return fetchAllFailure(state, action)
    case questionActions.FETCH_ONE_REQUEST:
      return fetchOneRequest(state, action)
    case questionActions.FETCH_ONE_SUCCESS:
      return fetchOneSuccess(state, action)
    case questionActions.FETCH_ONE_FAILURE:
      return fetchOneFailure(state, action)
    case questionActions.FETCH_LABELED_REQUEST:
      return fetchLabeledRequest(state)
    case questionActions.FETCH_LABELED_SUCCESS:
      return fetchLabeledSuccess(state, action)
    case questionActions.FETCH_LABELED_FAILURE:
      return fetchLabeledFailure(state, action)
    case questionActions.FETCH_QUESTION_LINK_REQUEST:
      return fetchQuestionReferencesRequest(state)
    case questionActions.FETCH_QUESTION_LINK_SUCCESS:
      return fetchQuestionReferencesSuccess(state, action)
    case questionActions.FETCH_QUESTION_LINK_FAILURE:
      return fetchQuestionReferencesFailure(state, action)
    case questionActions.SORT_BY_CREATION_DESC:
      return sortByCreationDesc(state)
    case questionActions.SAVE_ONE_REQUEST:
      return saveOne(state, action)
    case questionActions.SAVE_ONE_SUCCESS:
      return saveOneSuccess(state)
    case questionActions.SAVE_ONE_FAILURE:
      return saveOneFailure(state, action)
    default:
      return state
  }
}
