import {
  pinQuestionToTapFlow,
  unpinQuestionToTapFlow
} from 'modules/question/actions'
import { Question, QuestionState } from 'modules/question/types'
import React, { useState } from 'react'
import { MdDone } from 'react-icons/md'
import { useDispatch } from 'react-redux'
import { CLOSE_DROPDOWN } from '.'
import { PinProps } from './types'

export const PIN_TO_TAPFLOW_REQUEST = 'question/PIN_TO_TAPFLOW_REQUEST'
export const UNPIN_TO_TAPFLOW_REQUEST = 'question/UNPIN_TO_TAPFLOW_REQUEST'

export const PinToTapFlow = ({
  question,
  setPinDropdownState,
  toggleParentState,
  setActionDescriptionText
}: PinProps) => {
  const dispatch = useDispatch()
  const [isInTapFlow, setIsInTapFlow] = useState(!!question.is_in_tapflow)

  const unpin = (question: Question) => {
    dispatch(
      unpinQuestionToTapFlow({
        ...question,
        is_in_tapflow: QuestionState.UNPIN
      })
    )
    toggleParentState(QuestionState.UNPIN)
    setIsInTapFlow(QuestionState.UNPIN)
  }
  const pin = (question: Question) => {
    dispatch(
      pinQuestionToTapFlow({
        ...question,
        is_in_tapflow: QuestionState.PIN
      })
    )
    toggleParentState(QuestionState.UNPIN)
    setIsInTapFlow(QuestionState.PIN)
  }

  const onClick = () => {
    setPinDropdownState(CLOSE_DROPDOWN)
    setActionDescriptionText('')
    if (isInTapFlow) {
      unpin(question)
    } else {
      pin(question)
    }
    return
  }

  return (
    <div
      role="option"
      aria-selected={isInTapFlow}
      className="item"
      onClick={onClick}
    >
      {isInTapFlow && <MdDone />}
      <span className="text" aria-selected={isInTapFlow}>
        Tap Flow
      </span>
    </div>
  )
}
