import types from './types'

const initialState = {
  fetching: false,
  fetchingOverTime: false
}

const payloadsReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.FETCH_PAYLOADS_REQUEST:
    return {
      ...state,
      fetching: true,
      data: null,
      count: null
    }
  case types.FETCH_PAYLOADS_NO_DATA:
    return { ...state, fetching: false }
  case types.FETCH_PAYLOADS_SUCCESS:
    return {
      ...state,
      fetching: false,
      data: action.payload.data,
      count: action.payload.count
    }

  case types.FETCH_PAYLOADS_OVER_TIME_REQUEST:
    return {
      ...state,
      fetchingOverTime: true,
      all: null
    }
  case types.FETCH_PAYLOADS_OVER_TIME_NO_DATA:
    return { ...state, fetchingOverTime: false }
  case types.FETCH_PAYLOADS_OVER_TIME_SUCCESS:
    return {
      ...state,
      fetchingOverTime: false,
      all: action.payload
    }

  default:
    return state
  }
}

export default payloadsReducer
