import React from 'react'

import { BASE_SKILL_LABELS, RGPD } from '../../types'

const RgpdButton = () => (
  <div className='skill-default--button skill-rgpd--button'>
    <p className='skill-default--label'>{BASE_SKILL_LABELS[RGPD]}</p>
  </div>
)

export default RgpdButton
