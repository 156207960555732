const SET_LOADING = 'tcfdash/main/SET_LOADING'
const REMOVE_LOADING = 'tcfdash/main/REMOVE_LOADING'

const SET_DATE_RANGE = 'tcfdash/main/SET_DATE_RANGE'
const SET_ACTIVE_PERIOD = 'tcfdash/main/SET_ACTIVE_PERIOD'
const SET_ACTIVE_CHANNEL = 'tcfdash/main/SET_ACTIVE_CHANNEL'
const SET_DATA_FILTERS = 'tcfdash/analytics/SET_DATA_FILTERS'

const LOGIN_REQUEST = 'tcfdash/main/LOGIN_REQUEST'
const LOGIN_SUCCESS = 'tcfdash/main/LOGIN_SUCCESS'
const LOGIN_FAILURE = 'tcfdash/main/LOGIN_FAILURE'

const VERIFY_AUTH_REQUEST = 'tcfdash/main/VERIFY_AUTH_REQUEST'
const VERIFY_AUTH_SUCCESS = 'tcfdash/main/VERIFY_AUTH_SUCCESS'
const VERIFY_AUTH_FAILURE = 'tcfdash/main/VERIFY_AUTH_FAILURE'

const LOGOUT = 'tcfdash/main/LOGOUT'

export default {
  SET_LOADING,
  REMOVE_LOADING,

  SET_DATE_RANGE,
  SET_ACTIVE_PERIOD,
  SET_ACTIVE_CHANNEL,
  SET_DATA_FILTERS,

  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,

  VERIFY_AUTH_REQUEST,
  VERIFY_AUTH_SUCCESS,
  VERIFY_AUTH_FAILURE,

  LOGOUT
}
