import actions from '../actions'
import users from '../users/operations'
import messages from '../messages/operations'
import delays from '../delays/operations'
import demographics from '../demographics/operations'
import intents from '../intents/operations'
import payloads from '../payloads/operations'
import conversations from '../conversations/operations'
import events from '../events/operations'
import words from '../words/operations'
import entities from '../entities/operations'
import answers from '../answers/operations'
import retention from '../retention/operations'
import nothandled from '../nothandled/operations'
import wordsusers from '../wordsusers/operations'
import misunderstood from '../misunderstood/operations'
import messagesHeat from '../messagesHeat/operations'
import escaladesHeat from '../escaladesHeat/operations'
import menuPersistent from '../menuPersistent/operations'
import referral from '../referral/operations'
import satisfaction from '../satisfaction/operations'

const fetchAnalyticsAll = filters => dispatch => {
  dispatch(actions.fetchAnalyticsRequest())
  Promise.all([
    dispatch(users.fetchUsers(filters)),
    dispatch(users.fetchNewUsers(filters)),
    dispatch(users.fetchUsersPreviousPeriod(filters)),
    dispatch(users.fetchUsersByHour(filters)),
    dispatch(messages.fetchMessages(filters)),
    dispatch(messages.fetchMessagesCountByHour(filters)),
    dispatch(messages.fetchMessagesAverageByDay(filters)),
    dispatch(users.fetchUsersAverageByDay(filters)),
    dispatch(demographics.fetchDemographics(filters)),
    dispatch(messages.fetchMessagesPreviousPeriod(filters)),
    dispatch(intents.fetchIntents(filters)),
    dispatch(intents.fetchIntentsPreviousPeriod(filters)),
    dispatch(intents.fetchIntentsOverTime(filters)),
    dispatch(delays.fetchDelays(filters)),
    dispatch(delays.fetchDelaysPreviousPeriod(filters)),
    dispatch(payloads.fetchPayloads(filters)),
    dispatch(payloads.fetchPayloadsOverTime(filters)),
    dispatch(conversations.fetchConversations(filters)),
    dispatch(conversations.fetchConversationsCountByHour(filters)),
    dispatch(conversations.fetchConversationsPreviousPeriod(filters)),
    dispatch(events.fetchBotConfig(filters.bot)),
    dispatch(words.fetchWordsCount(filters)),
    dispatch(entities.fetchEntities(filters)),
    dispatch(answers.fetchAnswers(filters)),
    dispatch(retention.fetchRetention(filters)),
    dispatch(nothandled.fetchNothandled(filters)),
    dispatch(wordsusers.fetchWordsusers(filters)),
    dispatch(misunderstood.fetchMisunderstood(filters)),
    dispatch(messagesHeat.fetchMessageHeat(filters)),
    dispatch(escaladesHeat.fetchEscaladesHeat(filters)),
    dispatch(menuPersistent.fetchMenuPersistent(filters)),
    dispatch(menuPersistent.fetchMenuPersistentUtil(filters)),
    dispatch(referral.fetchReferral(filters)),
    dispatch(referral.fetchReferralUtil(filters)),
    dispatch(satisfaction.fetchSatisfaction(filters))
  ]).then(() => dispatch(events.fetchCustomEventsData(filters)))
}

export default fetchAnalyticsAll
