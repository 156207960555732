import types from './types'

const escaladeHeatReducer = (state = { fetching: false }, action) => {
  switch (action.type) {
  case types.FETCH_ESCALADESBEGIN_REQUEST:
    return { ...state, fetching: true, data: null }

  case types.FETCH_ESCALADESBEGIN_NO_DATA:
    return { ...state, fetching: false }

  case types.FETCH_ESCALADESBEGIN_SUCCESS:
    return {
      ...state,
      fetching: false,
      data: action.payload.data
    }

  default:
    return state
  }
}

export default escaladeHeatReducer
