import { Id, ServerFailure } from 'types'

import * as routes from './routes'
import {
  CLEAN_CREATED_QUESTION,
  CleanCreatedQuestion,
  CONFIRM_ONE_QUESTION_FAILURE,
  CONFIRM_ONE_QUESTION_REQUEST,
  CONFIRM_ONE_QUESTION_SUCCESS,
  ConfirmOneFailure,
  ConfirmOneParam,
  ConfirmOneRequest,
  ConfirmOneSuccess,
  CREATE_QUESTION_FAILURE,
  CREATE_QUESTION_REQUEST,
  CREATE_QUESTION_SUCCESS,
  CreateQuestion,
  CreateQuestionFailure,
  CreateQuestionRequest,
  CreateQuestionSuccess,
  FetchOutOfScopeFailure,
  FetchOutOfScopeRequest,
  FetchOutOfScopeSuccess,
  FetchRequest,
  FetchSuccess,
  FetchFailure,
  FetchProgressRequest,
  FetchProgressSuccess,
  FetchProgressFailure,
  MONITOR_FETCH_OUT_OF_SCOPE_FAILURE,
  MONITOR_FETCH_OUT_OF_SCOPE_REQUEST,
  MONITOR_FETCH_OUT_OF_SCOPE_SUCCESS,
  MONITOR_FETCH_REQUEST,
  MONITOR_FETCH_SUCCESS,
  MONITOR_FETCH_FAILURE,
  MONITOR_FETCH_PROGRESS_REQUEST,
  MONITOR_FETCH_PROGRESS_SUCCESS,
  MONITOR_FETCH_PROGRESS_FAILURE,
  MONITOR_VALIDATE_TAGGING,
  MonitorLogs,
  NAVIGATE_TO_DASHBOARD,
  NAVIGATE_TO_OUT_OF_SCOPE,
  NavigateToDashboard,
  NavigateToOutOfScope,
  TaggedLog,
  ValidateTaggingAction,
  Progress
} from './types'

export const fetchRequest: () => FetchRequest = () => ({
  type: MONITOR_FETCH_REQUEST
})

export const fetchSuccess: (questions: MonitorLogs) => FetchSuccess = questions => ({
  type: MONITOR_FETCH_SUCCESS,
  payload: questions
})

export const fetchFailure: (serverFailure: ServerFailure) => FetchFailure = serverFailure => ({
  type: MONITOR_FETCH_FAILURE,
  payload: serverFailure
})

export const fetchProgressRequest: () => FetchProgressRequest = () => ({
  type: MONITOR_FETCH_PROGRESS_REQUEST
})

export const fetchProgressSuccess: (progress: Progress) => FetchProgressSuccess = progress => ({
  type: MONITOR_FETCH_PROGRESS_SUCCESS,
  payload: progress
})

export const fetchProgressFailure: (serverFailure: ServerFailure) => FetchProgressFailure = serverFailure => ({
  type: MONITOR_FETCH_PROGRESS_FAILURE,
  payload: serverFailure
})

export const fetchOutOfScopeRequest: () => FetchOutOfScopeRequest = () => ({
  type: MONITOR_FETCH_OUT_OF_SCOPE_REQUEST
})

export const fetchOutOfScopeSuccess: (userLogs: MonitorLogs) => FetchOutOfScopeSuccess = userLogs => ({
  type: MONITOR_FETCH_OUT_OF_SCOPE_SUCCESS,
  payload: userLogs
})

export const fetchOutOfScopeFailure: (serverFailure: ServerFailure) => FetchOutOfScopeFailure = serverFailure => ({
  type: MONITOR_FETCH_OUT_OF_SCOPE_FAILURE,
  payload: serverFailure
})

export const confirmOne: (confirmOneParam: ConfirmOneParam) => ConfirmOneRequest = confirmOneParam => ({
  type: CONFIRM_ONE_QUESTION_REQUEST,
  payload: confirmOneParam
})

export const confirmOneSuccess: () => ConfirmOneSuccess = () => ({
  type: CONFIRM_ONE_QUESTION_SUCCESS
})

export const confirmOneFailure: (serverFailure: ServerFailure) => ConfirmOneFailure = serverFailure => ({
  type: CONFIRM_ONE_QUESTION_FAILURE,
  payload: serverFailure
})

export const validateTagging: (value: TaggedLog[]) => ValidateTaggingAction = value => ({
  type: MONITOR_VALIDATE_TAGGING,
  payload: value
})

export const navigateToOutOfScope: () => NavigateToOutOfScope = () => ({
  type: NAVIGATE_TO_OUT_OF_SCOPE,
  payload: routes.OUT_OF_SCOPE
})

export const navigateToDashboard: () => NavigateToDashboard = () => ({
  type: NAVIGATE_TO_DASHBOARD,
  payload: routes.ANALYTICS_DASHBOARD
})

export const createQuestion: (param: CreateQuestion) => CreateQuestionRequest = param => ({
  type: CREATE_QUESTION_REQUEST,
  payload: param
})

export const createQuestionSuccess: (questionId: Id) => CreateQuestionSuccess = questionId => ({
  type: CREATE_QUESTION_SUCCESS,
  payload: questionId
})

export const createQuestionFailure: (serverFailure: ServerFailure) => CreateQuestionFailure = serverFailure => ({
  type: CREATE_QUESTION_FAILURE,
  payload: serverFailure
})

export const cleanCreatedQuestion: () => CleanCreatedQuestion = () => ({
  type: CLEAN_CREATED_QUESTION
})

export {
  CLEAN_CREATED_QUESTION,
  CONFIRM_ONE_QUESTION_FAILURE,
  CONFIRM_ONE_QUESTION_REQUEST,
  CONFIRM_ONE_QUESTION_SUCCESS,
  CREATE_QUESTION_FAILURE,
  CREATE_QUESTION_REQUEST,
  CREATE_QUESTION_SUCCESS,
  MONITOR_FETCH_OUT_OF_SCOPE_FAILURE,
  MONITOR_FETCH_OUT_OF_SCOPE_REQUEST,
  MONITOR_FETCH_OUT_OF_SCOPE_SUCCESS,
  MONITOR_FETCH_REQUEST,
  MONITOR_FETCH_SUCCESS,
  MONITOR_FETCH_FAILURE,
  MONITOR_FETCH_PROGRESS_REQUEST,
  MONITOR_FETCH_PROGRESS_SUCCESS,
  MONITOR_FETCH_PROGRESS_FAILURE,
  MONITOR_VALIDATE_TAGGING,
  NAVIGATE_TO_DASHBOARD,
  NAVIGATE_TO_OUT_OF_SCOPE
}
