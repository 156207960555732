import { maybe } from '@sbizeul/fp-flow'

import actions from '../actions'
import isResValid from '../../../_shared/isResValid'
import formatFilters from '../../../_shared/formatFilters'
import bot from '../../../../modules/bot'
import api from 'services/tolkApiLegacy'

const fetchUsersPreviousPeriod = rawFilters => (dispatch, getState) => {
  const { channelId, start, end, period } = formatFilters(rawFilters, true)
  const botId = bot.selectors.getSelectedId(getState())

  dispatch(actions.fetchUsersPreviousPeriodRequest())

  return api.get(
    `/analytics/${maybe.get(botId)}/${channelId}/users`,
    {
      params: {
        start,
        end,
        period
      }
    }
  ).then(response => {
      if (isResValid(response)) {
        const formatedResponse = {
          count: response.data.result.count
        }
        dispatch(actions.fetchUsersPreviousPeriodSuccess(formatedResponse))
      } else {
        dispatch(actions.fetchUsersPreviousPeriodFailure())
      }
    })
    .catch(err => {
      dispatch(actions.fetchUsersPreviousPeriodFailure(err))
    })
}

export default fetchUsersPreviousPeriod
