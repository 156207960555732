import * as React from 'react'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import './styles.css'
import Button from '../../../../components/Button'

export default function PageChanger({
  onPreviousPage,
  onNextPage,
  currentPage,
  maximumNumberOfElementsPerPage,
  realNumberOfElementsPerPage,
  previousIcon = <FaChevronLeft />,
  nextIcon = <FaChevronRight />
}) {
  const isFirstPage = currentPage === 0
  const isLastPage = realNumberOfElementsPerPage < maximumNumberOfElementsPerPage

  const currentIndex = 1 + maximumNumberOfElementsPerPage * currentPage
  const lastElementIndex = Math.max(realNumberOfElementsPerPage, 1) + maximumNumberOfElementsPerPage * currentPage

  return (
    <div className='PageChanger'>
      <div className='PageChanger-container'>
        {currentIndex} - {lastElementIndex}
        <Button className='ChangePageArrow' onClick={onPreviousPage} disabled={isFirstPage} display='custom'>
          {previousIcon}
        </Button>
        <Button className='ChangePageArrow' onClick={onNextPage} disabled={isLastPage} display='custom'>
          {nextIcon}
        </Button>
      </div>
    </div>
  )
}
