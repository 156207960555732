import { takeLatest, select, call, put } from 'redux-saga/effects'
import * as O from 'fp-ts/lib/Option'

import Api from 'services/tolkApi'
import bot from 'modules/bot'
import {
  SaveChannelInformationRequest,
  ChannelInformation,
  SAVE_CHANNEL_INFORMATION_REQUEST,
  FETCH_CHANNEL_INFORMATION_REQUEST,
  FetchChannelInformationRequest
} from './types'
import {
  fetchChannelInformationSuccess,
  fetchChannelInformationFailure,
  saveChannelInformationSuccess,
  saveChannelInformationFailure
} from './actions'

export function* fetch (action: FetchChannelInformationRequest) {
  const botIdMaybe = yield select(bot.selectors.getSelectedId)

  try {
    const response: Readonly<{ data: ChannelInformation }> = yield call(Api.get, `/bots/${botIdMaybe.value}/channels/${action.payload}`)
    yield put(fetchChannelInformationSuccess(O.some(response.data)))
  } catch (err) {
    const serverFailure = err.response.data
    yield put(fetchChannelInformationFailure(serverFailure))
  }
}

export function* save (action: SaveChannelInformationRequest) {
  try {
    const botIdMaybe = yield select(bot.selectors.getSelectedId)
    const response = yield call(Api.post, `/bots/${botIdMaybe.value}/channels/save`, action.payload)
    yield put(saveChannelInformationSuccess(response.data))
  } catch (err) {
    const serverFailure = err.response.data
    yield put(saveChannelInformationFailure(serverFailure))
  }
}

export function* root () {
  yield takeLatest(FETCH_CHANNEL_INFORMATION_REQUEST, fetch)
  yield takeLatest(SAVE_CHANNEL_INFORMATION_REQUEST, save)
}
