import React from 'react'
import * as R from 'ramda'
import { FaTimes } from 'react-icons/fa'

import TextInput from '../../../components/TextInput'
import Button from '../../../components/Button'

import './QuickReply.css'

export default function QuickReply(props) {
  const buttonsLens = R.lensPath(['objectAttachment', 'buttons'])

  const addButton = () => {
    const button = { type: 'postback', title: [''], payload: [''] }
    const { buttons } = props.quickReply.objectAttachment
    const quickReply = R.set(buttonsLens, buttons.concat(button), props.quickReply)
    props.updateAnswer(quickReply, props.intentSlug)
  }

  const removeButton = buttonIndex => {
    const { buttons } = props.quickReply.objectAttachment
    const quickReply = R.set(
      buttonsLens,
      buttons.filter((_, i) => i !== buttonIndex),
      props.quickReply
    )
    props.updateAnswer(quickReply, props.intentSlug)
  }

  const updateText = (title, buttonIndex) => {
    const titleLens = R.lensPath(['objectAttachment', 'text', 0])
    const quickReply = R.set(titleLens, title, props.quickReply)
    props.updateAnswer(quickReply, props.intentSlug)
  }

  const updateTitle = (title, buttonIndex) => {
    const titleLens = R.lensPath(['objectAttachment', 'buttons', buttonIndex, 'title', 0])
    const quickReply = R.set(titleLens, title, props.quickReply)
    props.updateAnswer(quickReply, props.intentSlug)
  }

  const updatePayload = (payload, buttonIndex) => {
    const titleLens = R.lensPath(['objectAttachment', 'buttons', buttonIndex, 'payload', 0])
    const quickReply = R.set(titleLens, payload, props.quickReply)
    props.updateAnswer(quickReply, props.intentSlug)
  }

  const removeAnswer = () => {
    props.removeAnswer(props.quickReply.id, props.intentSlug)
  }

  const renderButtons = (button, index) => {
    return (
      <div className='QuickReply_old-buttons--button' key={`QuickReply_old-button_${index}`}>
        <TextInput
          className='QuickReply_old-buttons--button---reply'
          type='rounded'
          value={button.title[0]}
          placeholder='Quick Reply displayed to users...'
          onChangeText={text => updateTitle(text, index)}
          key={`ButtonTitle_${props.quickReply.id}`}
        />
        <TextInput
          className='QuickReply_old-buttons--button---payload'
          type='rounded'
          value={button.payload[0]}
          placeholder='Payload'
          onChangeText={text => updatePayload(text, index)}
          key={`ButtonPayload_${props.quickReply.id}`}
        />
        {index > 0 && (
          <FaTimes
            className='QuickReply_old-buttons--button---delete'
            key={`ButtonRemove_${props.quickReply.id}`}
            onClick={() => removeButton(index)}
          />
        )}
      </div>
    )
  }

  return (
    <div className='QuickReply_old'>
      <div className='QuickReply_old-delete'>
        <FaTimes className='QuickReply_old-delete--icon' onClick={removeAnswer} />
      </div>
      <div className='QuickReply_old-text'>
        <TextInput
          type='rounded'
          value={props.quickReply.objectAttachment.text[0]}
          placeholder='Type your message preceeding quick replies...'
          onChangeText={text => updateText(text, props.quickReply.id)}
        />
      </div>
      <div className='QuickReply_old-buttons'>{props.quickReply.objectAttachment.buttons.map(renderButtons)}</div>
      <div className='QuickReply_old-actions'>
        <Button display='rounded' onClick={addButton} className='QuickReply_old-actions--addQuickReply'>
          + Add Quick Reply
        </Button>
      </div>
    </div>
  )
}
