import { call, put, takeLatest, select } from 'redux-saga/effects'
import { maybe, Maybe } from '@sbizeul/fp-flow'

import Api from 'services/tolkApiLegacy'

import bot from '../bot'
import * as actions from './actions'

import { CreateOneRequest, FetchAllRequest, DeleteOneRequest, SaveOneRequest } from './types'
import { Id } from 'types'

export const getTrainingQuestionsRoute = (botId: Maybe<Id>, questionId: Id) =>
  `/bot/${maybe.getOrElse(() => 'unknown', botId)}/questions/${questionId}/trainings`

export const getTrainingQuestionRoute = (botId: Maybe<Id>, questionId: Id, trainingQuestionId: Id) =>
  `${getTrainingQuestionsRoute(botId, questionId)}/${trainingQuestionId}`

export function* fetchAll (action: FetchAllRequest) {
  const botId = yield select(bot.selectors.getSelectedId)
  const questionId = action.payload

  try {
    const { data } = yield call(Api.get, getTrainingQuestionsRoute(botId, questionId))

    yield put(actions.fetchAllSuccess({ questionId, trainingQuestions: data }))
  } catch (e) {
    const serverFailure = e.response.data
    yield put(actions.fetchAllFailure({ questionId, error: serverFailure }))
  }
}

export function* createOne (action: CreateOneRequest) {
  const botId = yield select(bot.selectors.getSelectedId)
  const { questionId, text } = action.payload

  const newTrainingQuestion = { question_id: questionId, text }

  try {
    const { data } = yield call(Api.post, getTrainingQuestionsRoute(botId, questionId), newTrainingQuestion)
    yield put(actions.createOneSuccess(data))
    yield put(actions.fetchAll(questionId))
  } catch (e) {
    const serverFailure = e.response.data
    yield put(actions.createOneFailure(serverFailure))
  }
}

export function* deleteOne (action: DeleteOneRequest) {
  const botId = yield select(bot.selectors.getSelectedId)
  const { questionId, trainingQuestionId } = action.payload

  try {
    const { data } = yield call(Api.delete, getTrainingQuestionRoute(botId, questionId, trainingQuestionId))

    yield put(actions.deleteOneSuccess(data))
    yield put(actions.fetchAll(questionId))
  } catch (e) {
    const serverFailure = e.response.data
    yield put(actions.deleteOneFailure(serverFailure))
  }
}

export function* saveOne (action: SaveOneRequest) {
  const botId = yield select(bot.selectors.getSelectedId)
  const { trainingQuestion, text } = action.payload
  try {
    const { data } = yield call(
      Api.put,
      getTrainingQuestionRoute(botId, trainingQuestion.question_id, trainingQuestion.id),
      { ...trainingQuestion, text }
    )

    yield put(actions.saveOneSuccess(data))
    yield put(actions.fetchAll(trainingQuestion.question_id))
  } catch (e) {
    const serverFailure = e.response.data
    yield put(actions.saveOneFailure(serverFailure))
  }
}

export function* root () {
  yield takeLatest(actions.FETCH_ALL_REQUEST, fetchAll)
  yield takeLatest(actions.CREATE_ONE_REQUEST, createOne)
  yield takeLatest(actions.CREATE_ONE_SUCCESS, bot.saga.fetchPublishStatus)
  yield takeLatest(actions.DELETE_ONE_REQUEST, deleteOne)
  yield takeLatest(actions.DELETE_ONE_SUCCESS, bot.saga.fetchPublishStatus)
  yield takeLatest(actions.SAVE_ONE_REQUEST, saveOne)
  yield takeLatest(actions.SAVE_ONE_SUCCESS, bot.saga.fetchPublishStatus)
}
