import React, { FunctionComponent } from 'react'
import { MdMenu } from 'react-icons/md'
import { IoMdMic } from 'react-icons/io'
import * as R from 'ramda'
import { v4 as uuid } from 'uuid'

import { coloursToTheme } from 'modules/bot/models.typed'
import { WebchatTheme } from 'modules/bot/types'
import { IsoLanguage } from 'helpers/language'

import './styles.css'

type Props = Readonly<{
  botName: string
  greetings: string
  language: IsoLanguage
  colours: WebchatTheme
  avatar: string
}>

const WebchatPreview: FunctionComponent<Props> = ({
  botName,
  greetings,
  colours,
  avatar
}: Props) => (
  <div className="webchat-preview-component">
    <div className="webchat-preview-box">
      <div
        className="webchat-preview-header"
        style={{
          backgroundImage: `radial-gradient(circle at 100% 12%, ${colours.firstColour}, ${colours.firstColour})`,
          color: colours.textColour
        }}
      >
        <p className="webchat-preview-close">X</p>
        <p className="webchat-preview-title">{botName}</p>
      </div>
      <ul
        className="webchat-preview-body"
        style={{ color: colours.textColour }}
      >
        {greetings === '' ? (
          <div>
            <span
              className="webchat-preview-empty"
              style={{ backgroundColor: colours.secondColour }}
            />
            <span
              className="webchat-preview-empty webchat-preview-empty-second"
              style={{ backgroundColor: colours.secondColour }}
            />
          </div>
        ) : (
          R.split('\n\n', greetings).map((message) => (
            <li key={uuid()}>
              <span
                style={{ backgroundColor: colours.secondColour }}
                className="webchat-preview-message"
              >
                {message}
              </span>
            </li>
          ))
        )}
        <li
          style={{ backgroundColor: colours.thirdColour }}
          className="webchat-preview-bot-response"
        >
          …
        </li>
      </ul>
      <div className="webchat-preview-footer">
        <MdMenu className="webchat-preview-params" />
        <div className="webchat-preview-input">
          <IoMdMic />
        </div>
      </div>
    </div>
    <div
      className="webchat-preview-icon"
      style={{
        backgroundImage: `url(${avatar})`,
        backgroundColor: colours.firstColour
      }}
    />
  </div>
)

type Theme = Readonly<{
  firstColor: string
  secondColor?: string
  thirdColor: string
}>

type WrapperProps = Omit<Props, 'colours'> &
  Readonly<{
    theme: Theme
  }>

const WebchatPreviewWrapper: FunctionComponent<WrapperProps> = ({
  theme,
  ...props
}) => <WebchatPreview {...props} colours={coloursToTheme(theme)} />

export default WebchatPreviewWrapper
