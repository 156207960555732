import React from 'react'
import ReactHighcharts from 'react-highcharts'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import NoDataSegment from '../NoDataSegment'
import defaultStyle from '../chartStyle'

const config = {
  ...defaultStyle,
  yAxis: [
    {
      title: {
        text: 'Messages'
      }
    },
    {
      title: {
        text: 'Delays'
      },
      opposite: true
    }
  ],

  xAxis: [
    {
      type: 'datetime',
      crosshair: true
    }
  ]
}

export default function MessagesAndDelays({ data, delay, period }) {
  if (isEmpty(data) || isEmpty(delay)) {
    return <NoDataSegment />
  }
  const messages = {
    title: null,
    type: 'column',
    animation: false,
    name: 'Messages',
    data: data.map(elem => {
      if (elem.total === 0) {
        return [moment.utc(elem.time).valueOf(), null]
      }
      return [moment.utc(elem.time).valueOf(), elem.total]
    })
  }
  const delays = {
    title: null,
    type: 'spline',
    yAxis: 1,
    tooltip: {
      valueSuffix: 's'
    },
    animation: false,
    name: 'Delays',
    data: delay.map(elem => [moment.utc(elem.time).valueOf(), elem.averageReplyTime])
  }
  config.series = [messages, delays]

  return <ReactHighcharts config={config} domProps={{ id: 'MessagesAndDelays' }} />
}
