import Highcharts from 'react-highcharts'

const colors = ['#3B86FF', '#77E5AA', '#093fb9', '#6d00f6', '#FF006E', '#FFBE0B', '#1EFFBC', '#ff8b12']
const gradients = ['#87B5FF', '#9AFFC9', '#0078ff', '#852cf5', '#FC3C8F', '#FFD41F', '#7CFFD8', '#FFB364']

export default Highcharts.Highcharts.setOptions({
  colors: Highcharts.Highcharts.map(colors, (color, i) => ({
    linearGradient: {
      x1: 0,
      y1: 0,
      x2: 0,
      y2: 1
    },
    stops: [
      [0, color],
      [1, gradients[i]]
    ]
  }))
})

Highcharts.Highcharts.setOptions({
  chart: {
    style: {
      fontFamily: 'Roboto'
    }
  }
})
