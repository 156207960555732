import { createSelector } from 'reselect'
import { remoteData } from '@sbizeul/fp-flow'

import * as map from 'helpers/map'
import { AppState } from '../keyword/types'

const getKeywordsState = (state: AppState) => state.entities.keyword

const getAll = createSelector(getKeywordsState, (state) =>
  remoteData.map(map.toArray, state.all)
)

export {
  getAll
}
