import { call, put, takeLatest, select } from 'redux-saga/effects'

import { maybe } from '@sbizeul/fp-flow'

import bot from 'modules/bot'
import Api from 'services/tolkApi'

import * as synonymActions from './actions'

export function* fetchSynonyms(action) {
  const selectedBotId = yield select(bot.selectors.getSelectedId)
  const botId = maybe.getOrElse(() => 'unknownBot', selectedBotId)
  const keywordId = action.payload
  try {
    const { data } = yield call(Api.get, `/bots/${botId}/keywords/${keywordId}/synonyms`)
    yield put(synonymActions.fetchSynonymsSuccess(keywordId, data))
  } catch (error) {
    yield put(synonymActions.fetchSynonymsFailure(error, keywordId))
  }
}

export function* addSynonym(action) {
  const selectedBotId = yield select(bot.selectors.getSelectedId)
  const botId = maybe.getOrElse(() => 'unknownBot', selectedBotId)
  const keywordId = action.payload.keywordId
  try {
    const url = `/bots/${botId}/keywords/${keywordId}/synonyms`
    const { data } = yield call(Api.post, url, action.payload.synonym)
    yield put(synonymActions.addSynonymSuccess(data))
    yield put(synonymActions.fetchSynonymsRequest(keywordId))
  } catch (error) {
    yield put(synonymActions.addSynonymFailure(error))
  }
}

export function* deleteSynonym(action) {
  const selectedBotID = yield select(bot.selectors.getSelectedId)
  const botID = maybe.getOrElse(() => 'unknownBot', selectedBotID)
  const keywordID = action.payload.keywordId
  const synonymID = action.payload.synonymId
  try {
    yield call(Api.delete, `/bots/${botID}/keywords/${keywordID}/synonyms/${synonymID}`)
    yield put(synonymActions.deleteSynonymSuccess())
    yield put(synonymActions.fetchSynonymsRequest(keywordID))
  } catch (error) {
    yield put(synonymActions.deleteSynonymFailure(error))
  }
}

export function* updateSynonym(action) {
  const selectedBotId = yield select(bot.selectors.getSelectedId)
  const botId = maybe.getOrElse(() => 'unknownBot', selectedBotId)
  const keywordId = action.payload.keywordId
  const synonymId = action.payload.synonym.id
  try {
    const url = `/bots/${botId}/keywords/${keywordId}/synonyms/${synonymId}`
    const { data } = yield call(Api.patch, url, { value: action.payload.synonym.value })
    yield put(synonymActions.updateSynonymSuccess(data))
    yield put(synonymActions.fetchSynonymsRequest(keywordId))
  } catch (error) {
    yield put(synonymActions.updateSynonymFailure(error))
  }
}

export function* root() {
  yield takeLatest(synonymActions.FETCH_SYNONYMS_REQUEST, fetchSynonyms)
  yield takeLatest(synonymActions.ADD_SYNONYM_REQUEST, addSynonym)
  yield takeLatest(synonymActions.ADD_SYNONYM_SUCCESS, bot.saga.fetchPublishStatus)
  yield takeLatest(synonymActions.DELETE_SYNONYM_REQUEST, deleteSynonym)
  yield takeLatest(synonymActions.DELETE_SYNONYM_SUCCESS, bot.saga.fetchPublishStatus)
  yield takeLatest(synonymActions.UPDATE_SYNONYM_REQUEST, updateSynonym)
  yield takeLatest(synonymActions.UPDATE_SYNONYM_SUCCESS, bot.saga.fetchPublishStatus)
}
