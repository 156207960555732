import React, { useEffect } from 'react'
import * as R from 'ramda'
import { remoteData, RemoteData, Maybe, maybe } from '@sbizeul/fp-flow'
import { MdMail, MdTextFields, MdAttachFile } from 'react-icons/md'
import * as option from 'fp-ts/lib/Option'

import answer from 'modules/answer'
import { Answer } from 'modules/answer/types'
import { renderNothing, renderServerFailure, renderLoader } from 'helpers/react'
import SkillHeader from 'components/Header'
import TextContentEditor from 'components/TextContentEditor'
import EmailContentEditor from 'components/EmailContentEditor'
import FileContentEditor from 'components/FileContentEditor'
import { Id, ServerFailure } from 'types'

import FormResponse from './FormResponse'
import { isFormSkillValid } from '../../models.typed'
import * as routes from '../../routes'
import { FormSkillContent, InputId, ParamsId, SaveFormResponseParams, FormResponseContent } from '../../types'

import './styles.css'
import { emptySimpleAnswer } from 'modules/answer/models.typed'

type Fetch = (skillId: Maybe<Id>) => unknown

type CreateFormContent = () => unknown
type CreateFormEmailContent = () => unknown
type CreateFormFileContent = () => unknown

type UpdateContent = (params: { content: InputId }) => unknown

type RemoveContent = (id: ParamsId) => unknown

type UpdateFormConclusion = (conclusionMessage: Maybe<Answer>) => unknown

type SaveForm = (skillContent: FormSkillContent) => unknown

const renderFormSkills:
  (updateContent: UpdateContent, removeContent: RemoveContent) => (formContent: FormSkillContent) => JSX.Element[] =
  (updateContent, removeContent) => form =>
    R.map(
      inputId => {
        if (inputId.type === 'email') {
          return <EmailContentEditor
          key={inputId.id}
          pathToId={R.lensProp('id')}
          pathToText={R.lensPath(['text', 0])}
          textContent={inputId}
          updateContent={updateContent}
          removeContent={removeContent}
        />
        } else if (inputId.type === 'file') {
          return <FileContentEditor
          key={inputId.id}
          pathToId={R.lensProp('id')}
          pathToText={R.lensPath(['text', 0])}
          textContent={inputId}
          updateContent={updateContent}
          removeContent={removeContent}
        />
        }
        return <TextContentEditor
        key={inputId.id}
        pathToId={R.lensProp('id')}
        pathToText={R.lensPath(['text', 0])}
        textContent={inputId}
        updateContent={updateContent}
        removeContent={removeContent}
      />
      },
      form.content
    )

const renderConclusionAnswer: (updateFormConclusion: UpdateFormConclusion) => (formSkill: FormSkillContent) => JSX.Element = updateFormConclusion => (formSkill) =>
  <answer.components.AnswerEditorSimpleContainer
    answer={maybe.of(option.getOrElse(() => emptySimpleAnswer(('fr_FR'), formSkill.botId))(formSkill.conclusionAnswer))}
    onAnswerUpdated={updateFormConclusion} />

const renderCreateContentMenu: (createFormContent: CreateFormContent, createFormEmailContent: CreateFormEmailContent, createFormFileContent: CreateFormFileContent) => JSX.Element =
  (createFormContent, createFormEmailContent, createFormFileContent) => (
    <div className='CreateContentMenu'>
      <h1 className='CreateContentMenu-title'>Add Element</h1>
      <ul>
        <li className='CreateContentMenu-box' onClick={createFormContent}>
          <MdTextFields className='CreateContentMenu-icon' />
          <span className='CreateContentMenu-text'>Free input</span>
        </li>
        <li className='CreateContentMenu-box' onClick={createFormEmailContent}>
          <MdMail className='CreateContentMenu-icon' />
          <span className='CreateContentMenu-text'>Email input</span>
        </li>
        <li className='CreateContentMenu-box' onClick={createFormFileContent}>
          <MdAttachFile className='CreateContentMenu-icon' />
          <span className='CreateContentMenu-text'>File input</span>
        </li>
      </ul>
    </div>
  )

type FormBuilderProps = Readonly<{
  formSkill: FormSkillContent
  isModified: boolean

  createFormContent: CreateFormContent,
  createFormEmailContent: CreateFormEmailContent,
  createFormFileContent: CreateFormFileContent,
  removeContent: RemoveContent
  saveForm: SaveForm
  updateContent: UpdateContent
  updateFormConclusion: UpdateFormConclusion
}>

const FormBuilder: React.FunctionComponent<FormBuilderProps> = ({
  createFormContent,
  createFormEmailContent,
  createFormFileContent,
  formSkill,
  isModified,
  removeContent,
  saveForm,
  updateContent,
  updateFormConclusion
}) => (
  <React.Fragment>
    <SkillHeader
      label={formSkill.name}
      canItBeSaved={isModified && isFormSkillValid(formSkill)}
      route={routes.ALL_SKILLS}
      save={() => saveForm(formSkill)}
    />
    <div className='FormBuilder-containers'>
      <h1 className='FormBuilder-containers--title'>Assistant questions</h1>
      <hr className='FormBuilder-content--separator'/>
      <div className='FormBuilder-content'>
        {renderFormSkills(updateContent, removeContent)(formSkill)}
        {renderCreateContentMenu(createFormContent, createFormEmailContent, createFormFileContent)}
      </div>
    </div>
    <div className='FormBuilder-containers'>
      <h1 className='FormBuilder-containers--title'>Closing message</h1>
      <hr className='FormBuilder-content--separator'/>
      <div className='FormBuilder-content'>
        {renderConclusionAnswer(updateFormConclusion)(formSkill)}
      </div>
    </div>
  </React.Fragment>
)

type RemoteFormBuilderProps = Omit<FormBuilderProps, 'formSkill'> & Readonly<{
  formSkill: RemoteData<ServerFailure, FormSkillContent>
  formResponse: RemoteData<ServerFailure, FormResponseContent>
  formResponseError: option.Option<ServerFailure>
  formId: Maybe<Id>

  fetch: Fetch
  fetchFormResponse: (skillId: Maybe<Id>) => unknown
  saveFormResponse: (formReponseParam: SaveFormResponseParams) => unknown
}>

const RemoteFormBuilder: React.FunctionComponent<RemoteFormBuilderProps> = ({
  createFormContent,
  createFormEmailContent,
  createFormFileContent,
  formSkill,
  formResponse,
  formId,
  fetch,
  fetchFormResponse,
  formResponseError,
  isModified,
  removeContent,
  saveForm,
  updateContent,
  updateFormConclusion,
  saveFormResponse,
  ...answerProps
}) => {
  useEffect(() => {
    fetch(formId)
    fetchFormResponse(formId)
    // eslint-disable-next-line
  }, [])

  return (
    <div>
      {
        remoteData.fold(
          {
            Success: skill =>
              <FormBuilder
                formSkill={skill}
                isModified={isModified}
                createFormContent={createFormContent}
                createFormEmailContent={createFormEmailContent}
                createFormFileContent={createFormFileContent}
                updateContent={updateContent}
                removeContent={removeContent}
                saveForm={saveForm}
                updateFormConclusion={updateFormConclusion}
                {...answerProps}
              />,
            Failure: renderServerFailure,
            Loading: () => renderLoader('please wait...'),
            NotAsked: renderNothing
          },
          formSkill
        )
      }
      {
        remoteData.fold(
          {
            Success: formResponse =>
              <FormResponse
                formId={formId}
                formResponse={formResponse}
                saveFormResponse={saveFormResponse}
                formResponseError={formResponseError}
              />,
            Failure: renderServerFailure,
            Loading: () => renderLoader('please wait...'),
            NotAsked: renderNothing
          },
          formResponse
        )
      }
    </div>
  )
}

export default RemoteFormBuilder
