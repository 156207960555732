import React from 'react'
import { Button, Icon, Modal } from 'semantic-ui-react'
import { remoteData } from '@sbizeul/fp-flow'

import Entities from './Entities'
import CreateEntityForm from '../CreateEntityForm'
import { Loader } from '../../../../components/Loading'
import { renderFailure } from '../../../../helpers/react'

import './styles.css'

class RemoteEntities extends React.PureComponent {
  handleOpen = this.props.openCreateForm

  handleClose = this.props.closeCreateForm

  componentDidMount() {
    this.props.fetchEntities()
  }

  render() {
    const { entities } = this.props
    return remoteData.fold(
      {
        Success: this.renderEntities,
        NotAsked: renderLoading,
        Loading: renderLoading,
        Failure: renderFailure
      },
      entities
    )
  }

  renderEntities = entities => {
    return (
      <React.Fragment>
        <div className='Entities-actionBar'>{this.addCreateEntityFormModal()}</div>
        <Entities entities={entities} />
      </React.Fragment>
    )
  }

  addCreateEntityFormModal() {
    const { createEntity, isCreating, isOpenCreateForm } = this.props
    return (
      <Modal
        open={isOpenCreateForm}
        closeOnEscape={true}
        closeOnDimmerClick={true}
        onClose={this.handleClose}
        trigger={
          <Button inverted size='small' color='green' onClick={this.handleOpen}>
            <Icon name='add' />
            Create an Entity
          </Button>
        }
      >
        <Modal.Header>Create an Entity</Modal.Header>
        <Modal.Content>
          <CreateEntityForm createEntity={createEntity} isCreating={isCreating} onClose={this.handleClose} />
        </Modal.Content>
      </Modal>
    )
  }
}

function renderLoading() {
  return <Loader description='Fetching entities' />
}

export default RemoteEntities
