import React from 'react'
import { Grid } from 'semantic-ui-react'

import { getVariation } from '../../../utils'
import Tiles from '../../../components/Tiles'

import '../../index.css'

const Messages = ({
  conversations,
  fetchingConversations,
  conversationsPrev,
  fetchingConversationsPrev,
  messages,
  fetchingMessages,
  messagesPrev,
  fetchingMessagesPrev,
  payloads,
  fetchingPayloads,
  comparisonDateRange,
}) => {
  return(
    <Grid columns={4}>
      <Grid.Row>
        <Grid.Column mobile={8} tablet={4} computer={4} widescreen={4}>
          <Tiles
            name='Messages sent by users'
            value={messages}
            comparison={{
              variation: getVariation(messagesPrev, messages),
              prevValue: messagesPrev,
              comparisonDateRange
            }}
            loading={fetchingMessages || fetchingMessagesPrev}
          />
        </Grid.Column>
        <Grid.Column mobile={8} tablet={4} computer={4} widescreen={4}>
          <Tiles
            name='Conversations'
            value={conversations}
            comparison={{
              variation: getVariation(conversationsPrev, conversations),
              prevValue: conversationsPrev,
              comparisonDateRange
            }}
            loading={fetchingConversations || fetchingConversationsPrev}
            moreInfo='Number of conversations. A conversation is ended by 15 min of inactivity.'
          />
        </Grid.Column>
        <Grid.Column mobile={8} tablet={4} computer={4} widescreen={4}>
          <Tiles
            name='User messages per conv.'
            value={(messages / conversations).toPrecision(2)}
            comparison={{
              variation: getVariation(messagesPrev / conversationsPrev, messages / conversations),
              prevValue: (messagesPrev / conversationsPrev).toPrecision(2),
              comparisonDateRange
            }}
            moreInfo='Average number of user messages per conversation'
            loading={fetchingMessages || fetchingConversations || fetchingMessagesPrev || fetchingConversationsPrev}
          />
        </Grid.Column>
        <Grid.Column mobile={8} tablet={4} computer={4} widescreen={4}>
          <Tiles name='Buttons clicked' value={payloads} loading={fetchingPayloads} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
)}

Messages.defaultProps = {
  conversations: null,
  conversationsPrev: null,
  messages: null,
  messagesPrev: null,
  messagesByDay: null,
  messagesByHour: null,
  messagesHeat: null,
  escaladesHeat: null,
  usersByDay: null,
  usersByHour: null,
  payloads: null,
  usersData: null
}

export default Messages
