import types from './types'

/* fetchMisunderstood */
const fetchMisunderstoodRequest = () => ({
  type: types.FETCH_MISUNDERSTOOD_REQUEST
})

const fetchMisunderstoodSuccess = payload => ({
  type: types.FETCH_MISUNDERSTOOD_SUCCESS,
  payload
})

const fetchMisunderstoodFailure = errors => ({
  type: types.FETCH_MISUNDERSTOOD_NO_DATA,
  errors
})

/* fetchMisunderstoodAverage */
const fetchMisunderstoodAverageRequest = () => ({
  type: types.FETCH_MISUNDERSTOOD_AVERAGE_REQUEST
})

const fetchMisunderstoodAverageSuccess = payload => ({
  type: types.FETCH_MISUNDERSTOOD_AVERAGE_SUCCESS,
  payload
})

const fetchMisunderstoodAverageFailure = errors => ({
  type: types.FETCH_MISUNDERSTOOD_AVERAGE_NO_DATA,
  errors
})

export default {
  fetchMisunderstoodRequest,
  fetchMisunderstoodSuccess,
  fetchMisunderstoodFailure,

  fetchMisunderstoodAverageRequest,
  fetchMisunderstoodAverageSuccess,
  fetchMisunderstoodAverageFailure
}
