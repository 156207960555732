import { createSelector } from 'reselect'
import { remoteData } from '@sbizeul/fp-flow'
import { createMatchSelector } from 'connected-react-router'
import * as R from 'ramda'

export const getState = state => state.admin

export const getSelectedUserId = createSelector(getState, state => state.selectedUserId)

export const getSelectedUser = state => {
  return R.pipe(
    remoteData.fromMaybe,
    remoteData.chain(id => state.user.byId[id]),
    remoteData.chain(remoteData.fromNullable)
  )(state.admin.selectedUserId)
}

export const getMode = createSelector(getState, state => state.mode)

export const matchEditRoute = createMatchSelector('/admin/user/edit/:id')

export const matchNewRoute = createMatchSelector('/admin/user/new')
