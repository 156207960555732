import React from 'react'
import * as option from 'fp-ts/lib/Option'
import classNames from 'classnames'

import ButtonLink from 'components/ButtonLink/ButtonLink'

import './ChannelCard.css'

export type Props = Readonly<{
  icon: string
  title: string
  route: option.Option<string>
}>

const ChannelCard: React.FunctionComponent<Props> = (props) => (
  <article
    className={classNames('channelcard', { 'channelcard--disabled': option.isNone(props.route) })}
  >
    <img
      className='channelcard__icon'
      src={props.icon}
      alt={`${props.title} icon`}
    />
    <h1 className='channelcard__title'>{props.title}</h1>
    {option.fold(
      () => (<p className='channelcard__infotext'>Coming soon</p>),
      (route: string) => (
        <ButtonLink route={route} className='channelcard__button'>Settings</ButtonLink>
      )
    )(props.route)
    }
  </article>
)

export default ChannelCard
