import { connect } from 'react-redux'

import router from 'modules/router'

import * as actions from '../actions'
import SkillPage from '../components/SkillPage'
import * as selectors from '../selectors'

const mapStateToProps = state => ({
  skills: selectors.getAllSkills(state),
  formSkills: selectors.getFormSkills(state),
  isDownloadingFormData: selectors.isDownloadingFormData(state),
  advancedSkills: selectors.getAdvancedSkills(state),
  skillReferences: selectors.getSkillReferences(state),
  formReferences: selectors.getFormReferences(state)
})

const mapDispatchToProps = {
  fetch: actions.fetchAllDefault,
  fetchAllAdvanced: actions.fetchAllAdvanced,
  fetchFormSkills: actions.fetchForms,
  createFormSkill: actions.createFormSkill,
  navigateTo: router.actions.navigateTo,
  onClickDownload: actions.downloadFormDataAsExcelRequest,
  createAdvancedSkill: actions.createAdvancedSkillRequest,
  fetchSkillReferences: actions.fetchSkillReferencesRequest,
  deleteSkill: actions.deleteSkillRequest,
  fetchFormReferences: actions.fetchFormReferencesRequest,
  deleteForm: actions.deleteFormRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(SkillPage)
