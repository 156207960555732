import { RemoteData, Maybe, Either } from '@sbizeul/fp-flow'
import { Option } from 'fp-ts/lib/Option'
import * as E from 'fp-ts/lib/Either'
import { RouterState } from 'connected-react-router'
import { FETCH_SKILL_REFERENCES_REQUEST, FETCH_SKILL_REFERENCES_SUCCESS, FETCH_SKILL_REFERENCES_FAILURE, DELETE_SKILL_REQUEST, DELETE_SKILL_SUCCESS, DELETE_SKILL_FAILURE, FETCH_FORM_REFERENCES_REQUEST, FETCH_FORM_REFERENCES_SUCCESS, FETCH_FORM_REFERENCES_FAILURE, DELETE_FORM_REQUEST, DELETE_FORM_SUCCESS, DELETE_FORM_FAILURE } from './actions'

import { Map } from 'helpers/map'
import { IsoLanguage } from 'helpers/language'
import { Id, ServerFailure } from 'types'
import {
  Answer as LegacyAnswer,
  SaveOneParam as SaveDefaultParam,
  SimpleAnswer
} from 'modules/answer/types'

export type ParamsId = Readonly<{
  id: Id
}>

export type ParamsIndex = Readonly<{
  index: number
}>

export type ParamsIdAndIndex = ParamsId & ParamsIndex

export type RemoveContent = (removeContentParam: ParamsId) => unknown
export type RemoveScenario = (index: number) => unknown
export type UpdateContent = (updateContentParam: UpdateQuestionContentParams) => unknown
export type UpdateAnswerContentParams = (updateContentParam: AnswerInputContent) => unknown
export type UploadImage = (params: UploadImageRequestPayload) => unknown
export type CreateScenario = (scenarios: ReadonlyArray<QuestionAndButtonIDs>) => unknown
export type CreateQuestion = () => unknown
export type CreateAnswer = (index: number) => unknown
export type CreateSkillContent = (index: number) => unknown
export type ImagesUrl = Map<RemoteData<ServerFailure, string>>

export type Button = Readonly<{
  id: Id
  keywordID: Option<Id>
  label: string
}>

export type Question = Readonly<{
  buttons: ReadonlyArray<Button>
  id: Id
  label: string
}>

export type QuestionAndButtonIDs = Readonly<{
  questionID: Id
  buttonID: Id
}>

export type Answer = Readonly<{
  scenarios: ReadonlyArray<QuestionAndButtonIDs>
  isValid: boolean
  answer: SimpleAnswer
}>

export type SkillId = string

export type Skill = Readonly<{
  id: Id
  botId: Id
  name: string
  language: IsoLanguage
  questions: ReadonlyArray<Question>
  answers: ReadonlyArray<Answer>
}>

export type SkillFromApi = Omit<Skill, 'questions'> & {
  questions: ReadonlyArray<Omit<Question, 'buttons'> & {
    buttons: ReadonlyArray<Omit<Button, 'keywordID'> & {
      keywordID: null | Id
    }>
  }>
}

export type Input = Readonly<{
  text: ReadonlyArray<string>
}>

export type InputId = Readonly<{
  id: Id
  text: ReadonlyArray<string>
  type?: string
}>

export type UpdateQuestionContentParams = Readonly<{
  id: Id
  text: string
}>

export type FormSkill = Readonly<{
  id: Id
  botId: Id
  name: string
  isDataAvailable: boolean
  inputs: ReadonlyArray<Input>
  conclusionAnswer: SimpleAnswer | null
}>

export type FormEmail = Readonly<{
  address: string
  isEnabled: boolean
}>

export type FormZapier = Readonly<{
  url: string
  isEnabled: boolean
}>

export type FormResponse = Readonly<{
  id: string
  emailResponseReception: FormEmail | null
  zapierResponseReception: FormZapier | null
}>

export type FormResponseContent = Readonly<{
  email: Option<FormEmail>
  zapier: Option<FormZapier>
}>

export type FormSkillContent = Readonly<{
  id: Id,
  botId: Id,
  name: string,
  content: ReadonlyArray<InputId>
  conclusionAnswer: Option<SimpleAnswer>
}>

export type AnswerInputContent = Readonly<{
  index: number
  content: Maybe<LegacyAnswer>
}>

export type UpdateQuestion = (question: Question) => unknown

export type UploadImageRequestPayload = Readonly<{
  id: Id
  file: File
  index: number
}>

export type UploadImageSuccessPayload = Readonly<{
  id: Id
  url: string
  index: number
}>

export type UploadImageFailurePayload = Readonly<{
  id: Id
  error: ServerFailure
}>

export type UpdateQuestionAction = Readonly<{
  type: 'skill/UPDATE_QUESTION'
  payload: Question
}>

export type CreateFormContent = Readonly<{
  type: 'skill/CREATE_FORM_CONTENT'
}>

export type CreateFormEmailContent = Readonly<{
  type: 'skill/CREATE_FORM_EMAIL_CONTENT'
}>

export type CreateFormFileContent = Readonly<{
  type: 'skill/CREATE_FORM_FILE_CONTENT'
}>

export type UpdateFormContent = Readonly<{
  type: 'skill/UPDATE_FORM_CONTENT'
  payload: { content: InputId }
}>

export type SaveFormResponseParams = Readonly<{
  id: Maybe<Id>
  email?: FormEmail
  zapier?: FormZapier
}>

export type SaveFormResponseRequest = Readonly<{
  type: 'skill/SAVE_FORM_RESPONSE_REQUEST'
  payload: SaveFormResponseParams
}>

export type SaveFormResponseSuccess = Readonly<{
  type: 'skill/SAVE_FORM_RESPONSE_SUCCESS'
}>

export type SaveFormResponseFailure = Readonly<{
  type: 'skill/SAVE_FORM_RESPONSE_FAILURE'
  payload: ServerFailure
}>

export type UpdateFormConclusion = Readonly<{
  type: 'skill/UPDATE_FORM_CONCLUSION'
  payload: Maybe<LegacyAnswer>
}>

export type RemoveFormContent = Readonly<{
  type: 'skill/REMOVE_FORM_CONTENT'
  payload: ParamsId
}>

export type CreateScenarioAction = Readonly<{
  type: 'skill/CREATE_SCENARIO'
  payload: Answer
}>

export type CreateQuestionContent = Readonly<{
  type: 'skill/CREATE_QUESTION_CONTENT'
  payload: Question
}>

export type UpdateQuestionContent = Readonly<{
  type: 'skill/UPDATE_QUESTION_CONTENT'
  payload: UpdateQuestionContentParams
}>

export type RemoveQuestionContent = Readonly<{
  type: 'skill/REMOVE_QUESTION_CONTENT'
  payload: ParamsId
}>

export type CreateButtonContent = Readonly<{
  type: 'skill/CREATE_ANSWER_BUTTON_CONTENT'
  payload: ParamsIndex
}>
export type CreateImageContent = Readonly<{
  type: 'skill/CREATE_ANSWER_IMAGE_CONTENT'
  payload: ParamsIndex
}>
export type CreateTagAgentContent = Readonly<{
  type: 'skill/CREATE_ANSWER_TAG_CONTENT'
  payload: ParamsIndex
}>
export type CreateTextContent = Readonly<{
  type: 'skill/CREATE_ANSWER_TEXT_CONTENT'
  payload: ParamsIndex
}>

export type UpdateAnswerContent = Readonly<{
  type: 'skill/UPDATE_ANSWER_SKILL_CONTENT'
  payload: AnswerInputContent
}>

export type UploadImageRequest = Readonly<{
  type: 'skill/UPLOAD_IMAGE_REQUEST'
  payload: UploadImageRequestPayload
}>

export type UploadImageSuccess = Readonly<{
  type: 'skill/UPLOAD_IMAGE_SUCCESS'
  payload: UploadImageSuccessPayload
}>

export type UploadImageFailure = Readonly<{
  type: 'skill/UPLOAD_IMAGE_FAILURE'
  payload: UploadImageFailurePayload
}>

export type RemoveScenarioAction = Readonly<{
  type: 'skill/REMOVE_SCENARIO',
  payload: number
}>

export type SaveSkillRequest = Readonly<{
  type: 'skill/SAVE_SKILL_REQUEST'
  payload: Skill
}>

export type SaveSkillSuccess = Readonly<{
  type: 'skill/SAVE_SKILL_SUCCESS'
}>

export type SaveSkillFailure = Readonly<{
  type: 'skill/SAVE_SKILL_FAILURE'
  payload: ServerFailure
}>

export type SaveFormSuccess = Readonly<{
  type: 'skill/SAVE_FORM_SUCCESS'
}>

export type SaveFormRequest = Readonly<{
  type: 'skill/SAVE_FORM_REQUEST'
  payload: FormSkillContent
}>

export type SaveFormFailure = Readonly<{
  type: 'skill/SAVE_FORM_FAILURE'
  payload: ServerFailure
}>

export type FetchSkillReferencesRequestParam = SkillId

export type FetchSkillReferencesRequest = Readonly<{
  type: typeof FETCH_SKILL_REFERENCES_REQUEST
  payload: FetchSkillReferencesRequestParam
}>

export type RestSkillReference = {
  readonly id: string
  readonly name: string
  readonly type: 'skill'
}

export type RestQuestionReference = {
  readonly id: string
  readonly name: string
  readonly type: 'question'
}

export type RestSkillReferences = {
  readonly skills: readonly RestSkillReference[]
  readonly questions: readonly RestQuestionReference[]
}

export type FetchSkillReferencesSuccessParam = RestSkillReferences

export type FetchSkillReferencesSuccess = Readonly<{
  type: typeof FETCH_SKILL_REFERENCES_SUCCESS
  payload: FetchSkillReferencesSuccessParam
}>

export type FetchSkillReferencesFailureParam = string

export type FetchSkillReferencesFailure = Readonly<{
  type: typeof FETCH_SKILL_REFERENCES_FAILURE
  payload: FetchSkillReferencesFailureParam
}>


export type DeleteSkillRequestParam = SkillId

export type DeleteSkillRequest = Readonly<{
  type: typeof DELETE_SKILL_REQUEST
  payload: DeleteSkillRequestParam
}>

export type DeleteSkillSuccessParam = Boolean

export type DeleteSkillSuccess = Readonly<{
  type: typeof DELETE_SKILL_SUCCESS
  payload: DeleteSkillSuccessParam
}>

export type DeleteSkillFailureParam = string

export type DeleteSkillFailure = Readonly<{
  type: typeof DELETE_SKILL_FAILURE
  payload: DeleteSkillFailureParam
}>

export type FormId = string

export type FetchFormReferencesRequestParam = FormId

export type FetchFormReferencesRequest = Readonly<{
  type: typeof FETCH_FORM_REFERENCES_REQUEST
  payload: FetchFormReferencesRequestParam
}>

export type RestFormReferences = {
  readonly skills: readonly RestSkillReference[]
  readonly questions: readonly RestQuestionReference[]
}

export type FetchFormReferencesSuccessParam = RestFormReferences

export type FetchFormReferencesSuccess = Readonly<{
  type: typeof FETCH_FORM_REFERENCES_SUCCESS
  payload: FetchFormReferencesSuccessParam
}>

export type FetchFormReferencesFailureParam = string

export type FetchFormReferencesFailure = Readonly<{
  type: typeof FETCH_FORM_REFERENCES_FAILURE
  payload: FetchFormReferencesFailureParam
}>

export type DeleteFormRequestParam = FormId

export type DeleteFormRequest = Readonly<{
  type: typeof DELETE_FORM_REQUEST
  payload: DeleteFormRequestParam
}>

export type DeleteFormSuccessParam = Boolean

export type DeleteFormSuccess = Readonly<{
  type: typeof DELETE_FORM_SUCCESS
  payload: DeleteFormSuccessParam
}>

export type DeleteFormFailureParam = string

export type DeleteFormFailure = Readonly<{
  type: typeof DELETE_FORM_FAILURE
  payload: DeleteFormFailureParam
}>

export type BaseSkillTypes = 'Fallback' | 'OnBoarding' | 'HumanHandover' | 'TapFlow' | 'Rgpd'

export type DefaultSkill = Readonly<{
  name: BaseSkillTypes
} & LegacyAnswer>

export type ReceivedSkill = {
  id: Id,
  name: BaseSkillTypes
}

export const FALLBACK: BaseSkillTypes = 'Fallback'
export const ONBOARDING: BaseSkillTypes = 'OnBoarding'
export const HUMANHANDOVER: BaseSkillTypes = 'HumanHandover'
export const TAPFLOW: BaseSkillTypes = 'TapFlow'
export const RGPD: BaseSkillTypes = 'Rgpd'
export const BASE_SKILL_LABELS = {
  [ONBOARDING]: 'Onboarding',
  [FALLBACK]: 'Default answer',
  [HUMANHANDOVER]: 'Human handover',
  [TAPFLOW]: 'Tap flow',
  [RGPD]: 'RGPD'
}

export type SkillUiState = Readonly<{
  allSkills: RemoteData<ServerFailure, ReadonlyArray<ReceivedSkill>>
  defaultSkill: RemoteData<ServerFailure, DefaultSkill>
  formSkills: RemoteData<ServerFailure, ReadonlyArray<FormSkill>>
  formSkill: RemoteData<ServerFailure, FormSkillContent>
  formResponse: RemoteData<ServerFailure, FormResponseContent>
  formResponseError: Option<ServerFailure>
  advancedSkills: RemoteData<ServerFailure, ReadonlyArray<Skill>>
  skill: RemoteData<ServerFailure, Skill>
  isDownloadingFormData: Map<E.Either<ServerFailure, boolean>>
  isModified: boolean
  isPosting: Either<ServerFailure, boolean>
  imagesById: Map<RemoteData<ServerFailure, string>>
  skillReferences: RemoteData<ServerFailure, RestSkillReferences>
  formReferences: RemoteData<ServerFailure, RestFormReferences>
}>

export type SkillEntityState = Readonly<{
  byId: Map<FormSkill>
  isPosting: Either<ServerFailure, boolean>
}>

export type AppState = Readonly<{
  entities: { skill: SkillEntityState }
  ui: { skill: SkillUiState }
  router: RouterState
}>

export type SaveDefaultRequest = Readonly<{
  type: 'skill/SAVE_DEFAULT_SKILL_REQUEST'
  payload: SaveDefaultParam
}>

export type SaveDefaultSuccess = Readonly<{
  type: 'skill/SAVE_DEFAULT_SKILL_SUCCESS'
  payload: Id
}>

export type SaveDefaultFailure = Readonly<{
  type: 'skill/SAVE_DEFAULT_SKILL_FAILURE'
  payload: ServerFailure
}>

export type FetchDefaultRequest = Readonly<{
  type: 'skill/FETCH_DEFAULT_SKILL_REQUEST'
}>

export type FetchDefaultSuccess = Readonly<{
  type: 'skill/FETCH_DEFAULT_SKILL_SUCCESS'
  payload: DefaultSkill
}>

export type FetchDefaultFailure = Readonly<{
  type: 'skill/FETCH_DEFAULT_SKILL_FAILURE'
  payload: ServerFailure
}>

export type FetchSkillRequest = Readonly<{
  type: 'skill/FETCH_SKILL_REQUEST'
  payload: Option<Id>
}>

export type FetchSkillSuccess = Readonly<{
  type: 'skill/FETCH_SKILL_SUCCESS'
  payload: Skill
}>

export type FetchSkillFailure = Readonly<{
  type: 'skill/FETCH_SKILL_FAILURE'
  payload: ServerFailure
}>

export type FetchAllSimplifiedSkills = Readonly<{
  type: 'skill/FETCH_ALL_SIMPLIFIED_SKILLS_REQUEST'
}>

export type FetchAllSimplifiedTags = Readonly<{
  type: 'skill/FETCH_ALL_SIMPLIFIED_TAGS_REQUEST'
}>

export type FetchFormsRequest = Readonly<{
  type: 'skill/FETCH_FORM_SKILLS_REQUEST'
}>

export type FetchFormsSuccess = Readonly<{
  type: 'skill/FETCH_FORM_SKILLS_SUCCESS'
  payload: ReadonlyArray<FormSkill>
}>

export type FetchFormsFailure = Readonly<{
  type: 'skill/FETCH_FORM_SKILLS_FAILURE'
  payload: ServerFailure
}>

export type FetchFormRequest = Readonly<{
  type: 'skill/FETCH_FORM_SKILL_REQUEST'
  payload: Maybe<Id>
}>

export type FetchFormSuccess = Readonly<{
  type: 'skill/FETCH_FORM_SKILL_SUCCESS'
  payload: FormSkillContent
}>

export type FetchFormFailure = Readonly<{
  type: 'skill/FETCH_FORM_SKILL_FAILURE'
  payload: ServerFailure
}>

export type FetchFormResponseRequest = Readonly<{
  type: 'skill/FETCH_FORM_RESPONSE_REQUEST'
  payload: Maybe<Id>
}>

export type FetchFormResponseSuccess = Readonly<{
  type: 'skill/FETCH_FORM_RESPONSE_SUCCESS'
  payload: FormResponseContent
}>

export type FetchFormResponseFailure = Readonly<{
  type: 'skill/FETCH_FORM_RESPONSE_FAILURE'
  payload: ServerFailure
}>

export type FetchAllDefaultRequest = Readonly<{
  type: 'skill/FETCH_ALL_DEFAULT_SKILLS_REQUEST'
}>

export type FetchAllDefaultSuccess = Readonly<{
  type: 'skill/FETCH_ALL_DEFAULT_SKILLS_SUCCESS'
  payload: ReadonlyArray<ReceivedSkill>
}>

export type FetchAllDefaultFailure = Readonly<{
  type: 'skill/FETCH_ALL_DEFAULT_SKILLS_FAILURE'
  payload: ServerFailure
}>

export type FetchAllRequest = Readonly<{
  type: 'skill/FETCH_ALL_SKILLS_REQUEST'
}>

export type FetchAllSuccess = Readonly<{
  type: 'skill/FETCH_ALL_SKILLS_SUCCESS'
  payload: ReadonlyArray<FormSkill>
}>

export type FetchAllFailure = Readonly<{
  type: 'skill/FETCH_ALL_SKILLS_FAILURE'
  payload: ServerFailure
}>

export type CreateFormSkill = Readonly<{
  type: 'skill/CREATE_FORM_REQUEST'
  payload: string
}>

export type CreateFormSkillSuccess = Readonly<{
  type: 'skill/CREATE_FORM_SUCCESS'
  payload: Id
}>

export type CreateFormSkillFailure = Readonly<{
  type: 'skill/CREATE_FORM_FAILURE'
  payload: ServerFailure
}>

export type DownloadFormDataAsExcelRequest = Readonly<{
  type: 'skill/DOWNLOAD_FORM_DATA_REQUEST'
  payload: {
    skillId: Id
    skillName: string
  }
}>

export type DownloadFormDataAsExcelFailure = Readonly<{
  type: 'skill/DOWNLOAD_FORM_DATA_FAILURE'
  payload: {
    skillId: Id
    error: ServerFailure
  }
}>

export type DownloadFormDataAsExcelSuccess = Readonly<{
  type: 'skill/DOWNLOAD_FORM_DATA_SUCCESS'
  payload: {
    skillId: Id
  }
}>

export type CreateAdvancedSkillRequest = Readonly<{
  type: 'skill/CREATE_ADVANCED_REQUEST'
  payload: string
}>

export type CreateAdvancedSkillSuccess = Readonly<{
  type: 'skill/CREATE_ADVANCED_SUCCESS'
  payload: Id
}>

export type CreateAdvancedSkillFailure = Readonly<{
  type: 'skill/CREATE_ADVANCED_FAILURE'
  payload: ServerFailure
}>

export type FetchAllAdvancedRequest = Readonly<{
  type: 'skill/FETCH_ALL_ADVANCED_REQUEST'
}>

export type FetchAllAdvancedSuccess = Readonly<{
  type: 'skill/FETCH_ALL_ADVANCED_SUCCESS'
  payload: ReadonlyArray<Skill>
}>

export type FetchAllAdvancedFailure = Readonly<{
  type: 'skill/FETCH_ALL_ADVANCED_FAILURE'
  payload: ServerFailure
}>
export type SkillUiAction =
  | CreateScenarioAction
  | CreateFormContent
  | CreateFormEmailContent
  | CreateFormFileContent
  | CreateQuestionContent
  | CreateButtonContent
  | CreateImageContent
  | CreateTagAgentContent
  | CreateTextContent
  | FetchDefaultRequest
  | FetchDefaultSuccess
  | FetchDefaultFailure
  | FetchFormsRequest
  | FetchFormsSuccess
  | FetchFormsFailure
  | FetchFormRequest
  | FetchFormSuccess
  | FetchFormFailure
  | FetchFormResponseRequest
  | FetchFormResponseSuccess
  | FetchFormResponseFailure
  | FetchAllDefaultRequest
  | FetchAllDefaultSuccess
  | FetchAllDefaultFailure
  | FetchSkillRequest
  | FetchSkillSuccess
  | FetchSkillFailure
  | UpdateQuestionContent
  | UpdateAnswerContent
  | UpdateQuestionAction
  | SaveSkillSuccess
  | RemoveQuestionContent
  | RemoveFormContent
  | RemoveScenarioAction
  | UpdateFormContent
  | UpdateFormConclusion
  | SaveFormResponseRequest
  | SaveFormResponseFailure
  | SaveDefaultRequest
  | SaveDefaultSuccess
  | SaveDefaultFailure
  | SaveFormRequest
  | SaveFormSuccess
  | SaveFormFailure
  | UploadImageRequest
  | UploadImageFailure
  | UploadImageSuccess
  | DownloadFormDataAsExcelRequest
  | DownloadFormDataAsExcelFailure
  | DownloadFormDataAsExcelSuccess
  | CreateAdvancedSkillFailure
  | CreateAdvancedSkillRequest
  | CreateAdvancedSkillSuccess
  | FetchAllAdvancedRequest
  | FetchAllAdvancedSuccess
  | FetchAllAdvancedFailure
  | FetchSkillReferencesRequest
  | FetchSkillReferencesSuccess
  | FetchSkillReferencesFailure
  | DeleteSkillRequest 
  | DeleteSkillSuccess
  | DeleteSkillFailure
  | FetchFormReferencesRequest
  | FetchFormReferencesSuccess
  | FetchFormReferencesFailure
  | DeleteFormRequest 
  | DeleteFormSuccess
  | DeleteFormFailure
