import * as option from 'fp-ts/lib/Option'
import { Predicate } from 'fp-ts/lib/function'
import * as R from 'ramda'

import {
  Question,
  QuestionState,
  QuestionStateActivity,
  Section
} from './types'

const sortQuestionsByLabel = R.sortBy(R.pipe(R.prop('label'), R.toLower))

const hasNoAnswer: Predicate<Question> = (question) => !question.answer_id

const isActive: Predicate<Question> = (question) =>
  R.propEq('state', QuestionState.ACTIVE, question)

const isArchived: Predicate<Question> = (question) =>
  R.propEq('state', QuestionState.ARCHIVED, question)

const UNLABELED_SECTION_ID = 'tcf:unlabeled-id'

const isUnlabeledSection: Predicate<Section> = R.propEq(
  'id',
  UNLABELED_SECTION_ID
)

const updatingQuestion = {
  nothing: () => option.none,
  archive: (question: Question) =>
    option.some({ state: QuestionState.ARCHIVED, id: question.id }),
  unarchive: (question: Question) =>
    option.some({ state: QuestionState.ACTIVE, id: question.id }),
  isMatchingQuestion: (question: Question) => (
    activityState: QuestionStateActivity
  ) => activityState.id === question.id,
  isArchiving: (activityState: QuestionStateActivity) =>
    activityState.state === QuestionState.ARCHIVED
}

const changeFeedbackStatus: (
  askFeedback: boolean
) => (question: Question) => Question = (askFeedback) =>
  R.set(R.lensProp('ask_feedback'), askFeedback)

export {
  changeFeedbackStatus,
  hasNoAnswer,
  isActive,
  isArchived,
  isUnlabeledSection,
  sortQuestionsByLabel,
  updatingQuestion
}
