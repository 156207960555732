import React from 'react'
import { Button, Form as SemanticForm, Message } from 'semantic-ui-react'
import { either } from '@sbizeul/fp-flow'

import { Form, renderNothing } from 'helpers/react'

import './styles.css'

class CreateKeywordForm extends React.Component {
  state = { keywordName: '' }

  handleChange = (event, { name, value }) => this.setState({ [name]: value })

  handleSubmit = () => this.props.createKeyword({ name: this.state.keywordName })

  fireOnClose = () => this.props.onClose && this.props.onClose()

  render() {
    const { isCreating } = this.props
    const { keywordName } = this.state

    return (
      <Form testId='create-keyword-form' activity={isCreating}>
        <SemanticForm.Field required>
          <h2 className='create-keyword-form-label'>Keyword Name</h2>
          <SemanticForm.Input
            placeholder='Keyword Name...'
            name='keywordName'
            value={keywordName}
            autoComplete='off'
            onChange={this.handleChange}
          />
        </SemanticForm.Field>
        {either.fold(
          error => (
            <Message error header='Creation error' content={error} />
          ),
          renderNothing,
          isCreating
        )}
        <div className='CreateKeyword-actions'>
          <Button
            className='primary'
            data-testid='submit'
            onClick={this.handleSubmit}
            disabled={this.state.keywordName.trim().length === 0}
          >
            Create
          </Button>
          <Button data-testid='cancel' onClick={this.fireOnClose}>
            Cancel
          </Button>
        </div>
      </Form>
    )
  }
}

export default CreateKeywordForm
