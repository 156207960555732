import { remoteData } from '@sbizeul/fp-flow'
import { lensProp, set } from 'ramda'
import moment from 'moment'

import * as actions from './actions'
import {
  ChangeDates,
  EntityState,
  FetchHealthRateSuccess,
  FetchHealthRateFailure,
  AnalyticsAction,
  FetchWebchatstatsSuccess
} from './types'

export const initialState: EntityState = {
  filter: {
    startDate: moment().subtract(3, 'week'),
    endDate: moment()
  },
  healthRate: remoteData.notAsked(),
  webchatStats: remoteData.notAsked()
}

const healthRate = lensProp('healthRate')
const webchatStats = lensProp('webchatStats')

const changeDates = (state: EntityState, action: ChangeDates) => ({
  ...state,
  filter: {
    startDate: action.payload.startDate,
    endDate: action.payload.endDate
  }
})

const healthRateRequest = (state: EntityState) => set(healthRate, remoteData.loading(), state)

const healthRateSuccess = (state: EntityState, action: FetchHealthRateSuccess) => set(healthRate, remoteData.success(action.payload), state)

const healthRateFailure = (state: EntityState, action: FetchHealthRateFailure) => set(healthRate, remoteData.failure(action.payload), state)

const webchatStatsRequest = (state: EntityState) => set(webchatStats, remoteData.loading(), state)

const webchatStatsSuccess = (state: EntityState, action: FetchWebchatstatsSuccess) => set(webchatStats, remoteData.success(action.payload), state)

export default function (state: EntityState = initialState, action: AnalyticsAction) {
  switch (action.type) {
    case actions.FILTER_CHANGE_DATE:
      return changeDates(state, action)
    case actions.FETCH_HEALTH_RATE_REQUEST:
      return healthRateRequest(state)
    case actions.FETCH_HEALTH_RATE_SUCCESS:
      return healthRateSuccess(state, action)
    case actions.FETCH_HEALTH_RATE_FAILURE:
      return healthRateFailure(state, action)
    case actions.FETCH_WEBCHAT_STATS_REQUEST:
      return webchatStatsRequest(state)
    case actions.FETCH_WEBCHAT_STATS_SUCCESS:
      return webchatStatsSuccess(state, action)
    default:
      return state
  }
}
