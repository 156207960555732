import {
  FetchAppInformationSuccess,
  FetchAppInformationFailure,
  AppSettingsEntitiesState,
  AppsEntityAction,
  FETCH_APP_INFORMATION_FAILURE,
  FETCH_APP_INFORMATION_REQUEST,
  FETCH_APP_INFORMATION_SUCCESS
} from './types'
import { remoteData } from '@sbizeul/fp-flow'

export const initialState: AppSettingsEntitiesState = {
  appInfo: remoteData.notAsked()
}

const fetchRequest = (
  state: AppSettingsEntitiesState
): AppSettingsEntitiesState => (
{
  ...state,
  appInfo: remoteData.loading()
}
)

const fetchSuccess = (
  state: AppSettingsEntitiesState,
  action: FetchAppInformationSuccess
): AppSettingsEntitiesState => ({
  ...state,
  appInfo: remoteData.success(action.payload)
})

const fetchFailure = (
  state: AppSettingsEntitiesState,
  action: FetchAppInformationFailure
): AppSettingsEntitiesState => ({
  ...state,
  appInfo: remoteData.failure(action.payload)
})

export default function apps (state: AppSettingsEntitiesState = initialState, action: AppsEntityAction) {
  switch (action.type) {
    case FETCH_APP_INFORMATION_REQUEST:
      return fetchRequest(state)
    case FETCH_APP_INFORMATION_SUCCESS:
      return fetchSuccess(state, action)
    case FETCH_APP_INFORMATION_FAILURE:
      return fetchFailure(state, action)
    default:
      return state
  }
}
