import { KeyboardEvent } from 'react'
import { Either, RemoteData } from '@sbizeul/fp-flow'

import { Id, ServerFailure } from 'types'
import { Map } from 'helpers/map'
import { WithScrollManagerAndLocationProps } from 'helpers/scroll-manager/types'

import {
  ArchiveQuestionRequest,
  CreateRequestParam,
  CreateSection,
  CreateSectionFailure,
  CreateSectionParam,
  CreateSectionSuccess,
  DeleteSectionFailure,
  DeleteSectionParam,
  DeleteSectionRequest,
  DeleteSectionSuccess,
  LabeledQuestions,
  QuestionId,
  QuestionProps,
  RemoteDataQuestionReferencesElements,
  SaveOneFailure,
  SaveOneSuccess,
  SaveSectionFailure,
  SaveSectionRequest,
  SaveSectionSuccess,
  Section,
  UnarchiveQuestionRequest,
  UpdatingQuestion
} from '../question/types'
import { TrainingQuestion } from '../trainingQuestion/types'

export const IMPORT_REQUEST = 'qa/IMPORT_REQUEST'
export const IMPORT_SUCCESS = 'qa/IMPORT_SUCCESS'
export const IMPORT_FAILURE = 'qa/IMPORT_FAILURE'

export const DELETE_ONE_QNA_REQUEST = 'question/DELETE_ONE_QNA_REQUEST'
export const DELETE_ONE_QNA_SUCCESS = 'question/DELETE_ONE_QNA_SUCCESS'
export const DELETE_ONE_QNA_FAILURE = 'question/DELETE_ONE_QNA_FAILURE'

export const DELETE_ALL_QNA_REQUEST = 'qa/DELETE_ALL_REQUEST'
export const DELETE_ALL_QNA_SUCCESS = 'qa/DELETE_ALL_SUCCESS'
export const DELETE_ALL_QNA_FAILURE = 'qa/DELETE_ALL_FAILURE'

export type ImportParams = FileList

export type ImportRequest = Readonly<{
  type: typeof IMPORT_REQUEST
  payload: ImportParams
}>

export type ImportSuccess = Readonly<{
  type: typeof IMPORT_SUCCESS
}>

export type ImportFailureParam = ServerFailure

export type ImportFailure = Readonly<{
  type: typeof IMPORT_FAILURE
  payload: ImportFailureParam
}>

export type DeleteOneQnaRequestParams = Id

export type DeleteOneQnARequest = Readonly<{
  type: typeof DELETE_ONE_QNA_REQUEST
  payload: DeleteOneQnaRequestParams
}>

export type DeleteOneQnASuccess = Readonly<{
  type: typeof DELETE_ONE_QNA_SUCCESS
}>

export type DeleteOneQnAFailureParam = ServerFailure

export type DeleteOneQnAFailure = Readonly<{
  type: typeof DELETE_ONE_QNA_FAILURE
  payload: DeleteOneQnAFailureParam
}>

export type DeleteAllRequest = Readonly<{
  type: typeof DELETE_ALL_QNA_REQUEST
}>

export type DeleteAllSuccess = Readonly<{
  type: typeof DELETE_ALL_QNA_SUCCESS
}>

export type DeleteAllFailureParam = ServerFailure

export type DeleteAllFailure = Readonly<{
  type: typeof DELETE_ALL_QNA_FAILURE
  payload: DeleteAllFailureParam
}>

export const SAVE_COLLAPSIBLE_SECTION_OPENING_STATUS = 'qa/SAVE_COLLAPSIBLE_SECTION_OPENING_STATUS'
export const SAVE_COLLAPSIBLE_SECTION_OPENING_STATUS_SUCCESS = 'qa/SAVE_COLLAPSIBLE_SECTION_OPENING_STATUS_SUCCESS'

export type SaveOpeningStatus = {
  type: typeof SAVE_COLLAPSIBLE_SECTION_OPENING_STATUS
  payload: OpeningStatusInfo
}

export type SaveOpeningStatusSuccess = {
  type: typeof SAVE_COLLAPSIBLE_SECTION_OPENING_STATUS_SUCCESS
  payload: OpeningStatusInfo
}

export type QAUiAction =
  | ImportRequest
  | ImportSuccess
  | ImportFailure
  | DeleteOneQnARequest
  | DeleteOneQnASuccess
  | DeleteOneQnAFailure
  | DeleteAllRequest
  | DeleteAllSuccess
  | DeleteAllFailure
  | CreateSection
  | CreateSectionSuccess
  | CreateSectionFailure
  | SaveSectionRequest
  | SaveSectionSuccess
  | SaveSectionFailure
  | DeleteSectionRequest
  | DeleteSectionSuccess
  | DeleteSectionFailure
  | ArchiveQuestionRequest
  | UnarchiveQuestionRequest
  | SaveOneSuccess
  | SaveOneFailure
  | SaveOpeningStatus
  | SaveOpeningStatusSuccess

export type QAUiState = Readonly<{
  isImporting: Either<ServerFailure, boolean>,
  isDeleting: Either<ServerFailure, boolean>
  isUpdatingQuestion: Either<ServerFailure, UpdatingQuestion>
  isCreatingSection: Either<ServerFailure, boolean>
  isUpdatingSection: Either<ServerFailure, UpdatingSection>
  isDeletingSection: Either<ServerFailure, DeletingSection>
  collapsibleSectionsOpeningStatus: OpeningStatusInfoList
}>

type DiffQuestionProps = Omit<QuestionProps, 'question' | 'trainingQuestions' | 'sections'>

export type UpdatingSection = Either<false, Id>

export type DeletingSection = Either<false, Id>

export type QaQuestionPageProps = DiffQuestionProps & Readonly<{
  labeledQuestions: RemoteData<ServerFailure, LabeledQuestions>
  isPostingQuestion: Either<ServerFailure, boolean>
  isImporting: Either<ServerFailure, boolean>
  isUpdatingQuestion: Either<ServerFailure, UpdatingQuestion>
  isCreatingSection: Either<ServerFailure, boolean>
  isUpdatingSection: Either<ServerFailure, UpdatingSection>
  isDeletingSection: Either<ServerFailure, DeletingSection>
  isDeleting: Either<ServerFailure, boolean>
  trainingQuestionsById: Map<RemoteData<ServerFailure, ReadonlyArray<TrainingQuestion>>>
  collapsibleSectionsOpeningStatus: OpeningStatusInfoList
  questionReferences: RemoteDataQuestionReferencesElements
  questionTemplateId: string
  
  importFile: (param: ImportParams) => unknown
  createSection: (param: CreateSectionParam) => unknown
  updateSection: (param: Section) => unknown
  deleteSection: (param: DeleteSectionParam) => unknown
  fetchLabeledQuestions: () => unknown
  createQuestion: (param: CreateRequestParam) => unknown
  saveOpeningStatus: (openingStatusInfo: OpeningStatusInfo) => unknown
  fetchQuestionReferences: (questionId: QuestionId) => unknown

}> & WithScrollManagerAndLocationProps

export type TopBarProps = Readonly<{
  isPostingQuestion: Either<ServerFailure, boolean>
  isImporting: Either<ServerFailure, boolean>
  isCreatingSection: Either<ServerFailure, boolean>

  onInputKeyEnter: (text: string, event: KeyboardEvent) => unknown
  importFile: (param: ImportParams) => unknown
  createSection: (param: CreateSectionParam) => unknown
}>

export type OpeningStatusInfo = Readonly<{
  id: Id,
  isOpened: boolean
}>

export type OpeningStatusInfoList = Record<Id, boolean | null>
