import types from './types'

const initialState = {
  fetching: false,
  fetchingConfig: false,
  postingEventChart: false
}

const eventsReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.FETCH_BOTCONFIG_REQUEST:
    return {
      ...state,
      fetchingConfig: true,
      config: null
    }
  case types.FETCH_BOTCONFIG_NO_DATA:
    return {
      ...state,
      fetchingConfig: false
    }
  case types.FETCH_BOTCONFIG_SUCCESS:
    return {
      ...state,
      fetchingConfig: false,
      config: action.payload
    }

  case types.FETCH_CUSTOM_EVENTS_REQUEST:
    return {
      ...state,
      fetching: true,
      data: []
    }
  case types.FETCH_CUSTOM_EVENTS_NO_DATA:
    return {
      ...state,
      fetching: false
    }
  case types.FETCH_CUSTOM_EVENTS_SUCCESS: {
    return {
      ...state,
      fetching: false,
      data: [...state.data, action.payload]
    }
  }

  case types.POST_EVENT_CHART_REQUEST:
    return { ...state, postingEventChart: true }
  case types.POST_EVENT_CHART_FAILURE:
    return { ...state, postingEventChart: false }
  case types.POST_EVENT_CHART_SUCCESS:
    return { ...state, postingEventChart: false }

  default:
    return state
  }
}

export default eventsReducer
