import { connect } from 'react-redux'

import * as actions from '../../actions'
import { AppSettingsState } from '../../types'
import AppSettings from '../../components/AppSettings'

const mapStateToProps = (state: AppSettingsState) => ({
  appInfo: state.entities.apps.appInfo,
  saving: state.ui.apps.saving
})

const mapDispatchToProps = {
  fetchAppInfo: actions.fetchAppInformationRequest,
  saveAppInfo: actions.saveAppInformationRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(AppSettings)
