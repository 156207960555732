import * as R from 'ramda'
import { remoteData } from '@sbizeul/fp-flow'

import * as answerActions from 'modules/answer/actions'
import { UploadImageSuccess } from 'modules/answer/types'

import * as actions from './actions'
import {
  BotEntityState,
  BotEntityAction,
  FetchAllSuccess, FetchAllFailure,
  FetchOneFailure, FetchOneSuccess,
  FetchPublishStatusSuccess, FetchPublishStatusFailure,
  FetchUseCasesSuccess, FetchUseCasesFailure,
  FetchWebchatSuccess, FetchWebchatFailure,
  PublishSuccess, PublishFailure,
  RegisterCreatedBotSuccess, RegisterCreatedBotFailure,
  UpdateWebchat
} from './types'
import { botToMap, convertRestBotToBot } from './models.typed'

export const initialState: BotEntityState = {
  byId: remoteData.notAsked(),
  publishStatus: remoteData.notAsked(),
  info: remoteData.notAsked(),
  webchat: remoteData.notAsked(),
  availableUseCases: remoteData.notAsked(),
  isModified: false
}

const byId = R.lensProp('byId')
const info = R.lensProp('info')
const isModified = R.lensProp('isModified')
const publishStatus = R.lensProp('publishStatus')
const availableUseCases = R.lensProp('availableUseCases')
const webchat = R.lensProp('webchat')

const botCreationRequest = (state: BotEntityState) =>
  R.set(info, remoteData.loading(), state)

const botCreationSuccess = (state: BotEntityState, action: RegisterCreatedBotSuccess) =>
  R.set(info, remoteData.success(action.payload), state)

const botCreationFailure = (state: BotEntityState, action: RegisterCreatedBotFailure) =>
  R.set(info, remoteData.failure(action.payload), state)

const botCreationEnded = (state: BotEntityState) =>
  R.set(info, remoteData.notAsked(), state)

const fetchAllrequest = (state: BotEntityState) => {
  return R.over(byId, remoteData.loading, state)
}

const fetchAllsuccess: (state: BotEntityState, action: FetchAllSuccess) => BotEntityState = (state, action) => {
  return R.set(byId, R.pipe(botToMap, remoteData.success)(action.payload), state)
}

const fetchAllfailure = (state: BotEntityState, action: FetchAllFailure) => {
  return R.set(byId, remoteData.failure(action.payload), state)
}

const fetchOnerequest = (state: BotEntityState) => {
  return R.over(byId, remoteData.loading, state)
}

const fetchOnesuccess: (state: BotEntityState, action: FetchOneSuccess) => BotEntityState = (state, action) => {
  return R.set(byId, R.pipe(convertRestBotToBot, remoteData.success)(action.payload), state)
}

const fetchOnefailure = (state: BotEntityState, action: FetchOneFailure) => {
  return R.set(byId, remoteData.failure(action.payload), state)
}

const fetchPublishStatusRequest = R.set(publishStatus, remoteData.loading())

const fetchPublishStatusSuccess = (state: BotEntityState, action: FetchPublishStatusSuccess | PublishSuccess) =>
  R.set(publishStatus, remoteData.success(action.payload), state)

const fetchPublishStatusFailure = (state: BotEntityState, action: FetchPublishStatusFailure | PublishFailure) =>
  R.set(publishStatus, remoteData.failure(action.payload), state)

const publishRequest = R.set(
  publishStatus,
  remoteData.success({ status: 'In progress' })
)

const fetchUseCases = R.set(availableUseCases, remoteData.loading())

const fetchUseCasesSuccess = (state: BotEntityState, action: FetchUseCasesSuccess) =>
  R.set(availableUseCases, remoteData.success(action.payload), state)

const fetchUseCasesFailure = (state: BotEntityState, action: FetchUseCasesFailure) =>
  R.set(availableUseCases, remoteData.failure(action.payload), state)

const fetchWebchat = R.set(webchat, remoteData.loading())

const fetchWebchatFailure = (state: BotEntityState, action: FetchWebchatFailure) =>
  R.set(webchat, remoteData.failure(action.payload), state)

const fetchWebchatSuccess = (state: BotEntityState, action: FetchWebchatSuccess) =>
R.pipe(
  R.set(webchat, remoteData.success(action.payload)),
  R.set(isModified, false)
)(state)

const cleanWebchat = R.set(webchat, remoteData.notAsked())

const uploadImageSuccess = (
  state: BotEntityState,
  action: UploadImageSuccess
) =>
  R.pipe(
    R.over(
    webchat,
    remoteData.map(R.set(R.lensPath(['settings', 'avatar', 'pics']), action.payload.url))),
    R.set(isModified, true)
  )(state)

const updateWebchat = (state: BotEntityState, action: UpdateWebchat) =>
  R.pipe(
    R.over(
      webchat,
      remoteData.map(R.set(R.lensProp('settings'), action.payload.settings))
    ),

    R.over(
      webchat,
      remoteData.map(R.set(R.lensProp('active'), action.payload.active))
    ),

    R.set(isModified, true)
)(state)
export default function (state: BotEntityState = initialState, action: BotEntityAction) {
  switch (action.type) {
    case actions.FETCH_ALL_REQUEST:
      return fetchAllrequest(state)
    case actions.FETCH_ALL_SUCCESS:
      return fetchAllsuccess(state, action)
    case actions.FETCH_ALL_FAILURE:
      return fetchAllfailure(state, action)
    case actions.FETCH_ONE_REQUEST:
      return fetchOnerequest(state)
    case actions.FETCH_ONE_SUCCESS:
      return fetchOnesuccess(state, action)
    case actions.FETCH_ONE_FAILURE:
      return fetchOnefailure(state, action)
    case actions.FETCH_PUBLISH_STATUS_REQUEST:
      return fetchPublishStatusRequest(state)
    case actions.FETCH_PUBLISH_STATUS_SUCCESS:
      return fetchPublishStatusSuccess(state, action)
    case actions.FETCH_PUBLISH_STATUS_FAILURE:
      return fetchPublishStatusFailure(state, action)
    case actions.PUBLISH_REQUEST:
      return publishRequest(state)
    case actions.PUBLISH_SUCCESS:
      return fetchPublishStatusSuccess(state, action)
    case actions.PUBLISH_FAILURE:
      return fetchPublishStatusFailure(state, action)
    case actions.BOT_CREATION_REQUEST:
      return botCreationRequest(state)
    case actions.BOT_CREATION_SUCCESS:
      return botCreationSuccess(state, action)
    case actions.BOT_CREATION_FAILURE:
      return botCreationFailure(state, action)
    case actions.BOT_CREATION_ENDED:
      return botCreationEnded(state)
    case actions.FETCH_USE_CASES_REQUEST:
      return fetchUseCases(state)
    case actions.FETCH_USE_CASES_SUCCESS:
      return fetchUseCasesSuccess(state, action)
    case actions.FETCH_USE_CASES_FAILURE:
      return fetchUseCasesFailure(state, action)
    case actions.FETCH_WEBCHAT_REQUEST:
      return fetchWebchat(state)
    case actions.FETCH_WEBCHAT_FAILURE:
      return fetchWebchatFailure(state, action)
    case actions.FETCH_WEBCHAT_SUCCESS:
      return fetchWebchatSuccess(state, action)
    case actions.UPDATE_WEBCHAT:
      return updateWebchat(state, action)
    case actions.CLEAN_WEBCHAT:
      return cleanWebchat(state)
    case answerActions.UPLOAD_IMAGE_SUCCESS:
      return uploadImageSuccess(state, action)
    default:
      return state
  }
}
