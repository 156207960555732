import { Id, ServerFailure } from 'types'
import { RemoteData } from '@sbizeul/fp-flow'

export const CONFIRM_ONE_QUESTION_REQUEST = 'monitor/CONFIRM_ONE_QUESTION_REQUEST'
export const CONFIRM_ONE_QUESTION_SUCCESS = 'monitor/CONFIRM_ONE_QUESTION_SUCCESS'
export const CONFIRM_ONE_QUESTION_FAILURE = 'monitor/CONFIRM_ONE_QUESTION_FAILURE'

export const MONITOR_FETCH_REQUEST = 'monitor/FETCH_REQUEST'
export const MONITOR_FETCH_SUCCESS = 'monitor/FETCH_SUCCESS'
export const MONITOR_FETCH_FAILURE = 'monitor/FETCH_FAILURE'

export const MONITOR_FETCH_PROGRESS_REQUEST = 'monitor/FETCH_PROGRESS_REQUEST'
export const MONITOR_FETCH_PROGRESS_SUCCESS = 'monitor/FETCH_PROGRESS_SUCCESS'
export const MONITOR_FETCH_PROGRESS_FAILURE = 'monitor/FETCH_PROGRESS_FAILURE'

export const MONITOR_FETCH_OUT_OF_SCOPE_REQUEST = 'monitor/FETCH_OUT_OF_SCOPE_REQUEST'
export const MONITOR_FETCH_OUT_OF_SCOPE_SUCCESS = 'monitor/FETCH_OUT_OF_SCOPE_SUCCESS'
export const MONITOR_FETCH_OUT_OF_SCOPE_FAILURE = 'monitor/FETCH_OUT_OF_SCOPE_FAILURE'

export const CREATE_QUESTION_REQUEST = 'monitor/CREATE_QUESTION_REQUEST'
export const CREATE_QUESTION_SUCCESS = 'monitor/CREATE_QUESTION_SUCCESS'
export const CREATE_QUESTION_FAILURE = 'monitor/CREATE_QUESTION_FAILURE'
export const CLEAN_CREATED_QUESTION = 'monitor/CLEAN_CREATED_QUESTION'

export const MONITOR_VALIDATE_TAGGING = 'monitor/VALIDATE_TAGGING'

export const NAVIGATE_TO_OUT_OF_SCOPE = 'monitor/NAVIGATE_TO_OUT_OF_SCOPE'
export const NAVIGATE_TO_DASHBOARD = 'monitor/NAVIGATE_TO_DASHBOARD'

export type AssignedQuestion = Readonly<{
  logId: Id
  dropdownIndex: number
  value: string
  confirmed: boolean
  deleted: boolean
}>

export type Log = Readonly<{
  log_id: Id
}>

export type ReQualifiedLog = Log & Readonly<{
  monitoring: 'ReQualified'
  expected_id: string
}>

export type SidelinedLog = Log & Readonly<{
  monitoring: 'Sidelined'
}>

export type DeletedLog = Log & Readonly<{
  monitoring: 'Deleted'
}>

export type TaggedLog = ReQualifiedLog | SidelinedLog | DeletedLog

export type UserLog = Readonly<{
  understood: boolean
  text_in: string
  intents: {id: Id, text: string}[]
  date: string
  id: Id
  cluster_id: Id | null,
  intentList: string[]
}>

export type MonitorStatus = 'in_progress' | 'terminated' | 'complete'

export type Progress = Readonly<{
  treatedLogs: number
  total: number
}>

export type Intent = Readonly<{
  questionId: string
  questionText: string
}>

export type MonitorLog = Readonly<{
  id: string
  botId: string
  text: string
  createdAt: Date
  question?: Intent
  intentList?: string[]
}>

export type MatchedQuestion = Readonly<{
  id: Id,
  textIn: string
}>

export type MatchedLog = Readonly<{
  id: Id,
  associatedQuestion: string,
  matchedLogs: MatchedQuestion[]
}>

export type MonitorLogs = ReadonlyArray<MonitorLog>

export type UnmatchedQuestion = Readonly<{
  id: Id
  textIn: string
  intentList: string[]
}>

export type ConfirmedLog = Readonly<{
  log_id: string
  understood: boolean
}>

export type CreateQuestion = Readonly<{
  question: string
  selectedLogs: MonitorLogs
}>

export type Question = Readonly<{
  id: string
  label: string
}>

export type ConfirmOneParam = Readonly<{
  questionId: Id
  confirmedLogs: ConfirmedLog[]
}>

export type ConfirmOneRequest = Readonly<{
  type: typeof CONFIRM_ONE_QUESTION_REQUEST
  payload: ConfirmOneParam
}>

export type ConfirmOneSuccess = Readonly<{
  type: typeof CONFIRM_ONE_QUESTION_SUCCESS
}>

export type ConfirmOneFailure = Readonly<{
  type: typeof CONFIRM_ONE_QUESTION_FAILURE
  payload: ServerFailure
}>

export type FetchSuccess = Readonly<{
  type: typeof MONITOR_FETCH_SUCCESS
  payload: MonitorLogs
}>

export type FetchFailure = Readonly<{
  type: typeof MONITOR_FETCH_FAILURE
  payload: ServerFailure
}>

export type FetchRequest = Readonly<{
  type: typeof MONITOR_FETCH_REQUEST
}>

export type FetchProgressSuccess = Readonly<{
  type: typeof MONITOR_FETCH_PROGRESS_SUCCESS
  payload: Progress
}>

export type FetchProgressFailure = Readonly<{
  type: typeof MONITOR_FETCH_PROGRESS_FAILURE
  payload: ServerFailure
}>

export type FetchProgressRequest = Readonly<{
  type: typeof MONITOR_FETCH_PROGRESS_REQUEST
}>

export type FetchOutOfScopeSuccess = Readonly<{
  type: typeof MONITOR_FETCH_OUT_OF_SCOPE_SUCCESS
  payload: MonitorLogs
}>

export type FetchOutOfScopeFailure = Readonly<{
  type: typeof MONITOR_FETCH_OUT_OF_SCOPE_FAILURE
  payload: ServerFailure
}>

export type FetchOutOfScopeRequest = Readonly<{
  type: typeof MONITOR_FETCH_OUT_OF_SCOPE_REQUEST
}>

export type ValidateTaggingAction = Readonly<{
  type: typeof MONITOR_VALIDATE_TAGGING
  payload: TaggedLog[]
}>

export type NavigateToOutOfScope = Readonly<{
  type: typeof NAVIGATE_TO_OUT_OF_SCOPE
  payload: string
}>

export type NavigateToDashboard = Readonly<{
  type: typeof NAVIGATE_TO_DASHBOARD
  payload: string
}>

export type Navigate = NavigateToDashboard | NavigateToOutOfScope

export type CreateQuestionRequest = Readonly<{
  type: typeof CREATE_QUESTION_REQUEST
  payload: CreateQuestion
}>

export type CreateQuestionSuccess = Readonly<{
  type: typeof CREATE_QUESTION_SUCCESS
  payload: Id
}>

export type CreateQuestionFailure = Readonly<{
  type: typeof CREATE_QUESTION_FAILURE
  payload: ServerFailure
}>

export type CleanCreatedQuestion = Readonly<{
  type: typeof CLEAN_CREATED_QUESTION
}>

export type RegisterMonitorAction =
  | FetchRequest
  | FetchSuccess
  | FetchFailure
  | FetchProgressRequest
  | FetchProgressSuccess
  | FetchProgressFailure
  | FetchOutOfScopeRequest
  | FetchOutOfScopeSuccess
  | FetchOutOfScopeFailure
  | CreateQuestionRequest
  | CreateQuestionSuccess
  | CreateQuestionFailure
  | CleanCreatedQuestion

export type MonitorEntityState = Readonly<{
  logs: RemoteData<ServerFailure, MonitorLogs>
  logsOutOfScope: RemoteData<ServerFailure, MonitorLogs>
  createdQuestionId: RemoteData<ServerFailure, Id>
  progress: RemoteData<ServerFailure, Progress>
}>

export type MonitorState = Readonly<{
  entities: { monitor: MonitorEntityState }
}>

export type DropdownOptions = ReadonlyArray<DropdownOption>

export type DropdownOption = Readonly<{
  key: string,
  text: string,
  value: string
}>
