import React from 'react'
import CreationFormContainer from '../../containers/CreationFormContainer'
import styles from './EmptyBotPage.module.css'

const EmptyBotPage = () => {
  return (
    <div>
      <header className={styles.topbar}>
        <img src='/img/flags/logo-bot_hd.png' className={styles.topbar__logo} alt='logo tcf' />
      </header>

      <CreationFormContainer />
    </div>
  )
}

export default EmptyBotPage
