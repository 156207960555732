import { call, put, takeEvery, takeLatest, select } from 'redux-saga/effects'

import { LOCATION_CHANGE } from 'connected-react-router'
import { maybe } from '@sbizeul/fp-flow'

import Api from 'services/tolkApiLegacy'
import bot from 'modules/bot'
import synonym from 'modules/synonym_old'
import * as entityActions from './actions'
import { matchSelector, matchSelectEntityRoute } from './selectors'

export function* fetchEntities() {
  const botId = maybe.get(yield select(bot.selectors.getSelectedId))
  try {
    const { data } = yield call(Api.get, `/bot/${botId}/entities`)
    yield put(entityActions.fetchEntitiesSuccess(data))
  } catch (e) {
    yield put(entityActions.fetchEntitiesFailure(e))
  }
}

export function* fetchOne(action) {
  const entityId = action.payload
  yield put(synonym.actions.fetchSynonymsRequest(entityId))
  const botId = maybe.get(yield select(bot.selectors.getSelectedId))
  try {
    const { data } = yield call(Api.get, `/bot/${botId}/entities/${entityId}`)
    yield put(entityActions.fetchOneSuccess(data))
  } catch (e) {
    yield put(entityActions.fetchOneFailure(e))
  }
}

export function* create(action) {
  const botId = maybe.get(yield select(bot.selectors.getSelectedId))
  try {
    const url = `/bot/${botId}/entities`
    const { data } = yield call(Api.post, url, action.payload)
    yield put(entityActions.createSuccess(data))
  } catch (error) {
    yield put(entityActions.createFailure(error))
  }
}

export function* update(action) {
  const botId = maybe.get(yield select(bot.selectors.getSelectedId))
  const { id: entityId } = action.payload
  try {
    const url = `/bot/${botId}/entities/${entityId}`
    const { data } = yield call(Api.put, url, action.payload)
    yield put(entityActions.updateSuccess(data))
  } catch (error) {
    yield put(entityActions.updateFailure(entityId, error))
  }
}

export function* closeCreateForm() {
  yield put(entityActions.closeCreateForm())
}

export function* selectBot() {
  const route = yield select(matchSelector)
  if (route !== null) {
    yield put(entityActions.fetchEntitiesRequest())
  }
}

export function* matchRoute() {
  const matchSelectedEntity = yield select(matchSelectEntityRoute)
  if (matchSelectedEntity != null) {
    yield put(entityActions.selectEntity(matchSelectedEntity.params.id))
  } else {
    yield put(entityActions.unselectEntity())
  }
}

export function* root() {
  yield takeEvery(LOCATION_CHANGE, matchRoute)
  yield takeLatest(entityActions.FETCH_ENTITIES_REQUEST, fetchEntities)
  yield takeLatest(entityActions.FETCH_ONE_REQUEST, fetchOne)
  yield takeLatest(entityActions.CREATE_SUCCESS, fetchEntities)
  yield takeLatest(entityActions.CREATE_SUCCESS, closeCreateForm)
  yield takeLatest(entityActions.CREATE_REQUEST, create)
  yield takeLatest(entityActions.UPDATE_REQUEST, update)
  yield takeLatest(bot.actions.SELECT, selectBot)
}
