import React, { Component } from 'react'
import { connect } from 'react-redux'
import { maybe } from '@sbizeul/fp-flow'

import operations from '../../../../../redux/analytics/operations'
import getSafe from '../../../../utils/getSafe'
import Behaviours from './Behaviours'
import NewFilters from '../../Filters/NewFilters'
import bot from '../../../../../modules/bot'
import { getFunnel } from '../../../../../redux/analytics/funnel/selectors'

class BehavioursContainer extends Component {
  componentDidMount() {
    const { filters } = this.props
    this.props.fetchBehaviours(filters)
  }

  componentDidUpdate(prevProps) {
    const { filters } = this.props
    if (!maybe.equals(prevProps.botId, this.props.botId) || prevProps.filters.channel.id !== filters.channel.id) {
      this.props.fetchBehaviours(filters)
    }
  }

  render() {
    return (
      <div>
        <NewFilters fetch={this.props.fetchBehaviours} />
        <Behaviours {...this.props} botId={maybe.get(this.props.botId)} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  filters: state.main.filters,
  wordsCount: getSafe(() => state.analytics.words.moy_words),
  fetchingWords: getSafe(() => state.analytics.words.fetching),
  charCount: getSafe(() => state.analytics.words.moy_car),
  noWordsCount: false,
  entitiesData: getSafe(() => state.analytics.entities.data),
  intentsData: getSafe(() => state.analytics.intents.data),
  answersData: getSafe(() => state.analytics.answers.data),
  payloadsData: getSafe(() => state.analytics.payloads.data),
  noIntentsData: false,
  payloadsCount: getSafe(() => state.analytics.payloads.count.total),
  messagesCount: getSafe(() => state.analytics.messages.count.users),
  menuPersistent: getSafe(() => state.analytics.menuPersistent.data),
  menuPersistentUtil: getSafe(() => state.analytics.menuPersistent.util),
  referral: getSafe(() => state.analytics.referral.data),
  noReferralData: false,
  referralUtil: getSafe(() => state.analytics.referral.util),
  noReferralUtil: false,
  botId: bot.selectors.getSelectedId(state),
  funnel: getFunnel(state)
})

export default connect(mapStateToProps, operations)(BehavioursContainer)
