import moment from 'moment'
import getComparisonDateRange from './utils/comparisonDateRange'

const dateRange = {
  start: moment().subtract(1, 'week'),
  end: moment()
}

const filters = {
  channel: {
    name: null,
    id: null
  },
  bots: [],
  dateRange,
  comparisonDateRange: getComparisonDateRange(dateRange),
  periods: {
    visible: [1, 2],
    active: 2,
    data: [
      {
        id: 0,
        name: 'min'
      },
      {
        id: 1,
        name: 'hours'
      },
      {
        id: 2,
        name: 'days'
      }
    ]
  }
}

export default {
  fetching: false,
  loginError: null,
  verifyError: null,
  userData: null,
  isAuthenticated: null,
  filters
}
