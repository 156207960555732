import { createSelector } from 'reselect'
import { createMatchSelector } from 'connected-react-router'

export const getState = state => {
  return state.answer_old
}

export const getByIntentSlug = (state, intentSlug) => {
  return getState(state).byIntentSlug[intentSlug] || []
}

export const getIsFetching = createSelector(getState, state => state.isFetching)

export const getError = createSelector(getState, state => state.error)

export const matchCreateRoute = createMatchSelector('/answers/:slug/create')

export const matchEditRoute = createMatchSelector('/answers/:slug/edit')

export const matchAnswersRoute = state => matchCreateRoute(state) || matchEditRoute(state)
