import React from 'react'
import * as O from 'fp-ts/lib/Option'
import { remoteData } from '@sbizeul/fp-flow'

import { renderLoader, useEffectOnce } from 'helpers/react'

import { AppSettingsProps, AppInformation } from '../../types'
import { AppSettings } from './AppSettings'

import './AppSettings.css'

const RemoteAppSettings: React.FunctionComponent<AppSettingsProps> = (props) => {
  useEffectOnce(() => { props.fetchAppInfo(props.appSlug, props.appId, props.enabled) })

  const renderSuccess = (appInformation: O.Option<AppInformation>) => (
    <AppSettings
      {...props}
      appInformation={appInformation}
    />
  )

  return remoteData.fold({
    Success: renderSuccess,
    NotAsked: () => renderLoader('Please wait...'),
    Loading: () => renderLoader('Please wait...'),
    Failure: () => renderSuccess(O.none)
  })(props.appInfo)
}

export {
  RemoteAppSettings
}
