import types from './types'

const fetchMessagesRequest = () => ({
  type: types.FETCH_MESSAGES_REQUEST
})
const fetchMessagesSuccess = payload => ({
  type: types.FETCH_MESSAGES_SUCCESS,
  payload
})
const fetchMessagesFailure = errors => ({
  type: types.FETCH_MESSAGES_NO_DATA,
  errors
})

const fetchMessagesPreviousPeriodRequest = () => ({
  type: types.FETCH_MESSAGES_PREVIOUS_PERIOD_REQUEST
})
const fetchMessagesPreviousPeriodSuccess = payload => ({
  type: types.FETCH_MESSAGES_PREVIOUS_PERIOD_SUCCESS,
  payload
})
const fetchMessagesPreviousPeriodFailure = errors => ({
  type: types.FETCH_MESSAGES_PREVIOUS_PERIOD_NO_DATA,
  errors
})

const fetchMessagesCountByHourRequest = () => ({
  type: types.FETCH_MESSAGES_COUNT_BY_HOUR_REQUEST
})
const fetchMessagesCountByHourSuccess = payload => ({
  type: types.FETCH_MESSAGES_COUNT_BY_HOUR_SUCCESS,
  payload
})
const fetchMessagesCountByHourFailure = errors => ({
  type: types.FETCH_MESSAGES_COUNT_BY_HOUR_NO_DATA,
  errors
})

const fetchMessagesAverageByDayRequest = () => ({
  type: types.FETCH_MESSAGES_AVERAGE_BY_DAY_REQUEST
})
const fetchMessagesAverageByDaySuccess = payload => ({
  type: types.FETCH_MESSAGES_AVERAGE_BY_DAY_SUCCESS,
  payload
})
const fetchMessagesAverageByDayFailure = payload => ({
  type: types.FETCH_MESSAGES_AVERAGE_BY_DAY_NO_DATA,
  payload
})

export default {
  fetchMessagesRequest,
  fetchMessagesSuccess,
  fetchMessagesFailure,
  fetchMessagesPreviousPeriodRequest,
  fetchMessagesPreviousPeriodSuccess,
  fetchMessagesPreviousPeriodFailure,
  fetchMessagesCountByHourRequest,
  fetchMessagesCountByHourSuccess,
  fetchMessagesCountByHourFailure,
  fetchMessagesAverageByDayRequest,
  fetchMessagesAverageByDaySuccess,
  fetchMessagesAverageByDayFailure
}
