import React from 'react'
import { Location } from 'history'
import { StaticContext } from 'react-router'
import classNames from 'classnames'
import * as R from 'ramda'
import { withRouter, Link, RouteComponentProps } from 'react-router-dom'
import { Menu } from 'semantic-ui-react'

import * as routes from '../../routes'
import RoundProgressBar from 'components/RoundProgressBar'

import './styles.css'

// currying seems to be off here, does not work if we apply it partially...
const isOnMonitorMatched = (location: Location) => R.propEq('pathname', routes.MONITOR, location)

const isOnMonitorUnmatched = (location: Location) => R.propEq('pathname', routes.MONITOR_UNMATCHED, location)

type MonitorTabsProps = RouteComponentProps<{}, StaticContext> & Readonly<{
  location: Location
  treatedLogs: number
  total: number
}>

const MonitorTabs = ({ location, treatedLogs, total }: MonitorTabsProps) => (
  <div className='MonitorTabs'>
    <div className='linkButtons'>
      <Menu className='MonitorTabs-menu'>
        <Menu.Item
          name='Unmatched'
          active={isOnMonitorUnmatched(location)}
          className={classNames('MonitorTabs-menu--item', {
            active: isOnMonitorUnmatched(location)
          })}
          as={Link}
          to={{ pathname: routes.MONITOR_UNMATCHED }}
        />
        <Menu.Item
          name='Matched'
          active={isOnMonitorMatched(location)}
          className={classNames('MonitorTabs-menu--item', {
            active: isOnMonitorMatched(location)
          })}
          as={Link}
          to={{ pathname: routes.MONITOR }}
        />
      </Menu>
    </div>
    <RoundProgressBar className='MonitorTabs-RoundBar' value={treatedLogs} max={total} />
    <p className='MonitorTabs-Roundbar--label'>
      {total - treatedLogs} {total - treatedLogs === 1 ? 'log' : 'logs'} before training
    </p>
  </div>
)

export default withRouter(MonitorTabs)
