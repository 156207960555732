import * as React from 'react'
import { remoteData } from '@sbizeul/fp-flow'

import { renderLoader, renderFailure } from 'helpers/react'

import CreationForm from './CreationForm'
import { Congrats } from './formComponents/Congrats'

import './styles.css'

const RemoteCreationForm = ({ botUrl, getLastBot, creationBotEnded, ...props }) => (
  <div className='creationForm-container'>
    {remoteData.fold({
      Success: botUrl => <Congrats botUrl={botUrl} getLastBot={getLastBot} creationBotEnded={creationBotEnded} />,
      NotAsked: () => (
        <CreationForm {...props} />
      ),
      Loading: () => renderLoader('Please wait...'),
      Failure: renderFailure
    }, botUrl)}
  </div>
)

export default RemoteCreationForm
