import React from 'react'
import { MdAdd } from 'react-icons/md'

import Button from 'components/Button'
import { Props as ButtonProps } from 'components/Button/Button'

type Props = Omit<ButtonProps, 'children'>

const CreateKeywordButton: React.FunctionComponent<Props> = props => (
  <Button {...props} className='primary'>
    <MdAdd className='Keywords-button-icon' size='20' />
    CREATE A KEYWORD
  </Button>
)

export default CreateKeywordButton
