import { connect } from 'react-redux'
import { maybe, remoteData } from '@sbizeul/fp-flow'

import Entity from '../components/Entity'

import { fetchOneRequest, update } from '../actions'
import { getSelectedEntity, getSelectedEntityId, isSynonymAdding, getIsDeletingSynonym } from '../selectors'

import bot from 'modules/bot'
import synonym from 'modules/synonym_old'

const mapStateToProps = state => {
  return {
    entity: getSelectedEntity(state),
    selectedEntityId: getSelectedEntityId(state),
    languages: bot.selectors.getLanguages(state),
    synonyms: maybe.fold(remoteData.notAsked, synonym.selectors.getByEntityId(state), getSelectedEntityId(state)),
    isSynonymAdding: isSynonymAdding(state),
    isDeletingSynonym: getIsDeletingSynonym(state)
  }
}

const mapDispatchToProps = {
  fetchEntity: fetchOneRequest,
  fetchSynonyms: synonym.actions.fetchSynonymsRequest,
  addSynonym: synonym.actions.addSynonymRequest,
  onDeleteSynonym: synonym.actions.deleteSynonymRequest,
  updateSynonym: synonym.actions.updateSynonymRequest,
  updateEntity: update
}

export default connect(mapStateToProps, mapDispatchToProps)(Entity)
