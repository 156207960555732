import { either } from '@sbizeul/fp-flow'
import * as R from 'ramda'

import * as array from 'helpers/array'

import * as skillActions from './actions'

export const initialState = {
  byId: {},
  isPosting: either.of(false)
}

export const byId = R.lensPath(['byId'])
export const isPosting = R.lensProp(['isPosting'])

const fetchAllRequest = state => {
  return R.set(isPosting, either.of(R.T()), state)
}

const fetchAllSuccess = (state, { payload: skills }) => {
  const newMap = array.toMapById(skills)
  return R.pipe(R.set(isPosting, either.of(R.F())), R.set(byId, newMap))(state)
}

const fetchAllFailure = (state, { payload: serverFailure }) => {
  return R.set(isPosting, either.left(serverFailure), state)
}

export default function skill(state = initialState, action) {
  switch (action.type) {
  case skillActions.FETCH_ALL_SKILLS_REQUEST:
    return fetchAllRequest(state)
  case skillActions.FETCH_ALL_SKILLS_SUCCESS:
    return fetchAllSuccess(state, action)
  case skillActions.FETCH_ALL_SKILLS_FAILURE:
    return fetchAllFailure(state, action)
  default:
    return state
  }
}
