import React from 'react'
import './index.css'

const releases = () => (
  <div>
    {/* Headers */}
    <div id='title-release'>Release notes</div>
    <div id='release-feedback'>
      We value your feedback, so if you have something to share then email us at hello@tolk.ai
    </div>

    {/* Release 7 */}
    <div className='release-title'>June 15, 2018 - Version 1.2.2</div>
    <div className='release-subtitle'>
      Improvements{' '}
      <span role='img' aria-label='emoji'>
        🤕
      </span>
    </div>
    <ul>
      <li>When you load the trainer or analytics, date of today is automatically selected</li>
      <li>Trainer is now fully operational with loaders</li>
      <li>Design in conversations</li>
    </ul>
    <div className='release-subtitle'>
      Bug fixes{' '}
      <span role='img' aria-label='skull'>
        💀
      </span>
    </div>
    <ul>
      <li>Mainly in analytics</li>
    </ul>

    {/* Release 6 */}
    <div className='release-title'>June 8, 2018 - Version 1.2.1</div>
    <div className='release-subtitle'>
      New{' '}
      <span role='img' aria-label='stars'>
        ✨
      </span>
    </div>
    <ul>
      <li>You can test your chatbot in the Trainer in a webchat</li>
    </ul>
    <div className='release-subtitle'>
      Improvements{' '}
      <span role='img' aria-label='emoji injured'>
        🤕
      </span>
    </div>
    <ul>
      <li>Analytics are much more faster to load</li>
    </ul>
    <div className='release-subtitle'>
      Bug fixes{' '}
      <span role='img' aria-label='skull'>
        💀
      </span>
    </div>
    <ul>
      <li>You can now change your password</li>
    </ul>

    {/* Release 5 */}
    <div className='release-title'>May 25, 2018 - Version 1.2</div>
    <div className='release-subtitle'>
      New{' '}
      <span role='img' aria-label='stars'>
        ✨
      </span>
    </div>
    <ul>
      <li>Bot builder: you can create a Q&A bot by uploading an Excel file</li>
      <li>
        When a bot is created, you have access to all intents/tags and can train directly the bot with new expressions
        and new answers
      </li>
      <li>You can also create intents with a simple form</li>
      <li>Trainer: Select all logs</li>
      <li>Trainer: possibility to train, delete, archive logs individually</li>
      <li>New chart in Analytics: Messages Sent By Users, Handled Messages and Messages Understanding</li>
    </ul>
    <div className='release-subtitle'>
      Improvements{' '}
      <span role='img' aria-label='emoji injured'>
        🤕
      </span>
    </div>
    <ul>
      <li>Trainer: all logs from all intents and languages are loaded automatically</li>
      <li>Trainer: you now have access to logs not matched and archived in separate tabs</li>
      <li>Trainer & Analytics: possibility to choose custom dates and to load directly from the date picker</li>
      <li>In the filter bar, when you click on min/hours/days, data is load automatically</li>
      <li>Trainer: design trainer bar</li>
    </ul>
    <div className='release-subtitle'>
      Bug fixes{' '}
      <span role='img' aria-label='skull'>
        💀
      </span>
    </div>
    <ul>
      <li>In Analytics, some bugs appeared when the date was changed or empty</li>
    </ul>

    {/* Release 4 */}
    <div className='release-title'>May 11, 2018 - Version 1.1.1</div>
    <div className='release-subtitle'>
      New{' '}
      <span role='img' aria-label='stars'>
        ✨
      </span>
    </div>
    <ul>
      <li>New KPIs and charts in Analytics</li>
      <li>
        In the Trainer section you can now filter and search by date / intent / platform and see the date / hour / min
        for each log{' '}
      </li>
      <li>Design were reviewed for all charts, footer, cards, tables and date pickers</li>
    </ul>
    <div className='release-subtitle'>
      Improvements{' '}
      <span role='img' aria-label='emoji injured'>
        🤕
      </span>
    </div>
    <ul>
      <li>Titles and captions in Analytics were reviewed</li>
      <li>Some words were excluded from the wordcloud for more consistency</li>
      <li>When you change tab, it is visible in the menu</li>
      <li>When no data is available, it is now much more pretty</li>
    </ul>

    {/* Release 3 */}
    <div className='release-title'>April 30, 2018 - Version 1.1</div>
    <div className='release-subtitle'>
      New{' '}
      <span role='img' aria-label='stars'>
        ✨
      </span>
    </div>
    <ul>
      <li>New architecture and design</li>
      <li>New KPIs and charts in Analytics</li>
    </ul>

    {/* Release 2 */}
    <div className='release-title'>April 17, 2018 - Version 1.0.2</div>
    <div className='release-subtitle'>
      New{' '}
      <span role='img' aria-label='stars'>
        ✨
      </span>
    </div>
    <ul>
      <li>New KPIs and charts in Analytics</li>
      <li>As soon as a user is onboarded, a email is sent with practical information</li>
    </ul>

    {/* Release 1 */}
    <div className='release-title'>April 10, 2018 - Version 1.0.1</div>
    <div className='release-subtitle'>
      New{' '}
      <span role='img' aria-label='stars'>
        ✨
      </span>
    </div>
    <ul>
      <li>New KPIs and charts in Analytics</li>
    </ul>
    <div className='release-subtitle'>
      Improvements{' '}
      <span role='img' aria-label='emoji injured'>
        🤕
      </span>
    </div>
    <ul>
      <li>Routes security</li>
      <li>Intervals between conversations are now set to 15min instead of 30min</li>
    </ul>
    <div className='release-subtitle'>
      Bug fixes{' '}
      <span role='img' aria-label='skull'>
        💀
      </span>
    </div>
    <ul>
      <li>Calculation of the answers delay was incorrect</li>
    </ul>
  </div>
)

export default releases
