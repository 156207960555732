import types from './types'

const fetchEscaladesEndRequest = () => ({
  type: types.FETCH_ESCALADESEND_REQUEST
})

const fetchEscaladesEndSuccess = payload => ({
  type: types.FETCH_ESCALADESEND_SUCCESS,
  payload
})

const fetchEscaladesEndFailure = errors => ({
  type: types.FETCH_ESCALADESEND_NO_DATA,
  errors
})

export default {
  fetchEscaladesEndRequest,
  fetchEscaladesEndSuccess,
  fetchEscaladesEndFailure
}
