import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect'
import { maybe } from '@sbizeul/fp-flow'
import * as R from 'ramda'

import user from '../../modules/user'
import bot from '../../modules/bot'

export const userIsAdmin = connectedRouterRedirect({
  redirectPath: '/analytics',
  authenticatedSelector: user.selectors.getIsAdmin,
  wrapperDisplayName: 'UserIsAdmin'
})

export const botIsPsa = connectedRouterRedirect({
  redirectPath: '/',
  authenticatedSelector: state => {
    return R.pipe(
      bot.selectors.getSelectedId,
      maybe.map(id => id === '5a0c2bc1946f111a4f15ee29'),
      maybe.getOrElse(R.F)
    )(state)
  },
  wrapperDisplayName: 'BotIsPsa'
})

export const botXRessource = connectedRouterRedirect({
  redirectPath: '/',
  authenticatedSelector: state => {
    const botIds = ['59bb972772095b091acab1d9', '5ab3973ab4469074c1ee37cf']
    return R.pipe(
      bot.selectors.getSelectedId,
      maybe.map(botId => botIds.includes(botId)),
      maybe.getOrElse(R.F)
    )(state)
  },
  wrapperDisplayName: 'BotXRessource'
})
