import React from 'react'
import PropTypes from 'prop-types'
import { Form, Label } from 'semantic-ui-react'

const FieldLabel = ({ error, warning }) => (
  <Label basic color={(error && 'red') || (warning && 'yellow')} pointing>
    {error}
  </Label>
)

FieldLabel.propTypes = {
  error: PropTypes.string,
  warning: PropTypes.string
}

FieldLabel.defaultProps = {
  error: undefined,
  warning: undefined
}

const InputField = ({
  className,
  icon,
  input,
  label,
  required,
  type,
  meta: { touched, error, warning },
  valeur,
  userInput
}) => (
  <Form.Field>
    {required ? <label htmlFor={input.name}>{`${label}*`}</label> : <label htmlFor={input.name}>{label}</label>}
    <div>
      <Form.Input
        {...input}
        className={className}
        placeholder={label}
        type={type}
        value={input.value ? input.value : ''}
        error={touched && error ? true : null}
        icon={icon}
      />
      {touched && (error || warning) && <FieldLabel error={error} warning={warning} />}
    </div>
  </Form.Field>
)

export default InputField
