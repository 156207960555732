import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { either } from '@sbizeul/fp-flow'
import { Form as SemanticForm } from 'semantic-ui-react'
import { curry } from 'ramda'

import { Error } from '../components/Message'
import { DotLoader, InlineLoader, Loader } from '../components/Loading'

const renderNothing = () => null

const renderFailure = error => (
  <Error header='Sorry, something went wrong with our service' content={error.toString()} />
)

const renderServerFailure = error => renderFailure(error.message)

const renderLoader = message => <Loader description={message} />

const renderInlineLoader = (message, className) => <InlineLoader description={message} className={className} />

const renderDotLoader = (message) => <DotLoader description={message} />

const Form = ({ activity, handleSubmit, testId, children }) => {
  return either.fold(
    () => (
      <SemanticForm data-testid={testId} error onSubmit={handleSubmit}>
        {children}
      </SemanticForm>
    ),
    creating => (
      <SemanticForm data-testid={testId} loading={creating} onSubmit={handleSubmit}>
        {children}
      </SemanticForm>
    ),
    activity
  )
}

const render = elementId => Component => {
  const el = document.getElementById(elementId)
  if (el) {
    ReactDOM.render(<Component />, el)
  } else {
    console.error(`Cannot find DOM node with id equals to ${elementId}`)
  }
}

const didMount = curry(
  (effect, WrappedComponent) =>
    class DidMount extends React.Component {
      componentDidMount() {
        effect(this.props)
      }

      render() {
        return <WrappedComponent {...this.props} />
      }
    }
)

const useEffectOnce = effect => useEffect(effect, [])

const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback()
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  })
}

export default useOutsideClick

export {
  Form,
  didMount,
  renderDotLoader,
  renderFailure,
  renderInlineLoader,
  renderServerFailure,
  renderNothing,
  renderLoader,
  render,
  useEffectOnce,
  useOutsideClick
}
