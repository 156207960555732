import types from './types'

const initialState = {
  funnel: [],
  fetchingFunnel: false
}

const funnelReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.FETCH_FUNNEL_REQUEST:
    return {
      ...state,
      fetchingFunnel: true
    }
  case types.FETCH_FUNNEL_SUCCESS:
    return {
      ...state,
      fetchingFunnel: false,
      funnel: action.payload
    }
  case types.FETCH_FUNNEL_NO_DATA:
    return {
      ...state,
      fetchingFunnel: false
    }
  default:
    return state
  }
}

export default funnelReducer
