import types from './types'

const fetchWordsCountRequest = () => ({
  type: types.FETCH_WORDSCOUNT_REQUEST
})

const fetchWordsCountSuccess = payload => ({
  type: types.FETCH_WORDSCOUNT_SUCCESS,
  payload
})

const fetchWordsCountFailure = errors => ({
  type: types.FETCH_WORDSCOUNT_NO_DATA,
  errors
})

export default {
  fetchWordsCountRequest,
  fetchWordsCountSuccess,
  fetchWordsCountFailure
}
