import React from 'react'
import classnames from 'classnames'
import './styles.css'

type Props = Readonly<{
  className?: string
}>

const HubIcon: React.FunctionComponent<Props> = (props) => (
  <div className={classnames('HubIcon', props.className)}>
    <svg className='HubIcon-svg' height='20pt' viewBox='0 -26 512 512' width='20pt' xmlns='http://www.w3.org/2000/svg'>
      <path d='m475.148438 317.941406h-19.429688v-35.542968c0-19.039063-15.527344-34.527344-34.617188-34.527344h-150.382812v-38.109375h99.679688c22.480468 0 40.773437-18.292969 40.773437-40.773438v-128.21875c0-22.480469-18.292969-40.769531-40.773437-40.769531h-229.320313c-22.476563 0-40.769531 18.289062-40.769531 40.769531v128.21875c0 22.480469 18.292968 40.773438 40.769531 40.773438h99.640625v38.109375h-149.820312c-19.089844 0-34.617188 15.488281-34.617188 34.527344v35.542968h-19.429688c-20.320312 0-36.851562 16.527344-36.851562 36.847656v68.359376c0 20.320312 16.53125 36.851562 36.851562 36.851562h68.859376c20.320312 0 36.847656-16.53125 36.847656-36.851562v-68.359376c0-20.320312-16.527344-36.847656-36.847656-36.847656h-19.429688v-35.542968c0-2.5 2.070312-4.527344 4.617188-4.527344h149.820312v40.070312h-19.148438c-20.320312 0-36.851562 16.527344-36.851562 36.847656v68.359376c0 20.320312 16.53125 36.851562 36.851562 36.851562h68.859376c20.320312 0 36.851562-16.53125 36.851562-36.851562v-68.359376c0-20.320312-16.53125-36.847656-36.851562-36.847656h-19.710938v-40.070312h150.382812c2.546876 0 4.617188 2.027344 4.617188 4.527344v35.542968h-19.429688c-20.320312 0-36.847656 16.527344-36.847656 36.847656v68.359376c0 20.320312 16.527344 36.851562 36.847656 36.851562h68.859376c20.320312 0 36.851562-16.53125 36.851562-36.851562v-68.359376c0-20.320312-16.53125-36.847656-36.851562-36.847656zm-143.136719-252.960937h16.078125c8.289062 0 15 6.710937 15 15 0 8.28125-6.710938 15-15 15h-16.078125c-8.292969 0-15-6.71875-15-15 0-8.289063 6.707031-15 15-15zm-166.8125 0h116.601562c8.289063 0 15 6.710937 15 15 0 8.28125-6.710937 15-15 15h-116.601562c-8.289063 0-15-6.71875-15-15 0-8.289063 6.710937-15 15-15zm0 79.800781c-8.289063 0-15-6.710938-15-15 0-8.28125 6.710937-15 15-15h166.8125c8.277343 0 15 6.71875 15 15 0 8.289062-6.722657 15-15 15zm0 0' />
    </svg>
  </div>
)

export default HubIcon
