import React from 'react'
import classNames from 'classnames'

import './Button.css'

export type Props = Readonly<{
  children: React.ReactNode
  className?: string
  display?: 'default' | 'rounded' | 'custom'
  type?: 'button' | 'submit' | 'reset'
  disabled?: boolean

  onClick?: () => unknown
}>

const Button: React.FunctionComponent<Props> = ({ display = 'default', disabled = false, type = 'button', ...props }) => {
  const getClassNames = () => {
    return classNames(
      'Button',
      {
        'Button-rounded': display === 'rounded',
        'Button-default': display === 'default',
        'Button-disabled': disabled
      },
      props.className
    )
  }

  return (
    <button className={getClassNames()} onClick={props.onClick} disabled={disabled} type={type}>
      <div className='Button-content'>{props.children}</div>
    </button>
  )
}

export default Button
