import React from 'react'
import { Button, Form as SemanticForm, Message } from 'semantic-ui-react'
import { either } from '@sbizeul/fp-flow'

import { Form, renderNothing } from '../../../../helpers/react'

import './styles.css'

class CreateEntityForm extends React.Component {
  state = { entityName: '' }

  handleChange = (event, { name, value }) => this.setState({ [name]: value })

  handleSubmit = () => this.props.createEntity({ name: this.state.entityName })

  fireOnClose = () => this.props.onClose && this.props.onClose()

  render() {
    const { isCreating } = this.props
    const { entityName } = this.state

    return (
      <Form testId='create-entity-form' activity={isCreating}>
        <SemanticForm.Field required>
          <label>Entity Name</label>
          <SemanticForm.Input
            placeholder='Entity Name...'
            name='entityName'
            value={entityName}
            onChange={this.handleChange}
          />
        </SemanticForm.Field>
        {either.fold(
          error => (
            <Message error header='Creation error' content={error} />
          ),
          renderNothing,
          isCreating
        )}
        <div className='CreateEntity-actions'>
          <Button
            className='primary'
            data-testid='submit'
            onClick={this.handleSubmit}
            disabled={this.state.entityName.trim().length === 0}
          >
            Create
          </Button>
          <Button data-testid='cancel' onClick={this.fireOnClose}>
            Cancel
          </Button>
        </div>
      </Form>
    )
  }
}

export default CreateEntityForm
