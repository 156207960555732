import React from 'react'
import { connect } from 'react-redux'
import { remoteData } from '@sbizeul/fp-flow'

import user from '../../../../modules/user'
import admin from '../../../../modules/admin'
import NewUserButton from './NewUserButton'
import { renderFailure } from '../../../../helpers/react'
import { Loader } from '../../../../components/Loading'
import UsersTable from './UsersTable'

function MainPage({ users, deleteUser }) {
  const renderUsers = users => <UsersTable data={users} deleteUser={deleteUser} />

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <NewUserButton />
      </div>
      {remoteData.fold(
        {
          Success: renderUsers,
          NotAsked: renderLoading,
          Loading: renderLoading,
          Failure: renderFailure
        },
        users
      )}
    </div>
  )
}

const renderLoading = () => <Loader description='Fetching users' />

const mapStateToProps = state => ({
  users: user.selectors.getAll(state)
})

const mapDispatchToProps = {
  deleteUser: admin.actions.deleteUser
}

const connectedMainPage = connect(mapStateToProps, mapDispatchToProps)(MainPage)

export default connectedMainPage
