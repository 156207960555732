import 'react-dates/initialize'
import React from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import PasswordContent from './Pass'
import operations from '../../../redux/intents/operations'

const PasswordPage = () => (
  <div className='PasswordPage pageContainer'>
    <Switch>
      <Route exact path='/password' component={PasswordContent} />
    </Switch>
  </div>
)
const mapStateToProps = state => ({})

const PassContentPage = connect(mapStateToProps, operations)(PasswordPage)

export default PassContentPage
