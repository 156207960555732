import React from 'react'
import { Modale, OutlinedUI } from '..'
import * as icons from '../../icons'
import { formatCohortSummary } from '../../helpers'
import moment from 'moment'

const formatDate = date => {
  const now = moment()
  const tomorrow = moment().add(1, 'days')
  if (date.isSame(now, 'days')) {
    return `today at ${date.format('h:mm A')}`
  }
  if (date.isSame(tomorrow, 'days')) {
    return `tomorrow at ${date.format('h:mm A')}`
  }
  return `on ${date.clone().format('LLLL')}`
}
const ConfirmCampaignBefore = ({ title, theme, cohorts, campaignCohorts, sendNow, date, confirm, cancel }) => {
  const formattedDate = formatDate(date)
  return (
    <Modale
      image={icons.CampaignSend}
      title='Attention please!'
      message={[
        `You will send ${title} to ${formatCohortSummary(cohorts, campaignCohorts)} ${sendNow ? 'now' : formattedDate}.`
      ]}
      postScriptum='You can still edit your campaign as long as the delivery is not initiated.'
      important
      resetModale={cancel}
      buttons={[
        {
          Component: OutlinedUI,
          props: {
            onClick: cancel,
            value: 'CANCEL',
            style: {
              color: theme.color.white,
              backgroundColor: theme.color.indicator
            }
          }
        },
        {
          Component: OutlinedUI,
          props: {
            onClick: confirm,
            value: 'CONFIRM',
            style: {
              color: theme.color.white,
              backgroundColor: theme.color.carouselDotSelected
            }
          }
        }
      ]}
    />
  )
}

export default ConfirmCampaignBefore
