import React from 'react'
import classNames from 'classnames'

import './Option.css'

export default function Option(props) {
  return (
    <div
      className={classNames(
        'Option',
        {
          'Option-striped': props.striped
        },
        props.className
      )}
      onClick={() => props.onClick(props.option)}
    >
      {props.option.text === 'Agent connect' && (<span><img className='Option-icon' src='/img/agent-logo.svg' alt="Agent Logo" /></span>)}
      <span className='Option-text'>{props.option.text === 'Agent connect' ? 'connect' : props.option.text}</span>
    </div>
  )
}
