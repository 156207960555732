import React from 'react'
import { AddFriendIcon } from '../../icons'
import { Cohort, CheckBox, CohortContainer, CohortsContainer, CohortsSummary } from './styles'
import { formatCohort, formatCohortSummary, calculateCohortSum } from '../../helpers'
import { allCohorts } from '../../reference'

const appendAllCohorts = cohorts => [
  {
    name: allCohorts,
    number: calculateCohortSum(
      cohorts,
      cohorts.map(({ name }) => name)
    )
  },
  ...cohorts
]

const Cohorts = ({ cohorts, selectCohort, campaignCohorts, canEdit }) => {
  const allSelected = campaignCohorts.length && campaignCohorts.length === cohorts.length

  return (
    <React.Fragment>
      <CohortsContainer>
        {appendAllCohorts(cohorts).map(cohort => {
          const selected = campaignCohorts.includes(cohort.name) || allSelected
          return (
            <CohortContainer key={cohort.name} onClick={() => selectCohort(cohort.name, cohorts)} disabled={!canEdit}>
              <CheckBox selected={selected} disabled={!canEdit} />
              <Cohort selected={selected}>{formatCohort(cohort)}</Cohort>
            </CohortContainer>
          )
        })}
      </CohortsContainer>
      <CohortsSummary>
        <AddFriendIcon />
        <span>{formatCohortSummary(cohorts, campaignCohorts)}</span>
      </CohortsSummary>
    </React.Fragment>
  )
}

export default Cohorts
