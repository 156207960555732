import React from 'react'
import { FaCircle } from 'react-icons/fa'

import './styles.css'

function EnabledState({ enabled = true }) {
  return (
    <div className='EnabledState'>
      <FaCircle className={enabled ? 'EnabledState-enabled' : 'EnabledState-disabled'} />
    </div>
  )
}

export default EnabledState
