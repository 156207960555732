import * as R from 'ramda'
import { v4 as uuid } from 'uuid'
import * as option from 'fp-ts/lib/Option'
import * as array from 'fp-ts/lib/Array'
import { Predicate } from 'fp-ts/lib/function'
import { maybe, Maybe, remoteData } from '@sbizeul/fp-flow'

import { isValidAnswer } from 'modules/answer/models.typed'
import { Keywords, TrackableKeyword, Keyword } from 'modules/keyword/types'
import { Id } from 'types'

import { Button, FALLBACK, ONBOARDING, DefaultSkill, Answer, Question, QuestionAndButtonIDs, HUMANHANDOVER, TAPFLOW } from './types'

export const emptyFormContent = (name: string) => ({
  inputs: [],
  name,
  conclusionAnswer: null
})

export const emptyAnswer: (scenarios: ReadonlyArray<QuestionAndButtonIDs>) => Answer = (scenarios) => ({
  scenarios: scenarios,
  isValid: false,
  answer: {
    id: uuid(),
    bot_id: '',
    type: 'Simple',
    content: []
  }
})

export const emptyButton: (id: Id) => Button = (id) => ({
  id,
  keywordID: option.none,
  label: ''
})

const emptyQuestion: () => Question = () => ({
  buttons: [],
  id: uuid(),
  label: ''
})

const isFallback = R.propEq('name', FALLBACK)

const isOnboarding = R.propEq('name', ONBOARDING)

const isHumanHandover = R.propEq('name', HUMANHANDOVER)

const isTapFlow = R.propEq('name', TAPFLOW)

const isValidSkill: Predicate<DefaultSkill> = isValidAnswer

const keywordIdLens: (buttonIndex: number) => R.Lens = (buttonIndex: number) =>
  R.lensPath(['buttons', buttonIndex, 'keywordID'])

const keywordIdFromLens: (
  question: Question
) => (keywordIdLens: R.Lens) => option.Option<Id> = question => keywordIdLens =>
  R.view(keywordIdLens, question)

const findKeywordById: (
  keywordId: Id
) => (keywords: Keywords) => option.Option<Keyword> = keywordId =>
  R.pipe(
    remoteData.getOrElse(() => []),
    array.findFirst(keyword => R.propEq('id', keywordId, keyword))
  )

const findKeywordByOptionalId: (
  keywords: Keywords
) => (keywordID: option.Option<string>) => option.Option<Keyword> = keywords =>
  option.chain(keywordId => findKeywordById(keywordId)(keywords))

const keywordId: (
  question: Question
) => (buttonIndex: number) => option.Option<Id> = question =>
  R.compose(keywordIdFromLens(question), keywordIdLens)

const selectedOptionalKeyword: (
  keywords: Keywords
) => (
  question: Question
) => (buttonIndex: number) => option.Option<Keyword> = keywords => question =>
  R.compose(findKeywordByOptionalId(keywords), keywordId(question))

const selectedKeyword: (
  keywords: Keywords
) => (
  question: Question
) => (buttonIndex: number) => Keyword | undefined = keywords => question =>
  R.pipe(
    selectedOptionalKeyword(keywords)(question),
    option.getOrElse<Keyword | undefined>(() => undefined)
)

const updateQuestionWithKeyword = (question: Question) => ({
  index,
  keyword
}: {
  index: number
  keyword: Maybe<TrackableKeyword>
}) => {
  const keywordID = maybe.fold(
    () => option.none,
    (keywordFromMaybe: TrackableKeyword) => option.some(keywordFromMaybe.id)
  )(keyword)

  return R.set(keywordIdLens(index), keywordID, question)
}

export {
  emptyQuestion,
  isFallback,
  isOnboarding,
  isHumanHandover,
  isTapFlow,
  isValidSkill,
  selectedKeyword,
  updateQuestionWithKeyword
}
