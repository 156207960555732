import React from 'react'
import type * as Axios from 'axios'
import * as reactRouter from 'react-router-dom'


import * as utils from '../../utils'

const component = (
  botManagementExpressAxios: Axios.AxiosInstance,
  document: Document,
  authManagementExpressBaseUrl: string,
  cookieDomain: string
): React.FC => () => {
  const history = reactRouter.useHistory()

  const SmartSkill = utils.smartSkillsComponent(botManagementExpressAxios, document, authManagementExpressBaseUrl, cookieDomain, history)

  const botId = localStorage.getItem('botId') || ''

    return (
      <SmartSkill botId={botId} />
    )
}

export { component }