import React, { Component } from 'react'
import { Button, Modal, Icon, Form, Message, Popup } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { List } from 'immutable'
import axios from 'axios'
import { maybe } from '@sbizeul/fp-flow'

import * as operations from '../.././../../redux/training/operations'
import bot from '../../../../modules/bot'
import { legacyApiUrl } from 'helpers/effects'

class IntentModal extends Component {
  constructor(props) {
    super(props)

    const { id, description } = this.props

    this.state = {
      modalOpen: false,
      name: '',
      description,
      id,
      error: false,
      loading: false
    }
  }

  getBotId() {
    return maybe.getOrElse(() => 'unknownBot', this.props.botId)
  }

  closeModal = () => {
    this.setState({ modalOpen: false })
  }

  handleInputChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    this.setState({ loading: true })

    const { name, description, id } = this.state

    const { activeChannelId, dateRange, activeLanguage } = this.props

    axios({
      url: `${legacyApiUrl}/contents/intents/${this.getBotId()}`,
      method: 'post',
      responseType: 'json',
      data: {
        name,
        description,
        id
      }
    })
      .then(res => {
        if (res.data) {
          this.setState({ loading: false })
          this.closeModal()
        } else {
          this.state({ error: true, loading: false })
        }
      })
      .then(this.props.fetchTrainingNotMatchedData(activeChannelId, dateRange, activeLanguage))
  }

  render() {
    const { modalOpen, error, loading } = this.state

    return (
      <Modal
        closeIcon
        onClose={this.closeModal}
        open={modalOpen}
        trigger={
          <Popup
            trigger={
              <Button
                icon
                inverted
                size='small'
                color='green'
                style={{ marginLeft: '40px' }}
                onClick={() => this.setState({ modalOpen: true })}
              >
                <Icon name='add' />
              </Button>
            }
            content='Create intent from this expression'
          />
        }
      >
        <Modal.Content>
          <Form onSubmit={this.handleSubmit} autoComplete='off'>
            <Form.Field>
              <Form.Input required name='name' placeholder='Intent Name' onChange={this.handleInputChange} />
            </Form.Field>
            <Form.Field>
              <Form.Input
                required
                name='description'
                placeholder='Description'
                defaultValue={this.props.description}
                onChange={this.handleInputChange}
              />
            </Form.Field>
            <Form.Field>
              <Form.Input required name='id' type='hidden' value={this.props.id} />
            </Form.Field>
            <Button type='submit' positive loading={loading}>
              Create
            </Button>
          </Form>
          {error ? <Message error header='There was some errors with your submission' /> : null}
        </Modal.Content>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  botId: bot.selectors.getSelectedId(state),
  activeChannelId: state.main.filters.channel.id,
  dateRange: state.training.getIn(['filters', 'dateRange'], List()).toJS(),
  activeLanguage: state.training.getIn(['language', 'data', 'id'])
})

export default connect(mapStateToProps, operations)(IntentModal)
