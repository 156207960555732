import React, { Component } from 'react'
import { Button, Modal, Icon, Form, Message, Popup } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { List } from 'immutable'
import axios from 'axios'
import * as operations from '../../../../../redux/analytics/operations'
import { legacyApiUrl } from 'helpers/effects'

class DeleteModal extends Component {
  constructor(props) {
    super(props)

    const { id, description } = this.props

    this.state = {
      modalOpen: false,
      name: '',
      description,
      id,
      error: false,
      loading: false
    }
  }

  closeModal = () => {
    this.setState({ modalOpen: false })
  }

  handleInputChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    this.setState({ loading: true })

    const { id } = this.state

    const { filters, reloadEvents } = this.props
    axios({
      url: `${legacyApiUrl}/admin/${this.props.botId}/analytics/events/${id}`,
      method: 'delete',
      responseType: 'json'
    }).then(res => {
      if (res.data) {
        this.setState({ loading: false })
        this.closeModal()
        reloadEvents(filters)
      } else {
        this.state({ error: true, loading: false })
      }
    })
  }

  render() {
    const { modalOpen, error, loading } = this.state

    return (
      <Modal
        closeIcon
        onClose={this.closeModal}
        open={modalOpen}
        trigger={
          <Popup
            trigger={
              <Icon className='small delete alternate link icon' onClick={() => this.setState({ modalOpen: true })} />
            }
            content='Delete this custom chart'
          />
        }
      >
        <Modal.Header>Delete</Modal.Header>
        <Modal.Content>
          Are you sure you want to delete the chart "{this.props.description}"?
          <Form onSubmit={this.handleSubmit} autoComplete='off'>
            <Form.Field>
              <Form.Input required name='id' type='hidden' value={this.props.id} />
            </Form.Field>
            <Button type='submit' positive loading={loading}>
              Delete
            </Button>
          </Form>
          {error ? <Message error header='There was some errors with your submission' /> : null}
        </Modal.Content>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  filters: state.main.filters,
  activeChannelId: state.main.filters.channel.id,
  dateRange: state.training.getIn(['filters', 'dateRange'], List()).toJS(),
  activeLanguage: state.training.getIn(['language', 'data', 'id'])
})

export default connect(mapStateToProps, operations)(DeleteModal)
