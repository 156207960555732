import { Either, RemoteData } from '@sbizeul/fp-flow'

import { Id, ServerFailure, Trackable } from '../../types'

export const CREATE_ONE_REQUEST = 'trainingQuestions/CREATE_ONE_REQUEST'
export const CREATE_ONE_FAILURE = 'trainingQuestions/CREATE_ONE_FAILURE'
export const CREATE_ONE_SUCCESS = 'trainingQuestions/CREATE_ONE_SUCCESS'

export const DELETE_ONE_REQUEST = 'trainingQuestions/DELETE_ONE_REQUEST'
export const DELETE_ONE_FAILURE = 'trainingQuestions/DELETE_ONE_FAILURE'
export const DELETE_ONE_SUCCESS = 'trainingQuestions/DELETE_ONE_SUCCESS'

export const UPDATE_ONE = 'trainingQuestions/UPDATE_ONE'

export const SAVE_ONE_REQUEST = 'trainingQuestions/SAVE_ONE_REQUEST'
export const SAVE_ONE_FAILURE = 'trainingQuestions/SAVE_ONE_FAILURE'
export const SAVE_ONE_SUCCESS = 'trainingQuestions/SAVE_ONE_SUCCESS'

export const FETCH_ALL_REQUEST = 'trainingQuestions/FETCH_ALL_REQUEST'
export const FETCH_ALL_FAILURE = 'trainingQuestions/FETCH_ALL_FAILURE'
export const FETCH_ALL_SUCCESS = 'trainingQuestions/FETCH_ALL_SUCCESS'

export type TrainingQuestionEntityState = {

}

export type TrainingQuestionEntitiesAction =
  | FetchAllRequest
  | FetchAllSuccess
  | FetchAllFailure

export type TrainingQuestion = Readonly<{
  external_id: string
  master: boolean
  question_id: Id
  text: string
} & Trackable>

export type CreateOneParams = Readonly<{
  questionId: Id
  text: string
}>
export type CreateOneRequest = Readonly<{
  type: typeof CREATE_ONE_REQUEST
  payload: CreateOneParams
}>

export type DeleteOneParams = Readonly<{
  questionId: Id
  trainingQuestionId: Id
}>
export type DeleteOneRequest = Readonly<{
  type: typeof DELETE_ONE_REQUEST
  payload: DeleteOneParams
}>

export type SaveOneParams = Readonly<{
  trainingQuestion: TrainingQuestion
  text: string
}>
export type SaveOneRequest = Readonly<{
  type: typeof SAVE_ONE_REQUEST
  payload: SaveOneParams
}>

export type FetchAllParams = Id
export type FetchAllRequest = Readonly<{
  type: typeof FETCH_ALL_REQUEST
  payload: FetchAllParams
}>

export type FetchAllSuccessParams = Readonly<{
  questionId: Id,
  trainingQuestions: ReadonlyArray<TrainingQuestion>
}>
export type FetchAllSuccess = Readonly<{
  type: typeof FETCH_ALL_SUCCESS
  payload: FetchAllSuccessParams
}>

export type FetchAllFailureParams = Readonly<{
  error: ServerFailure,
  questionId: Id
}>
export type FetchAllFailure = Readonly<{
  type: typeof FETCH_ALL_FAILURE
  payload: FetchAllFailureParams
}>

export type TrainingQuestions = RemoteData<ServerFailure, ReadonlyArray<TrainingQuestion>>

export type ListTrainingQuestionsProps = Readonly<{
  questionId: Id
  isChangingTrainingQuestion: Either<ServerFailure, boolean>
  trainingQuestions: TrainingQuestions
  questionTemplateId: string

  createTraining: (param: CreateOneParams) => unknown
  deleteTraining: (param: DeleteOneParams) => unknown
  saveTraining: (param: SaveOneParams) => unknown
  fetchTrainingQuestions: (param: FetchAllParams) => unknown
}>
