import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { MdArrowForward } from 'react-icons/md'
import { RemoteData, remoteData } from '@sbizeul/fp-flow'
import * as R from 'ramda'

import { Question } from 'modules/question/types'
import { ServerFailure } from 'types'
import EmptyStateContent from 'components/EmptyState'
import Button from 'components/Button'

import MonitorTabs from '../MonitorTabs'
import {
  fromAssignedQuestionToTaggedLog,
  monitorLogslogsAreEmpty,
  toFormattedUnmatchedLogs
} from '../../models'
import { AssignedQuestion, MonitorLogs, TaggedLog, UnmatchedQuestion, Progress } from '../../types'
import UnmatchedLog from './UnmatchedQuestion'
import ValidateButton from './ValidateButton'

import './styles.css'
import { renderInlineLoader, renderServerFailure } from 'helpers/react'

export const renderNoLogs = () => (
  <EmptyStateContent
    img='/img/speech-bubble.svg'
    title='No message to show :('
    text='Here you will be able to monitor your assistant’s comprehension. Talk with your assistant or add the script to your website to start.'>
    <Link to='/webchat'>
      <Button className='primary'>
        Go to Channel
        <MdArrowForward className='Monitor-Qna-empty-container--forwardIcon' />
      </Button>
    </Link>
  </EmptyStateContent>
)

export type UnmatchedLogsProps = Readonly<{
  logs: MonitorLogs,
  progress: RemoteData<ServerFailure, Progress>
  questions: RemoteData<ServerFailure, Question[]>

  validateTagging: (logs: TaggedLog[]) => unknown
}>

const renderEmptyUnmatchedQuestions = () => <div>No more unmatched question for this moment, Good Job!</div>

const isQuestionAtSuppliedIndexNotDeleted: (index: number) => (questions: AssignedQuestion[]) => boolean =
  index => R.pipe(
    R.find(question => question.dropdownIndex === index && question.deleted),
    R.isNil
  )

const UnmatchedQuestions: React.FunctionComponent<UnmatchedLogsProps> = props => {

  const dispatchValidationForSelectedQuestions = (selectedQuestions: AssignedQuestion[]) => {
    const formattedPayload = R.map(fromAssignedQuestionToTaggedLog)(selectedQuestions)
    props.validateTagging(formattedPayload)
    setAssignedQuestions([])
  }

  const renderUnmatchedQuestion = (unmatchedQuestion: UnmatchedQuestion, index: number) => {
    return (
      isQuestionAtSuppliedIndexNotDeleted(index)(assignedQuestions)
      && <UnmatchedLog
        key={index}
        assignedQuestions={assignedQuestions}
        unmatchedQuestion={unmatchedQuestion}
        index={index}
        questions={props.questions}
        onQuestionSelected={assignedQuestions => setAssignedQuestions(assignedQuestions)}/>
    )
  }

  const renderUnmatchedQuestions = (monitorLogs: MonitorLogs) => {
    const unmatchedQuestions = toFormattedUnmatchedLogs(monitorLogs)
    return unmatchedQuestions.length === 0
      ? renderEmptyUnmatchedQuestions()
      : <>
        <div className='unmatched-header'>
          <p className='unmatched-header-index'>#</p>
          <p className='unmatched-header-text'>User says...</p>
          <p className='unmatched-header-matching'>Matching</p>
          <p className='.unmatched-header-done'/>
        </div>
        {unmatchedQuestions.map((unmatchedQuestion, index) => renderUnmatchedQuestion(unmatchedQuestion, index))}
      </>
  }
  const renderAll = (remoteProgress: RemoteData<ServerFailure, Progress>) => (monitorLogs: MonitorLogs) => (
    <>
      {
        remoteData.fold(
          {
            Success: (progress: Progress) => <MonitorTabs treatedLogs={progress.treatedLogs} total={progress.total} />,
            NotAsked: renderInlineLoader,
            Loading: renderInlineLoader,
            Failure: renderServerFailure
          }
        )(remoteProgress)
      }
      <div className='unmatched-component'>
        {renderUnmatchedQuestions(monitorLogs)}
        {renderValidateButton(selectedAssignedQuestions)}
      </div>
    </>
  )

  const renderValidateButton = (selectedAssignedQuestions: AssignedQuestion[]) => (
    <div className='unmatched-bottom'>
      <ValidateButton
        numberToValidate={selectedAssignedQuestions.length}
        onClick={() => {
          dispatchValidationForSelectedQuestions(selectedAssignedQuestions)
        }}
      />
    </div>
  )

  const [assignedQuestions, setAssignedQuestions] = useState([] as AssignedQuestion[])
  const { logs } = props

  const selectedAssignedQuestions = assignedQuestions.filter(question => question.confirmed)
  const unmatchedLogs = logs.filter(log => !log.question)
  return (
    R.ifElse(monitorLogslogsAreEmpty, renderNoLogs, renderAll(props.progress))(unmatchedLogs)
  )
}

export default UnmatchedQuestions
