import moment from 'moment'

const date = moment([2019, 9, 16, 15, 6])

const editCarouselElementImageUrlTest = {
  initState: {
    campaigns: [
      {
        id: 1,
        updated: true,
        title: 'Campagne 1',
        elements: [
          {
            type: 'text',
            content: 'plafiplouf'
          },
          {
            type: 'carousel',
            content: {
              attachments: [
                {
                  title: 'A title 1',
                  text: 'A description',
                  image: {
                    url: '',
                    temporaryUrl: 'a-temp-url'
                  }
                },
                {
                  title: 'A title 2',
                  text: 'A description',
                  image: {
                    url: 'a-definitive-url',
                    temporaryUrl: ''
                  }
                }
              ]
            }
          }
        ],
        cohorts: [],
        date,
        kpis: {
          sent: 0,
          read: 0
        },
        status: 'draft'
      },
      {
        id: 2,
        updated: true,
        title: 'Campagne 2',
        elements: [],
        cohorts: [],
        date,
        kpis: {
          sent: 0,
          read: 0
        },
        status: 'scheduled'
      }
    ],
    currentId: 1
  },
  action: {
    index: 1,
    cardIndex: 0,
    url: 'a-definitive-url'
  },
  reducedState: {
    campaigns: [
      {
        id: 1,
        updated: true,
        title: 'Campagne 1',
        elements: [
          {
            type: 'text',
            content: 'plafiplouf'
          },
          {
            type: 'carousel',
            content: {
              attachments: [
                {
                  title: 'A title 1',
                  text: 'A description',
                  image: {
                    url: 'a-definitive-url', // updated
                    temporaryUrl: '' // updated
                  }
                },
                {
                  title: 'A title 2',
                  text: 'A description',
                  image: {
                    url: 'a-definitive-url',
                    temporaryUrl: ''
                  }
                }
              ]
            }
          }
        ],
        cohorts: [],
        date,
        kpis: {
          sent: 0,
          read: 0
        },
        status: 'draft'
      },
      {
        id: 2,
        updated: true,
        title: 'Campagne 2',
        elements: [],
        cohorts: [],
        date,
        kpis: {
          sent: 0,
          read: 0
        },
        status: 'scheduled'
      }
    ],
    currentId: 1
  }
}

export default editCarouselElementImageUrlTest
