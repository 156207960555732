import styled, { css } from 'styled-components'
import { initButton } from '../../style/globalStyles'

const showOnHover = css`
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
`

const hide = css`
  opacity: 0;
  pointer-events: auto;
  cursor: pointer;
  &.isVisible {
    pointer-events: auto;
    cursor: pointer;
    opacity: 1;
  }
`

const show = css`
  pointer-events: auto;
  cursor: pointer;
  opacity: 1;
  visibility: visible;
`

const absolute = css`
  position: absolute;
  left: ${({ theme, inside }) => (inside ? -theme.margin.left.trash / 2 : theme.margin.left.trash)}px;
  ${({ theme, notCentered }) => notCentered && `margin-top: ${theme.margin.top.trashNotCentered}px`};
`

const relative = css`
  position: relative;
`

const TrashStyled = styled.button`
  ${initButton}
  ${props => (props.relative ? relative : absolute)}
  color: ${({ theme }) => theme.color.indicator};
  font-size: ${({ size }) => size}px;

  transition: opacity 250ms ease-in-out;
  ${props => props.showOnHover && showOnHover}
  ${({ showWhenSelected, selected }) => showWhenSelected && !selected && hide}
  ${({ showWhenSelected, selected }) => showWhenSelected && selected && show}
`

export default TrashStyled
