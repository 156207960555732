import { either, maybe, remoteData, RemoteData } from '@sbizeul/fp-flow'
import * as R from 'ramda'
import * as E from 'fp-ts/lib/Either'
import { v4 as uuid } from 'uuid'
import * as option from 'fp-ts/lib/Option'

import answer from 'modules/answer'
import {
  AnswerContent,
  AnswerUiAction,
  SaveOneFailure,
  UploadImageSuccessPayload
} from 'modules/answer/types'
import { emptySimpleAnswer } from 'modules/answer/models.typed'
import { ServerFailure, Id } from 'types'

import * as skillActions from './actions'
import {
  CreateButtonContent,
  CreateImageContent,
  CreateTextContent,
  CreateTagAgentContent,
  CreateQuestionContent,
  DownloadFormDataAsExcelRequest,
  DownloadFormDataAsExcelFailure,
  DownloadFormDataAsExcelSuccess,
  FetchAllDefaultFailure,
  FetchAllDefaultSuccess,
  FetchDefaultSuccess,
  FetchDefaultFailure,
  FetchFormSuccess,
  FetchFormFailure,
  FetchFormsSuccess,
  FetchFormsFailure,
  FormSkillContent,
  FetchSkillSuccess,
  FetchSkillFailure,
  InputId,
  Question,
  RemoveFormContent,
  RemoveQuestionContent,
  RemoveScenarioAction,
  SaveFormFailure,
  SkillUiState,
  SkillUiAction,
  Skill,
  UpdateAnswerContent,
  UpdateFormContent,
  UpdateQuestionContent,
  FetchAllAdvancedSuccess,
  FetchAllAdvancedFailure,
  CreateScenarioAction,
  UpdateQuestionAction,
  UpdateQuestionContentParams,
  UploadImageRequest,
  UploadImageFailure,
  UploadImageSuccess,
  UpdateFormConclusion,
  SaveFormResponseRequest,
  FetchFormResponseSuccess,
  FetchFormResponseFailure,
  SaveFormResponseFailure,
  FetchSkillReferencesSuccess,
  FetchSkillReferencesFailure,
  FetchFormReferencesSuccess,
  FetchFormReferencesFailure,
} from './types'

export const initialState: SkillUiState = {
  allSkills: remoteData.notAsked(),
  defaultSkill: remoteData.notAsked(),
  formSkills: remoteData.notAsked(),
  formSkill: remoteData.notAsked(),
  formResponse: remoteData.notAsked(),
  formResponseError: option.none,
  skill: remoteData.notAsked(),
  isDownloadingFormData: {},
  advancedSkills: remoteData.notAsked(),
  isModified: false,
  isPosting: either.right(false),
  imagesById: {},
  skillReferences: remoteData.notAsked(),
  formReferences: remoteData.notAsked()
}

export const allSkills = R.lensProp('allSkills')
export const defaultSkill = R.lensProp('defaultSkill')
export const formSkills = R.lensProp('formSkills')
export const formSkill = R.lensProp('formSkill')
export const formResponse = R.lensProp('formResponse')
export const formResponseError = R.lensProp('formResponseError')
export const advancedSkills = R.lensProp('advancedSkills')
export const isModified = R.lensProp('isModified')
export const isPosting = R.lensProp('isPosting')
export const skill = R.lensProp('skill')

export const contentLens = R.lensProp('content')
export const questionsLens = R.lensProp('questions')
export const answersLens = R.lensProp('answers')
export const answerLens = R.lensProp('answer')
export const conclusionAnswer = R.lensProp('conclusionAnswer')

const fetchAll = R.set(allSkills, remoteData.loading())

const fetchAllSuccess = (action: FetchAllDefaultSuccess, state: SkillUiState) =>
  R.set(allSkills, remoteData.of(action.payload), state)

const fetchAllFailure = (action: FetchAllDefaultFailure, state: SkillUiState) =>
  R.set(allSkills, remoteData.failure(action.payload), state)

const fetch = R.pipe(
  R.set(defaultSkill, remoteData.loading()),
  R.set(isPosting, either.right(true))
)

const fetchSuccess = (action: FetchDefaultSuccess, state: SkillUiState) =>
  R.pipe(
    R.set(defaultSkill, remoteData.success(action.payload)),
    R.set(isModified, false),
    R.set(isPosting, either.right(false))
  )(state)

const fetchFailure = (action: FetchDefaultFailure, state: SkillUiState) =>
  R.pipe(
    R.set(defaultSkill, remoteData.failure(action.payload)),
    R.set(isPosting, either.right(false))
  )(state)

const fetchSkill = R.pipe(
    R.set(skill, remoteData.loading()),
    R.set(isPosting, either.right(true))
  )

const fetchSkillSuccess = (action: FetchSkillSuccess, state: SkillUiState) =>
  R.pipe(
    R.set(skill, remoteData.success(action.payload)),
    R.set(isModified, false)
  )(state)

const fetchSkillFailure = (action: FetchSkillFailure, state: SkillUiState) =>
  R.set(skill, remoteData.failure(action.payload), state)

const updateOneQuestion: (
  payload: UpdateQuestionContentParams
) => (
  stateSkill: RemoteData<ServerFailure, Skill>
) => RemoteData<ServerFailure, Skill> = payload =>
  remoteData.map((stateSkill: Skill) => {
    const index = R.findIndex((question: Question) => question.id === payload.id)(stateSkill.questions)
    const path = R.lensPath(['questions', index, 'label'])

    return R.set(path, payload.text, stateSkill)
  })

const updateQuestionSkillContent = ({ payload }: UpdateQuestionContent, state: SkillUiState) =>
  R.pipe(
    R.over(skill, updateOneQuestion(payload)),
    R.set(isModified, true)
  )(state)

const removeQuestionSkillContent = (action: RemoveQuestionContent, state: SkillUiState) =>
      R.pipe(
        R.over(
          skill,
          remoteData.map<Skill, Skill, ServerFailure>(
            stateSkill =>
            R.over(
              questionsLens,
              R.filter<InputId>(question => action.payload.id !== question.id),
              stateSkill
            )
          )
        ),
        R.set(isModified, true)
      )(state)

const fetchForm = R.pipe(
  R.set(formSkill, remoteData.loading()),
  R.set(isPosting, either.right(true))
)

const fetchFormSuccess = (action: FetchFormSuccess, state: SkillUiState) =>
  R.pipe(
    R.set(formSkill, remoteData.success(action.payload)),
    R.set(isModified, false),
    R.set(isPosting, either.right(false))
  )(state)

const fetchFormFailure = (action: FetchFormFailure, state: SkillUiState) =>
  R.pipe(
    R.set(formSkill, remoteData.failure(action.payload)),
    R.set(isPosting, either.right(false))
  )(state)

const fetchForms = R.pipe(
  R.set(formSkills, remoteData.loading()),
  R.set(isPosting, either.right(true))
)

const fetchFormResponse = R.set(formResponse, remoteData.loading())
const fetchFormResponseSuccess = (action: FetchFormResponseSuccess, state: SkillUiState) => R.set(formResponse, remoteData.success(action.payload))(state)
const fetchFormResponseFailure = (action: FetchFormResponseFailure, state: SkillUiState) => R.set(formResponse, remoteData.failure(action.payload))(state)

const fetchFormsSuccess = (action: FetchFormsSuccess, state: SkillUiState) =>
  R.pipe(
    R.set(formSkills, remoteData.success(action.payload)),
    R.set(isModified, false),
    R.set(isPosting, either.right(false))
  )(state)

const fetchFormsFailure = (action: FetchFormsFailure, state: SkillUiState) =>
  R.pipe(
    R.set(formSkills, remoteData.failure(action.payload)),
    R.set(isPosting, either.right(false))
  )(state)

const saveDefault = R.set(isPosting, either.right(true))

const saveDefaultSuccess = R.pipe(
  R.set(isPosting, either.right(false)),
  R.set(isModified, false)
)

const saveDefaultFailure = (action: SaveOneFailure | SaveFormFailure, state: SkillUiState) =>
  R.set(isPosting, either.left(action.payload), state)

const removeFormContent = (action: RemoveFormContent, state: SkillUiState) =>
  R.pipe(
    R.over(
      formSkill,
      remoteData.map<FormSkillContent, FormSkillContent, ServerFailure>(
        stateSkill =>
          R.over(
            contentLens,
            R.filter<InputId>(content => action.payload.id !== content.id),
            stateSkill
          )
      )
    ),
    R.set(isModified, true)
  )(state)

const updateOneContent: (
  payload: InputId
) => (
  stateSkill: RemoteData<ServerFailure, FormSkillContent>
) => RemoteData<ServerFailure, FormSkillContent> = (payload) =>
  remoteData.map((stateSkill: FormSkillContent) => {
    const index = R.findIndex((element: InputId) => element.id === payload.id)(
      stateSkill.content
    )
    const path = R.lensPath(['content', index, 'text'])
    return R.set(path, payload.text, stateSkill)
  })

const updateFormContent = ({ payload }: UpdateFormContent , state: SkillUiState) =>
  R.pipe(
    R.over(formSkill, updateOneContent(R.view(contentLens, payload))),
    R.set(isModified, true)
  )(state)

const updateFormConclusion = ({ payload }: UpdateFormConclusion, state: SkillUiState) =>
  R.pipe(
    R.over(formSkill, remoteData.map((stateSkill: FormSkillContent) => R.set(conclusionAnswer, option.fromNullable(maybe.getOrElse(() => emptySimpleAnswer('fr_FR'), payload)), stateSkill))),
    R.set(isModified, true)
  )(state)

const saveFormResponse = ({ payload }: SaveFormResponseRequest, state: SkillUiState) => R.pipe(
  R.over(
  formResponse,
  remoteData.map(
    formResponseState => R.pipe(
      R.set(R.lensProp('email'), option.fromNullable(payload.email)),
      R.set(R.lensProp('zapier'), option.fromNullable(payload.zapier))
    )(formResponseState)
    )
  ),
  R.set(formResponseError, option.none)
)(state)

const saveFormResponseFailure = (action: SaveFormResponseFailure, state: SkillUiState) => R.set(formResponseError, option.some(action.payload))(state)

const createFormContent = (state: SkillUiState) =>
  R.pipe(
    R.over(
      formSkill,
      remoteData.map<FormSkillContent, FormSkillContent, ServerFailure>(stateSkill =>
        R.set(
          R.lensProp('content'),
          R.append({ id: uuid(), text: [''] }, stateSkill.content),
          stateSkill
        )
      )
    ),
    R.set(isModified, true)
  )(state)

const createFormEmailContent = (state: SkillUiState) =>
  R.pipe(
    R.over(
      formSkill,
      remoteData.map<FormSkillContent, FormSkillContent, ServerFailure>(stateSkill =>
        R.set(
          R.lensProp('content'),
          R.append({ id: uuid(), text: [''], type: 'email' }, stateSkill.content),
          stateSkill
        )
      )
    ),
    R.set(isModified, true)
  )(state)

const createFormFileContent = (state: SkillUiState) =>
  R.pipe(
    R.over(
      formSkill,
      remoteData.map<FormSkillContent, FormSkillContent, ServerFailure>(stateSkill =>
        R.set(
          R.lensProp('content'),
          R.append({ id: uuid(), text: [''], type: 'file' }, stateSkill.content),
          stateSkill
        )
      )
    ),
    R.set(isModified, true)
  )(state)

const modifyFormContent = R.set(isModified, true)

const downloadFormDataRequest = (action: DownloadFormDataAsExcelRequest, state: SkillUiState) =>
  R.set(isDownloadingEntry(action), E.right(true), state)

const downloadFormDataFailure = (action: DownloadFormDataAsExcelFailure, state: SkillUiState) =>
  R.set(isDownloadingEntry(action), E.left(action.payload.error), state)

const downloadFormDataSuccess = (action: DownloadFormDataAsExcelSuccess, state: SkillUiState) =>
  R.set(isDownloadingEntry(action), E.right(false), state)

const isDownloadingEntry: (action: { payload: { skillId: Id } }) => R.Lens =
  action => R.lensPath(['isDownloadingFormData', action.payload.skillId])

const fetchAllAdvancedSuccess = (action: FetchAllAdvancedSuccess, state: SkillUiState) =>
  R.set(advancedSkills, remoteData.success(action.payload), state)

const fetchAllAdvancedFailure = (action: FetchAllAdvancedFailure, state: SkillUiState) =>
  R.set(advancedSkills, remoteData.failure(action.payload), state)

const fetchAllAdvanced = R.set(advancedSkills, remoteData.loading())

const createScenario: (action: CreateScenarioAction, state: SkillUiState) => SkillUiState = (action, state) => R.pipe(
  R.over(skill, remoteData.map(
    R.over(R.lensProp('answers'), R.append(action.payload))
  )),
  R.set(isModified, true)
)(state)

const createAnswerContent: (payload: { index: number }, state: SkillUiState, emptyContent: unknown) => SkillUiState =
(payload, state, emptyContent) =>
  R.pipe(
    R.over(
      skill,
      remoteData.map(
        R.pipe(
          R.over(
            R.lensPath(['answers', payload.index, 'answer', 'content']),
            R.append(emptyContent)
          )
        )
      )
    ),
    R.set(isModified, true)
  )(state)

const createButtonContent: (
  { payload }: CreateButtonContent,
  state: SkillUiState
) => SkillUiState = ({ payload }, state) => createAnswerContent(payload, state, answer.models.emptyButtonContent('fr_FR'))

const createImageContent: (
  { payload }: CreateImageContent,
  state: SkillUiState
) => SkillUiState = ({ payload }, state) => createAnswerContent(payload, state, answer.models.emptyImageContent('fr_FR'))

const createTagAgentContent: (
  { payload }: CreateTagAgentContent,
  state: SkillUiState
) => SkillUiState = ({ payload }, state) => createAnswerContent(payload, state, answer.models.emptyTagAgentContent())

const createTextContent: (
  { payload }: CreateTextContent,
  state: SkillUiState
) => SkillUiState = ({ payload }, state) => createAnswerContent(payload, state, answer.models.emptyTextContent('fr_FR'))

const getAnswerContentFromIndex: (index: number) => R.Lens = index => R.lensPath(['answers', index])

const updateAnswerContent: (action: UpdateAnswerContent, state: SkillUiState) => SkillUiState = (action, state) =>
  R.pipe(
    R.over(
      skill,
      remoteData.map(
        R.over(
          getAnswerContentFromIndex(action.payload.index),
          R.set(answerLens, maybe.get(action.payload.content))
        )
      )
    ),
    R.set(isModified, true)
  )(state)

const uploadImage = (state: SkillUiState, action: UploadImageRequest) => {
  return R.set(R.lensPath(['imageById', action.payload.id]), remoteData.loading(), state)
}

const uploadImageFailure = (state: SkillUiState, action: UploadImageFailure) => {
  return R.set(R.lensPath(['imageById', action.payload.id]), remoteData.failure(action.payload.error), state)
}

const updateImageContent: (payload: UploadImageSuccessPayload) => (contents: ReadonlyArray<AnswerContent>) => ReadonlyArray<AnswerContent> =
  payload => R.reduce(
    (contents, content) => [...contents, content.id === payload.id ? R.set(R.lensPath(['objectAttachment', 'imageUrl']), payload.url, content) : content],
    [] as ReadonlyArray<AnswerContent>
  )

const uploadImageSuccess = (state: SkillUiState, action: UploadImageSuccess) => {
  return R.pipe(
    R.set(R.lensPath(['imageById', action.payload.id]), remoteData.success(action.payload.url)),
    R.over(
      skill,
      remoteData.map(
        R.over(
          getAnswerContentFromIndex(action.payload.index),
          updateImageContent(action.payload)
        )
      )
    ),
    R.set(isModified, true)
  )(state)
}

const createQuestionContent: (action: CreateQuestionContent, state: SkillUiState) => SkillUiState =
  (action, state) => R.over(
    skill,
    remoteData.map(R.over(R.lensProp('questions'), R.append(action.payload)))
  )(state)

const updateQuestion: (action: UpdateQuestionAction, state: SkillUiState) => SkillUiState =
  (action, state) => R.pipe(
    R.over(
      skill,
      remoteData.map(R.over(R.lensProp('questions'), R.reduce<Question, ReadonlyArray<Question>>((questions, question) => [
        ...questions,
        question.id === action.payload.id ? action.payload : question], [] as ReadonlyArray<Question>))
      )
    ),
    R.set(isModified, true)
  )(state)

const saveSkillSuccess: (state: SkillUiState) => SkillUiState = R.set(isModified, false)

const handleRemoveScenario: (
  { payload }: RemoveScenarioAction,
  state: SkillUiState
) => SkillUiState = ({ payload }, state) =>
  R.pipe(
    R.over(
      skill,
      remoteData.map(R.over(answersLens, R.remove(payload, 1)))
    ),
    R.set(isModified, true)
  )(state)

const fetchSkillReferencesRequest = (state: SkillUiState) => ({
  ...state,
  skillReferences: remoteData.loading()
})

const fetchSkillReferencesSuccess = (state: SkillUiState, action: FetchSkillReferencesSuccess) => ({
  ...state,
  skillReferences: remoteData.success(action.payload)
})

const fetchSkillReferencesFailure = (state: SkillUiState, action: FetchSkillReferencesFailure) => ({
  ...state,
  skillReferences: remoteData.failure(action.payload)
})

const fetchFormReferencesRequest = (state: SkillUiState) => ({
  ...state,
  formReferences: remoteData.loading()
})

const fetchFormReferencesSuccess = (state: SkillUiState, action: FetchFormReferencesSuccess) => ({
  ...state,
  formReferences: remoteData.success(action.payload)
})

const fetchFormReferencesFailure = (state: SkillUiState, action: FetchFormReferencesFailure) => ({
  ...state,
  formReferences: remoteData.failure(action.payload)
})

export default function (state: SkillUiState = initialState, action: SkillUiAction | AnswerUiAction) {
  switch (action.type) {
    case skillActions.CREATE_ANSWER_BUTTON_CONTENT:
      return createButtonContent(action, state)
    case skillActions.CREATE_ANSWER_IMAGE_CONTENT:
      return createImageContent(action, state)
    case skillActions.UPLOAD_IMAGE_REQUEST:
      return uploadImage(state, action)
    case skillActions.UPLOAD_IMAGE_FAILURE:
      return uploadImageFailure(state, action)
    case skillActions.UPLOAD_IMAGE_SUCCESS:
      return uploadImageSuccess(state, action)
    case skillActions.CREATE_ANSWER_TAG_CONTENT:
      return createTagAgentContent(action, state)
    case skillActions.CREATE_ANSWER_TEXT_CONTENT:
      return createTextContent(action, state)
    case skillActions.CREATE_FORM_CONTENT:
      return createFormContent(state)
    case skillActions.CREATE_FORM_EMAIL_CONTENT:
      return createFormEmailContent(state)
    case skillActions.CREATE_FORM_FILE_CONTENT:
      return createFormFileContent(state)
    case skillActions.CREATE_SCENARIO:
      return createScenario(action, state)
    case skillActions.FETCH_DEFAULT_SKILL_REQUEST:
      return fetch(state)
    case skillActions.FETCH_DEFAULT_SKILL_SUCCESS:
      return fetchSuccess(action, state)
    case skillActions.FETCH_DEFAULT_SKILL_FAILURE:
      return fetchFailure(action, state)
    case skillActions.FETCH_ALL_DEFAULT_SKILLS_REQUEST:
      return fetchAll(state)
    case skillActions.FETCH_ALL_DEFAULT_SKILLS_SUCCESS:
      return fetchAllSuccess(action, state)
    case skillActions.FETCH_ALL_DEFAULT_SKILLS_FAILURE:
      return fetchAllFailure(action, state)
    case skillActions.FETCH_FORM_SKILL_REQUEST:
      return fetchForm(state)
    case skillActions.FETCH_FORM_SKILLS_SUCCESS:
      return fetchFormsSuccess(action, state)
    case skillActions.FETCH_FORM_SKILLS_FAILURE:
      return fetchFormsFailure(action, state)
    case skillActions.FETCH_FORM_SKILLS_REQUEST:
      return fetchForms(state)
    case skillActions.FETCH_FORM_SKILL_SUCCESS:
      return fetchFormSuccess(action, state)
    case skillActions.FETCH_FORM_SKILL_FAILURE:
      return fetchFormFailure(action, state)
    case skillActions.FETCH_FORM_RESPONSE_REQUEST:
      return fetchFormResponse(state)
    case skillActions.FETCH_FORM_RESPONSE_SUCCESS:
      return fetchFormResponseSuccess(action, state)
    case skillActions.FETCH_FORM_RESPONSE_FAILURE:
      return fetchFormResponseFailure(action, state)
    case skillActions.FETCH_SKILL_REQUEST:
      return fetchSkill(state)
    case skillActions.FETCH_SKILL_SUCCESS:
      return fetchSkillSuccess(action, state)
    case skillActions.FETCH_SKILL_FAILURE:
      return fetchSkillFailure(action, state)
    case skillActions.REMOVE_QUESTION_CONTENT:
      return removeQuestionSkillContent(action, state)
    case skillActions.REMOVE_FORM_CONTENT:
      return removeFormContent(action, state)
    case skillActions.REMOVE_SCENARIO:
      return handleRemoveScenario(action, state)
    case skillActions.UPDATE_ANSWER_SKILL_CONTENT:
      return updateAnswerContent(action, state)
    case skillActions.CREATE_QUESTION_CONTENT:
      return createQuestionContent(action, state)
    case skillActions.UPDATE_QUESTION:
      return updateQuestion(action, state)
    case skillActions.UPDATE_FORM_CONTENT:
      return updateFormContent(action, state)
    case skillActions.UPDATE_FORM_CONCLUSION:
      return updateFormConclusion(action, state)
    case skillActions.SAVE_FORM_RESPONSE_REQUEST:
      return saveFormResponse(action, state)
    case skillActions.SAVE_FORM_RESPONSE_FAILURE:
      return saveFormResponseFailure(action, state)
    case skillActions.UPDATE_QUESTION_CONTENT:
      return updateQuestionSkillContent(action, state)
    case skillActions.SAVE_FORM_REQUEST:
    case answer.actions.SAVE_ONE_REQUEST:
      return saveDefault(state)
    case skillActions.SAVE_FORM_SUCCESS:
    case answer.actions.SAVE_ONE_SUCCESS:
      return saveDefaultSuccess(state)
    case skillActions.SAVE_FORM_FAILURE:
    case answer.actions.SAVE_ONE_FAILURE:
      return saveDefaultFailure(action, state)
    case skillActions.DOWNLOAD_FORM_DATA_REQUEST:
      return downloadFormDataRequest(action, state)
    case skillActions.DOWNLOAD_FORM_DATA_FAILURE:
      return downloadFormDataFailure(action, state)
    case skillActions.DOWNLOAD_FORM_DATA_SUCCESS:
      return downloadFormDataSuccess(action, state)
    case skillActions.FETCH_ALL_ADVANCED_REQUEST:
      return fetchAllAdvanced(state)
    case skillActions.FETCH_ALL_ADVANCED_SUCCESS:
      return fetchAllAdvancedSuccess(action, state)
    case skillActions.FETCH_ALL_ADVANCED_FAILURE:
      return fetchAllAdvancedFailure(action, state)
    case skillActions.SAVE_SKILL_SUCCESS:
      return saveSkillSuccess(state)
    case answer.actions.UPDATE_CONTENT:
      return modifyFormContent(state)
    case skillActions.FETCH_SKILL_REFERENCES_REQUEST:
      return fetchSkillReferencesRequest(state)
    case skillActions.FETCH_SKILL_REFERENCES_SUCCESS:
      return fetchSkillReferencesSuccess(state, action)
    case skillActions.FETCH_SKILL_REFERENCES_FAILURE:
      return fetchSkillReferencesFailure(state, action)
    case skillActions.FETCH_FORM_REFERENCES_REQUEST:
      return fetchFormReferencesRequest(state)
    case skillActions.FETCH_FORM_REFERENCES_SUCCESS:
      return fetchFormReferencesSuccess(state, action)
    case skillActions.FETCH_FORM_REFERENCES_FAILURE:
      return fetchFormReferencesFailure(state, action)
    default:
      return state
  }
}
