import React, { useState } from 'react'
import { FaPlus, FaTimes } from 'react-icons/fa'
import * as R from 'ramda'

import { Id } from 'types'
import AddButton from 'components/AddButton'
import TextInput from 'components/TextInput'

import LogSelectedLabel from '../../LogSelected'

import './styles.css'

type Props = Readonly<{
  selectedLogIds: Id[]

  onQuestionEdited: (question: string) => unknown
  setSelectedLogIds: React.Dispatch<React.SetStateAction<string[]>>
}>

const AddQnA: React.FunctionComponent<Props> = ({ selectedLogIds, onQuestionEdited, setSelectedLogIds }) => {
  const [editable, setEditable] = useState(false)
  const [question, setQuestion] = useState('')

  const logsAreSelected = () => R.complement(R.isEmpty)(selectedLogIds)

  return (
    <div className='AddQnA'>
      <LogSelectedLabel logCount={selectedLogIds.length}/>
      {editable && logsAreSelected()
        ? <TextInput
          withBorder
          className='AddQnA-questionEditor'
          placeholder='Give your Q&A a name…'
          value={question}
          autoFocus={true}
          emptyDecoratorChild={<FaTimes />}
          decoratorChild={<FaPlus />}
          onChangeText={setQuestion}
          onEscapeKeyPressed={() => setEditable(false)}
          onEnterKeyPressed={() => {(!R.isEmpty(question) && onQuestionEdited(question)); setSelectedLogIds([])}}
          onDecoratorClick={value => {(R.isEmpty(value) ? setEditable(false) : onQuestionEdited(question)); setSelectedLogIds([])}}
        />
        : <AddButton disabled={selectedLogIds.length === 0} onClick={() => setEditable(true)}>Add a Q&amp;A</AddButton>
      }
    </div>
  )
}

export default AddQnA
