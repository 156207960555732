import React, { ReactElement, useEffect, useState } from 'react'
import { MdDone, MdArrowForward } from 'react-icons/md'
import { Link } from 'react-router-dom'
import * as R from 'ramda'
import { remoteData } from '@sbizeul/fp-flow'
import moment from 'moment'
import { v4 as uuid } from 'uuid'
import classNames from 'classnames'

import { Id } from 'types'
import Button from 'components/Button'
import EmptyStateContent from 'components/EmptyState'
import { DotLoader } from 'components/Loading'
import { renderServerFailure, renderLoader, renderFailure } from 'helpers/react'

import { MonitorLogs, MonitorLog } from '../../types'
import { GroupedUserLogs, OnSelected, RemoteDataOutOfScopeLogsProps } from './types'
import * as logModel from './models'
import AddQnA from './AddQnA'
import SuccessfulLabel from './SuccessfulLabel'
// import SuggestedQnA from './SuggestedQnAToggle'

import './styles.css'

const renderEmptyState = () => (
  <EmptyStateContent
    title='Wait mate :)'
    text='To be able to manage out of scope logs, you first need to monitor.
  Please go to monitor, then you will be able to create new Q&A form it!'
    img='/img/hourglass.svg'
  >
    <Link to='/monitor/matched'>
      <Button className='primary'>MONITOR NOW<MdArrowForward className='OutOfScope-empty-container--forwardIcon'/></Button>
    </Link>
  </EmptyStateContent>
)

const RemoteDataOutOfScopeLogs: React.FunctionComponent<RemoteDataOutOfScopeLogsProps> =
  ({ logsOutOfScope, createdQuestionId, fetchOutOfScopeLogs, onQuestionCreated }) => {
    useEffect(
      () => { fetchOutOfScopeLogs() },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    )
    return remoteData.fold({
      Success: logs => (
        <OutOfScopeLogs
          logs={logs}
          createdQuestionId={createdQuestionId}
          onQuestionCreated={onQuestionCreated}
        />
      ),
      Loading: () => renderLoader('Loading ...'),
      NotAsked: () => renderLoader('Loading ...'),
      Failure: renderServerFailure
    }, logsOutOfScope)
  }

type OutOfScopeLogsProps = Omit<RemoteDataOutOfScopeLogsProps, 'fetchOutOfScopeLogs' | 'logsOutOfScope'> & Readonly<{
  logs: MonitorLogs
}>

const OutOfScopeLogs: React.FunctionComponent<OutOfScopeLogsProps> =
  ({ logs, createdQuestionId, onQuestionCreated }) => {
    const [selectedLogIds, setSelectedLogIds] = useState<Id[]>([])
    const [groupedLogs, setGroupedLogs] = useState<GroupedUserLogs>([logs])
    useEffect((
      ) => {
        setGroupedLogs([logs])
      }, [logs])
    // const onToggleSuggestedQnA: () => void = () =>
    //   setGroupedLogs([logs])

    const onSelected: OnSelected = R.pipe(
      logModel.updateSelectedIds(selectedLogIds),
      setSelectedLogIds
    )

    const onQuestionEdited: (question: string) => unknown = question => {
      onQuestionCreated({
        question,
        selectedLogs: logModel.selectedLogs(selectedLogIds)(logs)
      })
    }

    const renderOutOfScopeLog: (userLog: MonitorLog) => ReactElement =
      userLog => {
        return <OutOfScopeLog
          key={uuid()}
          log={userLog}
          selected={logModel.isSelected(userLog.id)(selectedLogIds)}
          onSelected={onSelected}
        />
      }

    const renderOutOfScopeLogs: (logs: MonitorLogs) => ReactElement = logs => (
      <ul key={uuid()} className='OutOfScope-container'>
        {R.map(renderOutOfScopeLog, logs)}
      </ul>
    )

    const renderGroupedOutOfScopeLogs: (logs: GroupedUserLogs) => ReactElement =
      R.ifElse(logModel.noLogs, renderEmptyState, R.map(renderOutOfScopeLogs))

    return (
      <div className='OutOfScope pageContainer'>
        <div className='OutOfScope-header'>
          <div className='OutOfScope-title'>
            <h1>Out of scope</h1>
            {/* {<SuggestedQnA onChange={onToggleSuggestedQnA} />} */}
          </div>
          {remoteData.fold({
            Success: questionId => selectedLogIds.length === 0
              ? <SuccessfulLabel createdQuestionId={questionId}/>
              : <AddQnA selectedLogIds={selectedLogIds} onQuestionEdited={onQuestionEdited} setSelectedLogIds={setSelectedLogIds}/>,
            NotAsked: () => <AddQnA selectedLogIds={selectedLogIds} onQuestionEdited={onQuestionEdited} setSelectedLogIds={setSelectedLogIds}/>,
            Loading: () => <DotLoader description={'creating question'}/>,
            Failure: error => (
              <>
                {renderFailure(error.message)}
                <AddQnA selectedLogIds={selectedLogIds} onQuestionEdited={onQuestionEdited} setSelectedLogIds={setSelectedLogIds}/>
              </>
            )
          }, createdQuestionId)}
        </div>
        {renderGroupedOutOfScopeLogs(groupedLogs)}
      </div>
    )
  }

type OutOfScopeLogProps = Readonly<{
  log: MonitorLog,
  selected: boolean

  onSelected: OnSelected
}>

const OutOfScopeLog: React.FunctionComponent<OutOfScopeLogProps> = ({ log, selected, onSelected }) => {
  const [isSelected, setSelected] = useState(selected)

  const handleSelectClick = () => {
    const selected = !isSelected
    setSelected(selected)
    onSelected({ logId: log.id, isSelected: selected })
  }

  return (
    <div className='OutOfScope-log' onClick={handleSelectClick}>
      <div className='OutOfScope-select'>
        <MdDone className={classNames('OutOfScope-select--icon', { 'selected': isSelected })}/>
      </div>
      <div className='OutOfScope-text'>{log.text}</div>
      <div className='OutOfScope-date'>{toDate(log)}</div>
    </div>
  )
}

const toDate: (log: MonitorLog) => string = log => moment(log.createdAt).calendar(undefined, {
  sameDay: '[Today]',
  lastDay: '[Yesterday]',
  lastWeek: 'DD/MM/YYYY',
  sameElse: 'DD/MM/YYYY'
})

export default RemoteDataOutOfScopeLogs
