const toMap = entities => {
  return entities.reduce((acc, currentEntity) => {
    acc[currentEntity.name] = currentEntity
    return acc
  }, {})
}

function groupSynonymsByLanguages(languages, synonyms) {
  return languages.reduce(
    (accumulator, currentLanguage) => ({
      ...accumulator,
      [currentLanguage]: synonyms.filter(synonym => synonym.language === currentLanguage)
    }),
    {}
  )
}

function getSynonymCountByLanguage(synonymsByLanguages, language) {
  const synonyms = synonymsByLanguages[language]
  return synonyms ? synonyms.length : 0
}

export { toMap, getSynonymCountByLanguage, groupSynonymsByLanguages }
