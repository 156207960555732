import React from 'react'
import { Route, Switch } from 'react-router-dom'

import AddEvents from './Add'
import MyEvents from './MyEvents'

const EventsPage = () => (
  <Switch>
    <Route exact path='/analytics/events' component={MyEvents} />
    <Route exact path='/analytics/events/add' component={AddEvents} />
  </Switch>
)

export default EventsPage
