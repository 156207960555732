import React from 'react'

import Select from '../../../../components/Select'

const IntentDropDown = ({
  setActiveIntent,
  clearIntent,
  intents,
  fetch,
  activeBot,
  activeChannelId,
  dateRangeStart,
  dateRangeEnd,
  language,
  activeIntent
}) => {
  const handleChange = ({ value: id, text: name }) => {
    setActiveIntent(id, name)
    fetch(activeChannelId, { start: dateRangeStart, end: dateRangeEnd }, language, name)
  }

  const handleClear = () => {
    clearIntent()
    fetch(activeChannelId, { start: dateRangeStart, end: dateRangeEnd }, language, null)
  }

  if (intents.length >= 1) {
    const options = intents.map(intent => ({ value: intent.name, text: intent.name }))
    return (
      <Select
        placeholder='Select intent'
        value={activeIntent}
        options={options}
        onChange={handleChange}
        withSearch
        onClear={handleClear}
      />
    )
  }
  return null
}

export default IntentDropDown
