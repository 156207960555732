import types from './types'

const initialState = {
  fetching: false
}

const messagesHeatReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.FETCH_MISUNDERSTOOD_REQUEST:
    return { ...state, fetching: true, data: null, count: null }

  case types.FETCH_MISUNDERSTOOD_NO_DATA:
    return { ...state, fetching: false }

  case types.FETCH_MISUNDERSTOOD_SUCCESS:
    return {
      ...state,
      fetching: false,
      data: action.payload.data,
      count: action.payload.count
    }

  case types.FETCH_MISUNDERSTOOD_AVERAGE_REQUEST:
    return { ...state, fetching: true, data: null, count: null }

  case types.FETCH_MISUNDERSTOOD_AVERAGE_NO_DATA:
    return { ...state, fetching: false }

  case types.FETCH_MISUNDERSTOOD_AVERAGE_SUCCESS:
    return {
      ...state,
      fetchingAverage: false,
      dataAverage: action.payload.data,
      countAverage: action.payload.count
    }

  default:
    return state
  }
}

export default messagesHeatReducer
