import * as React from 'react'
import Textarea from 'react-textarea-autosize'
import classNames from 'classnames'
import * as R from 'ramda'

import './TextArea.css'

function TextArea(props, ref) {
  const handleChange = evt => {
    if (props.onChange) {
      props.onChange(evt)
    }
    if (props.onChangeText) {
      props.onChangeText(evt.target.value)
    }
  }

  const isKeyPressed = R.curry(
    (expectedKey, onKeyPressed, key) => R.not(R.isNil(onKeyPressed)) && R.equals(key, expectedKey)
  )

  const handleKeyPressed = keyboardEvent => {
    keyboardEvent.persist()
    R.cond([[isKeyPressed('Escape', props.onEscapeKeyPressed), props.onEscapeKeyPressed]])(keyboardEvent.key)
  }

  return (
    <Textarea
      ref={ref}
      className={classNames(
        'TextArea',
        { 'TextArea-rounded': props.type === 'rounded' },
        { 'TextArea-border': props.withBorder },
        { 'TextArea-disabled': props.disabled },
        props.className
      )}
      type='textArea'
      name={props.name}
      value={props.value}
      onChange={handleChange}
      onKeyDown={handleKeyPressed}
      onBlur={props.onBlur}
      required={props.required}
      placeholder={props.placeholder}
      autoFocus={props.autoFocus}
      disabled={props.disabled}
      autoComplete={props.autocomplete}
    />
  )
}

export default React.forwardRef(TextArea)
