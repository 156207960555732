import React from 'react'
import classNames from 'classnames'
import onClickOutside from 'react-onclickoutside'
import { VelocityComponent } from 'velocity-react'
import { FaCaretDown } from 'react-icons/fa'

import TextInput from '../TextInput'
import Option from '../Option/Option'
import './Select.css'

export class Select extends React.Component {
  state = {
    isOpen: false,
    textFilter: ''
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  handleClickOutside = () => {
    this.setState({ isOpen: false })
  }

  handleClickOption = option => {
    this.setState({ isOpen: false })
    this.props.onChange(option)
  }

  handleChangeText = text => {
    this.setState({ textFilter: text })
  }

  filterOptions = option => {
    return option.text.toLowerCase().includes(this.state.textFilter)
  }

  handleClear = e => {
    e.stopPropagation()
    if (this.props.onClear) {
      this.props.onClear()
    }
  }

  renderIcon() {
    return this.props.onClear && this.props.value ? (
      <span data-testid='cross' className='Select-control--cross' onClick={this.handleClear}>
        ✕
      </span>
    ) : (
      <VelocityComponent animation={{ rotateZ: this.state.isOpen ? '180deg' : '0deg' }} duration={100}>
        <FaCaretDown className='Select-control--faCaret' />
      </VelocityComponent>
    )
  }

  renderSelectedText() {
    const option = this.props.options.find(o => o.value === this.props.value)
    return (
      <p className='Select-control--selectedText'>
        <span className='Select-control--selectedText---text'>{option ? (
          <>
            {option.text === 'Agent connect' && (<span><img className='Select-icon' src='/img/agent-logo.svg' alt="Agent Logo" /></span>)}
            <span>{option.text === 'Agent connect' ? 'connect' : option.text}</span>
          </>
         ) : null}</span>
      </p>
    )
  }

  renderOption = (option, idx) => {
    return (
      <Option
        key={`select_option_${option.value}`}
        option={option}
        striped={idx % 2 === 0}
        onClick={this.handleClickOption}
      />
    )
  }

  renderOptions = options => {
    return (
      <div className='Select-options' style={{ top: this.props.withSearch ? 32 : 0 }}>
        {options.map(this.renderOption)}
      </div>
    )
  }

  renderTextInput() {
    return <TextInput value={this.state.textFilter} />
  }

  renderPlaceholder(placeholder) {
    return <span className='Select-control--placeholder'>{placeholder}</span>
  }

  renderDropdown() {
    return (
      <div className='Select-wrapper'>
        {this.props.withSearch && (
          <TextInput
            className='Select-textInput'
            onChangeText={this.handleChangeText}
            value={this.state.textFilter}
            placeholder='search...'
            autoFocus
          />
        )}
        {this.renderOptions(this.props.options.filter(this.filterOptions))}
      </div>
    )
  }

  render() {
    return (
      <div className={classNames('Select', this.props.className)}>
        <div
          className={classNames('Select-control', {
            'Select-control--open': this.state.isOpen
          })}
          onClick={this.toggle}
        >
          {this.props.value ? this.renderSelectedText() : this.renderPlaceholder(this.props.placeholder)}
          {this.renderIcon()}
        </div>
        {this.state.isOpen && this.renderDropdown()}
      </div>
    )
  }
}

export default onClickOutside(Select)
