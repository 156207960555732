import React from 'react'
import ReactHighcharts from 'react-highcharts'
import defaultStyle from '../chartStyle'
import NoDataSegment from '../NoDataSegment'

const config = {
  ...defaultStyle,
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: 'pie'
  },
  tooltip: {
    pointFormat: '{series.name}: <b>{point.percentage:.1f}% ({point.y})</b>',
    shared: true
  },
  plotOptions: {
    pie: {
      cursor: 'pointer',
      dataLabels: {
        enabled: false
      },
      showInLegend: true
    }
  }
}

export default function CustomPieChart({ chartId, name, data, noDataDisplay }) {
  if (noDataDisplay) {
    return <NoDataSegment />
  }
  const keys = Object.keys(data)
  const custom = {
    type: 'pie',
    animation: false,
    name: 'Repartition',
    data: keys.map(key => [key, data[key]])
  }
  config.series = [custom]
  return <ReactHighcharts config={config} domProps={{ id: chartId }} />
}
