import React from 'react'
import { Grid, Segment } from 'semantic-ui-react'
import isEmpty from 'lodash/isEmpty'

import Tiles from '../../components/Tiles'
import '../index.css'
import text from '../../../../../config/translations/en.json'
import TextButtons from '../../components/PieCharts/TextButtons'
import CustomPieChart from '../../components/PieCharts/CustomPieChart'
import CustomPieChart2 from '../../components/PieCharts/CustomPieChart0'
import MenuUtilChart from '../../components/AreaCharts/MisunderstoodHighcharts'
import MenuUtilChart2 from '../../components/AreaCharts/MisunderstoodHighcharts0'
import MoreInfoPopup from '../../../../components/MoreInfoPopup'
import HotPath from '../../components/Funnel/HotPath'

const style = {
  borderRadius: '3px',
  border: 'none',
  boxShadow: '0 2px 4px rgba(50, 50, 93,.1)',
  padding: '0'
}

const isAuthorizedBotsHotPath = botId => {
  const LucieDevId = '5bffee8507c16e75e4a51c01'
  const LucieProdId = '5b430a8111a5136f6789196a'
  const DsAutoDevId = '5bed3f7707c16e75e4a51bfa'
  const DsAutoProdId = '5bed437ce6f4b847256c6d57'
  const AmpDevId = '5bffcb1607c16e75e4a51bfe'
  const AmpProdId = '5bffd1fbe6f4b847256c6d5d'
  const BrealDevId = '5bfd426507c16e75e4a51bfc'
  const BrealProdId = '5bfd59a3e6f4b847256c6d5b'
  const DsAutoLeadGenProdId = '5d39ab344f65193bcb2279ee'
  const muglerProdId = '5c9b3fe7d0760c4dded64932'
  const freeprintsProdId = 'C1fe113cd1cb3Eb5A3d4fdf6'
  const freeprintsDevId = '28e6C1F3EaA4dbf19cF1cBDb'
  const opelProdId = '5d19ca864f65193bcb2279ed'
  const opelPreprodId = '5d0b69d8cbd87d203b9cdccb'
  const opelDevId = '5d0b5ef7b00739025445f432'
  const brealTolkId = 'DFDfEdeD3ed563ADAB14EfeA'
  const dsTolkId = '5179FE835AF2A8F9CfFD94be'

  const botIds = [
    LucieDevId,
    LucieProdId,
    DsAutoDevId,
    DsAutoProdId,
    AmpDevId,
    AmpProdId,
    BrealDevId,
    BrealProdId,
    DsAutoLeadGenProdId,
    muglerProdId,
    freeprintsProdId,
    freeprintsDevId,
    opelProdId,
    opelPreprodId,
    opelDevId,
    brealTolkId,
    dsTolkId
  ]
  return botIds.includes(botId)
}

const isAuthorizedBotPermanentMenu = botId => {
  const botIds = ['584edeb7b7dd9b11aa51d3c4', '5899b1c501249f780b5df9e6']
  return botIds.includes(botId)
}

const isAuthorizedBotReferral = botId => {
  const botIds = ['59f09b39bed87007d10138b8', '5b430a8111a5136f6789196a']
  return botIds.includes(botId)
}

const Behaviours = ({
  botId,
  wordsCount,
  fetchingWords,
  noWordsCount,
  charCount,
  noIntentsData,
  payloadsCount,
  messagesCount,
  menuPersistent,
  menuPersistentUtil,
  referral,
  referralUtil,
  noReferralUtil,
  funnel
}) => (
  <div>
    <Grid columns={4}>
      <Grid.Column mobile={8} tablet={4} computer={4} widescreen={4}>
        <Tiles
          name='Words per message'
          value={Math.round(wordsCount * 10) / 10}
          noData={noWordsCount}
          moreInfo='Average number of words per message'
          loading={fetchingWords}
        />
      </Grid.Column>
      <Grid.Column mobile={8} tablet={4} computer={4} widescreen={4}>
        <Tiles
          name='Characters per message'
          value={Math.round(charCount * 10) / 10}
          noData={noWordsCount}
          moreInfo='Average number of characters per message'
          loading={fetchingWords}
        />
      </Grid.Column>
    </Grid>
    <Grid>
      <Grid.Row>
        <Grid.Column mobile={16} tablet={8} computer={8} widescreen={8}>
          <h1>User input</h1>
          <Segment style={style}>
            <TextButtons data1={payloadsCount} data2={messagesCount} noDataDisplay={noIntentsData} />
          </Segment>
        </Grid.Column>
      </Grid.Row>
      {isAuthorizedBotsHotPath(botId) && (
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={16} widescreen={16}>
            <h1>Hot path</h1>
            <Segment style={style}>
              <HotPath name='funnel' noDataDisplay={isEmpty(funnel)} />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      )}
      {isAuthorizedBotPermanentMenu(botId) && (
        <React.Fragment>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={8} computer={8} widescreen={8}>
              <h1>
                Permanent menu
                <MoreInfoPopup content={text.behaviours.permanentmenu} />
              </h1>
              <Segment style={style}>
                <CustomPieChart
                  chartId='menu1'
                  name='menu persitent'
                  data={menuPersistent}
                  noDataDisplay={isEmpty(menuPersistent)}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8} widescreen={8}>
              <h1>
                Permanent menu
                <MoreInfoPopup content={text.behaviours.permanentmenuuse} />
              </h1>
              <Segment style={style}>
                <MenuUtilChart period='' data={menuPersistentUtil} noDataDisplay={isEmpty(menuPersistentUtil)} />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </React.Fragment>
      )}
      {isAuthorizedBotReferral(botId) && (
        <React.Fragment>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={8} computer={8} widescreen={8}>
              <h1>Usage du referral</h1>
              <Segment style={style}>
                <CustomPieChart2 chartId='menu2' name='referral' data={referral} noDataDisplay={isEmpty(referral)} />
              </Segment>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8} widescreen={8}>
              <h1>Utilisation du referral</h1>
              <Segment style={style}>
                <MenuUtilChart2 period='' data={referralUtil} noDataDisplay={isEmpty(referralUtil)} />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </React.Fragment>
      )}
    </Grid>
  </div>
)

export default Behaviours
