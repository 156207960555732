import React from 'react'
import PropTypes from 'prop-types'

const RSSOutlinedIcon = ({ width, height, fill }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 56 56'>
      <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Push-Copy-2' transform='translate(-1986.000000, -620.000000)' fill={fill} fillRule='nonzero'>
          <g id='rss' transform='translate(1986.175781, 620.175781)'>
            <path
              d='M27.4753642,54.9507284 C20.1365542,54.9507284 13.2366893,52.092764 8.04732693,46.9034015 C2.85796442,41.7140391 0,34.8141741 0,27.4753642 C0,20.1365542 2.85796442,13.2366893 8.04732693,8.04732693 C13.2366893,2.85796442 20.1365542,0 27.4753642,0 C34.8141741,0 41.7140391,2.85796442 46.9034015,8.04732693 C52.092764,13.2366893 54.9507284,20.1365542 54.9507284,27.4753642 C54.9507284,34.8141741 52.092764,41.7140391 46.9034015,46.9034015 C41.7140391,52.092764 34.8141741,54.9507284 27.4753642,54.9507284 Z M27.4753642,3.21976924 C20.996417,3.21976924 14.9052677,5.74276024 10.3242236,10.3242236 C5.74276024,14.9052677 3.21976924,20.996417 3.21976924,27.4753642 C3.21976924,33.9543114 5.74276024,40.0454607 10.3242236,44.6265048 C14.9052677,49.2079681 20.996417,51.7309591 27.4753642,51.7309591 C33.9543114,51.7309591 40.0454607,49.2079681 44.6265048,44.6265048 C49.2079681,40.0454607 51.7309591,33.9543114 51.7309591,27.4753642 C51.7309591,20.996417 49.2079681,14.9052677 44.6265048,10.3242236 C40.0454607,5.74276024 33.9543114,3.21976924 27.4753642,3.21976924 Z'
              id='Shape'
            ></path>
            <path
              d='M16.5742653,42.7302787 C14.1908814,42.7302787 12.2518927,40.79129 12.2518927,38.4079062 C12.2518927,36.0245223 14.1908814,34.0859529 16.5742653,34.0859529 C18.9576492,34.0859529 20.8966378,36.0245223 20.8966378,38.4079062 C20.8966378,40.79129 18.9576492,42.7302787 16.5742653,42.7302787 Z M16.5742653,37.3053029 C15.9663661,37.3053029 15.471662,37.8000071 15.471662,38.4079062 C15.471662,39.0158054 15.9663661,39.5105095 16.5742653,39.5105095 C17.1821644,39.5105095 17.6768685,39.0158054 17.6768685,38.4079062 C17.6768685,37.8000071 17.1821644,37.3053029 16.5742653,37.3053029 Z'
              id='Shape'
            ></path>
            <path
              d='M26.7819399,40.0177908 C25.8931494,40.0177908 25.1720553,39.2971159 25.1720553,38.4079062 C25.1720553,33.6671313 21.31504,29.8101161 16.5742653,29.8101161 C15.6850555,29.8101161 14.9643807,29.089022 14.9643807,28.2002315 C14.9643807,27.3110218 15.6850555,26.5903469 16.5742653,26.5903469 C23.0905248,26.5903469 28.3918245,31.8916467 28.3918245,38.4079062 C28.3918245,39.2971159 27.6711497,40.0177908 26.7819399,40.0177908 Z'
              id='Shape'
            ></path>
            <path
              d='M33.9828196,40.0177908 C33.0936099,40.0177908 32.372935,39.2971159 32.372935,38.4079062 C32.372935,29.6965018 25.2856695,22.6092364 16.5742653,22.6092364 C15.6850555,22.6092364 14.9643807,21.8885614 14.9643807,20.9993517 C14.9643807,20.1101421 15.6850555,19.3894671 16.5742653,19.3894671 C27.0611543,19.3894671 35.5927042,27.9210172 35.5927042,38.4079062 C35.5927042,39.2971159 34.8720294,40.0177908 33.9828196,40.0177908 Z'
              id='Shape'
            ></path>
            <path
              d='M41.097755,40.0177908 C40.2089646,40.0177908 39.4878704,39.2971159 39.4878704,38.4079062 C39.4878704,25.7732466 29.2089249,15.494301 16.5742653,15.494301 C15.6850555,15.494301 14.9643807,14.7736261 14.9643807,13.8844164 C14.9643807,12.9952066 15.6850555,12.2745318 16.5742653,12.2745318 C23.5546244,12.2745318 30.1174197,14.992889 35.0535607,19.9286108 C39.9892824,24.8647518 42.7076397,31.4275471 42.7076397,38.4079062 C42.7076397,39.2971159 41.9869648,40.0177908 41.097755,40.0177908 Z'
              id='Shape'
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

RSSOutlinedIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string
}

RSSOutlinedIcon.defaultProps = {
  width: '1.5em',
  height: '1.5em',
  fill: 'currentColor'
}

export default RSSOutlinedIcon
