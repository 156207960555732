import { call, put, takeLatest, select, takeEvery } from 'redux-saga/effects'
import * as R from 'ramda'
import { Maybe, maybe } from '@sbizeul/fp-flow'

import Api from 'services/tolkApi'
import botManagementApi from 'services/botManagementApi'
import { Id } from 'types'
import bot from 'modules/bot'
import keyword from 'modules/keyword'

import * as questionActions from './actions'
import {
  ArchiveQuestionRequest,
  CreateRequest,
  CreateSection,
  DeleteSectionRequest,
  FetchOneRequest,
  LabeledQuestions,
  MoveSectionRequest,
  Question,
  QuestionState,
  SaveOneRequest,
  SaveSectionRequest,
  Section,
  MergeQuestionRequest,
  FetchQuestionReferencesRequest
} from './types'
import { PinRequest } from './components/PinQuestion/types'

const questionsEndpoint = (botId: Maybe<Id>) =>
  `/bot/${maybe.get(botId)}/qa/questions`

export function* fetchAll() {
  const botId = yield select(bot.selectors.getSelectedId)
  try {
    const { data } = yield call(Api.get, questionsEndpoint(botId))
    yield put(questionActions.fetchAllSuccess(R.map(fromApiToQuestion, data)))
    yield put(questionActions.sortByCreationDesc())
  } catch (error) {
    yield put(questionActions.fetchAllFailure(error.response.data))
  }
}

export function* fetchLabeledQuestions() {
  const botId = yield select(bot.selectors.getSelectedId)
  const labeledQuestionsEndpoint = `/bot/${maybe.get(
    botId
  )}/qa/labeled-questions`
  try {
    const { data } = yield call(Api.get, labeledQuestionsEndpoint)
    yield put(
      questionActions.fetchLabeledQuestionsSuccess(
        fromApiToLabeledQuestions(data)
      )
    )
  } catch (error) {
    yield put(questionActions.fetchLabeledQuestionsFailure(error.response.data))
  }
}

export function* fetchQuestionReferences(
  action: FetchQuestionReferencesRequest
) {
  const questionId = action.payload
  const botId = yield select(bot.selectors.getSelectedId)
  const questionReferencesEndpoint = `/bots/${maybe.get(
    botId
  )}/questions/${questionId}/references`
  try {
    const { data } = yield call(Api.get, questionReferencesEndpoint)
    yield put(questionActions.fetchQuestionReferencesSuccess(data))
  } catch (error) {
    yield put(
      questionActions.fetchQuestionReferencesFailure(error.response.data)
    )
  }
}

export function* fetchOne(action: FetchOneRequest) {
  const botId = yield select(bot.selectors.getSelectedId)
  try {
    const { data } = yield call(
      Api.get,
      `${questionsEndpoint(botId)}/${action.payload}`
    )
    yield put(questionActions.fetchOneSuccess(fromApiToQuestion(data)))
  } catch (error) {
    yield put(
      questionActions.fetchOneFailure({
        error: error.response.data,
        id: action.payload
      })
    )
  }
}

export function* create(action: CreateRequest) {
  const botId = yield select(bot.selectors.getSelectedId)
  const label = action.payload.question
  try {
    const { data } = yield call(Api.post, questionsEndpoint(botId), {
      label,
      bot_id: maybe.get(botId)
    })
    yield put(questionActions.createSuccess(data))
    action.payload.shouldStayOnPage
      ? yield put(questionActions.fetchAll())
      : yield put(questionActions.selectQuestion(data))
  } catch (error) {
    yield put(questionActions.createFailure(error.response.data))
  }
}

export function* updateLabel(action: SaveOneRequest) {
  const newQuestion = R.set(
    R.lensProp('label'),
    action.payload.text,
    action.payload.question
  )
  yield call(updateQuestion, newQuestion)
}

export function* togglePinQuestionToTapFlow(action: PinRequest) {
  const newQuestion = R.set(
    R.lensProp('is_in_tapflow'),
    action.payload.is_in_tapflow,
    action.payload
  )
  yield call(updateQuestion, newQuestion)
}
export function* togglePinQuestionToHottopic(action: PinRequest) {
  const newQuestion = R.set(
    R.lensProp('is_in_hot_topics'),
    action.payload.is_in_hot_topics,
    action.payload
  )
  yield call(updateQuestion, newQuestion)
}

export function* moveSection(action: MoveSectionRequest) {
  const newQuestion = R.set(
    R.lensProp('sectionId'),
    action.payload.sectionId,
    action.payload.question
  )
  yield call(updateQuestion, newQuestion)
}

export function* archiveQuestion(action: ArchiveQuestionRequest) {
  yield call(toggleArchiveQuestion, action, QuestionState.ARCHIVED)
}

export function* unarchiveQuestion(action: ArchiveQuestionRequest) {
  yield call(toggleArchiveQuestion, action, QuestionState.ACTIVE)
}

export function* toggleArchiveQuestion(
  action: ArchiveQuestionRequest,
  state: QuestionState
) {
  const newQuestion = R.set(R.lensProp('state'), state, action.payload)
  yield call(updateQuestion, newQuestion)
}

export function* mergeQuestion(action: MergeQuestionRequest) {
  const { sourceQuestionId, targetQuestionId } = action.payload
  const botId = yield select(bot.selectors.getSelectedId)
  try {
    const { data } = yield call(
      Api.post,
      `${questionsEndpoint(botId)}/merge-training`,
      { sourceId: sourceQuestionId, targetId: targetQuestionId }
    )
    yield put(questionActions.mergeQuestionSuccess(data))
  } catch (error) {
    yield put(questionActions.mergeQuestionFailure(error.response.data))
  }
}

export function* updateQuestion(question: Question) {
  try {
      const restQuestion = {
         id: question.external_id,
         botId: question.bot_id,
         isInHotTopics: question.is_in_hot_topics ?? false,
         isInTapflow: question.is_in_tapflow ?? false,
         text: question.label,
         sectionId: question.sectionId,
         // @ts-ignore
         askFeedback: question.askFeedback,
         state: question.state,
         templateId: question.template_id ? question.template_id : undefined
      }

    yield call(botManagementApi.put, `/v1/bots/${question.bot_id}/questions/${question.external_id}`, restQuestion)

    yield put(questionActions.saveOneSuccess(question.id))
  } catch (error) {
    yield put(questionActions.saveOneFailure({ message: error.message }))
  }
}

const sectionEndpoint = (botId: Maybe<Id>) =>
  `/bot/${maybe.getOrElse(() => 'unknown', botId)}/qa/sections`

export function* createSection(action: CreateSection) {
  const optionalBotId = yield select(bot.selectors.getSelectedId)
  const botId = maybe.getOrElse(() => 'unknown', optionalBotId)
  const label = action.payload.label
  try {
    const { data } = yield call(Api.post, sectionEndpoint(optionalBotId), {
      label,
      bot_id: botId
    })
    yield put(questionActions.createSectionSuccess(data))
  } catch (error) {
    yield put(questionActions.createSectionFailure(error.response.data))
  }
}

export function* updateSection(action: SaveSectionRequest) {
  const botId = yield select(bot.selectors.getSelectedId)
  const section = action.payload
  try {
    const { data } = yield call(
      Api.put,
      `${sectionEndpoint(botId)}/${section.id}`,
      fromSectionToApi(section)
    )
    yield put(questionActions.saveSectionSuccess(data))
  } catch (error) {
    yield put(questionActions.saveSectionFailure(error.response.data))
  }
}

export function* deleteSection(action: DeleteSectionRequest) {
  const botId = yield select(bot.selectors.getSelectedId)
  const sectionId = action.payload
  try {
    const { data } = yield call(
      Api.delete,
      `${sectionEndpoint(botId)}/${sectionId}`
    )
    yield put(questionActions.deleteSectionSuccess(data))
  } catch (error) {
    yield put(questionActions.deleteSectionFailure(error.response.data))
  }
}

export type QuestionApi = Omit<Question, 'sectionId'> &
  Readonly<{
    section_id: Id
  }>

export type SectionApi = Section

export type LabeledQuestionsApi = Readonly<{
  questions: ReadonlyArray<QuestionApi>
  sections: ReadonlyArray<SectionApi>
}>

export const fromApiToQuestion: (questionApi: QuestionApi) => Question = (
  questionApi
) =>
  R.pipe(
    R.omit(['section_id']),
    R.assoc('sectionId', questionApi.section_id)
  )(questionApi)

export const fromQuestionToApi: (question: Question) => QuestionApi = (
  question
) =>
  R.pipe(
    R.omit(['sectionId']),
    R.assoc('section_id', question.sectionId)
  )(question)

export const fromApiToSection: (sectionApi: SectionApi) => Section = R.identity

export const fromSectionToApi: (section: SectionApi) => SectionApi = R.identity

export const fromApiToLabeledQuestions: (
  labeledQuestionsApi: LabeledQuestionsApi
) => LabeledQuestions = (labeledQuestionsApi) =>
  R.pipe(
    R.assoc(
      'questions',
      R.map(fromApiToQuestion, labeledQuestionsApi.questions)
    ),
    R.assoc('sections', R.map(fromApiToSection, labeledQuestionsApi.sections))
  )({})

export const fromLabeledQuestionsToApi: (
  labeledQuestions: LabeledQuestions
) => LabeledQuestionsApi = (labeledQuestions) =>
  R.pipe(
    R.assoc('questions', R.map(fromQuestionToApi, labeledQuestions.questions)),
    R.assoc('sections', R.map(fromSectionToApi, labeledQuestions.sections))
  )({})

export function* root() {
  yield takeLatest(questionActions.FETCH_ONE_REQUEST, fetchOne)
  yield takeLatest(questionActions.FETCH_ALL_REQUEST, fetchAll)
  yield takeLatest(
    questionActions.FETCH_ALL_SUCCESS,
    keyword.saga.fetchKeywords
  )
  yield takeLatest(questionActions.FETCH_LABELED_REQUEST, fetchLabeledQuestions)
  yield takeLatest(
    questionActions.FETCH_QUESTION_LINK_REQUEST,
    fetchQuestionReferences
  )
  yield takeEvery(questionActions.CREATE_REQUEST, create)
  yield takeLatest(questionActions.SAVE_ONE_REQUEST, updateLabel)
  yield takeLatest(
    questionActions.PIN_TO_HOTTOPIC_REQUEST,
    togglePinQuestionToHottopic
  )
  yield takeLatest(
    questionActions.UNPIN_TO_HOTTOPIC_REQUEST,
    togglePinQuestionToHottopic
  )
  yield takeLatest(
    questionActions.PIN_TO_TAPFLOW_REQUEST,
    togglePinQuestionToTapFlow
  )
  yield takeLatest(
    questionActions.UNPIN_TO_TAPFLOW_REQUEST,
    togglePinQuestionToTapFlow
  )
  yield takeLatest(questionActions.MOVE_SECTION_REQUEST, moveSection)
  yield takeLatest(questionActions.ARCHIVE_QUESTION_REQUEST, archiveQuestion)
  yield takeLatest(
    questionActions.UNARCHIVE_QUESTION_REQUEST,
    unarchiveQuestion
  )
  yield takeLatest(questionActions.MERGE_QUESTION_REQUEST, mergeQuestion)
  yield takeLatest(
    questionActions.MERGE_QUESTION_SUCCESS,
    bot.saga.fetchPublishStatus
  )
  yield takeLatest(
    questionActions.MERGE_QUESTION_SUCCESS,
    fetchLabeledQuestions
  )
  yield takeLatest(questionActions.CREATE_SUCCESS, bot.saga.fetchPublishStatus)
  yield takeLatest(questionActions.CREATE_SECTION_REQUEST, createSection)
  yield takeLatest(
    questionActions.CREATE_SECTION_SUCCESS,
    fetchLabeledQuestions
  )
  yield takeLatest(
    questionActions.SAVE_ONE_SUCCESS,
    bot.saga.fetchPublishStatus
  )
  yield takeLatest(questionActions.SAVE_ONE_SUCCESS, fetchLabeledQuestions)
  yield takeLatest(questionActions.SAVE_SECTION_REQUEST, updateSection)
  yield takeLatest(questionActions.SAVE_SECTION_SUCCESS, fetchLabeledQuestions)
  yield takeLatest(questionActions.DELETE_SECTION_REQUEST, deleteSection)
  yield takeLatest(
    questionActions.DELETE_SECTION_SUCCESS,
    fetchLabeledQuestions
  )
}
