import React from 'react'
import { v4 as uuid } from 'uuid'

import { bucketUrl } from 'helpers/effects'

import ColourInput from '../../WebchatUpdater/ColourInput'

class Customize extends React.Component {
  state = {
    selectedAvatar: ''
  }

  render() {
    const { updateTheme, updateAvatar, selectedTheme, selectedAvatar } =
      this.props

    const avatArr = [
      `${bucketUrl}/bots/icon-white.png`,
      `${bucketUrl}/bots/icon-black.png`
    ]

    const themes = [
      {
        firstColor: '#3a0071',
        secondColor: '#3a0071',
        thirdColor: '#3a0071'
      },
      {
        firstColor: '#008060',
        secondColor: '#008060',
        thirdColor: '#008060'
      }
    ]

    return (
      <div>
        <h2 className="bot-creation-form-title">
          How should your webchat look?
        </h2>
        <p className="bot-creation-form-text">
          Choose our beautiful themes to match your website. Same with your
          avatar.
        </p>
        <h2 className="bot-creation-form-subtitle">Main color</h2>
        <div className="customize-color">
          {themes.map((theme) => (
            <ColourInput
              key={uuid()}
              firstColor={theme.firstColor}
              secondColor={theme.secondColor}
              selected={theme.firstColor === selectedTheme.firstColor}
              changeColours={updateTheme}
            />
          ))}
        </div>
        <h2 className="bot-creation-form-subtitle">Avatar</h2>
        <div className="customize-icon-avatar">
          {avatArr.map((avatar) => (
            <label
              key={uuid()}
              className={'customize-icon'}
              style={{
                backgroundImage: `url(${avatar})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundColor: selectedTheme.firstColor
              }}
            >
              <input
                onClick={() => updateAvatar(avatar)}
                defaultChecked={avatar === selectedAvatar}
                type="radio"
                name="avatar-group"
                className={'customize-icon-input'}
              />
            </label>
          ))}
        </div>
      </div>
    )
  }
}

export default Customize
