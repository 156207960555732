import React from 'react'
import { FaRegEdit } from 'react-icons/fa'
import OutlinedUI from '../OutlinedUI'
import { Caption } from './styles'

const NewCampaign = ({ onClick, colored }) => (
  <OutlinedUI direction='row' onClick={onClick} colored={colored}>
    <div className='Navbar-menuItem'>
      <FaRegEdit />
    </div>
    <Caption>New campaign</Caption>
  </OutlinedUI>
)

export default NewCampaign
