import UnmatchedQuestionsContainer from './containers/UnmatchedQuestionsContainer'
import MatchedQuestionsContainer from './containers/MatchedQuestionsContainer'
import OutOfScopeContainer from './containers/OutOfScopeContainer'
import * as routes from './routes'
import * as saga from './saga'
import * as entity from './reducer.entity'

export default {
  components: {
    UnmatchedQuestionsContainer,
    MatchedQuestionsContainer,
    OutOfScopeContainer
  },
  reducers: {
    entity
  },
  routes,
  saga
}
