import React, { FormEvent } from 'react'
import * as E from 'fp-ts/lib/Either'
import * as O from 'fp-ts/lib/Option'

import { renderLoader, renderServerFailure } from 'helpers/react'

import {
  AppSettingsProps as RemoteAppSettingsProps,
  AppInformation,
  UpdatedAppInformation
} from '../../types'
import { SettingsForm } from './SettingsForm'

import './AppSettings.css'

export type AppSettingsProps = Omit<RemoteAppSettingsProps, 'appInfo' | 'fetchAppInfo'> & Readonly<{
  appInformation: O.Option<AppInformation>
}>

const AppSettings: React.FunctionComponent<AppSettingsProps> = (props) => {

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const formData: FormData = new FormData(e.target as HTMLFormElement)
    const formDataObject = Object.fromEntries(formData)
    const config = Object.keys(formDataObject).map(key => {
      return {
        key: key,
        value: formDataObject[key].toString()
      }
    })
    const updatedInfo: UpdatedAppInformation = {
      actionId: props.appId,
      config
    }
    props.saveAppInfo(updatedInfo)
  }

  const renderSavingIndicator = () => E.fold(
    renderServerFailure,
    isSaving => isSaving && renderLoader('Please wait...')
  )(props.saving)

  return (
    <div className='pageContainer'>
      <section className='appsettings'>
        {renderSavingIndicator()}
        <SettingsForm
          name={props.appName}
          appId={props.appId}
          fields={props.appFields}
          appInformation={props.appInformation}
          onSubmit={handleSubmit}
        />
      </section>
    </div>
  )
}

export {
  AppSettings
}
