import types from './types'

const initialState = {
  fetchingDelays: false,
  fetchingPreviousPeriod: false
}

const delaysReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.FETCH_DELAYS_REQUEST:
    return {
      ...state,
      fetchingDelays: true,
      data: null,
      count: null
    }
  case types.FETCH_DELAYS_NO_DATA:
    return {
      ...state,
      fetchingDelays: false
    }
  case types.FETCH_DELAYS_SUCCESS:
    return {
      ...state,
      fetchingDelays: false,
      data: action.payload.data,
      count: action.payload.count
    }

  case types.FETCH_DELAYS_PREVIOUS_PERIOD_REQUEST:
    return {
      ...state,
      fetchingPreviousPeriod: true,
      countPreviousPeriod: null
    }
  case types.FETCH_DELAYS_PREVIOUS_PERIOD_NO_DATA:
    return {
      ...state,
      fetchingPreviousPeriod: false
    }
  case types.FETCH_DELAYS_PREVIOUS_PERIOD_SUCCESS:
    return {
      ...state,
      fetchingPreviousPeriod: false,
      countPreviousPeriod: action.payload.count
    }
  default:
    return state
  }
}

export default delaysReducer
