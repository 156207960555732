import * as E from 'fp-ts/lib/Either'
import * as R from 'ramda'

import {
  SaveAppInformationFailure,
  SAVE_APP_INFORMATION_REQUEST,
  SAVE_APP_INFORMATION_SUCCESS,
  SAVE_APP_INFORMATION_FAILURE, AppSettingsUIState, AppsUIAction
} from './types'

export const initialState: AppSettingsUIState = {
  saving: E.right(false)
}

const saveRequest = R.assoc('saving', E.right(true))

const saveSuccess = R.assoc('saving', E.right(false))

const saveFailure = (state: AppSettingsUIState, action: SaveAppInformationFailure) =>
  R.assoc('saving', E.left(action.payload), state)

export default function apps (state: AppSettingsUIState = initialState, action: AppsUIAction) {
  switch (action.type) {
    case SAVE_APP_INFORMATION_REQUEST:
      return saveRequest(state)
    case SAVE_APP_INFORMATION_SUCCESS:
      return saveSuccess(state)
    case SAVE_APP_INFORMATION_FAILURE:
      return saveFailure(state, action)
    default:
      return state
  }
}
