export const isCustom = answers => {
  return Array.isArray(answers)
}

export const isRandom = answers => {
  return !isCustom(answers)
}

const isNotEmpty = str => str.length > 0

export const isValidSimpleText = answer => {
  return answer.objectAttachment.text.every(isNotEmpty)
}

export const isValidButton = answer => {
  return (
    answer.objectAttachment.text.every(isNotEmpty) &&
    answer.objectAttachment.buttons.every(btn => btn.title.every(isNotEmpty) && btn.url.every(isNotEmpty))
  )
}

export const isValidQuickReply = answer => {
  return (
    answer.objectAttachment.text.every(isNotEmpty) &&
    answer.objectAttachment.buttons.every(btn => btn.title.every(isNotEmpty) && btn.payload.every(isNotEmpty))
  )
}

export const isValidAnswers = custom => {
  return custom.every(answer => {
    switch (answer.type) {
    case 'text':
      return isValidSimpleText(answer)
    case 'button':
      return isValidButton(answer)
    case 'quick_reply':
      return isValidQuickReply(answer)
    default:
      return false
    }
  })
}
