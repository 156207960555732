import axios from 'axios'

import * as browserUtils from '@tolk-genii/browser-utils'
import { accountManagementBaseUrl, botManagementBaseUrl, authManagementBaseUrl, cookieDomain } from 'helpers/effects'


const botManagementAxios = axios.create({
  baseURL: botManagementBaseUrl
})

const accountManagementAxios = axios.create({
  baseURL: accountManagementBaseUrl
})

const botManagement = browserUtils.authenticatedRequest(
  botManagementAxios,
  authManagementBaseUrl,
  browserUtils.removeCookie(document, cookieDomain),
  browserUtils.getCookie(document),
  browserUtils.setCookie(document, cookieDomain)
)

const accountManagement = browserUtils.authenticatedRequest(
  accountManagementAxios,
  authManagementBaseUrl,
  browserUtils.removeCookie(document, cookieDomain),
  browserUtils.getCookie(document),
  browserUtils.setCookie(document, cookieDomain)
)

export {
  botManagement,
  accountManagement
}
