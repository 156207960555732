import { remoteData, RemoteData } from '@sbizeul/fp-flow'
import { RestSkillReferences, SkillId, RestFormReferences, FormId } from 'modules/skill/types'
import React, * as react from 'react'
import { Dimmer, Loader } from 'semantic-ui-react'
import { ServerFailure } from 'types'

import { v4 as uuid } from 'uuid'


type Properties = {
  contentType: "skill" | "form",
  skillId: SkillId,
  skillReferences: RemoteData<ServerFailure, RestSkillReferences | RestFormReferences>
  fetchSkillReferences: (id: SkillId | FormId) => unknown}

const Component: react.FC<Properties> = ({contentType, skillReferences, fetchSkillReferences, skillId}: Properties) => {
  react.useEffect(
    () => {
      fetchSkillReferences(skillId)
    },
    []
  )

  return(
    <div className="skill-modal-content">
        {remoteData.fold<RestSkillReferences, JSX.Element, ServerFailure>(
        {
          Success: (skillReferences) => {
            if (skillReferences.questions.length > 0 || skillReferences.skills.length > 0) {
              return <>
                <p>You should remove the {contentType === 'skill' ? 'scenario' : 'form'} in the content linked below
                  :
                </p>
                <ul>
                  {skillReferences.questions.map((question) => {
                    return (
                      <li className='skill-modal-element__li' key={uuid()}>
                        <p className='skill-modal-element__li-title'>Name: {question.name}</p>
                        <p>Type of content: {question.type}</p>
                      </li>
                    )
                  })}
                  {skillReferences.skills.map((skill) => {
                    return (
                      <li className='skill-modal-element__li' key={uuid()}>
                        <p className='skill-modal-element__li-title'>Name: {skill.name}</p>
                        <p>Type of content: {skill.type}</p>
                      </li>
                    )
                  })}
                </ul>
              </>
            }

            return <>Are you sure to delete this {contentType === 'skill' ? 'scenario' : 'form'} ?</>
          },
          Loading: () => <Dimmer active inverted>
            <Loader inverted />
          </Dimmer>,
          Failure: () => <>An error occured, please retry.</>,
          NotAsked: () => <></>
        }
      )(skillReferences)}

    </div>
  )
}

export { Component }
