import { connect } from 'react-redux'

import * as actions from '../../actions'
import { ChannelSettingsState } from '../../types'
import ChannelSettings from '../../components/ChannelSettings'

const mapStateToProps = (state: ChannelSettingsState) => ({
  channelInfo: state.entities.channels.channelInfo,
  saving: state.ui.channels.saving
})

const mapDispatchToProps = {
  fetchChannelInfo: actions.fetchChannelInformationRequest,
  saveChannelInfo: actions.saveChannelInformationRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(ChannelSettings)
