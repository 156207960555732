export const PUBLISH_REQUEST = 'train/PUBLISH_REQUEST'
export const PUBLISH_SUCCESS = 'train/PUBLISH_SUCCESS'
export const PUBLISH_FAILURE = 'train/PUBLISH_FAILURE'

export const publish = () => ({
  type: PUBLISH_REQUEST
})

export const publishSuccess = () => ({
  type: PUBLISH_SUCCESS
})

export const publishFailure = error => ({
  type: PUBLISH_FAILURE,
  payload: error
})
