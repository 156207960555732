import React, { useState } from 'react'
import { Either, either } from '@sbizeul/fp-flow'
import * as R from 'ramda'

import { ServerFailure } from 'types'
import { renderDotLoader, renderServerFailure, useEffectOnce } from 'helpers/react'

import './style.css'

export type Props = Readonly<{
  isCreatingSection: Either<ServerFailure, boolean>

  onSectionCreated: (label: string) => unknown
  onEscapeKeyPressed: () => unknown
}>

export type KeyEvent = React.KeyboardEvent<HTMLInputElement>

type OnKeyPressed = (keyEvent: KeyEvent) => unknown

const CreateSection: React.FunctionComponent<Props> = ({ isCreatingSection, onEscapeKeyPressed, onSectionCreated }) => {
  const [value, setValue] = useState('')

  const textInputRef = React.createRef<HTMLInputElement>()

  const handleChange: (event: React.ChangeEvent<HTMLInputElement>) => unknown =
    event => setValue(event.target.value)

  const onEnterKeyPressed = () => onSectionCreated(value)

  const isKeyPressed: (expectedKey: string) => (onKeyPressed?: OnKeyPressed) => (keyEvent: KeyEvent) => boolean =
    expectedKey => onKeyPressed => keyEvent => R.not(R.isNil(onKeyPressed)) && R.equals(keyEvent.key, expectedKey)

  const isEnterKeyPressed = isKeyPressed('Enter')(onEnterKeyPressed)

  const isEscapePressed = isKeyPressed('Escape')(onEscapeKeyPressed)

  const handleKeyPressed: (keyboardEvent: KeyEvent) => unknown =
    keyboardEvent => {
      keyboardEvent.persist()
      R.cond<KeyEvent, unknown>([
        [isEnterKeyPressed, onEnterKeyPressed],
        [isEscapePressed, onEscapeKeyPressed]
      ])(keyboardEvent)
    }

  const renderInput = () => (
    <input
      className='CreateSection--input'
      placeholder='New section'
      value={value}
      ref={textInputRef}
      onChange={handleChange}
      onKeyDown={handleKeyPressed}
    />
  )

  useEffectOnce(() => {
    textInputRef.current && textInputRef.current.focus()
  })

  return (
    <div className='CreateSection'>
      {either.fold(
        error => (<>
          {renderServerFailure(error)}
          {renderInput()}
        </>),
        isCreating => isCreating ? renderDotLoader('section creation') : renderInput(),
        isCreatingSection
      )}
    </div>
  )
}

export {
  CreateSection
}
