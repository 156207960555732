import fetchNewUsers from './fetchNewUsers'
import fetchUsers from './fetchUsers'
import fetchUsersAverageByDay from './fetchUsersAverageByDay'
import fetchUsersByHour from './fetchUsersByHour'
import fetchUsersPreviousPeriod from './fetchUsersPreviousPeriod'

export default {
  fetchNewUsers,
  fetchUsers,
  fetchUsersAverageByDay,
  fetchUsersByHour,
  fetchUsersPreviousPeriod
}
