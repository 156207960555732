import types from './types'

const fetchNothandledRequest = () => ({
  type: types.FETCH_NOTHANDLED_REQUEST
})

const fetchNothandledSuccess = payload => ({
  type: types.FETCH_NOTHANDLED_SUCCESS,
  payload
})

const fetchNothandledFailure = errors => ({
  type: types.FETCH_NOTHANDLED_NO_DATA,
  errors
})

export default {
  fetchNothandledRequest,
  fetchNothandledSuccess,
  fetchNothandledFailure
}
