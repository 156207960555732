import types from './types'

const fetchUsersRequest = () => ({
  type: types.FETCH_USERS_REQUEST
})
const fetchUsersSuccess = payload => ({
  type: types.FETCH_USERS_SUCCESS,
  payload
})
const fetchUsersFailure = errors => ({
  type: types.FETCH_USERS_NO_DATA,
  errors
})

const fetchUsersPreviousPeriodRequest = () => ({
  type: types.FETCH_USERS_PREVIOUS_PERIOD_REQUEST
})
const fetchUsersPreviousPeriodSuccess = payload => ({
  type: types.FETCH_USERS_PREVIOUS_PERIOD_SUCCESS,
  payload
})
const fetchUsersPreviousPeriodFailure = errors => ({
  type: types.FETCH_USERS_PREVIOUS_PERIOD_NO_DATA,
  errors
})

const fetchNewUsersRequest = () => ({
  type: types.FETCH_NEW_USERS_REQUEST
})
const fetchNewUsersSuccess = payload => ({
  type: types.FETCH_NEW_USERS_SUCCESS,
  payload
})
const fetchNewUsersFailure = errors => ({
  type: types.FETCH_NEW_USERS_NO_DATA,
  errors
})

const fetchUsersByHourRequest = () => ({
  type: types.FETCH_USERS_BY_HOUR_REQUEST
})
const fetchUsersByHourSuccess = payload => ({
  type: types.FETCH_USERS_BY_HOUR_SUCCESS,
  payload
})
const fetchUsersByHourFailure = errors => ({
  type: types.FETCH_USERS_BY_HOUR_NO_DATA,
  errors
})

const fetchUsersAverageByDayRequest = () => ({
  type: types.FETCH_USERS_AVERAGE_BY_DAY_REQUEST
})
const fetchUsersAverageByDaySuccess = payload => ({
  type: types.FETCH_USERS_AVERAGE_BY_DAY_SUCCESS,
  payload
})
const fetchUsersAverageByDayFailure = payload => ({
  type: types.FETCH_USERS_AVERAGE_BY_DAY_NO_DATA,
  payload
})

export default {
  fetchUsersRequest,
  fetchUsersSuccess,
  fetchUsersFailure,
  fetchUsersPreviousPeriodRequest,
  fetchUsersPreviousPeriodSuccess,
  fetchUsersPreviousPeriodFailure,
  fetchNewUsersRequest,
  fetchNewUsersSuccess,
  fetchNewUsersFailure,
  fetchUsersByHourRequest,
  fetchUsersByHourSuccess,
  fetchUsersByHourFailure,
  fetchUsersAverageByDayRequest,
  fetchUsersAverageByDaySuccess,
  fetchUsersAverageByDayFailure
}
