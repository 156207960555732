import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'

import './index.css'
import Tabs from './Tabs'
import Details from './Messages/Details'
import ConversationsChart from './Messages/Conversations-chart'
import MessagesChart from './Messages/Messages-chart'
import Week from './Messages/Week-chart'
import Day from './Messages/Day-chart'
import TrafficChart from './Messages/Traffic-chart'
import Users from './Users'

const Traffic = () => (
  <div>
    <Tabs />
    <Switch>
      <Route exact path='/analytics/traffic'>
        <div>
          <Details />
          <Grid>
            <ConversationsChart />
            <MessagesChart />
            <Week />
            <Day />
            <TrafficChart />
          </Grid>
        </div>
      </Route>
      <Route exact path='/analytics/traffic/users' component={Users} />
    </Switch>
  </div>
)

export default Traffic
