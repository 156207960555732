import * as React from 'react'
import { FaPlus } from 'react-icons/fa'
import { Loader } from 'semantic-ui-react'

import TextInput from 'components/TextInput/TextInput'

import './styles.css'

class AddSynonym extends React.Component {
  state = {
    value: ''
  }

  handleChange = value => this.setState({ value })

  handleEnterKeyPressed = () => {
    this.props.addSynonym({ value: this.state.value, language: this.props.language })
    this.setState({ value: '' })
  }

  render() {
    return (
      <div className='AddSynonym-TextInput'>
        <TextInput
          autoFocus={true}
          onChangeText={this.handleChange}
          onEnterKeyPressed={this.handleEnterKeyPressed}
          placeholder='Add a new synonym...'
          value={this.state.value}
          withBorder={true}
        />
      </div>
    )
  }
}

const AddIcon = () => <FaPlus className='AddSynonym-AddIcon' />

const LoaderIcon = () => <Loader active inline />

export default AddSynonym
export { AddIcon, LoaderIcon }
