import { createSelector } from 'reselect'
import { createMatchSelector } from 'connected-react-router'
import * as R from 'ramda'
import * as option from 'fp-ts/lib/Option'
import { maybe } from '@sbizeul/fp-flow'

import { AppState } from './types'
import * as routes from './routes'

export const getEntityState = (state: AppState) => state.entities.skill

export const getUiState = (state: AppState) => state.ui.skill

export const getUiSkill = createSelector(getUiState, state => state.defaultSkill)

export const skillRouteSelector = createMatchSelector(routes.SKILLS)

export const getIsPosting = createSelector(getUiState, state => state.isPosting)

export const getIsModified = createSelector(getUiState, state => state.isModified)

export const getLabel = createSelector(
  skillRouteSelector,
  skillRouter => R.view(R.lensPath(['params', 'name']), skillRouter) || 'unknown skill'
)

export const getAllSkills = createSelector(getUiState, state => state.allSkills)

export const getAllById = createSelector(getEntityState, state => state.byId)

export const getSkill = createSelector(getUiState, state => state.skill)

export const getFormSkill = createSelector(getUiState, state => state.formSkill)

export const getFormSkills = createSelector(getUiState, state => state.formSkills)

export const getFormResponse = createSelector(getUiState, state => state.formResponse)

export const getFormResponseError = createSelector(getUiState, state => state.formResponseError)

export const isDownloadingFormData = createSelector(getUiState, state => state.isDownloadingFormData)

export const getImagesUrl = createSelector(getUiState, state => state.imagesById)

export const formSkillsRouteSelector = createMatchSelector(routes.SKILL_FORMS)

export const skillsRouteSelector = createMatchSelector(routes.SKILL_BUILDER)

export const getAdvancedSkills = createSelector(getUiState, state => state.advancedSkills)

export const getSkillId = createSelector(skillsRouteSelector, routeParams =>
  option.fromNullable<string>(R.view(R.lensPath(['params', 'skillBuilderId']), routeParams))
)

export const getFormSkillId = createSelector(formSkillsRouteSelector, routeParams =>
  maybe.fromNullable<string>(R.view(R.lensPath(['params', 'formId']), routeParams))
)

export const getSkillReferences = createSelector(getUiState, state => state.skillReferences)

export const getFormReferences = createSelector(getUiState, state => state.formReferences)