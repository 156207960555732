import { call, put, takeLatest, select } from 'redux-saga/effects'

import { maybe } from '@sbizeul/fp-flow'

import Api from 'services/tolkApiLegacy'

import bot from '../bot'
import * as synonymActions from './actions'

export function* fetchSynonyms(action) {
  const selectedBotId = yield select(bot.selectors.getSelectedId)
  const botId = maybe.getOrElse(() => 'unknownBot', selectedBotId)
  const entityId = action.payload
  try {
    const { data } = yield call(Api.get, `/bot/${botId}/entities/${entityId}/synonyms`)
    yield put(synonymActions.fetchSynonymsSuccess(entityId, data))
  } catch (error) {
    yield put(synonymActions.fetchSynonymsFailure(error, entityId))
  }
}

export function* addSynonym(action) {
  const selectedBotId = yield select(bot.selectors.getSelectedId)
  const botId = maybe.getOrElse(() => 'unknownBot', selectedBotId)
  const entityId = action.payload.entityId
  try {
    const url = `/bot/${botId}/entities/${entityId}/synonyms`
    const { data } = yield call(Api.post, url, action.payload.synonym)
    yield put(synonymActions.addSynonymSuccess(data))
    yield put(synonymActions.fetchSynonymsRequest(entityId))
  } catch (error) {
    yield put(synonymActions.addSynonymFailure(error))
  }
}

export function* deleteSynonym(action) {
  const selectedBotID = yield select(bot.selectors.getSelectedId)
  const botID = maybe.getOrElse(() => 'unknownBot', selectedBotID)
  const entityID = action.payload.entityId
  const synonymID = action.payload.synonymId
  try {
    yield call(Api.delete, `/bot/${botID}/entities/${entityID}/synonyms/${synonymID}`)
    yield put(synonymActions.deleteSynonymSuccess())
    yield put(synonymActions.fetchSynonymsRequest(entityID))
  } catch (error) {
    yield put(synonymActions.deleteSynonymFailure(error))
  }
}

export function* updateSynonym(action) {
  const selectedBotId = yield select(bot.selectors.getSelectedId)
  const botId = maybe.getOrElse(() => 'unknownBot', selectedBotId)
  const entityId = action.payload.entityId
  const synonymId = action.payload.synonym.id
  try {
    const url = `/bot/${botId}/entities/${entityId}/synonyms/${synonymId}`
    const { data } = yield call(Api.put, url, action.payload.synonym)
    yield put(synonymActions.updateSynonymSuccess(data))
    yield put(synonymActions.fetchSynonymsRequest(entityId))
  } catch (error) {
    yield put(synonymActions.updateSynonymFailure(error))
  }
}

export function* root() {
  yield takeLatest(synonymActions.FETCH_SYNONYMS_REQUEST, fetchSynonyms)
  yield takeLatest(synonymActions.ADD_SYNONYM_REQUEST, addSynonym)
  yield takeLatest(synonymActions.DELETE_SYNONYM_REQUEST, deleteSynonym)
  yield takeLatest(synonymActions.UPDATE_SYNONYM_REQUEST, updateSynonym)
}
