import PropTypes from 'prop-types'
import React from 'react'
import { Table } from 'semantic-ui-react'
import EditAnswerInPlace from './EditAnswerInPlace'

const AnswersTable = ({ answers, botId, intentSlug, language, intentName, description }) => (
  <Table verticalAlign='middle' celled fixed>
    <Table.Body>
      {answers.map((answer, id) => (
        <Table.Row key={`form-EditAnswerInPlace-${id}`} verticalAlign='middle'>
          <EditAnswerInPlace
            form={`form-EditAnswerInPlace-${id}`}
            answer={answer}
            initialValues={{
              botId,
              intentSlug,
              intentName,
              description,
              answer,
              id,
              language
            }}
          />
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
)

AnswersTable.propTypes = {
  botId: PropTypes.string.isRequired,
  intentSlug: PropTypes.string.isRequired,
  answers: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  intentName: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
}

export default AnswersTable
