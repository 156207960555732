import types from './types'

const initialState = {
  fetching: false,
  fetchingUtil: false
}

const menuPersistentReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.FETCH_MENUPERSISTENT_REQUEST:
    return {
      ...state,
      fetching: true,
      data: null
    }
  case types.FETCH_MENUPERSISTENT_NO_DATA:
    return {
      ...state,
      fetching: false
    }
  case types.FETCH_MENUPERSISTENT_SUCCESS:
    return {
      ...state,
      fetching: false,
      data: action.payload.data
    }

  case types.FETCH_MENUPERSISTENTUTIL_REQUEST:
    return {
      ...state,
      fetchingUtil: true,
      util: null
    }
  case types.FETCH_MENUPERSISTENTUTIL_NO_DATA:
    return {
      ...state,
      fetchingUtil: false
    }
  case types.FETCH_MENUPERSISTENTUTIL_SUCCESS:
    return {
      ...state,
      fetchingUtil: false,
      util: action.payload.data
    }

  default:
    return state
  }
}

export default menuPersistentReducer
