import axios from 'axios'
import { SubmissionError } from 'redux-form'
import Buffer from 'buffer'

import actions from '../actions'
import channel from '../../../modules/channel'
import bot from '../../../modules/bot'
import { legacyApiUrl } from 'helpers/effects'

const fromLocalStorage = bots => {
  const botId = localStorage.getItem('botId')
  return bots.find(b => b.bot_id === botId)
}

const login = data => dispatch => {
  const token = new Buffer.Buffer(`${data.username}:${data.password}`).toString('base64')
  const tcfAuthHeader = `Basic ${token}`

  dispatch(actions.loginRequest())
  const url = `${legacyApiUrl}/user/me`
  return axios({
    url,
    method: 'get',
    contentType: 'application/json',
    responseType: 'json',
    headers: { authorization: tcfAuthHeader }
  })
    .then(res => {
      localStorage.setItem('tcfAuthHeader', tcfAuthHeader)
      axios.defaults.headers.common.authorization = tcfAuthHeader
      const selectedBot = fromLocalStorage(res.data.bots) ? fromLocalStorage(res.data.bots) : res.data.bots[0]
      dispatch(channel.actions.fetchChannels(res.data._id.$oid))
      dispatch(bot.actions.select(selectedBot.bot_id))
      actions.setActiveChannel({
        id: selectedBot.channels[0]._id,
        name: selectedBot.channels[0].slug
      })
      dispatch(actions.loginSuccess(res.data))
    })
    .catch(err => {
      if (err.message) {
        dispatch(actions.loginFailure())
        dispatch(actions.removeLoading())
        throw new SubmissionError({ _error: err.message })
      } else if (err.response) {
        dispatch(actions.loginFailure())
        throw new SubmissionError({ _error: err.response.data.errors[0] })
      } else if (err.request) {
        // TODO: bad request
      }
    })
}

export default login
