import { takeLatest, select, call, put } from 'redux-saga/effects'
import * as O from 'fp-ts/lib/Option'

import botManagementApi from 'services/botManagementApi'
import bot from 'modules/bot'
import {
  SaveAppInformationRequest,
  AppInformation,
  SAVE_APP_INFORMATION_REQUEST,
  FETCH_APP_INFORMATION_REQUEST,
  FetchAppInformationRequest
} from './types'
import {
  fetchAppInformationSuccess,
  fetchAppInformationFailure,
  saveAppInformationSuccess,
  saveAppInformationFailure
} from './actions'

export function* fetch (action: FetchAppInformationRequest) {
  const botIdMaybe = yield select(bot.selectors.getSelectedId)

  try {
    const response: Readonly<{ data: AppInformation }> = yield call(botManagementApi.get, `/v1/bots/${botIdMaybe.value}/actions/${action.payload}/config`)
    if (response.data) {
      yield put(fetchAppInformationSuccess(O.some(response.data)))
    }
    yield put(fetchAppInformationFailure({
          message: 'no app configured'
    }))
  } catch (err) {
    const serverFailure = err.response.data
    yield put(fetchAppInformationFailure(serverFailure))
  }
}

export function* save (action: SaveAppInformationRequest) {
  try {
    const botIdMaybe = yield select(bot.selectors.getSelectedId)
    const response = yield call(botManagementApi.put, `/v1/bots/${botIdMaybe.value}/actions/${action.payload.actionId}/config`, { config: action.payload.config })
    yield put(saveAppInformationSuccess(response.data))
  } catch (err) {
    const serverFailure = err.response.data
    yield put(saveAppInformationFailure(serverFailure))
  }
}

export function* root () {
  yield takeLatest(FETCH_APP_INFORMATION_REQUEST, fetch)
  yield takeLatest(SAVE_APP_INFORMATION_REQUEST, save)
}
