import { maybe } from '@sbizeul/fp-flow'

import actions from '../actions'
import isResValid from '../../../_shared/isResValid'
import formatFilters from '../../../_shared/formatFilters'
import {
  groupMessagesByDay,
  groupMessagesByHour,
  groupMessagesByHourByDay
} from '../../../../views/pages/Analytics/utils'
import bot from '../../../../modules/bot'
import api from 'services/tolkApiLegacy'

const fetchMessagesCountByHour = rawFilters => (dispatch, getState) => {
  const { channelId, start, end } = formatFilters(rawFilters)
  const botId = bot.selectors.getSelectedId(getState())

  dispatch(actions.fetchMessagesCountByHourRequest())
  return api.get(`/analytics/${maybe.get(botId)}/${channelId}/messages`,
  { params: {
    start,
    end,
    period: 'hours'
  }}).then(response => {
      if (isResValid(response)) {
        const messagesCountByDay = groupMessagesByDay(response.data.result.data)
        const messagesCountByHour = groupMessagesByHour(response.data.result.data)
        const messagesCountByHourByDay = groupMessagesByHourByDay(response.data.result.data)
        dispatch(
          actions.fetchMessagesCountByHourSuccess({
            messagesCountByHour,
            messagesCountByDay,
            messagesCountByHourByDay
          })
        )
      } else {
        dispatch(actions.fetchMessagesCountByHourFailure())
      }
    })
    .catch(err => {
      dispatch(actions.fetchMessagesCountByHourFailure(err))
    })
}

export default fetchMessagesCountByHour
