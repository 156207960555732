const FETCH_USERS_REQUEST = 'tcfdash/analytics/FETCH_USERS_REQUEST'
const FETCH_USERS_SUCCESS = 'tcfdash/analytics/FETCH_USERS_SUCCESS'
const FETCH_USERS_NO_DATA = 'tcfdash/analytics/FETCH_USERS_NO_DATA'

const FETCH_NEW_USERS_REQUEST = 'tcfdash/analytics/FETCH_NEW_USERS_REQUEST'
const FETCH_NEW_USERS_SUCCESS = 'tcfdash/analytics/FETCH_NEW_USERS_SUCCESS'
const FETCH_NEW_USERS_NO_DATA = 'tcfdash/analytics/FETCH_NEW_USERS_NO_DATA'

const FETCH_USERS_PREVIOUS_PERIOD_REQUEST = 'tcfdash/analytics/FETCH_USERS_PREVIOUS_PERIOD_REQUEST'
const FETCH_USERS_PREVIOUS_PERIOD_SUCCESS = 'tcfdash/analytics/FETCH_USERS_PREVIOUS_PERIOD_SUCCESS'
const FETCH_USERS_PREVIOUS_PERIOD_NO_DATA = 'tcfdash/analytics/FETCH_USERS_PREVIOUS_PERIOD_NO_DATA'

const FETCH_USERS_BY_HOUR_REQUEST = 'tcfdash/analytics/FETCH_USERS_BY_HOUR_REQUEST'
const FETCH_USERS_BY_HOUR_SUCCESS = 'tcfdash/analytics/FETCH_USERS_BY_HOUR_SUCCESS'
const FETCH_USERS_BY_HOUR_NO_DATA = 'tcfdash/analytics/FETCH_USERS_BY_HOUR_NO_DATA'

const FETCH_USERS_AVERAGE_BY_DAY_REQUEST = 'tcfdash/analytics/FETCH_USERS_AVERAGE_BY_DAY_REQUEST'
const FETCH_USERS_AVERAGE_BY_DAY_SUCCESS = 'tcfdash/analytics/FETCH_USERS_AVERAGE_BY_DAY_SUCCESS'
const FETCH_USERS_AVERAGE_BY_DAY_NO_DATA = 'tcfdash/analytics/FETCH_USERS_AVERAGE_BY_DAY_NO_DATA'

const MERGE_NEW_AND_ALL_USERS = 'tcfdash/analytics/MERGE_NEW_AND_ALL_USERS' // TODO used?

export default {
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_NO_DATA,
  FETCH_NEW_USERS_REQUEST,
  FETCH_NEW_USERS_SUCCESS,
  FETCH_NEW_USERS_NO_DATA,
  FETCH_USERS_PREVIOUS_PERIOD_REQUEST,
  FETCH_USERS_PREVIOUS_PERIOD_SUCCESS,
  FETCH_USERS_PREVIOUS_PERIOD_NO_DATA,
  FETCH_USERS_BY_HOUR_REQUEST,
  FETCH_USERS_BY_HOUR_SUCCESS,
  FETCH_USERS_BY_HOUR_NO_DATA,
  FETCH_USERS_AVERAGE_BY_DAY_REQUEST,
  FETCH_USERS_AVERAGE_BY_DAY_SUCCESS,
  FETCH_USERS_AVERAGE_BY_DAY_NO_DATA,

  MERGE_NEW_AND_ALL_USERS
}
