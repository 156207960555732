import styled from 'styled-components'

const ElementStyled = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
`

export default ElementStyled
