import * as React from 'react'
import { maybe, remoteData } from '@sbizeul/fp-flow'

import { DotLoader } from '../../../../components/Loading'
import PublishButton from './PublishButton'

import * as uiLibrary from '@tolk-ai/ui-library-react'
import '@tolk-ai/ui-library-react/dist/ui-library-react.esm.css'

import './style.css'

function RemotePublishButton({ botId, published, fetchPublishedStatus, publish }) {
  const renderPublishButton = publishStatus => <PublishButton publishStatus={publishStatus} publish={publishBot} />

  const renderFetchingError = () => (
    <div className='ErrorPublish'>
      <p className='ErrorPublish-text'>Your bot could not be published</p>
        <uiLibrary.Button isRounded isOutlined onClick={fetchPublishedStatus} className={"ErrorPublish-button"}>
          Retry
        </uiLibrary.Button>
    </div>
  )

  const renderLoading = () => <DotLoader description='Checking status' className="remote-button__dotloader" />

  const publishBot = () => {
    maybe.fold(
      () => undefined,
      id => publish(id),
      botId
    )
  }

  return remoteData.fold(
    {
      Success: renderPublishButton,
      Loading: renderLoading,
      NotAsked: renderLoading,
      Failure: renderFetchingError
    },
    published
  )
}

export default RemotePublishButton
