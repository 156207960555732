import DashboardContainer from './containers/DashboardContainer'
import WebchatOverviewContainer from './containers/WebchatOverviewContainer'
import * as actions from './actions'
import * as entity from './reducer.entity'
import * as routes from './routes'
import * as saga from './saga'
import * as selectors from './selectors'

export default {
  actions,
  components: {
    DashboardContainer,
    WebchatOverviewContainer
  },
  reducers: {
    entity
  },
  routes,
  saga,
  selectors
}
