import React from 'react'

import TextArea from 'components/TextArea'

import { placeholderLanguage } from '../../../models'

const Interact = ({ updateGreetings, greetings, botName, language }) => (
  <div>
    <h1 className='bot-creation-form-title'>How should you greet users?</h1>
    <p className='bot-creation-form-text'>
      And now, tell us how the bot will introduce itself to your users. Be concise but precise on his abilities and the
      use-cases it can help them with
    </p>
    <h2 className='bot-creation-form-subtitle'>Onboarding</h2>
    <TextArea
      value={greetings}
      className='bot-creation-form-textarea'
      type='rounded'
      placeholder={placeholderLanguage(language, botName)}
      onChange={event => updateGreetings(event.target.value)}
    />
    <p className='bot-creation-form-text'>Remember that you will be able to edit it later in the Skill Builder</p>
  </div>
)

export default Interact
