import React from 'react'
import { Grid, Segment } from 'semantic-ui-react'
import '../index.css'
import text from '../../../../../config/translations/en.json'
import BarGraphIntents from '../../components/BarCharts/BarIntents'
import BarGraphEntities from '../../components/BarCharts/BarEntities'
import BarGraphButtons from '../../components/BarCharts/BarButtons'
import CustomTable from '../../components/Table/CustomTable'
import WordCloud from '../../components/WordCloud'
import MoreInfoPopup from '../../../../components/MoreInfoPopup'

const style = {
  borderRadius: '3px',
  border: 'none',
  boxShadow: '0 2px 4px rgba(50, 50, 93,.1)',
  padding: '5px'
}

const Trends = ({
  wordsCount,
  noWordsCount,
  charCount,
  entitiesData,
  intentsData,
  answersData,
  payloadsData,
  wordsusersData
}) => (
  <div>
    <Grid>
      <Grid.Column mobile={16} tablet={8} computer={8} widescreen={8}>
        <h1>Most popular intents</h1>
        <Segment style={style}>{intentsData ? <BarGraphIntents entities={intentsData.slice(0, 15)} /> : ''}</Segment>
      </Grid.Column>
      <Grid.Column mobile={16} tablet={8} computer={8} widescreen={8}>
        <h1>Most popular entities</h1>
        <Segment style={style}>{entitiesData ? <BarGraphEntities entities={entitiesData.slice(0, 15)} /> : ''}</Segment>
      </Grid.Column>
    </Grid>
    <Grid>
      <Grid.Column mobile={16} tablet={8} computer={8} widescreen={8}>
        <h1>Less popular intents</h1>
        {intentsData ? (
          <CustomTable
            data={intentsData.slice(intentsData.length - 8, intentsData.length)}
            header={['name', 'nb_users', 'value']}
          />
        ) : (
          ''
        )}
      </Grid.Column>
      <Grid.Column mobile={16} tablet={8} computer={8} widescreen={8}>
        <h1>Less popular entities</h1>
        {entitiesData ? (
          <CustomTable
            data={entitiesData.slice(entitiesData.length - 8, entitiesData.length)}
            header={['name', 'value']}
          />
        ) : (
          ''
        )}
      </Grid.Column>
    </Grid>
    <Grid>
      <Grid.Column mobile={16} tablet={16} computer={16} widescreen={16}>
        <h1>Most popular buttons</h1>
        <Segment style={style}>{payloadsData ? <BarGraphButtons entities={payloadsData.slice(0, 10)} /> : ''}</Segment>
      </Grid.Column>
    </Grid>
    <Grid>
      <Grid.Column mobile={16} tablet={16} computer={16} widescreen={16}>
        <h1>Most popular bot answers</h1>
        {answersData ? <CustomTable data={answersData.slice(0, 20)} header={['name', 'value']} /> : ''}
      </Grid.Column>
    </Grid>
    <Grid>
      <Grid.Column mobile={16} tablet={16} computer={16} widescreen={16}>
        <h1>
          Words Cloud
          <MoreInfoPopup content={text.trends.cloud} />
        </h1>
        <Segment style={style}>{wordsusersData ? <WordCloud words={wordsusersData} /> : ''}</Segment>
      </Grid.Column>
    </Grid>
  </div>
)

Trends.defaultProps = {
  wordsCount: null,
  noWordsCount: null,
  charCount: null,
  entitiesData: null,
  intentsData: null,
  answersData: null,
  payloadsData: null,
  wordsusersData: null
}

export default Trends
