import * as React from 'react'
import { RemoteData, remoteData } from '@sbizeul/fp-flow'
import * as O from 'fp-ts/lib/Option'
import { Moment } from 'moment'

import { renderLoader, renderFailure } from 'helpers/react'
import DateRangeComponent from 'components/DateRangePicker'
import { AnalyticRates, ChangeDatesParams, ResolutionRate } from 'modules/analytics/types'
import { ServerFailure } from 'types'
import { rateToPieFormat } from 'modules/analytics/piechart'

import './styles.css'
import DashboardPieChart, { PieFormat } from '../DashboardPieChart/DashboardPieChart'

export type Props = Readonly<{
  startDate: Moment
  endDate: Moment
  healthRate: RemoteData<ServerFailure, AnalyticRates>

  fetchHealthRate: () => unknown
  onDatesChange: (newDate: ChangeDatesParams) => unknown
}>

const toPercent = (value: number) => Math.round(value * 100)

const resolutionRateToPieFormat = (resolutionRate: ResolutionRate): PieFormat => {
  const pieFormat = [
    {
      name: 'Understood',
      value: toPercent(resolutionRate.details.understoodRate),
      unit: '%',
      color: '#3289F6'
    },
    {
      name: 'Disambiguation',
      value: toPercent(resolutionRate.details.disambiguationRate),
      unit: '%',
      color: '#3289F6'
    },
    {
      name: 'Out of scope feedback',
      value: toPercent(resolutionRate.details.outOfScopeFeedbackRate),
      unit: '%',
      color: '#dce9fd'
    },
    {
      name: 'Not understood',
      value: toPercent(resolutionRate.details.notUnderstoodRate),
      unit: '%',
      color: '#dce9fd'
    }
  ].filter(detail => detail.value > 0)
  return pieFormat
}

class Dashboard extends React.Component<Props> {
  componentDidMount () {
    this.props.fetchHealthRate()
  }

  renderNoData () {
    return (
      <div className='tolk-pie-chart-no-data'>
        <span>N/A</span>
      </div>
    )
  }

  renderPieChart (params: {
    mainRate?: number,
    values: ReadonlyArray<{
      name: string,
      value: number,
      color?: string,
      unit?: string
    }>,
    withTooltip?: boolean
  }) {
    return (
      <div>
        {params.mainRate && <div className='Dashboard-card--Percent'>{params.mainRate} %</div>}
        <div className='Dashboard-card--chart'>
          <DashboardPieChart values={params.values} tooltip={params.withTooltip}></DashboardPieChart>
        </div>
      </div>
    )
  }

  renderSimpleRateCard (rate: number | ResolutionRate) {
    const recievedRate = typeof rate === 'object' ? rate.value : rate
    const printedRate = recievedRate > 1 ? 1 : recievedRate

    return (
      <div>
        <div className='Dashboard-mini-card--Percent'>{toPercent(printedRate)}%</div>
      </div>
    )
  }

  renderSimpleRateCardFromOption (numberOption: O.Option<number | ResolutionRate>, title: string) {
    return (
      <div className='Dashboard-mini-card'>
        <div className='Dashboard-mini-card--title' >{title}</div>
        {O.fold(
          this.renderNoData,
          this.renderSimpleRateCard
        )(numberOption)}
      </div>
    )
  }

  renderHealthRate = (rate: AnalyticRates) => (
    <div>
      <div className='Dashboard-mini-card-container'>
        {this.renderSimpleRateCardFromOption(rate.resolutionRate, 'Resolution rate')}
        {this.renderSimpleRateCardFromOption(rate.contentQualityRate, 'Content quality')}
        {this.renderSimpleRateCardFromOption(rate.automationRate, 'Automation rate')}
        {this.renderSimpleRateCardFromOption(rate.negativeFeedbackRate, 'Negative feedback')}
        {this.renderSimpleRateCardFromOption(rate.monitorHealthRate, 'Monitoring completion')}
      </div>

      <div className='Dashboard-layout-container'>
        <div className='Dashboard-card Dashboard-layout-item'>
          <div className='Dashboard-card--title'>Resolution type</div>
            {O.fold(
              this.renderNoData,
              (resolutionRate: ResolutionRate) => {
                return this.renderPieChart({
                  values: resolutionRateToPieFormat(resolutionRate),
                  withTooltip: true
                })
              }
            )(rate.resolutionRate)}
        </div>
        <div className='Dashboard-card Dashboard-layout-item'>
          <div className='Dashboard-card--title'>Bot precision</div>
          {O.fold(
              this.renderNoData,
              (rate: number) => {
                const printedRate = rate > 1 ? 1 : toPercent(rate)
                return this.renderPieChart({
                  mainRate: printedRate,
                  values: rateToPieFormat(printedRate)
                })
              }
            )(rate.botPerformanceRate)}
        </div>
      </div>
    </div>
  )

  render () {
    const { startDate, endDate, onDatesChange, healthRate } = this.props

    return (
      <div className='Dashboard-container'>
        <header className='Dashboard-container-header'>
          <h1 className='Dashboard-container-header--title'>Performance</h1>

          <DateRangeComponent startDate={startDate} endDate={endDate} onDatesChange={onDatesChange}/>
        </header>

        {remoteData.fold({
          Success: this.renderHealthRate,
          NotAsked: () => renderLoader('Please wait...'),
          Loading: () => renderLoader('Please wait...'),
          Failure: renderFailure
        })(healthRate)}
      </div>
    )
  }
}

export default Dashboard
