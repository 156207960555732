import { call, put, select, takeLatest } from 'redux-saga/effects'

import { maybe } from '@sbizeul/fp-flow'
import { push } from 'connected-react-router'
import * as R from 'ramda'

import Api from 'services/tolkApi'
import botManagementApi from 'services/botManagementApi'

import answer from 'modules/answer'
import { isSimpleAnswerMaybe, toReceivedAnswer } from 'modules/answer/models'
import { answerConversion } from 'modules/answer/utils'
import bot from 'modules/bot'
import question from 'modules/question'

import * as qaActions from './actions'
import * as qaTypedActions from './actions.typed'
import * as selectors from './selectors'
import { QA_QUESTIONS, qaAnswer } from './routes'
import { SaveOpeningStatus, SAVE_COLLAPSIBLE_SECTION_OPENING_STATUS } from './types'

const qaEndpoint = (botId: any, questionId: any) => `/bot/${botId}/qa/${questionId}`
const qaEndpointWorkspace = (botId: string, questionId: string) => `/v1/bots/${botId}/questions/${questionId}/answer`

export function* fetchOneQnA (action: any) {
  const botId = yield select(bot.selectors.getSelectedId)
  const questionId = action.payload
  try {
    const { data } = yield call(Api.get, qaEndpoint(maybe.get(botId), questionId))

    const newAnswer = data.answer
      ? maybe.of(answer.models.toAnswer(data.answer))
      : maybe.of({ bot_id: maybe.getOrElse(() => 'unknown', botId), content: [], type: 'Simple' })
    yield put(answer.actions.fetchOneSuccess(newAnswer))
    yield put(qaActions.fetchOneSuccess(data))
  } catch (error) {
    const errorMessage = error.response.data
    yield put(qaActions.fetchOneFailure(errorMessage, questionId))
  }
}

export function* deleteOneQna (action: any) {
  const botId = yield select(bot.selectors.getSelectedId)
  const questionId = action.payload
  try {
    yield call(Api.delete, qaEndpoint(maybe.get(botId), questionId))
    yield put(qaActions.deleteOneQnASuccess())
  } catch (error) {
    yield put(qaActions.deleteOneQnAFailure(error))
  }
}

export function* deleteAllQnA () {
  const botId = yield select(bot.selectors.getSelectedId)
  try {
    yield call(Api.delete, qaEndpoint(maybe.get(botId), ''))
    yield put(qaActions.deleteAllQnASuccess())
  } catch (error) {
    yield put(qaActions.deleteAllQnAFailure(error))
  }
}

export function* saveOneQnA (saveParam: any) {
  const newAnswer = isSimpleAnswerMaybe(saveParam.payload.answer)
    ? toReceivedAnswer(maybe.get(saveParam.payload.answer))
    : R.pipe(
      R.set(R.lensPath(['missing_message', 'bot_id']), saveParam.payload.qna.bot_id),
      R.over(R.lensProp('missing_message'), toReceivedAnswer),
      R.set(R.lensProp('bot_id'), saveParam.payload.qna.bot_id),
      R.over(R.lensProp('buttons'), R.map(R.set(R.lensPath(['answer', 'bot_id']), saveParam.payload.qna.bot_id)))
    )(toReceivedAnswer(maybe.get(saveParam.payload.answer)))

  const qa = {
    ...saveParam.payload.qna,
    answer: newAnswer
  }

  try {
     yield call(botManagementApi.put,
      qaEndpointWorkspace(saveParam.payload.qna.bot_id, saveParam.payload.qna.external_id),
      answerConversion(qa.answer, qa.ask_feedback)
      )
    yield put(qaActions.saveOneSuccess(saveParam.payload.qna.id))
    yield put(qaActions.navigateToQuestions())
  } catch (e) {
    const serverFailure = e.response.data
    yield put(qaActions.saveOneFailure(serverFailure))
  }
}

export function* navigateToQuestions () {
  yield put(push(QA_QUESTIONS))
}

export function* navigateToQnA (action: any) {
  const questionId = action.payload
  yield put(push(qaAnswer(questionId)))
}

export function* selectBot () {
  const route = yield select(selectors.questionRouteSelector)
  if (route !== null) {
    yield put(question.actions.fetchLabeledQuestions())
  }
}

export function* importFile (action: any) {
  const botId = yield select(bot.selectors.getSelectedId)
  try {
    const data = new FormData()
    data.append('file', action.payload[0])
    yield call(Api.post, `/bot/${maybe.get(botId)}/qa`, data)
    yield put(qaActions.importFileSuccess())
  } catch (error) {
    const importError = (error.response && error.response.data) || error
    yield put(qaActions.importFileFailure(importError))
  }
}

export function* saveOpeningStatus (action: SaveOpeningStatus) {
  yield put(qaTypedActions.saveOpeningStatusSuccess(action.payload))

  // Save into localstorage
  const openingStatus = yield select(selectors.getCollapsibleSectionOpeningStatus)
  localStorage.setItem('qaCollapsibleSectionsOpeningStatus', JSON.stringify(openingStatus))
}

export function* root () {
  yield takeLatest(qaActions.FETCH_ONE_REQUEST, fetchOneQnA)
  yield takeLatest(qaActions.DELETE_ONE_QNA_REQUEST, deleteOneQna)
  yield takeLatest(qaActions.DELETE_ALL_QNA_REQUEST, deleteAllQnA)
  yield takeLatest(qaActions.DELETE_ONE_QNA_SUCCESS, question.saga.fetchLabeledQuestions)
  yield takeLatest(qaActions.DELETE_ONE_QNA_SUCCESS, bot.saga.fetchPublishStatus)
  yield takeLatest(qaActions.DELETE_ALL_QNA_SUCCESS, question.saga.fetchLabeledQuestions)
  yield takeLatest(qaActions.DELETE_ALL_QNA_SUCCESS, bot.saga.fetchPublishStatus)
  yield takeLatest(qaActions.SAVE_ONE_REQUEST, saveOneQnA)
  yield takeLatest(qaActions.NAVIGATE_TO_QUESTIONS, navigateToQuestions)
  yield takeLatest(qaActions.NAVIGATE_TO_QNA, navigateToQnA)
  yield takeLatest(qaActions.SAVE_ONE_SUCCESS, bot.saga.fetchPublishStatus)
  yield takeLatest(qaActions.IMPORT_REQUEST, importFile)
  yield takeLatest(qaActions.IMPORT_SUCCESS, question.saga.fetchLabeledQuestions)
  yield takeLatest(qaActions.IMPORT_SUCCESS, bot.saga.fetchPublishStatus)
  yield takeLatest(question.actions.SELECT, navigateToQnA)
  yield takeLatest(bot.actions.SELECT, selectBot)
  yield takeLatest(SAVE_COLLAPSIBLE_SECTION_OPENING_STATUS, saveOpeningStatus)
}
