import React from 'react'

import './styles.css'

const RoundProgressBar = ({ className, colorLine = '#3a86ff', max, size = 40, strokeWidth = 5, value }) => {
  const radius = (size - strokeWidth) / 2
  const viewBox = `0 0 ${size} ${size}`
  const dashArray = radius * Math.PI * 2
  const dashOffset = dashArray - (dashArray * value) / max
  const percentage = ((value / max) * 100).toFixed()
  return (
    <svg className={className} width={size} height={size} viewBox={viewBox}>
      <defs>
        <linearGradient id='roundProgressBar-linear-gradient'>
          <stop offset='0%' stopColor={colorLine} />
          <stop offset='100%' stopColor='#9ec3fe' />
        </linearGradient>
      </defs>
      <circle fill='none' stroke='#f9f9f9' cx={size / 2} cy={size / 2} r={radius} strokeWidth={`${strokeWidth}px`} />
      <circle
        fill='none'
        stroke='url(#roundProgressBar-linear-gradient)'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeDasharray={dashArray}
        strokeDashoffset={dashOffset}
        cx={size / 2}
        cy={size / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
        transform={`rotate(-90 ${size / 2} ${size / 2})`}
      />
      <text className='RoungProgressBar-text' x='50%' y='60%' textAnchor='middle' stroke='#616b87'>
        {`${percentage}%`}
      </text>
    </svg>
  )
}

export default RoundProgressBar
