import { connect } from 'react-redux'

import LogList from '../components/LogList'
import * as logSelectors from '../selectors'
import * as logActions from '../actions'

const mapStateToProps = state => ({
  logs: logSelectors.getAll(state),
  page: logSelectors.getPage(state)
})

const mapDispatchToProps = {
  fetchNext: logActions.fetchNext,
  fetchPrevious: logActions.fetchPrevious
}

export default connect(mapStateToProps, mapDispatchToProps)(LogList)
