import moment from 'moment'

const date = moment([2019, 9, 16, 15, 6])

const editCarouselElementButtonTitleTest = {
  initState: {
    campaigns: [
      {
        id: 1,
        updated: true,
        title: 'Campagne 1',
        elements: [
          {
            type: 'text',
            content: 'plafiplouf'
          },
          {
            type: 'carousel',
            content: {
              attachments: [
                {
                  title: 'A title 1',
                  text: 'A description'
                },
                {
                  title: 'A title 2',
                  text: 'A description',
                  buttons: [
                    {
                      type: 'url',
                      title: 'button 1',
                      value: 'a-url'
                    },
                    {
                      type: 'url',
                      title: 'button 2',
                      value: 'another-url'
                    }
                  ]
                }
              ]
            }
          }
        ],
        cohorts: [],
        date,
        kpis: {
          sent: 0,
          read: 0,
          targeted: 0
        },
        status: 'draft'
      },
      {
        id: 2,
        updated: true,
        title: 'Campagne 2',
        elements: [],
        cohorts: [],
        date,
        kpis: {
          sent: 0,
          read: 0,
          targeted: 0
        },
        status: 'scheduled'
      }
    ],
    currentId: 1
  },
  action: {
    index: 1,
    cardIndex: 1,
    buttonIndex: 1,
    title: 'A new title'
  },
  reducedState: {
    campaigns: [
      {
        id: 1,
        updated: true,
        title: 'Campagne 1',
        elements: [
          {
            type: 'text',
            content: 'plafiplouf'
          },
          {
            type: 'carousel',
            content: {
              attachments: [
                {
                  title: 'A title 1',
                  text: 'A description'
                },
                {
                  title: 'A title 2',
                  text: 'A description',
                  buttons: [
                    {
                      type: 'url',
                      title: 'button 1',
                      value: 'a-url'
                    },
                    {
                      type: 'url',
                      title: 'A new title',
                      value: 'another-url'
                    }
                  ]
                }
              ]
            }
          }
        ],
        cohorts: [],
        date,
        kpis: {
          sent: 0,
          read: 0,
          targeted: 0
        },
        status: 'draft'
      },
      {
        id: 2,
        updated: true,
        title: 'Campagne 2',
        elements: [],
        cohorts: [],
        date,
        kpis: {
          sent: 0,
          read: 0,
          targeted: 0
        },
        status: 'scheduled'
      }
    ],
    currentId: 1
  }
}

export default editCarouselElementButtonTitleTest
