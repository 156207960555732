import React, { useState } from 'react'
import { v4 as uuid } from 'uuid'
import * as R from 'ramda'
import { Option, fromNullable, fold, chain } from 'fp-ts/lib/Option'
import { FaTrash } from 'react-icons/fa'
import { IoMdArrowDropleft } from 'react-icons/io'
import { maybe } from '@sbizeul/fp-flow'

import answer from 'modules/answer'
import { Id } from 'types'

import CreateScenarioComponent from './CreateScenario'
import {
  Answer,
  Button as ButtonSkill,
  CreateScenario,
  Question,
  QuestionAndButtonIDs,
  RemoveScenario,
  Skill,
  UpdateAnswerContentParams
} from '../../types'

import './styles.css'

export type Props = Readonly<{
  skill: Skill

  createScenario: CreateScenario
  updateAnswerContent: UpdateAnswerContentParams
  removeScenario: RemoveScenario
}>

type DropdownFormat = Readonly<{
  key: Id
  text: string
  value: string
}>

export const toDropdownFormat: (buttons: ReadonlyArray<ButtonSkill>) => DropdownFormat[] = R.pipe(
  R.map((button: ButtonSkill) => ({
    key: button.id,
    text: button.label,
    value: button.id
  })),
  R.prepend({ key: uuid(), text: '-', value: '-' })
)

const getQuestion: (questionID: Id) => (skill: Skill) => Option<Question> = (questionID) => (skill) => R.pipe(
  R.find<Question>(question => question.id === questionID),
  fromNullable
)(skill.questions)

const getButton: (buttonID: Id) => (question: Question) => Option<ButtonSkill> = (buttonID) => (question) => R.pipe(
  R.find<ButtonSkill>(button => button.id === buttonID),
  fromNullable
)(question.buttons)

const getLabel: (button: Option<ButtonSkill>) => string = fold(() => 'label not found', (button) => button.label)

const Scenario = ({
  updateAnswerContent,
  removeScenario,
  skill
}: Omit<Props, 'createScenario'>
) => {
  const [scenarioIndex, setScenarioIndex] = useState(-1)

  return <React.Fragment>
    {R.addIndex<Answer, React.ReactNode>(R.map)((skillAnswer: Answer, index: number) => (
      <div key={skillAnswer.answer.id} className='SkillBuilder-scenario-container'>
        <div className='SkillBuilder-scenario-title-container'>
          <div className='SkillBuilder-scenario-label-containers' onClick={() => setScenarioIndex(previousState => previousState !== index ? index : -1)}>
            {R.map(
              (scenario: QuestionAndButtonIDs) => (
                <div key={scenario.questionID + scenario.buttonID} className='SkillBuilder-scenario-label'>
                  <p className='SkillBuilder-scenario-label-title'>
                    {R.pipe(
                      getQuestion(scenario.questionID),
                      chain(getButton(scenario.buttonID)),
                      getLabel
                    )(skill)}
                  </p>
                </div>
              ))(skillAnswer.scenarios)}
            <IoMdArrowDropleft size='25' className={`SkillBuilder-scenario-label-containers-arrow-icon ${scenarioIndex === index && 'open'}`} />
          </div>
          <FaTrash className='SkillBuilder-scenario-label-xtrash' onClick={() => removeScenario(index)} />
        </div>
        {scenarioIndex === index && <div className='SkillBuilder-scenario-simpleAnswerEditor-container'>
          <answer.components.AnswerEditorSimpleContainer
            answer={maybe.of(skillAnswer.answer)}
            onAnswerUpdated={answerState => !maybe.equals(answerState, maybe.of(skillAnswer.answer)) && updateAnswerContent({ index, content: answerState })}
          />
        </div>
        }
      </div>
    ))(skill.answers)}
  </React.Fragment>
}

const ScenarioComponent = ({
  createScenario,
  removeScenario,
  skill,
  updateAnswerContent
}: Props) => (
  <React.Fragment>
    <CreateScenarioComponent
      questions={skill.questions}
      onScenarioAdded={createScenario}
    />
    <Scenario
      updateAnswerContent={updateAnswerContent}
      removeScenario={removeScenario}
      skill={skill}
    />
  </React.Fragment>)

export default ScenarioComponent
