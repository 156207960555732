/*
  Format raw filters from state
  into data to make api calls
*/
const formatFilters = (f, previous = false) => ({
  channelId: f.channel.id,
  start: previous ? f.comparisonDateRange.start : f.dateRange.start.format('YYYY-MM-DD'),
  end: previous ? f.comparisonDateRange.end : f.dateRange.end.format('YYYY-MM-DD'),
  period: f.periods ? f.periods.data[f.periods.active].name : null
})

export default formatFilters
