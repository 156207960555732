import { connect } from 'react-redux'

import question from 'modules/question'

import * as actions from '../actions'
import UnmatchedQuestions from '../components/UnmatchedQuestions'
import * as selectors from '../selectors'

const mapStateToProps = state => ({
  logs: selectors.getLogs(state),
  progress: selectors.getProgress(state),
  questions: question.selectors.getAll(state)
})

const mapDispatchToProps = {
  fetchLogs: actions.fetchRequest,
  fetchQuestions: question.actions.fetchAll,
  validateTagging: actions.validateTagging
}

const UnmatchedQuestionsContainers = connect(mapStateToProps, mapDispatchToProps)(UnmatchedQuestions)

export default UnmatchedQuestionsContainers
