import { createSelector } from 'reselect'

import { AppState } from './types'

export const getState = (state: AppState) => state.entities

export const getAnalyticsState = createSelector(getState, state => state.analytics)

export const getFilterState = createSelector(getAnalyticsState, state => state.filter)

export const getStartDate = createSelector(getFilterState, state => state.startDate)

export const getEndDate = createSelector(getFilterState, state => state.endDate)

export const getHealthRate = createSelector(getAnalyticsState, state => state.healthRate)

export const getWebchatStats = createSelector(getAnalyticsState, state => state.webchatStats)
