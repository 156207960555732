import actions from '../actions'
import words from '../words/operations'
import payloads from '../payloads/operations'
import menuPersistent from '../menuPersistent/operations'
import referral from '../referral/operations'
import funnel from '../funnel/operations'

const fetchBehaviours = filters => async dispatch => {
  dispatch(actions.fetchBehavioursRequest())
  try {
    const wordsPromise = dispatch(words.fetchWordsCount(filters))
    const payloadsPromise = dispatch(payloads.fetchPayloads(filters))
    const persistent = dispatch(menuPersistent.fetchMenuPersistent(filters))
    const menuPersistentUtil = dispatch(menuPersistent.fetchMenuPersistentUtil(filters))
    const referraldata = dispatch(referral.fetchReferral(filters))
    const referralUtil = dispatch(referral.fetchReferralUtil(filters))
    const funnelPromise = dispatch(funnel.fetchFunnel(filters))
    await Promise.all([
      wordsPromise,
      payloadsPromise,
      persistent,
      menuPersistentUtil,
      referraldata,
      referralUtil,
      funnelPromise
    ])
    //  await dispatch(events.fetchCustomEventsData(filters))
    await dispatch(actions.fetchBehavioursSuccess())
  } catch (e) {
    await dispatch(actions.fetchBehavioursFailure())
  }
}

export default fetchBehaviours
