
import axios from 'axios'
import { botManagementBaseUrl } from 'helpers/effects'
import formatFilters from '../../../_shared/formatFilters'
import actions from '../actions'

const fetchConversationsPreviousPeriod = rawFilters => {
  const { channelId, start, end } = formatFilters(rawFilters, true)

  const startDate = encodeURIComponent(new Date(start).toISOString())
  const endDate = encodeURIComponent(new Date(end).toISOString())

  return async (dispatch, getState) => {
    dispatch(actions.fetchConversationsPreviousPeriodRequest())
    try {
      const response = await axios.get(
        `${botManagementBaseUrl}/v1/channels/${channelId}/detail-conversation-count?startDate=${startDate}&endDate=${endDate}`
      )
      const { totalCount } = response.data
      dispatch(actions.fetchConversationsPreviousPeriodSuccess({ totalCount }))
    } catch (e) {
      dispatch(actions.fetchConversationsPreviousPeriodFailure(e))
    }
  }
}

export default fetchConversationsPreviousPeriod
