import { remoteData } from '@sbizeul/fp-flow'
import * as R from 'ramda'
import { TrainingQuestionEntitiesAction, FetchAllRequest, FetchAllSuccess, FetchAllFailure, TrainingQuestionEntityState } from './types'

import { FETCH_ALL_FAILURE, FETCH_ALL_REQUEST, FETCH_ALL_SUCCESS } from './actions'

export const initialState = {
  byId: {}
}

const byId = (trainingQuestionId: string) => R.lensPath(['byId', trainingQuestionId])

const fetchAll = (action: FetchAllRequest, state: TrainingQuestionEntityState) =>
  R.set(byId(action.payload), remoteData.loading(), state)

const fetchAllSuccess = (action: FetchAllSuccess, state: TrainingQuestionEntityState) =>
  R.set(byId(action.payload.questionId), remoteData.success(action.payload.trainingQuestions), state)

const fetchAllFailure = (action: FetchAllFailure, state: TrainingQuestionEntityState) =>
  R.set(byId(action.payload.questionId), remoteData.failure(action.payload.error), state)

export default function (state = initialState, action: TrainingQuestionEntitiesAction) {
  switch (action.type) {
    case FETCH_ALL_REQUEST:
      return fetchAll(action, state)
    case FETCH_ALL_FAILURE:
      return fetchAllFailure(action, state)
    case FETCH_ALL_SUCCESS:
      return fetchAllSuccess(action, state)
    default:
      return state
  }
}
