import {
  FetchChannelInformationSuccess,
  FetchChannelInformationFailure,
  ChannelSettingsEntitiesState,
  ChannelsEntityAction,
  FETCH_CHANNEL_INFORMATION_FAILURE,
  FETCH_CHANNEL_INFORMATION_REQUEST,
  FETCH_CHANNEL_INFORMATION_SUCCESS
} from './types'
import { remoteData } from '@sbizeul/fp-flow'

export const initialState: ChannelSettingsEntitiesState = {
  channelInfo: remoteData.notAsked()
}

const fetchRequest = (
  state: ChannelSettingsEntitiesState
): ChannelSettingsEntitiesState => (
{
  ...state,
  channelInfo: remoteData.loading()
}
)

const fetchSuccess = (
  state: ChannelSettingsEntitiesState,
  action: FetchChannelInformationSuccess
): ChannelSettingsEntitiesState => ({
  ...state,
  channelInfo: remoteData.success(action.payload)
})

const fetchFailure = (
  state: ChannelSettingsEntitiesState,
  action: FetchChannelInformationFailure
): ChannelSettingsEntitiesState => ({
  ...state,
  channelInfo: remoteData.failure(action.payload)
})

export default function channels (state: ChannelSettingsEntitiesState = initialState, action: ChannelsEntityAction) {
  switch (action.type) {
    case FETCH_CHANNEL_INFORMATION_REQUEST:
      return fetchRequest(state)
    case FETCH_CHANNEL_INFORMATION_SUCCESS:
      return fetchSuccess(state, action)
    case FETCH_CHANNEL_INFORMATION_FAILURE:
      return fetchFailure(state, action)
    default:
      return state
  }
}
