import React from 'react'
import classNames from 'classnames'
import { MdKeyboardArrowRight } from 'react-icons/md'

import trainingQuestion from 'modules/trainingQuestion'

import { QuestionProps } from '../../types'
import { isArchived } from '../../models'
import EditableQuestion from './EditableQuestion'

import './styles.css'

const Question: React.FunctionComponent<QuestionProps> = (props) => {
  const [isOpen, toggle] = React.useState(false)
  const { navigateToQuestion, question, ...listProps } = props

  const renderQuestion = () => <EditableQuestion {...props} isOpen={isOpen} />

  const renderTrainingQuestions = () => (
    <trainingQuestion.components.List {...listProps} questionId={question.id} questionTemplateId={question.template_id} />
  )

  return (
    <React.Fragment>
      <div
        className={classNames(
          'Question',
          { 'Question-border': !isOpen },
          { 'Question-archived': isArchived(question) }
        )}
      >
        <div className="Question-button">
          <input
            onClick={(_) => toggle(!isOpen)}
            id={`QBI-${question.id}`}
            className="Question-button--input"
            type="checkbox"
          />
          <label htmlFor={`QBI-${question.id}`}>
            <div className="Question-button--label">
              <MdKeyboardArrowRight size="25" />
            </div>
          </label>
        </div>
        {renderQuestion()}
      </div>
      {isOpen && renderTrainingQuestions()}
    </React.Fragment>
  )
}

export default Question
