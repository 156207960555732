import React from 'react'

import * as connectorsConfig from '@tolk-ai/bot-management-react-connectors-config'
import * as connectorsConfigMessenger from '@tolk-ai/connectors-config-messenger'
import * as authenticatedRequest from 'services/authenticated-request'

import '@tolk-ai/bot-management-react-connectors-config/dist/bot-management-react-connectors-config.esm.css'
import BackButton from 'components/BackButton'

const MessengerLogin = connectorsConfig.messengerLogin.container(
  connectorsConfigMessenger.axiosMessenger.createOneMessengerLoginConfig(
    authenticatedRequest.botManagement
  ),
  connectorsConfigMessenger.axiosMessenger.getOneMessengerChannelConfig(
    authenticatedRequest.botManagement
  ),
  window
)

const Messenger = () => {
  const botId = localStorage.getItem('botId') || ''

  return (
    <>
      <div className="pageContainer">
        <section className="channelsettings">
          <header className="channelsettings__header">
            <BackButton label={`Messenger Settings`} path="/channels" />
          </header>
          <MessengerLogin
            botId={botId}
          />
        </section>
      </div>
    </>
  )
}

export default Messenger
