import { maybe } from '@sbizeul/fp-flow'

import actions from '../actions'
import isResValid from '../../../_shared/isResValid'
import formatFilters from '../../../_shared/formatFilters'
import { groupAverageByDayMessages } from '../../../../views/pages/Analytics/utils'
import bot from '../../../../modules/bot'
import api from 'services/tolkApiLegacy'

const fetchMessagesAverageByDay = rawFilters => (dispatch, getState) => {
  const { channelId, start, end } = formatFilters(rawFilters)
  const botId = bot.selectors.getSelectedId(getState())

  dispatch(actions.fetchMessagesAverageByDayRequest())
  return api.get(`/analytics/${maybe.get(botId)}/${channelId}/messages`,
  { params: {
    start,
    end,
    period: 'hours'
  }}).then(response => {
      if (isResValid(response)) {
        const messagesAverageByDay = groupAverageByDayMessages(response.data.result.data)
        dispatch(
          actions.fetchMessagesAverageByDaySuccess({
            messagesAverageByDay
          })
        )
      } else {
        dispatch(actions.fetchMessagesAverageByDayFailure())
      }
    })
    .catch(err => {
      dispatch(actions.fetchMessagesAverageByDayFailure(err))
    })
}

export default fetchMessagesAverageByDay
