import React from 'react'
import { Menu, Image } from 'semantic-ui-react'

import AuthTab from '../AuthTab'
import { Error } from '../../../../components/Message'

import './styles.css'

export default function AuthPage({ error }) {
  return (
    <div className='AuthPage'>
      <Menu
        fixed='top'
        size='large'
        borderless
        style={{
          backgroundColor: '#ffffff',
          height: '80px',
          fontSize: '14px',
          fontFamily: 'Raleway'
        }}
      >
        <Menu.Item header style={{ marginLeft: '60px', marginRight: '60px' }}>
          <Image src='/img/flags/logo-bot_hd.png' size='tiny' />
        </Menu.Item>
      </Menu>
      {error && <Error header={error.message} />}
      <AuthTab className='AuthPage-authForm' />
    </div>
  )
}
