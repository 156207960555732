import React from 'react'
import * as reactComponents from '@tolk-ai/react-components'
import * as agent from '@tolk-ai/account-management-agent'
import * as browserUtils from '@tolk-genii/browser-utils'

import '@tolk-ai/ui-library-react/dist/ui-library-react.esm.css'
import axios from 'axios'

import {
  analyticsReactBaseUrl,
  authManagementBaseUrl,
  accountManagementBaseUrl,
  botManagementReactBaseUrl,
  cookieDomain
} from 'helpers/effects'

const ComponentTolkMenu = () => {
  const accountManagementAxios = axios.create({
    baseURL: accountManagementBaseUrl
  })

  const tolkMenu = (
    accountManagementExpressAxios,
    document,
    authManagementExpressBaseUrl,
    botManagementBaseUrl,
    cookieDomain,
    analyticsReactBaseUrl
  ) =>
    reactComponents.tolkMenu.container(
      analyticsReactBaseUrl,
      agent.axiosAgent.getOneAgentUserInfos(
        browserUtils.authenticatedRequest(
          accountManagementExpressAxios,
          authManagementExpressBaseUrl,
          browserUtils.removeCookie(document, cookieDomain),
          browserUtils.getCookie(document),
          browserUtils.setCookie(document, cookieDomain)
        )
      ),
      botManagementBaseUrl
    )

  const TolkMenu = tolkMenu(
    accountManagementAxios,
    document,
    authManagementBaseUrl,
    botManagementReactBaseUrl,
    cookieDomain,
    analyticsReactBaseUrl
  )

  return (
    <TolkMenu appName="bot" userId={browserUtils.getAuthenticatedUserId()} />
  )
}

export default ComponentTolkMenu
