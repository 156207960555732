import React, { FunctionComponent } from 'react'
import * as R from 'ramda'

import TextInput from 'components/TextInput'

import AvatarSelection from './AvatarSelection'
import ColorSelection from './ColorSelection'
import { UpdateWebchatParams, UploadImageParams, Webchat } from '../../types'
import './styles.css'

type Props = Readonly<{
  webchat: Webchat
  isModified: boolean

  uploadImage: UploadImageParams
  updateWebchat: UpdateWebchatParams
}>

const renderBotName = (webchat: Webchat, updateWebchat: UpdateWebchatParams) => <>
  <h2>Bot name</h2>
  <TextInput
    className='BotConfig-name'
    value={webchat.settings.title}
    onChangeText={text => updateWebchat(R.set(R.lensPath(['settings', 'title']), text, webchat))}
    type='rounded'
  />
</>

const WebchatUpdater: FunctionComponent<Props> = ({ webchat, uploadImage, updateWebchat }) =>
  <div className='BotConfig-container'>
    <div className='BotConfig-title'>
      <h1 className='BotConfig-title--h1'>{'Chat\u00a0window'}</h1>
    </div>
    <p className='BotConfig-subtitle'>
      Choose your beautiful themes or choose a custom color to match your website. Same with your avatar.
  </p>
    {renderBotName(webchat, updateWebchat)}
    <div className='BotConfig-avatar-and-color-containers'>
      <AvatarSelection webchat={webchat} uploadImage={uploadImage} updateWebchat={updateWebchat} />
      <ColorSelection webchat={webchat} updateWebchat={updateWebchat} />
    </div>
  </div>

export default WebchatUpdater
