import { ServerFailure } from 'types'
import { RemoteData } from '@sbizeul/fp-flow'

export const REGISTER_REQUEST = 'register/REGISTER_REQUEST'
export const REGISTER_SUCCESS = 'register/REGISTER_SUCCESS'
export const REGISTER_FAILURE = 'register/REGISTER_FAILURE'

export type MonitorUiState = RemoteData<ServerFailure, string>

export type RegisterUiState = Readonly<{
  form: MonitorUiState
}>

export type RegisterAppState = Readonly<{
  ui: { register: RegisterUiState }
}>

export type RegisterState = Readonly<{
  username: string
  email: string
  companyName: string
  phoneNumber: string
}>

export type RegisterRequest = Readonly<{
  type: typeof REGISTER_REQUEST
  payload: RegisterState
}>

export type RegisterSuccess = Readonly<{
  type: typeof REGISTER_SUCCESS
}>

export type RegisterFailure = Readonly<{
  type: typeof REGISTER_FAILURE
  payload: ServerFailure
}>

export type RegisterAction =
  | RegisterRequest
  | RegisterSuccess
  | RegisterFailure
