
import * as React from 'react'
import { Modal } from 'semantic-ui-react'
import { maybe } from '@sbizeul/fp-flow'
import { MdSave } from 'react-icons/md'
import * as R from 'ramda'

import answerModule from 'modules/answer'
import Button from 'components/Button'


import './styles.css'

class AnswerModal extends React.PureComponent {
  state = {
    isOpen: false
  }

  handleOpen = () => this.setState({ isOpen: true })

  handleClose = () => this.setState({ isOpen: false })

  render() {
    const { text, answer, onAnswerUpdated } = this.props

    return (
      <React.Fragment>
        <Button onClick={this.handleOpen} className='QuickReply-answer--button'>
          {text || '+ Create Answer'}
        </Button>
        <Modal
          className='AnswerModal--modal'
          open={this.state.isOpen}
          closeOnEscape={true}
          closeOnDimmerClick={true}
          onClose={this.handleClose}
        >
          <answerModule.components.AnswerEditorSimpleContainer answer={answer} onAnswerUpdated={onAnswerUpdated} />
          <Button className='AnswerModal--button' disabled={maybe.fold(() => true, R.complement(answerModule.models.isValidAnswer), answer)} onClick={this.handleClose}>
            <MdSave className='AnswerModal--icon' size='20' />
            SAVE
          </Button>
        </Modal>
      </React.Fragment>
    )
  }
}

export default AnswerModal
