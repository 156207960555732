import { takeLatest, call, select, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'

import { maybe } from '@sbizeul/fp-flow'
import * as R from 'ramda'

import * as browserUtils from '@tolk-genii/browser-utils'

import { removeId } from 'helpers/object'
import Api from 'services/tolkApi'
import accountApi from 'services/accountManagementApi'
import botManagementApi from 'services/botManagementApi'
import agentApi from 'services/agentApi'
import bot from 'modules/bot'

import { ALL_SKILLS } from 'modules/skill/routes'

import * as answerActions from './actions'
import { toAnswer } from './models'

const answersEndpoint = (botId, answerId) => `/bot/${maybe.get(botId)}/qa/answers/${answerId}`

export const simplifiedSkillsEndpoint = botId => `bots/${maybe.getOrElse(() => 'error', botId)}/skill-mentions`
export const simplifiedSmartSkillsEndpoint = botId => `/v1/bots/${maybe.getOrElse(() => 'error', botId)}/smart-skills`
export const appsEndpoint = botId => `/v1/bots/${maybe.getOrElse(() => 'error', botId)}/apps-installed`
export const agentLabelsEndpoint = agentAccountId => `/api/v1/accounts/${agentAccountId}/labels`
export const agentStatusEndpoint = botId => `/v1/bots/${maybe.getOrElse(() => 'error', botId)}/connectors-config/agent`

const fromSmartSkillsToSimplifiedSkills = (smartskills) => {
  return smartskills.map(smartskill => {
    return {
      "id": smartskill.id,
      "type": "smartskill",
      "name": smartskill.name,
      "objectAttachment": {
        "smartskill_id": smartskill.id
      }
    }
  })
}

const fromAppsToSimplifiedSkills = (apps) => {
  return apps.map(app => {
    return {
      "id": app.action_id,
      "type": "app",
      "name": app.name,
      "objectAttachment": {
        "name": app.name,
        "scenario": app.name
      }
    }
  })
}

const fromAgentLabelsToSimplifiedTags = (tags) => {
  const tagsData = tags.payload;
  return tagsData.map(tag => {
    return {
      'id': `${tag.title}`,
      'name': tag.title
    }
  });
}

export function* fetchOne({ payload: id }) {
  try {
    const botId = yield select(bot.selectors.getSelectedId)
    const { data } = yield call(Api.get, answersEndpoint(botId, id))
    yield put(answerActions.fetchOneSuccess(maybe.of(toAnswer(data))))
  } catch (err) {
    const serverFailure = err.response.data
    yield put(answerActions.fetchOneFailure(id, serverFailure))
  }
}

export function* saveOne({ payload }) {
  const botId = yield select(bot.selectors.getSelectedId)
  const answer = {
    bot_id: maybe.getOrElse(() => 'unknown', botId),
    type: 'Simple',
    content: R.map(removeId, payload.content)
  }

  const answerWithName = R.unless(() => R.isNil(payload.name), R.assoc('name', payload.name))(answer)

  try {
    const { data } = yield maybe.fold(
      () => call(Api.post, answersEndpoint(botId, ''), answerWithName),
      answerId => call(Api.put, answersEndpoint(botId, answerId), answerWithName)
    )(payload.answerId)

    yield put(answerActions.saveOneSuccess(data))
  } catch (e) {
    const serverFailure = e.response.data
    yield put(answerActions.saveOneFailure(serverFailure))
  }
}

export function* uploadImage(action) {
  const botId = yield select(bot.selectors.getSelectedId)
  try {
    const dataForm = new FormData()
    dataForm.append('image', action.payload.file)
    const { data } = yield call(Api.post, `/bot/${maybe.get(botId)}/image`, dataForm)
    yield put(answerActions.uploadImageSuccess({ id: action.payload.id, url: data.url }))
  } catch (error) {
    yield put(answerActions.uploadImageFailure({ id: action.payload.id, error }))
  }
}

export function* fetchAllSimplifiedSkills () {
  const botId = yield select(bot.selectors.getSelectedId)
  try {
    let { data } = yield call(Api.get, simplifiedSkillsEndpoint(botId))
    const dataSmartSkills = yield call(botManagementApi.get, simplifiedSmartSkillsEndpoint(botId))
    if (dataSmartSkills.data) {
      const simplifiedSmartSKills = fromSmartSkillsToSimplifiedSkills(dataSmartSkills.data)
      data = data.concat(simplifiedSmartSKills);
    }
    const dataFromWorkspaceRaw = yield call(botManagementApi.get, appsEndpoint(botId))
    if (dataFromWorkspaceRaw.data) {
      const dataFromWorkspace = fromAppsToSimplifiedSkills(dataFromWorkspaceRaw.data)
      yield put(answerActions.fetchAllSimplifiedSkillsSuccess(data.concat(dataFromWorkspace)))
    } else {
      yield put(answerActions.fetchAllSimplifiedSkillsSuccess(data))
    }
  } catch (error) {
    const serverFailure = error.response.data
    yield put(answerActions.fetchAllSimplifiedSkillsFailure(serverFailure))
  }
}

export function* fetchAllLabelsFromAgent () {
  const botId = yield select(bot.selectors.getSelectedId)
  const userId = browserUtils.getAuthenticatedUserId()

  try {
    yield call(botManagementApi.get, agentStatusEndpoint(botId))

    const getClientInfos = yield call(
      accountApi.get,
      `/v1/users/${userId}/client-details`
    )
    const clientId = getClientInfos?.data?.clientId

    const getAgentInfos = yield call(
      accountApi.get,
      `/v1/clients/${clientId}/user-agent-infos`
    )
    const agentClientId = getAgentInfos?.data?.agentClientId
    const agentAccessToken = getAgentInfos?.data?.accessToken
    const headers = { 'api_access_token': agentAccessToken }
    const { data } = yield call(agentApi.get, agentLabelsEndpoint(agentClientId), { headers })

    if (data) {
      const dataAsTag = fromAgentLabelsToSimplifiedTags(data);
      yield put(answerActions.fetchAllSimplifiedTagsSuccess(dataAsTag))
    }
  } catch (error) {
    if (error && error.response.status === 404) {
      const fakeTag = {payload: []};
      const tagToDisplay = fromAgentLabelsToSimplifiedTags(fakeTag);
      yield put(answerActions.fetchAllSimplifiedTagsSuccess(tagToDisplay))
    } else {
      const serverFailure = error.response.data
      yield put(answerActions.fetchAllSimplifiedTagsFailure(serverFailure))
    }
  }
}

export function* goToSkills(action) {
  yield put(push(ALL_SKILLS))
}

export function* root() {
  yield takeLatest(answerActions.FETCH_ONE_REQUEST, fetchOne)
  yield takeLatest(answerActions.SAVE_ONE_REQUEST, saveOne)
  yield takeLatest(answerActions.GO_TO_SKILLS, goToSkills)
  yield takeLatest(answerActions.SAVE_ONE_SUCCESS, bot.saga.fetchPublishStatus)
  yield takeLatest(answerActions.UPLOAD_IMAGE_REQUEST, uploadImage)
  yield takeLatest(answerActions.FETCH_ALL_SIMPLIFIED_SKILLS_REQUEST, fetchAllSimplifiedSkills)
  yield takeLatest(answerActions.FETCH_ALL_SIMPLIFIED_TAGS_REQUEST, fetchAllLabelsFromAgent)
}
