import React from 'react'
import { Cell, Pie, PieChart, Tooltip } from 'recharts'

export type PieFormat = ReadonlyArray<{
  name: string,
  value: number,
  color?: string,
  unit?: string
}>

export type Props = Readonly<{
  values: PieFormat
  tooltip?: boolean
}>

const defaultColors = [
  '#3289F6',
  '#dce9fd',
  '#81C99C',
  '#CDF1DB',
  '#DE7792',
  '#F0D3DA'
]

const DashboardPieChart: React.FunctionComponent<Props> = (props) => (
  <PieChart width={200} height={200}>
    <Pie
      data={props.values}
      innerRadius={80}
      outerRadius={100}
      paddingAngle={5}
      dataKey='value'
    >
      {props.values.map((value, index) => (
        <Cell key={`cell-${index}`} fill={value.color || defaultColors[index] || defaultColors[0]} />
      ))}
    </Pie>
    {props.tooltip && <Tooltip formatter={(value, _name, props) => {
      return `${value} ${props.payload.unit || ''}`
    }} />}
  </PieChart>
)

export default DashboardPieChart
