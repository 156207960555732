import React, { FunctionComponent } from 'react'
import { RemoteData } from '@sbizeul/fp-flow'
import * as R from 'ramda'
import { MdClose, MdViewCarousel } from 'react-icons/md'
import * as O from 'fp-ts/lib/Option'

import { Map } from 'helpers/map'
import { ServerFailure } from 'types'
import { Question } from 'modules/question/types'
import { RemoveContentParam, UploadImageRequestPayload } from 'modules/answer/types'

import { CarouselAttachment, CarouselContent } from './types'
import { addCarouselAttachment, removeCarouselAttachment, updateCarouselAttachment, emptyCarouselAttachment } from './models'
import CarouselCard from './CarouselCard'

type Props = Readonly<{
  carouselContent: CarouselContent
  questions: RemoteData<ServerFailure, ReadonlyArray<Question>>
  imagesUrl: Map<RemoteData<ServerFailure, string>>

  fetchQuestions: () => unknown
  removeContent: (params: RemoveContentParam) => unknown
  updateContent: (params: CarouselContent) => unknown
  uploadImage: (params: UploadImageRequestPayload) => unknown
}>

const CarouselContentEditor: FunctionComponent<Props> = (props) => {
  const updateCarouselContent = (fn: (carouselContent: CarouselContent) => CarouselContent) => R.pipe(
    fn,
    props.updateContent
  )(props.carouselContent)

  const handleAddCarouselAttachment = R.compose(updateCarouselContent, addCarouselAttachment, emptyCarouselAttachment)
  const handleUdpdateCarouselContent = R.compose(updateCarouselContent, updateCarouselAttachment)
  const handleRemoveCarouselAttachment = R.compose(updateCarouselContent, removeCarouselAttachment)

  return <div className='CarouselContentEditor--container'>
    <MdViewCarousel size='25' className='CarouselContentEditor-icon--carousel' />
    <MdClose
      size='20'
      className='CarouselContentEditor-icon--delete'
      onClick={() => props.removeContent({ id: R.view(R.lensProp('id'), props.carouselContent) })}
    />
    <div className='CarouselContentEditor-carouselcard--container'>
      {R.map((carouselAttachment: CarouselAttachment) =>
        <CarouselCard
          key={carouselAttachment.id}
          {...props}
          content={carouselAttachment}
          updateContent={handleUdpdateCarouselContent}
          close={props.carouselContent.objectAttachment.length > 1 ? O.some(handleRemoveCarouselAttachment) : O.none}
        />)(props.carouselContent.objectAttachment)}
      {props.carouselContent.objectAttachment.length < 10 && <div onClick={handleAddCarouselAttachment} className='CarouselContentEditor-icon--add'>＋</div>}
    </div>
  </div>
}

export default CarouselContentEditor
