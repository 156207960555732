import { call, put, takeEvery, takeLatest, select } from 'redux-saga/effects'

import { LOCATION_CHANGE } from 'connected-react-router'
import { maybe } from '@sbizeul/fp-flow'

import ApiLegacy from 'services/tolkApiLegacy'
import Api from 'services/tolkApi'
import bot from 'modules/bot'
import synonym from 'modules/synonym'

import * as keywordActions from './actions'
import * as actions from './action.typed'
import { matchSelector, matchSelectKeywordRoute } from './selectors'
import { FetchLinkedKeywordElementRequest, FetchOneRequest, CreateRequest, DeleteKeywordRequest, UpdatePersistKeywordStatusRequest } from './types'

export function* fetchLinkedKeywordElement (action: FetchLinkedKeywordElementRequest) {
  const keywordId = action.payload
  const botId = maybe.get(yield select(bot.selectors.getSelectedId))
  try {
    const { data } = yield call(Api.get, `/bots/${botId}/keywords/${keywordId}/references`)
    yield put(actions.fetchLinkedKeywordElementSuccess(data))
  } catch (error) {
    yield put(actions.fetchLinkedKeywordElementFailure(error))
  }
}

export function* deleteKeyword (action: DeleteKeywordRequest) {
  const keywordId = action.payload
  const botId = maybe.get(yield select(bot.selectors.getSelectedId))
  try {
    const { data } = yield call(Api.delete, `/bots/${botId}/keywords/${keywordId}`)
    yield put(actions.deleteKeywordSuccess(data))
  } catch (error) {
    yield put(actions.deleteKeywordFailure(error))
  }
}

export function* updatePersistKeywordStatus (action: UpdatePersistKeywordStatusRequest) {
  const keywordId = action.payload.id
  const botId = maybe.get(yield select(bot.selectors.getSelectedId))
  try {
    yield call(Api.patch, `/bots/${botId}/keywords/${keywordId}`, { persisted: action.payload.persisted })
    yield put(actions.updatePersistKeywordStatusSuccess())
  } catch (error) {
    yield put(actions.updatePersistKeywordStatusFailure(error))
  }
}

export function* fetchKeywords () {
  const botId = maybe.get(yield select(bot.selectors.getSelectedId))
  try {
    const { data } = yield call(Api.get, `/bots/${botId}/keywords`)
    yield put(keywordActions.fetchKeywordsSuccess(data))
  } catch (e) {
    yield put(keywordActions.fetchKeywordsFailure(e))
  }
}

export function* fetchOne (action: FetchOneRequest) {
  const keywordId = action.payload
  yield put(synonym.actions.fetchSynonymsRequest(keywordId))
  const botId = maybe.get(yield select(bot.selectors.getSelectedId))
  try {
    const { data } = yield call(Api.get, `/bots/${botId}/keywords/${keywordId}`)
    yield put(keywordActions.fetchOneSuccess(data))
  } catch (e) {
    yield put(keywordActions.fetchOneFailure(e))
  }
}

export function* create (action: CreateRequest) {
  const botId = maybe.get(yield select(bot.selectors.getSelectedId))
  try {
    const url = `/bot/${botId}/keywords`
    const { data } = yield call(ApiLegacy.post, url, action.payload)
    yield put(keywordActions.createSuccess(data))
  } catch (error) {
    yield put(keywordActions.createFailure(error))
  }
}

export function* closeCreateForm () {
  yield put(keywordActions.closeCreateForm())
}

export function* selectBot () {
  const route = yield select(matchSelector)
  if (route !== null) {
    yield put(keywordActions.fetchKeywordsRequest())
  }
}

export function* matchRoute () {
  const matchSelectedKeyword = yield select(matchSelectKeywordRoute)
  if (matchSelectedKeyword != null) {
    yield put(keywordActions.selectKeyword(matchSelectedKeyword.params.id))
  } else {
    yield put(keywordActions.unselectKeyword())
  }
}

export function* root () {
  yield takeEvery(LOCATION_CHANGE, matchRoute)
  yield takeLatest(actions.FETCH_LINKED_KEYWORD_ELEMENT_REQUEST, fetchLinkedKeywordElement)
  yield takeLatest(actions.DELETE_KEYWORD_REQUEST, deleteKeyword)
  yield takeLatest(actions.DELETE_KEYWORD_SUCCESS, fetchKeywords)
  yield takeLatest(actions.DELETE_KEYWORD_SUCCESS, bot.saga.fetchPublishStatus)
  yield takeLatest(actions.UPDATE_PERSIST_KEYWORD_REQUEST, updatePersistKeywordStatus)
  yield takeLatest(actions.UPDATE_PERSIST_KEYWORD_SUCCESS, fetchKeywords)
  yield takeLatest(actions.UPDATE_PERSIST_KEYWORD_SUCCESS, bot.saga.fetchPublishStatus)
  yield takeLatest(keywordActions.FETCH_KEYWORDS_REQUEST, fetchKeywords)
  yield takeLatest(keywordActions.FETCH_ONE_REQUEST, fetchOne)
  yield takeLatest(keywordActions.CREATE_REQUEST, create)
  yield takeLatest(keywordActions.CREATE_SUCCESS, fetchKeywords)
  yield takeLatest(keywordActions.CREATE_SUCCESS, closeCreateForm)
  yield takeLatest(keywordActions.CREATE_SUCCESS, bot.saga.fetchPublishStatus)
  yield takeLatest(bot.actions.SELECT, selectBot)
}
