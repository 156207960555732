import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import _ from 'lodash'
import { Form } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'

import intentsOperations from '../../../../redux/intents/operations'
import InputField from '../../../components/InputFieldGroup'
import Select from '../../../../components/Select'
import './AddAnswerForm.css'

const required = value => (value ? undefined : 'Required')
const noEmpty = value => (_.trim(value) ? undefined : 'No Empty')

class AddAnswerForm extends React.Component {
  state = {
    selectedType: 'nothing'
  }

  createCustomButton() {
    return this.props.history.push(`/answers/${this.props.intentName}/create`)
  }

  renderCreateText() {
    return (
      <Form onSubmit={this.props.handleSubmit(this.props.putIntent)}>
        <Form.Group>
          <Field
            validate={[required, noEmpty]}
            name='answer'
            label='Add an answer'
            component={InputField}
            type='text'
          />
          <Form.Button
            style={{ color: '#F9F9F9', backgroundColor: '#3B86FF', fontFamily: 'Roboto', fontWeight: 'lighter' }}
            content='Train'
            type='submit'
            disabled={this.props.pristine || this.props.submitting}
          />
        </Form.Group>
      </Form>
    )
  }

  handleSelect = ({ value }) => {
    this.setState({ selectedAnswerType: value }, () => {
      if (this.state.selectedAnswerType === 'custom') {
        this.createCustomButton()
      }
    })
  }

  getOptions() {
    return [
      { value: 'text', text: 'Add random answer' },
      { value: 'custom', text: 'Create custom answer' }
    ]
  }

  render() {
    return (
      <div className='AddAnswerForm'>
        <Select
          className='AddAnswerForm-select'
          onChange={this.handleSelect}
          options={this.getOptions()}
          placeholder='Select answer type'
          value={this.state.selectedAnswerType}
        />
        {(this.state.selectedAnswerType === 'text' || this.props.isRandom) && this.renderCreateText()}
      </div>
    )
  }
}

AddAnswerForm.propTypes = {
  putIntent: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired
}

const withRedux = connect(null, { putIntent: intentsOperations.putIntent })

export default compose(
  withRedux,
  reduxForm({
    touchOnBlur: false,
    enableReinitialize: true,
    form: 'AddAnswerToIntent'
  }),
  withRouter
)(AddAnswerForm)
