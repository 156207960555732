
import React from 'react'
import { Link } from 'react-router-dom'

import { BASE_SKILL_LABELS, FALLBACK } from '../../types'

import * as routes from '../../routes'

const FallbackButton = () => (
  <Link className='skill-default--button skill-fallback-button' to={routes.skillRoute(FALLBACK)}>
    <p className='skill-default--label'>{BASE_SKILL_LABELS[FALLBACK]}</p>
  </Link>
)

export default FallbackButton
