export const ALL_SKILLS = '/skills'

export const SKILL_BUILDER = '/skill/:skillBuilderId'
export const SKILL_FORMS = '/skill-form/:formId'

export const SKILLS = `${ALL_SKILLS}/:name`

export const skillBuilderRoutes = (skillBuilderId: string) => SKILL_BUILDER.replace(':skillBuilderId', skillBuilderId)
export const skillFormsRoutes = (formId: string) => SKILL_FORMS.replace(':formId', formId)

export const skillRoute = (name: string) => SKILLS.replace(':name', name)
