import axios from 'axios'
import { maybe } from '@sbizeul/fp-flow'

import { legacyApiUrl } from 'helpers/effects'

import actions from '../actions'
import isResValid from '../../../_shared/isResValid'
import formatFilters from '../../../_shared/formatFilters'
import bot from '../../../../modules/bot'

const fetchDelaysPreviousPeriod = rawFilters => (dispatch, getState) => {
  const { channelId, start, end, period } = formatFilters(rawFilters, true)
  const botId = bot.selectors.getSelectedId(getState())

  dispatch(actions.fetchDelaysPreviousPeriodRequest())
  return axios({
    url: `${legacyApiUrl}/analytics/${maybe.get(botId)}/${channelId}/delays`,
    params: { start, end, period },
    method: 'get',
    responseType: 'json'
  })
    .then(response => {
      if (isResValid(response)) {
        const formatedResponse = {
          count: response.data.result.count
        }
        dispatch(actions.fetchDelaysPreviousPeriodSuccess(formatedResponse))
      } else {
        dispatch(actions.fetchDelaysPreviousPeriodFailure())
      }
    })
    .catch(err => {
      dispatch(actions.fetchDelaysPreviousPeriodFailure(err))
    })
}

export default fetchDelaysPreviousPeriod
