import React from 'react'
import { remoteData, RemoteData } from '@sbizeul/fp-flow'
import { renderLoader, renderServerFailure } from 'helpers/react'
import { ServerFailure } from 'types'

import { RegisterState } from '../../types'
import Register from './Register'

type Props = {
  register: (inputs: RegisterState) => unknown,
  registerState: RemoteData<ServerFailure, string>
}

const RemoteRegister = ({ register, registerState }: Props) => remoteData.fold({
  Success: () => <div className='RemoteRegister-success-container'>
    <p className='RemoteRegister-tada-title'>Success! <span role='img' aria-label='fire'>🎉</span></p>
    <p className='RemoteRegister-tada-text'>You’re on the list, watch your inbox we’ll get back to you ASAP. </p>
  </div>,
  NotAsked: () => <Register register={register} />,
  Loading: () => renderLoader('Please wait...'),
  Failure: renderServerFailure
})(registerState)

export default RemoteRegister
