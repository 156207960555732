import { fromJS } from 'immutable'

const initialState = fromJS({
  conversationsList: {
    isFetching: false,
    data: []
  },
  messages: {
    isFetching: false,
    data: []
  },
  activeConversation: ''
})

export default initialState
