import 'react-dates/initialize'
import React from 'react'
import { Container, Grid } from 'semantic-ui-react'
import { FaEdit, FaTrash } from 'react-icons/fa'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { maybe, either } from '@sbizeul/fp-flow'

import ExamplesTable from './ExamplesTable'
import AnswersTable from './AnswersTable'
import AddExampleForm from './AddExampleForm'
import AddAnswerForm from './AddAnswerForm'
import answer from '../../../../modules/answer_old'
import Button from '../../../../components/Button'
import { Loader } from '../../../../components/Loading'
import bot from '../../../../modules/bot'
import train from '../../../../modules/train'
import './TrainingPanel.css'

const renderAnswers = (intent, botId, language) => {
  return (
    <AnswersTable
      intentName={intent.name}
      description={intent.description}
      answers={intent.answers}
      botId={botId}
      intentSlug={intent.slug}
      language={language}
    />
  )
}

// FIXME: Temporary solution
const isRandom = answers => answers.every(a => typeof a === 'string')

const TrainingPanel = ({ botId, intentData, language, history, deleteAnswers, nlpEngine, publish, isPublishing }) => {
  const editAnswers = intentSlug => {
    history.push(`/answers/${intentSlug}/edit`)
  }

  const renderCustom = (intent, botId, language) => {
    return (
      <div className='TrainingPanel-customAnswer'>
        <div className='TrainingPanel-customAnswer--msg'>A custom answer is associated with this intent</div>
        <div className='TrainingPanel-customAnswer--actions'>
          <FaEdit className='TrainingPanel-customAnswer--actions---edit' onClick={() => editAnswers(intent.slug)} />
          <FaTrash className='TrainingPanel-customAnswer--actions---trash' onClick={() => deleteAnswers(intent.slug)} />
        </div>
      </div>
    )
  }

  const isLuis = nlp => nlp === 'luis'

  const renderPublishButton = () => {
    return (
      <Grid.Row>
        <div className='TrainingPanel-publish'>
          <Button className='TrainingPanel-publish--button' onClick={publish}>
            Publish
          </Button>
        </div>
      </Grid.Row>
    )
  }

  const renderContent = () => {
    return (
      <Grid>
        {maybe.fold(
          () => null,
          nlp => isLuis(nlp) && renderPublishButton(),
          nlpEngine
        )}
        <Grid.Column width={8}>
          <Container>
            <AddExampleForm
              initialValues={{
                botId,
                intentSlug: intentData.slug,
                language
              }}
              intentCount={intentData.examples.length}
            />
            {!!intentData.examples.length && (
              <ExamplesTable
                data={intentData.examples}
                botId={botId}
                intentSlug={intentData.slug}
                language={language}
              />
            )}
          </Container>
        </Grid.Column>
        <Grid.Column width={8}>
          <AddAnswerForm
            initialValues={{
              botId,
              intentName: intentData.slug,
              description: intentData.description,
              language,
              answer: '',
              intentSlug: intentData.slug
            }}
            intentName={intentData.slug}
            isRandom={isRandom(intentData.answers)}
          />
          {isRandom(intentData.answers) ? renderAnswers(intentData, botId, language) : renderCustom(intentData)}
        </Grid.Column>
      </Grid>
    )
  }

  const renderFailure = error => {
    return (
      <div className='TrainingPanel-failure'>
        {error.message}
        <Button onClick={publish} className='TrainingPanel-publish--button'>
          Retry?
        </Button>
      </div>
    )
  }

  const match = isPublishing => {
    if (isPublishing) {
      return <Loader description='Publishing, please wait...' className='TrainingPanel-loading' />
    }
    return renderContent()
  }

  return either.fold(renderFailure, match, isPublishing)
}

const mapStateToProps = state => ({
  nlpEngine: bot.selectors.getNlpEngine(state),
  isPublishing: train.selectors.getIsPublishing(state)
})

const mapDispatchToProps = {
  deleteAnswers: answer.actions.deleteAnswers,
  publish: train.actions.publish
}

const withRedux = connect(mapStateToProps, mapDispatchToProps)

export default compose(withRouter, withRedux)(TrainingPanel)
