import React from 'react'
import classNames from 'classnames'
import { Loader } from 'semantic-ui-react'

import './styles.css'

function InlineLoader({ description, className }) {
  return (
    <Loader active inline size='small' data-testid='loader' className={classNames(className, 'Loading-loader-inline')}>
      {description}
    </Loader>
  )
}

export default InlineLoader
