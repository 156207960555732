const FETCH_CONVERSATIONS_REQUEST = 'tcfdash/analytics/conversations/FETCH_REQUEST'
const FETCH_CONVERSATIONS_SUCCESS = 'tcfdash/analytics/conversations/FETCH_SUCCESS'
const FETCH_CONVERSATIONS_NO_DATA = 'tcfdash/analytics/conversations/FETCH_NO_DATA'

const FETCH_CONVERSATIONS_PREVIOUS_PERIOD_REQUEST = 'tcfdash/analytics/conversations/FETCH_PREVIOUS_PERIOD_REQUEST'
const FETCH_CONVERSATIONS_PREVIOUS_PERIOD_SUCCESS = 'tcfdash/analytics/conversations/FETCH_PREVIOUS_PERIOD_SUCCESS'
const FETCH_CONVERSATIONS_PREVIOUS_PERIOD_NO_DATA = 'tcfdash/analytics/conversations/FETCH_PREVIOUS_PERIOD_NO_DATA'

const FETCH_CONVERSATIONS_COUNT_BY_HOUR_REQUEST = 'tcfdash/analytics/conversations/FETCH_COUNT_BY_HOUR_REQUEST'
const FETCH_CONVERSATIONS_COUNT_BY_HOUR_SUCCESS = 'tcfdash/analytics/conversations/FETCH_COUNT_BY_HOUR_SUCCESS'
const FETCH_CONVERSATIONS_COUNT_BY_HOUR_NO_DATA = 'tcfdash/analytics/conversations/FETCH_COUNT_BY_HOUR_NO_DATA'

const FETCH_CONVERSATIONS_COUNT_BY_DAY_REQUEST = 'analytics/conversations/FETCH_COUNT_BY_DAY_REQUEST'
const FETCH_CONVERSATIONS_COUNT_BY_DAY_SUCCESS = 'analytics/conversations/FETCH_COUNT_BY_DAY_SUCCESS'
const FETCH_CONVERSATIONS_COUNT_BY_DAY_NO_DATA = 'analytics/conversations/FETCH_COUNT_BY_DAY_NO_DATA'

export default {
  FETCH_CONVERSATIONS_REQUEST,
  FETCH_CONVERSATIONS_SUCCESS,
  FETCH_CONVERSATIONS_NO_DATA,
  FETCH_CONVERSATIONS_PREVIOUS_PERIOD_REQUEST,
  FETCH_CONVERSATIONS_PREVIOUS_PERIOD_SUCCESS,
  FETCH_CONVERSATIONS_PREVIOUS_PERIOD_NO_DATA,
  FETCH_CONVERSATIONS_COUNT_BY_HOUR_REQUEST,
  FETCH_CONVERSATIONS_COUNT_BY_HOUR_SUCCESS,
  FETCH_CONVERSATIONS_COUNT_BY_HOUR_NO_DATA,
  FETCH_CONVERSATIONS_COUNT_BY_DAY_REQUEST,
  FETCH_CONVERSATIONS_COUNT_BY_DAY_SUCCESS,
  FETCH_CONVERSATIONS_COUNT_BY_DAY_NO_DATA
}
