import React from 'react'
import { WithStore } from 'pure-react-carousel'
import {
  Container,
  StyledCarouselProvider,
  StyledSlider,
  Picture,
  OpaqueMask,
  DotGroupStyled,
  WelcomeMessage
} from './styles'
import { ModaleStyled } from '../Modale'
import { Onboarding1, Onboarding2, Onboarding3, Onboarding4 } from '../../icons'
import NewCampaign from '../NewCampaign'

const Content = ({ title, message, visible }) => (
  <WelcomeMessage visible={visible}>
    <span>{title}</span>
    <p dangerouslySetInnerHTML={{ __html: message.join('<br />') }} />
  </WelcomeMessage>
)

class SliderForState extends React.Component {
  render() {
    const { currentSlide } = this.props
    return (
      <React.Fragment>
        <StyledSlider>
          <Picture />
          <Picture src={Onboarding1}></Picture>
          <Picture src={Onboarding2}></Picture>
          <Picture src={Onboarding3}></Picture>
          <Picture src={Onboarding4}></Picture>
          <Picture />
        </StyledSlider>
        <OpaqueMask right />
        <OpaqueMask left />
        <Content
          title='Hey there 👋'
          visible={currentSlide === 0}
          message={[
            'Ready to broadcast cool things to your audience?',
            'Create a new campaign or follow the steps to learn how to broadcast messages to your audience.'
          ]}
        />
        <Content
          title='Craft your story'
          visible={currentSlide === 1}
          message={[
            'Create a campaign with rich messages templates to enhance what you have to say.',
            'Simple text, carrousel, button, pictures or video. You choose.'
          ]}
        />
        <Content
          title='Target the right user'
          visible={currentSlide === 2}
          message={['Get better user engagement with a personalized message to the right audience.']}
        />
        <Content
          title='And send it!'
          visible={currentSlide === 3}
          message={[
            'Timing is key.',
            'Avoid spamming by sending your campaign at the perfect time considering the user’s timezone.'
          ]}
        />
        <DotGroupStyled showAsSelectedForCurrentSlideOnly disableActiveDots={false} />
      </React.Fragment>
    )
  }
}

const SliderWithState = WithStore(SliderForState, state => ({
  currentSlide: state.currentSlide
}))

class Onboarding extends React.Component {
  render() {
    const { createNewCampaignRequest } = this.props

    return (
      <Container>
        <ModaleStyled isVisible>
          <StyledCarouselProvider
            naturalSlideWidth={150}
            naturalSlideHeight={120}
            totalSlides={6}
            visibleSlides={3}
            orientation='horizontal'
          >
            <SliderWithState />
          </StyledCarouselProvider>
          <NewCampaign colored onClick={() => createNewCampaignRequest()} />
        </ModaleStyled>
      </Container>
    )
  }
}

export default Onboarding
