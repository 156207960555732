import types from './types'

const setFetchError = err => ({
  type: types.SET_FETCH_ERROR,
  payload: err
})

const removeFetchError = () => ({
  type: types.REMOVE_FETCH_ERROR
})

const setLoading = message => ({
  type: types.SET_LOADING,
  message
})

const removeLoading = () => ({
  type: types.REMOVE_LOADING
})

export default {
  setFetchError,
  removeFetchError,
  setLoading,
  removeLoading
}
