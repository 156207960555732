import fetchAnalyticsAll from './fetchAnalyticsAll'
import fetchAnalyticsTrafficMessages from './fetchAnalyticsTrafficMessages'
import fetchAnalyticsTrafficUsers from './fetchAnalyticsTrafficUsers'
import fetchAnalyticsPerformanceDelays from './fetchAnalyticsPerformanceDelays'
import fetchAnalyticsPerformanceRetention from './fetchAnalyticsPerformanceRetention'
import fetchAnalyticsPerformanceUnderstanding from './fetchAnalyticsPerformanceUnderstanding'
import fetchTrends from './fetchTrends'
import fetchBehaviours from './fetchBehaviours'
import fetchEvents from './fetchEvents'

export default {
  fetchAnalyticsAll,
  fetchAnalyticsTrafficMessages,
  fetchAnalyticsTrafficUsers,
  fetchTrends,
  fetchBehaviours,
  fetchAnalyticsPerformanceDelays,
  fetchAnalyticsPerformanceRetention,
  fetchAnalyticsPerformanceUnderstanding,
  fetchEvents
}
