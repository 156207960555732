import styled from 'styled-components'

const DatePickerContainerStyled = styled.div`
  position: relative;
  .SingleDatePicker_1 {
    position: absolute;
  }
  .DateInput_1 {
    opacity: 0;
  }
`

export default DatePickerContainerStyled
