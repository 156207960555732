import React from 'react'
import { Route, Switch } from 'react-router-dom'

import AddRessources from './Add'
import MyRessources from './MyRessources'

const RessourcesPage = () => (
  <Switch>
    <Route exact path='/ressources' component={MyRessources} />
    <Route path='/ressources/add' component={AddRessources} />
  </Switch>
)

export default RessourcesPage
