import React from 'react'
import { Grid, Segment } from 'semantic-ui-react'
import { getVariation } from '../../utils'

import '../index.css'
import NoDataSegment from '../../components/NoDataSegment'
import Tiles from '../../components/Tiles'
import GenderPieChart from '../../components/PieCharts/GenderHighcharts'
import UsersPieChart from '../../components/PieCharts/NewAndRecUsersHighcharts'
import AllUsers from '../../components/AreaCharts/AllUsersHighcharts'

const style = {
  borderRadius: '3px',
  border: 'none',
  boxShadow: '0 2px 4px rgba(50, 50, 93,.1)',
  padding: '5px'
}

const Users = ({
  conversations,
  fetchingConversations,

  messagesPerUser,
  fetchingMessages,
  fetchingUsers,
  messagesPerUserPrevPeriod,
  fetchingMessagesPrev,
  fetchingUsersPrev,

  usersGender,
  fetchingUsersGender,

  overallUsersCount,
  usersData,
  usersCount,
  usersCountPrevPeriod,

  newUsers,
  fetchingNewUsers,
  newUsersCount,

  comparisonDateRange,
  period
}) => (
  <div>
    <Grid>
      <Grid.Row columns={3}>
        <Grid.Column>
          <Tiles
            name='Messages per users'
            value={messagesPerUser.toPrecision(2)}
            comparison={{
              variation: getVariation(messagesPerUserPrevPeriod, messagesPerUser),
              prevValue: messagesPerUserPrevPeriod.toPrecision(2),
              comparisonDateRange
            }}
            moreInfo='Average number of messages sent by user'
            loading={fetchingMessages}
          />
        </Grid.Column>
        <Grid.Column>
          <Tiles
            name='Conversations per user'
            value={Math.round((conversations / usersCount) * 10) / 10}
            moreInfo='Average number of conversations per user. A conversation is ended by 15 min of inactivity.'
            loading={fetchingConversations || fetchingUsers}
          />
        </Grid.Column>
        <Grid.Column>
          <Tiles
            name='Total users'
            value={usersCount}
            comparison={{
              variation: Math.round((usersCount / usersCountPrevPeriod - 1) * 100),
              comparisonDateRange
            }}
            moreInfo={`Users who interacted with the bot at least one time (over ${overallUsersCount} users all periods combined)`}
            loading={fetchingUsers || fetchingUsersPrev}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
    <Grid columns={2}>
      <Grid.Row>
        <Grid.Column>
          <h1>Gender</h1>
          {usersGender ? (
            <Segment style={style}>
              <GenderPieChart data={usersGender} />
            </Segment>
          ) : (
            <NoDataSegment />
          )}
        </Grid.Column>
        <Grid.Column>
          <h1>Users split</h1>
          {newUsersCount && usersCount ? (
            <Segment style={style}>
              <UsersPieChart data={{ new: newUsersCount, all: usersCount }} />
            </Segment>
          ) : (
            <NoDataSegment />
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
    <h1>Lifetime Users</h1>
    {usersData || newUsers ? (
      <Segment style={style}>
        <AllUsers totalUsers={usersCount} allUsers={usersData} newUsers={newUsers} period={period} />
      </Segment>
    ) : (
      <NoDataSegment />
    )}
  </div>
)

export default Users
