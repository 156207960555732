
import React from 'react'
import classNames from 'classnames'
import { FaFrown } from 'react-icons/fa'

import './styles.css'

function Empty({ description, className }) {
  return (
    <div className={classNames('Loading-Empty', className)}>
      {description}
      <p>
        <FaFrown size='2em' />
      </p>
    </div>
  )
}

export default Empty
