import { curry } from 'ramda'
import { remoteData, pipe } from '@sbizeul/fp-flow'

const getEntitiesState = state => state.entities.synonym_old

const getByEntityId = curry((state, entityId) =>
  pipe(
    remoteData.fromNullable,
    remoteData.getOrElse(remoteData.notAsked)
  )(state.entities.synonym_old.byEntityId[entityId])
)

export { getEntitiesState, getByEntityId }
