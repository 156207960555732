import types from './types'

const fetchIntentsRequest = () => ({
  type: types.FETCH_INTENTS_REQUEST
})
const fetchIntentsSuccess = payload => ({
  type: types.FETCH_INTENTS_SUCCESS,
  payload
})
const fetchIntentsFailure = errors => ({
  type: types.FETCH_INTENTS_NO_DATA,
  errors
})

const fetchIntentsPreviousPeriodRequest = () => ({
  type: types.FETCH_INTENTS_PREVIOUS_PERIOD_REQUEST
})
const fetchIntentsPreviousPeriodSuccess = payload => ({
  type: types.FETCH_INTENTS_PREVIOUS_PERIOD_SUCCESS,
  payload
})
const fetchIntentsPreviousPeriodFailure = errors => ({
  type: types.FETCH_INTENTS_PREVIOUS_PERIOD_NO_DATA,
  errors
})

const fetchIntentsOverTimeRequest = () => ({
  type: types.FETCH_INTENTS_OVER_TIME_REQUEST
})
const fetchIntentsOverTimeSuccess = payload => ({
  type: types.FETCH_INTENTS_OVER_TIME_SUCCESS,
  payload
})
const fetchIntentsOverTimeFailure = errors => ({
  type: types.FETCH_INTENTS_OVER_TIME_NO_DATA,
  errors
})

export default {
  fetchIntentsRequest,
  fetchIntentsSuccess,
  fetchIntentsFailure,
  fetchIntentsPreviousPeriodRequest,
  fetchIntentsPreviousPeriodSuccess,
  fetchIntentsPreviousPeriodFailure,
  fetchIntentsOverTimeRequest,
  fetchIntentsOverTimeSuccess,
  fetchIntentsOverTimeFailure
}
