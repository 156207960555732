import React from 'react'
import { Button, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

const NewUserButton = () => (
  <Link to={'/admin/user/new'}>
    <Button inverted size='small' color='green'>
      <Icon name='add' />
      New user
    </Button>
  </Link>
)

export default NewUserButton
