import React from 'react'
import { Modal } from 'semantic-ui-react'
import { remoteData } from '@sbizeul/fp-flow'
import * as R from 'ramda'

import { Loader } from 'components/Loading'
import { renderFailure } from 'helpers/react'
import { restoreScroll, withScrollManager } from 'helpers/scroll-manager'

import CreateKeywordForm from '../CreateKeywordForm'
import CreateKeywordButton from './CreateKeywordButton'
import Keywords from './Keywords'

import './styles.css'

class RemoteKeywords extends React.PureComponent {
  handleOpen = this.props.openCreateForm

  handleClose = this.props.closeCreateForm

  componentDidMount() {
    this.props.fetchKeywords()
  }

  render() {
    const { keywords } = this.props
    return remoteData.fold(
      {
        Success: R.compose(restoreScroll(this.props), this.renderKeywords(this.handleOpen, this.props)),
        NotAsked: renderLoading,
        Loading: renderLoading,
        Failure: renderFailure
      },
      keywords
    )
  }

  renderKeywords = (openCreateForm) => keywords => {
    return (
      <React.Fragment>
        <div className='Keywords-actionBar'>{this.addCreateKeywordFormModal()}</div>
        <Keywords {...this.props} keywords={keywords} openCreateForm={openCreateForm} />
      </React.Fragment>
    )
  }

  addCreateKeywordFormModal() {
    const { createKeyword, isCreating, isOpenCreateForm } = this.props
    return (
      <Modal
        open={isOpenCreateForm}
        closeOnEscape={true}
        closeOnDimmerClick={true}
        onClose={this.handleClose}
        trigger={
          <CreateKeywordButton onClick={this.handleOpen} />
        }
      >
        <Modal.Header>
          <p className='Keywords-modal-title'>Create a keyword</p>
        </Modal.Header>
        <Modal.Content>
          <CreateKeywordForm createKeyword={createKeyword} isCreating={isCreating} onClose={this.handleClose} />
        </Modal.Content>
      </Modal>
    )
  }
}

function renderLoading() {
  return <Loader description='Fetching keywords' />
}

export default withScrollManager(RemoteKeywords)
