import { maybe } from '@sbizeul/fp-flow'

export const placeholder1 = a => maybe.nothing()

export const placeholder2 = (a, b) => maybe.nothing()

export const placeholder3 = (a, b, c) => maybe.nothing()

export const placeholder4 = (a, b, c, d) => maybe.nothing()

export const placeholder5 = (a, b, c, d, e) => maybe.nothing()
