import React from 'react'
import { Grid, Segment } from 'semantic-ui-react'
import { getVariation } from '../../utils'
import Tiles from '../../components/Tiles'
import NoDataSegment from '../../components/NoDataSegment'
import MessagesAndDelays from '../../components/AreaCharts/MessagesAndDelaysHighcharts'
import MoreInfoPopup from '../../../../components/MoreInfoPopup'
import text from '../../../../../config/translations/en.json'

const style = {
  borderRadius: '3px',
  border: 'none',
  boxShadow: '0 2px 4px rgba(50, 50, 93,.1)',
  padding: '5px'
}

const Delays = ({
  delaysAverage,
  delaysAveragePrevPeriod,
  comparisonDateRange,
  messages,
  delays,
  fetchingDelays,
  noMessagesData,
  noDelaysData,
  period
}) => (
  <div style={{ fontFamily: 'Roboto' }}>
    <br />
    <Grid>
      <Grid.Row>
        <Grid.Column mobile={8} tablet={4} computer={4} widescreen={4}>
          {comparisonDateRange ? (
            <Tiles
              name='Delay in replying'
              suffixe='s'
              value={delaysAverage}
              comparison={{
                variation: getVariation(delaysAveragePrevPeriod, delaysAverage),
                prevValue: `${delaysAveragePrevPeriod}s`,
                comparisonDateRange,
                increaseIsGood: 0
              }}
              noData={noDelaysData}
              moreInfo='Average delay in replying'
              loading={fetchingDelays}
            />
          ) : (
            ''
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
    <h1>
      Scalability <MoreInfoPopup content={text.bot.scalability} />
    </h1>
    {messages ? (
      <Segment style={style}>
        <MessagesAndDelays data={messages} delay={delays} period={period} />
      </Segment>
    ) : (
      <NoDataSegment />
    )}
  </div>
)

export default Delays
