import React from 'react'
import { Moment } from 'moment'
import * as R from 'ramda'
import { FaBookmark } from 'react-icons/fa'
import { RemoteData, remoteData } from '@sbizeul/fp-flow'

import DateRangeComponent from 'components/DateRangePicker'
import { groupAndSumBy } from 'helpers/object'
import { renderFailure, renderLoader, useEffectOnce, renderNothing } from 'helpers/react'
import { ServerFailure } from 'types'

import { ChangeDatesParams, WebchatStats, WebchatStat } from '../../types'

import './styles.css'

export type Props = Readonly<{
  webchatStats: RemoteData<ServerFailure, WebchatStats>
  startDate: Moment
  endDate: Moment

  fetchWebchatStats: () => unknown
  onDatesChange: (newDate: ChangeDatesParams) => unknown
}>

const webchatStatsOverall = (webchatStats: WebchatStats) => {
  const overallStatWithoutUrl = webchatStats.map(R.dissoc('url'))
  const computeOverAllStats = groupAndSumBy(R.keys(overallStatWithoutUrl[0]), webchatStats)

  return R.assoc('url', 'Overall', computeOverAllStats)
}

const isOverall = (webchatStat: WebchatStat) => webchatStat.url === 'Overall'

const renderWebchatStatsRow = (webchatStat: WebchatStat) =>
  <tr className={`WebchatOverview-tr ${isOverall(webchatStat) && 'WebchatOverview-tr-higthlight'}`}>
    <th className='WebchatOverview-table-th WebchatOverview-url' scope='row'><a className='WebchatOverview-table-th-link' href={`${webchatStat.url}`} target='_blank'>{webchatStat.url}</a></th>
    <td className='WebchatOverview-table-td'>{webchatStat.nb_global}</td>
    <td className='WebchatOverview-table-td'>
      <div className='WebchatOverview-values-container'>
        <div>{webchatStat.preonboarding_open}</div>
        <div>{webchatStat.preonboarding_close}</div>
      </div>
    </td>
    <td className='WebchatOverview-table-td'>
      <div className='WebchatOverview-values-container'>
        <div>{webchatStat.avatar_open}</div>
        <div>{webchatStat.chat_close}</div>
      </div>
    </td>
  </tr>

const renderWebchatOverallStatsRow = (webchatStat: WebchatStat) =>
  <tr className={`WebchatOverview-tr ${isOverall(webchatStat) && 'WebchatOverview-tr-higthlight'}`}>
    <th className='WebchatOverview-table-th WebchatOverview-url' scope='row'><FaBookmark size='15' /><span className='WebchatOverview-url-text'>{webchatStat.url}</span></th>
    <td className='WebchatOverview-table-td'><span className='WebchatOverview-overall-display'>{webchatStat.nb_global}</span></td>
    <td className='WebchatOverview-table-td'>
      <div className='WebchatOverview-double-container'>
        <div>
          <p>Open</p>
          {webchatStat.preonboarding_open}
        </div>
        <div>
          <p>Close</p>
          {webchatStat.preonboarding_close}
        </div>
      </div>
    </td>
    <td className='WebchatOverview-table-td'>
      <div className='WebchatOverview-double-container'>
        <div>
          <p>Open</p>
          {webchatStat.avatar_open}
        </div>
        <div>
          <p>Close</p>
          {webchatStat.chat_close}
        </div>
      </div>
    </td>
  </tr>

const renderWebchatTable = (webchatStats: WebchatStats) =>
<table className='WebchatOverview-table'>
  <thead>
    <tr className='WebchatOverview-tr--title'>
      <th className='WebchatOverview-th-col WebchatOverview-th--url' scope='col'>
        <p className='WebchatOverview-th-col-title'>
          URL
        </p>
      </th>
      <th className='WebchatOverview-th-col WebchatOverview-table-th' scope='col'>
        <p className='WebchatOverview-th-col-title'>
          Display
        </p>
      </th>
      <th className='WebchatOverview-th-col WebchatOverview-table-th' scope='col'>
        <div className='WebchatOverview-title-image-container'>
          <img className='WebchatOverview-preonboarding-img' src='/img/preonboarding.svg' alt='chat windows icon' />
          <p>
            Pré-onboarding
          </p>
        </div>
      </th>
      <th className='WebchatOverview-th-col WebchatOverview-table-th' scope='col'>
        <div className='WebchatOverview-title-image-container'>
          <img className='WebchatOverview-chatwindow-img' src='/img/chatwindow.svg' alt='chat windows icon' />
          <p>
            Chat window
          </p>
        </div>
      </th>
    </tr>
  </thead>
  <tbody className='WebchatOverview-tbody'>
    {renderWebchatOverallStatsRow(webchatStatsOverall(webchatStats))}
    {webchatStats.map(renderWebchatStatsRow)}
  </tbody>
</table>

const WebchatOverview: React.FunctionComponent<Props> = (props) => {
  useEffectOnce(() => { props.fetchWebchatStats() })

  return <div className='WebchatOverview-container'>
    <div className='WebchatOverview-header-container'>
      <h1 className='WebchatOverview-header-container-title'>Webchat overview</h1>
      <DateRangeComponent startDate={props.startDate} endDate={props.endDate} onDatesChange={props.onDatesChange} />
    </div>
    <div className='WebchatOverview-content-container'>
      <h2 className='WebchatOverview-content-container-title'>Source traffic</h2>
      {remoteData.fold({
        Success: (webchatStats) => renderWebchatTable(webchatStats),
        Failure: renderFailure,
        Loading: renderLoader,
        NotAsked: renderNothing
      }, props.webchatStats)}
    </div>
  </div>
}

export default WebchatOverview
