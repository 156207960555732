import { remoteData, maybe, either } from '@sbizeul/fp-flow'
import { dec, inc, lensProp, over, pipe, set } from 'ramda'

import * as convActions from './actions'

import { defaultEndDate, sortByLastInRange, defaultStartDate } from './models'
import {
  ChangeDates,
  ChangeNumberConversationsPerPage,
  ChangeSearchValue,
  ConversationAction,
  ConversationState,
  DownloadCSVFailure,
  FetchConversationsFailure,
  FetchConversationsSuccess,
  Select
} from './types'

export const initialState: ConversationState = {
  startDate: defaultStartDate(),
  endDate: defaultEndDate(),
  channelID: maybe.nothing(),
  lang: 'fr_FR',
  nbResultsPerPage: 15,
  page: 0,
  conversations: remoteData.notAsked(),
  selectedID: maybe.nothing(),
  searchValue: '',
  isDownloadingCSV: either.of(false)
}

export const conversations = lensProp('conversations')
export const selectedID = lensProp('selectedID')
export const startDate = lensProp('startDate')
export const endDate = lensProp('endDate')
export const page = lensProp('page')
export const searchValue = lensProp('searchValue')
export const nbResultsPerPage = lensProp('nbResultsPerPage')
export const isDownloadingCSV = lensProp('isDownloadingCSV')

const conversationsRequest = over(conversations, remoteData.loading)

const conversationsFailure = (state: ConversationState, action: FetchConversationsFailure) =>
  set(conversations, remoteData.failure(action.payload), state)

const conversationsSuccess = (state: ConversationState, action: FetchConversationsSuccess) => {
  // @ts-ignore Fix issue with @sbizeul/fp-flow
  const sortedConversations = sortByLastInRange(state.endDate.toDate())(action.payload)
  return pipe(
    set(conversations, remoteData.success(sortedConversations)),
    set(
      selectedID,
      pipe(
        maybe.fromNullable,
        maybe.map(conv => conv.id)
        // @ts-ignore Fix issue with @sbizeul/fp-flow
      )(sortedConversations[0])
    )
  )(state)
}

const changeDates = (state: ConversationState, action: ChangeDates) =>
  pipe(
    set(startDate, action.payload.startDate),
    set(endDate, action.payload.endDate),
    set(conversations, remoteData.loading()),
    set(page, 0)
  )(state)

const select = (state: ConversationState, action: Select) => set(selectedID, maybe.just(action.payload), state)

const nextPage = pipe(over(page, inc), set(conversations, remoteData.loading()))

const previousPage = pipe(over(page, dec), set(conversations, remoteData.loading()))

const firstPage = set(page, 0)

const changeSearchValue = (state: ConversationState, action: ChangeSearchValue) => set(searchValue, action.payload, state)

const changeNumberConversationsPerPage = (state: ConversationState, action: ChangeNumberConversationsPerPage) =>
  pipe(set(nbResultsPerPage, action.payload), over(conversations, remoteData.loading))(state)

const downloadCSVRequest = set(isDownloadingCSV, either.of(true))
const downloadCSVFailure = (state: ConversationState, action: DownloadCSVFailure) =>
  set(isDownloadingCSV, either.left(action.payload), state)
const downloadCSVSuccess = set(isDownloadingCSV, either.of(false))

export default function conversation (
  state: ConversationState = initialState,
  action: ConversationAction
): ConversationState {
  switch (action.type) {
    case convActions.FETCH_CONVERSATIONS_REQUEST:
      return conversationsRequest(state)
    case convActions.FETCH_CONVERSATIONS_FAILURE:
      return conversationsFailure(state, action)
    case convActions.FETCH_CONVERSATIONS_SUCCESS:
      return conversationsSuccess(state, action)
    case convActions.SELECT:
      return select(state, action)
    case convActions.CHANGE_DATES:
      return changeDates(state, action)
    case convActions.NEXT_PAGE:
      return nextPage(state)
    case convActions.PREVIOUS_PAGE:
      return previousPage(state)
    case convActions.FIRST_PAGE:
      return firstPage(state)
    case convActions.CHANGE_SEARCH_VALUE:
      return changeSearchValue(state, action)
    case convActions.CHANGE_NUMBER_CONVERSATIONS_PER_PAGE:
      return changeNumberConversationsPerPage(state, action)
    case convActions.DOWNLOAD_CSV_REQUEST:
      return downloadCSVRequest(state)
    case convActions.DOWNLOAD_CSV_FAILURE:
      return downloadCSVFailure(state, action)
    case convActions.DOWNLOAD_CSV_SUCCESS:
      return downloadCSVSuccess(state)
    default:
      return state
  }
}
