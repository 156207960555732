import React from 'react'
import { Route, Switch } from 'react-router-dom'

import KeywordContainer from './KeywordContainer'
import KeywordsContainer from './KeywordsContainer'

function KeywordIndex() {
  return (
    <Switch>
      <Route exact path='/keywords/:id' component={KeywordContainer} />
      <Route exact path='/keywords' component={KeywordsContainer} />
    </Switch>
  )
}

export default KeywordIndex
