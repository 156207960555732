import React from 'react'
import { OutlinedUIWithIndicator, OutlinedUI } from '..'
import { formatTitleDisplayed } from '../../helpers'

const CampaignDrafts = ({ onClick, deleteCampaignRequest, selected, campaign }) => (
  <OutlinedUIWithIndicator>
    <OutlinedUI
      withTrash
      left
      value={formatTitleDisplayed(campaign)}
      selected={selected}
      onClick={() => onClick(campaign.id)}
      onTrashClick={() => deleteCampaignRequest(campaign.id)}
    />
  </OutlinedUIWithIndicator>
)

export default CampaignDrafts
