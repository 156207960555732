import * as React from 'react'
import OutlinedUIStyled, { OutlinedUIWithTrash, ColoredOutlinedUI } from './styles'
import Trash from '../Trash'

const OutlinedUI = ({
  direction,
  colored,
  left,
  children,
  value,
  withTrash,
  onTrashClick,
  disabled = false,
  ...props
}) => {
  const Container = withTrash ? OutlinedUIWithTrash : React.Fragment

  const Button = colored ? ColoredOutlinedUI : OutlinedUIStyled

  return (
    <Container>
      {withTrash && <Trash centered showWhenSelected selected={props.selected} onTrashClick={onTrashClick} />}
      <Button title={value} childrenDirection={direction} left={left} type='button' {...props} disabled={disabled}>
        {children}
        {value && <span>{value}</span>}
      </Button>
    </Container>
  )
}

export default OutlinedUI
