import * as React from 'react'
import onClickOutside from 'react-onclickoutside'
import { ButtonEditContainer, ButtonEditSection } from './styles'
import { ElementText } from '..'
import { LinkIcon } from '../../icons'

class ButtonEdit extends React.Component {
  handleClickOutside = e => {
    this.props.resetButtonEditPortal()
  }

  render() {
    const {
      left,
      top,
      button,
      onButtonTitleChange,
      onButtonValueChange,
      buttonTitlePlaceholder,
      buttonUrlPlaceholder,
      settings: { maxLength }
    } = this.props
    return (
      <ButtonEditContainer style={{ left, top }}>
        <ButtonEditSection>If user clicks</ButtonEditSection>
        <ElementText
          forCarousel
          type='button title'
          placeholder={buttonTitlePlaceholder}
          maxLength={maxLength}
          text={button.title}
          changeText={onButtonTitleChange}
        />
        <ButtonEditSection>
          <LinkIcon /> URL
        </ButtonEditSection>
        <ElementText
          forCarousel
          type='button url'
          isUrl
          text={button.value}
          placeholder={buttonUrlPlaceholder}
          changeText={onButtonValueChange}
        />
      </ButtonEditContainer>
    )
  }
}

export default onClickOutside(ButtonEdit)
