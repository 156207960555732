import * as R from 'ramda'
import { maybe } from '@sbizeul/fp-flow'

import { bucketUrl } from 'helpers/effects'

import {
  BotCreation,
  BotUiAction,
  BotUiState,
  Select,
  UpdateAvatar,
  UpdateBotName,
  UpdateGreetings,
  UpdateLanguage,
  UpdateTheme,
  UpdateUseCase
} from './types'
import * as botActions from './actions'
import { NO_USE_CASE } from './models'

const DEFAULT_BOT_CREATION: BotCreation = {
  botName: '',
  greetings: '',
  language: 'fr_FR',
  useCase: NO_USE_CASE,
  theme: {
    firstColor: '#3a0071',
    secondColor: '#3a0071',
    thirdColor: '#3a0071'
  },
  avatar: `${bucketUrl}/bots/icon-white.png`
}

export const initialState: BotUiState = {
  selectedId: maybe.nothing(),
  botCreation: DEFAULT_BOT_CREATION
}

const botName = R.lensPath(['botCreation', 'botName'])
const greetings = R.lensPath(['botCreation', 'greetings'])
const language = R.lensPath(['botCreation', 'language'])
const theme = R.lensPath(['botCreation', 'theme'])
const avatar = R.lensPath(['botCreation', 'avatar'])
const selectedId = R.lensProp('selectedId')
const useCase = R.lensPath(['botCreation', 'useCase'])

const select = (state: BotUiState, { payload }: Select) =>
  R.set(selectedId, maybe.just(payload), state)

const updateBotName = (state: BotUiState, { payload }: UpdateBotName) =>
  R.set(botName, payload, state)

const updateGreetings = (state: BotUiState, { payload }: UpdateGreetings) =>
  R.set(greetings, payload, state)

const updateLanguage = (state: BotUiState, { payload }: UpdateLanguage) =>
  R.set(language, payload, state)

const updateTheme = (state: BotUiState, { payload }: UpdateTheme) =>
  R.set(theme, payload, state)

const updateAvatar = (state: BotUiState, { payload }: UpdateAvatar) =>
  R.set(avatar, payload, state)

const updateUseCase = (state: BotUiState, { payload }: UpdateUseCase) =>
  R.set(useCase, payload, state)

const botCreationEnded = (state: BotUiState) =>
  R.set(R.lensProp('botCreation'), DEFAULT_BOT_CREATION, state)

export default function (state = initialState, action: BotUiAction) {
  switch (action.type) {
    case botActions.SELECT:
      return select(state, action)
    case botActions.BOT_CREATION_UPDATE_BOTNAME:
      return updateBotName(state, action)
    case botActions.BOT_CREATION_UPDATE_GREETINGS:
      return updateGreetings(state, action)
    case botActions.BOT_CREATION_UPDATE_LANGUAGE:
      return updateLanguage(state, action)
    case botActions.BOT_CREATION_UPDATE_THEME:
      return updateTheme(state, action)
    case botActions.BOT_CREATION_UPDATE_AVATAR:
      return updateAvatar(state, action)
    case botActions.BOT_CREATION_UPDATE_USE_CASE:
      return updateUseCase(state, action)
    case botActions.BOT_CREATION_ENDED:
      return botCreationEnded(state)
    default:
      return state
  }
}
