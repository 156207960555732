import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { Menu } from 'semantic-ui-react'

import * as routes from '../../../layouts/Routes'

const PerformanceTabs = ({ location: { pathname } }) => (
  <div>
    <Menu pointing secondary position='center' style={{ fontFamily: 'Raleway', marginBottom: '21px' }}>
      <Menu.Item
        name='Delays'
        active={pathname === routes.ANALYTICS_PERFORMANCE_DELAYS}
        as={Link}
        to={{ pathname: routes.ANALYTICS_PERFORMANCE_DELAYS }}
      />
      <Menu.Item
        name='Satisfaction'
        active={pathname === routes.ANALYTICS_PERFORMANCE_RETENTION}
        as={Link}
        to={{ pathname: routes.ANALYTICS_PERFORMANCE_RETENTION }}
      />
      <Menu.Item
        name='Understanding'
        active={pathname === routes.ANALYTICS_PERFORMANCE_UNDERSTANDING}
        as={Link}
        to={{ pathname: routes.ANALYTICS_PERFORMANCE_UNDERSTANDING }}
      />
    </Menu>
  </div>
)

export default withRouter(PerformanceTabs)
