import { remoteData } from '@sbizeul/fp-flow'
import { lensProp, over, pipe, set, lensPath, compose } from 'ramda'

import * as actions from './actions'
import { toMapById } from '../../helpers/array'

export const initialState = {
  all: remoteData.notAsked(),
  byId: {}
}

const all = lensProp('all')
const byId = lensPath(['byId'])

const entitiesRequest = state => over(all, remoteData.loading, state)

const entitiesSuccess = (state, action) => set(all, pipe(toMapById, remoteData.success)(action.payload), state)

const entitiesFailure = (state, action) => set(all, remoteData.failure(action.payload), state)

const oneRequest = (state, action) => {
  const path = compose(byId, lensPath([action.payload]))
  return over(path, remoteData.loading, state)
}

const oneSuccess = (state, action) => {
  const path = compose(byId, lensPath([action.payload.id]))
  return set(path, remoteData.success(action.payload), state)
}

const oneFailure = (state, action) => {
  const path = compose(byId, lensPath([action.payload.entityId]))
  return set(path, remoteData.failure(action.payload.error), state)
}

const updateEntityRequest = (state, action) => {
  const path = compose(byId, lensPath([action.payload.id]))
  return over(path, remoteData.loading, state)
}

const updateEntitySuccess = (state, action) => {
  const path = compose(byId, lensPath([action.payload.id]))
  return set(path, remoteData.success(action.payload), state)
}

const updateEntityFailure = (state, action) => {
  const { entityId, error } = action.payload
  const path = compose(byId, lensPath([entityId]))
  return set(path, remoteData.failure(error), state)
}

export default function(state = initialState, action) {
  switch (action.type) {
  case actions.FETCH_ENTITIES_REQUEST:
    return entitiesRequest(state)
  case actions.FETCH_ENTITIES_SUCCESS:
    return entitiesSuccess(state, action)
  case actions.FETCH_ENTITIES_FAILURE:
    return entitiesFailure(state, action)
  case actions.FETCH_ONE_REQUEST:
    return oneRequest(state, action)
  case actions.FETCH_ONE_SUCCESS:
    return oneSuccess(state, action)
  case actions.FETCH_ONE_FAILURE:
    return oneFailure(state, action)
  case actions.UPDATE_REQUEST:
    return updateEntityRequest(state, action)
  case actions.UPDATE_SUCCESS:
    return updateEntitySuccess(state, action)
  case actions.UPDATE_FAILURE:
    return updateEntityFailure(state, action)
  default:
    return state
  }
}
