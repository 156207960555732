import React from 'react'
import * as R from 'ramda'
import { MdAttachFile, MdClose } from 'react-icons/md'

import TextArea from 'components/TextArea'

import './style.css'

export default function FileContentEditor(props) {
  const handleTextChange = text => {
    const simpleText = R.set(props.pathToText, text, props.textContent)
    props.updateContent({ content: simpleText })
  }

  const handleClickDelete = () => {
    props.removeContent({ id: R.view(props.pathToId, props.textContent) })
  }

  return (
    <div className='FileContent'>
      <div className='FileContent-icons-container'>
        <MdAttachFile size='25' />
        <MdClose
          data-testid='remove-content'
          size='20'
          className='FileContent-delete--icon'
          onClick={handleClickDelete}
        />
      </div>
      <div className='FileContent-text'>
        <TextArea
          data-testid='update-text'
          className='FileContent-text--input'
          type='rounded'
          required
          pattern='^.{1,600}'
          placeholder='Type your question to receive file'
          autocomplete='off'
          onChangeText={handleTextChange}
          value={R.view(props.pathToText, props.textContent)}
        />
      </div>
    </div>
  )
}
