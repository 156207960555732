import moment from 'moment-timezone'

function getDateFormat(period) {
  switch (period) {
  case 'minutes':
    return 'DD-MM HH:mm'
  case 'hours':
    return 'D MMM. HH\\h'
  default:
    return 'D MMM.'
  }
}

function addNullValuesByPeriod(rawData, dateRange, period) {
  const add = {
    value: period === 'minutes' ? 5 : 1,
    type: period
  }

  // On met le tableau qu'on recoit au bon format en TZ de Paris
  const formatedData = rawData.map(data => {
    const time =
      period === 'days'
        ? moment.utc(data.date).format('YYYY-MM-DD HH:mm')
        : moment
          .utc(data.date)
          .tz('Europe/Paris')
          .format('YYYY-MM-DD HH:mm')
    return {
      time,
      total: data.total,
      weekDay: data.weekDay
    }
  })

  if (formatedData === []) {
    return []
  }

  // Tableau a retourner
  const outputArr = []

  const start = dateRange.start.clone().set({ h: 0, m: 0, s: 0, ms: 0 })
  let end = dateRange.end.clone().set({ h: 23, m: 59, s: 0, ms: 0 })
  if (end.isAfter(moment())) {
    end = moment()
  }

  // On remplit outputArr avec le tableau init ou des valeurs nulles
  for (const key in formatedData) {
    if (moment(formatedData[key].time).isAfter(start)) {
      for (start; moment(formatedData[key].time).isAfter(start); start.add(add.value, add.type)) {
        outputArr.push({ time: start.format('YYYY-MM-DD HH:mm'), total: 0 })
      }
      start.add(add.value, add.type)
    } else {
      start.add(add.value, add.type)
    }
    outputArr.push(formatedData[key])
  }

  // Si end > lastDataDate on remplit le tableau avec des 0 jusqu'au dernier chiffre
  const lastDataDate = moment(formatedData[formatedData.length - 1].time)
  if (lastDataDate.isBefore(end)) {
    for (start; start.isSameOrBefore(end); start.add(add.value, add.type)) {
      outputArr.push({ time: start.format('YYYY-MM-DD HH:mm'), total: 0 })
    }
  }
  return outputArr
}

function addNullValuesByPeriodIntents(rawData, dateRange, period) {
  const add = {
    value: period === 'minutes' ? 5 : 1,
    type: period
  }

  // On met le tableau qu'on recoit au bon format en TZ de Paris
  const formatedData = rawData.map(data => {
    const time =
      period === 'days'
        ? moment.utc(data.date).format('YYYY-MM-DD HH:mm')
        : moment
          .utc(data.date)
          .tz('Europe/Paris')
          .format('YYYY-MM-DD HH:mm')
    return {
      time,
      total: data.total,
      total_match_per: data.total_match_per,
      total_notmatch_per: data.total_notmatch_per,
      weekDay: data.weekDay
    }
  })

  if (formatedData === []) {
    return []
  }

  // Tableau a retourner
  const outputArr = []

  const start = dateRange.start.clone().set({ h: 0, m: 0, s: 0, ms: 0 })
  let end = dateRange.end.clone().set({ h: 23, m: 59, s: 0, ms: 0 })
  if (end.isAfter(moment())) {
    end = moment()
  }

  // On remplit outputArr avec le tableau init ou des valeurs nulles
  for (const key in formatedData) {
    if (moment(formatedData[key].time).isAfter(start)) {
      for (start; moment(formatedData[key].time).isAfter(start); start.add(add.value, add.type)) {
        outputArr.push({ time: start.format('YYYY-MM-DD HH:mm'), total: 0 })
      }
      start.add(add.value, add.type)
    } else {
      start.add(add.value, add.type)
    }
    outputArr.push(formatedData[key])
  }

  // Si end > lastDataDate on remplit le tableau avec des 0 jusqu'au dernier chiffre
  const lastDataDate = moment(formatedData[formatedData.length - 1].time)
  if (lastDataDate.isBefore(end)) {
    for (start; start.isSameOrBefore(end); start.add(add.value, add.type)) {
      outputArr.push({ time: start.format('YYYY-MM-DD HH:mm'), total: 0 })
    }
  }
  return outputArr
}

function addNullValuesToMessagesByPeriod(rawData, dateRange, period) {
  const add = {
    value: period === 'minutes' ? 5 : 1,
    type: period
  }

  // On met le tableau qu'on recoit au bon format
  // + ajout de weekDay = valeur du jour de la semaine (0 === sunday)
  const formatedData = rawData.map(data => {
    const day = new Date(data.date).getDay()
    data.weekDay = day

    const time =
      period === 'days'
        ? moment.utc(data.date).format('YYYY-MM-DD HH:mm')
        : moment
          .utc(data.date)
          .tz('Europe/Paris')
          .format('YYYY-MM-DD HH:mm')
    return {
      time,
      user: data.user,
      bot: data.bot,
      total: data.bot + data.user,
      weekDay: data.weekDay
    }
  })

  if (formatedData === []) {
    return []
  }

  // Tableau a retourner
  const outputArr = []

  const start = dateRange.start.clone().set({ h: 0, m: 0, s: 0, ms: 0 })
  let end = dateRange.end.clone().set({ h: 23, m: 59, s: 0, ms: 0 })
  if (end.isAfter(moment())) {
    end = moment()
  }

  // On remplit outputArr avec le tableau init ou des valeurs nulles
  for (const key in formatedData) {
    if (moment(formatedData[key].time).isAfter(start)) {
      for (start; moment(formatedData[key].time).isAfter(start); start.add(add.value, add.type)) {
        outputArr.push({ time: start.format('YYYY-MM-DD HH:mm'), user: 0, bot: 0, total: 0 })
      }
      start.add(add.value, add.type)
    } else {
      start.add(add.value, add.type)
    }
    outputArr.push(formatedData[key])
  }

  // Si end > lastDataDate on remplit le tableau avec des 0 jusqu'au dernier chiffre
  const lastDataDate = moment(formatedData[formatedData.length - 1].time)
  if (lastDataDate.isBefore(end)) {
    for (start; start.isSameOrBefore(end); start.add(add.value, add.type)) {
      outputArr.push({ time: start.format('YYYY-MM-DD HH:mm'), user: 0, bot: 0, total: 0 })
    }
  }
  return outputArr
}

function addNullValuesToDelaysByPeriod(rawData, dateRange, period) {
  const add = {
    value: period === 'minutes' ? 5 : 1,
    type: period
  }

  // On met le tableau qu'on recoit au bon format
  const formatedData = rawData.map(data => {
    const time =
      period === 'days'
        ? moment.utc(data.date).format('YYYY-MM-DD HH:mm')
        : moment
          .utc(data.date)
          .tz('Europe/Paris')
          .format('YYYY-MM-DD HH:mm')
    return {
      time,
      averageReplyTime: data.average
    }
  })

  if (formatedData === []) {
    return []
  }

  // Tableau a retourner
  const outputArr = []

  const start = dateRange.start.clone().set({ h: 0, m: 0, s: 0, ms: 0 })
  let end = dateRange.end.clone().set({ h: 23, m: 59, s: 0, ms: 0 })
  if (end.isAfter(moment())) {
    end = moment()
  }

  // On remplit outputArr avec le tableau init ou des valeurs nulles
  for (const key in formatedData) {
    if (moment(formatedData[key].time).isAfter(start)) {
      for (start; moment(formatedData[key].time).isAfter(start); start.add(add.value, add.type)) {
        outputArr.push({ time: start.format('YYYY-MM-DD HH:mm'), averageReplyTime: 0 })
      }
      start.add(add.value, add.type)
    } else {
      start.add(add.value, add.type)
    }
    outputArr.push(formatedData[key])
  }

  // Si end > lastDataDate on remplit le tableau avec des 0 jusqu'au dernier chiffre
  const lastDataDate = moment(formatedData[formatedData.length - 1].time)
  if (lastDataDate.isBefore(end)) {
    for (start; start.isSameOrBefore(end); start.add(add.value, add.type)) {
      outputArr.push({ time: start.format('YYYY-MM-DD HH:mm'), averageReplyTime: 0 })
    }
  }
  return outputArr
}

function groupMessagesByDay(rawData) {
  const weekDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
  const messagesCountByDay = weekDays.map(day => ({ day, count: 0 }))
  rawData.forEach(elem => {
    messagesCountByDay[moment(elem.date).isoWeekday() - 1].count += elem.user
  })
  return messagesCountByDay
}

function groupAverageByDayMessages(rawData) {
  const weekDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
  const messagesByDay = weekDays.map(day => ({ day, count: 0, nbDay: [] }))
  rawData.forEach(elem => {
    messagesByDay[moment(elem.date).isoWeekday() - 1].count += elem.user
    if (!messagesByDay[moment(elem.date).isoWeekday() - 1].nbDay.includes(elem.date.substring(0, 10))) {
      messagesByDay[moment(elem.date).isoWeekday() - 1].nbDay.push(elem.date.substring(0, 10))
    }
  })
  messagesByDay.forEach(day => {
    day.nbDay = day.nbDay.length
    day.count = Math.round(day.count / day.nbDay)
  })
  return messagesByDay
}

function groupAverageByDayUsers(rawData) {
  const weekDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
  const usersByDay = weekDays.map(day => ({ day, count: {} }))
  rawData.forEach(elem => {
    if (usersByDay[moment(elem.date).isoWeekday() - 1].count[elem.date.substring(0, 10)] === undefined) {
      usersByDay[moment(elem.date).isoWeekday() - 1].count[elem.date.substring(0, 10)] = []
    }
    elem.foreign.forEach(userId => {
      if (!usersByDay[moment(elem.date).isoWeekday() - 1].count[elem.date.substring(0, 10)].includes(userId)) {
        usersByDay[moment(elem.date).isoWeekday() - 1].count[elem.date.substring(0, 10)].push(userId)
      }
    })
  })
  usersByDay.forEach(day => {
    const nbDay = Object.keys(day.count).length
    let nbUsers = 0

    Object.keys(day.count).forEach(key => {
      const value = day.count[key]
      nbUsers += value.length
    })

    day.count = Math.round(nbUsers / nbDay)
  })
  return usersByDay
}

function groupMessagesByHour(rawData) {
  const messagesCountByHour = []
  for (let i = 0; i < 24; i += 1) {
    messagesCountByHour.push({ hour: i, count: 0 })
  }

  rawData.forEach(elem => {
    messagesCountByHour[moment(elem.date).hour()].count += elem.user
  })
  return messagesCountByHour
}

function groupMessagesByHourByDay(rawData) {
  const hours = []
  for (let i = 0; i < 24; i += 1) {
    hours.push(0)
  }

  const messagesCountByHourByDay = []
  for (let i = 0; i < 7; i += 1) {
    messagesCountByHourByDay.push(hours.slice())
  }

  rawData.forEach(elem => {
    messagesCountByHourByDay[moment(elem.date).isoWeekday() - 1][moment(elem.date).hour()] += elem.user
  })
  return messagesCountByHourByDay
}

function groupConversationsByHourByDay(rawData) {
  const hours = []
  for (let i = 0; i < 24; i += 1) {
    hours.push(0)
  }

  const conversationsCountByHourByDay = []
  for (let i = 0; i < 7; i += 1) {
    conversationsCountByHourByDay.push(hours.slice())
  }

  rawData.forEach(elem => {
    conversationsCountByHourByDay[moment(elem.date).isoWeekday() - 1][moment(elem.date).hour()] += elem.total
  })
  return conversationsCountByHourByDay
}

function mergeNewAndAllUsers(allUsers, newUsers) {
  return allUsers.map((elem, index) => ({
    time: elem.time,
    total: elem.total,
    new: newUsers[index].total
  }))
}

function groupDataByDay(rawData) {
  const weekDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

  const conversationsCountByDay = weekDays.map(day => ({ day, count: 0 }))

  rawData.forEach(elem => {
    conversationsCountByDay[moment(elem.date).isoWeekday() - 1].count += elem.total
  })

  return conversationsCountByDay
}

function groupDataByHour(rawData) {
  const conversationsCountByHour = []
  for (let i = 0; i < 24; i += 1) {
    conversationsCountByHour.push({ hour: i, count: 0 })
  }

  rawData.forEach(elem => {
    conversationsCountByHour[moment(elem.date).hour()].count += elem.total
  })
  return conversationsCountByHour
}
function groupUsersByHour(rawData) {
  const usersByHour = []
  for (let i = 0; i < 24; i += 1) {
    usersByHour.push({ hour: i, count: 0 })
  }

  rawData.forEach(elem => {
    usersByHour[moment(elem.date).hour()].count += elem.total
  })
  return usersByHour
}

function getVariation(prevValue, value) {
  return Math.round((value / prevValue - 1) * 100)
}

export {
  addNullValuesByPeriod,
  addNullValuesToMessagesByPeriod,
  addNullValuesToDelaysByPeriod,
  addNullValuesByPeriodIntents,
  groupMessagesByDay,
  groupMessagesByHour,
  groupMessagesByHourByDay,
  groupConversationsByHourByDay,
  groupAverageByDayMessages,
  groupAverageByDayUsers,
  groupDataByDay,
  groupDataByHour,
  groupUsersByHour,
  mergeNewAndAllUsers,
  getDateFormat,
  getVariation
}
