/* eslint no-alert: "off" */
import React from 'react'
import { Divider, Button, Dropdown, Segment, Icon } from 'semantic-ui-react'
import { connect } from 'react-redux'
import Table from '../components/Table/CustomTable'
import { maybe } from '@sbizeul/fp-flow'

import { getRessources, getRessourceData } from '../../../../redux/selectors'
import operations from '../../../../redux/ressources/operations'
import Modal from './Modal'
import bot from '../../../../modules/bot'

const style = {
  border: '1px solid #e6eaee',
  WebkitBoxShadow: '0 0 0 0',
  padding: '5px'
}

const deleteData = (botId, ressourceId, deleteRessource) => (
  <Button icon onClick={() => deleteRessource(botId, ressourceId)}>
    <Icon name='delete' />
  </Button>
)

class MyRessources extends React.PureComponent {
  constructor(props) {
    super(props)
    if (props.ressources && props.ressources.forms && props.ressources.forms.length) {
      this.state = { ressourceName: props.ressources.forms[0].name }
    }
  }

  getBotId() {
    return maybe.getOrElse(() => 'unknownBotId', this.props.botId)
  }

  componentDidMount() {
    if (this.state.ressourceName) {
      this.props.getRessource(this.getBotId(), this.state.ressourceName)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.state.ressourceName &&
      ((nextProps.isDeleting === false && this.props.isDeleting === true) ||
        (nextProps.isEditing === false && this.props.isEditing === true))
    ) {
      this.props.getRessource(this.getBotId(), this.state.ressourceName)
    }
  }

  handleChange = (e, { value }) => {
    const { getRessource } = this.props
    getRessource(this.getBotId(), value)
    this.setState({
      ressourceName: value,
      modalOpen: false
    })
  }

  render() {
    const { ressources, ressource, deleteRessource } = this.props

    const { ressourceName } = this.state

    if (!ressources.forms.length) {
      return null
    }

    const availableRessources = ressources.forms.map((form, index) => ({
      key: index,
      text: form.name,
      value: form.name
    }))

    return (
      <div>
        <Dropdown
          onChange={this.handleChange}
          options={availableRessources}
          placeholder='View Ressources'
          selection
          value={ressourceName}
        />
        <Divider />
        {ressource && ressource.header && ressource.data && (
          <div>
            <h3>{ressourceName}</h3>
            <Segment style={style}>
              <Table
                data={ressource.data.map(elem => ({
                  ...elem.ressources,
                  edit: (
                    <Modal
                      botId={this.getBotId()}
                      ressourceId={elem._id.$oid}
                      ressourceName={ressourceName}
                      values={elem.ressources}
                    />
                  ),
                  delete: deleteData(this.getBotId(), elem._id.$oid, deleteRessource)
                }))}
                header={[...ressource.header, 'edit', 'delete']}
              />
            </Segment>
          </div>
        )}
        <br />
      </div>
    )
  }
}

MyRessources.defaultProps = {
  ressources: {
    forms: []
  }
}

const mapStateToProps = state => ({
  ressources: getRessources(state),
  ressource: getRessourceData(state),
  botId: bot.selectors.getSelectedId(state),
  isDeleting: state.ressources.get('isDeleting'),
  isEditing: state.ressources.get('isEditing')
})

const connectedMyRessources = connect(mapStateToProps, operations)(MyRessources)

export default connectedMyRessources
