import * as R from 'ramda'

import { Id } from 'types'

import { CreateQuestion } from './types'

type Log = Readonly<{
  id: Id
  label: string
}>

type CreateQuestionWithTrainingQuestions = Readonly<{
  botID: Id
  label: string
  logs: ReadonlyArray<Log>
}>

const toApi: (botId: Id) => (createQuestion: CreateQuestion) => CreateQuestionWithTrainingQuestions =
  botId => createQuestion => ({
    botID: botId,
    label: createQuestion.question,
    logs: R.map(log => ({ id: log.id, label: log.text }), createQuestion.selectedLogs)
  })

export {
  toApi
}
