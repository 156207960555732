import React from 'react'
import PropTypes from 'prop-types'

const ImageIcon = ({ width, height, fill }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 56 56'>
      <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Push-Copy-2' transform='translate(-1072.000000, -617.000000)' fill={fill} fillRule='nonzero'>
          <g id='image' transform='translate(1072.681641, 617.681641)'>
            <path
              d='M46.9870355,8.06167621 C41.7884198,2.86306048 34.8762517,0 27.5243558,0 C20.17246,0 13.2602918,2.86306048 8.06167621,8.06167621 C2.86306048,13.2602918 -3.90798505e-14,20.17246 -3.90798505e-14,27.5243558 C-3.90798505e-14,34.8762517 2.86306048,41.7884198 8.06167621,46.9870355 C13.2602918,52.1856512 20.17246,55.0487117 27.5243558,55.0487117 C34.8762517,55.0487117 41.7884198,52.1856512 46.9870355,46.9870355 C52.1856512,41.7884198 55.0487117,34.8762517 55.0487117,27.5243558 C55.0487117,20.17246 52.1856512,13.2602918 46.9870355,8.06167621 Z M10.3426328,10.3426328 C14.9318454,5.75300022 21.033856,3.22551045 27.5243558,3.22551045 C34.0148557,3.22551045 40.1168662,5.75300022 44.7060789,10.3426328 C49.2957114,14.9318454 51.8232012,21.033856 51.8232012,27.5243558 C51.8232012,29.3177061 51.6304265,31.0808171 51.254117,32.7910096 L43.0219256,25.5961893 C40.8896448,23.7327011 37.7640915,23.6970021 35.5898119,25.5109318 L28.7834809,31.1900141 C27.9422443,31.8918146 26.7578771,31.9661525 25.8360027,31.3748089 L21.465184,28.5730667 C19.6340349,27.3983593 17.3333388,27.3576205 15.4610307,28.4663897 L4.3968579,35.0177877 C3.62575931,32.6288941 3.22551045,30.1072842 3.22551045,27.5243558 C3.22551045,21.033856 5.75300022,14.9318454 10.3426328,10.3426328 Z M44.7060789,44.7060789 C40.1168662,49.2957114 34.0148557,51.8232012 27.5243558,51.8232012 C21.033856,51.8232012 14.9318454,49.2957114 10.3426328,44.7060789 C8.37246758,42.7359135 6.78239167,40.4872959 5.6089443,38.0488437 L17.1044452,31.2416727 C17.9213225,30.7578461 18.9250945,30.7759056 19.7243324,31.2882913 L24.0951511,34.0904535 C26.2081123,35.4453359 28.922497,35.2748207 30.8494035,33.6671052 L37.6561545,27.988023 C38.6049082,27.196345 39.9686104,27.2118845 40.8988845,28.0249819 L50.2398452,36.1887153 C49.0365785,39.3474476 47.1680505,42.2445271 44.7060789,44.7060789 Z'
              id='Shape'
            ></path>
            <path
              d='M17.0763059,23.9384954 C20.5470896,23.9384954 23.3710912,21.1149138 23.3710912,17.6441302 C23.3710912,14.1733465 20.5470896,11.3497649 17.0763059,11.3497649 C13.6059423,11.3497649 10.7819407,14.1733465 10.7819407,17.6441302 C10.7819407,21.1149138 13.6059423,23.9384954 17.0763059,23.9384954 Z M17.0763059,14.5752753 C18.7688589,14.5752753 20.1455807,15.9519972 20.1455807,17.6441302 C20.1455807,19.3362632 18.7688589,20.712985 17.0763059,20.712985 C15.3845929,20.712985 14.0074511,19.3362632 14.0074511,17.6441302 C14.0074511,15.9519972 15.3845929,14.5752753 17.0763059,14.5752753 Z'
              id='Shape'
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

ImageIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string
}

ImageIcon.defaultProps = {
  width: '1.5em',
  height: '1.5em',
  fill: 'currentColor'
}

export default ImageIcon
