import axios from 'axios'
import { maybe } from '@sbizeul/fp-flow'

import actions from './actions'
import isResValid from '../../_shared/isResValid'
import formatFilters from '../../_shared/formatFilters'
import bot from '../../../modules/bot'
import { legacyApiUrl } from 'helpers/effects'

const fetchWordsusers = rawFilters => (dispatch, getState) => {
  const { channelId, start, end, period } = formatFilters(rawFilters)
  const botId = bot.selectors.getSelectedId(getState())

  dispatch(actions.fetchWordsusersRequest())
  return axios({
    url: `${legacyApiUrl}/analytics/${maybe.get(botId)}/${channelId}/wordstab`,
    params: {
      start,
      end,
      period
    },
    method: 'get',
    responseType: 'json'
  })
    .then(response => {
      if (isResValid(response)) {
        const formatedResponse = {
          data: response.data.result.data
        }
        dispatch(actions.fetchWordsusersSuccess(formatedResponse))
      } else {
        dispatch(actions.fetchWordsusersFailure())
      }
    })
    .catch(err => {
      dispatch(actions.fetchWordsusersFailure(err))
    })
}

export default { fetchWordsusers }
