import { connect } from 'react-redux'

import * as selectors from '../selectors'
import * as actions from '../actions'
import PublishButton from '../components/PublishButton'

const mapStateToProps = state => ({
  botId: selectors.getSelectedId(state),
  published: selectors.getPublishStatus(state)
})

const mapDispatchToProps = {
  fetchPublishedStatus: actions.fetchPublishStatus,
  publish: actions.publishRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(PublishButton)
