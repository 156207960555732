import { maybe } from '@sbizeul/fp-flow'
import * as R from 'ramda'

import * as adminActions from './actions'

export const initialState = {
  selectedUserId: maybe.nothing(),
  mode: 'create'
}

const selectedUserId = R.lensProp('selectedUserId')
const mode = R.lensProp('mode')

const selectUser = (state, action) => {
  return R.set(selectedUserId, maybe.just(action.payload), state)
}

const unselectUser = state => {
  return R.set(selectedUserId, maybe.nothing(), state)
}

const setMode = (state, action) => {
  return R.set(mode, action.payload, state)
}

export default function admin(state = initialState, action) {
  switch (action.type) {
  case adminActions.SELECT_USER:
    return selectUser(state, action)
  case adminActions.UNSELECT_USER:
    return unselectUser(state)
  case adminActions.SET_MODE:
    return setMode(state, action)
  default:
    return state
  }
}
