import 'react-dates/initialize'
import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Grid } from 'semantic-ui-react'
import CreateIntentForm from './CreateIntentForm'

const NewIntentPage = () => (
  <div>
    <Link to={'/intents/'}>
      <Button content='Return To Intents' icon='arrow left' labelPosition='left' />
    </Link>
    <Grid>
      <Grid.Column mobile={8} tablet={7} computer={7} widescreen={5}>
        <CreateIntentForm />
      </Grid.Column>
    </Grid>
  </div>
)

export default NewIntentPage
