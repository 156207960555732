import React from 'react'
import { Predicate } from 'fp-ts/lib/function'
import { either, maybe, remoteData } from '@sbizeul/fp-flow'
import * as R from 'ramda'

import { get } from 'helpers/map'
import { renderServerFailure } from 'helpers/react'
import { Id } from 'types'
import questionModule from 'modules/question'
import { Question, QuestionState } from 'modules/question/types'
import EmptyStateContent from 'components/EmptyState'

import {
  AllQuestionsBySection,
  isMatchSectionId,
  QaQuestionsProps,
  QuestionsBySection,
  questionsProp,
  sectionProp
} from './types'
import ImportButton from '../ImportButton'
import CollapsibleSection, { DeletingSectionIndicator } from '../CollapsibleSection'

const theSectionHaveNoQuestions: Predicate<QuestionsBySection> = R.compose(
  R.isEmpty, questionsProp
)

const theSectionHaveQuestions: Predicate<QuestionsBySection> = R.compose(
  R.not, theSectionHaveNoQuestions
)

const isNotUnlabeledSection: Predicate<QuestionsBySection> = R.compose(
  R.not, questionModule.models.isUnlabeledSection, sectionProp
)

const theSectionHaveToBeShown: Predicate<QuestionsBySection> =
  R.anyPass([ isNotUnlabeledSection, theSectionHaveQuestions ])

const thereIsNoActiveQuestion: Predicate<AllQuestionsBySection> = R.pipe(
  R.map(questionsProp),
  R.flatten,
  R.filter(questionModule.models.isActive),
  R.isEmpty
)

const isContentEmpty: Predicate<AllQuestionsBySection> = thereIsNoActiveQuestion

const filterQuestionWith: (state: QuestionState) => (questions: ReadonlyArray<Question>) => ReadonlyArray<Question> =
  state => questions => R.filter(R.propEq('state', state), questions)

const activeQuestions: (questions: ReadonlyArray<Question>) => ReadonlyArray<Question> =
  filterQuestionWith(QuestionState.ACTIVE)

const archivedQuestions: (questions: ReadonlyArray<Question>) => ReadonlyArray<Question> =
  filterQuestionWith(QuestionState.ARCHIVED)

const orderedActiveQuestions: (questions: ReadonlyArray<Question>) => ReadonlyArray<Question> =
  R.compose(questionModule.models.sortQuestionsByLabel, activeQuestions)

const orderedArchivedQuestions: (questions: ReadonlyArray<Question>) => ReadonlyArray<Question> =
  R.compose(questionModule.models.sortQuestionsByLabel, archivedQuestions)

const orderedQuestions: (questions: ReadonlyArray<Question>) => ReadonlyArray<Question> =
  questions => R.pipe(
    orderedArchivedQuestions,
    R.concat(orderedActiveQuestions(questions))
  )(questions)

const QaQuestions = (props: QaQuestionsProps) => {
  const {
    questionsBySection,
    importFile,
    isImporting,
    isDeleting,
    isDeletingSection,
    deleteQa,
    fetchLabeledQuestions,
    isPostingQuestion,
    trainingQuestionsById,
    isUpdatingSection,
    updateSection,
    deleteSection,
    saveOpeningStatus,
    collapsibleSectionsOpeningStatus,
    mergeQuestion,
    questionReferences,
    fetchQuestionReferences,
    ...questionProps
  } = props

  const allQuestions = R.flatten(R.map(R.prop('questions'), questionsBySection))
  const sections = R.map(R.prop('section'), questionsBySection)
  const getTrainingQuestions = (questionId: Id) =>
    maybe.getOrElse(() => remoteData.notAsked(), get(questionId, trainingQuestionsById))

  const renderDeletingSectionIndicator = (questionsBySection: QuestionsBySection) => (
    <DeletingSectionIndicator key={questionsBySection.section.id} />
  )

  const renderQuestion = (question: Question) => (
    <questionModule.components.Question
      {...questionProps}
      key={question.id}
      question={question}
      questions={allQuestions}
      // @ts-ignore Fix issue with @sbizeul/fp-flow
      trainingQuestions={getTrainingQuestions(question.id)}
      sections={sections}
      isDeleting={isDeleting}
      questionReferences={questionReferences}
      fetchQuestionReferences={fetchQuestionReferences}
      deleteQa={deleteQa}
      mergeQuestion={mergeQuestion}
    />
  )

  const renderQuestions = R.compose(R.map(renderQuestion), orderedQuestions)

  const handleTitleUpdate = (questionsBySection: QuestionsBySection) => (title: string) => R.pipe(
    sectionProp,
    R.set(R.lensProp('label'), title),
    updateSection
  )(questionsBySection)

  const renderSection = (questionsBySection: QuestionsBySection) => (
    theSectionHaveToBeShown(questionsBySection)
    && <CollapsibleSection
      key={questionsBySection.section.id}
      section={questionsBySection.section}
      open={true}
      isUpdatingSection={isUpdatingSection}
      onLabelUpdate={handleTitleUpdate(questionsBySection)}
      onSectionDeleted={() => deleteSection(questionsBySection.section.id)}
      saveOpeningStatus={saveOpeningStatus}
      collapsibleSectionsOpeningStatus={collapsibleSectionsOpeningStatus}
    >
      {renderQuestions(questionsBySection.questions)}
    </CollapsibleSection>
  )

  const renderSectionOrDeletingIndicator = (questionsBySection: QuestionsBySection) => either.fold(
    renderServerFailure,
    either.fold(
      _ => renderSection(questionsBySection),
      sectionId => R.ifElse(
        isMatchSectionId(sectionId), renderDeletingSectionIndicator, renderSection
      )(questionsBySection)
    ),
    isDeletingSection
  )

  const renderAllQuestionsBySection = R.map(renderSectionOrDeletingIndicator)

  const renderEmptyState = () => (
    <EmptyStateContent
      img='/img/cloud-computing.svg'
      title='Enrich your assistant now!'
      text='In order to answer user questions, you first need to add a question with the « Create a Q&A » feature above. You’ve got already a FAQ? Import it with our template.'
    >
      <ImportButton importFile={importFile} isImporting={isImporting}/>
    </EmptyStateContent>
  )

  return (
    <>
      {isContentEmpty(questionsBySection) && renderEmptyState()}
      {renderAllQuestionsBySection(questionsBySection)}
    </>
  )
}

export default QaQuestions
