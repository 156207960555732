import React from 'react'
import Heatmap from 'highcharts/modules/heatmap'
import ReactHighcharts from 'react-highcharts'

Heatmap(ReactHighcharts.Highcharts)

const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

const config = {
  chart: {
    type: 'heatmap',
    marginTop: 40,
    marginBottom: 40
  },
  title: {
    text: null
  },
  credits: {
    enabled: false
  },

  yAxis: {
    categories: [
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
      '19',
      '20',
      '21',
      '22',
      '23',
      '24'
    ],
    title: {
      text: null
    },
    gridLineWidth: 0
  },

  xAxis: {
    categories: weekDays
  },
  colorAxis: {
    min: 0,
    minColor: '#F7FAFF',
    maxColor: '#3B86FF'
  },
  legend: {
    align: 'right',
    layout: 'vertical',
    margin: 0,
    verticalAlign: 'top',
    y: 25,
    symbolHeight: 280
  },
  tooltip: {
    formatter: function() {
      return `<b>${this.series.xAxis.categories[this.point.x]}</b>: <br><b>
        ${this.point.value}</b> escalades from <br><b>${this.series.yAxis.categories[this.point.y]}h to ${
  this.series.yAxis.categories[this.point.y + 1]
}h</b>`
    }
  }
}

export default function HeatHighcharts({ data }) {
  const allMessages = {
    data
  }

  config.series = [allMessages]
  return <ReactHighcharts config={config} domProps={{ id: 'heat' }} />
}
