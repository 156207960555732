import React, { FunctionComponent } from 'react'
import { MdFileDownload } from 'react-icons/md'
import * as either from 'fp-ts/lib/Either'

import { Map } from 'helpers/map'
import { renderInlineLoader, renderServerFailure } from 'helpers/react'
import { Id, ServerFailure } from 'types'
import { RemoteData } from '@sbizeul/fp-flow'

import * as routes from '../../routes'
import { FormId, FormSkill, RestFormReferences } from '../../types'
import { FaTrash } from 'react-icons/fa'
import { Modal, Button } from 'semantic-ui-react'
import * as SkillModalContent from './SkillModalContent'

import classNames from 'classnames'

export type Props = Readonly<{
  formSkill: FormSkill
  isDownloadingFormData: Map<either.Either<ServerFailure, boolean>>
  onClickDownload: ({ skillId, skillName }: { skillId: Id, skillName: string }) => unknown
  navigateTo: (path: string) => unknown,
  formReferences: RemoteData<ServerFailure, RestFormReferences>
  fetchFormReferences: (id: FormId) => unknown
  deleteForm: (id: FormId) => unknown
}>

const PrebuiltSkills = ({ formSkill, isDownloadingFormData, navigateTo, onClickDownload, formReferences, fetchFormReferences, deleteForm }: Props) => {
  const goToSkill: (skillId: Id) => (event: React.MouseEvent) => void =
    skillId => () => navigateTo(routes.skillFormsRoutes(skillId))    

  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const openModal = async (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsModalOpen(true)
  }

  const closeModal = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsModalOpen(false)
  }

  return (
    <div
      className='skill-default--button skill-form--button'
      onClick={goToSkill(formSkill.id)}>
      <p className='skill-form--label'>{formSkill.name}</p>
      {formSkill.isDataAvailable &&
        <DownloadData skillId={formSkill.id} skillName={formSkill.name} onClickDownload={onClickDownload} />
      }
      <Modal
        onClose={closeModal}
        onOpen={openModal}
        open={isModalOpen}
        onClick={(evt: React.MouseEvent) => evt.stopPropagation()}
        trigger={
          <button className={classNames('skill-trash-container', {'skill-form-trash-container--no-download': !formSkill.isDataAvailable})}>
            <FaTrash className='skill-trash-icon' size='15' />
          </button>
        }
      >
        <Modal.Header>Delete your form</Modal.Header>
        <Modal.Content>
        {
          isModalOpen && <SkillModalContent.Component
            contentType="form"
            skillId={formSkill.id}
            skillReferences={formReferences}
            fetchSkillReferences={fetchFormReferences}
          />
        }
        </Modal.Content>
        <Modal.Actions>
          <Button negative content='Cancel'
            onClick={closeModal}
          />
          {formReferences.tag === 'Success' && formReferences.value.questions.length === 0 && formReferences.value.skills.length === 0 &&
            <Button
            content="Yes"
            labelPosition='right'
            icon='checkmark'
            onClick={() => {deleteForm(formSkill.id); setIsModalOpen(false)}}
            positive
            />
          }
        </Modal.Actions>
      </Modal>
      {isDownloadingFormData[formSkill.id] && either.fold(
        renderServerFailure,
        renderLoader
      )(isDownloadingFormData[formSkill.id])}
    </div>
  )
}

export type DownloadDataProps = Readonly<{
  skillId: Id
  skillName: string
  onClickDownload: ({ skillId, skillName }: { skillId: Id, skillName: string }) => unknown
}>

const DownloadData: FunctionComponent<DownloadDataProps> = ({ skillId, skillName, onClickDownload }) => {
  const downloadData: ({ skillId, skillName }: { skillId: Id, skillName: string }) => (event: React.MouseEvent) => void =
    ({ skillId, skillName }) => event => {
      event.stopPropagation()
      event.preventDefault()
      onClickDownload({ skillId, skillName })
    }

  return (
    <MdFileDownload
      size='30'
      className='skill-form-download--icon'
      onClick={downloadData({ skillId, skillName })}
    />
  )
}

const renderLoader = (isDownloading: boolean) =>
  isDownloading && (
    <div className='skill-form-download-data'>
      {renderInlineLoader('Downloading data...')}
    </div>
  )

export default PrebuiltSkills
