export const LOGIN_REQUEST = 'user/LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'user/LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'user/LOGIN_FAILURE'
export const LOGOUT = 'user/LOGOUT'
export const FETCH_ONE_REQUEST = 'user/FETCH_ONE_REQUEST'
export const FETCH_ONE_SUCCESS = 'user/FETCH_ONE_SUCCESS'
export const FETCH_ONE_FAILURE = 'user/FETCH_ONE_FAILURE'
export const FETCH_ALL_REQUEST = 'user/FETCH_ALL_REQUEST'
export const FETCH_ALL_SUCCESS = 'user/FETCH_ALL_SUCCESS'
export const FETCH_ALL_FAILURE = 'user/FETCH_ALL_FAILURE'

export const login = () => {
  return { type: LOGIN_REQUEST }
}

export const loginSuccess = user => {
  return { type: LOGIN_SUCCESS, payload: user }
}

export const loginFailure = error => {
  return { type: LOGIN_FAILURE, payload: error }
}

export const logout = () => {
  return { type: LOGOUT }
}

export const fetchOne = id => {
  return { type: FETCH_ONE_REQUEST, payload: id }
}

export const fetchOneSuccess = user => {
  return { type: FETCH_ONE_SUCCESS, payload: user }
}

export const fetchOneFailure = (error, userId) => {
  return {
    type: FETCH_ONE_FAILURE,
    payload: {
      error,
      userId
    }
  }
}

export const fetchAll = () => {
  return { type: FETCH_ALL_REQUEST }
}

export const fetchAllSuccess = users => {
  return { type: FETCH_ALL_SUCCESS, payload: users }
}

export const fetchAllFailure = error => {
  return { type: FETCH_ALL_FAILURE, payload: error }
}
