import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Field, FieldArray, reduxForm, formValueSelector } from 'redux-form'
import { Header, Form, Button, Icon } from 'semantic-ui-react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { maybe } from '@sbizeul/fp-flow'
import * as R from 'ramda'

import InputField from '../../../../components/InputField'
import SelectField from '../../../../components/SelectField'
import operations from '../../../../../redux/analytics/events/operations'
import addSeriesLineChart from './addSeriesLineChart'
import addSeriesPieChart from './addSeriesPieChart'
import bot from '../../../../../modules/bot'

const required = value => (!_.trim(value) ? 'Required' : undefined)

const selector = formValueSelector('AddEventChartForm')

class AddEvent extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return (
      JSON.stringify(this.props) !== JSON.stringify(nextProps) ||
      JSON.stringify(this.state) !== JSON.stringify(nextState)
    )
  }

  handleChange = (e, { value }) => this.setState({ formType: value })

  getEvents() {
    return R.pipe(
      maybe.map(bot => bot.events),
      maybe.getOrElse(() => [])
    )(this.props.bot)
  }

  saveEvent = data => {
    maybe.map(bot => this.props.postEventChart(data, bot), this.props.bot)
  }

  render() {
    const { handleSubmit, pristine, submitting, chartType, selectedSlug } = this.props

    if (this.getEvents() === undefined) {
      return <div>There is no event set up thus no custom event can be created</div>
    }

    return (
      <div>
        <Form onSubmit={handleSubmit(this.saveEvent)} autoComplete='off'>
          <Button inverted size='small' color='green' as={Link} to={'/analytics/events'}>
            <Icon name='long arrow alternate left' />
            Return to Custom Analytics
          </Button>
          <Header as='h3'>Chart Information</Header>
          <Field type='text' name='name' label='Title' validate={required} required component={InputField} />
          <Field
            type='number'
            parse={value => Number(value)}
            name='position'
            label='Position on the page'
            component={InputField}
          />
          <Field
            type='select'
            name='chart'
            label='Type Of Chart'
            validate={required}
            required
            options={[
              { key: 1, text: 'LineChart', value: 'line' },
              { key: 2, text: 'PieChart', value: 'pie' }
            ]}
            component={SelectField}
          />
          {chartType === 'line' && (
            <Field
              type='select'
              name='category'
              label='Type Data'
              validate={required}
              required
              options={[
                { key: 1, text: 'Dates', value: 'datetime' },
                { key: 2, text: 'Categories', value: 'categories' }
              ]}
              component={SelectField}
            />
          )}
          <Header as='h3'>Data</Header>
          {chartType === 'line' && (
            <FieldArray
              name='series'
              component={addSeriesLineChart}
              chartType={chartType}
              selectedSlug={selectedSlug}
              eventConfig={this.getEvents()}
            />
          )}
          {chartType === 'pie' && (
            <FieldArray
              name='series'
              component={addSeriesPieChart}
              chartType={chartType}
              selectedSlug={selectedSlug}
              eventConfig={this.getEvents()}
            />
          )}
          <br />
          <Form.Button content='Submit' type='submit' disabled={pristine || submitting} />
        </Form>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  chartType: selector(state, 'chart'),
  bot: bot.selectors.getSelected(state),
  selectedSlug: _.get(state, 'form.AddEventChartForm.values.series.0.event_slug')
})

const connectedAddEvent = connect(mapStateToProps, operations)(AddEvent)

export default reduxForm({
  form: 'AddEventChartForm',
  destroyOnUnmount: false,
  touchOnBlur: false,
  enableReinitialize: true
})(connectedAddEvent)
