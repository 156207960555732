import React, { Component } from 'react'
import { Button, Tab } from 'semantic-ui-react'
import { connect } from 'react-redux'
import operations from '../../../redux/psadata/operations'

import { createDefectSelector, settleDefectSelector } from '../../../redux/selectors'

import DefectPane from './components/DefectPane'

class PsaDefects extends Component {
  componentDidMount() {
    const { getDefectCreate, getDefectSettle } = this.props
    getDefectCreate()
    getDefectSettle()
  }

  render() {
    const {
      createDefectData,
      settleDefectData,

      isFetchingCreate,
      isFetchingSettle,

      getDefectCreate,
      getDefectSettle
    } = this.props

    const panes = [
      {
        menuItem: 'Reported',
        pane: (
          <Tab.Pane style={{ height: 'calc(100% - 100px)', marginBottom: '0px' }}>
            <DefectPane data={createDefectData} type='defaut_data' isFetching={isFetchingCreate} />
          </Tab.Pane>
        )
      },
      {
        menuItem: 'Fixed',
        pane: (
          <Tab.Pane style={{ height: 'calc(100% - 100px)' }}>
            <DefectPane data={settleDefectData} type='solde_data' isFetching={isFetchingSettle} />
          </Tab.Pane>
        )
      }
    ]

    return (
      <div style={{ height: '100%' }}>
        <Button
          content='Refresh'
          onClick={() => {
            getDefectCreate()
            getDefectSettle()
          }}
        />
        <br />
        <br />
        <Tab style={{ height: 'calc(100% - 50px)' }} panes={panes} renderActiveOnly={false} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  createDefectData: createDefectSelector(state),
  settleDefectData: settleDefectSelector(state),
  isFetchingCreate: state.psa.getIn(['defectCreate', 'isFetching']),
  isFetchingSettle: state.psa.getIn(['defectSettle', 'isFetching'])
})

const ConnectedPsaDefects = connect(mapStateToProps, operations)(PsaDefects)

export default ConnectedPsaDefects
