
import React from 'react'
import TextInput from 'components/TextInput'

const Title = ({ onChangeText, value }) => (
  <div className='QuickReplyContent-row'>
    <TextInput
      className='QuickReplyContent-row--text'
      data-testid='update-text-input'
      type='rounded'
      name='text'
      autocomplete='off'
      value={value}
      onChangeText={onChangeText}
      placeholder='Type your message...'
    />
  </div>
)

export default Title
