import * as entity from './reducer.entity'
import * as ui from './reducer.ui'
import * as actions from './actions'
import * as saga from './saga'
import * as routes from './routes'
import * as selectors from './selectors'
import EditorWithMenu from './containers/EditorWithMenuContainer'
import Formbuilder from './containers/FormBuilderContainer'
import SkillPage from './containers/SkillPageContainer'
import SkillBuilder from './containers/SkillBuilderContainer'

export default {
  actions,
  saga,
  selectors,
  components: {
    EditorWithMenu,
    Formbuilder,
    SkillPage,
    SkillBuilder
  },
  routes,
  reducers: {
    entity,
    ui
  }
}
