import { ServerFailure } from '../../types'
import {
  RegisterState,
  RegisterRequest,
  RegisterSuccess,
  RegisterFailure,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAILURE
} from './types'

export const registerRequest: (inputs: RegisterState) => RegisterRequest = inputs => ({
  type: REGISTER_REQUEST,
  payload: inputs
})
export const registerSuccess: () => RegisterSuccess = () => ({
  type: REGISTER_SUCCESS
})
export const registerFailure: (error: ServerFailure) => RegisterFailure = error => ({
  type: REGISTER_FAILURE,
  payload: error
})

export { REGISTER_REQUEST, REGISTER_SUCCESS, REGISTER_FAILURE }
