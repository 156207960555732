const FETCH_MENUPERSISTENT_REQUEST = 'tcfdash/analytics/FETCH_MENUPERSISTENT_REQUEST'
const FETCH_MENUPERSISTENT_SUCCESS = 'tcfdash/analytics/FETCH_MENUPERSISTENT_SUCCESS'
const FETCH_MENUPERSISTENT_NO_DATA = 'tcfdash/analytics/FETCH_MENUPERSISTENT_NO_DATA'

const FETCH_MENUPERSISTENTUTIL_REQUEST = 'tcfdash/analytics/FETCH_MENUPERSISTENTUTIL_REQUEST'
const FETCH_MENUPERSISTENTUTIL_SUCCESS = 'tcfdash/analytics/FETCH_MENUPERSISTENTUTIL_SUCCESS'
const FETCH_MENUPERSISTENTUTIL_NO_DATA = 'tcfdash/analytics/FETCH_MENUPERSISTENTUTIL_NO_DATA'

export default {
  FETCH_MENUPERSISTENT_REQUEST,
  FETCH_MENUPERSISTENT_SUCCESS,
  FETCH_MENUPERSISTENT_NO_DATA,

  FETCH_MENUPERSISTENTUTIL_REQUEST,
  FETCH_MENUPERSISTENTUTIL_SUCCESS,
  FETCH_MENUPERSISTENTUTIL_NO_DATA
}
