import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import analytics from 'modules/analytics'
import answer_old from 'modules/answer_old'
import bot from 'modules/bot'
import broadcast from 'modules/broadcast'
import conversation from 'modules/conversation'
import entity from 'modules/entity'
import keyword from 'modules/keyword'
import monitor from 'modules/monitor'
import qa from 'modules/qa'
import skill from 'modules/skill'
import channels from 'modules/channels'
import apps from 'modules/apps'
import * as reactComponents from '@tolk-ai/react-components'
import '@tolk-ai/react-components/dist/react-components.esm.css'
import * as browserUtils from '@tolk-genii/browser-utils'
import * as authentication from '@tolk-genii/identity-and-access-management-authentication'

import { botIsPsa, botXRessource } from '../enhancers/auth'
import Analytics from '../pages/Analytics'
import PsaDefects from '../pages/PsaDefects'
import PsaPoste from '../pages/PsaPoste'
import PsaAndon from '../pages/PsaAndon'
import PsaCommentaires from '../pages/PsaCommentaires'
import Ressources from '../pages/Ressources'
import Intent from '../pages/Intents'
import NotFoundPage from '../pages/NotFound'
import Training from '../pages/Training'
import Admin from '../pages/Admin'
import Password from '../pages/Password'
import CreatePassword from '../pages/CreatePassword'
import Releases from '../pages/Releases'
import axios from 'axios'
import * as apis from '../pages/APIs'
import * as smartskills from '../pages/SmartSkills'

import {
  authManagementBaseUrl,
  oauthClientId,
  oauthRedirectUri
} from 'helpers/effects'

export const ROOT = '/'

export const ANALYTICS = '/analytics'
export const PSA_DEFECTS = '/defects'
export const PSA_POSTES = '/postes'
export const PSA_ANDON = '/andon'
export const PSA_COMMENTAIRES = '/commentaires'
export const CONVERSATIONS = '/conversations'

export const RESSOURCES = '/ressources'
export const INTENTS = '/intents'
export const BROADCAST = '/broadcast'

export const TRAINING = '/training'
export const TRAINING_NOT_MATCHED = '/training'
export const TRAINING_MATCHED = '/training/matched'
export const TRAINING_ARCHIVED = '/training/archived'

export const AUTH = '/auth'
export const LOGIN = '/login'
export const REGISTER = '/register'
export const LOGOUT = '/auth/logout'
export const ADMIN = '/admin'

export const ANALYTICS_EVENTS = '/analytics/events'

export const ANALYTICS_TRAFFIC_MESSAGES = '/analytics/traffic'
export const ANALYTICS_TRAFFIC_USERS = '/analytics/traffic/users'
export const ANALYTICS_TRENDS = '/analytics/trends'
export const ANALYTICS_TRENDS_BEHAVIOURS = '/analytics/trends/behaviours'
export const ANALYTICS_PERFORMANCE_DELAYS = '/analytics/performance'
export const ANALYTICS_PERFORMANCE_RETENTION =
  '/analytics/performance/retention'
export const ANALYTICS_PERFORMANCE_UNDERSTANDING =
  '/analytics/performance/understanding'

export const ADD_RESSOURCES = '/ressources/add'

export const PASSWORD = '/password'
export const CREATE_PASSWORD = '/createpassword/:id'
export const RELEASES = '/releases'
export const ENTITIES = '/entities'
export const KEYWORDS = '/keywords'

export const ANSWERS_CREATE = '/answers/:intent/create'
export const ANSWERS_EDIT = '/answers/:intent/edit'
export const SKILL = '/skill/:name'
export const CUSTOMER_APIS_LIST = '/customer-apis'
export const CUSTOMER_API_FORM = '/customer-apis/setup'
export const SMART_SKILLS_FORM = '/smart-skills/setup'
export const SMART_SKILLS_LIST = '/smart-skills'

const axiosAuthManagementExpressBaseUrl = axios.create({
  baseURL: authManagementBaseUrl
})

const cookieDomain = window._env_['COOKIE_DOMAIN']

const RessourcesPage = botXRessource(Ressources)
const PsaDefectsPage = botIsPsa(PsaDefects)
const PsaPostePage = botIsPsa(PsaPoste)
const PsaAndonPage = botIsPsa(PsaAndon)
const PsaCommentairesPage = botIsPsa(PsaCommentaires)
const loginComponent = reactComponents.login.container(
  window,
  authentication.createAuthorizationInfos(
    authentication.authorizationInfosModel.authorizationInfosFactory(
      authManagementBaseUrl,
      window.btoa
    ),
    authentication.localstorageAuthorizationInfosRepository.saveOneAuthorizationInfos(
      browserUtils.setCookie(document, cookieDomain)
    )
  ),
  authentication.completeAuthenticationProcess(
    authentication.localstorageAuthorizationInfosRepository.getOneAuthorizationInfos(
      window.localStorage
    ),
    authentication.localstorageAuthorizationInfosRepository.deleteOneAuthorizationInfos(
      window.localStorage
    ),
    authentication.axiosAuthenticationService.exchangeAuthorizationCodeWithAccessToken(
      axiosAuthManagementExpressBaseUrl
    ),
    authentication.cookiesAccessTokenResponseRepository.saveOneAccessTokenResponse(
      browserUtils.setCookie(document, cookieDomain)
    ),
    oauthClientId,
    oauthRedirectUri,
    'openid profile email role:tolk-admin'
  ),
  oauthClientId,
  oauthRedirectUri,
  browserUtils.refreshAccessToken(
    authManagementBaseUrl,
    browserUtils.removeCookie(document, cookieDomain),
    browserUtils.getCookie(document),
    browserUtils.setCookie(document, cookieDomain)
  )
)

const Routes = () => (
  <Switch>
    <Route
      exact
      path={ROOT}
      render={() => <Redirect to={qa.routes.QA_QUESTIONS} />}
    />
    <Route
      path={CONVERSATIONS}
      component={conversation.components.ConversationContainer}
    />
    <Route path={RESSOURCES} component={RessourcesPage} />
    <Route path={ANALYTICS} component={Analytics} />
    <Route
      path={analytics.routes.ANALYTICS_DASHBOARD}
      component={analytics.components.DashboardContainer}
    />
    <Route path={PSA_DEFECTS} component={PsaDefectsPage} />
    <Route path={PSA_POSTES} component={PsaPostePage} />
    <Route path={PSA_ANDON} component={PsaAndonPage} />
    <Route path={PSA_COMMENTAIRES} component={PsaCommentairesPage} />
    <Route path={INTENTS} component={Intent} />
    <Route path={TRAINING} component={Training} />
    <Route path={ADMIN} component={Admin} />
    <Route path={PASSWORD} component={Password} />
    <Route path={CREATE_PASSWORD} component={CreatePassword} />
    <Route path={BROADCAST} component={broadcast.containers.BroadcastManager} />

    <Route path={RELEASES} component={Releases} />
    <Route
      path={ANSWERS_CREATE}
      component={answer_old.components.CustomAnswerContainer}
    />
    <Route
      path={ANSWERS_EDIT}
      component={answer_old.components.CustomAnswerContainer}
    />
    <Route
      path={monitor.routes.MONITOR}
      component={monitor.components.MatchedQuestionsContainer}
    />
    <Route
      path={monitor.routes.MONITOR_UNMATCHED}
      component={monitor.components.UnmatchedQuestionsContainer}
    />
    <Route
      path={monitor.routes.OUT_OF_SCOPE}
      component={monitor.components.OutOfScopeContainer}
    />
    <Route
      path={bot.routes.BOT_CREATION}
      component={bot.components.CreationFormContainer}
    />
    <Route
      path={ANSWERS_CREATE}
      component={answer_old.components.CustomAnswerContainer}
    />
    <Route
      path={ANSWERS_EDIT}
      component={answer_old.components.CustomAnswerContainer}
    />
    <Route path={ENTITIES} component={entity.components.EntityIndex} />
    <Route path={KEYWORDS} component={keyword.components.KeywordIndex} />
    <Route path={qa.routes.QA_QUESTIONS} component={qa.components.QaRoot} />
    <Route
      path={skill.routes.SKILLS}
      component={skill.components.EditorWithMenu}
    />
    <Route
      path={skill.routes.SKILL_FORMS}
      component={skill.components.Formbuilder}
    />
    <Route
      path={skill.routes.ALL_SKILLS}
      component={skill.components.SkillPage}
    />
    <Route
      path={skill.routes.ALL_SKILLS}
      component={skill.components.SkillPage}
    />
    <Route path={LOGIN} component={loginComponent} />
    <Route
      path={skill.routes.SKILL_BUILDER}
      component={skill.components.SkillBuilder}
    />
    <Route path={bot.routes.WEBCHAT} component={bot.components.WebchatConfig} />
    <Route
      path={channels.routes.CHANNEL_ZENDESK}
      component={(data) => <channels.components.Zendesk />}
    />
    <Route
      path={channels.routes.CHANNEL_SHOPIFY}
      component={(data) => <channels.components.Shopify />}
    />
    <Route
      path={channels.routes.CHANNEL_INSTAGRAM}
      component={(data) => <channels.components.Instagram />}
    />
    <Route
      path={channels.routes.CHANNEL_MESSENGER}
      component={(data) => <channels.components.Messenger />}
    />
    <Route
      path={channels.routes.CHANNEL_SETTINGS}
      component={(data) => (
        <channels.components.ChannelSettingsContainer
          channelName={channels.config[data.match.params.slug].name}
          channelFields={channels.config[data.match.params.slug].fields}
          channelSlug={data.match.params.slug}
        />
      )}
    />
    <Route
      path={channels.routes.CHANNELS_LIST}
      component={channels.components.ChannelsList}
    />
    <Route
      path={apps.routes.APP_SETTINGS}
      component={(data) => (
        <apps.components.AppSettingsContainer
          appName={apps.config[data.match.params.slug].name}
          appId={apps.config[data.match.params.slug].id}
          appFields={apps.config[data.match.params.slug].fields}
          appSlug={data.match.params.slug}
        />
      )}
    />
    <Route
      path={CUSTOMER_API_FORM}
      component={apis.CustomerApiForm}
    />
    <Route
      path={CUSTOMER_APIS_LIST}
      component={apis.CustomerApisList}
    />
    <Route
      path={SMART_SKILLS_FORM}
      component={smartskills.SmartSkillsForm}
    />
    <Route
      path={SMART_SKILLS_LIST}
      component={smartskills.SmartSkills}
    />
    <Route component={NotFoundPage} />
  </Switch>
)

export default Routes
