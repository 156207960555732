import { campaignsApiUrl } from 'helpers/effects'
import { formatCampaignToSend } from './helpers'

import * as R from 'ramda'

const fetchBroadcastApi = async ({
  botId,
  path,
  method = 'GET',
  body,
  headers = { 'Content-Type': 'application/json' },
  json = true
}) =>
  fetch(`${campaignsApiUrl}/bot/${botId}${path}`, {
    method,
    headers: new Headers(headers),
    body
  }).then(res => {
    if (json) return res.json()
    return res.text()
  })

// Mock
export const fetchAnalytics = R.memoizeWith(
  (botId, campaignId) => `${botId}-${campaignId}`,
  async (botId, campaignId) => {
    const kpis = await fetchBroadcastApi({
      botId,
      path: `/campaigns/${campaignId}/kpis`,
      method: 'GET'
    })
    return kpis.value.read
  }
)

export const cancelCampaignDelete = async (botId, campaignId) =>
  fetchBroadcastApi({
    botId,
    path: '/campaigns/cancelDeletion',
    method: 'POST',
    body: JSON.stringify({ campaignId })
  })

export const postCampaign = async (botId, campaign) =>
  fetchBroadcastApi({
    botId,
    path: '/campaigns',
    method: 'POST',
    body: JSON.stringify(formatCampaignToSend(campaign))
  })

export const duplicateCampaign = async (botId, campaign) =>
  fetchBroadcastApi({
    botId,
    path: '/campaigns/duplicate',
    method: 'POST',
    body: JSON.stringify(formatCampaignToSend(campaign))
  })

export const deleteCampaign = async (botId, campaignId) =>
  fetchBroadcastApi({
    botId,
    path: '/campaigns/' + campaignId,
    method: 'DELETE',
    json: false
  })

export const sendCampaign = async (botId, campaign) =>
  fetchBroadcastApi({
    botId,
    path: '/broadcast',
    method: 'POST',
    body: JSON.stringify(formatCampaignToSend(campaign))
  })

export const scheduleCampaign = async (botId, campaign) =>
  fetchBroadcastApi({
    botId,
    path: '/campaigns/schedule',
    method: 'POST',
    body: JSON.stringify(formatCampaignToSend(campaign))
  })

export const sendPreview = async (botId, campaign, users) =>
  fetchBroadcastApi({
    botId,
    path: '/broadcast/preview',
    method: 'POST',
    body: JSON.stringify({
      campaign: formatCampaignToSend(campaign),
      userIds: users
    })
  })

export const getCampaigns = async botId =>
  fetchBroadcastApi({
    botId,
    path: '/campaigns'
  })

export const getCohorts = async botId =>
  fetchBroadcastApi({
    botId,
    path: '/cohort/?extended=true'
  })

export const uploadFile = async (botId, formData) =>
  fetchBroadcastApi({
    botId,
    headers: {},
    method: 'POST',
    path: '/upload',
    body: formData,
    json: false
  })
