import React from 'react'
import moment from 'moment-timezone'
import SelectOutlinedUI, { SelectStyled } from '../SelectOutlinedUI'

const TimeZonePicker = ({ changeDate, date, disabled }) => {
  return (
    <SelectOutlinedUI as='div'>
      <SelectStyled
        disabled={disabled}
        value={moment(date).tz()}
        onChange={e => changeDate(moment.tz(date, e.target.value))}
      >
        {moment.tz.names().map(zone => (
          <option key={zone} value={zone}>
            {zone}
          </option>
        ))}
      </SelectStyled>
    </SelectOutlinedUI>
  )
}

export default TimeZonePicker
