import types from './types'

const initialState = {
  fetching: false,
  fetchingUnsat: false,
  data: {},
  graph: {},
  globalSatisfaction: {},
  dataUnsat: null
}

const satisfactionReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.FETCH_SATISFACTION_REQUEST:
    return { ...state, fetching: true }

  case types.FETCH_SATISFACTION_NO_DATA:
    return { ...state, fetching: false }

  case types.FETCH_SATISFACTION_SUCCESS:
    return {
      ...state,
      fetching: false,
      data: action.payload.data,
      graph: action.payload.graph
    }
  case types.FETCH_GLOBAL_SATISFACTION_REQUEST:
    return { ...state, fetching: true }

  case types.FETCH_GLOBAL_SATISFACTION_NO_DATA:
    return { ...state, fetching: false }

  case types.FETCH_GLOBAL_SATISFACTION_SUCCESS:
    return { ...state, fetching: false, globalSatisfaction: action.payload.data }

  case types.FETCH_UNSATISFACTION_REQUEST:
    return { ...state, fetchingUnsat: true }

  case types.FETCH_UNSATISFACTION_NO_DATA:
    return { ...state, fetchingUnsat: false }

  case types.FETCH_UNSATISFACTION_SUCCESS:
    return { ...state, fetchingUnsat: false, dataUnsat: action.payload.data }

  default:
    return state
  }
}

export default satisfactionReducer
