import React from 'react'

import matchingFlags from 'utils/matchingFlags'

import './styles.css'

function ByLanguageCounter({ language, count }) {
  return (
    <div className='KeywordCounter'>
      <span className='KeywordCounter-flag'>{matchingFlags[language]}</span>
      <span className='KeywordCounter-label'>{count.toString()}</span>
    </div>
  )
}

export default ByLanguageCounter
