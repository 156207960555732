import React from 'react'
import UploadFile from '../UploadFile'
import { VideoStyled } from './styles'


const ElementVideo = ({ settings, url, temporaryUrl, saveVideo, deleteVideo, changeVideo, canEdit }) => (
  <UploadFile
    {...settings.media}
    uploadType='video'
    url={url}
    temporaryUrl={temporaryUrl}
    changeFile={changeVideo}
    saveFile={saveVideo}
    deleteFile={deleteVideo}
    canEdit={canEdit}
  >
    {(url || temporaryUrl) && <VideoStyled src={url || temporaryUrl} controls={true} />}
  </UploadFile>
)

export default ElementVideo
