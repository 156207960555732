import 'react-dates/initialize'
import React from 'react'
import { Grid } from 'semantic-ui-react'
import PassForm from './passForm'

const ChangePassPage = () => (
  <div>
    <Grid>
      <Grid.Column mobile={8} tablet={7} computer={7} widescreen={5}>
        <PassForm />
      </Grid.Column>
    </Grid>
  </div>
)

export default ChangePassPage
