import React from 'react'

import './Log.css'

export default function Log({ log }) {
  return (
    <div className='Log'>
      <span className='Log-createdAt'>{log.createdAt}: &nbsp;</span>
      <span className='Log-login'>{log.login}</span>
      <span className='Log-ip'>&nbsp;connected with IP {log.ip}</span>
    </div>
  )
}
