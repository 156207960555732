export default {
  credits: {
    enabled: false
  },
  tooltip: {
    shared: true
  },
  yAxis: {
    lineWidth: 1,
    min: 0,
    title: {
      text: null
    }
  },
  xAxis: {
    lineWidth: 1,
    min: 0,
    title: {
      text: null
    }
  },
  plotOptions: {
    spline: {
      lineWidth: 2.5,
      marker: {
        enabled: true,
        radius: 3
      }
    },
    area: {
      fillOpacity: 0.25,
      stacking: 'normal',
      marker: {
        enabled: true,
        radius: 3
      }
    },
    areaspline: {
      fillOpacity: 0.25,
      stacking: 'normal',
      marker: {
        enabled: true,
        radius: 3
      }
    }
  },
  title: {
    text: null
  }
}
