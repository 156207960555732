import types from './types'

const initialState = {
  fetching: false,
  data: []
}

const messagesHeatReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.FETCH_MESSAGESHEAT_REQUEST:
    return { ...state, fetching: true, data: null }

  case types.FETCH_MESSAGESHEAT_NO_DATA:
    return { ...state, fetching: false }

  case types.FETCH_MESSAGESHEAT_SUCCESS:
    return {
      ...state,
      fetching: false,
      data: action.payload.data
    }

  default:
    return state
  }
}

export default messagesHeatReducer
