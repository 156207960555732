import { connect } from 'react-redux'

import Notifications from '../components/Notifications'
import * as notificationsSelector from '../selectors'

import * as notificationActions from '../actions'

const mapStateToProps = state => ({
  notifications: notificationsSelector.getState(state)
})

const mapDispatchToProps = {
  popNotification: notificationActions.pop
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifications)
