const GET_DEFECTCREATE_REQUEST = 'tcfdash/auth/GET_DEFECTCREATE_REQUEST'
const GET_DEFECTCREATE_SUCCESS = 'tcfdash/auth/GET_DEFECTCREATE_SUCCESS'
const GET_DEFECTCREATE_FAILURE = 'tcfdash/auth/GET_DEFECTCREATE_FAILURE'

const GET_DEFECTSETTLE_REQUEST = 'tcfdash/auth/GET_DEFECTSETTLE_REQUEST'
const GET_DEFECTSETTLE_SUCCESS = 'tcfdash/auth/GET_DEFECTSETTLE_SUCCESS'
const GET_DEFECTSETTLE_FAILURE = 'tcfdash/auth/GET_DEFECTSETTLE_FAILURE'

const GET_PRISEPOSTE_REQUEST = 'tcfdash/auth/GET_PRISEPOSTE_REQUEST'
const GET_PRISEPOSTE_SUCCESS = 'tcfdash/auth/GET_PRISEPOSTE_SUCCESS'
const GET_PRISEPOSTE_FAILURE = 'tcfdash/auth/GET_PRISEPOSTE_FAILURE'

const GET_SORTIEPOSTE_REQUEST = 'tcfdash/auth/GET_SORTIEPOSTE_REQUEST'
const GET_SORTIEPOSTE_SUCCESS = 'tcfdash/auth/GET_SORTIEPOSTE_SUCCESS'
const GET_SORTIEPOSTE_FAILURE = 'tcfdash/auth/GET_SORTIEPOSTE_FAILURE'

const GET_ANDON_REQUEST = 'tcfdash/auth/GET_ANDON_REQUEST'
const GET_ANDON_SUCCESS = 'tcfdash/auth/GET_ANDON_SUCCESS'
const GET_ANDON_FAILURE = 'tcfdash/auth/GET_ANDON_FAILURE'

const GET_COMMENTAIRES_REQUEST = 'tcfdash/auth/GET_COMMENTAIRES_REQUEST'
const GET_COMMENTAIRES_SUCCESS = 'tcfdash/auth/GET_COMMENTAIRES_SUCCESS'
const GET_COMMENTAIRES_FAILURE = 'tcfdash/auth/GET_COMMENTAIRES_FAILURE'

export default {
  GET_DEFECTCREATE_REQUEST,
  GET_DEFECTCREATE_SUCCESS,
  GET_DEFECTCREATE_FAILURE,

  GET_DEFECTSETTLE_REQUEST,
  GET_DEFECTSETTLE_SUCCESS,
  GET_DEFECTSETTLE_FAILURE,

  GET_PRISEPOSTE_REQUEST,
  GET_PRISEPOSTE_SUCCESS,
  GET_PRISEPOSTE_FAILURE,

  GET_SORTIEPOSTE_REQUEST,
  GET_SORTIEPOSTE_SUCCESS,
  GET_SORTIEPOSTE_FAILURE,

  GET_ANDON_REQUEST,
  GET_ANDON_SUCCESS,
  GET_ANDON_FAILURE,

  GET_COMMENTAIRES_REQUEST,
  GET_COMMENTAIRES_SUCCESS,
  GET_COMMENTAIRES_FAILURE
}
