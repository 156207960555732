import React from 'react'
import WordCloud from 'react-d3-cloud'

const SuperWordCloud = words => {
  const data = words.words
  const fontSizeMapper = word => Math.log10(word.value) * 24
  const rotate = word => word.value % 1

  return <WordCloud data={data} fontSizeMapper={fontSizeMapper} rotate={rotate} padding='6' font='Roboto' />
}

export default SuperWordCloud
