import * as actions from './actions'
import * as selectors from './selectors'
import * as entity from './reducer.entity'
import * as ui from './reducer.ui'
import * as saga from './saga'
import * as models from './models'
import * as routes from './routes'
import CreationFormContainer from './containers/CreationFormContainer'
import PublishButton from './containers/PublishButtonContainer'
import WebchatConfig from './containers/WebchatConfigContainer'
import EmptyBotPage from './components/EmptyBotPage/EmptyBotPage'

export default {
  actions,
  components: {
    CreationFormContainer,
    PublishButton,
    WebchatConfig,
    EmptyBotPage
  },
  selectors,
  reducers: {
    entity,
    ui
  },
  routes,
  saga,
  models
}
