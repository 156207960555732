import { call, put, takeLatest } from 'redux-saga/effects'

import Api from 'services/tolkApi'

import * as registerActions from './actions'
import { RegisterRequest } from './types'

export function* register ({ payload }: RegisterRequest) {
  try {
    yield call(Api.post, 'register', payload)

    yield put(registerActions.registerSuccess())
  } catch (error) {
    const serverFailure = error.response.data
    yield put(registerActions.registerFailure(serverFailure))
  }
}

export function* root () {
  yield takeLatest(registerActions.REGISTER_REQUEST, register)
}
