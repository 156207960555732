import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Container } from 'semantic-ui-react'
import ReduxToastr from 'react-redux-toastr'
import { connect } from 'react-redux'
import { remoteData, pipe } from '@sbizeul/fp-flow'
import axios from 'axios'

import bot from 'modules/bot'
import channel from 'modules/channel'
import Notifications from 'modules/notifications/containers/Notifications'
import user from 'modules/user'
import WebchatContainer from 'modules/bot/containers/WebchatContainer'
import { placeholder3 } from 'helpers/placeholders'

import './App.css'
import './Highcharts.css'
import '../pages/Analytics/components/customHighchart'
import NavbarMenu from './Navbar'
import Routes from './Routes'
import TopbarContainer from './Topbar'
import ErrorMessage from './ErrorMessage'
import Loader from './Loader'
import EmptyBotPage from '../../modules/bot/components/EmptyBotPage/EmptyBotPage'
import * as accountManagementClients from '@tolk-ai/account-management-clients'
import * as uiLibraryReact from '@tolk-ai/ui-library-react'
import { accountManagementBaseUrl, accountManagementReactBaseUrl, authManagementBaseUrl, cookieDomain } from 'helpers/effects'
import * as browserUtils from '@tolk-genii/browser-utils'
import * as genericUtils from '@tolk-genii/generic-utils'

const accountManagementExpressAxios = axios.create({
  baseURL: accountManagementBaseUrl
})

const Banner = uiLibraryReact.Banner

class App extends Component {
  async componentDidMount() {
    if (browserUtils.isAuthenticated()) {
      const userId = browserUtils.getAuthenticatedUserId()

      const authenticatedRequest = browserUtils.authenticatedRequest(
        accountManagementExpressAxios,
        authManagementBaseUrl,
        browserUtils.removeCookie(document, cookieDomain),
        browserUtils.getCookie(document),
        browserUtils.setCookie(document, cookieDomain)
      )
      const clientDetails = await accountManagementClients.axiosLocalStorageClients.getOneClientDetails(authenticatedRequest, window.localStorage)(userId)
      if (!genericUtils.isDomainError(clientDetails, accountManagementClients.appPorts.GetOneClientDetailsErrors)) {
        if (
          clientDetails.currentPlan !== null &&
          clientDetails.currentPlan.planId === 'free' &&
          clientDetails.currentPlan.areLimitsReached === true
        ) {
          this.setState({
            banner: { type: 'free-plan-limits-reached' }
          })
        }

        if (
          clientDetails.currentPlan !== null &&
          ['start', 'grow'].includes(clientDetails.currentPlan.planId) &&
          clientDetails.hasPaymentMethod === false
        ) {
          this.setState({
            banner: { type: 'no-payment-method' }
          })
        }
      }
      this.setState({
        bots: clientDetails.bots
      })
    }

    this.props.login()
  }

  renderLoading = () => <Loader message='Loading... please wait' />

  renderApp = () => {
    const { fetchError, fetchErrorMessage } = this.props
    return (
      <div className='headerDiv'>
        <TopbarContainer PublishButton={<bot.components.PublishButton />} bots={this.state?.bots} />
        <Container className='mainContainer' fluid>
          {fetchError && <ErrorMessage detail={fetchErrorMessage} />}
          <div className='App-navbarMenu'>
            <NavbarMenu />
          </div>
          <div id='App-content' className='App-content'>
            {this.state?.banner?.type === 'no-payment-method' && (
              <Banner
                link={{
                  label: 'Add credit card',
                  url: `${accountManagementReactBaseUrl}/payment-method`,
                  type: 'external'
                }}>
                You don’t have any payment method
              </Banner>
            )}
            {this.state?.banner?.type === 'free-plan-limits-reached' && (
              <Banner
                link={{
                  label: 'Upgrade now',
                  url: `${accountManagementReactBaseUrl}/pricing-plans`,
                  type: 'external'
                }}>
                <>
                  <span aria-label="Current plan is locked" role="img">
                    🔒
                  </span>{' '}
                  You have exceeded your free plan
                </>
              </Banner>
            )}
            <Container className='mainContent' fluid>
              <Routes className='mainContent' />
            </Container>
          </div>
          <div className='App-webchat'>
            <WebchatContainer />
          </div>
        </Container>
        <Notifications />
        <ReduxToastr />
      </div>
    )
  }

  renderWithoutBot = () => <EmptyBotPage />
  renderWithBot = () => pipe(
    remoteData.map3(placeholder3, this.props.user, this.props.bots),
    remoteData.fold({
      Success: this.renderApp,
      Failure: this.renderApp,
      Loading: this.renderLoading,
      NotAsked: this.renderLoading
    })
  )(this.props.channels)

  render = () => remoteData.fold({
    Success: (bots) => (
      bots.length === 0
        ? this.renderWithoutBot()
        : this.renderWithBot()
    ),
    Failure: this.renderApp,
    Loading: this.renderLoading,
    NotAsked: this.renderLoading
  })(this.props.bots)
}

const mapDispatchToProps = {
  login: user.actions.login
}

const mapStateToProps = state => ({
  bots: bot.selectors.getAll(state),
  channels: channel.selectors.getBySelectedBot(state),
  user: user.selectors.getUser(state)
})

export default pipe(connect(mapStateToProps, mapDispatchToProps), withRouter)(App)
