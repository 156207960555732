const FETCH_MISUNDERSTOOD_REQUEST = 'tcfdash/analytics/FETCH_MISUNDERSTOOD_REQUEST'
const FETCH_MISUNDERSTOOD_SUCCESS = 'tcfdash/analytics/FETCH_MISUNDERSTOOD_SUCCESS'
const FETCH_MISUNDERSTOOD_NO_DATA = 'tcfdash/analytics/FETCH_MISUNDERSTOOD_NO_DATA'

const FETCH_MISUNDERSTOOD_AVERAGE_REQUEST = 'tcfdash/analytics/FETCH_MISUNDERSTOOD_AVERAGE_REQUEST'
const FETCH_MISUNDERSTOOD_AVERAGE_SUCCESS = 'tcfdash/analytics/FETCH_MISUNDERSTOOD_AVERAGE_SUCCESS'
const FETCH_MISUNDERSTOOD_AVERAGE_NO_DATA = 'tcfdash/analytics/FETCH_MISUNDERSTOOD_AVERAGE_NO_DATA'

export default {
  FETCH_MISUNDERSTOOD_REQUEST,
  FETCH_MISUNDERSTOOD_SUCCESS,
  FETCH_MISUNDERSTOOD_NO_DATA,

  FETCH_MISUNDERSTOOD_AVERAGE_REQUEST,
  FETCH_MISUNDERSTOOD_AVERAGE_SUCCESS,
  FETCH_MISUNDERSTOOD_AVERAGE_NO_DATA
}
