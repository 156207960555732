import 'react-dates/initialize'
import React from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import PasswordContent from './Pass'
import operations from '../../../redux/intents/operations'

const PasswordPage = () => (
  <div>
    <Switch>
      <Route exact path='/createpassword/:id' component={PasswordContent} />
    </Switch>
  </div>
)

const PassContentPage = connect({}, operations)(PasswordPage)

export default PassContentPage
