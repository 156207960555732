import {
  FetchLinkedKeywordElementRequest,
  FetchLinkedKeywordElementSuccess,
  FetchLinkedKeywordElementFailure,
  FetchLinkedKeywordElementRequestParam,
  FetchLinkedKeywordElementSuccessParam,
  FetchLinkedKeywordElementFailureParam,
  FETCH_LINKED_KEYWORD_ELEMENT_REQUEST,
  FETCH_LINKED_KEYWORD_ELEMENT_SUCCESS,
  FETCH_LINKED_KEYWORD_ELEMENT_FAILURE,
  DeleteKeywordRequest,
  DeleteKeywordSuccess,
  DeleteKeywordFailure,
  DeleteKeywordRequestParam,
  DeleteKeywordSuccessParam,
  DeleteKeywordFailureParam,
  DELETE_KEYWORD_REQUEST,
  DELETE_KEYWORD_SUCCESS,
  DELETE_KEYWORD_FAILURE,
  UPDATE_PERSIST_KEYWORD_REQUEST,
  UPDATE_PERSIST_KEYWORD_SUCCESS,
  UPDATE_PERSIST_KEYWORD_FAILURE,
  UpdatePersistKeywordStatusRequestParam, UpdatePersistKeywordStatusRequest,
  UpdatePersistKeywordStatusSuccess,
  UpdatePersistKeywordStatusFailureParam, UpdatePersistKeywordStatusFailure
} from './types'

const fetchLinkedKeywordElementRequest: (linkedKeywordElementRequestParam: FetchLinkedKeywordElementRequestParam) => FetchLinkedKeywordElementRequest =
linkedKeywordElementRequestParam => {
  return {
    type: FETCH_LINKED_KEYWORD_ELEMENT_REQUEST,
    payload: linkedKeywordElementRequestParam
  }
}

const fetchLinkedKeywordElementSuccess: (linkedKeywordElementSuccessParam: FetchLinkedKeywordElementSuccessParam) => FetchLinkedKeywordElementSuccess =
linkedKeywordElementSuccessParam => {
  return {
    type: FETCH_LINKED_KEYWORD_ELEMENT_SUCCESS,
    payload: linkedKeywordElementSuccessParam
  }
}

const fetchLinkedKeywordElementFailure: (fetchLinkedKeywordElementFailureParam: FetchLinkedKeywordElementFailureParam) => FetchLinkedKeywordElementFailure =
fetchLinkedKeywordElementFailureParam => {
  return {
    type: FETCH_LINKED_KEYWORD_ELEMENT_FAILURE,
    payload: fetchLinkedKeywordElementFailureParam
  }
}

const deleteKeywordRequest: (deleteKeywordRequestParam: DeleteKeywordRequestParam) => DeleteKeywordRequest =
deleteKeywordRequestParam => {
  return {
    type: DELETE_KEYWORD_REQUEST,
    payload: deleteKeywordRequestParam
  }
}

const deleteKeywordSuccess: (deleteKeywordSuccessParam: DeleteKeywordSuccessParam) => DeleteKeywordSuccess =
deleteKeywordSuccessParam => {
  return {
    type: DELETE_KEYWORD_SUCCESS,
    payload: deleteKeywordSuccessParam
  }
}

const deleteKeywordFailure: (deleteKeywordFailureParam: DeleteKeywordFailureParam) => DeleteKeywordFailure =
deleteKeywordFailureParam => {
  return {
    type: DELETE_KEYWORD_FAILURE,
    payload: deleteKeywordFailureParam
  }
}

const updatePersistKeywordStatusRequest: (updatePersistKeywordRequestParam: UpdatePersistKeywordStatusRequestParam) => UpdatePersistKeywordStatusRequest =
  updatePersistKeywordRequestParam => ({
    type: UPDATE_PERSIST_KEYWORD_REQUEST,
    payload: updatePersistKeywordRequestParam
  })
const updatePersistKeywordStatusSuccess: () => UpdatePersistKeywordStatusSuccess = () => ({
  type: UPDATE_PERSIST_KEYWORD_SUCCESS
})
const updatePersistKeywordStatusFailure: (updatePersistKeywordFailureParam: UpdatePersistKeywordStatusFailureParam) => UpdatePersistKeywordStatusFailure =
  updatePersistKeywordFailureParam => ({
    type: UPDATE_PERSIST_KEYWORD_FAILURE,
    payload: updatePersistKeywordFailureParam
  })

export {
  fetchLinkedKeywordElementRequest,
  fetchLinkedKeywordElementSuccess,
  fetchLinkedKeywordElementFailure,
  updatePersistKeywordStatusRequest,
  updatePersistKeywordStatusSuccess,
  updatePersistKeywordStatusFailure,
  deleteKeywordRequest,
  deleteKeywordSuccess,
  deleteKeywordFailure,

  FETCH_LINKED_KEYWORD_ELEMENT_REQUEST,
  FETCH_LINKED_KEYWORD_ELEMENT_SUCCESS,
  FETCH_LINKED_KEYWORD_ELEMENT_FAILURE,
  DELETE_KEYWORD_REQUEST,
  DELETE_KEYWORD_SUCCESS,
  DELETE_KEYWORD_FAILURE,
  UPDATE_PERSIST_KEYWORD_REQUEST,
  UPDATE_PERSIST_KEYWORD_SUCCESS,
  UPDATE_PERSIST_KEYWORD_FAILURE
}
