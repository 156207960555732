import { connect } from 'react-redux'

import { AppState as BotAppState } from 'modules/bot/types'

import * as actions from '../actions'
import * as selectors from '../selectors'
import FormBuilder from '../components/FormBuilder/FormBuilder'
import { AppState } from '../types'

type State = AppState & BotAppState

const mapStateToProps = (state: State) => ({
  formSkill: selectors.getFormSkill(state),
  formId: selectors.getFormSkillId(state),
  formResponse: selectors.getFormResponse(state),
  formResponseError: selectors.getFormResponseError(state),
  isModified: selectors.getIsModified(state),
  isPosting: selectors.getIsPosting(state),
  label: selectors.getLabel(state)
})

const mapDispatchToProps = {
  fetch: actions.fetchForm,
  fetchFormResponse: actions.fetchFormResponse,
  createFormContent: actions.createFormContent,
  createFormEmailContent: actions.createFormEmailContent,
  createFormFileContent: actions.createFormFileContent,
  removeContent: actions.removeFormContent,
  saveForm: actions.saveFormRequest,
  updateContent: actions.updateFormContent,
  updateFormConclusion: actions.updateFormConclusion,
  saveFormResponse: actions.saveFormReponse
}

export default connect(mapStateToProps, mapDispatchToProps)(FormBuilder)
