import getTimeDelta from './getTimeDelta'

const visiblePeriods = (start, end) => {
  const timeDelta = getTimeDelta(start, end)
  if (timeDelta.asHours() < 48) {
    return [0, 1, 2]
  }
  if (timeDelta.asDays() >= 15) {
    return [2]
  }
  return [1, 2]
}

export default visiblePeriods
