import { maybe } from '@sbizeul/fp-flow'

import actions from '../actions'
import isResValid from '../../../_shared/isResValid'
import formatFilters from '../../../_shared/formatFilters'
import { groupAverageByDayUsers } from '../../../../views/pages/Analytics/utils'
import bot from '../../../../modules/bot'
import api from 'services/tolkApiLegacy'

const fetchUsersAverageByDay = rawFilters => (dispatch, getState) => {
  const { channelId, start, end } = formatFilters(rawFilters)
  const botId = bot.selectors.getSelectedId(getState())

  dispatch(actions.fetchUsersAverageByDayRequest())
  return api.get(
    `/analytics/${maybe.get(botId)}/${channelId}/users`,
    {
      params: {
        start,
        end,
        period: 'day'
      }
    }
  ).then(response => {
      if (isResValid(response)) {
        const usersAverageByDay = groupAverageByDayUsers(response.data.result.data)
        dispatch(
          actions.fetchUsersAverageByDaySuccess({
            usersAverageByDay
          })
        )
      } else {
        dispatch(actions.fetchUsersAverageByDayFailure())
      }
    })
    .catch(err => {
      dispatch(actions.fetchUsersAverageByDayFailure(err))
    })
}

export default fetchUsersAverageByDay
