import styled from 'styled-components'
import OutlinedUIStyled from '../OutlinedUI/styles'

const SelectOutlinedUIStyled = styled(OutlinedUIStyled)`
  position: relative;
  margin-top: 0px !important;
  padding: 0px !important;
  overflow: hidden;
  > :first-child {
    padding: ${({
    theme: {
      padding: { YAxis, XAxis }
    }
  }) => `${YAxis.outlinedUI}px ${XAxis.outlinedUI}px`};
    padding-right: ${({ theme }) => theme.width.selectArrow + 2 * theme.padding.XAxis.outlinedUI}px !important;
  }
  &:not(:first-of-type) {
    margin-left: ${({ theme }) => theme.margin.left.selectIcon}px;
  }
  > .Navbar-menuItem {
    position: absolute;
    margin-top: auto;
    margin-bottom: auto;
    width: ${({ theme }) => theme.width.selectArrow}px !important;
    right: ${({ theme }) => theme.padding.XAxis.outlinedUI}px;
  }
`
const SelectStyled = styled.select`
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  border: none;
  background: transparent;
  font-family: inherit;
  font-weight: inherit;
  color: inherit;
  pointer-events: auto;
  cursor: pointer;
  ${props =>
    props.disabled &&
    `{
    pointer-events: none;
  }`}
`

export { SelectOutlinedUIStyled, SelectStyled }
