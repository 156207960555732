
import React from 'react'
import { Link } from 'react-router-dom'

import { BASE_SKILL_LABELS, ONBOARDING } from '../../types'

import * as routes from '../../routes'

const OnboardingButton = () => (
  <Link className='skill-default--button skill-onboarding-button' to={routes.skillRoute(ONBOARDING)}>
    <p className='skill-default--label'>{BASE_SKILL_LABELS[ONBOARDING]}</p>
  </Link>
)

export default OnboardingButton
