import { remoteData } from '@sbizeul/fp-flow'
import { lensProp, set } from 'ramda'

import * as actions from './actions'
import {
  CreateQuestionFailure,
  CreateQuestionSuccess,
  FetchFailure,
  FetchOutOfScopeFailure,
  FetchOutOfScopeSuccess,
  FetchSuccess,
  MonitorEntityState,
  RegisterMonitorAction,
  FetchProgressSuccess,
  FetchProgressFailure
} from './types'

export const initialState: MonitorEntityState = {
  logs: remoteData.notAsked(),
  logsOutOfScope: remoteData.notAsked(),
  createdQuestionId: remoteData.notAsked(),
  progress: remoteData.notAsked()
}

const logs = lensProp('logs')
const logsOutOfScope = lensProp('logsOutOfScope')
const createdQuestionId = lensProp('createdQuestionId')
const progress = lensProp('progress')

const fetchRequest = (state: MonitorEntityState) =>
  set(logs, remoteData.loading(), state)

const fetchSuccess = (state: MonitorEntityState, action: FetchSuccess) =>
  set(logs, remoteData.success(action.payload), state)

const fetchFailure = (state: MonitorEntityState, action: FetchFailure) =>
  set(logs, remoteData.failure(action.payload), state)

const fetchProgressRequest = (state: MonitorEntityState) =>
  set(progress, remoteData.loading(), state)

const fetchProgressSuccess = (state: MonitorEntityState, action: FetchProgressSuccess) =>
  set(progress, remoteData.success(action.payload), state)

const fetchProgressFailure = (state: MonitorEntityState, action: FetchProgressFailure) =>
  set(progress, remoteData.failure(action.payload), state)

const fetchOutOfScopeRequest = (state: MonitorEntityState) =>
  set(logsOutOfScope, remoteData.loading(), state)

const fetchOutOfScopeSuccess = (state: MonitorEntityState, action: FetchOutOfScopeSuccess) =>
  set(logsOutOfScope, remoteData.success(action.payload), state)

const fetchOutOfScopeFailure = (state: MonitorEntityState, action: FetchOutOfScopeFailure) =>
  set(logsOutOfScope, remoteData.failure(action.payload), state)

const createQuestionRequest = (state: MonitorEntityState) =>
  set(createdQuestionId, remoteData.notAsked(), state)

const createQuestionSuccess = (state: MonitorEntityState, action: CreateQuestionSuccess) =>
  set(createdQuestionId, remoteData.of(action.payload), state)

const createQuestionFailure = (state: MonitorEntityState, action: CreateQuestionFailure) =>
  set(createdQuestionId, remoteData.failure(action.payload), state)

const cleanCreatedQuestion = createQuestionRequest

export default function (state = initialState, action: RegisterMonitorAction) {
  switch (action.type) {
    case actions.MONITOR_FETCH_REQUEST:
      return fetchRequest(state)
    case actions.MONITOR_FETCH_SUCCESS:
      return fetchSuccess(state, action)
    case actions.MONITOR_FETCH_FAILURE:
      return fetchFailure(state, action)
    case actions.MONITOR_FETCH_PROGRESS_REQUEST:
      return fetchProgressRequest(state)
    case actions.MONITOR_FETCH_PROGRESS_SUCCESS:
      return fetchProgressSuccess(state, action)
    case actions.MONITOR_FETCH_PROGRESS_FAILURE:
      return fetchProgressFailure(state, action)
    case actions.MONITOR_FETCH_OUT_OF_SCOPE_REQUEST:
      return fetchOutOfScopeRequest(state)
    case actions.MONITOR_FETCH_OUT_OF_SCOPE_SUCCESS:
      return fetchOutOfScopeSuccess(state, action)
    case actions.MONITOR_FETCH_OUT_OF_SCOPE_FAILURE:
      return fetchOutOfScopeFailure(state, action)
    case actions.CREATE_QUESTION_REQUEST:
      return createQuestionRequest(state)
    case actions.CREATE_QUESTION_SUCCESS:
      return createQuestionSuccess(state, action)
    case actions.CREATE_QUESTION_FAILURE:
      return createQuestionFailure(state, action)
    case actions.CLEAN_CREATED_QUESTION:
      return cleanCreatedQuestion(state)
    default:
      return state
  }
}
