import React from 'react'
import { Grid, Segment } from 'semantic-ui-react'

import NoDataSegment from '../../../components/NoDataSegment'
import * as conversationsChart from './components/conversations'

import '../../index.css'

const style = {
  borderRadius: '3px',
  border: 'none',
  boxShadow: '0 2px 4px rgba(50, 50, 93,.1)',
  padding: '5px'
}

const Messages = ({ filters, usersData }) => {
  const startDate = filters.dateRange.start.format('YYYY-MM-DD')

  const endDate = filters.dateRange.end.format('YYYY-MM-DD')
  return (
    <Grid.Row>
      <Grid.Column mobile={16} tablet={16} computer={16} widescreen={16}>
        <h1>Conversations</h1>
        {usersData ? (
          <Segment style={style}>
            <conversationsChart.Component
              channelId={filters.channel.id}
              endDate={new Date(endDate)}
              startDate={new Date(startDate)}
            />
          </Segment>
        ) : (
          <NoDataSegment />
        )}
      </Grid.Column>
    </Grid.Row>
  )
}

Messages.defaultProps = {
  usersData: null
}

export default Messages
