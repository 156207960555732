import React from 'react'
import { Message, Icon } from 'semantic-ui-react'

const ErrorMessage = ({ detail }) => (
  <Message negative icon>
    <Icon name='warning sign' />
    <Message.Content>
      <Message.Header>There was a problem to connect to the server</Message.Header>
      Please reload the page to reconnect. If the problem persists, contact the webmaster.
      <br />
      Detail: {detail}
    </Message.Content>
  </Message>
)

export default ErrorMessage
