import React, { useEffect, useState } from 'react'
import {
  MdArchive,
  MdBookmark,
  MdCallMerge,
  MdDelete,
  MdEdit,
  MdFolder,
  MdUnarchive
} from 'react-icons/md'
import { FiAlertCircle } from 'react-icons/fi'
import { Either, either, remoteData } from '@sbizeul/fp-flow'
import {
  Button,
  Dropdown,
  DropdownItemProps,
  Label,
  Modal,
  Popup
} from 'semantic-ui-react'
import classNames from 'classnames'
import * as R from 'ramda'
import * as option from 'fp-ts/lib/Option'

import { Id, ServerFailure } from 'types'

import LinkAction from 'components/LinkAction'
import TextInput from 'components/TextInput'

import {
  renderDotLoader,
  renderInlineLoader,
  renderServerFailure
} from 'helpers/react'

import RemoteQuestionModalContent from './RemoteQuestionModalContent'

import { hasNoAnswer, updatingQuestion, isArchived } from '../../models'
import {
  ArchiveQuestionParam,
  MoveSectionParam,
  Question,
  QuestionState,
  QuestionStateActivity,
  Section,
  UnarchiveQuestionParam,
  UpdatingQuestion,
  MergeQuestionParam,
  QuestionReferencesElements,
  QuestionId,
  RemoteDataQuestionReferencesElements
} from '../../types'

import './styles.css'
import {
  HotTopicIcon,
  PinToHottopic,
  PinToTapFlow,
  TapFlowIcon
} from '../PinQuestion'

const MIN_INPUT_LENGTH = 2

type OpenModalParams = {
  title: string
  text: string
  onClickYesFunction: any
}
type OnClick = (
  event: React.MouseEvent<HTMLDivElement>,
  data: DropdownItemProps
) => unknown

type Props = Readonly<{
  isOpen: boolean
  questions: ReadonlyArray<Question>
  question: Question
  sections: ReadonlyArray<Section>
  isDeleting: Either<ServerFailure, boolean>
  isUpdatingQuestion: Either<ServerFailure, UpdatingQuestion>
  questionReferences: RemoteDataQuestionReferencesElements

  saveQuestion: ({
    question,
    text
  }: {
    question: Question
    text: string
  }) => unknown
  deleteQa: (id: Id) => unknown
  navigateToQuestion: (id: Id) => unknown
  moveSection: (param: MoveSectionParam) => unknown
  archiveQuestion: (param: ArchiveQuestionParam) => unknown
  unarchiveQuestion: (param: UnarchiveQuestionParam) => unknown
  mergeQuestion: (param: MergeQuestionParam) => unknown
  fetchQuestionReferences: (questionId: QuestionId) => unknown
}>

const fromSectionToDropdownOptions: (
  onClick: OnClick
) => (sections: ReadonlyArray<Section>) => DropdownItemProps[] = (onClick) =>
  R.map((section) => ({
    key: section.id,
    text: section.label,
    value: section.id,
    onClick
  }))

const rejectSection: (
  id: Id
) => (sections: ReadonlyArray<Section>) => ReadonlyArray<Section> = (id) => (
  sections
) => R.reject(R.propEq('id', id), sections)

const optionsFromSections: (
  onClick: OnClick
) => (
  question: Question
) => (sections: ReadonlyArray<Section>) => DropdownItemProps[] = (onClick) => (
  question
) =>
  R.pipe(
    rejectSection(question.sectionId),
    fromSectionToDropdownOptions(onClick)
  )

const EditableQuestion: React.FunctionComponent<Props> = ({
  questions,
  question,
  sections,
  isDeleting,
  isUpdatingQuestion,
  isOpen,
  questionReferences,
  saveQuestion,
  deleteQa,
  navigateToQuestion,
  moveSection,
  archiveQuestion,
  unarchiveQuestion,
  mergeQuestion,
  fetchQuestionReferences
}) => {
  const [actionDescriptionText, setActionDescriptionText] = useState('')
  const [editMode, setEditMode] = useState(false)
  const [text, setText] = useState(question.label)
  const [displayTemplateIcon, setDisplayTemplateIcon] = useState(question.template_id ? '☁️' : '')
  const [isMergeDropdownOpen, setIsMergeDropdownOpen] = useState(false)
  const [isMoveDropdownOpen, setIsMoveDropdownOpen] = useState(false)
  const [confirmationModal, updateModal] = useState({
    isOpen: false,
    title: '??? your Q&A',
    text: 'Are you sure ?',
    onClickYesFunction: () => true
  })
  const [hasValidationErrors, setHasValidationErrors] = useState(false)

  const [isModalOpen, setIsModalOpen] = useState(false)

  const textInput = React.createRef<HTMLInputElement>()

  const [isPinDropdownOpen, setIsPinDropdownOpen] = useState(false)
  const [isInTapFlow, setIsInTapFlow] = useState(false)
  const [isInHotTopics, setIsInHotTopics] = useState(question.is_in_hot_topics)

  const toggleEditModeOn = () => setEditMode(true)

  const toggleEditModeOff = () => setEditMode(false)

  const handleTextUpdate = (text: string) => setText(text)

  const closeModal = () => updateModal({ ...confirmationModal, isOpen: false })
  const openConfirmationModal = (modalData: OpenModalParams) => {
    updateModal({
      isOpen: true,
      title: modalData.title,
      text: modalData.text,
      onClickYesFunction: modalData.onClickYesFunction
    })
  }

  const handleEscapeEdition = () => {
    setText(question.label)
    toggleEditModeOff()
  }

  const deleteQnAHandler = () => {
    deleteQa(question.id)
    setIsModalOpen(false)
  }

  const mergeQnAHandler = (
    sourceQuestionId: string,
    targetQuestionId: string
  ) => {
    mergeQuestion({ sourceQuestionId, targetQuestionId })
    closeModal()
  }

  const renderActionDescription = () => {
    return (
      <span className="Question-action_description">
        {actionDescriptionText}
      </span>
    )
  }

  const isEmptyQuestionReferences = (
    questionReferencesRemote: RemoteDataQuestionReferencesElements
  ) =>
    R.pipe(
      remoteData.getOrElse(() => ({
        questionId: question.id,
        questionNames: ['error'],
        advancedSkillNames: ['error']
      })),
      (questionReferences: QuestionReferencesElements) =>
        R.isEmpty(questionReferences.questionNames) &&
        R.isEmpty(questionReferences.advancedSkillNames)
    )(questionReferencesRemote)

  const renderDeleteButton = (isDeleting: boolean) =>
    isDeleting ? (
      renderInlineLoader()
    ) : (
      <>
        <MdDelete
          className="Question-action_button"
          onClick={() => setIsModalOpen(true)}
          onMouseEnter={() => setActionDescriptionText('Delete this Q&A')}
          onMouseLeave={() => setActionDescriptionText('')}
        />
        <Modal
          className="Question-deletion--modal"
          open={isModalOpen}
          closeOnEscape={true}
          closeOnDimmerClick={true}
          onClose={() => setIsModalOpen(false)}
        >
          <Modal.Header className="Question-modal-header">
            Delete your question
          </Modal.Header>
          <Modal.Content>
            {isModalOpen && (
              <RemoteQuestionModalContent
                questionReferences={questionReferences}
                questionId={question.id}
                fetchQuestionReferences={fetchQuestionReferences}
              />
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button
              negative
              content="Cancel"
              onClick={() => setIsModalOpen(false)}
            />
            {isEmptyQuestionReferences(questionReferences) && (
              <Button
                positive
                icon="checkmark"
                labelPosition="right"
                content="Yes"
                onClick={() => {
                  deleteQnAHandler()
                }}
              />
            )}
          </Modal.Actions>
        </Modal>
      </>
    )

  const renderButtonDropdown = (
    actionDescription: string,
    dropdownOptions: DropdownItemProps[],
    opened: boolean
  ) => {
    return (
      <Dropdown
        inline
        open={opened}
        options={dropdownOptions}
        icon={null}
        direction="left"
        closeOnBlur
        scrolling
        closeOnEscape
        onMouseEnter={() => setActionDescriptionText(actionDescription)}
        onMouseLeave={() => setActionDescriptionText('')}
      />
    )
  }

  const renderMergeToButton = () => {
    const onItemClick: OnClick = (_, { value }) =>
      openConfirmationModal({
        title: 'Merge your Q&A',
        text:
          'Are you sure you want to merge your current Q&A? It will archive your current Q&A, and move its training to the targeted Q&A',
        onClickYesFunction: () => mergeQnAHandler(question.id, value as string)
      })

    const dropdownOptionsFromQuestions = questions
      .filter((dropdownQuestion) => dropdownQuestion.id !== question.id)
      .filter((dropdownQuestion) => !isArchived(dropdownQuestion))
      .map((dropdownQuestion) => ({
        key: dropdownQuestion.id,
        text: dropdownQuestion.label,
        value: dropdownQuestion.id,
        onClick: onItemClick
      }))

    const actionDescription = 'Merge this Q&A into'
    return (
      <div>
        <MdCallMerge
          onClick={() => setIsMergeDropdownOpen(!isMergeDropdownOpen)}
          className="Question-action_button"
          onMouseEnter={() => setActionDescriptionText(actionDescription)}
          onMouseLeave={() => setActionDescriptionText('')}
        />
        {isMergeDropdownOpen &&
          renderButtonDropdown(
            actionDescription,
            dropdownOptionsFromQuestions,
            isMergeDropdownOpen
          )}
      </div>
    )
  }

  const assignedSlots = (questions: ReadonlyArray<Question>): number => {
    const slots = questions.filter(
      (question: Question) => question.is_in_hot_topics === true
    )
    return slots.length || 0
  }
  const renderPinQuestion = (question: Question) => {
    const actionDescription = 'Pin this Q&A to'
    const dropdownOptions: DropdownItemProps[] = [
      <PinToTapFlow
        key={`${question.id}-tapflow`}
        question={question}
        toggleParentState={setIsInTapFlow}
        setPinDropdownState={setIsPinDropdownOpen}
        setActionDescriptionText={setActionDescriptionText}
      />,
      <PinToHottopic
        key={`${question.id}-hottopic`}
        question={question}
        toggleParentState={setIsInHotTopics}
        setPinDropdownState={setIsPinDropdownOpen}
        assignedSlots={assignedSlots(questions)}
        setActionDescriptionText={setActionDescriptionText}
      />
    ]

    return (
      <div>
        <MdBookmark
          className="Question-action_button"
          onClick={() => setIsPinDropdownOpen(!isPinDropdownOpen)}
          onMouseEnter={() => setActionDescriptionText(actionDescription)}
          onMouseLeave={() => setActionDescriptionText('')}
        />
        {isPinDropdownOpen &&
          renderButtonDropdown(
            actionDescription,
            dropdownOptions,
            isPinDropdownOpen
          )}
      </div>
    )
  }
  const renderMoveToButton = () => {
    const actionDescription = 'Move this Q&A to section'
    const onItemClick: OnClick = (_, { value }) =>
      moveSection({ question, sectionId: value as string })
    return (
      <div>
        <MdFolder
          className="Question-action_button"
          onClick={() => setIsMoveDropdownOpen(!isMoveDropdownOpen)}
          onMouseEnter={() => setActionDescriptionText(actionDescription)}
          onMouseLeave={() => setActionDescriptionText('')}
        />
        {isMoveDropdownOpen &&
          renderButtonDropdown(
            actionDescription,
            optionsFromSections(onItemClick)(question)(sections),
            isMoveDropdownOpen
          )}
      </div>
    )
  }

  const renderArchiveButton = () => (
    <MdArchive
      className="Question-action_button"
      onClick={() => archiveQuestion(question)}
      onMouseEnter={() => setActionDescriptionText('Archive this Q&A')}
      onMouseLeave={() => setActionDescriptionText('')}
    />
  )

  const renderUnarchiveButton = () => (
    <MdUnarchive
      className="Question-action_button"
      onClick={() => unarchiveQuestion(question)}
    />
  )

  const renderEditButton = () => (
    <MdEdit
      className="Question-action_button"
      data-testid="edit"
      onClick={toggleEditModeOn}
      onMouseEnter={() => setActionDescriptionText('Edit this Q&A')}
      onMouseLeave={() => setActionDescriptionText('')}
    />
  )

  const isQuestionActive = question.state === QuestionState.ACTIVE

  const renderActionButtons = () =>
    isQuestionActive ? (
      <>
        {renderPinQuestion(question)}
        {renderMergeToButton()}
        {renderEditButton()}
        {renderMoveToButton()}
        {renderArchiveButton()}
        {either.fold(
          (error) => renderServerFailure(error),
          renderDeleteButton
        )(isDeleting)}
      </>
    ) : (
      renderUnarchiveButton()
    )

  const isInputValid = (text: string) => text.length >= MIN_INPUT_LENGTH

  const handleTextValidation = () => {
    R.cond([
      [R.equals(question.label), (_) => toggleEditModeOff()],
      [
        isInputValid,
        (text) => {
          saveQuestion({
            question,
            text
          })
          toggleEditModeOff()
        }
      ],
      [
        R.T,
        (_) => {
          setHasValidationErrors(true)
          textInput.current && textInput.current.focus()
        }
      ]
    ])(text.trim())
  }

  const getAlertIconClassnames = (hasValidationErrors: boolean) => ({
    'Question-label-alert-icon--hidden': !hasValidationErrors
  })

  const renderQuestionStateActivity: (
    activityState: QuestionStateActivity
  ) => JSX.Element = R.pipe(
    R.ifElse(
      updatingQuestion.isArchiving,
      R.always('archiving question'),
      R.always('unarchiving question')
    ),
    renderDotLoader
  )

  const renderQuestion = () => (
    <>
      {hasNoAnswer(question) && (
        <Popup
          position="top right"
          className="Question-popup"
          content="It seems there is no answer linked to this question. Create a simple answer or link it to a custom skill"
          trigger={
            <Label
              className="Question-info-label"
              as="a"
              size="small"
              corner="right"
              icon="warning circle"
            />
          }
        />
      )}
      <LinkAction
        className="Question-link"
        to={() => navigateToQuestion(question.id)}
      >
        {isInTapFlow && <TapFlowIcon />}
        {isInHotTopics && <HotTopicIcon />}
        <div data-testid="Question-label" className="Question-label-wrapper">
          {editMode ? (
            <div
              className="Question-label-input-wrapper"
              onClick={(event) => {
                event.stopPropagation()
              }}
            >
              <TextInput
                className="Question-label Question-label--input"
                ref={textInput}
                disabled={!editMode}
                value={text}
                onChange={(event) => handleTextUpdate(event.target.value)}
                onEnterKeyPressed={handleTextValidation}
                onEscapeKeyPressed={handleEscapeEdition}
                onBlur={handleTextValidation}
              />
              <FiAlertCircle
                className={classNames(
                  'Question-label-alert-icon',
                  getAlertIconClassnames(hasValidationErrors)
                )}
              />
            </div>
          ) : (
            <span data-testid="text-read-only" className="Question-label">
              {text} <span title="Question from pre-trained template">{displayTemplateIcon}</span>
            </span>
          )}
        </div>
      </LinkAction>
      <div>{isQuestionActive && renderActionDescription()}</div>
      {isOpen === false && (
        <div className="Question-buttons">{renderActionButtons()}</div>
      )}
      <Modal open={confirmationModal.isOpen} onClose={closeModal}>
        <Modal.Header>{confirmationModal.title}</Modal.Header>
        <Modal.Content>
          <p>{confirmationModal.text}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button negative content="No" onClick={closeModal} />
          <Button
            positive
            icon="checkmark"
            labelPosition="right"
            content="Yes"
            onClick={confirmationModal.onClickYesFunction}
          />
        </Modal.Actions>
      </Modal>
    </>
  )
  useEffect(() => {
    if (hasValidationErrors && isInputValid(text)) {
      setHasValidationErrors(false)
    }
    textInput.current && textInput.current.focus()
  }, [editMode, hasValidationErrors, text, textInput])

  return either.fold(
    renderServerFailure,
    option.fold(
      renderQuestion,
      R.ifElse(
        updatingQuestion.isMatchingQuestion(question),
        renderQuestionStateActivity,
        renderQuestion
      )
    ),
    isUpdatingQuestion
  )
}

export default EditableQuestion
