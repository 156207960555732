import React from 'react'
import type * as Axios from 'axios'
import * as reactRouter from 'react-router-dom'

import * as botTypes from 'modules/bot/types'

import '@tolk-ai/bot-management-react-smart-skills-form/dist/bot-management-react-smart-skills-form.esm.css'

import * as utils from '../../utils'


type Properties = {
  fetchPublishedStatus: () => botTypes.FetchPublishStatus
}


const component = (
  botManagementExpressAxios: Axios.AxiosInstance,
  document: Document,
  authManagementExpressBaseUrl: string,
  cookieDomain: string
): React.FC<Properties> => ({fetchPublishedStatus}: Properties) => {
  React.useEffect(() => {
    return () => {fetchPublishedStatus()}
  }, [fetchPublishedStatus])

  const history = reactRouter.useHistory()

  const SmartSkillForm = utils.smartSkillsFormComponent(botManagementExpressAxios, document, authManagementExpressBaseUrl, cookieDomain, history)

  const botId = localStorage.getItem('botId') || ''

    return (
      <SmartSkillForm botId={botId} />
    )
}

export { component }
export type {Properties}