import React from 'react'
import { FaComments, FaLink, FaTh } from 'react-icons/fa'

import './AnswerType.css'

export default function AnswerType(props) {
  return (
    <div className='AnswerType'>
      <ul>
        <li onClick={props.createSimpleText}>
          <FaComments className='AnswerType-icon' />
          <span>Simple Text</span>
        </li>
        <li onClick={props.createButton}>
          <FaLink className='AnswerType-icon' />
          <span>Text and URL Button</span>
        </li>
        <li onClick={props.createQuickReply}>
          <FaTh className='AnswerType-icon' />
          <span>Quick Replies</span>
        </li>
      </ul>
    </div>
  )
}
