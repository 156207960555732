import * as React from 'react'
import { Menu, Tab } from 'semantic-ui-react'
import { either } from '@sbizeul/fp-flow'

import DismissErrorMessage from 'components/Message/DismissErrorMessage'
import synonym from 'modules/synonym_old'

import { renderNothing } from 'helpers/react'

import { getSynonymCountByLanguage, groupSynonymsByLanguages } from '../../models'
import ByLanguageCounter from '../ByLanguageCounter'
import EntityBreadcrumb from '../EntityBreadcrumb'
import EntitySettings from '../EntitySettings'
import './styles.css'

function Entity(props) {
  const synonymsByLanguages = groupSynonymsByLanguages(props.languages, props.synonyms)
  const synonymsByLanguagesPanes = props.languages.map(tabPaneForLanguage)

  return (
    <div className='Entity'>
      <EntityBreadcrumb entity={props.entity} />
      <div className='Entity-content'>
        <Tab panes={synonymsByLanguagesPanes} className='Entity-content--tab' />
        <EntitySettings className='Entity-content--settings' update={props.update} entity={props.entity} />
      </div>
    </div>
  )

  function tabPaneForLanguage(language) {
    return {
      menuItem: (
        <Menu.Item key={language} className='Entities-tabItem'>
          <ByLanguageCounter language={language} count={getSynonymCountByLanguage(synonymsByLanguages, language)} />
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane>
          {either.fold(renderErrorMessage, renderNothing, props.isDeletingSynonym)}
          <synonym.components.AddSynonym
            language={language}
            addSynonym={props.addSynonym}
            isAdding={props.isSynonymAdding}
          />
          {props.synonyms && (
            <synonym.components.Synonyms
              entityID={props.entity.id}
              synonyms={synonymsByLanguages[language]}
              isDeletingSynonym={props.isDeletingSynonym}
              onDelete={props.onDeleteSynonym}
              updateSynonym={props.updateSynonym}
            />
          )}
        </Tab.Pane>
      )
    }
  }
}

function renderErrorMessage(message) {
  return <DismissErrorMessage header={message} />
}

export default Entity
