import React, { Component } from 'react'
import { Button, Tab } from 'semantic-ui-react'
import { connect } from 'react-redux'
import operations from '../../../redux/psadata/operations'

import { prisePosteSelector, sortiePosteSelector } from '../../../redux/selectors'

import PostPane from './components/PostPane'

class PsaPoste extends Component {
  componentDidMount() {
    const { getPrisePoste, getSortiePoste } = this.props
    getPrisePoste()
    getSortiePoste()
  }

  render() {
    const {
      prisePosteData,
      sortiePosteData,

      isFetchingCreate,
      isFetchingSettle,

      getPrisePoste,
      getSortiePoste
    } = this.props

    const panes = [
      {
        menuItem: 'Prise de poste',
        pane: (
          <Tab.Pane style={{ height: 'calc(100% - 100px)', marginBottom: '0px' }}>
            <PostPane data={prisePosteData} type='defaut_data' isFetching={isFetchingCreate} />
          </Tab.Pane>
        )
      },
      {
        menuItem: 'Sortie de poste',
        pane: (
          <Tab.Pane style={{ height: 'calc(100% - 100px)' }}>
            <PostPane data={sortiePosteData} type='defaut_data' isFetching={isFetchingSettle} />
          </Tab.Pane>
        )
      }
    ]

    return (
      <div style={{ height: '100%' }}>
        <Button
          content='Refresh'
          onClick={() => {
            getPrisePoste()
            getSortiePoste()
          }}
        />
        <br />
        <br />
        <Tab style={{ height: 'calc(100% - 50px)' }} panes={panes} renderActiveOnly={false} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  prisePosteData: prisePosteSelector(state),
  sortiePosteData: sortiePosteSelector(state),
  isFetchingCreate: state.psa.getIn(['prisePoste', 'isFetching']),
  isFetchingSettle: state.psa.getIn(['sortiePoste', 'isFetching'])
})

const ConnectedPsaPoste = connect(mapStateToProps, operations)(PsaPoste)

export default ConnectedPsaPoste
