import React from 'react'

import './FormLabel.css'

export default function FormLabel({ label, required = false }) {
  return (
    <label className='FormLabel-label'>
      <span data-testid='label'>{label}</span>
      {required && (
        <span className='FormLabel-required' data-testid='required'>
          *
        </span>
      )}
    </label>
  )
}
