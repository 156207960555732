import * as moment from 'moment'

import { Id, ServerFailure } from 'types'

import { defaultEndDate, defaultStartDate } from './models'
import {
  CHANGE_DATES,
  CHANGE_NUMBER_CONVERSATIONS_PER_PAGE,
  CHANGE_SEARCH_VALUE, ChangeDates, ChangeNumberConversationsPerPage, ChangeSearchValue, Conversation,
  DOWNLOAD_CSV_FAILURE,
  DOWNLOAD_CSV_REQUEST,
  DOWNLOAD_CSV_SUCCESS, DownloadCSVFailure, DownloadCSVRequest, DownloadCSVSuccess,
  FETCH_CONVERSATIONS_FAILURE,
  FETCH_CONVERSATIONS_REQUEST,
  FETCH_CONVERSATIONS_SUCCESS, FetchConversationsFailure, FetchConversationsRequest, FetchConversationsSuccess,
  FIRST_PAGE, FirstPage,
  NEXT_PAGE, NextPage,
  PREVIOUS_PAGE, PreviousPage, Search,
  SEARCH, Select,
  SELECT
} from './types'

export const fetchConversationsRequest = (): FetchConversationsRequest => ({
  type: FETCH_CONVERSATIONS_REQUEST
})

export const fetchConversationsFailure = (error: ServerFailure): FetchConversationsFailure => ({
  type: FETCH_CONVERSATIONS_FAILURE,
  payload: error
})

export const fetchConversationsSuccess = (payload: ReadonlyArray<Conversation>): FetchConversationsSuccess => ({
  type: FETCH_CONVERSATIONS_SUCCESS,
  payload
})

export const select = (selectedID: Id): Select => ({
  type: SELECT,
  payload: selectedID
})

export const changeDates = ({ startDate, endDate }: { startDate: moment.Moment, endDate: moment.Moment }): ChangeDates => ({
  type: CHANGE_DATES,
  payload: {
    startDate: defaultStartDate(startDate),
    endDate: defaultEndDate(endDate)
  }
})

export const nextPage = (): NextPage => ({
  type: NEXT_PAGE
})

export const previousPage = (): PreviousPage => ({
  type: PREVIOUS_PAGE
})

export const firstPage = (): FirstPage => ({
  type: FIRST_PAGE
})

export const search = (): Search => ({
  type: SEARCH
})

export const changeSearchValue = (text: string): ChangeSearchValue => ({
  type: CHANGE_SEARCH_VALUE,
  payload: text
})

export const changeNumberConversationsPerPage = (limit: number): ChangeNumberConversationsPerPage => ({
  type: CHANGE_NUMBER_CONVERSATIONS_PER_PAGE,
  payload: limit
})

export const downloadCSVRequest = (): DownloadCSVRequest => ({
  type: DOWNLOAD_CSV_REQUEST
})

export const downloadCSVFailure = (error: ServerFailure): DownloadCSVFailure => ({
  type: DOWNLOAD_CSV_FAILURE,
  payload: error
})

export const downloadCSVSuccess = (): DownloadCSVSuccess => ({
  type: DOWNLOAD_CSV_SUCCESS
})

export {
  CHANGE_DATES,
  CHANGE_NUMBER_CONVERSATIONS_PER_PAGE,
  CHANGE_SEARCH_VALUE,
  DOWNLOAD_CSV_FAILURE,
  DOWNLOAD_CSV_REQUEST,
  DOWNLOAD_CSV_SUCCESS,
  FETCH_CONVERSATIONS_FAILURE,
  FETCH_CONVERSATIONS_REQUEST,
  FETCH_CONVERSATIONS_SUCCESS,
  FIRST_PAGE,
  NEXT_PAGE,
  PREVIOUS_PAGE,
  SEARCH,
  SELECT
}
