import { call, put, takeLatest, select } from 'redux-saga/effects'

import Api from 'services/tolkApiLegacy'

import * as logActions from './actions'
import * as logSelectors from './selectors'

export function* fetchLogs() {
  try {
    const page = yield select(logSelectors.getPage)
    const { data } = yield call(Api.get, `/admin/logs?page=${page}`)
    yield put(logActions.fetchSuccess(data))
  } catch (error) {
    yield put(logActions.fetchFailure(error))
  }
}

export function* root() {
  yield takeLatest(logActions.FETCH_NEXT_REQUEST, fetchLogs)
  yield takeLatest(logActions.FETCH_PREV_REQUEST, fetchLogs)
}
