import styled from 'styled-components'

const ToggleSwitchStyled = styled.div`
  position: relative;
  padding: ${({ theme }) => theme.padding.toggleSwitch}px;
  width: ${({ theme }) => theme.width.toggleSwitch}px;
  height: ${({ theme }) => theme.height.toggleSwitch}px;
  margin-left: ${({ theme }) => theme.margin.left.toggleSwitch}px;
  margin-right: ${({ theme }) => theme.margin.right.toggleSwitch}px;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  border-radius: ${({ theme }) => theme.height.toggleSwitch}px;
  border: ${({ theme }) => theme.padding.toggleSwitch}px solid ${({ theme }) => theme.color.defaultColor};
  cursor: pointer;
  ${props =>
    props.disabled &&
    `
    pointer-events: none;
    opacity: 0.3;
  `}
`
const ToggleValue = styled.span`
  ${props =>
    props.disabled &&
    `
    pointer-events: none;
    opacity: 0.3;
  `}
  cursor: pointer;
`

const SwitchStyled = styled.div`
  height: ${({ theme }) => theme.height.switch}px;
  width: ${({ theme }) => theme.width.switch}px;
  border-radius: 100%;
  background: ${({ theme }) => theme.color.defaultColor};
  position: absolute;
  margin-top: auto;
  margin-bottom: auto;
  ${({ theme, selected }) => {
    const { width, padding } = theme
    const distance = width.toggleSwitch - 4 * padding.toggleSwitch - width.switch
    return !selected && `transform: translateX(${distance}px)`
  }};
  transition: all 250ms ease-in-out;
`

export { ToggleSwitchStyled, ToggleValue, SwitchStyled }
