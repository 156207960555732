import * as actions from './actions'
import * as models from './models'
import * as entity from './reducer.entity'

import * as ui from './reducer.ui'
import * as saga from './saga'
import * as selectors from './selectors'
import * as selectorsTyped from './selectors.typed'
import KeywordIndex from './containers/KeywordIndex'

export default {
  actions,
  models,
  reducers: {
    entity,
    ui
  },
  saga,
  selectors,
  selectorsTyped,
  components: {
    KeywordIndex
  }
}
