import React from 'react'
import ReactHighcharts from 'react-highcharts'
import moment from 'moment'
import isEmpty from 'lodash/isEmpty'
import NoDataSegment from '../NoDataSegment'
import defaultStyle from '../chartStyle'

const config = {
  ...defaultStyle,
  chart: {
    type: 'areaspline'
  },
  xAxis: {
    type: 'datetime'
  },
  yAxis: {
    title: null,
    max: 100,
    labels: {
      format: '{value}%'
    }
  },
  tooltip: {
    valueDecimals: 2,
    valueSuffix: '%'
  }
}

export default function IntentsPayloadsAndMessages({ misunderstood }) {
  if (isEmpty(misunderstood)) {
    return <NoDataSegment />
  }
  const series = []

  series.push({
    stacking: 'normal',
    animation: false,
    name: 'Answer Rate',
    data: misunderstood.map(elem => [moment.utc(elem.date).valueOf(), elem.average])
  })
  return <ReactHighcharts config={{ ...config, series }} domProps={{ id: 'IntentsPayloadsAndMessagesHighcharts' }} />
}
