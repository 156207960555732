import { connect } from 'react-redux'

import auth from 'modules/auth'

import * as selectors from '../selectors'
import Webchat from '../components/Webchat'

const mapStateToProps = state => ({
  webchatUrl: selectors.getWebchatUrl(state),
  isLoggedIn: auth.selectors.getIsAuthenticated(state)
})

export default connect(mapStateToProps, null)(Webchat)
