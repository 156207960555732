import React from 'react'
import { FaSearch } from 'react-icons/fa'

import './SearchBar.css'
import TextInput from '../TextInput/TextInput'

export default function SearchBar({ value, onChangeText, onEnterKeyPressed }) {
  return (
    <div className='SearchBar' onKeyDown={e => (e.key === 'Enter' ? onEnterKeyPressed() : null)}>
      <TextInput
        type='rounded'
        value={value}
        onChangeText={onChangeText}
        placeholder='Search a sentence ...'
        decoratorChild={<FaSearch />}
      />
    </div>
  )
}
