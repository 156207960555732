import React from 'react'
import { ElementSelectorStyled, OutlinedUIStyled } from './styles'
import {
  CarouselIcon,
  ImageIcon,
  LinkIcon,
  // RSSOutlinedIcon,
  TextIcon,
  VideoIcon
} from '../../icons'

import { ElementTypes } from '../../types'

const ElementSelector = ({ addElement }) => (
  <ElementSelectorStyled grouped>
    <OutlinedUIStyled direction='column' grouped onClick={() => addElement(ElementTypes.text)}>
      <TextIcon />
      <span>Text</span>
    </OutlinedUIStyled>
    <OutlinedUIStyled direction='column' grouped onClick={() => addElement(ElementTypes.image)}>
      <ImageIcon />
      <span>Image</span>
    </OutlinedUIStyled>
    <OutlinedUIStyled direction='column' grouped onClick={() => addElement(ElementTypes.video)}>
      <VideoIcon />
      <span>Video</span>
    </OutlinedUIStyled>
    <OutlinedUIStyled direction='column' grouped onClick={() => addElement(ElementTypes.carousel)}>
      <CarouselIcon />
      <span>Carousel</span>
    </OutlinedUIStyled>
    <OutlinedUIStyled direction='column' grouped onClick={() => addElement(ElementTypes.url)}>
      <LinkIcon />
      <span>Link</span>
    </OutlinedUIStyled>
    {/* <OutlinedUIStyled direction='column' grouped disabled>
      <RSSOutlinedIcon />
      <span>RSS</span>
    </OutlinedUIStyled> */}
  </ElementSelectorStyled>
)

export default ElementSelector
