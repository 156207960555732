import { remoteData } from '@sbizeul/fp-flow'
import * as R from 'ramda'

import * as channelActions from './actions'
import { toMapById } from './models'

export const initialState = {
  all: remoteData.notAsked(),
  byBotId: {}
}

const all = R.lensProp('all')
const byBotId = R.lensPath(['byBotId'])

const allRequest = R.over(all, remoteData.loading)

const allSuccess = (state, action) => {
  const { payload: channels } = action
  return R.set(all, remoteData.success(toMapById(channels)), state)
}

const allFailure = (state, action) => {
  return R.set(all, remoteData.failure(action.payload), state)
}

const byBotRequest = (state, action) => {
  const lensPath = R.compose(byBotId, R.lensPath([action.payload]))
  return R.over(lensPath, remoteData.loading, state)
}

const byBotSuccess = (state, action) => {
  const { botId, channels } = action.payload
  const lensPath = R.compose(byBotId, R.lensPath([botId]))
  return R.set(lensPath, remoteData.success(channels), state)
}

const byBotFailure = (state, action) => {
  const { botId, error } = action.payload
  const lensPath = R.compose(byBotId, R.lensPath([botId]))
  return R.set(lensPath, remoteData.failure(error), state)
}

export default function channel(state = initialState, action) {
  switch (action.type) {
  case channelActions.FETCH_ALL_REQUEST:
    return allRequest(state)
  case channelActions.FETCH_ALL_SUCCESS:
    return allSuccess(state, action)
  case channelActions.FETCH_ALL_FAILURE:
    return allFailure(state, action)
  case channelActions.FETCH_BY_BOT_REQUEST:
    return byBotRequest(state, action)
  case channelActions.FETCH_BY_BOT_SUCCESS:
    return byBotSuccess(state, action)
  case channelActions.FETCH_BY_BOT_FAILURE:
    return byBotFailure(state, action)
  default:
    return state
  }
}
