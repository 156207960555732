import { maybe } from '@sbizeul/fp-flow'

import isResValid from '../../_shared/isResValid'
import formatFilters from '../../_shared/formatFilters'
import actions from './actions'
import bot from '../../../modules/bot'
import api from 'services/tolkApiLegacy'

const fetchEscaladesEnd = rawFilters => (dispatch, getState) => {
  const { channelId, start, end, period } = formatFilters(rawFilters)
  const botId = bot.selectors.getSelectedId(getState())

  dispatch(actions.fetchEscaladesEndRequest())
  return api.get(
    `/analytics/${maybe.get(botId)}/${channelId}/events/escalade_end`,
    {
      params: {
        start,
        end,
        period
      }
    }
  ).then(response => {
      if (isResValid(response)) {
        const formatedResponse = {
          data: response.data.result.data
        }
        dispatch(actions.fetchEscaladesEndSuccess(formatedResponse))
      } else {
        dispatch(actions.fetchEscaladesEndFailure())
      }
    })
    .catch(() => {
      dispatch(actions.fetchEscaladesEndFailure())
    })
}

export default { fetchEscaladesEnd }
