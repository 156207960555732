import React, { Component } from 'react'
import { connect } from 'react-redux'
import { maybe } from '@sbizeul/fp-flow'

import operations from '../../../../../redux/analytics/operations'
import getSafe from '../../../../utils/getSafe'
import Trends from './Trends'
import NewFilters from '../../Filters/NewFilters'
import bot from '../../../../../modules/bot'

class TrendsContainer extends Component {
  componentDidMount() {
    const { filters } = this.props
    this.props.fetchTrends(filters)
  }

  componentDidUpdate(prevProps) {
    const { filters } = this.props
    if (!maybe.equals(prevProps.botId, this.props.botId) || prevProps.filters.channel.id !== filters.channel.id) {
      this.props.fetchTrends(filters)
    }
  }

  render() {
    return (
      <div>
        <NewFilters fetch={this.props.fetchTrends} />
        <Trends {...this.props} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  filters: state.main.filters,
  wordsCount: getSafe(() => state.analytics.words.moy_words),
  charCount: getSafe(() => state.analytics.words.moy_car),
  noWordsCount: false,
  entitiesData: getSafe(() => state.analytics.entities.data),
  intentsData: getSafe(() => state.analytics.intents.data),
  answersData: getSafe(() => state.analytics.answers.data),
  payloadsData: getSafe(() => state.analytics.payloads.data),
  wordsusersData: getSafe(() => state.analytics.wordsusers.data),
  botId: bot.selectors.getSelectedId(state)
})

export default connect(mapStateToProps, operations)(TrendsContainer)
