import { Predicate } from 'fp-ts/lib/function'
import * as array from 'fp-ts/lib/Array'
import * as option from 'fp-ts/lib/Option'
import { maybe, Maybe } from '@sbizeul/fp-flow'
import * as R from 'ramda'
import { v4 as uuid } from 'uuid'

import { IsoLanguage } from 'helpers/language'
import { Id } from 'types'

import {
  Answer,
  AnswerContent,
  ButtonAttachmentContent,
  ButtonAttachmentQnAContent,
  ButtonAttachmentUrlContent,
  ButtonContent,
  ButtonWithAnswer,
  FormContent,
  GotoSkillContent,
  GotoSmartSkillContent,
  ImageContent,
  QRAnswer,
  SimpleAnswer,
  TagAgentContent,
  TextContent,
  UpdateContentParam,
  CarouselContent,
  ButtonsAttachmentContent,
  BaseSkillContent} from './types'
import { emptyButtonAnswer, isSimpleAnswer, contentLens } from './models'

const botId = R.lensProp('bot_id')
const botIdMissingMessage = R.lensPath(['missing_message', 'bot_id'])
const id = R.lensProp('id')

const emptyQRAnswer = (language: IsoLanguage) => ({
  type: 'QR',
  language,
  missing_message: emptySimpleAnswer(language),
  buttons: [emptyButtonAnswer()]
}) as QRAnswer

const emptySimpleAnswer = (language: IsoLanguage, botId = '') =>
  R.set(R.lensProp('content'), [emptyTextContent(language)], newSimpleAnswer(botId, uuid()))

const emptyGotoSkillContent: () => GotoSkillContent = () => ({
  id: uuid(),
  type: 'skill',
  objectAttachment: {
    skill_id: ''
  }
})

const emptyTagAgentContent: () => TagAgentContent = () => ({
  id: uuid(),
  type: 'tagagent',
  objectAttachment: {
    tag_id: '0',
    action: 'set'
  }
})

const isNotEmpty: Predicate<string> = str => str.length > 0

const isValidTextContent: Predicate<TextContent> = answerContent => {
  return answerContent.objectAttachment.text.every(isNotEmpty)
}

const isValidImageContent: Predicate<ImageContent> = answerContent => {
  return isNotEmpty(answerContent.objectAttachment.imageUrl)
}

const isValidTagAgentContent: Predicate<TagAgentContent> = answerContent => {
  return isNotEmpty(answerContent.objectAttachment.tag_id)
}

const isButtonAttachmentContentValid = (button: ButtonAttachmentQnAContent | ButtonAttachmentUrlContent) => {
  switch (button.type) {
    case 'question_link':
      return isNotEmpty(R.prop('external_question_id')(button))
    case 'web_url':
      return isNotEmpty(R.prop('url')(button))
  }
}

const isValidButton = (button: ButtonAttachmentContent) =>
  button.title.every(isNotEmpty) && isButtonAttachmentContentValid(button)

export const isValidButtonContent: Predicate<ButtonContent> = answerContent => {
  return (
    answerContent.objectAttachment.text.every(isNotEmpty) &&
    isValidButtonsAttachment(answerContent.objectAttachment.buttons)
  )
}

const isValidButtonsAttachment: Predicate<ButtonsAttachmentContent> = R.all(isValidButton)

const isValidCarouselContent: Predicate<CarouselContent> = carouselContent =>
  carouselContent.objectAttachment.length >= 1 && R.all(R.compose(R.not, R.isEmpty, R.prop('title')), carouselContent.objectAttachment)

const isValidFormContent: Predicate<FormContent> = R.pipe(
  R.path(['objectAttachment', 'form_id']),
  R.complement(R.isEmpty)
)

const isValidSkillContent: Predicate<GotoSkillContent> = R.pipe(
  R.path(['objectAttachment', 'skill_id']),
  R.complement(R.isEmpty)
)

const isValidSmartSkillContent: Predicate<GotoSmartSkillContent> = R.pipe(
  R.path(['objectAttachment', 'smartskill_id']),
  R.complement(R.isEmpty)
)


const isValidBaseSkillAnswerContent: Predicate<BaseSkillContent> = R.pipe(
  R.path(['objectAttachment', 'answer_id']),
  R.complement(R.isEmpty)
)

const newSimpleAnswer: (botId: Id, id: Id) => SimpleAnswer = (botId, id) => ({
  id,
  type: 'Simple',
  bot_id: botId,
  content: []
})
const isValidAnswerContents = (answerContent: ReadonlyArray<AnswerContent>) =>
  answerContent.length > 0 && answerContent.every(isValidAnswerContent)

const isValidAnswerContent: Predicate<AnswerContent> = (answerContent: AnswerContent) => {
  switch (answerContent.type) {
    case 'text':
      return isValidTextContent(answerContent)
    case 'button':
      return isValidButtonContent(answerContent)
    case 'carousel':
      return isValidCarouselContent(answerContent)
    case 'image':
      return isValidImageContent(answerContent)
    case 'form':
      return isValidFormContent(answerContent)
    case 'skill':
      return isValidSkillContent(answerContent)
    case 'smartskill':
      return isValidSmartSkillContent(answerContent)
    case 'answer':
      return isValidBaseSkillAnswerContent(answerContent)
    case 'tagagent':
      return isValidTagAgentContent(answerContent)
    case 'rgpd':
      return true
    case 'app':
      return true
  }
}

const isSimpleAnswerMaybe: Predicate<Maybe<Answer>> = optionalAnswer => maybe.fold(
  () => true,
  isSimpleAnswer,
  optionalAnswer)

const isValidButtonTitle: (button: ButtonWithAnswer) => boolean = R.pipe(
  R.prop('title'),
  array.head,
  option.fold(R.T, R.allPass([R.isNil, R.isEmpty])),
  R.not
)
const missingMessage = R.lensPath(['missing_message'])
const titleLens = R.pipe(
  R.lensPath(['content', 0, 'objectAttachment', 'text', 0]),
  missingMessage
) as R.Lens

const isValidQRAnswer: Predicate<QRAnswer> = answer =>
  R.view(titleLens, answer) !== '' &&
  !R.isEmpty(answer.buttons) &&
  R.all(
    button =>
      R.defaultTo(
        false,
        isValidButtonTitle(button) &&
          button.answer &&
          isValidAnswerContents(button.answer.content)
      ),
    answer.buttons
  )

const isValidAnswer: Predicate<Answer> = answer =>
  isSimpleAnswerMaybe(maybe.of(answer))
    ? isValidAnswerContents(R.view(contentLens, answer))
    : isValidQRAnswer(answer as QRAnswer)

const isEmptyButtonAttachement = (buttonAttachement: ButtonAttachmentContent) =>
  buttonAttachement.title[0].length < 1
const hasThreeButton = (button: ButtonContent) => hasTreeButtonAttachment(button.objectAttachment.buttons)
const hasTreeButtonAttachment = (buttonsAttachement: ButtonsAttachmentContent) => buttonsAttachement.length === 3
const hasTenButtonAttachment = (buttonsAttachement: ButtonsAttachmentContent) => buttonsAttachement.length === 10

const completeButton = (button: ButtonContent) =>
  R.over(
    R.lensPath(['objectAttachment', 'buttons']),
    completeButtonsAttachment,
    button
  )

const completeButtonsAttachment = (buttonsAttachment: ButtonsAttachmentContent) => R.append(emptyButtonAttachment, buttonsAttachment)

const hasOneButton = (content: UpdateContentParam) =>
  R.pipe(
    R.view(R.lensPath(['content', 'objectAttachment', 'buttons'])),
    (buttons: ButtonAttachmentContent[]) => R.length(buttons),
    R.equals(1)
  )(content)

const filterEmptyButton = (content: UpdateContentParam) =>
  hasOneButton(content)
    ? content
    : R.over(
        R.lensPath(['content', 'objectAttachment', 'buttons']),
        R.reject(isEmptyButtonAttachement)
      )(content)

const addContent = (payload: AnswerContent) => (answerState: Maybe<Answer>) =>
    maybe.map((ans: Answer) =>
      R.set(contentLens, R.concat(R.view(contentLens, ans), [payload]), ans)
    )(answerState)

const createQRAnswer: (
  qrAnswerModel: QRAnswer
) => (state: Maybe<Answer>) => Maybe<Answer> = (qrAnswerModel) => (
  state
) =>
    maybe.map((ans) =>
      R.pipe(
        R.set(id, ans.id),
        R.set(botIdMissingMessage, ans.bot_id),
        R.set(botId, ans.bot_id)
      )(qrAnswerModel)
    )(state)

const removeContent: (id: Id) => (answer: Maybe<Answer>) => Maybe<Answer> = id => answer =>
  isSimpleAnswerMaybe(answer)
    ? maybe.map(
        R.over(
          contentLens,
          R.filter((content: AnswerContent) => id !== content.id)
        )
      )(answer)
    : maybe.map((ans) => newSimpleAnswer(ans.bot_id, ans.id))(answer)

const updateContent: (answerContent: AnswerContent) => (state: Maybe<Answer>) => Maybe<Answer> = answerContent => state =>
    maybe.map((ans: Answer) =>
      R.over(
        contentLens,
        R.reduce(
          (acc: AnswerContent[], el: AnswerContent) => [
            ...acc,
            el.id === answerContent.id ? answerContent : el
          ],
          []
        )
      )(ans)
    )(state)

const emptyButtonAttachment: ButtonAttachmentContent = {
  type: 'web_url',
  url: '',
  title: [''],
  target: 'blank'
}

const emptyButtonContent: (language: IsoLanguage) => ButtonContent = language => ({
  id: uuid(),
  type: 'button',
  delay: 1000,
  language,
  objectAttachment: {
    text: [''],
    buttons: [emptyButtonAttachment]
  }
})

const emptyTextContent: (language: IsoLanguage) => TextContent = language => ({
  id: uuid(),
  type: 'text',
  delay: 1000,
  language,
  objectAttachment: {
    text: ['']
  }
})

const emptyImageContent: (language: IsoLanguage) => ImageContent = language => ({
  id: uuid(),
  type: 'image',
  delay: 1000,
  language,
  objectAttachment: {
    imageUrl: ''
  }
})

const emptyFormContent: () => FormContent = () => ({
  id: uuid(),
  type: 'form',
  objectAttachment: {
    form_id: ''
  }
})

export {
  addContent,
  createQRAnswer,
  emptyButtonContent,
  completeButtonsAttachment,
  emptyFormContent,
  emptyImageContent,
  emptyTagAgentContent,
  emptyTextContent,
  emptyGotoSkillContent,
  emptyQRAnswer,
  emptySimpleAnswer,
  filterEmptyButton,
  hasOneButton,
  hasThreeButton,
  hasTenButtonAttachment,
  hasTreeButtonAttachment,
  isEmptyButtonAttachement,
  isValidAnswer,
  isValidButton,
  isValidButtonsAttachment,
  newSimpleAnswer,
  removeContent,
  updateContent,
  completeButton
}
