import React from 'react'
import axios from 'axios'

import SmartSkillsForm from './containers/SmartSkillsFormsContainer'
import * as smartSkills from './components/list'

import '@tolk-ai/bot-management-react-smart-skills/dist/bot-management-react-smart-skills.esm.css'

import {
  botManagementBaseUrl,
  authManagementBaseUrl,
  cookieDomain
} from 'helpers/effects'

const botManagementExpressAxios = axios.create({
  baseURL: botManagementBaseUrl
})

const smartSkillsComponent = (): React.FC => smartSkills.component(botManagementExpressAxios, document, authManagementBaseUrl, cookieDomain)
const SmartSkills = smartSkillsComponent() 

export { SmartSkills, SmartSkillsForm }