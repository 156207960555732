import * as R from 'ramda'
import { v4 as uuid } from 'uuid'
import moment from 'moment-timezone'
import * as actionTypes from './actions'
import { CampaignStatus, ElementTypes, ButtonTypes } from './types'
import { mockCampaignsFetch } from './mocks'
import { updateCarousel, updateButton } from './helpers'
import { getCarouselCard, getCarouselLength } from './selectors'
import { allCohorts } from './reference'

/* INIT STATES */
const initTextElement = (id = uuid()) => ({
  id,
  type: ElementTypes.text,
  content: ''
})

const initUrlElement = (id = uuid()) => ({
  id,
  type: ElementTypes.url,
  content: ''
})

const initImageElement = (id = uuid()) => ({
  id,
  type: ElementTypes.image,
  content: {
    url: '',
    temporaryUrl: ''
  }
})

const initVideoElement = (id = uuid()) => ({
  id,
  type: ElementTypes.video,
  content: {
    url: '',
    temporaryUrl: '',
    size: 0
  }
})

const initButton = (id = uuid()) => ({
  id,
  type: ButtonTypes.url,
  title: '',
  value: ''
})

const initCarouselAttachment = (id = uuid()) => ({
  id,
  title: '',
  text: '',
  image: {
    url: '',
    temporaryUrl: ''
  },
  buttons: [initButton()]
})

const initCarouselElement = (id = uuid()) => ({
  id,
  type: ElementTypes.carousel,
  content: {
    attachments: [initCarouselAttachment()]
  }
})

export const initCampaign = (id, date, timeZone, channel = '') => ({
  id,
  updated: false,
  title: 'Campaign name',
  elements: [],
  cohorts: [],
  date,
  timeZone,
  sendNow: true,
  kpis: {
    sent: 0,
    read: 0,
    targeted: 0
  },
  status: CampaignStatus.draft, // draft - scheduled - sent - to-review
  channel,
  sendingPreview: false,
  recurrence: 'day',
  recurring: false
})

export const initialState = {
  campaigns: [],
  currentId: '',
  channel: '',
  cohorts: [],
  previewUsers: [],
  currentAnalytics: []
}

/* ELEMENT TRANSFORMERS */

const editImageElementTemporaryUrl = (element, { payload: { temporaryUrl } }) => ({
  ...element,
  content: {
    ...element.content,
    temporaryUrl
  }
})

const editImageElementUrl = (element, { payload: { url } }) => ({
  ...element,
  content: {
    ...element.content,
    temporaryUrl: '',
    url
  }
})

const editTextElement = (element, { payload: { text } }) => ({
  ...element,
  content: text
})

const editVideoElementTemporaryUrl = (element, { payload: { temporaryUrl } }) => ({
  ...element,
  content: {
    ...element.content,
    temporaryUrl
  }
})

const editVideoElementUrl = (element, { payload: { url, size } }) => ({
  ...element,
  content: {
    ...element.content,
    temporaryUrl: '',
    url,
    size
  }
})

const editCarouselElementAddCard = carousel =>
  updateCarousel(carousel, getCarouselLength(carousel), initCarouselAttachment())

const editCarouselElementDeleteCard = (carousel, { payload: { cardIndex } }) => ({
  ...carousel,
  content: {
    ...carousel.content,
    attachments: carousel.content.attachments.filter((_, index) => index !== cardIndex)
  }
})

const editCarouselElementReorderCards = (carousel, { payload: { oldIndex, newIndex } }) => {
  const card = getCarouselCard(carousel, oldIndex)
  const {
    content: { attachments }
  } = editCarouselElementDeleteCard(carousel, { payload: { cardIndex: oldIndex } })
  return {
    ...carousel,
    content: {
      ...carousel.content,
      attachments: [...attachments.slice(0, newIndex), card, ...attachments.slice(newIndex)]
    }
  }
}

const editCarouselElementImageTemporaryUrl = (carousel, { payload: { cardIndex, temporaryUrl } }) => {
  const oldCard = getCarouselCard(carousel, cardIndex)
  const newCard = {
    ...oldCard,
    image: {
      ...oldCard.image,
      temporaryUrl
    }
  }
  return updateCarousel(carousel, cardIndex, newCard)
}

const editCarouselElementImageUrl = (carousel, { payload: { cardIndex, url } }) => {
  const oldCard = getCarouselCard(carousel, cardIndex)
  const newCard = {
    ...oldCard,
    image: {
      ...oldCard.image,
      temporaryUrl: '',
      url
    }
  }
  return updateCarousel(carousel, cardIndex, newCard)
}

const editCarouselElementTitle = (carousel, { payload: { cardIndex, title } }) => {
  const oldCard = getCarouselCard(carousel, cardIndex)
  const newCard = {
    ...oldCard,
    title
  }
  return updateCarousel(carousel, cardIndex, newCard)
}

const editCarouselElementDescription = (carousel, { payload: { cardIndex, description } }) => {
  const oldCard = getCarouselCard(carousel, cardIndex)
  const newCard = {
    ...oldCard,
    text: description
  }
  return updateCarousel(carousel, cardIndex, newCard)
}

const editCarouselElementAddButton = (carousel, { payload: { cardIndex } }) => {
  const oldCard = getCarouselCard(carousel, cardIndex)
  const newCard = {
    ...oldCard,
    buttons: [...oldCard.buttons, initButton()]
  }
  return updateCarousel(carousel, cardIndex, newCard)
}

const editCarouselElementDeleteButton = (carousel, { payload: { cardIndex, buttonIndex } }) => {
  const oldCard = getCarouselCard(carousel, cardIndex)
  const newCard = {
    ...oldCard,
    buttons: oldCard.buttons.filter((_, index) => index !== buttonIndex)
  }
  return updateCarousel(carousel, cardIndex, newCard)
}

const editCarouselElementButtonTitle = (carousel, { payload: { cardIndex, buttonIndex, title } }) => {
  const oldCard = getCarouselCard(carousel, cardIndex)
  const oldCardButtons = oldCard.buttons
  const oldButton = oldCardButtons[buttonIndex]
  const newButton = {
    ...oldButton,
    title
  }
  const newCard = {
    ...oldCard,
    buttons: updateButton(oldCardButtons, buttonIndex, newButton)
  }
  return updateCarousel(carousel, cardIndex, newCard)
}

const editCarouselElementButtonValue = (carousel, { payload: { cardIndex, buttonIndex, value } }) => {
  const oldCard = getCarouselCard(carousel, cardIndex)
  const oldCardButtons = oldCard.buttons
  const oldButton = oldCardButtons[buttonIndex]
  const newButton = {
    ...oldButton,
    value
  }
  const newCard = {
    ...oldCard,
    buttons: updateButton(oldCardButtons, buttonIndex, newButton)
  }
  return updateCarousel(carousel, cardIndex, newCard)
}

/* CAMPAIGN TRANSFORMERS */

const updateCampaignTitle = campaign => action => ({ ...campaign, title: action.payload })
const updateCampaignSendNow = campaign => action => ({ ...campaign, sendNow: Boolean(action.payload) })
const updateCampaignDate = campaign => action => ({
  ...campaign,
  date: action.payload,
  timeZone: moment(action.payload).tz()
})
const scheduleCampaign = campaign => action => ({ ...campaign, status: CampaignStatus.scheduled, updated: false })
const sendCampaign = () => action => ({
  ...action.payload,
  status: CampaignStatus.sent //TODO: remove when errors are handle
})

const previewSent = campaign => () => ({
  ...campaign,
  sendingPreview: false
})

const sendPreview = campaign => () => ({
  ...campaign,
  sendingPreview: true
})

const selectCohortReducer = campaign => ({ payload: { cohort, cohorts } }) => {
  const cohortNames = cohorts.map(({ name }) => name)
  if (cohort === allCohorts) {
    const allCohortsSelected = campaign.cohorts.length === cohortNames.length
    return {
      ...campaign,
      cohorts: allCohortsSelected ? [] : cohortNames
    }
  }

  return {
    ...campaign,
    cohorts: campaign.cohorts.includes(cohort)
      ? campaign.cohorts.filter(campaignCohort => campaignCohort !== cohort)
      : [...campaign.cohorts, cohort]
  }
}

const selectRecurringReducer = campaign => ({ payload }) => ({
  ...campaign,
  recurring: payload
})

const selectRecurrenceReducer = campaign => ({ payload }) => ({
  ...campaign,
  recurrence: payload
})

// Analytics Transformer
const updateAnalyticsReducer = (state, { payload }) => ({
  ...state,
  currentAnalytics: payload
})

// Updated: false means that store state is now up to date with the persisted
// one (used to disable/unable save button)
const saveCampaign = campaign => () => ({ ...campaign, updated: false })

const addElementToCampaign = campaign => action => {
  const addElement = element => ({
    ...campaign,
    elements: [...campaign.elements, element]
  })
  switch (action.payload) {
  case ElementTypes.text: {
    return addElement(initTextElement())
  }
  case ElementTypes.url: {
    return addElement(initUrlElement())
  }
  case ElementTypes.image: {
    return addElement(initImageElement())
  }
  case ElementTypes.video: {
    return addElement(initVideoElement())
  }
  case ElementTypes.carousel: {
    return addElement(initCarouselElement())
  }
  default:
    console.error(`no element of type ${action.payload} exists`)
    return campaign
  }
}

const deleteElementFromCampaign = campaign => action => ({
  ...campaign,
  elements: campaign.elements.filter((_, index) => index !== action.payload)
})

const reorderElementsFromCampaign = campaign => ({ payload: { oldIndex, newIndex } }) => {
  const { elements } = campaign
  const element = elements[oldIndex]
  const reorderedElements = elements.filter((_, index) => index !== oldIndex)
  reorderedElements.splice(newIndex, 0, element)

  return {
    ...campaign,
    elements: reorderedElements
  }
}

/* REDUCERS */

const selectChannelReducer = (state, action) => ({
  ...state,
  channel: action.payload
})

const selectPreviewUsersReducer = (state, action) => ({
  ...state,
  previewUsers: action.payload
})

const createNewCampaignReducer = (state, { payload: { id, date, timeZone, channel } }) => {
  const campaign = initCampaign(id, date, timeZone, channel)
  return {
    ...state,
    campaigns: [...state.campaigns, campaign],
    currentId: campaign.id
  }
}

const addCampaignReducer = (state, { payload }) => {
  return {
    ...state,
    campaigns: [...state.campaigns, { ...payload, updated: false }],
    currentId: payload.id
  }
}

const restoreCampaignReducer = (state, { payload }) => ({
  ...state,
  campaigns: [...state.campaigns, payload],
  currentId: state.currentId || payload.id
})

const loadCampaignsReducer = (state, action) => ({
  ...state,
  campaigns: mockCampaignsFetch(action.payload), // TEMPORARY - to be used in dev while waiting for back to handle all campaign parameter
  currentId: ''
})

const loadCohortsReducer = (state, action) => ({
  ...state,
  cohorts: action.payload
})

const selectCampaignReducer = (state, action) => ({
  ...state,
  currentId: action.payload
})

const deleteCampaignReducer = (state, action) => ({
  ...state,
  campaigns: state.campaigns.filter(campaign => campaign.id !== action.payload),
  currentId: state.currentId === action.payload ? '' : state.currentId
})

const findAndTransformCampaignReducer = R.curry((transform, state, action) => ({
  ...state,
  campaigns: state.campaigns.map(campaign =>
    campaign.id === state.currentId ? transform({ ...campaign, updated: true })(action) : campaign
  )
}))

const findAndTransformElementReducer = R.curry((transform, state, action) => ({
  ...state,
  campaigns: state.campaigns.map(campaign =>
    campaign.id === state.currentId ? transformElementReducer(transform)(campaign, action) : campaign
  )
}))

const transformElementReducer = R.curry((transformElement, campaign, action) => ({
  ...campaign,
  updated: true,
  elements: campaign.elements.map((el, i) => (i === action.payload.index ? transformElement(el, action) : el))
}))

/*
 *
 *
 * REDUCER
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
  case actionTypes.BROADCAST_SELECT_CHANNEL:
    return selectChannelReducer(state, action)
  case actionTypes.BROADCAST_SELECT_PREVIEW_USERS:
    return selectPreviewUsersReducer(state, action)
  case actionTypes.BROADCAST_LOAD_CAMPAIGNS_SUCCESS:
    return loadCampaignsReducer(state, action)
  case actionTypes.BROADCAST_FETCH_ANALYTICS_SUCCESS:
    return updateAnalyticsReducer(state, action)
  case actionTypes.BROADCAST_LOAD_COHORTS_SUCCESS:
    return loadCohortsReducer(state, action)
  case actionTypes.BROADCAST_CREATE_NEW_CAMPAIGN_SUCCESS:
    return createNewCampaignReducer(state, action)
  case actionTypes.BROADCAST_DELETE_CAMPAIGN_SUCCESS:
    return deleteCampaignReducer(state, action)
  case actionTypes.BROADCAST_SAVE_CAMPAIGN_SUCCESS:
    return findAndTransformCampaignReducer(saveCampaign)(state, action)
  case actionTypes.BROADCAST_DUPLICATE_CAMPAIGN_SUCCESS:
    return addCampaignReducer(state, action)
  case actionTypes.BROADCAST_CANCEL_CAMPAIGN_DELETE_SUCCESS:
    return restoreCampaignReducer(state, action)
  case actionTypes.BROADCAST_SELECT_CAMPAIGN:
    return selectCampaignReducer(state, action)
  case actionTypes.BROADCAST_SELECT_COHORT:
    return findAndTransformCampaignReducer(selectCohortReducer)(state, action)
  case actionTypes.BROADCAST_SELECT_IS_RECURRING:
    return findAndTransformCampaignReducer(selectRecurringReducer)(state, action)
  case actionTypes.BROADCAST_SELECT_RECURRENCE:
    return findAndTransformCampaignReducer(selectRecurrenceReducer)(state, action)
  case actionTypes.BROADCAST_SCHEDULE_CAMPAIGN_SUCCESS:
    return findAndTransformCampaignReducer(scheduleCampaign)(state, action)
  case actionTypes.BROADCAST_SEND_CAMPAIGN_SUCCESS:
    return findAndTransformCampaignReducer(sendCampaign)(state, action)
  case actionTypes.BROADCAST_SEND_PREVIEW_SUCCESS:
    return findAndTransformCampaignReducer(previewSent)(state, action)
  case actionTypes.BROADCAST_SEND_PREVIEW_FAILURE:
    return findAndTransformCampaignReducer(previewSent)(state, action)
  case actionTypes.BROADCAST_SEND_PREVIEW_REQUEST:
    return findAndTransformCampaignReducer(sendPreview)(state, action)
  case actionTypes.BROADCAST_UPDATE_CAMPAIGN_DATE:
    return findAndTransformCampaignReducer(updateCampaignDate)(state, action)
  case actionTypes.BROADCAST_UPDATE_CAMPAIGN_TITLE:
    return findAndTransformCampaignReducer(updateCampaignTitle)(state, action)
  case actionTypes.BROADCAST_TOGGLE_CAMPAIGN_SEND_NOW:
    return findAndTransformCampaignReducer(updateCampaignSendNow)(state, action)
  case actionTypes.BROADCAST_ADD_ELEMENT:
    return findAndTransformCampaignReducer(addElementToCampaign)(state, action)
  case actionTypes.BROADCAST_DELETE_ELEMENT:
    return findAndTransformCampaignReducer(deleteElementFromCampaign)(state, action)
  case actionTypes.BROADCAST_REORDER_ELEMENTS:
    return findAndTransformCampaignReducer(reorderElementsFromCampaign)(state, action)
  case actionTypes.BROADCAST_EDIT_CAROUSEL_ELEMENT_ADD_CARD:
    return findAndTransformElementReducer(editCarouselElementAddCard)(state, action)
  case actionTypes.BROADCAST_EDIT_CAROUSEL_ELEMENT_DELETE_CARD:
    return findAndTransformElementReducer(editCarouselElementDeleteCard)(state, action)
  case actionTypes.BROADCAST_EDIT_CAROUSEL_ELEMENT_REORDER_CARDS:
    return findAndTransformElementReducer(editCarouselElementReorderCards)(state, action)
  case actionTypes.BROADCAST_EDIT_CAROUSEL_ELEMENT_IMAGE:
    return findAndTransformElementReducer(editCarouselElementImageTemporaryUrl)(state, action)
  case actionTypes.BROADCAST_EDIT_CAROUSEL_ELEMENT_TITLE:
    return findAndTransformElementReducer(editCarouselElementTitle)(state, action)
  case actionTypes.BROADCAST_EDIT_CAROUSEL_ELEMENT_DESCRIPTION:
    return findAndTransformElementReducer(editCarouselElementDescription)(state, action)
  case actionTypes.BROADCAST_EDIT_CAROUSEL_ELEMENT_ADD_BUTTON:
    return findAndTransformElementReducer(editCarouselElementAddButton)(state, action)
  case actionTypes.BROADCAST_EDIT_CAROUSEL_ELEMENT_DELETE_BUTTON:
    return findAndTransformElementReducer(editCarouselElementDeleteButton)(state, action)
  case actionTypes.BROADCAST_EDIT_CAROUSEL_ELEMENT_BUTTON_TITLE:
    return findAndTransformElementReducer(editCarouselElementButtonTitle)(state, action)
  case actionTypes.BROADCAST_EDIT_CAROUSEL_ELEMENT_BUTTON_VALUE:
    return findAndTransformElementReducer(editCarouselElementButtonValue)(state, action)
  case actionTypes.BROADCAST_EDIT_IMAGE_ELEMENT:
    return findAndTransformElementReducer(editImageElementTemporaryUrl)(state, action)
  case actionTypes.BROADCAST_EDIT_TEXT_ELEMENT:
    return findAndTransformElementReducer(editTextElement)(state, action)
  case actionTypes.BROADCAST_EDIT_VIDEO_ELEMENT:
    return findAndTransformElementReducer(editVideoElementTemporaryUrl)(state, action)
  case actionTypes.BROADCAST_SAVE_CAROUSEL_ELEMENT_IMAGE_SUCCESS:
    return findAndTransformElementReducer(editCarouselElementImageUrl)(state, action)
  case actionTypes.BROADCAST_SAVE_IMAGE_ELEMENT_SUCCESS:
    return findAndTransformElementReducer(editImageElementUrl)(state, action)
  case actionTypes.BROADCAST_SAVE_VIDEO_ELEMENT_SUCCESS:
    return findAndTransformElementReducer(editVideoElementUrl)(state, action)
  default:
    return state
  }
}

export default reducer
