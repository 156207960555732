import { createSelector } from 'reselect'
import { createMatchSelector } from 'connected-react-router'
import { maybe, remoteData } from '@sbizeul/fp-flow'
import * as R from 'ramda'

import * as map from '../../helpers/map'

import answer from '../answer'

import * as routes from './routes'

export const getEntityState = state => state.entities.qa

export const getUiState = state => state.ui.qa

export const questionRouteSelector = createMatchSelector(routes.QA_QUESTIONS)

export const qnARouteSelector = createMatchSelector(routes.QA_QnA)

export const getQuestionId = createSelector(qnARouteSelector, routeParams =>
  maybe.fromNullable(R.view(R.lensPath(['params', 'id']), routeParams))
)

const byId = state => questionId => maybe.getOrElse(() => remoteData.notAsked(), map.get(questionId, state.byId))

export const getById = createSelector([getEntityState, getQuestionId], (state, questionId) =>
  maybe.fold(() => remoteData.notAsked(), byId(state), questionId)
)

export const isValid = answer.selectors.isAnswerValid

export const getIsImporting = createSelector(getUiState, state => state.isImporting)

export const getIsDeleting = createSelector(getUiState, state => state.isDeleting)

export const getIsUpdatingQuestion = createSelector(getUiState, state => state.isUpdatingQuestion)

export const getIsCreatingSection = createSelector(getUiState, state => state.isCreatingSection)

export const getIsUpdatingSection = createSelector(getUiState, state => state.isUpdatingSection)

export const getIsDeletingSection = createSelector(getUiState, state => state.isDeletingSection)

export const getCollapsibleSectionOpeningStatus = createSelector(getUiState, state => state.collapsibleSectionsOpeningStatus)