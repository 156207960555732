import { connect } from 'react-redux'
import { Maybe } from '@sbizeul/fp-flow'

import bot from 'modules/bot'
import question from 'modules/question'
import { AppState as BotAppState } from 'modules/bot/types'
import { AppState as QuestionAppState } from 'modules/question/types'

import * as actions from '../actions'
import AnswerEditorSimple from '../components/AnswerEditor/AnswerEditorSimple'
import * as selectors from '../selectors'

import {
  AppState,
  SimpleDispatchFromProps,
  StateFromProps,
  Answer
} from '../types'

type State = AppState & BotAppState & QuestionAppState

const mapStateToProps: (
  state: State,
  props: { answer: Maybe<Answer>; canCreateQR?: boolean }
) => Omit<StateFromProps, 'keywords'> = (state, props) => ({
  answer: props.answer,
  imagesUrl: selectors.getImagesUrl(state),
  skills: selectors.getSimplifiedSkills(state),
  tags: selectors.getSimplifiedTags(state),
  languages: bot.selectors.getLanguages(state),
  questions: question.selectors.getAll(state)
})

const mapDispatchToProps: Omit<SimpleDispatchFromProps, 'fetchAllKeywords'> = {
  fetchAllSkills: actions.fetchAllSimplifiedSkills,
  fetchAllTags: actions.fetchAllSimplifiedTags,
  fetchQuestions: question.actions.fetchAll,
  uploadImage: actions.uploadImage,
  goToSkills: actions.goToSkills
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnswerEditorSimple)
