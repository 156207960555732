import { Predicate } from 'fp-ts/lib/function'
import * as R from 'ramda'

import { Id } from 'types'

import { MonitorLogs } from '../../types'
import { GroupedUserLogs, LogSelection } from './types'

const updateSelectedIds: (selectedLogIds: Id[]) => (logSelection: LogSelection) => Id[] =
  selectedLogIds => logSelection => logSelection.isSelected
    ? addLogId(logSelection.logId)(selectedLogIds)
    : removeLogId(logSelection.logId)(selectedLogIds)

const removeLogId: (logId: Id) => (selectedLogIds: Id[]) => Id[] = logId => R.without([logId])

const addLogId: (logId: Id) => (selectedLogIds: Id[]) => Id[] = logId => R.append(logId)

const isSelected: (logId: Id) => (selectedLogIds: Id[]) => boolean = R.includes

const selectedLogs: (selectedLogIds: Id[]) => (allLogs: MonitorLogs) => MonitorLogs =
  selectedLogIds => allLogs => allLogs.filter(
    userLog => selectedLogIds.includes(userLog.id)
  )

const noLogs: Predicate<GroupedUserLogs> = R.compose(
  R.isEmpty, R.reject(R.isEmpty)
)

export {
  isSelected,
  noLogs,
  selectedLogs,
  updateSelectedIds
}
