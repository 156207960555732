import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import FormInput from 'components/FormInput'
import { RegisterState } from '../../types'

import './styles.css'

type Props = {
  register: (inputs: RegisterState) => unknown
}

const validationSchema = Yup.object().shape({
  username: Yup.string().min(3, 'Your complete name must be at least 3 characters').required('Please enter your complete name'),
  email: Yup.string().email('Please enter a valid e-mail').required('Please enter your e-mail'),
  companyName: Yup.string().min(3, 'Company name must be at least 3 characters').required('Please enter your company name'),
  phoneNumber: Yup.string()
})

const Register = ({ register }: Props) => {
  const formik = useFormik({
    initialValues: {
      username: '',
      email: '',
      companyName: '',
      phoneNumber: ''
    },
    validationSchema,
    onSubmit: inputs => { register(inputs) }
  })
  const setTouched = (inputName: string) => formik.setTouched({ ...formik.touched, [inputName]: true })

  return <form className='Register-form-container' onSubmit={formik.handleSubmit}>
    <FormInput
      type='text'
      label='Name *'
      name='username'
      placeholder='Your first name and last name…'
      onBlur={() => setTouched('username')}
      onChange={formik.handleChange}
      value={formik.values.username}
      error={formik.touched.username && formik.errors.username}
    />
    <FormInput
      type='email'
      label='E-mail *'
      name='email'
      placeholder='Your professional e-mail address…'
      onBlur={() => setTouched('email')}
      onChange={formik.handleChange}
      value={formik.values.email}
      error={formik.touched.email && formik.errors.email}
    />
    <FormInput
      type='text'
      label='Company Name *'
      name='companyName'
      onBlur={() => setTouched('companyName')}
      onChange={formik.handleChange}
      placeholder='Your company name…'
      value={formik.values.companyName}
      error={formik.touched.companyName && formik.errors.companyName}
    />
    <FormInput
      type='tel'
      label='Phone number'
      name='phoneNumber'
      placeholder='Your phone number...'
      onChange={formik.handleChange}
      value={formik.values.phoneNumber}
    />
    <button disabled={!formik.touched.username || !formik.isValid} type='submit' className='Register-submitButton'>Request an access</button>
  </form>
}

export default Register
