import * as R from 'ramda'
import { either } from '@sbizeul/fp-flow'

import question from 'modules/question'
import {
  ARCHIVE_QUESTION_REQUEST,
  ArchiveQuestionRequest,
  CREATE_SECTION_FAILURE,
  CREATE_SECTION_REQUEST,
  CREATE_SECTION_SUCCESS,
  CreateSectionFailure,
  DELETE_SECTION_FAILURE,
  DELETE_SECTION_REQUEST,
  DELETE_SECTION_SUCCESS,
  DeleteSectionFailure,
  DeleteSectionRequest,
  SAVE_ONE_FAILURE,
  SAVE_ONE_SUCCESS,
  SAVE_SECTION_FAILURE,
  SAVE_SECTION_REQUEST,
  SAVE_SECTION_SUCCESS,
  SaveOneFailure,
  SaveSectionFailure,
  SaveSectionRequest,
  UNARCHIVE_QUESTION_REQUEST,
  UnarchiveQuestionRequest
} from 'modules/question/types'

import * as qaActions from './actions'
import {
  DeleteAllFailure,
  DeleteOneQnAFailure,
  ImportFailure,
  QAUiAction,
  QAUiState,
  SaveOpeningStatusSuccess,
  SAVE_COLLAPSIBLE_SECTION_OPENING_STATUS_SUCCESS
  // OpeningStatusInfo
} from './types'

export const initialState: QAUiState = {
  isImporting: either.right(false),
  isDeleting: either.right(false),
  isUpdatingQuestion: either.right(question.models.updatingQuestion.nothing()),
  isCreatingSection: either.right(false),
  isUpdatingSection: either.right(either.left(false)),
  isDeletingSection: either.right(either.left(false)),
  collapsibleSectionsOpeningStatus: {}
}

const isImporting = R.lensProp('isImporting')

const importFile = R.set(isImporting, either.right(true))

const importFileSuccess = R.set(isImporting, either.right(false))

const importFileFailure = (state: QAUiState, action: ImportFailure) =>
  R.set(isImporting, either.left(action.payload), state)

const isDeleting = R.lensProp('isDeleting')

const deletingQnA = R.set(isDeleting, either.right(true))

const deletingQnASuccess = R.set(isDeleting, either.right(false))

const deletingQnAFailure = (state: QAUiState, action: DeleteOneQnAFailure | DeleteAllFailure) =>
  R.set(isDeleting, either.left(action.payload), state)

const isCreatingSection = R.lensProp('isCreatingSection')

const createSection = R.set(isCreatingSection, either.right(true))

const createSectionSuccess = R.set(isCreatingSection, either.right(false))

const createSectionFailure = (state: QAUiState, action: CreateSectionFailure) =>
  R.set(isCreatingSection, either.left(action.payload), state)

const isUpdatingSection = R.lensProp('isUpdatingSection')

const updateSection = (state: QAUiState, action: SaveSectionRequest) =>
  R.set(isUpdatingSection, either.right(either.right(action.payload)), state)

const updateSectionSuccess = R.set(isUpdatingSection, either.right(either.left(false)))

const updateSectionFailure = (state: QAUiState, action: SaveSectionFailure) =>
  R.set(isUpdatingSection, either.left(action.payload), state)

const isDeletingSection = R.lensProp('isDeletingSection')

const deleteSection = (state: QAUiState, action: DeleteSectionRequest) =>
  R.set(isDeletingSection, either.right(either.right(action.payload)), state)

const deleteSectionSuccess = R.set(isDeletingSection, either.right(either.left(false)))

const deleteSectionFailure = (state: QAUiState, action: DeleteSectionFailure) =>
  R.set(isDeletingSection, either.left(action.payload), state)

const isUpdatingQuestion = R.lensProp('isUpdatingQuestion')

const archiveQuestion = (state: QAUiState, action: ArchiveQuestionRequest) =>
  R.set(isUpdatingQuestion, either.right(question.models.updatingQuestion.archive(action.payload)), state)

const unarchiveQuestion = (state: QAUiState, action: UnarchiveQuestionRequest) =>
  R.set(isUpdatingQuestion, either.right(question.models.updatingQuestion.unarchive(action.payload)), state)

const updateQuestionSuccess = R.set(isUpdatingQuestion, either.right(question.models.updatingQuestion.nothing()))

const updateQuestionFailure = (state: QAUiState, action: SaveOneFailure) =>
  R.set(isUpdatingQuestion, either.left(action.payload), state)

const saveCollapsibleSectionOpeningStatusSuccess = (state: QAUiState, action: SaveOpeningStatusSuccess) => {
  // Create new array
  const collapsibleSectionsOpeningStatus = {
    ...state.collapsibleSectionsOpeningStatus,
    [action.payload.id]: action.payload.isOpened
  }

  // Populate the state
  return {
    ...state,
    collapsibleSectionsOpeningStatus
  }
}

export default function (state: QAUiState = initialState, action: QAUiAction) {
  switch (action.type) {
    case qaActions.IMPORT_REQUEST:
      return importFile(state)
    case qaActions.IMPORT_SUCCESS:
      return importFileSuccess(state)
    case qaActions.IMPORT_FAILURE:
      return importFileFailure(state, action)
    case qaActions.DELETE_ONE_QNA_REQUEST:
    case qaActions.DELETE_ALL_QNA_REQUEST:
      return deletingQnA(state)
    case qaActions.DELETE_ONE_QNA_SUCCESS:
    case qaActions.DELETE_ALL_QNA_SUCCESS:
      return deletingQnASuccess(state)
    case qaActions.DELETE_ONE_QNA_FAILURE:
    case qaActions.DELETE_ALL_QNA_FAILURE:
      return deletingQnAFailure(state, action)
    case CREATE_SECTION_REQUEST:
      return createSection(state)
    case CREATE_SECTION_SUCCESS:
      return createSectionSuccess(state)
    case CREATE_SECTION_FAILURE:
      return createSectionFailure(state, action)
    case SAVE_SECTION_REQUEST:
      return updateSection(state, action)
    case SAVE_SECTION_SUCCESS:
      return updateSectionSuccess(state)
    case SAVE_SECTION_FAILURE:
      return updateSectionFailure(state, action)
    case DELETE_SECTION_REQUEST:
      return deleteSection(state, action)
    case DELETE_SECTION_SUCCESS:
      return deleteSectionSuccess(state)
    case DELETE_SECTION_FAILURE:
      return deleteSectionFailure(state, action)
    case ARCHIVE_QUESTION_REQUEST:
      return archiveQuestion(state, action)
    case UNARCHIVE_QUESTION_REQUEST:
      return unarchiveQuestion(state, action)
    case SAVE_ONE_SUCCESS:
      return updateQuestionSuccess(state)
    case SAVE_ONE_FAILURE:
      return updateQuestionFailure(state, action)
    case SAVE_COLLAPSIBLE_SECTION_OPENING_STATUS_SUCCESS:
      return saveCollapsibleSectionOpeningStatusSuccess(state, action)
    default:
      return state
  }
}
