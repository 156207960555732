import styled, { css } from 'styled-components'
import { initButton, initInput } from '../../style/globalStyles'

const cssDisabled = css`
  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
`

const PanelStyled = styled.div`
  flex: 1;
  height: 100%;
  background-color: ${({ theme }) => theme.color.mainContentBg};
  padding: ${({ theme }) => theme.padding.mainContent}px;
  color: ${({ theme }) => theme.color.defaultColor}px;
  overflow: auto;
  transition: filter 250ms ease-in-out;
  ${props => props.blurry && 'filter: blur(8px);'}
`
const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

const LeftSection = styled.div`
  display: flex;
  align-items: center;
`

const TitleContainer = styled.div``

const DuplicateButtonContainer = styled.button`
  ${initButton}
  float: right;
  display: inline-flex;
  justify-content: center;
  position: relative;
`
const PreviewButtonContainer = styled.button`
  ${initButton}
  font-weight: bold;
  float: right;
  display: inline-flex;
  justify-content: center;
  position: relative;
  border: solid 2px;
  color: ${({ theme }) => theme.color.blue};
  border-color: ${({ theme }) => theme.color.blue};
  border-radius: 5px;
  padding: 8px 10px;
  margin-left: 10px;
  transition: opacity 250ms ease-in-out;

  span {
    margin-right: 10px;
  }

  ${cssDisabled}
`
const TooltipContainer = styled.span`
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s;
  width: 120px;
  background-color: ${({ theme }) => theme.color.defaultColor};
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  font-size: 13px;
  position: absolute;
  right: 125%;
  z-index: 1;
  &:after {
    content: ' ';
    position: absolute;
    top: 50%;
    left: 100%; /* To the right of the tooltip */
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent ${({ theme }) => theme.color.defaultColor};
  }
  ${DuplicateButtonContainer}:hover & {
    visibility: visible;
    opacity: 1;
  }
`

const Title = styled.h1`
  ${initInput}
  font-weight: 700;
  font-size: 2.85em;
  height: 1.25em;
  line-height: 1.25em;
  display: ${({ visible }) => (visible ? 'inline-flex' : 'none')};
  border-radius: ${({ theme }) => theme.borderRadius.titleInput}px;
  color: ${({ theme }) => theme.color.defaultColor};
  border: 1px solid ${({ theme, noBorder }) => (noBorder ? 'transparent' : theme.color.grey)};
`

const ButtonEdit = styled.button`
  ${initButton}
  text-decoration: underline;
  text-transform: uppercase;
  margin-left: 25px;
`

const Section = styled.section`
  /* display: ${({ block }) => (block ? 'block' : 'flex')}; */
  /* flex-direction: column; */
  display: block;
  margin-top: ${({ theme }) => theme.margin.top.section}px;
  margin-bottom: ${({ theme }) => theme.margin.bottom.section}px;
  max-width: 600px;
`

const SectionTitle = styled.h2`
  font-size: 2em;
  font-weight: 100;
  margin-bottom: ${({ theme }) => theme.margin.bottom.sectionTitle}px;
  color: ${({ theme }) => theme.color.defaultColor};
`

const SectionActionTitle = styled.h3`
  display: flex;
  align-items: center;
  font-size: 1em;
  font-weight: 700;
  margin-bottom: ${({ theme }) => theme.margin.bottom.sectionActionTitle}px;
  color: ${({ theme }) => theme.color.defaultColor};
`

const sectionActionsDeactivated = css`
  > * {
    opacity: 0.5;
  }
  > * {
    pointer-events: none;
  }
`
const SectionActions = styled.div`
  display: flex;
  width: ${({ children, theme, grouped }) => {
    if (!grouped) return '100%'
    return `${children.length * theme.width.outlinedUIGrouped}px`
  }}
    > * {
    flex-grow: ${({ grouped }) => (grouped ? 1 : 0)};
    transition: all 250ms ease-in-out;
  }

  > *:not(:first-child) {
    margin-left: 50px;
    min-width: 100px;
  }

  ${props => props.deactivated && sectionActionsDeactivated}
`

export {
  PanelStyled,
  TopContainer,
  LeftSection,
  TitleContainer,
  Title,
  ButtonEdit,
  Section,
  SectionTitle,
  SectionActionTitle,
  SectionActions,
  DuplicateButtonContainer,
  PreviewButtonContainer,
  TooltipContainer
}
