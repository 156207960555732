import React from 'react'
import * as R from 'ramda'
import { MdClose, MdQuestionAnswer } from 'react-icons/md'
import { v4 as uuid } from 'uuid'

import TextArea from 'components/TextArea'
import { Keywords } from 'modules/keyword/types'
import { Id } from 'types'

import QuickReplies from './QuickReplies'
import { Question, UpdateQuestionContentParams, ParamsId, Button } from '../../types'
import { emptyButton, selectedKeyword, updateQuestionWithKeyword } from '../../models'

export type Props = Readonly<{
  question: Question
  keywords: Keywords

  removeQuestionContent: (removeContentParam: ParamsId) => unknown
  updateQuestionContent: (updateContentParam: UpdateQuestionContentParams) => unknown
  updateQuestion: (newQuestion: Question) => unknown
}>

const QuestionEditor = ({ keywords, question, updateQuestionContent, removeQuestionContent, updateQuestion }: Props) => {
  const handleTextChange = (text: string) => {
    const simpleText = R.set(R.lensProp('label'), text, question)
    updateQuestionContent({ id: simpleText.id, text: simpleText.label })
  }
  const handleClickDelete = () => {
    removeQuestionContent({ id: R.view(R.lensProp('id'), question) })
  }

  const addQuickReply = () => updateQuestion(R.over(R.lensProp('buttons'), R.append(emptyButton(uuid())), question))

  const updateQuickReply: ({ index, text }: {
    index: number
    text: string
  }) => unknown = ({ index, text }) => updateQuestion(R.set(R.lensPath(['buttons', index, 'label']), text, question))

  const updateKeyword = R.pipe(
    updateQuestionWithKeyword(question),
    updateQuestion
  )

  const removeQuickReply: (id: Id) => unknown =
    (id) => updateQuestion(R.over(
      R.lensProp('buttons'),
      R.reject<Button>(button => button.id === id),
      question
    ))

  return (
    <div className='QuestionEditor'>
      <div className='QuestionEditor-icons-container'>
        <MdQuestionAnswer size='25' />
        <p className='QuestionEditor-question--title'>Question</p>
        <MdClose
          data-testid='remove-content'
          size='20'
          className='QuestionEditor-delete--icon'
          onClick={handleClickDelete}
        />
      </div>
      <p>Assistant question</p>
      <div className='QuestionEditor-text'>
        <TextArea
          data-testid='update-text'
          className='QuestionEditor-text--input'
          type='rounded'
          required
          pattern='^.{1,600}'
          placeholder='Add a question...'
          autocomplete='off'
          onChangeText={handleTextChange}
          value={R.view(R.lensProp('label'), question)}
        />
      </div>
      <QuickReplies
        add={addQuickReply}
        elements={question.buttons}
        onChangeText={updateQuickReply}
        remove={removeQuickReply}
        keywords={keywords}
        updateKeyword={updateKeyword}
        selectedKeyword={selectedKeyword(keywords)(question)}
      />
    </div>
  )
}

export default QuestionEditor
