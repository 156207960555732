import React from 'react'
import { Table } from 'semantic-ui-react'

export default function CustomTable({ data, header }) {
  const content = data.map((elem, index) => (
    <Table.Row key={index}>
      {header.map((key, index) => (
        <Table.Cell key={index}>{elem[key]}</Table.Cell>
      ))}
    </Table.Row>
  ))
  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          {header.map(key => (
            <Table.HeaderCell key={key}>{key}</Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>
      <Table.Body>{content}</Table.Body>
    </Table>
  )
}
