import * as React from 'react'
import { FaTrashAlt, FaPencilAlt } from 'react-icons/fa'
import { FiAlertCircle } from 'react-icons/fi'
import classNames from 'classnames'

import TextInput from 'components/TextInput'

import { Id } from 'types'
import { TrainingQuestion as TrainingQuestionT, SaveOneParams, DeleteOneParams } from '../../types'

import './styles.css'

type TrainingQuestionState = {
  isEditable: boolean,
  text: string,
  hasValidationErrors: boolean
}

type Props = Readonly<{
  trainingQuestion: TrainingQuestionT

  saveTraining: (param: SaveOneParams) => unknown
  deleteTraining: (param: DeleteOneParams) => unknown
}>

class TrainingQuestion extends React.PureComponent<Props> {
  state: TrainingQuestionState = {
    isEditable: false,
    text: this.props.trainingQuestion.text,
    hasValidationErrors: false
  }

  textInput = React.createRef<HTMLDivElement>()

  componentDidUpdate = () => {
    if (this.state.hasValidationErrors && this.isInputValid(this.state.text)) {
      this.setState({ hasValidationErrors: false })
    }
  }

  makeInputEditable = () =>
    this.setState({ isEditable: true }, () => {
      this.textInput.current && this.textInput.current.focus()
    })

  makeInputUneditable = () => this.setState({ isEditable: false })

  handleTextUpdate = (text: string) => this.setState({ text })

  handleEscapeEdition = () => {
    this.setState({ text: this.props.trainingQuestion.text })
    this.makeInputUneditable()
  }

  isInputValid = (text: string) => text.trim().length >= 2

  handleTextValidation = () => {
    if (this.state.text.trim() === this.props.trainingQuestion.text) {
      this.makeInputUneditable()
    } else if (this.isInputValid(this.state.text)) {
      this.props.saveTraining({
        trainingQuestion: this.props.trainingQuestion,
        text: this.state.text.trim()
      })
      this.makeInputUneditable()
    } else {
      this.setState({ hasValidationErrors: true })
      this.textInput.current && this.textInput.current.focus()
    }
  }

  handleDelete = (questionId: Id, trainingQuestionId: Id) => {
    this.props.deleteTraining({ questionId, trainingQuestionId })
  }

  getAlertIconClassnames = (hasValidationErrors: boolean) => ({
    'trainingQuestion-label--alert-icon--hidden': !hasValidationErrors
  })

  render () {
    const { question_id, id } = this.props.trainingQuestion

    return (
      <div className='trainingQuestion'>
        <div className='trainingQuestion-label' onDoubleClick={this.makeInputEditable}>
          <TextInput
            className='trainingQuestion-label--input'
            ref={this.textInput as React.MutableRefObject<HTMLInputElement>}
            disabled={!this.state.isEditable}
            value={this.state.text}
            onChange={event => this.handleTextUpdate(event.target.value)}
            onEnterKeyPressed={this.handleTextValidation}
            onEscapeKeyPressed={this.handleEscapeEdition}
            onBlur={this.handleTextValidation}
          />
          <FiAlertCircle
            className={classNames(
              'trainingQuestion-label--alert-icon',
              this.getAlertIconClassnames(this.state.hasValidationErrors)
            )}
          />
        </div>
        <div className='trainingQuestion-buttons'>
          <FaPencilAlt className='trainingQuestion-button' data-testid='edit' onClick={this.makeInputEditable} />
          <FaTrashAlt
            className='trainingQuestion-button'
            data-testid='delete'
            onClick={() => this.handleDelete(question_id, id)}
          />
        </div>
      </div>
    )
  }
}

export default TrainingQuestion
