import * as R from 'ramda'

export const initialState = []

const pushNotification = (notification, state) => [...state, notification]

const popNotification = id => R.filter(R.pipe(R.propEq('id', id), R.not))

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
  case 'notifications/PUSH_NOTIFICATION':
    return pushNotification(action.payload, state)
  case 'notifications/POP_NOTIFICATION':
    return popNotification(action.payload)(state)
  default:
    return state
  }
}

export default notificationsReducer
