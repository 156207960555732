import moment from 'moment'

const id = '123456'
const date = moment([2019, 9, 16, 15, 6])
const timeZone = 'Europe/Paris'
const createdCampaignTest = {
  initState: {
    campaigns: [],
    currentId: null
  },
  action: {
    id,
    date,
    timeZone
  },
  reducedState: {
    campaigns: [
      // updated
      {
        id,
        updated: false,
        title: 'Campaign name',
        elements: [],
        cohorts: [],
        date,
        timeZone,
        sendNow: true,
        kpis: {
          sent: 0,
          read: 0,
          targeted: 0
        },
        status: 'draft',
        sendingPreview: false,
        channel: '',
        recurrence: 'day',
        recurring: false
      }
    ],
    currentId: id // updated
  }
}

export default createdCampaignTest
