import React from 'react'
import ReactHighcharts from 'react-highcharts'
import defaultStyle from '../chartStyle'

const config = {
  ...defaultStyle,
  chart: {
    type: 'bar'
  },
  xAxis: {
    crosshair: true
  }
}

export default function BarGraphButtons({ entities = null }) {
  const series = []
  if (entities) {
    series.push({
      animation: false,
      name: 'Occurrences',
      data: entities.map(elem => [elem.name, elem.value])
    })
  }
  config.xAxis.categories = entities.map(elem => elem.name)
  return <ReactHighcharts config={{ ...config, series }} domProps={{ id: 'BarGraphEntities' }} />
}
