import React, { Component } from 'react'
import { connect } from 'react-redux'
import { maybe } from '@sbizeul/fp-flow'

import operations from '../../../../../redux/analytics/operations'
import getSafe from '../../../../utils/getSafe'
import { getMessagesPerUser, getMessagesPerUserPrevPeriod } from '../../../../../redux/selectors'
import Users from './Users'
import NewFilters from '../../Filters/NewFilters'
import bot from '../../../../../modules/bot'

class UsersContainer extends Component {
  componentDidMount() {
    const { filters } = this.props
    this.props.fetchAnalyticsTrafficUsers(filters)
  }

  componentDidUpdate(prevProps) {
    const { filters } = this.props
    if (!maybe.equals(prevProps.botId, this.props.botId) || prevProps.filters.channel.id !== filters.channel.id) {
      this.props.fetchAnalyticsTrafficUsers(filters)
    }
  }

  render() {
    return (
      <div>
        <NewFilters fetch={this.props.fetchAnalyticsTrafficUsers} />
        <Users {...this.props} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  filters: state.main.filters,
  conversations: getSafe(() => state.analytics.conversations.count.total, 0),
  fetchingConversations: getSafe(() => state.analytics.conversations.fetching),
  messagesPerUser: getMessagesPerUser(state.analytics),
  fetchingMessages: getSafe(() => state.analytics.messages.fetching),
  fetchingUsers: getSafe(() => state.analytics.users.fetching),
  messagesPerUserPrevPeriod: getMessagesPerUserPrevPeriod(state.analytics),
  fetchingMessagesPrev: getSafe(() => state.analytics.messages.fetchingCountPreviousPeriod),
  fetchingUsersPrev: getSafe(() => state.analytics.users.fetchingPrevious),
  usersGender: getSafe(() => state.analytics.demographics.count.gender),
  fetchingUsersGender: getSafe(() => state.analytics.demographics.fetching),
  overallUsersCount: getSafe(() => state.analytics.users.count.all_time_total),
  usersData: getSafe(() => state.analytics.users.data),
  usersCount: getSafe(() => state.analytics.users.count.total),
  usersCountPrevPeriod: getSafe(() => state.analytics.users.countPreviousPeriod.total),
  newUsers: getSafe(() => state.analytics.users.new),
  fetchingNewUsers: getSafe(() => state.analytics.users.fetchingNew),
  newUsersCount: getSafe(() => state.analytics.users.count.new),
  newUsersCountPrevPeriod: getSafe(() => state.analytics.users.countPreviousPeriod.new),
  comparisonDateRange: getSafe(() => state.main.filters.comparisonDateRange),
  period: ['minutes', 'hours', 'days'][getSafe(() => state.main.filters.periods.active)],
  botId: bot.selectors.getSelectedId(state)
})

export default connect(mapStateToProps, operations)(UsersContainer)
