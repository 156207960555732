import QaAnswerPage from './components/QaAnswerPage'
import QaRoot from './containers/QaRoot'
import * as routes from './routes'
import * as selectors from './selectors'
import * as entityReducer from './reducer.entity'
import * as uiReducer from './reducer.ui'
import * as saga from './saga'

export default {
  components: {
    QaAnswerPage,
    QaRoot
  },
  reducers: {
    entity: entityReducer,
    ui: uiReducer
  },
  routes,
  saga,
  selectors
}
