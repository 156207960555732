import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import _ from 'lodash'
import { Form, Button } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { maybe } from '@sbizeul/fp-flow'
import { compose } from 'redux'

import InputField from '../../../components/InputField'
import bot from '../../../../modules/bot'
import intent from '../../../../modules/intent'

const required = value => (value ? undefined : 'Required')
const noEmpty = value => (_.trim(value) ? undefined : 'No Empty')

const IntentForm = ({ createIntent, handleSubmit, pristine, submitting }) => (
  <Form onSubmit={handleSubmit(createIntent)} autoComplete='off'>
    <Field validate={[required, noEmpty]} name='name' label='Intent Name' component={InputField} type='text' />
    <Field
      validate={[required, noEmpty]}
      name='description'
      component={InputField}
      label='Description'
      type='notes'
      truc='toto'
    />
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Button inverted size='small' color='green' type='submit' disabled={pristine || submitting}>
        Create
      </Button>
    </div>
  </Form>
)

IntentForm.propTypes = {
  createIntent: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired
}

const mapDispatchToProps = {
  createIntent: intent.actions.create
}

const mapStateToProps = state => ({
  initialValues: {
    botId: maybe.getOrElse(() => 'unknownBotId', bot.selectors.getSelectedId(state))
  }
})

const withRedux = connect(mapStateToProps, mapDispatchToProps)

const withReduxForm = reduxForm({
  enableReinitialize: true,
  form: 'IntentForm'
})

export default compose(withRedux, withReduxForm)(IntentForm)
