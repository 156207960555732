
import React from 'react'
import { FaTimes } from 'react-icons/fa'

const DeleteButton = ({ removeAnswer, className }) => (
  <div className={className}>
    <FaTimes data-testid='remove-content' className={`${className}--delete`} onClick={removeAnswer} />
  </div>
)

export default DeleteButton
