import * as R from 'ramda'
import { either } from '@sbizeul/fp-flow'

import * as questionActions from './actions'
import { CreateFailureParam, QuestionUiAction, QuestionUiState } from './types'

export const initialState: QuestionUiState = {
  isPosting: either.right(false)
}

const isPosting = R.lensProp('isPosting')

const createRequest = (state: QuestionUiState) =>
  R.set(isPosting, either.right(true), state)

const createSuccess = (state: QuestionUiState) =>
  R.set(isPosting, either.right(false), state)

const createFailure = (
  state: QuestionUiState,
  { payload }: { payload: CreateFailureParam }
) => R.set(isPosting, either.left(payload), state)

export default function (
  state: QuestionUiState = initialState,
  action: QuestionUiAction
) {
  switch (action.type) {
    case questionActions.CREATE_REQUEST:
      return createRequest(state)
    case questionActions.CREATE_SUCCESS:
      return createSuccess(state)
    case questionActions.CREATE_FAILURE:
      return createFailure(state, action)
    default:
      return state
  }
}
