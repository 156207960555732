import { ServerFailure, Id } from '../../types'
import {

  CreateOneParams,
  CREATE_ONE_REQUEST,
  CREATE_ONE_FAILURE,
  CREATE_ONE_SUCCESS,

  DeleteOneParams,
  DELETE_ONE_REQUEST,
  DELETE_ONE_FAILURE,
  DELETE_ONE_SUCCESS,

  UPDATE_ONE,

  SaveOneParams,
  SAVE_ONE_REQUEST,
  SAVE_ONE_FAILURE,
  SAVE_ONE_SUCCESS,

  FetchAllSuccessParams,
  FetchAllFailureParams,
  FETCH_ALL_REQUEST,
  FETCH_ALL_FAILURE,
  FETCH_ALL_SUCCESS
} from './types'

export const createOne = (trainingQuestion: CreateOneParams) => ({
  type: CREATE_ONE_REQUEST,
  payload: trainingQuestion
})

export const createOneFailure = (error: ServerFailure) => ({
  type: CREATE_ONE_FAILURE,
  payload: error
})

export const createOneSuccess = (id: Id) => ({
  type: CREATE_ONE_SUCCESS,
  payload: id
})

export const deleteOne = (trainingQuestion: DeleteOneParams) => ({
  type: DELETE_ONE_REQUEST,
  payload: trainingQuestion
})

export const deleteOneFailure = (error: ServerFailure) => ({
  type: DELETE_ONE_FAILURE,
  payload: error
})

export const deleteOneSuccess = (trainingId: Id) => ({
  type: DELETE_ONE_SUCCESS,
  payload: trainingId
})

export const saveOne = (trainingQuestion: SaveOneParams) => ({
  type: SAVE_ONE_REQUEST,
  payload: trainingQuestion
})

export const saveOneFailure = (error: ServerFailure) => ({
  type: SAVE_ONE_FAILURE,
  payload: error
})

export const saveOneSuccess = (questionId: Id) => ({
  type: SAVE_ONE_SUCCESS,
  payload: questionId
})

export const fetchAll = (questionId: Id) => ({
  type: FETCH_ALL_REQUEST,
  payload: questionId
})

export const fetchAllSuccess = (trainingQuestions: FetchAllSuccessParams) => ({
  type: FETCH_ALL_SUCCESS,
  payload: trainingQuestions
})

export const fetchAllFailure = (error: FetchAllFailureParams) => ({
  type: FETCH_ALL_FAILURE,
  payload: error
})

export {
  CREATE_ONE_REQUEST,
  CREATE_ONE_FAILURE,
  CREATE_ONE_SUCCESS,

  DELETE_ONE_REQUEST,
  DELETE_ONE_FAILURE,
  DELETE_ONE_SUCCESS,

  UPDATE_ONE,

  SAVE_ONE_REQUEST,
  SAVE_ONE_FAILURE,
  SAVE_ONE_SUCCESS,

  FETCH_ALL_REQUEST,
  FETCH_ALL_FAILURE,
  FETCH_ALL_SUCCESS
}
