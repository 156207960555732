import React from 'react'
import ReactHighcharts from 'react-highcharts'
import defaultStyle from '../chartStyle'
import NoDataSegment from '../NoDataSegment'

const config = {
  ...defaultStyle,
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: 'pie'
  },
  tooltip: {
    pointFormat: '{series.name}: <b>{point.percentage:.1f}% ({point.y} actions)</b>',
    shared: true
  },
  plotOptions: {
    pie: {
      cursor: 'pointer',
      dataLabels: {
        enabled: false
      },
      showInLegend: true
    }
  }
}

export default function MostPopularButtons({ data1, data2, noDataDisplay }) {
  if (noDataDisplay) {
    return <NoDataSegment />
  }
  config.series = [
    {
      type: 'pie',
      animation: false,
      name: 'Repartition',
      data: [
        ['Text', data2],
        ['Button', data1]
      ]
    }
  ]
  return <ReactHighcharts config={config} domProps={{ id: 'TextButtonsPieChart' }} />
}
