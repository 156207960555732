import * as actions from './actions'
import * as entity from './reducer.entity'
import * as ui from './reducer.ui'
import * as routes from './routes'
import * as saga from './saga'
import ChannelSettingsContainer from './containers/ChannelSettingsContainer/ChannelSettingsContainer'
import ChannelsList from './components/ChannelsList/ChannelsList'
import Zendesk from './components/Zendesk/Zendesk'
import Shopify from './components/Shopify/Shopify'
import Instagram from './components/Instagram/Instagram'
import Messenger from './components/Messenger/Messenger'
import { channelsConfig as config } from './config'

export default {
  actions,
  components: {
    ChannelSettingsContainer,
    ChannelsList,
    Zendesk,
    Shopify,
    Instagram,
    Messenger
  },
  reducers: {
    entity,
    ui
  },
  routes,
  saga,
  config
}
