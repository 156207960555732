import * as R from 'ramda'

import { Id } from 'types'
import { Question, Section } from 'modules/question/types'

import { QaQuestionPageProps } from '../../types'

export type QuestionsBySection = Readonly<{
  section: Section
  questions: ReadonlyArray<Question>
}>

export const sectionProp: (questionsBySection: QuestionsBySection) => Section = R.prop('section')
export const questionsProp: (questionsBySection: QuestionsBySection) => ReadonlyArray<Question> = R.prop('questions')

export const isMatchSectionId: (sectionId: Id) => (questionsBySection: QuestionsBySection) => boolean =
  sectionId => questionsBySection => sectionId === questionsBySection.section.id

export type AllQuestionsBySection = ReadonlyArray<QuestionsBySection>

export type QaQuestionsProps = Omit<QaQuestionPageProps, 'labeledQuestions' | 'createQuestion'> & Readonly<{
  questionsBySection: AllQuestionsBySection
}>
