import React from 'react'
import classNames from 'classnames'
import * as R from 'ramda'

import './styles.css'
import LogSelectedLabel from '../../LogSelected'

const shouldBeDisabled = R.lte(R.__, 0)

const shouldBeActivated = R.gt(R.__, 0)

const shouldBeDeactivated = R.complement(shouldBeActivated)

const ValidateButton = ({ onClick, numberToValidate }) => (
  <div className='ValidateButton'>
    <LogSelectedLabel logCount={numberToValidate}/>
    <button
      className={classNames('ValidateButton-button', {
        'activated-button': shouldBeActivated(numberToValidate),
        'deactivated-button': shouldBeDeactivated(numberToValidate)
      })}
      disabled={shouldBeDisabled(numberToValidate)}
      onClick={onClick}
    >
      VALIDATE
    </button>
  </div>
)

export default ValidateButton
