import { remoteData, maybe, RemoteData, Maybe } from '@sbizeul/fp-flow'
import { createSelector } from 'reselect'
import { createMatchSelector } from 'connected-react-router'
import { Predicate } from 'fp-ts/lib/function'
import * as R from 'ramda'

import { Id, ServerFailure } from 'types'

import { AppState, Conversation } from './types'

export const getState = (state: AppState) => state.conversation

export const getCurrentPage = createSelector(getState, state => state.page)

export const getNbResultsPerPage = createSelector(getState, state => state.nbResultsPerPage)

export const getCurrentLang = createSelector(getState, state => state.lang)

export const getStartDate = createSelector(getState, state => state.startDate)
export const getEndDate = createSelector(getState, state => state.endDate)

export const getSelectedID = createSelector(getState, state => state.selectedID)

export const getConversations = createSelector(getState, state => state.conversations)

export const getSelectedConversation = createSelector([getConversations, getSelectedID], (conversations, selectedID) =>
  maybe.flatMap(conversationId => findConversationFromRemoteData(conversationId)(conversations), selectedID)
)

export const matchSelector = createMatchSelector('/conversations')
export const getFirstChannel = createSelector(getState, state => state.channelID)

export const getSearchValue = createSelector(getState, state => state.searchValue)

export const getIsDownloadingCSV = createSelector(getState, state => state.isDownloadingCSV)

const findConversationFromRemoteData: (selectedId: Id) => (conversations: RemoteData<ServerFailure, Conversation[]>) => Maybe<Conversation> =
  selectedId => R.pipe(
    remoteData.getOrElse(() => []),
    findConversationFromId(selectedId)
  )

const findConversationFromId: (selectedId: Id) => (conversations: Conversation[]) => Maybe<Conversation> =
  selectedId => R.compose(
    maybe.fromNullable, R.find(isMatchingId(selectedId))
  )

const isMatchingId: (selectedId: Id) => Predicate<Conversation> = R.propEq('id')
