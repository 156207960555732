import { put, call, takeEvery, takeLatest } from 'redux-saga/effects'

import Api from 'services/tolkApiLegacy'

import mainActions from '../../redux/main/actions'
import * as channelActions from './actions'
import bot from '../bot'

export function* fetchAll(action) {
  const { payload: userId } = action
  try {
    const { data } = yield call(Api.get, `/user/${userId}/channels`)
    yield put(channelActions.fetchAllSuccess(data))
  } catch (error) {
    yield put(channelActions.fetchAllFailure(error.response.data))
  }
}

export function* fetchByBot(action) {
  const { payload: botId } = action
  try {
    const { data: channels } = yield call(Api.get, `/bot/${botId}/channels`)
    yield put(channelActions.fetchByBotSuccess(botId, channels))
    if (channels.length) {
      yield put(mainActions.setActiveChannel({ name: channels[0].name, id: channels[0]._id }))
    }
  } catch (error) {
    yield put(channelActions.fetchByBotFailure(botId, error.response.data))
  }
}

export function* root() {
  yield takeLatest(channelActions.FETCH_ALL_REQUEST, fetchAll)
  yield takeLatest(channelActions.FETCH_BY_BOT_REQUEST, fetchByBot)
  yield takeEvery(bot.actions.SELECT, fetchByBot)
}
