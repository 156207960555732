import { connect } from 'react-redux'

import * as registerActions from '../actions'
import RemoteRegister from '../components/Register/RemoteRegister'
import { RegisterAppState } from '../types'
import * as selectors from '../selectors'

const mapStateToProps = (state: RegisterAppState) => ({
  registerState: selectors.getRegister(state)
})

const mapDispatchToProps = {
  register: registerActions.registerRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(RemoteRegister)
