import moment from 'moment-timezone'
import * as R from 'ramda'
import { v4 as uuid } from 'uuid'
import { isToday, isTomorrow } from '../../helpers/moment'
import { CampaignStatus, ElementTypes } from './types'

export const rehydrateCampaign = R.curry((newTimeZone, campaign) => ({
  ...campaign,
  updated: false,
  kpis: {
    sent: R.pathOr(0, ['kpis', 'sent'])(campaign),
    read: R.pathOr(0, ['kpis', 'read'])(campaign),
    targeted: R.pathOr(0, ['kpis', 'targeted'])(campaign)
  },
  elements: campaign.elements.map(element => {
    if (element.type !== ElementTypes.carousel) {
      return {
        ...element,
        id: uuid()
      }
    }
    return {
      ...element,
      id: uuid(),
      content: {
        ...element.content,
        attachments: element.content.attachments.map(attachment => ({
          ...attachment,
          id: uuid(),
          buttons: attachment.buttons.map(button => ({
            ...button,
            id: uuid()
          }))
        }))
      }
    }
  }),
  recurrence: R.propOr('day', 'recurrence')(campaign),
  date: campaign.timeZone ? moment(campaign.date).tz(campaign.timeZone) : moment(campaign.date).tz(newTimeZone),
  timeZone: campaign.timeZone || newTimeZone,
  sendNow: campaign.sendNow || R.isNil(campaign.sendNow)
}))

export const rehydrateCampaigns = (campaigns, newTimeZone = moment.tz.guess(true)) =>
  campaigns.map(rehydrateCampaign(newTimeZone))

export const formatCampaignToSend = campaign => ({
  ...campaign,

  date: campaign.date.clone().format(),
  timeZone: moment(campaign.date).tz(),
  elements: campaign.elements.map(originalElement => {
    let element = Object.assign({}, originalElement)
    delete element.id
    if (element.type !== ElementTypes.carousel) {

      return element
    }

    return {
      ...element,

      content: {
        ...element.content,

        attachments: element.content.attachments.map(originalAttachment => {
          let att = Object.assign({}, originalAttachment)
          delete att.id
          return {
            ...att,
            buttons: att.buttons.map(originalButton => {
              let button = Object.assign({}, originalButton)
              delete button.id
              return button
            })
          }
        })
      }
    }
  })
})

export const formatTitleDisplayed = ({ title, date, sendNow, status }) => {
  if (sendNow && status === CampaignStatus.draft) return title
  return `${date.format('DD / MM')} - ${title}`
}

export const formatDate = date => {
  const time = date.clone().format('h:mm A')
  if (isToday(date)) return `Today, ${time}`
  if (isTomorrow(date)) return `Tomorrow, ${time}`
  return `${date.clone().format('MMM Do')}, ${time}`
}

export const timeOptionValue = (date, minutes) =>
  date
    .clone()
    .startOf('day')
    .add(minutes, 'm')
    .format('LT')

export const createMinutesIntervals = () => {
  const minutesInterval = 15
  const minutesIntervals = []
  const numberOfTimes = (24 * 60) / minutesInterval
  for (let i = 0; i < numberOfTimes; i++) {
    minutesIntervals[i] = i * minutesInterval
  }
  return minutesIntervals
}

export const filterMinutes = (date, minutes, isSent) =>
  isSent ||
  !isToday(date) ||
  moment().diff(
    moment()
      .clone()
      .startOf('day'),
    'minutes'
  ) < minutes

export const createInitialDate = () => {
  const timeZone = moment.tz.guess(true)
  const date = moment()
  const min = createMinutesIntervals().filter(minutes => filterMinutes(date, minutes, false))[0]
  return {
    date: date
      .clone()
      .startOf('day')
      .add(min, 'm')
      .tz(timeZone),
    timeZone
  }
}

export const formatKpi = kpi => {
  const kpiNumber = parseInt(kpi, 10)
  if (kpi < 1000) return `${kpiNumber}`
  if (kpi >= 1000000) return `${Math.round((kpiNumber * 10) / 1000000) / 10}M`
  return `${Math.round(kpiNumber / 1000)}K`
}

export const formatCohort = ({ name, number }) => `${name} (${formatKpi(number)})`

export const calculateCohortSum = (cohorts, campaignCohorts) =>
  [
    ...cohorts.reduce((userIds, cohort) => {
      if (!campaignCohorts.includes(cohort.name)) return userIds
      for (let userId of cohort.userIds) {
        userIds.add(userId)
      }
      return userIds
    }, new Set())
  ].length

export const formatCohortSummary = (cohorts, campaignCohorts) =>
  formatKpi(calculateCohortSum(cohorts, campaignCohorts)) + ' reachable unique users'

export const updateCarousel = (carousel, cardIndex, card) => ({
  ...carousel,
  content: {
    ...carousel.content,
    attachments: [
      ...carousel.content.attachments.slice(0, cardIndex),
      card,
      ...carousel.content.attachments.slice(cardIndex + 1)
    ]
  }
})

export const updateButton = (buttons, buttonIndex, button) => [
  ...buttons.slice(0, buttonIndex),
  button,
  ...buttons.slice(buttonIndex + 1)
]

export const urlIsValid = url => url.startsWith('https://')

// KPIS processing helpers

// sentDate is a utc timestamp string

export const getByHour = (analytics, sentDate) => {
  return analytics.filter(analytic => {
    const { date } = analytic
    return !moment(sentDate)
      .add(1, 'hour')
      .isBefore(moment(date))
  })
}

export const getByDay = (analytics, sentDate) => {
  return analytics.filter(analytic => {
    const { date } = analytic
    return !moment(sentDate)
      .add(1, 'day')
      .isBefore(moment(date))
  })
}

export const getByWeek = (analytics, sentDate) => {
  return analytics.filter(analytic => {
    const { date } = analytic
    return !moment(sentDate)
      .add(1, 'week')
      .isBefore(moment(date))
  })
}

export const nextLimits = {
  hour: (date, interval) => moment(date).add(interval * 5, 'minutes'),
  day: (date, interval) =>
    moment(date)
      .add(interval, 'hours')
      .startOf('hours'),
  week: (date, interval) => moment(date).add(interval, 'days')
}

export const getIntervals = (actionKpis, sentDate, options) => {
  const cohorts = [...new Set(actionKpis.map(R.prop('cohort')))]
  const sorted = actionKpis.sort((a, b) => moment(a.date).format('YYYYMMDD') - moment(b.date).format('YYYYMMDD'))
  const intervals = R.range(1, options.nIntervals + 1)
  let slice = sorted
  const formattedDatas = []
  const defaultObj = cohorts.reduce((acc, curr) => ({ ...acc, [curr]: 0 }), {})
  intervals.forEach(interval => {
    const obj = R.clone(defaultObj)
    const limit = nextLimits[options.type](sentDate, interval)
    for (let i = 0; i < slice.length; i++) {
      if (moment(slice[i].date).isAfter(limit)) {
        slice = slice.slice(i)
        break
      }
      obj[slice[i].cohort] += 1
      if (i + 1 === slice.length) slice = []
    }
    formattedDatas.push(obj)
  })
  return formattedDatas
}

export const getHourInterval = (actionKpis, sentDate) =>
  getIntervals(actionKpis, sentDate, { type: 'hour', step: 'minutes', nIntervals: 12, interval: 5 })

export const getDayInterval = (actionKpis, sentDate) =>
  getIntervals(actionKpis, sentDate, { type: 'day', step: 'hours', nIntervals: 24, interval: 1 })

export const getWeekInterval = (actionKpis, sentDate) =>
  getIntervals(actionKpis, sentDate, { type: 'week', step: 'days', nIntervals: 7, interval: 1 })

export const minutesIntervalLabel = _date => R.range(1, 13).map(i => `${(i - 1) * 5}-${i * 5} min`)

export const hoursIntervalLabel = date =>
  R.range(1, 25).map(i => {
    const s = moment(date)
      .startOf('hour')
      .add(i - 1, 'hour')
    const e = moment(date)
      .startOf('hour')
      .add(i, 'hour')
    return `${s.format('ha')}<br />-<br />${e.format('ha')}`
  })

export const daysIntervalLabel = date =>
  R.range(1, 8).map(i => {
    const s = moment(date)
      .startOf('day')
      .add(i - 1, 'day')
    return `${s.format('ddd')}`
  })

export const minutesIntervalDetails = date =>
  R.range(1, 25).map(i => {
    const s = moment(date).add((i - 1) * 5, 'minute')
    const e = moment(date).add(i * 5, 'minute')
    return `
    Opened beetween<br />
    ${s.format('MMMM Do YYYY, h:mm a')}<br />
    and
    <br />${e.format('MMMM Do YYYY, h:mm a')}
  `
  })

export const hoursIntervalDetails = date =>
  R.range(1, 25).map(i => {
    const tz = moment.tz.guess()
    const s =
      i === 1
        ? moment(date)
          .tz(tz)
          .add(i - 1, 'hour')
        : moment(date)
          .startOf('hour')
          .tz(tz)
          .add(i - 1, 'hour')
    const e = moment(date)
      .startOf('hour')
      .add(i, 'hour')
      .tz(tz)
    return `
    Opened beetween<br />
    ${s.format('MMMM Do YYYY, h:mm a')}<br />
    and
    <br />${e.format('MMMM Do YYYY, h:mm a')}
  `
  })

export const daysIntervalDetails = date =>
  R.range(1, 25).map(i => {
    const tz = moment.tz.guess()
    const s =
      i === 1
        ? moment(date)
          .add(i - 1, 'day')
          .tz(tz)
        : moment(date)
          .startOf('day')
          .tz(tz)
          .add(i - 1, 'day')
    const e = moment(date)
      .startOf('day')
      .add(i, 'day')
      .tz(tz)
    return `
    Opened beetween<br />
    ${s.format('MMMM Do YYYY, h:mm a')}<br />
    and
    <br />${e.format('MMMM Do YYYY, h:mm a')}
  `
  })

export const intervalLabels = {
  hour: minutesIntervalLabel,
  day: hoursIntervalLabel,
  week: daysIntervalLabel,
  details: {
    hour: minutesIntervalDetails,
    day: hoursIntervalDetails,
    week: daysIntervalDetails
  }
}
