import React from 'react'
import PropTypes from 'prop-types'
import { Dimmer, Loader } from 'semantic-ui-react'

const GlobalLoader = ({ message }) => (
  <Dimmer active page>
    <Loader size='big'>{message}</Loader>
  </Dimmer>
)

GlobalLoader.propTypes = {
  message: PropTypes.string
}

GlobalLoader.defaultProps = {
  message: null
}

export default GlobalLoader
