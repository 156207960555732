import 'react-dates/initialize'
import React from 'react'
import classNames from 'classnames'
import Dropzone from 'react-dropzone'
import { Button, Modal, Icon } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { maybe, remoteData, either, pipe } from '@sbizeul/fp-flow'
import * as R from 'ramda'
import { compose } from 'redux'
import 'react-sliding-pane/dist/react-sliding-pane.css'

import CreateIntentForm from '../New/CreateIntentForm'
import IntentsTable from './IntentsTable'
import { Loader } from '../../../../components/Loading'
import { Error } from '../../../../components/Message'
import bot from '../../../../modules/bot'
import intent from '../../../../modules/intent'
import { renderNothing, didMount } from '../../../../helpers/react'

import './index.css'

export const MainPage = props => (
  <div className='MainPage'>
    <div className='MainPage-createTagZone'>
      <Modal
        trigger={
          <Button inverted size='small' color='green'>
            <Icon name='add' />
            Create Intent
          </Button>
        }
      >
        <Modal.Header>Create Intent</Modal.Header>
        <Modal.Content>
          <CreateIntentForm />
        </Modal.Content>
      </Modal>
    </div>
    {remoteData.fold(
      {
        Success: intents =>
          R.isEmpty(intents) ? renderEmpty(props.isPosting, props.importFile) : <IntentsTable data={intents} />,
        Failure: renderError,
        Loading: renderLoader('Fetching intents...'),
        NotAsked: renderNothing
      },
      props.intents
    )}
  </div>
)

function renderDropzone(importFile) {
  return () => (
    <Dropzone onDrop={importFile}>
      {({ getRootProps, getInputProps, isDragActive }) => (
        <div {...getRootProps()} className={classNames('MainPage-dropzone', { 'dropzone-active': isDragActive })}>
          <input {...getInputProps()} />
          <div>
            <Icon size='huge' name='add circle' />
          </div>
          {isDragActive ? (
            <p>Drop files here...</p>
          ) : (
            <p>Try dropping some files here, or click to select files to upload</p>
          )}
        </div>
      )}
    </Dropzone>
  )
}

function renderLoader(message) {
  return () => <Loader description={message} />
}

function renderError(error) {
  return <Error header={error.message} />
}

function match(importFile) {
  return isPosting => (isPosting ? renderLoader('Importing files...') : renderDropzone(importFile))
}

function renderEmpty(isPosting, importFile) {
  return (
    <div>
      <div className='MainPage-dropZoneContainer'>
        {pipe(either.map(match(importFile)), either.fold(renderError, R.call))(isPosting)}
      </div>
      <div className='MainPage-botNeedsTrain'>
        <p>Your bot needs to be trained!</p>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  botId: bot.selectors.getSelectedId(state),
  intents: intent.selectors.getAll(state),
  isPosting: intent.selectors.getIsPosting(state)
})

const mapDispatchToProps = {
  fetchIntents: intent.actions.fetchAll,
  importFile: intent.actions.importFile
}

const withRedux = connect(mapStateToProps, mapDispatchToProps)

const withDidMount = didMount(props => maybe.map(props.fetchIntents, props.botId))

export default compose(withRedux, withDidMount)(MainPage)
