import React, { FormEvent } from 'react'
import * as E from 'fp-ts/lib/Either'
import * as O from 'fp-ts/lib/Option'

import { renderLoader, renderServerFailure } from 'helpers/react'

import {
  ChannelSettingsProps as RemoteChannelSettingsProps,
  ChannelInformation,
  UpdatedChannelInformation
} from '../../types'
import { SettingsForm } from './SettingsForm'

import './ChannelSettings.css'

export type ChannelSettingsProps = Omit<RemoteChannelSettingsProps, 'channelInfo' | 'fetchChannelInfo'> & Readonly<{
  channelInformation: O.Option<ChannelInformation>
}>

const ChannelSettings: React.FunctionComponent<ChannelSettingsProps> = (props) => {

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const formData: FormData = new FormData(e.target as HTMLFormElement)
    const channelOptions = JSON.parse(JSON.stringify(Object.fromEntries(formData)))
    const updatedInfo: UpdatedChannelInformation = {
      name: props.channelSlug,
      options: channelOptions
    }
    props.saveChannelInfo(updatedInfo)
  }

  const renderSavingIndicator = () => E.fold(
    renderServerFailure,
    isSaving => isSaving && renderLoader('Please wait...')
  )(props.saving)

  return (
    <div className='pageContainer'>
      <section className='channelsettings'>
        {renderSavingIndicator()}
        <SettingsForm
          name={props.channelName}
          fields={props.channelFields}
          channelInformation={props.channelInformation}
          onSubmit={handleSubmit}
        />
      </section>
    </div>
  )
}

export {
  ChannelSettings
}
