import { connect } from 'react-redux'

import { fetchKeywordsRequest, create, openCreateForm, closeCreateForm } from '../actions'
import { deleteKeywordRequest, fetchLinkedKeywordElementRequest, updatePersistKeywordStatusRequest } from '../action.typed'
import Keywords from '../components/Keywords'
import { getAll, isKeywordCreating, isOpenCreateForm, getLinkedKeywordElement } from '../selectors'

const mapStateToProps = state => {
  return {
    keywords: getAll(state),
    isCreating: isKeywordCreating(state),
    isOpenCreateForm: isOpenCreateForm(state),
    linkedKeywordElement: getLinkedKeywordElement(state)
  }
}

const mapDispatchToProps = {
  closeCreateForm: closeCreateForm,
  createKeyword: create,
  deleteKeyword: deleteKeywordRequest,
  updatePersistKeywordStatus: updatePersistKeywordStatusRequest,
  fetchLinkedKeywordElement: fetchLinkedKeywordElementRequest,
  fetchKeywords: fetchKeywordsRequest,
  openCreateForm: openCreateForm
}

const KeywordsContainer = connect(mapStateToProps, mapDispatchToProps)(Keywords)

export default KeywordsContainer
